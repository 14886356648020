import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FieldTextDTO } from 'src/app/app-core/dto/field-text-dto';
import { ApplicationService } from 'src/app/services/application.service';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { isNumber } from 'util';
import { BasicService } from 'src/app/services/basic.service';
var CalendarFieldComponent = /** @class */ (function () {
    function CalendarFieldComponent(myApplication, basicService) {
        this.myApplication = myApplication;
        this.basicService = basicService;
        this.minDate = { year: 2018, month: 1, day: 1 };
        this.inModal = false;
        this.min = 1;
        this.init = true;
        this._disable = false;
        this.useWarningClass = false;
    }
    CalendarFieldComponent.prototype.ngOnInit = function () {
        if (!this.id) {
            this.id = this.valueName;
        }
        this.fieldDTO = this.myApplication.textDTOs.get(this.id);
        if (!this.fieldDTO) {
            this.fieldDTO = new FieldTextDTO(this.id, this.id, '');
        }
        this.update();
        try {
            this.object.addObserver(this);
        }
        catch (_a) {
            // was solls
        }
    };
    Object.defineProperty(CalendarFieldComponent.prototype, "disable", {
        get: function () {
            return this._disable;
        },
        set: function (value) {
            this._disable = value;
        },
        enumerable: true,
        configurable: true
    });
    CalendarFieldComponent.prototype.update = function () {
        this.init = true;
        this.model = {
            year: this.object[this.valueName.toString()].getFullYear(),
            month: this.object[this.valueName.toString()].getMonth() + 1,
            day: this.object[this.valueName.toString()].getDate()
        };
        this.init = false;
    };
    CalendarFieldComponent.prototype.getWarningClass = function () {
        if (this.useWarningClass) {
            return 'border-warning';
        }
    };
    CalendarFieldComponent.prototype.onBlur = function (event) {
        this.update();
    };
    CalendarFieldComponent.prototype.setValueToObject = function (event) {
        var dateString = event.target.value.toString();
        var newDate = this.basicService.parseDate(dateString, 'dd.mm.yyyy');
        if (newDate && !isNaN(newDate.getTime())) {
            this.object[this.valueName.toString()] = newDate;
            this.update();
            this.useWarningClass = false;
        }
        else {
            this.useWarningClass = true;
        }
    };
    CalendarFieldComponent.prototype.setModelToObject = function () {
        var newDate = new Date(this.model.year, this.model.month - 1, this.model.day);
        if (!isNaN(newDate.getTime())) {
            this.object[this.valueName.toString()] = newDate;
            this.update();
            this.useWarningClass = false;
        }
        else {
            this.useWarningClass = true;
        }
    };
    return CalendarFieldComponent;
}());
export { CalendarFieldComponent };
var NgbDateCustomParserFormatter = /** @class */ (function (_super) {
    tslib_1.__extends(NgbDateCustomParserFormatter, _super);
    function NgbDateCustomParserFormatter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NgbDateCustomParserFormatter.prototype.parse = function (value) {
        if (value) {
            var dateParts = value.trim().split('.');
            if (dateParts.length === 1 && isNumber(dateParts[0])) {
                return { day: Number.parseInt(dateParts[0]), month: null, year: null };
            }
            else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
                return { day: Number.parseInt(dateParts[0]), month: Number.parseInt(dateParts[1]), year: null };
            }
            else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
                return {
                    day: Number.parseInt(dateParts[0]), month: Number.parseInt(dateParts[1]), year: Number.parseInt(dateParts[2])
                };
            }
        }
        return null;
    };
    NgbDateCustomParserFormatter.prototype.format = function (date) {
        function padNumber(value) {
            if (isNumber(value)) {
                return ("0" + value).slice(-2);
            }
            else {
                return "";
            }
        }
        return date ? (isNumber(date.day) ? padNumber(date.day) : '') + "." + (isNumber(date.month) ? padNumber(date.month) : '') + "." + date.year : '';
    };
    return NgbDateCustomParserFormatter;
}(NgbDateParserFormatter));
export { NgbDateCustomParserFormatter };
