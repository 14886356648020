import * as tslib_1 from "tslib";
import { AbstractObject } from '../technical-aspects/abstract-object';
import { SortEO } from '../entity-objects/sort-eo';
import { SortSeedSet } from './sort-seed-set';
import { SeedSet } from './seed-set';
var Sort = /** @class */ (function (_super) {
    tslib_1.__extends(Sort, _super);
    function Sort() {
        return _super.call(this) || this;
    }
    Sort.prototype.update = function () {
    };
    Sort.prototype.deleteChildren = function () {
        var _this = this;
        this.seedSet.seeds.forEach(function (element) {
            _this.daoSeeds.deleteObject(element);
        });
        this.sortSeedSet.sortSeeds.forEach(function (element) {
            _this.daoSortSeeds.deleteObject(element);
        });
    };
    Object.defineProperty(Sort.prototype, "plantDistance", {
        get: function () {
            return this.sortEO.plantDistance;
        },
        set: function (value) {
            this.sortEO.plantDistance = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Sort.prototype, "rowDistance", {
        get: function () {
            return this.sortEO.rowDistance;
        },
        set: function (value) {
            this.sortEO.rowDistance = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Sort.prototype, "numRows", {
        get: function () {
            return this.sortEO.numRows;
        },
        set: function (value) {
            this.sortEO.numRows = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Sort.prototype, "seedSet", {
        get: function () {
            if (!this._seedSet) {
                this._seedSet = new SeedSet(this.daoSeeds, this.daoSortSeeds, this.id, this.myApplication);
                this._seedSet.otherUnit = this.useOtherSeedUnit;
            }
            return this._seedSet;
        },
        set: function (value) {
            this._seedSet = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Sort.prototype, "sortSeedSet", {
        get: function () {
            if (!this._sortSeedSet) {
                this._sortSeedSet = new SortSeedSet(this.daoSortSeeds, this.id, this.myApplication);
                //Kennzeichen ob TKG oder andere Einheit weitergeben
                this._sortSeedSet.otherUnit = this.useOtherSeedUnit;
            }
            return this._sortSeedSet;
        },
        set: function (value) {
            this._sortSeedSet = value;
        },
        enumerable: true,
        configurable: true
    });
    Sort.prototype.isDeleteable = function () {
        if (this.sortSeedSet.getCompleteLength() > 0) {
            return false;
        }
        else {
            return true;
        }
    };
    Object.defineProperty(Sort.prototype, "culture", {
        get: function () {
            if (!this._culture) {
                this.culture = this.daoCultures.getObjectById(this.cultureId);
            }
            return this._culture;
        },
        set: function (value) {
            this._culture = value;
        },
        enumerable: true,
        configurable: true
    });
    Sort.prototype.initialize = function () {
        this.eo = new SortEO();
    };
    Sort.prototype.getFullName = function () {
        return this.culture.name + ' ' + this.name;
    };
    Sort.prototype.setDefaultValues = function () {
        //this.name = '<' + this.myApplication.getText('newSort') + '>';
    };
    Object.defineProperty(Sort.prototype, "sortEO", {
        get: function () {
            return this.eo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Sort.prototype, "name", {
        get: function () {
            return this.sortEO.name;
        },
        set: function (value) {
            this.sortEO.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Sort.prototype, "cultureId", {
        get: function () {
            return this.sortEO.cultureId;
        },
        set: function (value) {
            this.sortEO.cultureId = value;
            this.culture = this.daoCultures.getObjectById(this.cultureId);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Sort.prototype, "useOwnPeriods", {
        get: function () {
            return this.sortEO.useOwnPeriods;
        },
        set: function (value) {
            if (value != this.sortEO.useOwnPeriods) {
                this.sortEO.useOwnPeriods = value;
                if (this.sortEO.useOwnPeriods) {
                    this.seed = this.culture.seed;
                    this.grow = this.culture.grow;
                    this.harvest = this.culture.harvest;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Sort.prototype, "note", {
        get: function () {
            return this.sortEO.note;
        },
        set: function (value) {
            this.sortEO.note = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Sort.prototype, "useOwnDistances", {
        get: function () {
            return this.sortEO.useOwnDistances;
        },
        set: function (value) {
            if (value != this.sortEO.useOwnDistances) {
                this.sortEO.useOwnDistances = value;
                if (this.sortEO.useOwnDistances) {
                    this.plantDistance = this.culture.plantDistance;
                    this.rowDistance = this.culture.rowDistance;
                    this.numRows = this.culture.numRows;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Sort.prototype, "seed", {
        get: function () {
            return this.sortEO.seed;
        },
        set: function (value) {
            if (value != this.sortEO.seed) {
                this.sortEO.seed = value;
                this.hasChanged();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Sort.prototype, "grow", {
        get: function () {
            return this.sortEO.grow;
        },
        set: function (value) {
            if (value != this.sortEO.grow) {
                this.sortEO.grow = value;
                this.hasChanged();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Sort.prototype, "harvest", {
        get: function () {
            return this.sortEO.harvest;
        },
        set: function (value) {
            if (value != this.sortEO.harvest) {
                this.sortEO.harvest = value;
                this.hasChanged();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Sort.prototype, "seedRate", {
        get: function () {
            return this.sortEO.seedRate;
        },
        set: function (value) {
            this.sortEO.seedRate = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Sort.prototype, "useOwnDetails", {
        get: function () {
            return this.sortEO.useOwnDetails;
        },
        set: function (value) {
            if (value != this.sortEO.useOwnDetails) {
                this.sortEO.useOwnDetails = value;
                if (this.sortEO.useOwnDetails) {
                    this.seedRate = this.culture.seedRate;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Sort.prototype, "useOtherSeedUnit", {
        get: function () {
            return this.sortEO.useOtherSeedUnit;
        },
        set: function (value) {
            this.sortEO.useOtherSeedUnit = value;
        },
        enumerable: true,
        configurable: true
    });
    Sort.prototype.checkIfTkgIsUsed = function () {
        var isUsed = false;
        this.sortSeedSet.sortSeeds.forEach(function (sortSeed) {
            if (!sortSeed.otherUnit) {
                isUsed = true;
            }
        });
        return isUsed;
    };
    Sort.prototype.checkIfOtherUnitIsUsed = function () {
        var isUsed = false;
        this.sortSeedSet.sortSeeds.forEach(function (sortSeed) {
            if (sortSeed.otherUnit == true) {
                isUsed = true;
            }
        });
        return isUsed;
    };
    return Sort;
}(AbstractObject));
export { Sort };
