import { Injectable } from '@angular/core';
import { DaoService } from './dao.service';
import { PermissionsService } from './permissions.service';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitializerService {

  public initialized = false;


  constructor(private permissionsSrv: PermissionsService, private repoSrv: RepositoryService, private daoService: DaoService) { }

  public initialize() {
    this.daoService.initialize();

    this.permissionsSrv.readPermissions().then(() => {
      this.repoSrv.refresh().then(() => {
        this.initialized = true;
      });
    });
  }
}
