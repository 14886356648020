import * as tslib_1 from "tslib";
import { AbstractObject } from '../technical-aspects/abstract-object';
import { PersonEO } from '../entity-objects/person-eo';
var Person = /** @class */ (function (_super) {
    tslib_1.__extends(Person, _super);
    function Person() {
        return _super.call(this) || this;
    }
    Person.prototype.update = function () {
    };
    Person.prototype.initialize = function () {
        this.eo = new PersonEO();
    };
    Object.defineProperty(Person.prototype, "personEO", {
        get: function () {
            return this.eo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Person.prototype, "name", {
        get: function () {
            return this.personEO.name;
        },
        set: function (value) {
            this.personEO.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Person.prototype, "avatar", {
        get: function () {
            return this.personEO.avatar;
        },
        set: function (value) {
            this.personEO.avatar = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Person.prototype, "workHours", {
        get: function () {
            return this.personEO.workHours;
        },
        set: function (value) {
            this.personEO.workHours = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Person.prototype, "uname", {
        get: function () {
            return this.personEO.uname;
        },
        set: function (value) {
            this.personEO.uname = value;
        },
        enumerable: true,
        configurable: true
    });
    return Person;
}(AbstractObject));
export { Person };
