export class Constants {
    public static readonly CONSUMER_TYPE_STARKZEHRER = 'S';
    public static readonly CONSUMER_TYPE_MITTELZEHRER = 'M';
    public static readonly CONSUMER_TYPE_SCHWACHZEHRER = 'W';

    public static readonly CULTURE_TYPE_ANZUCHT = 'A';
    public static readonly CULTURE_TYPE_DIREKTSAAT = 'D';
    public static readonly CULTURE_TYPE_PFLANZKAUF = 'K';
    public static readonly CULTURE_TYPE_ANZUCHTVERKAUF = 'V';

    public static readonly HARVEST_METHOD_ABSOLUT = 'A';
    public static readonly HARVEST_METHOD_M2 = 'M';
    public static readonly HARVEST_METHOD_WEEK = 'P';
    public static readonly HARVEST_METHOD_M2_WEEK = 'W';

    public static readonly UNIT_PIECE = 'S';
    public static readonly UNIT_GRAMM = 'g';
    public static readonly UNIT_KILOGRAMM = 'kg';
    public static readonly UNIT_TONS = 't';
    public static readonly UNIT_KORN = 'Korn';
    public static readonly UNIT_BUND = 'B';
   
    public static readonly SYSTEM_SOLAWI = '1';
    public static readonly SYSTEM_MARKET_GARDENING = '2';
    public static readonly SYSTEM_PRIVATE = '3';
    public static readonly SYTEM_ERWERBS_GEMUESE = '4';

    public static readonly STATE_PLANNED = 'P';
    public static readonly STATE_ANZUCHT = 'V';
    public static readonly STATE_PLANTED = 'F';
    public static readonly STATE_HARVEST = 'H';

    public static readonly AREA_TYPE_FREILAND = 'F';
    public static readonly AREA_TYPE_GLAS = 'G';

    public static readonly TODO_RELATIVE = 'R';
    public static readonly TODO_STANDALONE = 'S';
    public static readonly TODO_RECORD_DATES = 'D';
    public static readonly TODO_BED = 'B';
    public static readonly TODO_AREA = 'A';
    public static readonly TODO_SERIES_RELATIVE = 'Z';
    public static readonly TODO_SERIES_STANDALONE = 'Y';
    public static readonly TODO_SERIES_BED = 'X';
    public static readonly TODO_SERIES_AREA = 'W';
    public static readonly TODO_RECORD_ABSOLUTE = 'C';
    public static readonly TODO_SERIES_RECORD_ABSOLUTE = 'V';

    public static readonly TEMPLATE_PREPOSITION_BEFORE = 'v';
    public static readonly TEMPLATE_PREPOSITION_AFTER = 'n';

    public static readonly TEMPLATE_TIME_REF_ANZUCHT = 'a';
    public static readonly TEMPLATE_TIME_REF_PFLANZUNG = 'w';
    public static readonly TEMPLATE_TIME_REF_ERNTE = 'e';
    public static readonly TEMPLATE_TIME_REF_KULTURENDE = 'z';

    public static readonly TODO_STATE_INITIAL = '';
    public static readonly TODO_STATE_DONE = 'O';

    public static readonly COLOR_PLANNED: String = '#119595';
    public static readonly COLOR_ANZUCHT: String = '#ffc107';
    public static readonly COLOR_PLANTED: String = '#28a745';
    public static readonly COLOR_HARVEST: String = '#868e96';

    public static readonly TIME_UNIT_TAGE = 'Tage';

    public static readonly PARENT_TYPE_RECORD = 'R';
    public static readonly PARENT_TYPE_BED = 'B';
    public static readonly PARENT_TYPE_AREA = 'A';

    public static readonly YES = 'ja';
    public static readonly NO = 'nein';
    public static readonly NOT_RELEVANT = '';

    public static readonly NO_BEDMETERS = 'noBedM';

    public static readonly TODO_TYPE_ANZUCHT = 'Anzucht';
    public static readonly TODO_TYPE_AUSSAAT = 'Aussaat';
    public static readonly TODO_TYPE_PFLANZUNG = 'Pflanzung';
    public static readonly TODO_TYPE_ERNTE = 'Ernte';

    public static readonly PAYMENT_METHOD_ANNUALY = 'A';
    public static readonly PAYMENT_METHOD_MONTHLY = 'M';
    public static readonly PAYMENT_METHOD_QUATERLY = 'Q';
    public static readonly PAYMENT_METHOD_SEMIANNUAL = 'H';

    public static readonly PACKAGE_BUY = 'Buy';
    public static readonly PACKAGE_TEST = 'Test';

    public static readonly UNIT_CM = 'cm';
    public static readonly UNIT_M = 'm';

    public static readonly CALENDAR_VIEW_MONTH = 'M';
    public static readonly CALENDAR_VIEW_WEEK = 'W';
    public static readonly CALENDAR_VIEW_DAY = 'D';

    public static readonly HARVEST_KIND_MARKED = 'M';
    public static readonly HARVEST_KIND_STORE = 'S';

    public static readonly REPO_GROUP = 'G';
    public static readonly REPO_GROUP_CHILD = 'C';

    public static readonly SERIES_INTERVAL_UNIT_TAG = 'T';
    public static readonly SERIES_INTERVAL_UNIT_WOCHEN = 'W';

    public static readonly FIX_USER_INPUT_PLANTS = 'P';
    public static readonly FIX_USER_INPUT_BEDMETERS = 'B';

    constructor() {
    }
}