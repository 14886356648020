import { OnInit } from '@angular/core';
import { FieldTextDTO } from 'src/app/app-core/dto/field-text-dto';
import { ApplicationService } from 'src/app/services/application.service';
var CheckFieldComponent = /** @class */ (function () {
    function CheckFieldComponent(myApplication) {
        this.myApplication = myApplication;
        this._disable = false;
        this.min = 1;
    }
    CheckFieldComponent.prototype.ngOnInit = function () {
        if (!this.id) {
            this.id = this.valueName;
        }
        this.fieldDTO = this.myApplication.textDTOs.get(this.id);
        if (!this.fieldDTO) {
            this.fieldDTO = new FieldTextDTO(this.id, this.id, '');
        }
    };
    Object.defineProperty(CheckFieldComponent.prototype, "disable", {
        get: function () {
            return this._disable;
        },
        set: function (value) {
            this._disable = value;
        },
        enumerable: true,
        configurable: true
    });
    return CheckFieldComponent;
}());
export { CheckFieldComponent };
