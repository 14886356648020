import { DAO } from '../technical-aspects/dao';
import { Harvest } from '../business-logic/harvest';

export class DAOHarvest extends DAO {

    protected createInstance() {
        let newInstance = new Harvest();
        return newInstance;
    }
    
    getHarvestforRecord(recordId: String): Array<Harvest> {
        let set = new Array<Harvest>();

        this.objects.forEach(element => {
            let harvest = <Harvest>element;
            if (recordId == harvest.recordId) {
                set.push(harvest);
            }
        });
        set.sort(function (one, two) {
            return one.harvestDate.getTime() - two.harvestDate.getTime();
        });
    return set;
    }

}