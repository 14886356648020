import { Component, OnInit, Input } from '@angular/core';
import { FieldTextDTO } from 'src/app/app-core/dto/field-text-dto';
import { ApplicationService } from 'src/app/services/application.service';

@Component({
  selector: 'app-check-field',
  templateUrl: './check-field.component.html',
  styleUrls: ['./check-field.component.css']
})
export class CheckFieldComponent implements OnInit {

  @Input() id: String;
  @Input() object;
  @Input() valueName: String;
  private _disable: boolean = false;

  public min: number = 1;
  public fieldDTO: FieldTextDTO;

  constructor(public myApplication: ApplicationService) { }

  ngOnInit() {
    if (!this.id) {
      this.id = this.valueName;
    }
    this.fieldDTO = this.myApplication.textDTOs.get(this.id);
    if (!this.fieldDTO) {
      this.fieldDTO = new FieldTextDTO(this.id, this.id, '');
    }
  }

  public get disable(): boolean {
    return this._disable;
  }
  @Input()
  public set disable(value: boolean) {
    this._disable = value;
  }
}
