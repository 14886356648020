<div class="modal fade" [id]="id" tabindex="-1" role="dialog" aria-labelledby="confirmModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="confirmModalLabel">
          <fa name="comment"></fa> {{ myApplication.getText('Feedback') }}
        </h5>
      </div>
      <div class="modal-body">
        {{ questionDTO.text }}

        <P></P>

        <app-select-field [id]="'Feedback.view'" [object]="feedback" [valueName]="'view'" [disable]="false"
          [keyValueList]="feedbackViews"></app-select-field>

        <textarea class="form-control form-control-sm my-3 bg-white" name="feedback.comment"
          [(ngModel)]="feedback.comment" rows="5" placeholder="Feedback" #name="ngModel"></textarea>

      </div>
      <div class="modal-footer">
        <app-button [iconName]="'comment'" [id]="'Feedback.send'" [callback]="callback" [callbackOwner]="callbackOwner"
          [dataDismiss]="'modal'" [disable]="false">
        </app-button>

        <app-button [iconName]="'times'" [id]="'Confirm.no'" [callback]="'noAction'" [callbackOwner]="this"
          [dataDismiss]="'modal'" [disable]="false">
        </app-button>
      </div>
    </div>
  </div>
</div>