import * as tslib_1 from "tslib";
import { AbstractObject } from '../technical-aspects/abstract-object';
import { BedEO } from '../entity-objects/bed-eo';
import { FilteredRecordSet } from './filtered-record-set';
import { ToDoListBed } from './todo-list-bed';
var Bed = /** @class */ (function (_super) {
    tslib_1.__extends(Bed, _super);
    function Bed() {
        var _this = _super.call(this) || this;
        _this.sumMeters = 0;
        _this.selected = false;
        _this.marked = false;
        _this.checked = false;
        return _this;
    }
    Bed.prototype.update = function () {
        this.myApplication.addObserver(this);
        this.daoToDos.addObserver(this);
        this.todoList = null;
        this.recordSet = null;
    };
    Bed.prototype.isDeleteable = function () {
        var allRecords = Array.from(this.daoRecords.objects.values());
        var record;
        for (var i = 0; i < allRecords.length; i++) {
            record = allRecords[i];
            if (record.bedAssignList.isOnBed(this.id)) {
                return false;
            }
        }
        return true;
    };
    Bed.prototype.deleteBedAssigns = function () {
        var _this = this;
        var records = this.daoRecords.getRecordsForBed(this.id);
        records.forEach(function (record) {
            record.bedAssignList.removeFromBed(_this.id);
        });
    };
    Bed.prototype.getNextBedOnArea = function () {
        var beds = this.daoBeds.getBedsForArea(this.areaId);
        var i = 0;
        var bed = beds[i];
        while (bed && bed != this) {
            i = i + 1;
            bed = beds[i];
        }
        bed = beds[i + 1];
        return bed;
    };
    Bed.prototype.getName = function () {
        if (this.name) {
            return this.name;
        }
        else {
            return this.orderNum.toString();
        }
    };
    Bed.prototype.getPreviousBedOnArea = function () {
        var beds = this.daoBeds.getBedsForArea(this.areaId);
        var i = 0;
        var bed = beds[i];
        while (bed && bed != this) {
            i = i + 1;
            bed = beds[i];
        }
        bed = beds[i - 1];
        return bed;
    };
    Bed.prototype.getM2 = function () {
        return this.length * this.width;
    };
    Object.defineProperty(Bed.prototype, "bedEO", {
        get: function () {
            return this.eo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Bed.prototype, "areaId", {
        get: function () {
            return this.bedEO.areaId;
        },
        set: function (value) {
            this.bedEO.areaId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Bed.prototype, "name", {
        get: function () {
            return this.bedEO.name;
        },
        set: function (value) {
            this.bedEO.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Bed.prototype, "orderNum", {
        get: function () {
            return this.bedEO.orderNum;
        },
        set: function (value) {
            this.bedEO.orderNum = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Bed.prototype, "width", {
        get: function () {
            return this.bedEO.width;
        },
        set: function (value) {
            this.bedEO.width = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Bed.prototype, "length", {
        get: function () {
            return this.bedEO.length;
        },
        set: function (value) {
            this.bedEO.length = value;
            this.hasChanged();
        },
        enumerable: true,
        configurable: true
    });
    Bed.prototype.initialize = function () {
        this.eo = new BedEO();
        this.recordSet = null;
    };
    Bed.prototype.getRecordSet = function () {
        var _this = this;
        if (!this.recordSet) {
            var objects_1 = new Array();
            this.daoRecords.objects.forEach(function (element) {
                var record;
                record = element;
                if (record.bedAssignList.isOnArea(_this.areaId) && record.bedAssignList.isOnBed(_this.id)) {
                    if (record.recordPeriods.growsInYear(_this.myApplication.year)) {
                        objects_1.push(record);
                    }
                    ;
                }
            });
            this.recordSet = new FilteredRecordSet(objects_1, this.myApplication, this.basicService);
            this.recordSet.objects = objects_1;
            this.recordSet.completeObjects = objects_1;
            this.sortRecordSet();
        }
        return this.recordSet;
    };
    Object.defineProperty(Bed.prototype, "recordSetProp", {
        get: function () {
            return this.getRecordSet();
        },
        enumerable: true,
        configurable: true
    });
    Bed.prototype.sortRecordSet = function () {
        this.recordSet.objects = this.recordSet.objects.sort(this.compareOnBed(this));
        this.recordSet.completeObjects = this.recordSet.objects;
    };
    Bed.prototype.compareOnBed = function (bed) {
        return function (one, two) {
            return one.bedAssignList.getAssignment(bed).positionOnBed - two.bedAssignList.getAssignment(bed).positionOnBed;
        };
    };
    Bed.prototype.getSumMeters = function () {
        var sum = 0;
        this.recordSet.objects.forEach(function (element) {
            sum = sum + element.lengthOnBed;
        });
        return sum;
    };
    Bed.prototype.calculateStartOnBed = function () {
    };
    Bed.prototype.buildRecordSet = function () {
        var _this = this;
        if (this.recordSet) {
            var records_1 = new Array;
            this.daoRecords.objects.forEach(function (element) {
                var record;
                record = element;
                if (record.bedAssignList.isOnArea(_this.areaId) && record.bedAssignList.isOnBed(_this.id)) {
                    records_1.push(record);
                }
            });
            this.recordSet.replaceObjects(records_1);
        }
        else {
            this.getRecordSet();
        }
        this.hasChanged();
    };
    Bed.prototype.getNumberOfRecordsForUI = function () {
        var numberOfRecords = this.getRecordSet().objects.length;
        // if ( numberOfRecords == 0) {
        //   numberOfRecords = 1;
        // }
        return numberOfRecords;
    };
    Object.defineProperty(Bed.prototype, "todoList", {
        get: function () {
            if (!this._todoList) {
                this._todoList = new ToDoListBed(this.daoToDos, this, this.daoBeds);
                this._todoList.toDos = this._todoList.toDos.sort(function (one, two) {
                    var compare = 0;
                    if (one.dueDate > two.dueDate) {
                        compare = 1;
                    }
                    else if (one.dueDate < two.dueDate) {
                        compare = -1;
                    }
                    else {
                        compare = 0;
                    }
                    return compare;
                });
            }
            return this._todoList;
        },
        set: function (value) {
            this._todoList = value;
        },
        enumerable: true,
        configurable: true
    });
    return Bed;
}(AbstractObject));
export { Bed };
