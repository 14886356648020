import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';

export class SortSeedEO extends AbstractEntityObject {

    public sortId: String;
    public manufacturer: String;
    public tkg: number;
    public manufacturingYear: number;
    public seedValue: number;
    public seedUnit: String;
    public otherUnit: boolean;

    constructor() {
        super();
        this.sortId = '';
        this.manufacturer = '';
        this.tkg = 0;
        this.manufacturingYear = 0;
        this.seedValue = 0;
        this.seedUnit = 'a';
        this.otherUnit = false;

    }
  }