import { Listener } from './listener';

export abstract class Observable {

    public listener: Array<Listener>;
    protected myInit: boolean;

    constructor() {
    }

    addObserver(listener: Listener) {
        if (!this.listener) {
            this.listener = new Array<Listener>();
        }
        let index = this.listener.indexOf(listener);
        if (!index || index < 0) {
            this.listener.push(listener);
        }
    }

    removeObserver(listener: Listener) {
        if (this.listener) {
            let index = this.listener.indexOf(listener);
            this.listener.splice(index, 1);
        }
    }

    hasChanged() {
        if (!this.myInit && this.listener) {
            for (let i = 0; i < this.listener.length; i++) {
                this.listener[i].update();
            }
        }
    }

    setInit(init: boolean) {
        this.myInit = init;
    }
}