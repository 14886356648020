import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import { map } from 'rxjs/operators';
import { Http, RequestOptions } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class HelpingDataService {


  constructor(private authenticationService: AuthenticationService, private http: Http) {

  }

  deleteBackFunctions() {
    return new Promise<void>((resolve, reject) => {
      const options = new RequestOptions();
      options.headers = this.authenticationService.getHeadersAuthenticated();
      this.http.delete(environment.apiV2 + 'backFunction', options).pipe(
        map(resp => resp.json())
      )
        .subscribe((data: any) => {
          resolve();
        });
    });
  }
}
