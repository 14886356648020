export class TagBuilder {

    buildTags(text: String): Array<string> {
        if (!text) {
            return new Array<string>();
        }
        let resultList = new Array<string>();
        let list = text.split(' ');
        list.forEach(element => {
            element = element.replace(',', '');
            element.trim();
            if (element != '' && element != ' ') {
                resultList.push(element);
            }
        });

        resultList.sort(function (one, two) {
            return one.localeCompare(two);
        });
        return resultList;
    }
}