import * as tslib_1 from "tslib";
import { Save } from './save';
var SaveInsert = /** @class */ (function (_super) {
    tslib_1.__extends(SaveInsert, _super);
    function SaveInsert() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SaveInsert.prototype.save = function () {
        var _this = this;
        this.http.post(this.route, this.data, this.options).pipe(
        //map(resp => resp.json())
        )
            .subscribe(function () {
        }, function (err) {
            console.log('There was an error inserting ressource ' + _this.route + '.', err);
        });
    };
    return SaveInsert;
}(Save));
export { SaveInsert };
