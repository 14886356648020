/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./harvest-v2.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./harvest-v2.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../services/application.service";
import * as i6 from "../../services/breadcrumb.service";
import * as i7 from "../../services/permissions.service";
var styles_HarvestV2Component = [i0.styles];
var RenderType_HarvestV2Component = i1.ɵcrt({ encapsulation: 0, styles: styles_HarvestV2Component, data: {} });
export { RenderType_HarvestV2Component as RenderType_HarvestV2Component };
function View_HarvestV2Component_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "iframe", [["id", "harvestList2"]], [[8, "src", 5], [4, "height", "px"]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; var currVal_1 = _co.innerHeight; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_HarvestV2Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HarvestV2Component_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pShowHarvest; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HarvestV2Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-harvest-v2", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_HarvestV2Component_0, RenderType_HarvestV2Component)), i1.ɵdid(1, 245760, null, 0, i3.HarvestV2Component, [i4.DomSanitizer, i5.ApplicationService, i6.BreadcrumbService, i7.PermissionsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HarvestV2ComponentNgFactory = i1.ɵccf("app-harvest-v2", i3.HarvestV2Component, View_HarvestV2Component_Host_0, {}, {}, []);
export { HarvestV2ComponentNgFactory as HarvestV2ComponentNgFactory };
