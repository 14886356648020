import * as tslib_1 from "tslib";
import { AbstractObject } from '../technical-aspects/abstract-object';
import { CultureEO } from '../entity-objects/culture-eo';
import { Constants } from './constants';
import { TagBuilder } from './helper/tag-builder';
var Culture = /** @class */ (function (_super) {
    tslib_1.__extends(Culture, _super);
    function Culture() {
        return _super.call(this) || this;
    }
    // transferTemplates() {
    //     this.templates.forEach(element => {
    //         element.checkedForRecord = element.active;
    //     });
    //     let records = this.daoRecords.getRecordsForCulture(this.id, true);
    //     records.forEach(element => {
    //         let record = <Record>element;
    //         if (record.getYear() >= this.myApplication.year) {
    //             record.createToDos(this.templates);
    //         }
    //     });
    //     this.myApplication.messageDTO.setSuccessWithId('TemplatesTransferInfo', this.myApplication.year);
    // }
    // transferTemplate(template: Template) {
    //     template.checkedForRecord = template.active;
    //     let templates: Array<Template> = new Array();
    //     templates.push(template);
    //     let records = this.daoRecords.getRecordsForCulture(this.id, true);
    //     records.forEach(element => {
    //         let record = <Record>element;
    //         if (record.getYear() >= this.myApplication.year) {
    //             record.createToDos(templates);
    //         }
    //     });
    //     this.myApplication.messageDTO.setSuccessWithId('TemplateTransferInfo', this.myApplication.year);
    // }
    Culture.prototype.hasCultureInfoWarnings = function () {
        if ((this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seed <= 0) ||
            this.grow < 1 ||
            this.harvest < 1 ||
            this.plantDistance <= 0 ||
            this.rowDistance <= 0 ||
            this.numRows <= 0 ||
            this.hasQuickpotWarnings() ||
            this.hasPlantBoxesWarnings()) {
            return true;
        }
        else {
            return false;
        }
    };
    Culture.prototype.hasAnbauErnteWarnings = function () {
        if (
        // (this.neededHarvestQuantity <= 0 &&
        //     !this.alternativeDeliverySelected) ||
        (this.numOfDeliveryWeeks <= 0 &&
            this.alternativeDeliverySelected)) {
            return true;
        }
        else {
            return false;
        }
    };
    Culture.prototype.hasWarnings = function () {
        if (this.hasCultureInfoWarnings() ||
            this.hasAnbauErnteWarnings()) {
            return true;
        }
        else {
            return false;
        }
    };
    Culture.prototype.getWarnings = function () {
        var warnings = new Array();
        warnings = warnings.concat(this.getAnbauErnteWarnings(), this.getCultureInfoWarnings());
        return warnings;
    };
    Culture.prototype.getAnbauErnteWarnings = function () {
        var warnings = new Array();
        // if (this.neededHarvestQuantity <= 0 &&
        //     !this.alternativeDeliverySelected) {
        //     warnings.push(this.myApplication.getText('FM.neededHarvestQuantityIsZero'));
        // }
        if (this.numOfDeliveryWeeks <= 0 &&
            this.alternativeDeliverySelected) {
            warnings.push(this.myApplication.getText('FM.numDeliveryWeeksIsZero'));
        }
        return warnings;
    };
    Culture.prototype.getCultureInfoWarnings = function () {
        var warnings = new Array();
        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seed <= 0) {
            warnings.push(this.myApplication.getText('FM.seedIsZero'));
        }
        if (this.plantDistance <= 0) {
            warnings.push(this.myApplication.getText('FM.plantDistanceIsZero'));
        }
        if (this.rowDistance <= 0) {
            warnings.push(this.myApplication.getText('FM.rowDistanceIsZero'));
        }
        if (this.numRows <= 0) {
            warnings.push(this.myApplication.getText('FM.numRowsIsZero'));
        }
        if (this.grow < 1) {
            warnings.push(this.myApplication.getText('FM.growIsZero'));
        }
        if (this.harvest < 1) {
            warnings.push(this.myApplication.getText('FM.numHarvestWeeksIsZero'));
        }
        if (this.hasQuickpotWarnings()) {
            warnings.push(this.myApplication.getText('FM.QuickPotInfosMissing'));
        }
        if (this.hasPlantBoxesWarnings()) {
            warnings.push(this.myApplication.getText('FM.BoxInfoMissing'));
        }
        return warnings;
    };
    Culture.prototype.hasQuickpotWarnings = function () {
        if (this.cultureType != Constants.CULTURE_TYPE_ANZUCHT) {
            return false;
        }
        if (this.quickpotSize <= 0 || this.seedsPerQuickpot <= 0) {
            return true;
        }
        else {
            return false;
        }
    };
    Culture.prototype.hasPlantBoxesWarnings = function () {
        if (this.cultureType != Constants.CULTURE_TYPE_PFLANZKAUF) {
            return false;
        }
        if (this.plantsPerBox <= 0) {
            return true;
        }
        else {
            return false;
        }
    };
    Culture.prototype.addTags = function (tags) {
        if (this.cultureEO.tags != '') {
            this.cultureEO.tags = this.cultureEO.tags.toString() + ' ' + tags.toString();
        }
        else {
            this.cultureEO.tags = tags.toString();
        }
        this._tagList = new TagBuilder().buildTags(this.cultureEO.tags);
    };
    Object.defineProperty(Culture.prototype, "tagList", {
        get: function () {
            if (!this._tagList) {
                this._tagList = new TagBuilder().buildTags(this.cultureEO.tags);
            }
            return this._tagList;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "plantsPerBox", {
        get: function () {
            return this.cultureEO.plantsPerBox;
        },
        set: function (value) {
            this.cultureEO.plantsPerBox = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "tags", {
        get: function () {
            return this.cultureEO.tags;
        },
        set: function (value) {
            this.cultureEO.tags = '';
            this.addTags(value.toString());
        },
        enumerable: true,
        configurable: true
    });
    Culture.prototype.deleteChildren = function () {
        var _this = this;
        if (this.isDeletable()) {
            this.sorts.forEach(function (element) {
                _this.daoSorts.deleteObject(element);
            });
            this.templates.forEach(function (element) {
                _this.daoTemplate.deleteObject(element);
            });
        }
    };
    Culture.prototype.copy = function () {
        var newInstance = this.daoCultures.createNewInstance();
        newInstance.name = this.name + ' (' + this.myApplication.getTextSimple('Copy') + ')';
        newInstance.consumerType = this.consumerType;
        newInstance.cultureType = this.cultureType;
        newInstance.harvestMethod = this.harvestMethod;
        newInstance.harvestUnit = this.harvestUnit;
        newInstance.plantDistance = this.plantDistance;
        newInstance.rowDistance = this.rowDistance;
        newInstance.plantDeepth = this.plantDeepth;
        newInstance.numRows = this.numRows;
        newInstance.quickpotSize = this.quickpotSize;
        newInstance.seedsPerQuickpot = this.seedsPerQuickpot;
        newInstance.harvestQuantity = this.harvestQuantity;
        newInstance.neededHarvestQuantity = this.neededHarvestQuantity;
        newInstance.safety = this.safety;
        newInstance.color = this.basicService.randomColor();
        newInstance.note = this.note;
        newInstance.seed = this.seed;
        newInstance.grow = this.grow;
        newInstance.harvest = this.harvest;
        newInstance.averageQuantity = this.averageQuantity;
        newInstance.tags = this.tags;
        newInstance.alternativeDeliverySelected = this.alternativeDeliverySelected;
        newInstance.plantsPerBox = this.plantsPerBox;
        newInstance.seedRate = this.seedRate;
        newInstance.numOfDeliveryWeeks = this.numOfDeliveryWeeks;
        this.copyTemplates(newInstance);
        newInstance.copySorts(this);
        this.myApplication.save();
        return newInstance;
    };
    Culture.prototype.copyTemplates = function (culture) {
        this.templates.forEach(function (template) {
            if (!template.isPredefined()) {
                var newTemplate = template.clone();
                newTemplate.parentId = culture.id;
                //culture.templates.push(newTemplate); geht von ganz alleine
            }
        });
    };
    Object.defineProperty(Culture.prototype, "templates", {
        get: function () {
            if (!this._templates) {
                this.buildTemplates();
            }
            return this._templates;
        },
        set: function (value) {
            this._templates = value;
        },
        enumerable: true,
        configurable: true
    });
    Culture.prototype.isDeletable = function () {
        if (this.daoRecords.getNumRecordsForCulture(this.id, true) > 0) {
            return false;
        }
        // for (let i = 0; i < this.sorts.length; i++) {
        //     if (!this.sorts[i].isDeleteable()) {
        //         return false;
        //     }
        // }
        return true;
    };
    Culture.prototype.getSortNames = function () {
        var sortNames = new Array();
        this.sorts.forEach(function (sort) {
            sortNames.push(sort.name);
        });
        return sortNames;
    };
    Culture.prototype.initLabels = function () {
        // this.harvestQuantityLabel = 'Erwartete Erntemenge'
        // if (this.harvestMethod != Constants.HARVEST_METHOD_ABSOLUT) {
        //     this.harvestQuantityLabel = this.harvestQuantityLabel + ' ' + this.harvestMethodText;
        // }
        // this.harvestQuantityLabel = this.harvestQuantityLabel + ' in ' + this.harvestUnitText;
        // this.unitLabel = 'Benötigte Erntemenge pro Anteil';
        // if (this.harvestUnit != Constants.UNIT_PIECE) {
        //     this.unitLabel = this.unitLabel + ' in ' + this.harvestUnitText;
        // }
    };
    Culture.prototype.update = function () {
        var _this = this;
        this.templates = null;
        this.sorts = new Array();
        this.daoSorts.objects.forEach(function (element) {
            var sort = element;
            if (sort.cultureId == _this.id) {
                _this.sorts.push(sort);
            }
        });
        this.sorts.sort(function (one, two) {
            return one.name.localeCompare(two.name.toString());
        });
        this.sortTemplates();
    };
    Culture.prototype.buildTemplates = function () {
        var _this = this;
        this._templates = new Array();
        this.daoTemplate.removeObserver(this); //kein update jetzt bitte
        this.daoTemplate.objects.forEach(function (element) {
            var template = element;
            if (template.parentId == _this.id) {
                template.addObserver(_this);
                _this._templates.push(template);
            }
        });
        this.createPredefinedTemplateAnzuchtIfNeeded();
        this.createPredefinedTemplatePflanzungIfNeeded();
        this.createPredefinedTemplateAussaatIfNeeded();
        this.sortTemplates();
        this.daoTemplate.addObserver(this);
    };
    Culture.prototype.sortTemplates = function () {
        if (this._templates) {
            this._templates = this._templates.sort(function (one, two) {
                return one.type.localeCompare(two.type.toString());
            });
        }
    };
    Culture.prototype.createPredefinedTemplate = function (predefType) {
        var template = this.daoTemplate.createNewInstance();
        template.parentId = this.id;
        template.predefType = predefType;
        template.type = this.myApplication.getText('ToDo.type.' + predefType);
        return template;
    };
    Culture.prototype.createPredefinedTemplateAussaatIfNeeded = function () {
        if (this.cultureType == Constants.CULTURE_TYPE_DIREKTSAAT && !this.predefinedTemplateExists(Constants.TODO_TYPE_AUSSAAT)) {
            var template = this.createPredefinedTemplate(Constants.TODO_TYPE_AUSSAAT);
            template.color = Constants.COLOR_PLANTED;
            template.timeReference = Constants.TEMPLATE_TIME_REF_PFLANZUNG;
            this._templates.push(template);
        }
    };
    Culture.prototype.createPredefinedTemplatePflanzungIfNeeded = function () {
        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && !this.predefinedTemplateExists(Constants.TODO_TYPE_PFLANZUNG)
            || this.cultureType == Constants.CULTURE_TYPE_PFLANZKAUF && !this.predefinedTemplateExists(Constants.TODO_TYPE_PFLANZUNG)) {
            var template = this.createPredefinedTemplate(Constants.TODO_TYPE_PFLANZUNG);
            template.color = Constants.COLOR_PLANTED;
            template.timeReference = Constants.TEMPLATE_TIME_REF_PFLANZUNG;
            this._templates.push(template);
        }
    };
    Culture.prototype.createPredefinedTemplateAnzuchtIfNeeded = function () {
        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && !this.predefinedTemplateExists(Constants.TODO_TYPE_ANZUCHT)) {
            var template = this.createPredefinedTemplate(Constants.TODO_TYPE_ANZUCHT);
            template.color = Constants.COLOR_ANZUCHT;
            template.timeReference = Constants.TEMPLATE_TIME_REF_ANZUCHT;
            this._templates.push(template);
        }
    };
    Culture.prototype.predefinedTemplateExists = function (predefType) {
        for (var i = 0; i < this._templates.length; i++) {
            if (this._templates[i].predefType == predefType) {
                return true;
            }
        }
        return false;
    };
    Culture.prototype.deletePredefinedTemplates = function () {
        this.daoTemplate.removeObserver(this);
        for (var i = 0; i < this.templates.length; i++) {
            if (this.templates[i].predefType == Constants.TODO_TYPE_ANZUCHT ||
                this.templates[i].predefType == Constants.TODO_TYPE_AUSSAAT ||
                this.templates[i].predefType == Constants.TODO_TYPE_PFLANZUNG) {
                this.daoTemplate.deleteObject(this.templates[i]);
            }
        }
        this.daoTemplate.addObserver(this);
    };
    Culture.prototype.initialize = function () {
        this.eo = new CultureEO();
        this.daoSorts.addObserver(this);
        this.daoTemplate.addObserver(this);
    };
    Culture.prototype.setDefaultValues = function () {
        //this.name = '<' + this.myApplication.getTextSimple('newCulture') + '>';
        this.consumerType = 'S';
        this.harvestMethod = 'A';
        this.harvestUnit = 'S';
    };
    Culture.prototype.createSort = function () {
        var sort = this.daoSorts.createNewInstance();
        sort.cultureId = this.id;
        sort.name = '???';
        this.sorts.push(sort);
        this.sorts.sort(function (one, two) {
            return one.name.localeCompare(two.name.toString());
        });
        this.daoSorts.selectedObject = sort;
        sort.isNew = true;
        this.hasChanged();
        //this.myApplication.save();
        return sort;
    };
    Culture.prototype.copySorts = function (culture) {
        var _this = this;
        culture.sorts.forEach(function (sortToCopy) {
            _this.createSortBySort(sortToCopy);
        });
    };
    Culture.prototype.createSortBySort = function (sortIn) {
        var sort = this.daoSorts.createNewInstance();
        sort.cultureId = this.id;
        this.sorts.push(sort);
        this.sorts.sort(function (one, two) {
            return one.name.localeCompare(two.name.toString());
        });
        this.daoSorts.selectedObject = sort;
        sort.name = sortIn.name;
        sort.useOwnPeriods = sortIn.useOwnPeriods;
        sort.seed = sortIn.seed;
        sort.grow = sortIn.grow;
        sort.harvest = sortIn.harvest;
        sort.useOwnDistances = sortIn.useOwnDistances;
        sort.plantDistance = sortIn.plantDistance;
        sort.rowDistance = sortIn.rowDistance;
        sort.numRows = sortIn.numRows;
        sort.note = sortIn.note;
        sort.useOwnDetails = sortIn.useOwnDetails;
        sort.seedRate = sortIn.seedRate;
        this.hasChanged();
        return sort;
    };
    Culture.prototype.createTemplate = function () {
        var template = this.daoTemplate.createNewInstance();
        template.parentId = this.id;
        template.type = '<' + this.myApplication.getTextSimple('newTemplate') + '>';
        template.selected = true;
        this.templates = null;
        template.isNew = true;
        this.hasChanged();
        this.myApplication.save();
        return template;
    };
    Culture.prototype.createTemplateByTemplate = function (model) {
        var template = this.createTemplate();
        template.adoptDataFrom(model);
        template.fromTemplate = true;
        this.hasChanged();
        this.myApplication.save();
        return template;
    };
    Culture.prototype.createTemplateByTemplateId = function (id) {
        var template = this.createTemplate();
        template.typeId = id;
        template.type = this.myApplication.getText(id);
        template.fromTemplate = true;
        this.hasChanged();
        this.myApplication.save();
        return template;
    };
    Object.defineProperty(Culture.prototype, "cultureEO", {
        get: function () {
            return this.eo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "name", {
        get: function () {
            return this.cultureEO.name;
        },
        set: function (value) {
            this.cultureEO.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "seed", {
        get: function () {
            return this.cultureEO.seed;
        },
        set: function (value) {
            if (value != this.cultureEO.seed) {
                this.cultureEO.seed = value;
                this.hasChanged();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "grow", {
        get: function () {
            return this.cultureEO.grow;
        },
        set: function (value) {
            if (value != this.cultureEO.grow) {
                this.cultureEO.grow = value;
                this.hasChanged();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "harvest", {
        get: function () {
            return this.cultureEO.harvest;
        },
        set: function (value) {
            if (value != this.cultureEO.harvest) {
                this.cultureEO.harvest = value;
                this.hasChanged();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "cultureType", {
        get: function () {
            return this.cultureEO.cultureType;
        },
        set: function (value) {
            if (this.cultureEO.cultureType != value) {
                this.cultureEO.cultureType = value;
                this.deletePredefinedTemplates();
                this.templates = null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "plantDistance", {
        get: function () {
            return this.cultureEO.plantDistance;
        },
        set: function (value) {
            this.cultureEO.plantDistance = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "rowDistance", {
        get: function () {
            return this.cultureEO.rowDistance;
        },
        set: function (value) {
            this.cultureEO.rowDistance = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "plantDeepth", {
        get: function () {
            return this.cultureEO.plantDeepth;
        },
        set: function (value) {
            this.cultureEO.plantDeepth = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "numRows", {
        get: function () {
            return this.cultureEO.numRows;
        },
        set: function (value) {
            this.cultureEO.numRows = Math.floor(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "quickpotSize", {
        get: function () {
            return this.cultureEO.quickpotSize;
        },
        set: function (value) {
            this.cultureEO.quickpotSize = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "quickpotName", {
        get: function () {
            return this.cultureEO.quickpotName;
        },
        set: function (value) {
            this.cultureEO.quickpotName = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "quickpotId", {
        get: function () {
            return this.cultureEO.quickpotId;
        },
        set: function (value) {
            this.cultureEO.quickpotId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "seedsPerQuickpot", {
        get: function () {
            return this.cultureEO.seedsPerQuickpot;
        },
        set: function (value) {
            this.cultureEO.seedsPerQuickpot = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "harvestQuantity", {
        get: function () {
            return this.cultureEO.harvestQuantity;
        },
        set: function (value) {
            this.cultureEO.harvestQuantity = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "neededHarvestQuantity", {
        get: function () {
            return this.cultureEO.neededHarvestQuantity;
        },
        set: function (value) {
            this.cultureEO.neededHarvestQuantity = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "safety", {
        get: function () {
            return this.cultureEO.safety;
        },
        set: function (value) {
            this.cultureEO.safety = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "color", {
        get: function () {
            return this.cultureEO.color;
        },
        set: function (value) {
            this.cultureEO.color = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "note", {
        get: function () {
            return this.cultureEO.note;
        },
        set: function (value) {
            this.cultureEO.note = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "consumerType", {
        get: function () {
            return this.cultureEO.consumerType;
        },
        set: function (value) {
            this.cultureEO.consumerType = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "consumerTypeT", {
        get: function () {
            return '';
            // let lconsumerType = this.consumerType;
            // if (lconsumerType == 'S') {
            //     return 'Starkzehrer';
            // } else if (lconsumerType == 'M') {
            //     return 'Mittelzehrer';
            // } else if (lconsumerType == 'W') {
            //     return 'Schwachzehrer';
            // }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "harvestMethod", {
        get: function () {
            return this.cultureEO.harvestMethod;
        },
        set: function (value) {
            this.cultureEO.harvestMethod = value;
            if (this.cultureEO.harvestMethod == Constants.HARVEST_METHOD_ABSOLUT) {
                this.harvestUnit = Constants.UNIT_PIECE;
            }
            if (this.harvestMethod != Constants.HARVEST_METHOD_M2 && this.harvestUnit == Constants.UNIT_BUND) {
                this.harvestUnit = Constants.UNIT_PIECE;
            }
            this.initLabels();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "harvestUnit", {
        get: function () {
            return this.cultureEO.harvestUnit;
        },
        set: function (value) {
            this.cultureEO.harvestUnit = value;
            // if (this.cultureEO.harvestUnit == Constants.UNIT_PIECE) {
            //     this.harvestUnitText = 'Stück';
            // } else if (this.cultureEO.harvestUnit == Constants.UNIT_GRAMM) {
            //     this.harvestUnitText = 'g';
            // } else if (this.cultureEO.harvestUnit == Constants.UNIT_KILOGRAMM) {
            //     this.harvestUnitText = 'kg';
            // } else {
            //     this.harvestUnitText = 'other';
            // }
            this.initLabels();
        },
        enumerable: true,
        configurable: true
    });
    Culture.prototype.getNumberOfActiveTemplates = function () {
        var num = 0;
        this.templates.forEach(function (element) {
            if (element.active == true) {
                num = num + 1;
            }
        });
        return num;
    };
    Culture.prototype.getNumberOfCheckedForRecordTemplates = function () {
        var num = 0;
        this.templates.forEach(function (element) {
            if (element.checkedForRecord == true) {
                num = num + 1;
            }
        });
        return num;
    };
    Culture.prototype.getSort = function (sortName) {
        for (var i = 0; i < this.sorts.length; i++) {
            var name1 = this.sorts[i].name;
            if (name1 && sortName && name1.trim() == sortName.trim()) {
                return this.sorts[i];
            }
        }
        return null;
    };
    Culture.prototype.calculateQuantity = function (lengthOnBed) {
        var quantity;
        quantity = (lengthOnBed * this.numRows * 100) / this.plantDistance;
        return quantity;
    };
    Culture.prototype.calculateLengthOnBed = function (quantity) {
        var lengthOnBed;
        lengthOnBed = (quantity * this.plantDistance) / (this.numRows * 100);
        return lengthOnBed;
    };
    Object.defineProperty(Culture.prototype, "numOfDeliveryWeeks", {
        get: function () {
            return this.cultureEO.numOfDeliveryWeeks;
        },
        set: function (value) {
            this.cultureEO.numOfDeliveryWeeks = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "averageQuantity", {
        get: function () {
            return this.cultureEO.averageQuantity;
        },
        set: function (value) {
            this.cultureEO.averageQuantity = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "alternativeDeliverySelected", {
        get: function () {
            return this.cultureEO.alternativeDeliverySelected;
        },
        set: function (value) {
            this.cultureEO.alternativeDeliverySelected = value;
            if (this.cultureEO.alternativeDeliverySelected) {
                this.numOfDeliveryWeeks = this.harvest;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Culture.prototype, "seedRate", {
        get: function () {
            return this.cultureEO.seedRate;
        },
        set: function (value) {
            this.cultureEO.seedRate = value;
        },
        enumerable: true,
        configurable: true
    });
    return Culture;
}(AbstractObject));
export { Culture };
