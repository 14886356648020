import * as tslib_1 from "tslib";
import { AbstractObject } from '../technical-aspects/abstract-object';
import { RepositoryEO } from '../entity-objects/repository-eo';
import { Constants } from './constants';
var Repository = /** @class */ (function (_super) {
    tslib_1.__extends(Repository, _super);
    function Repository() {
        var _this = _super.call(this) || this;
        _this.showInfoText = true;
        return _this;
    }
    Repository.prototype.update = function () {
    };
    Object.defineProperty(Repository.prototype, "repositoryEO", {
        // isExpired(): boolean { DEPRECATED
        //   if (this.package != Constants.PACKAGE_TEST) {
        //     return false;
        //   }
        //   let expireDate = new Date(this.repositoryEO.createdAndAgreedTS.toString());
        //   expireDate.setDate(expireDate.getDate() + 14);
        //   let today = new Date();
        //   let envExpireDate = new Date(environment.expireDate.toString());
        //   if (today.getTime() > expireDate.getTime() && today.getTime() >= envExpireDate.getTime()) {
        //     return true;
        //   }
        //   return false;
        // }
        get: function () {
            return this.eo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Repository.prototype, "createAndAgreedTs", {
        get: function () {
            return this.repositoryEO.createdAndAgreedTS;
        },
        set: function (value) {
            this.repositoryEO.createdAndAgreedTS = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Repository.prototype, "name", {
        get: function () {
            return this.repositoryEO.name;
        },
        set: function (value) {
            this.repositoryEO.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Repository.prototype, "infoText", {
        get: function () {
            if (this.showInfoText) {
                return this.repositoryEO.infoText;
            }
            else {
                return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Repository.prototype.closeInfoText = function () {
        this.showInfoText = false;
    };
    Object.defineProperty(Repository.prototype, "package", {
        get: function () {
            return this.repositoryEO.package;
        },
        set: function (value) {
            this.repositoryEO.package = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Repository.prototype, "partLimit", {
        get: function () {
            return this.repositoryEO.partLimit;
        },
        set: function (value) {
            this.repositoryEO.partLimit = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Repository.prototype, "system", {
        get: function () {
            return this.repositoryEO.system;
        },
        set: function (value) {
            this.repositoryEO.system = value;
            this.calculateBedDefaults();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Repository.prototype, "bedWidth", {
        get: function () {
            return this.repositoryEO.bedWidth;
        },
        set: function (value) {
            this.repositoryEO.bedWidth = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Repository.prototype, "bedLength", {
        get: function () {
            return this.repositoryEO.bedLength;
        },
        set: function (value) {
            this.repositoryEO.bedLength = value;
        },
        enumerable: true,
        configurable: true
    });
    Repository.prototype.initialize = function () {
        this.eo = new RepositoryEO();
    };
    Repository.prototype.calculateBedDefaults = function () {
        if (this.system == Constants.SYSTEM_SOLAWI) {
            this.bedWidth = 1.2;
            this.bedLength = 30;
        }
        else if (this.system == Constants.SYSTEM_MARKET_GARDENING) {
            this.bedWidth = 0.75;
            this.bedLength = 30;
        }
    };
    return Repository;
}(AbstractObject));
export { Repository };
