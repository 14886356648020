import { Component, OnInit, Input } from '@angular/core';
import { ApplicationService } from 'src/app/services/application.service';
import { FieldTextDTO } from 'src/app/app-core/dto/field-text-dto';


@Component({
  selector: 'app-number-unit-field',
  templateUrl: './number-unit-field.component.html',
  styleUrls: ['./number-unit-field.component.css']
})
export class NumberUnitFieldComponent implements OnInit {

  @Input() id: String;
  @Input() textId: String;
  @Input() object;
  @Input() valueName: String;
  @Input() unit: String;
  @Input() colsLabel: String = 'col-sm-4';
  @Input() colsField: String = 'col-sm-8';
  @Input() btnIcon: String;
  @Input() btnMethod: String;
  @Input() step: number = 1;
  @Input() btnObject;
  @Input() min: number = 0;
  @Input() ngbPopoverContent: String;
  @Input() ngbPopoverTitle: String;
  @Input() btnClass: String;
  @Input() unitClass: String;
  @Input() useWarningClass = false;
  @Input() usePrimaryClass = false;
  @Input() warningValue = 0;

  private _disable: boolean = false;
  public fieldDTO: FieldTextDTO;
  public btnDTO: FieldTextDTO;


  constructor(public myApplication: ApplicationService) {
  }

  public get disable(): boolean {
      return this._disable;
  }
  @Input()
  public set disable(value: boolean) {
    this._disable = value;
  }

  public get value(): string {
    if (!this.object[this.valueName.toString()]) {
      return '0';
    }
    let value = this.object[this.valueName.toString()].toString();
    value = value.replace('.', ',');
    return value;
  }
  public set value(value: string) {
    value = value.replace(new RegExp(',', 'g'), '.');
    let num = Number(value);
    if ((!isNaN(num)) && num >= this.min) {
      this.object[this.valueName.toString()] = num;
    }
  }

  onBlur(event) {
    event.target.value = this.value;
  }

  getWarningClass() {
    if (this.usePrimaryClass) {
      return 'border-primary'
    } else {
      if (this.useWarningClass && this.object[this.valueName.toString()] == this.warningValue) {
        return 'border-warning'
      }
    }
  }

  keyup(event) {
    if (event.key == ',') {
      event.key = '.';
      this.object[this.valueName.toString()] += event.target.value + '.';
    }
  }

  ngOnInit() {
    if (!this.textId) {
      this.textId = this.id;
    }
    if (!this.id) {
      this.id = this.valueName;
    }
    this.fieldDTO = this.myApplication.textDTOs.get(this.textId);
    if (!this.fieldDTO) {
      this.fieldDTO = new FieldTextDTO(this.textId, this.textId, '');
    }
    this.btnDTO = this.myApplication.textDTOs.get(this.textId + '.btnTooltip');

    if (!this.btnClass) {
      this.btnClass = this.myApplication.theme.btnOutline;
    }
  }

  onClick() {
    if (this.btnObject) {
      this.btnObject[this.btnMethod.toString()]();
    }
    else {
      this.object[this.btnMethod.toString()]();
    }
  }
}
