import * as tslib_1 from "tslib";
import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';
var RepositoryEO = /** @class */ (function (_super) {
    tslib_1.__extends(RepositoryEO, _super);
    function RepositoryEO() {
        var _this = _super.call(this) || this;
        _this.name = '';
        _this.package = '';
        _this.partLimit = 0;
        _this.system = '1';
        _this.bedWidth = 0.75;
        _this.bedLength = 30;
        _this.firstName = '';
        _this.lastName = '';
        _this.agbAndDp = '';
        _this.createdAndAgreedTS = '';
        _this.infoText = null;
        return _this;
    }
    return RepositoryEO;
}(AbstractEntityObject));
export { RepositoryEO };
