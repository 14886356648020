/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./company.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./company.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../services/repository.service";
import * as i5 from "../../services/dao.service";
import * as i6 from "../../services/application.service";
import * as i7 from "../../services/user-settings.service";
import * as i8 from "../../services/permissions.service";
import * as i9 from "../../services/breadcrumb.service";
import * as i10 from "../../services/authentication.service";
var styles_CompanyComponent = [i0.styles];
var RenderType_CompanyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CompanyComponent, data: {} });
export { RenderType_CompanyComponent as RenderType_CompanyComponent };
export function View_CompanyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "iframe", [["id", "company"]], [[8, "src", 5], [4, "height", "px"]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; var currVal_1 = _co.innerHeight; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_CompanyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-company", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_CompanyComponent_0, RenderType_CompanyComponent)), i1.ɵdid(1, 245760, null, 0, i2.CompanyComponent, [i3.DomSanitizer, i4.RepositoryService, i5.DaoService, i6.ApplicationService, i7.UserSettingsService, i8.PermissionsService, i9.BreadcrumbService, i10.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CompanyComponentNgFactory = i1.ɵccf("app-company", i2.CompanyComponent, View_CompanyComponent_Host_0, {}, {}, []);
export { CompanyComponentNgFactory as CompanyComponentNgFactory };
