import { BasicService } from 'src/app/services/basic.service';
import { ApplicationService } from 'src/app/services/application.service';
import { Constants } from './constants';

export class CalculatorQuantityWithBedM {

    constructor(protected numRows: number, protected lengthOnBed: number, protected plantDistance: number, protected basicService: BasicService,
        protected myApplication: ApplicationService, protected seedRate: number, protected cultureType: String) {

    }

    public calculate(): number {
        let numPlants: number = 0;
        if (this.plantDistance <= 0) {
            if (this.myApplication) {
                this.myApplication.messageDTO.setErrorWithId('FM.plantDistanceIsNotValid');
            }
            return 0;
        }
        numPlants = this.basicService.ownCeil(this.lengthOnBed * this.numRows * 100 / this.plantDistance);

        //und jetzt noch fuer Anzucht die Keimrate beruecksichtigen
        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seedRate < 100) {
            numPlants = numPlants / this.seedRate * 100;
            numPlants = Math.ceil(numPlants);
        }
        return numPlants;
    }
}