import { Injectable } from '@angular/core';
import { DAO } from '../app-core/technical-aspects/dao';
import { Listener } from '../app-core/technical-aspects/listener';
import { AuthenticationService } from './authentication.service';
import { Http } from '@angular/http';
import { ApplicationService } from './application.service';
import { DAOAreas } from '../app-core/dao/dao-areas';
import { DAOBeds } from '../app-core/dao/dao-beds';
import { DAOParts } from '../app-core/dao/dao-parts';
import { DAOCultures } from '../app-core/dao/dao-cultures';
import { DAORecords } from '../app-core/dao/dao-records';
import { DAOSorts } from '../app-core/dao/dao-sorts';
import { BasicService } from './basic.service';
import { DAORepository } from '../app-core/dao/dao-repository';
import { DAOBedAssigns } from '../app-core/dao/dao-bed-assigns';
import { DAOTemplate } from '../app-core/dao/dao-template';
import { DAOToDos } from '../app-core/dao/dao-todos';
import { DAOPersons } from '../app-core/dao/dao-persons';
import { DAOSeeds } from '../app-core/dao/dao-seeds';
import { DAOFeedback } from '../app-core/dao/dao-feedback';
import { DAOSortSeeds } from '../app-core/dao/dao-sort-seeds';
import { interval } from 'rxjs';
import { Save } from '../app-core/technical-aspects/save';
import { DAOHarvest } from '../app-core/dao/dao-harvest';
import { RepositoryService } from './repository.service';
import { PartsService } from './parts.service';


@Injectable({
  providedIn: 'root'
})
export class DaoService implements Listener {

  public filesRead = 14;
  public allRead = true;
  private numRead = 14;
  private allDAOs: Array<DAO>;
  private initialized = false;
  public saveQueue = new Array<Save>();
  private inProgress = false;


  constructor(private authenticationService: AuthenticationService,
    private http: Http,
    private myApplication: ApplicationService,
    private basicService: BasicService, public repoService: RepositoryService, public partsService: PartsService) {
    if (myApplication) {
      myApplication.setDAOService(this);
    }

    const saveCounter = interval(3000);
    // Subscribe to begin publishing values
    saveCounter.subscribe(n =>
      this.saveToBackend());
  }

  public refreshDAOs(daos: Array<DAO>) {
    this.allRead = false;
    this.filesRead - daos.length;
    daos.forEach(dao => {
      dao.refresh();
    });
  }

  public saveToBackend(force?: boolean) {
    if (!this.inProgress || force == true) {
      this.inProgress = true;

      let saveQueue = this.saveQueue;
      this.saveQueue = new Array<Save>();

      saveQueue.forEach(save => {
        save.save();
      });

      this.inProgress = false;
    }
  }

  public deleteRepository() {
    this.allDAOs.forEach(dao => {
      if (dao.name != 'feedback' && dao.name != 'customer') {
        dao.objects.forEach(object => {
          dao.deleteObject(object);
        });
      }
    });
  }

  public getDAO(name: String): DAO {
    let found = null;
    if (!this.allDAOs) {
      return null;
    }
    this.allDAOs.forEach(element => {
      if (element.name == name) {
        found = element;
      }
    });
    return found;
  }

  update() {
    this.filesRead = this.filesRead + 1;
    if (this.filesRead == this.numRead) {
      this.allDAOs.forEach(dao => {
        dao.assembleObjects();
      });

      this.allDAOs.forEach(dao => {
        dao.objects.forEach(object => {
          object.setInit(false);
          object.initDone();
        });
      });
      this.allRead = true;
      //this.myApplication.repository = (<DAORepository>this.getDAO('repository')).getRepository();
    }
  }

  reset() {
    this.initialized = false;
    this.filesRead = 0;
    this.allRead = false;
    this.allDAOs = null;
  }

  save() {
    this.allDAOs.forEach(element => {
      element.save();
    });
  }

  initialize() {
    if (!this.initialized) {
      this.allDAOs = new Array<DAO>();

      this.initialized = true;

      this.initializeDAOs();
    }
  }

  private initializeDAOs() {
    let dao: DAO = new DAOAreas(this.http,
      this.authenticationService, 'areas', this.myApplication, this, this.basicService);
    this.assembleDAO(dao);

    dao = new DAOBeds(this.http,
      this.authenticationService, 'beds', this.myApplication, this, this.basicService);
    this.assembleDAO(dao);

    dao = new DAOBedAssigns(this.http,
      this.authenticationService, 'bedAssigns', this.myApplication, this, this.basicService);
    this.assembleDAO(dao);

    dao = new DAOCultures(this.http,
      this.authenticationService, 'cultures', this.myApplication, this, this.basicService);
    this.assembleDAO(dao);

    dao = new DAOParts(this.http,
      this.authenticationService, 'parts', this.myApplication, this, this.basicService);
    this.assembleDAO(dao);

    dao = new DAORecords(this.http,
      this.authenticationService, 'records', this.myApplication, this, this.basicService, this.partsService);
    this.assembleDAO(dao);

    dao = new DAOSorts(this.http,
      this.authenticationService, 'sorts', this.myApplication, this, this.basicService);
    this.assembleDAO(dao);

    dao = new DAORepository(this.http,
      this.authenticationService, 'repository', this.myApplication, this, this.basicService);
    this.assembleDAO(dao);

    dao = new DAOTemplate(this.http,
      this.authenticationService, 'template', this.myApplication, this, this.basicService);
    this.assembleDAO(dao);

    dao = new DAOToDos(this.http,
      this.authenticationService, 'todos', this.myApplication, this, this.basicService);
    this.assembleDAO(dao);

    dao = new DAOPersons(this.http,
      this.authenticationService, 'persons', this.myApplication, this, this.basicService);
    this.assembleDAO(dao);

    dao = new DAOSeeds(this.http,
      this.authenticationService, 'seeds', this.myApplication, this, this.basicService);
    this.assembleDAO(dao);

    // dao = new DAOCustomer(this.http,
    //   this.authenticationService, 'customer', this.myApplication, this, this.basicService);
    // this.assembleDAO(dao);

    dao = new DAOSortSeeds(this.http,
      this.authenticationService, 'sortSeeds', this.myApplication, this, this.basicService);
    this.assembleDAO(dao);

    dao = new DAOHarvest(this.http,
      this.authenticationService, 'harvests', this.myApplication, this, this.basicService);
    this.assembleDAO(dao);

    dao = new DAOFeedback(this.http,
      this.authenticationService, 'feedback', this.myApplication, this, this.basicService);
    this.allDAOs.push(dao);
    //not relevant
  }

  private assembleDAO(dao: DAO) {
    dao.addObserver(this);
    this.allDAOs.push(dao);
    // dao.readData();
  }
}
