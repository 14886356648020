import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StartComponent } from './ui-components/start/start.component';
import { DashboardComponent } from './ui-components/dashboard/dashboard.component';
import { SeedInventoryComponent } from './ui-components/seed-inventory/seed-inventory.component';
import { LicenseComponent } from './ui-components/app-info/license/license.component';
import { StatisticComponent } from './ui-components/statistic/statistic.component';
import { CompanyComponent } from './ui-components/company/company.component';
import { ConsumerComponent } from './ui-components/consumer/consumer.component';
import { GardeningCalComponent } from './ui-components/gardening-cal/gardening-cal.component';
import { Areasv2Component } from './ui-components/areasv2/areasv2.component';
import { CalendarComponent } from './ui-components/calendar/calendar.component';
import { DeliveryPlanComponent } from './ui-components/delivery-plan/delivery-plan.component';
import { StorageComponent } from './ui-components/storage/storage.component';
import { HarvestV2Component } from './ui-components/harvest-v2/harvest-v2.component';
import { DeliveryComponent } from './ui-components/delivery/delivery.component';
import { CulturesV2Component } from './ui-components/cultures-v2/cultures-v2.component';
import { UnsubscribeComponent } from './ui-components/unsubscribe/unsubscribe.component';


export const appRoutes: Routes = [
  { path: 'dashboard', component: DashboardComponent },
  { path: 'dashboard/lang/:langu', component: DashboardComponent },
  { path: 'start', component: StartComponent },
  { path: 'start/:token', component: StartComponent },
  { path: 'start/:id', component: StartComponent },
  { path: 'start', component: StartComponent },
  { path: 'register', component: StartComponent },
  { path: 'register/id', component: StartComponent },
  { path: 'invitation/:id', component: StartComponent },
  { path: 'userActivation/:id', component: StartComponent },
  { path: 'gardening-cal', component: GardeningCalComponent },
  { path: 'calendar', component: CalendarComponent },
  { path: 'areasv2', component: Areasv2Component },
  { path: 'seed-inventory', component: SeedInventoryComponent },
  { path: 'culturesV2', component: CulturesV2Component },
  { path: 'culturesV2/:cultureId', component: CulturesV2Component },
  { path: 'license', component: LicenseComponent },
  { path: 'statistic', component: StatisticComponent },
  { path: 'company', component: CompanyComponent },
  { path: 'consumer', component: ConsumerComponent },
  { path: 'harvestListV2', component: HarvestV2Component },
  { path: 'deliveryV2', component: DeliveryComponent },
  { path: 'deliveryV2/deliveries/:id', component: DeliveryComponent },
  { path: 'deliveryPlan', component: DeliveryPlanComponent },
  { path: 'storage', component: StorageComponent },
  { path: 'unsubscribe', component: UnsubscribeComponent },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '**', component: StartComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
  exports: [RouterModule]
})

export class AppRoutingModule { }

