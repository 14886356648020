import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-license-ngbootstrap',
  templateUrl: './license-ngbootstrap.component.html',
  styleUrls: ['./license-ngbootstrap.component.css']
})
export class LicenseNgbootstrapComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
