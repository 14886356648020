import { DAO } from '../technical-aspects/dao';
import { Template } from '../business-logic/template';
import { DAOToDos } from './dao-todos';

export class DAOTemplate extends DAO {
    
    protected createInstance() {
        let newInstance = new Template();
        newInstance.daoToDos = <DAOToDos>this.daoService.getDAO('todos');
        newInstance.daoTemplate = this;
        return newInstance;
    }
}
