import * as tslib_1 from "tslib";
import { DAO } from '../technical-aspects/dao';
import { Repository } from '../business-logic/repository';
var DAORepository = /** @class */ (function (_super) {
    tslib_1.__extends(DAORepository, _super);
    function DAORepository() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DAORepository.prototype.createInstance = function () {
        var newInstance = new Repository();
        return newInstance;
    };
    DAORepository.prototype.getRepository = function () {
        var repository = this.getObjectById(this.authenticationService.loginData.id);
        return repository;
    };
    return DAORepository;
}(DAO));
export { DAORepository };
