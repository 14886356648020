import * as tslib_1 from "tslib";
import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';
var SortSeedEO = /** @class */ (function (_super) {
    tslib_1.__extends(SortSeedEO, _super);
    function SortSeedEO() {
        var _this = _super.call(this) || this;
        _this.sortId = '';
        _this.manufacturer = '';
        _this.tkg = 0;
        _this.manufacturingYear = 0;
        _this.seedValue = 0;
        _this.seedUnit = 'a';
        _this.otherUnit = false;
        return _this;
    }
    return SortSeedEO;
}(AbstractEntityObject));
export { SortSeedEO };
