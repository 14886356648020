var SortSeedSet = /** @class */ (function () {
    function SortSeedSet(daoSortSeeds, sortId, myApplication) {
        this.daoSortSeeds = daoSortSeeds;
        this.sortId = sortId;
        this.myApplication = myApplication;
        this._all = false;
        this._otherUnit = false;
        this._sortSeedsTkg = new Array();
        this._sortSeedsOtherUnit = new Array();
    }
    SortSeedSet.prototype.yearExists = function (year) {
        for (var i = 0; i < this.sortSeeds.length; i++) {
            if (this.sortSeeds[i].manufacturingYear == year) {
                return true;
            }
        }
        return false;
    };
    Object.defineProperty(SortSeedSet.prototype, "all", {
        get: function () {
            return this._all;
        },
        set: function (value) {
            if (value != this._all) {
                this._all = value;
                this.buildSortSeedsFiltered();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SortSeedSet.prototype, "sortSeeds", {
        get: function () {
            var _this = this;
            if (!this._sortSeeds) {
                this._sortSeeds = this.daoSortSeeds.getSortSeedsForSort(this.sortId);
                this._sortSeeds = this.sortSortSeeds(this._sortSeeds);
                this._sortSeeds.forEach(function (sortSeed) {
                    if (sortSeed.otherUnit) {
                        _this._sortSeedsOtherUnit.push(sortSeed);
                    }
                    else {
                        _this._sortSeedsTkg.push(sortSeed);
                    }
                });
            }
            return this._sortSeeds;
        },
        set: function (value) {
            this._sortSeeds = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SortSeedSet.prototype, "sortSeedsTkg", {
        get: function () {
            var _this = this;
            if (!this._sortSeedsTkg) {
                this._sortSeeds = this.daoSortSeeds.getSortSeedsForSort(this.sortId);
                this._sortSeeds = this.sortSortSeeds(this._sortSeeds);
                this._sortSeeds.forEach(function (sortSeed) {
                    if (sortSeed.otherUnit) {
                        _this._sortSeedsOtherUnit.push(sortSeed);
                    }
                    else {
                        _this._sortSeedsTkg.push(sortSeed);
                    }
                });
            }
            return this._sortSeedsTkg;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SortSeedSet.prototype, "sortSeedsOtherUnit", {
        get: function () {
            var _this = this;
            if (!this._sortSeedsOtherUnit) {
                this._sortSeeds = this.daoSortSeeds.getSortSeedsForSort(this.sortId);
                this._sortSeeds = this.sortSortSeeds(this._sortSeeds);
                this._sortSeeds.forEach(function (sortSeed) {
                    if (sortSeed.otherUnit) {
                        _this._sortSeedsOtherUnit.push(sortSeed);
                    }
                    else {
                        _this._sortSeedsTkg.push(sortSeed);
                    }
                });
            }
            return this._sortSeedsOtherUnit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SortSeedSet.prototype, "sortSeedsFiltered", {
        get: function () {
            if (!this._sortSeedsFiltered) {
                this.buildSortSeedsFiltered();
            }
            return this._sortSeedsFiltered;
        },
        set: function (value) {
            this._sortSeedsFiltered = value;
        },
        enumerable: true,
        configurable: true
    });
    SortSeedSet.prototype.buildSortSeedsFiltered = function () {
        var _this = this;
        console.log('hier wird gefiltert');
        this._sortSeedsFiltered = new Array();
        this.sortSeeds.forEach(function (sortSeed) {
            if ((_this.all || sortSeed.manufacturingYear >= _this.myApplication.year) && _this.otherUnit == sortSeed.otherUnit) {
                _this._sortSeedsFiltered.push(sortSeed);
            }
        });
        this._sortSeedsFiltered = this.sortSortSeeds(this._sortSeedsFiltered);
    };
    SortSeedSet.prototype.getCompleteLength = function () {
        return this.sortSeeds.length;
    };
    SortSeedSet.prototype.createSortSeed = function () {
        var sortSeed = this.daoSortSeeds.createNewInstance();
        return this.createSortSeedDetail(sortSeed);
    };
    SortSeedSet.prototype.createSortSeedDetail = function (sortSeed) {
        this._sortSeeds = this.sortSortSeeds(this.sortSeeds);
        var year;
        sortSeed.sortId = this.sortId;
        if (this.sortSeeds.length > 0) {
            var lastObject = this.sortSeeds[this.sortSeeds.length - 1];
            year = lastObject.manufacturingYear;
            year++;
            sortSeed.manufacturingYear = year;
            sortSeed.manufacturer = lastObject.manufacturer;
            sortSeed.tkg = lastObject.tkg;
        }
        else {
            if (this.daoSortSeeds.objects.size > 0) {
                var anyObject = this.daoSortSeeds.objects.values().next().value;
                sortSeed.manufacturer = anyObject.manufacturer;
            }
            sortSeed.manufacturingYear = this.myApplication.year;
        }
        this.sortSeeds.push(sortSeed);
        this._sortSeeds = this.sortSortSeeds(this._sortSeeds);
        this.buildSortSeedsFiltered();
        this.myApplication.save();
        return sortSeed;
    };
    SortSeedSet.prototype.createSortSeedOtherUnit = function () {
        var sortSeed = this.daoSortSeeds.createNewInstance();
        sortSeed.otherUnit = true;
        return this.createSortSeedDetail(sortSeed);
    };
    SortSeedSet.prototype.getSortSeedForYear = function (year) {
        var sortSeed;
        for (var i = 0; i < this.sortSeeds.length; i++) {
            sortSeed = this.sortSeeds[i];
            if (sortSeed.manufacturingYear == year) {
                return sortSeed;
            }
        }
        return null;
    };
    SortSeedSet.prototype.getSortSeedBeforYear = function (year) {
        var sortSeed;
        var found;
        for (var i = 0; i < this.sortSeeds.length; i++) {
            sortSeed = this.sortSeeds[i];
            if (sortSeed.manufacturingYear < year) {
                if (!found || found.manufacturingYear < sortSeed.manufacturingYear) {
                    found = sortSeed;
                }
            }
        }
        return found;
    };
    SortSeedSet.prototype.deleteSortSeed = function (sortSeed) {
        this.daoSortSeeds.deleteObject(sortSeed);
        this._sortSeeds = null;
        this._sortSeedsFiltered = null;
    };
    SortSeedSet.prototype.sortSortSeeds = function (list) {
        return list.sort(function (one, two) {
            return one.manufacturingYear - two.manufacturingYear;
        });
    };
    Object.defineProperty(SortSeedSet.prototype, "otherUnit", {
        get: function () {
            return this._otherUnit;
        },
        set: function (value) {
            if (value != this._otherUnit) {
                this._otherUnit = value;
                this.buildSortSeedsFiltered();
            }
        },
        enumerable: true,
        configurable: true
    });
    return SortSeedSet;
}());
export { SortSeedSet };
