import { AbstractObject } from '../technical-aspects/abstract-object';
import { ToDoEO } from '../entity-objects/todo-eo';
import { Constants } from './constants';
import { Record } from './record';
import { DAORecords } from '../dao/dao-records';
import { DAOPersons } from '../dao/dao-persons';
import { Person } from './person';
import { Bed } from './bed';
import { DAOBeds } from '../dao/dao-beds';
import { DAOAreas } from '../dao/dao-areas';
import { Area } from './area';
import { DAOToDos } from '../dao/dao-todos';
import { FieldTextDTO } from '../dto/field-text-dto';
import { Template } from './template';


export class ToDo extends AbstractObject {

    daoRecords: DAORecords;
    daoPersons: DAOPersons;
    daoToDos: DAOToDos;
    daoBeds: DAOBeds;
    daoAreas: DAOAreas;
    private _series: boolean;
    public seriesRelevantAttributHasChanged: boolean;
    private _seriesMasterToDo: ToDo;
    private intervall: number;
    private intervallType: String;
    private toDoDate: Date;


    constructor() {
        super();
    }

    update() {

    }

    initialize() {
        this.eo = new ToDoEO();
    }

    public isDeleteable(): boolean {
        if (this.getRecord() && (this.cultureDatType == Constants.TODO_TYPE_ANZUCHT ||
            this.cultureDatType == Constants.TODO_TYPE_AUSSAAT ||
            this.cultureDatType == Constants.TODO_TYPE_PFLANZUNG)) {
            return false;
        } else {
            return true;
        }
    }

    public adoptDataFrom(model: Template) {
        this.name = model.name;
        this.note = model.note;
        this.timeReference = model.timeReference;
        this.timeType = model.timeType;
        this.quantity = model.quantity;
        this.quantityUnit = model.quantityUnit;
        this.time = model.time;
        this.timeUnit = model.timeUnit;
        this.color = model.color;
    }

    public isRelative() {
        if (this.toDoType == Constants.TODO_RELATIVE || this.toDoType == Constants.TODO_RECORD_DATES || this.toDoType == Constants.TODO_SERIES_RELATIVE) {
            return true;
        } else {
            return false;
        }
    }

    public usesNameQuantity() {
        if (this.type == 'Düngung' || this.type == 'Pflanzenschutz') {
            return true;
        } else {
            return false;
        }
    }

    protected get toDoEO(): ToDoEO {
        return <ToDoEO>this.eo;
    }

    public get toDoType(): String {
        return this.toDoEO.toDoType;
    }
    public set toDoType(value: String) {
        this.toDoEO.toDoType = value;
    }

    public get cultureDatType(): String {
        return this.toDoEO.cultureDatType;
    }
    public set cultureDatType(value: String) {
        this.toDoEO.cultureDatType = value;
    }

    public get type(): String {
        return this.toDoEO.type;
    }
    public set type(value: String) {
        if (value != this.toDoEO.type) {
            this.toDoEO.type = value;
            this.seriesRelevantAttributHasChanged = true;
        }
    }

    public get neededTime(): number {
        return this.toDoEO.neededTime;
    }
    public set neededTime(value: number) {
        this.toDoEO.neededTime = value;
    }

    public get name(): String {
        return this.toDoEO.name;
    }
    public set name(value: String) {
        this.toDoEO.name = value;
    }

    public get quantity(): number {
        return this.toDoEO.quantity;
    }
    public set quantity(value: number) {
        this.toDoEO.quantity = value;
    }

    public get quantityUnit(): String {
        return this.toDoEO.quantityUnit;
    }
    public set quantityUnit(value: String) {
        this.toDoEO.quantityUnit = value;
    }

    public get time(): number {
        return this.toDoEO.time;
    }
    public set time(value: number) {
        if (value != this.toDoEO.time) {
            if (this.isSeriesMaster()) {
                this.seriesRelevantAttributHasChanged = true;
            }
            this.toDoEO.time = value;
        }

    }

    public get timeUnit(): String {
        return this.toDoEO.timeUnit;
    }
    public set timeUnit(value: String) {
        if (value != this.toDoEO.timeUnit) {
            if (this.isSeriesMaster()) {
                this.seriesRelevantAttributHasChanged = true;
            }
            this.toDoEO.timeUnit = value;
        }
    }

    public get timeType(): String {
        return this.toDoEO.timeType;
    }
    public set timeType(value: String) {
        if (value != this.toDoEO.timeType) {
            if (this.isSeriesMaster()) {
                this.seriesRelevantAttributHasChanged = true;
            }
            this.toDoEO.timeType = value;
        }
    }

    public get timeReference(): String {
        return this.toDoEO.timeReference;
    }
    public set timeReference(value: String) {
        if (value != this.toDoEO.timeReference) {
            if (this.isSeriesMaster()) {
                this.seriesRelevantAttributHasChanged = true;
            }
            this.toDoEO.timeReference = value;
        }
    }

    public get color(): String {
        return this.toDoEO.color;
    }
    public set color(value: String) {
        this.toDoEO.color = value;
    }

    public get note(): String {
        return this.toDoEO.note;
    }
    public set note(value: String) {
        this.toDoEO.note = value;
    }

    public get parentType(): String {
        return this.toDoEO.parentType;
    }
    public set parentType(value: String) {
        this.toDoEO.parentType = value;
    }

    public get parentId(): String {
        return this.toDoEO.parentId;
    }
    public set parentId(value: String) {
        if (value != this.toDoEO.parentId) {
            if (this.isSeriesMaster()) {
                this.seriesRelevantAttributHasChanged = true;
            }
            this.toDoEO.parentId = value;
        }
    }
    public get parentIdArea(): String {
        if (this.parentType == Constants.PARENT_TYPE_AREA) {
            return this.toDoEO.parentId;
        }
        else {
            return '';
        }
    }
    public set parentIdArea(value: String) {
        if (value != this.toDoEO.parentId) {
            if (this.isSeriesMaster()) {
                this.seriesRelevantAttributHasChanged = true;
            }
            this.toDoEO.parentId = value;


            if (value != ' ') {
                this.parentType = Constants.PARENT_TYPE_AREA;
                if (this.toDoType == Constants.TODO_STANDALONE) {
                    this.toDoType = Constants.TODO_AREA;
                }
                else if (this.toDoType == Constants.TODO_SERIES_STANDALONE) {
                    this.toDoType = Constants.TODO_SERIES_AREA;
                }
            }
            else if (value == ' ') {
                this.parentType = ' ';
                this.bedIdStart = ' ';
                this.bedIdEnd = ' ';
                if (this.toDoType == Constants.TODO_AREA) {
                    this.toDoType = Constants.TODO_STANDALONE;
                }
                else if (this.toDoType == Constants.TODO_SERIES_AREA) {
                    this.toDoType = Constants.TODO_SERIES_STANDALONE;
                }
            }
        }
    }

    public get parentIdRecord(): String {
        if (this.parentType == Constants.PARENT_TYPE_RECORD) {
            return this.toDoEO.parentId;
        }
        else {
            return '';
        }
    }
    public set parentIdRecord(value: String) {
        if (value != this.toDoEO.parentId) {
            if (this.isSeriesMaster()) {
                this.seriesRelevantAttributHasChanged = true;
            }
            this.toDoEO.parentId = value;


            if (value != ' ') {
                this.parentType = Constants.PARENT_TYPE_RECORD;
                if (this.toDoType == Constants.TODO_STANDALONE) {
                    this.toDoType = Constants.TODO_RECORD_ABSOLUTE;
                }
                else if (this.toDoType == Constants.TODO_SERIES_STANDALONE) {
                    this.toDoType = Constants.TODO_SERIES_RECORD_ABSOLUTE;
                }
            }
            else if (value == ' ') {
                this.parentType = ' ';
                if (this.toDoType == Constants.TODO_RELATIVE || this.toDoType == Constants.TODO_RECORD_ABSOLUTE) {
                    this.toDoType = Constants.TODO_STANDALONE;
                }
                else if (this.toDoType == Constants.TODO_SERIES_RELATIVE || this.toDoType == Constants.TODO_SERIES_RECORD_ABSOLUTE) {
                    this.toDoType = Constants.TODO_SERIES_STANDALONE;
                }
            }
        }
    }
    public get series(): boolean {
        if (this.seriesMaster != '') {
            return true;
        }
        else {
            return this._series;
        }
    }
    public set series(value: boolean) {
        this._series = value;
        if (this.seriesMaster == '') {
            this.seriesMaster = this.id;
            if (this.series == true) {
                if (this.toDoType == Constants.TODO_RELATIVE) {
                    this.toDoType = Constants.TODO_SERIES_RELATIVE;
                }
                else if (this.toDoType == Constants.TODO_STANDALONE) {
                    this.toDoType = Constants.TODO_SERIES_STANDALONE;
                }
                else if (this.toDoType == Constants.TODO_BED) {
                    this.toDoType = Constants.TODO_SERIES_BED;
                }
                else if (this.toDoType == Constants.TODO_AREA) {
                    this.toDoType = Constants.TODO_SERIES_AREA;
                }
                else if (this.toDoType == Constants.TODO_RECORD_ABSOLUTE) {
                    this.toDoType = Constants.TODO_SERIES_RECORD_ABSOLUTE;
                }
            }
        }
        else {
            if (value == false) {
                this.seriesMaster = '';
            }
        }
    }

    public get endTime(): number {
        return this.toDoEO.endTime;
    }
    public set endTime(value: number) {
        if (value != this.toDoEO.endTime) {
            if (this.isSeriesMaster()) {
                this.seriesRelevantAttributHasChanged = true;
            }
            this.toDoEO.endTime = value;
        }
    }

    public get endTimeUnit(): String {
        return this.toDoEO.endTimeUnit;
    }
    public set endTimeUnit(value: String) {
        if (value != this.toDoEO.endTimeUnit) {
            if (this.isSeriesMaster()) {
                this.seriesRelevantAttributHasChanged = true;
            }
            this.toDoEO.endTimeUnit = value;
        }
    }

    public get endTimeType(): String {
        return this.toDoEO.endTimeType;
    }
    public set endTimeType(value: String) {
        if (value != this.toDoEO.endTimeType) {
            if (this.isSeriesMaster()) {
                this.seriesRelevantAttributHasChanged = true;
            }
            this.toDoEO.endTimeType = value;
        }
    }

    public get endTimeReference(): String {
        return this.toDoEO.endTimeReference;
    }
    public set endTimeReference(value: String) {
        if (value != this.toDoEO.endTimeReference) {
            if (this.isSeriesMaster()) {
                this.seriesRelevantAttributHasChanged = true;
            }
            this.toDoEO.endTimeReference = value;
        }
    }

    public get seriesMaster(): String {
        return this.toDoEO.seriesMaster;
    }
    public set seriesMaster(value: String) {
        this.toDoEO.seriesMaster = value;
    }

    public get seriesIntervall(): number {
        return this.toDoEO.seriesIntervall;
    }
    public set seriesIntervall(value: number) {
        if (value != this.toDoEO.seriesIntervall) {
            if (this.isSeriesMaster()) {
                this.seriesRelevantAttributHasChanged = true;
            }
            this.toDoEO.seriesIntervall = value;
        }
    }

    public get seriesIntervallUnit(): String {
        return this.toDoEO.seriesIntervallUnit;
    }
    public set seriesIntervallUnit(value: String) {
        if (value != this.toDoEO.seriesIntervallUnit) {
            if (this.isSeriesMaster()) {
                this.seriesRelevantAttributHasChanged = true;
            }
            this.toDoEO.seriesIntervallUnit = value;
        }
    }

    public get person(): String {
        return this.toDoEO.person;
    }
    public set person(value: String) {
        if (value != this.toDoEO.person) {
            this.toDoEO.person = value;
            this.seriesRelevantAttributHasChanged = true;
        }
    }

    public getPersonAvatar(): String {
        let person = this.getPerson();
        if (person) {
            return './assets/icons/avatars/' + person.avatar;
        } else {
            return '';
        }
    }

    public getPerson(): Person {
        return <Person>this.daoPersons.getObjectById(this.person);
    }

    public getPersonName(): String {
        if (this.getPerson()) {
            return this.getPerson().name;
        } else {
            return '';
        }
    }

    public get state(): String {
        return this.toDoEO.state;
    }
    public set state(value: String) {
        this.toDoEO.state = value;
    }

    public setDone() {
        this.toDoEO.state = Constants.TODO_STATE_DONE;
    }

    public isDone(): boolean {
        if (this.toDoEO.state == Constants.TODO_STATE_DONE) {
            return true;
        } else {
            return false;
        }
    }

    public get dueDate(): Date {
        if (this.isRelative()) {
            return this.calculateDueDate();
        } else {
            return this.toDoEO.dueDate;
        }
    }

    protected calculateDueDate(): Date {
        let dueDate = new Date();
        dueDate = this.basicService.normalizeDate(dueDate);
        if (this.parentType == Constants.PARENT_TYPE_RECORD) {
            if (this.parentId != '' && this.getRecord()) {
                dueDate.setTime(this.getRefDate().getTime());
                if (this.timeType == Constants.TEMPLATE_PREPOSITION_BEFORE) {
                    dueDate.setDate(dueDate.getDate() - this.time);
                } else if (this.timeType == Constants.TEMPLATE_PREPOSITION_AFTER) {
                    dueDate.setDate(dueDate.getDate() + this.time);
                }
                this.toDoEO.dueDate = this.basicService.normalizeDate(dueDate);
            }
        }
        return dueDate;
    }

    public get endDueDate(): Date {
        if (this.isRelative()) {
            return this.calculateEndDueDate();
        } else {
            return this.toDoEO.endDueDate;
        }
    }

    protected calculateEndDueDate(): Date {
        let endDueDate = new Date();
        endDueDate = this.basicService.normalizeDate(endDueDate);
        if (this.parentType == Constants.PARENT_TYPE_RECORD) {
            if (this.parentId != '' && this.getRecord()) {
                endDueDate.setTime(this.getEndRefDate().getTime());
                if (this.endTimeType == Constants.TEMPLATE_PREPOSITION_BEFORE) {
                    endDueDate.setDate(endDueDate.getDate() - this.endTime);
                } else if (this.endTimeType == Constants.TEMPLATE_PREPOSITION_AFTER) {
                    endDueDate.setDate(endDueDate.getDate() + this.endTime);
                }
            }
        }
        return endDueDate;
    }

    protected getRefDate(): Date {
        if (this.timeReference == Constants.TEMPLATE_TIME_REF_ANZUCHT) {
            return this.basicService.normalizeDate(this.getRecord().recordPeriods.seedDate);
        } else if (this.timeReference == Constants.TEMPLATE_TIME_REF_PFLANZUNG) {
            return this.basicService.normalizeDate(this.getRecord().recordPeriods.growDate);
        } else if (this.timeReference == Constants.TEMPLATE_TIME_REF_ERNTE) {
            return this.basicService.normalizeDate(this.getRecord().recordPeriods.harvestDate);
        } else if (this.timeReference == Constants.TEMPLATE_TIME_REF_KULTURENDE) {
            return this.getRecord().recordPeriods.getCultureEnd();
        }
    }
    protected getEndRefDate(): Date {
        if (this.endTimeReference == Constants.TEMPLATE_TIME_REF_ANZUCHT) {
            return this.basicService.normalizeDate(this.getRecord().recordPeriods.seedDate);
        } else if (this.endTimeReference == Constants.TEMPLATE_TIME_REF_PFLANZUNG) {
            return this.basicService.normalizeDate(this.getRecord().recordPeriods.growDate);
        } else if (this.endTimeReference == Constants.TEMPLATE_TIME_REF_ERNTE) {
            return this.basicService.normalizeDate(this.getRecord().recordPeriods.harvestDate);
        } else if (this.endTimeReference == Constants.TEMPLATE_TIME_REF_KULTURENDE) {
            return this.getRecord().recordPeriods.getCultureEnd();
        }
    }

    public get bedIdStart(): String {
        return this.toDoEO.bedIdStart;
    }
    public set bedIdStart(value: String) {
        if (value != this.toDoEO.bedIdStart) {
            if (this.isSeriesMaster()) {
                this.seriesRelevantAttributHasChanged = true;
            }
            this.toDoEO.bedIdStart = value;
        }
    }
    public get bedIdEnd(): String {
        return this.toDoEO.bedIdEnd;
    }
    public set bedIdEnd(value: String) {
        if (value != this.toDoEO.bedIdEnd) {
            if (this.isSeriesMaster()) {
                this.seriesRelevantAttributHasChanged = true;
            }
            this.toDoEO.bedIdEnd = value;
        }
    }

    public getRecord(): Record {
        if (this.parentType == Constants.PARENT_TYPE_RECORD) {
            return <Record>this.daoRecords.getObjectById(this.parentId);
        }
        else {
            return null;
        }
    }
    public getBed(): Bed {
        if (this.parentType == Constants.PARENT_TYPE_BED) {
            return <Bed>this.daoBeds.getObjectById(this.parentId);
        }
        else {
            return null;
        }
    }

    public getArea(): Area {
        if (this.parentType == Constants.PARENT_TYPE_AREA) {
            return <Area>this.daoAreas.getObjectById(this.parentId);
        }
        else {
            if (this.parentType == Constants.PARENT_TYPE_BED) {
                let area = <Area>this.daoAreas.getObjectById((<Bed>this.daoBeds.getObjectById(this.parentId)).areaId);
                return area;
            }
            return null;
        }
    }

    public set dueDate(value: Date) {
        if (this.toDoType == Constants.TODO_STANDALONE || this.toDoType == Constants.TODO_BED || this.toDoType == Constants.TODO_AREA ||
            this.toDoType == Constants.TODO_SERIES_STANDALONE || this.toDoType == Constants.TODO_SERIES_BED || this.toDoType == Constants.TODO_SERIES_AREA ||
            this.toDoType == Constants.TODO_RECORD_ABSOLUTE || this.toDoType == Constants.TODO_SERIES_RECORD_ABSOLUTE) {
            value = this.basicService.normalizeDate(value);
            if (value != this.toDoEO.dueDate) {
                if (this.isSeriesMaster()) {
                    this.seriesRelevantAttributHasChanged = true;
                }
                this.toDoEO.dueDate.setTime(value.getTime());
                this.hasChanged();
            }
        }
    }

    public set endDueDate(value: Date) {
        if (this.toDoType == Constants.TODO_STANDALONE || this.toDoType == Constants.TODO_BED || this.toDoType == Constants.TODO_AREA ||
            this.toDoType == Constants.TODO_SERIES_STANDALONE || this.toDoType == Constants.TODO_SERIES_BED || this.toDoType == Constants.TODO_SERIES_AREA ||
            this.toDoType == Constants.TODO_RECORD_ABSOLUTE || this.toDoType == Constants.TODO_SERIES_RECORD_ABSOLUTE) {
            value = this.basicService.normalizeDate(value);

            if (value != this.toDoEO.endDueDate) {
                if (this.isSeriesMaster()) {
                    this.seriesRelevantAttributHasChanged = true;
                }
                this.toDoEO.endDueDate = value;
                this.hasChanged();
            }
        }
    }

    getDueDateText() {
        let dueDate = this.dueDate;
        return dueDate.getDate() + '.' + (dueDate.getMonth() + 1).toString() + '.' + dueDate.getFullYear();
    }

    generateSeriesToDos(): ToDo[] {
        let toDos: Array<ToDo> = new Array;
        // Aenderungskennzeichen am Serienmaster loeschen - sonst wird immer wieder ueberschrieben
        this.seriesRelevantAttributHasChanged = false;

        // nur fuer SerienMaster
        if (this.isSeriesMaster() && this.seriesIntervall > 0) {

            toDos = this.getChildToDos();
            //wenn es schon ToDos gibt dann diese aendern
            if (toDos.length > 0) {
                this.reNewSeriesToDos(toDos);
            }
            // Neuanlage
            else {
                this.intervall = this.time;
                this.intervallType = this.timeType;
                this.toDoDate = new Date();
                this.toDoDate.setTime(this.dueDate.getTime());
                while (this.toDoDate <= this.endDueDate) {
                    toDos.push(this.generateNewSeriesToDo());
                    this.calculateIntervall();
                }
            }
            return toDos;
        } else {
            return new Array();
        }
    }

    public getChildToDos(): ToDo[] {
        let toDo: ToDo;
        let toDos = new Array();

        this.daoToDos.objectSet.objects.forEach(element => {
            toDo = element;
            if (toDo.seriesMaster == this.id) {
                if (toDo.id != this.id) {
                    toDos.push(toDo);
                }
            }
        });
        return toDos;
    }

    protected calculateIntervall() {
        if (this.seriesIntervallUnit == 'W') {
            this.toDoDate.setDate(this.toDoDate.getDate() + (7 * this.seriesIntervall));
            if (this.intervallType == Constants.TEMPLATE_PREPOSITION_BEFORE) {
                if (this.intervall >= (this.seriesIntervall * 7)) {
                    this.intervall = this.intervall - (this.seriesIntervall * 7);
                }
                else if (this.intervall < this.seriesIntervall * 7) {
                    this.intervall = (this.seriesIntervall * 7) - this.intervall;
                    this.intervallType = Constants.TEMPLATE_PREPOSITION_AFTER;
                }
            }
            else if (this.intervallType == Constants.TEMPLATE_PREPOSITION_AFTER) {
                this.intervall = this.intervall + (this.seriesIntervall * 7);
            }
        }
        else if (this.seriesIntervallUnit == 'T') {
            this.toDoDate.setDate(this.toDoDate.getDate() + this.seriesIntervall);
            if (this.intervallType == Constants.TEMPLATE_PREPOSITION_BEFORE) {
                if (this.intervall >= this.seriesIntervall) {
                    this.intervall = this.intervall - this.seriesIntervall;
                }
                else if (this.intervall < this.seriesIntervall) {
                    this.intervall = this.seriesIntervall - this.intervall;
                    this.intervallType = Constants.TEMPLATE_PREPOSITION_AFTER;
                }
            }
            else if (this.intervallType == Constants.TEMPLATE_PREPOSITION_AFTER) {
                this.intervall = this.intervall + this.seriesIntervall;
            }
        }
    }

    protected generateNewSeriesToDo(): ToDo {
        let toDo: ToDo;
        toDo = <ToDo>this.daoToDos.createNewInstance();

        toDo.toDoType = this.toDoType;
        toDo.type = this.type;
        toDo.name = this.name;
        toDo.quantity = this.quantity;
        toDo.quantityUnit = this.quantityUnit;


        if (this.toDoType == Constants.TODO_STANDALONE || this.toDoType == Constants.TODO_BED || this.toDoType == Constants.TODO_AREA ||
            this.toDoType == Constants.TODO_SERIES_STANDALONE || this.toDoType == Constants.TODO_SERIES_BED || this.toDoType == Constants.TODO_SERIES_AREA ||
            this.toDoType == Constants.TODO_RECORD_ABSOLUTE || this.toDoType == Constants.TODO_SERIES_RECORD_ABSOLUTE) {

            toDo.dueDate = new Date;
            toDo.dueDate.setTime(this.toDoDate.getTime());

        }
        else {
            toDo.time = this.intervall;
            toDo.timeUnit = this.timeUnit;
            toDo.timeType = this.intervallType;
            toDo.timeReference = this.timeReference;
        }
        toDo.person = '';
        toDo.color = this.color;
        toDo.state = '';

        toDo.note = this.note;
        toDo.parentType = this.parentType;
        toDo.parentId = this.parentId;
        toDo.bedIdStart = this.bedIdStart;
        toDo.bedIdEnd = this.bedIdEnd;
        toDo.person = this.person;

        toDo.seriesMaster = this.id;

        return toDo;
    }

    public isSeriesMaster(): boolean {
        if (this.seriesMaster == this.id) {
            return true;
        }
        else {
            return false;
        }
    }

    public get seriesMasterToDo(): ToDo {
        if (!this._seriesMasterToDo) {
            if (this.seriesMaster != '' && !this.isSeriesMaster()) {
                this._seriesMasterToDo = <ToDo>this.daoToDos.getObjectById(this.seriesMaster);
            }
        }

        return this._seriesMasterToDo;

    }

    public hasSeriesMasterChanged(): boolean {
        if (this.seriesRelevantAttributHasChanged) {
            return true;
        }
        else {
            return false;
        }
    }

    public getQuantityUnitText(): String {
        if (this.quantityUnit != '') {
            let fieldDTO: FieldTextDTO;
            fieldDTO = this.myApplication.textDTOs.get('ToDo.quantity.' + this.quantityUnit);
            if (fieldDTO) {
                return fieldDTO.text;
            } else {
                return '';
            }
        }
        else {
            return '';
        }
    }

    public deleteAllDependentToDos() {
        let toDo: ToDo;

        // nur wenn am SerienMaster loeschen ausgewaehlt wurde werden abhaengige mit geloescht
        if (this.isSeriesMaster()) {
            this.daoToDos.objectSet.objects.forEach(element => {
                toDo = element;
                if (toDo.seriesMaster == this.id) {
                    // nur loeschen wenn nicht erledigt
                    if (!toDo.isDone()) {
                        this.daoToDos.deleteObject(toDo);
                    }
                }
            });

        }

    }

    public reNewSeriesToDos(toDos: Array<ToDo>) {
        this.toDoDate = new Date();
        this.toDoDate.setTime(this.dueDate.getTime());
        this.intervall = this.time;
        this.intervallType = this.timeType;

        toDos = toDos.sort(function (one, two) {
            let compare = 0;
            if (one.dueDate > two.dueDate) {
                compare = 1;
            } else if (one.dueDate < two.dueDate) {
                compare = -1;
            } else {
                compare = 0;
            }

            return compare;
        });

        toDos.forEach(element => {
            // ToDo noch nicht erledigt dann aendern oder loeschen        
            if (element.isDone() == false) {
                if (this.toDoDate > this.endDueDate) {
                    this.daoToDos.deleteObject(element);
                }
                else {
                    if (this.isRelative()) {
                        element.time = this.intervall;
                        element.timeUnit = this.timeUnit;
                        element.timeType = this.intervallType;
                        element.timeReference = this.timeReference;
                    }
                    else {
                        element.dueDate = new Date;
                        element.dueDate.setTime(this.toDoDate.getTime());
                    }
                    this.calculateIntervall();
                }
                element.parentType = this.parentType;
                element.parentId = this.parentId;
                element.bedIdStart = this.bedIdStart;
                element.bedIdEnd = this.bedIdEnd;
                element.toDoType = this.toDoType;

                element.type = this.type;
                element.person = this.person;
            }

        });

        if (this.toDoDate <= this.endDueDate) {
            while (this.toDoDate <= this.endDueDate) {
                this.generateNewSeriesToDo();
                this.calculateIntervall();

            }
        }
    }

    public getAssignedBedsForArea(area): String {
        let bedAssigns = this.getRecord().bedAssignList.bedAssigns;
        let bedList: Array<Bed> = new Array();
        let bedList2: Array<Bed> = new Array();
        let beds: String;

        // alle verwendeten Beete des Schlags ermitteln
        bedAssigns.forEach(bedAssign => {
            if (bedAssign.getAreaId() == area.id) {
                bedList.push(bedAssign.bed);
            }
        });

        // nach Name oder Nummer sortieren
        bedList = bedList.sort(function (one, two) {
            let compare = one.orderNum.toString().localeCompare(two.orderNum.toString());
            return compare;
        });

        // doppelte raus
        let bedId: String = '';
        bedList.forEach(element => {
            if (bedId != element.id) {
                bedList2.push(element);
                bedId = element.id;
            }
        });
        let firstBed: boolean = true;
        if (bedList2.length > 1) {
            beds = this.myApplication.getText('Beds');
        } else {
            beds = this.myApplication.getText('RecordSet.bedId');
        }
        bedList2.forEach(bed => {
            if (firstBed) {
                beds = beds + ' ' + bed.getName();
                firstBed = false;
            } else {
                beds = beds + ', ' + bed.getName();
            }
        });
        return beds;
    }

}
