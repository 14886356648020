import { FieldTextDTO } from './field-text-dto';

export class KeyValueDTO {

    public value: String
    public disabled = false;
    public italic = false;
    

    constructor(public key: String, value?: String) {
        if (value) {
            this.value = value;
        }
    }
}
