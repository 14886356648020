export class NeedsDTO {
    public name: String = '';
    public sort: String = '';
    public quantity = 0;
    public tkg = 0;
    public weight = 0;
    public existingQuantity = 0;
    public existingWeight: number = 0;
    public buyWeight = 0;
    public buyQuantity = 0;
    public vendor: String = '';
    public hint: String = '';
    public quantityUnit: String = '';
    public seedValue: number = 0;
    public seedUnit: String = 'a';
    public weightUnit: String = 'kg';
    public existingWeightUnit: String = 'kg';
}