<div class="d-flex row justify-content-center bg my-4">

  <div class="card m-2">
    <div class="card-header">
      <div id="lLogin" class="text-center">
        <img [src]="logo">
      </div>
      <P><strong>vom Newsletter abmelden - unsubscribe from newsletter</strong></P>

      <div class="form-row">
        <div class="form-group col-sm-4">
          <label class="col-form-label col-form-label-sm">eMail</label>
        </div>
        <div class="col-sm-8">
          <div class="input-group input-group-sm">
            <input class="form-control form-control-sm" type="text" [(ngModel)]="mail">
          </div>
        </div>
      </div>

      <div *ngIf="!wait && !message" class="d-flex justify-content-center">
        <button class="btn btn-sm mr-1 btn-outline-success" type="button" (click)="unsubscribe()">
          abmelden - unsubscribe
        </button>
      </div>

      <div *ngIf="wait" class="d-flex justify-content-center mt-3">
        <div class="spinner-border spinner-border-xl mr-5" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div class="d-flex justify-content-center mt-3">
        {{message}}
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row text-center text-white mt-2 mb-4">
    <div class="col-sm-12">
      <a href="https://www.micro-farm-planner.com/impressum-und-datenschutz/" target="impressum">Impressum &
        Datenschutz</a>&nbsp;&nbsp;&nbsp;
      <a href="https://www.micro-farm-planner.com/agb/" target="agb">
        AGB</a>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row text-center text-white mt-2 mb-4">
    <div class="col-sm-12">
      <a href="https://www.micro-farm-planner.com/en/imprint-and-data-protection/" target="impressum">Imprint & data
        protection</a>&nbsp;&nbsp;&nbsp;
      <a href="https://www.micro-farm-planner.com/en/gtc" target="agb">
        Terms and Conditions (GTC)</a>
    </div>
  </div>
</div>