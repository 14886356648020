/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./calendar.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./calendar.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../services/application.service";
import * as i6 from "../../services/dao.service";
import * as i7 from "../../services/basic.service";
import * as i8 from "../../services/user-settings.service";
import * as i9 from "../../services/breadcrumb.service";
var styles_CalendarComponent = [i0.styles];
var RenderType_CalendarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CalendarComponent, data: {} });
export { RenderType_CalendarComponent as RenderType_CalendarComponent };
function View_CalendarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "iframe", [["id", "calendar"]], [[8, "src", 5], [4, "height", "px"]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; var currVal_1 = _co.innerHeight; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_CalendarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CalendarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-calendar", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_CalendarComponent_0, RenderType_CalendarComponent)), i1.ɵdid(1, 245760, null, 0, i3.CalendarComponent, [i4.DomSanitizer, i5.ApplicationService, i6.DaoService, i7.BasicService, i8.UserSettingsService, i9.BreadcrumbService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CalendarComponentNgFactory = i1.ɵccf("app-calendar", i3.CalendarComponent, View_CalendarComponent_Host_0, {}, {}, []);
export { CalendarComponentNgFactory as CalendarComponentNgFactory };
