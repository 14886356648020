export class TicketDTO {
    summary = '';
    description = 'Was läuft falsch?\n\n\nWie sollte es laufen?\n\n\n';
    category = 'Bug';
    priority = 'normal';
    partComponents = '';
    reporter = '';
    private _release = '';

    public get release() {
        return this._release;
    }
    public set release(value) {
        this._release = value;
        if (this._release == 'Raum der Wünsche') {
            this.category = 'Vorschlag';
        }
    }

    toJson() {
        let json = {};
        json["summary"] = this.summary;
        json["description"] = this.description;
        json["project"] = { "name": "Anbauplaner" };
        json["category"] = { "name": this.category };
        json["priority"] = { "name": this.priority };
        json["reporter"] = { "name": this.reporter };
        json["target_version"] = { "name": this.release };
        json["custom_fields"] = [
            {
                "field": {
                    "id": 3,
                    "name": "Handbuchrelevant"
                },
                "value": false
            },
            {
                "field": {
                    "id": 1,
                    "name": "Komponente"
                },
                "value": "Desktop-App"
            },
            {
                "field": {
                    "id": 2,
                    "name": "Teilkomponente"
                },
                "value": ' '+this.partComponents+' '
            }
        ];
        return json;
    }
}