import { SortSeed } from './sort-seed';
import { DAOSortSeeds } from '../dao/dao-sort-seeds';
import { ApplicationService } from 'src/app/services/application.service';


export class SortSeedSet {
    private _sortSeeds: Array<SortSeed>;
    private _sortSeedsFiltered: Array<SortSeed>;
    private _all = false;
    private _otherUnit = false;

    private _sortSeedsTkg: Array<SortSeed> = new Array();
    private _sortSeedsOtherUnit: Array<SortSeed> = new Array();


    constructor(private daoSortSeeds: DAOSortSeeds, private sortId: String, private myApplication: ApplicationService) {
    }

    public yearExists(year: number): boolean {
        for (let i = 0; i < this.sortSeeds.length; i++) {
            if (this.sortSeeds[i].manufacturingYear == year) {
                return true;
            }
        }
        return false;
    }

    public get all() {
        return this._all;
    }
    public set all(value) {
        if (value != this._all) {
            this._all = value;
            this.buildSortSeedsFiltered();
        }
    }

    public get sortSeeds(): Array<SortSeed> {
        if (!this._sortSeeds) {
            this._sortSeeds = this.daoSortSeeds.getSortSeedsForSort(this.sortId);
            this._sortSeeds = this.sortSortSeeds(this._sortSeeds);
            this._sortSeeds.forEach(sortSeed => {
                if (sortSeed.otherUnit) {
                    this._sortSeedsOtherUnit.push(sortSeed);
                } else {
                    this._sortSeedsTkg.push(sortSeed);
                }

            });
        }
        return this._sortSeeds;
    }
    public set sortSeeds(value: Array<SortSeed>) {
        this._sortSeeds = value;
    }

    public get sortSeedsTkg(): Array<SortSeed> {
        if (!this._sortSeedsTkg) {
            this._sortSeeds = this.daoSortSeeds.getSortSeedsForSort(this.sortId);
            this._sortSeeds = this.sortSortSeeds(this._sortSeeds);
            this._sortSeeds.forEach(sortSeed => {
                if (sortSeed.otherUnit) {
                    this._sortSeedsOtherUnit.push(sortSeed);
                } else {
                    this._sortSeedsTkg.push(sortSeed);
                }

            });
        }
        return this._sortSeedsTkg;
    }
    public get sortSeedsOtherUnit(): Array<SortSeed> {
        if (!this._sortSeedsOtherUnit) {
            this._sortSeeds = this.daoSortSeeds.getSortSeedsForSort(this.sortId);
            this._sortSeeds = this.sortSortSeeds(this._sortSeeds);
            this._sortSeeds.forEach(sortSeed => {
                if (sortSeed.otherUnit) {
                    this._sortSeedsOtherUnit.push(sortSeed);
                } else {
                    this._sortSeedsTkg.push(sortSeed);
                }

            });
        }
        return this._sortSeedsOtherUnit;
    }

    public get sortSeedsFiltered(): Array<SortSeed> {
        if (!this._sortSeedsFiltered) {
            this.buildSortSeedsFiltered();
        }
        return this._sortSeedsFiltered;
    }
    public set sortSeedsFiltered(value: Array<SortSeed>) {
        this._sortSeedsFiltered = value;
    }

    public buildSortSeedsFiltered() {
        console.log('hier wird gefiltert');
        this._sortSeedsFiltered = new Array<SortSeed>();
        this.sortSeeds.forEach(sortSeed => {
            if ((this.all || sortSeed.manufacturingYear >= this.myApplication.year) && this.otherUnit == sortSeed.otherUnit) {
                this._sortSeedsFiltered.push(sortSeed);
            }
        });
        this._sortSeedsFiltered = this.sortSortSeeds(this._sortSeedsFiltered);
    }

    getCompleteLength(): number {
        return this.sortSeeds.length;
    }

    createSortSeed(): SortSeed {
        let sortSeed = <SortSeed>this.daoSortSeeds.createNewInstance();
        return this.createSortSeedDetail(sortSeed);
    }
    createSortSeedDetail(sortSeed: SortSeed): SortSeed {
        this._sortSeeds = this.sortSortSeeds(this.sortSeeds);
        let year: number;
        sortSeed.sortId = this.sortId;
        if (this.sortSeeds.length > 0) {
            let lastObject = this.sortSeeds[this.sortSeeds.length - 1];
            year = lastObject.manufacturingYear;
            year++;
            sortSeed.manufacturingYear = year;
            sortSeed.manufacturer = lastObject.manufacturer;
            sortSeed.tkg = lastObject.tkg;
        } else {
            if (this.daoSortSeeds.objects.size > 0) {
                let anyObject = <SortSeed>this.daoSortSeeds.objects.values().next().value;
                sortSeed.manufacturer = anyObject.manufacturer;
            }
            sortSeed.manufacturingYear = this.myApplication.year;
        }
        this.sortSeeds.push(sortSeed);
        this._sortSeeds = this.sortSortSeeds(this._sortSeeds);
        this.buildSortSeedsFiltered();
        this.myApplication.save();
        return sortSeed;
    }

    createSortSeedOtherUnit(): SortSeed {
        let sortSeed = <SortSeed>this.daoSortSeeds.createNewInstance();
        sortSeed.otherUnit = true;
        return this.createSortSeedDetail(sortSeed);
    }

    getSortSeedForYear(year: number): SortSeed {
        let sortSeed: SortSeed;
        for (let i = 0; i < this.sortSeeds.length; i++) {
            sortSeed = this.sortSeeds[i];
            if (sortSeed.manufacturingYear == year) {
                return sortSeed;
            }
        }
        return null;
    }

    getSortSeedBeforYear(year: number): SortSeed {
        let sortSeed: SortSeed;
        let found: SortSeed;
        for (let i = 0; i < this.sortSeeds.length; i++) {
            sortSeed = this.sortSeeds[i];
            if (sortSeed.manufacturingYear < year) {
                if (!found || found.manufacturingYear < sortSeed.manufacturingYear) {
                    found = sortSeed;
                }
            }
        }
        return found;
    }

    deleteSortSeed(sortSeed: SortSeed) {
        this.daoSortSeeds.deleteObject(sortSeed);
        this._sortSeeds = null;
        this._sortSeedsFiltered = null;
    }

    private sortSortSeeds(list: Array<SortSeed>): Array<SortSeed> {
        return list.sort(function (one, two) {
            return one.manufacturingYear - two.manufacturingYear;
        });
    }

    public get otherUnit() {
        return this._otherUnit;
    }
    public set otherUnit(value) {
        if (value != this._otherUnit) {
            this._otherUnit = value;
            this.buildSortSeedsFiltered();
        }
    }
}