import { DAO } from '../technical-aspects/dao';
import { Feedback } from '../business-logic/feedback';
import { AbstractObject } from '../technical-aspects/abstract-object';


export class DAOFeedback extends DAO {

    protected createInstance() {
        let newInstance = new Feedback();
        return newInstance;
    }

    protected saveDB() {
        let data;

        if (this.newObjects.size > 0) {
            let filledObjects = new Array<AbstractObject>();
            this.newObjects.forEach(element => {
                if ((<Feedback>element).comment != '') {
                    filledObjects.push(element);
                }
            });
            if (filledObjects.length > 0) {
                data = this.buildJSON(Array.from(filledObjects), true);
                this.insert(data);
                this.newObjects.forEach(object => {
                    object.resetDBState();
                });
                this.newObjects.clear();
            }
        }
    }
}