import { AbstractObject } from '../technical-aspects/abstract-object';
import { WeekInYearDTO } from '../dto/week-in-year-dto';
import { PartsEO } from '../entity-objects/parts-eo';

export class Parts extends AbstractObject {

    constructor() {
        super();
    }

    update() {
    }

    initialize() {
        this.eo = new PartsEO();
    }

    protected get partsEO(): PartsEO {
        return <PartsEO>this.eo;
    }

    public get startWeek(): number {
        return this.partsEO.startWeek;
    }
    public set startWeek(value: number) {
        this.partsEO.startWeek = value;
    }

    public get startYear(): number {
        return this.partsEO.startYear;
    }
    public set startYear(value: number) {
        this.partsEO.startYear = value;
    }

    public get endWeek(): number {
        return this.partsEO.endWeek;
    }
    public set endWeek(value: number) {
        this.partsEO.endWeek = value;
    }

    public get endYear(): number {
        return this.partsEO.endYear;
    }
    public set endYear(value: number) {
        this.partsEO.endYear = value;
    }

    public get parts(): number {
        return this.partsEO.parts;
    }
    public set parts(value: number) {
        this.partsEO.parts = value;
    }

    getStartWeek() {
        return new WeekInYearDTO(this.startWeek, this.startYear);
    }

    getEndWeek() {
        return new WeekInYearDTO(this.endWeek, this.endYear);
    }

    getStart(): String {
        let text = this.myApplication.getText('CalendarWeek') + ' ' + this.startWeek + ' ' + this.startYear;
        return text;
    }

    getEnd(): String {
        let text = this.myApplication.getText('CalendarWeek') + ' ' + this.endWeek + ' ' + this.endYear;
        return text;
    }


}