import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Http, RequestOptions } from '@angular/http';
import { PartType } from '../app-core/business-logic/partType';
import { environment } from 'src/environments/environment';
import { Part } from '../app-core/business-logic/part';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PartsService {
  
  public partTypes: Array<PartType>;
  public parts: Array<Part>;

  constructor(private authenticationService: AuthenticationService, private http: Http) {

   // this.readParts();
  }

  public readParts() {

//deprecated


    // this.partTypes = new Array<PartType>();

    // const options = new RequestOptions();
    // options.headers = this.authenticationService.getHeadersAuthenticated();

    // this.http.get(environment.api + 'api/partTypes', options).pipe(
    //   map(resp => resp.json())
    // )
    //   .subscribe(
    //     res => {
    //       //Anteilstypen
    //       let partTypes = res['parts']['partTypes'];
    //       let partType: PartType;
    //       let k = 0;

    //       while (partTypes[k]) {
    //         partType = new PartType();
    //         partType.id = partTypes[k]['id'];
    //         partType.name = partTypes[k]['name'];
    //         partType.note = partTypes[k]['note'];
    //         partType.score = Number.parseFloat(partTypes[k]['score']);
    //         partType.isStandard = partTypes[k]['isStandard'];

    //         this.partTypes.push(partType);
    //         k = k + 1;
    //       }

    //       //Anteile
    //       let parts = res['parts']['parts'];
    //       let part: Part;
    //       let l = 0;
    //       this.parts = new Array();

    //       while (parts[l]) {
    //         part = new Part();
    //         part.id = parts[l]['id'];
    //         part.partTypeId = parts[l]['partTypeId'];
    //         part.startKW = Number.parseInt(parts[l]['startWeek']);
    //         part.startYear = Number.parseInt(parts[l]['startYear']);
    //         part.endKW = Number.parseInt(parts[l]['endWeek']);
    //         part.endYear = Number.parseInt(parts[l]['endYear']);
    //         part.numParts = Number.parseFloat(parts[l]['parts']);
    //         part.startText = part.startKW + '/' + part.startYear;
    //         part.endText = part.endKW + '/' + part.endYear;
    //         part.deliveryPlaceId = parts[l]['deliveryPlaceId'];
    //         part.consumerId = parts[l]['consumerId'];

    //         this.parts.push(part);
    //         l = l + 1;
    //       }
    //     });
  }
}      