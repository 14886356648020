import * as tslib_1 from "tslib";
import { AbstractObject } from '../technical-aspects/abstract-object';
import { AreaEO } from '../entity-objects/area-eo';
import { FilteredRecordSet } from './filtered-record-set';
import { ToDoListArea } from './todo-list-area';
import { Constants } from './constants';
var Area = /** @class */ (function (_super) {
    tslib_1.__extends(Area, _super);
    function Area() {
        var _this = _super.call(this) || this;
        _this.typeText = '';
        return _this;
    }
    Area.prototype.update = function () {
        this.daoToDos.addObserver(this);
        this.todoList = null;
        this.updateTypeText();
    };
    Object.defineProperty(Area.prototype, "areaEO", {
        get: function () {
            return this.eo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Area.prototype, "name", {
        get: function () {
            return this.areaEO.name;
        },
        set: function (value) {
            this.areaEO.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Area.prototype, "pathWidth", {
        get: function () {
            return this.areaEO.pathWidth;
        },
        set: function (value) {
            this.areaEO.pathWidth = value;
        },
        enumerable: true,
        configurable: true
    });
    Area.prototype.getNumPathes = function () {
        if (this.type = Constants.AREA_TYPE_FREILAND) {
            return this.getNumBeds() + 1;
        }
        else {
            return this.getNumBeds() - 1;
        }
    };
    Object.defineProperty(Area.prototype, "description", {
        get: function () {
            return this.areaEO.description;
        },
        set: function (value) {
            this.areaEO.description = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Area.prototype, "type", {
        get: function () {
            return this.areaEO.type;
        },
        set: function (value) {
            this.areaEO.type = value;
            this.updateTypeText();
        },
        enumerable: true,
        configurable: true
    });
    Area.prototype.updateTypeText = function () {
        this.typeText = this.myApplication.textDTOs.get('Area.type.' + this.type).text;
    };
    Area.prototype.initialize = function () {
        this.eo = new AreaEO();
    };
    Area.prototype.getRecordSet = function () {
        var _this = this;
        if (!this.recordSet) {
            var objects_1 = new Array();
            this.daoRecords.objects.forEach(function (element) {
                var record;
                record = element;
                if (record.areaId == _this.id) {
                    objects_1.push(record);
                }
            });
            this.recordSet = new FilteredRecordSet(objects_1, this.myApplication, this.basicService);
        }
        return this.recordSet;
    };
    Area.prototype.createNewBed = function () {
        var set = new Array();
        var size = this.daoBeds.getBedsForArea(this.id).length;
        var bed = this.daoBeds.createNewInstance();
        set.push(bed);
        bed.areaId = this.id;
        bed.orderNum = size + 1;
        return bed;
    };
    Area.prototype.getNumBeds = function () {
        return this.daoBeds.getBedsForArea(this.id).length;
    };
    Area.prototype.getSumBedM = function () {
        var sum = 0;
        var objects = this.daoBeds.getBedsForArea(this.id);
        objects.forEach(function (bed) {
            sum = sum + bed.length;
        });
        return Math.round(sum * 100) / 100;
    };
    Area.prototype.getM2 = function () {
        var sum = 0;
        var objects = this.daoBeds.getBedsForArea(this.id);
        objects.forEach(function (bed) {
            sum = sum + bed.getM2();
        });
        return sum;
    };
    Area.prototype.getM2Paths = function () {
        var sum = 0;
        var objects = this.daoBeds.getBedsForArea(this.id);
        for (var i = 0; i < objects.length; i++) {
            if (this.type == Constants.AREA_TYPE_FREILAND && i == 0) { //doppelt
                sum = sum + (objects[i].length * this.pathWidth);
            }
            if (this.type == Constants.AREA_TYPE_FREILAND || i < objects.length - 1) {
                sum = sum + (objects[i].length * this.pathWidth);
            }
        }
        return sum;
    };
    Area.prototype.getM2Sum = function () {
        return this.getM2() + this.getM2Paths();
    };
    Object.defineProperty(Area.prototype, "todoList", {
        get: function () {
            if (!this._todoList) {
                this._todoList = new ToDoListArea(this.daoToDos, this);
                this._todoList.toDos = this._todoList.toDos.sort(function (one, two) {
                    var compare = 0;
                    if (one.dueDate > two.dueDate) {
                        compare = 1;
                    }
                    else if (one.dueDate < two.dueDate) {
                        compare = -1;
                    }
                    else {
                        compare = 0;
                    }
                    return compare;
                });
            }
            return this._todoList;
        },
        set: function (value) {
            this._todoList = value;
        },
        enumerable: true,
        configurable: true
    });
    Area.prototype.isDeleteable = function () {
        var beds = this.daoBeds.getBedsForArea(this.id);
        if (beds.length == 0) {
            return true;
        }
        else {
            for (var i = 0; i < beds.length; i++) {
                var bed = beds[i];
                if (!bed.isDeleteable()) {
                    return false;
                }
            }
            ;
            return true;
        }
    };
    return Area;
}(AbstractObject));
export { Area };
