import { Workbook, Worksheet } from 'exceljs';
import * as fs from 'file-saver';
import { ApplicationService } from 'src/app/services/application.service';
import { Constants } from './constants';
import { Culture } from './culture';
import { NeedsCalculator } from './needs-calculator';
import { NeedsCalculatorPlants } from './needs-calculator-plants';


export class SeedsInventoryExcel {


  constructor(private myApplication: ApplicationService) {

  }

  buildHeader(sheet: Worksheet, otherUnit: boolean): Worksheet {

    const title = this.myApplication.getText('Seed.inventory') + " - " + this.myApplication.year;
    const myFont: String = 'Calibri';

    let header: Array<String> = new Array();
    header.push(this.myApplication.getText('Culture.name'));
    header.push(this.myApplication.getText('RecordsCreateDTO.sort'));
    header.push(this.myApplication.getText('Seed.manufacturingYear'));
    header.push(this.myApplication.getText('Seed.manufacturer'));

    if (!otherUnit) {
      header.push(this.myApplication.getText('Seed.tkg') + ' (g)');
      header.push(this.myApplication.getText('Seed.quantity'));     //Bestand
      header.push(this.myApplication.getText('ToDo.quantityUnit')); //Einheit
      header.push(this.myApplication.getText('Seed.quantity.Korn'));
    } else {
      header.push(this.myApplication.getText('Seed.seedValue'));
      header.push(this.myApplication.getText('ToDo.quantityUnit'));
      header.push(this.myApplication.getText('Seed.quantity'));     //Bestand
      header.push(this.myApplication.getText('ToDo.quantityUnit')); //Einheit
    }

    if (!otherUnit) {
      // Ueberschrift und formatieren
      let titleRow = sheet.addRow([title]);
      titleRow = sheet.addRow([this.myApplication.getText('Sort.tkg')]);
    } else {
      let titleRow = sheet.addRow(' ');
      titleRow = sheet.addRow([this.myApplication.getText('Sort.otherUnit')]);
    }

    // Kopfzeile schreiben und formatieren
    let headerRow = sheet.addRow(header);
    // sheet.mergeCells(2, 1, 2, 10);

    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF28a745' },
        bgColor: { argb: 'FFFFFFFF' },
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } },
        cell.font = { color: { argb: "FFFFFFFF" }, name: myFont.toString() }

    });
    return sheet;

  }


  buildData(sheet: Worksheet, cultures: Array<Culture>, otherUnit: boolean): Worksheet {

    cultures.forEach(culture => {
      culture.sorts.forEach(sort => {
        if (sort.seedSet.otherUnit == otherUnit) {
          sort.seedSet.seeds.forEach(seed => {
            if (!seed.done) {
              let data: Array<any> = new Array();
              data.push(culture.name);
              data.push(sort.name);
              data.push(seed.getManufacuringYear());
              data.push(seed.getSortSeed().manufacturer);
              if (!otherUnit) {
                data.push(seed.getTKG());
              } else {
                data.push(seed.getSortSeed().seedValue);
                data.push(this.myApplication.getText('ToDo.quantity.' + seed.getSortSeed().seedUnit));
              }
              data.push(seed.quantity);
              data.push(this.myApplication.getText('Unit.' + seed.quantityUnit));
              if (!otherUnit) {
                data.push(seed.getNumCorn());
              }

              //Satz schreiben und formatieren
              let dataRow = sheet.addRow(data);
            }
          });
        }
      });
    });

    return sheet;
  }



  buildCol(sheet: Worksheet): Worksheet {
    const myFont: String = 'Calibri';

    // Spaltenbreite festlegen
    for (let i = 1; i <= 10; i++) {
      let col = sheet.getColumn(i);
      col.header = ' ';
      col.width = 20;
      col.style = { font: { bold: false, name: myFont.toString() } };
    }
    sheet.getColumn(5).numFmt = '#,##0.000';
    sheet.getColumn(6).numFmt = '#,##0.0';
    sheet.getColumn(8).numFmt = '#,##0';

    return sheet;

  }

  checkIfOtherUnits(cultures): boolean {
    let otherUnitExits: boolean = false;
    cultures.forEach(culture => {
      culture.sorts.forEach(sort => {
        if (sort.seedSet.otherUnit == true) {
          otherUnitExits = true;
        }
      });
    });
    return otherUnitExits;
  }


  export(cultures: Array<Culture>) {
    var workbook = new Workbook();
    // 1. Blatt aufbauen
    var sheet = workbook.addWorksheet(
      this.myApplication.getText('Seed.inventory').toString(),
      { properties: { tabColor: { argb: 'FFC0000' } } });
    sheet.views = [
      { state: 'frozen', ySplit: 3 }
    ];

    sheet = this.buildCol(sheet);
    sheet = this.buildHeader(sheet, false);
    sheet = this.buildData(sheet, cultures, false);
    //nur wenn es abweichende Einheiten gibt
    if (this.checkIfOtherUnits(cultures)) {
      sheet = this.buildHeader(sheet, true);
      sheet = this.buildData(sheet, cultures, true);
    }



    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, this.myApplication.getText('Seed.inventory') + '.xlsx');
    });
  }
}