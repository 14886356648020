import { PeriodUI } from './period-ui';
import { ApplicationService } from 'src/app/services/application.service';
import { BasicService } from 'src/app/services/basic.service';
import { WeekInYearDTO } from '../dto/week-in-year-dto';
import { Record } from './record';
import { Bed } from './bed';
import { RecordPeriods } from './record-periods';
import { BedAssign } from './bed-assign';
import { Constants } from './constants';
import { MonthDTO } from '../dto/month-dto';


export class PeriodsUIList {

    public periods: Array<PeriodUI>;


    constructor(public myApplication: ApplicationService, public basicService: BasicService, public record: Record) {
        this.periods = new Array();
    }

    public createFromRecordPeriods(record: Record) {
        let recordPeriods = record.recordPeriods;
        let firstWeekOfYear = new WeekInYearDTO(1, this.myApplication.year);
        let lastWeekOfYear = new WeekInYearDTO(this.basicService.getNumWeeks(this.myApplication.year), this.myApplication.year);


        //Startet nicht in KW 1? Leere Perioden davor
        if (this.basicService.weekIsGreaterThan(recordPeriods.getStartWeek(), firstWeekOfYear)) {
            this.createEmptyPrePeriods(recordPeriods.getStartWeek().week - 1);
        }

        let periodUI;
        if (record.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
            this.buildPeriodUI(firstWeekOfYear, lastWeekOfYear, 'Anzucht', recordPeriods.seedWeek, recordPeriods.lastSeedWeek, recordPeriods.seedLength);
        }
        this.buildPeriodUI(firstWeekOfYear, lastWeekOfYear, 'Wachstum', recordPeriods.growWeek, recordPeriods.lastGrowWeek, recordPeriods.growLength);
        this.buildPeriodUI(firstWeekOfYear, lastWeekOfYear, 'Ernte', recordPeriods.harvestWeek, recordPeriods.lastHarvestWeek, recordPeriods.harvestLength);

        // if (!recordPeriods.isInYear(this.myApplication.year)) {
        //     this.periods.push(this.buildEmptyPeriod(lastWeekOfYear.week));
        // } else
        //hinten noch Luft?
        if (this.basicService.weekIsLessThan(recordPeriods.lastHarvestWeek, lastWeekOfYear)) {
            this.createEmptyPostPeriods(this.basicService.calculateWeekDiff(recordPeriods.lastHarvestWeek, lastWeekOfYear));

        }
    }

    protected createEmptyPrePeriods(durationPre: number) {
        let months = this.myApplication.getMonths(this.myApplication.year);
        let monthIndex = 0;
        let month: MonthDTO;
        let duration = 0;

        while (durationPre > 0) {
            month = months[monthIndex];
            duration = month.duration;
            if (duration > durationPre) {
                duration = durationPre;
            }

            this.periods.push(this.buildEmptyPeriod(duration));
            durationPre = durationPre - duration;
            monthIndex = monthIndex + 1;
        }
    }

    protected createEmptyPostPeriods(durationPost: number) {
        let monthIndex = 11;
        let month: MonthDTO;
        let duration = 0;
        let durationsPost = new Array<number>();
        let months = this.myApplication.getMonths(this.myApplication.year);

        while (durationPost > 0) {
            month = months[monthIndex];
            duration = month.duration;
            if (duration > durationPost) {
                duration = durationPost;
            }
            durationsPost.push(duration);
            durationPost = durationPost - duration;
            monthIndex = monthIndex - 1;
        }
        duration = durationsPost.pop();
        while (duration) {
            this.periods.push(this.buildEmptyPeriod(duration));
            duration = durationsPost.pop();
        }
    }

    protected buildPeriodUI(firstWeekOfYear: WeekInYearDTO, lastWeekOfYear: WeekInYearDTO, name: String, startWeek: WeekInYearDTO, lastWeek: WeekInYearDTO, length: number): PeriodUI {
        //liegt aber in diesem Jahr, oder?
        let diff = 0;
        let periodUI: PeriodUI = null;

        if (this.basicService.isInPeriod(firstWeekOfYear, lastWeekOfYear, startWeek) || this.basicService.isInPeriod(firstWeekOfYear, lastWeekOfYear, lastWeek) ||
            (this.basicService.weekIsLessThan(startWeek, firstWeekOfYear) && this.basicService.weekIsGreaterThan(lastWeek, lastWeekOfYear))) {
            //beginnt schon vor dem aktuellen Jahr?
            if (this.basicService.weekIsLessThan(startWeek, firstWeekOfYear)) {
                diff = this.basicService.calculateWeekDiff(startWeek, firstWeekOfYear);
            } else {
                diff = 0;
            }

            //geht über das Jahr hinaus?
            if (this.basicService.weekIsGreaterThan(lastWeek, lastWeekOfYear)) {
                diff = diff + this.basicService.calculateWeekDiff(lastWeek, lastWeekOfYear);
            }

            periodUI = new PeriodUI();
            periodUI.name = name;
            if (this.record) {
                periodUI.color = this.record.culture.color;
            }
            periodUI.duration = length - diff;
            if (periodUI.duration > 0) {
                this.periods.push(periodUI);
            }
        }
        return periodUI;
    }

    protected buildEmptyPeriod(duration: number): PeriodUI {
        let periodUI = new PeriodUI();
        periodUI.duration = duration;
        periodUI.color = '';
        periodUI.name = '';
        periodUI.opacity = 0.0;
        return periodUI;
    }

    public createForAreaBed(recordPeriods: RecordPeriods) {
        let periodUI: PeriodUI;
        let startWeek: WeekInYearDTO;
        let lastWeek: WeekInYearDTO;
        let diff: number;
        let firstWeekOfYear = new WeekInYearDTO(1, this.myApplication.year);
        let lastWeekOfYear = new WeekInYearDTO(this.basicService.getNumWeeks(this.myApplication.year), this.myApplication.year);

        startWeek = recordPeriods.growWeek;
        lastWeek = recordPeriods.lastHarvestWeek

        periodUI = new PeriodUI();
        periodUI.name = "Test";
        periodUI.text = "Test2";

        //Startet nicht in KW 1? Leere Periode davor
        if (this.basicService.weekIsGreaterThan(startWeek, firstWeekOfYear)) {
            let numEmptyPeriods = startWeek.week - 1;
            for (let i = 0; i < numEmptyPeriods; i++) {
                this.periods.push(this.buildEmptyPeriod(1));
            }
        }

        //liegt aber in diesem Jahr, oder?
        if (this.basicService.isInPeriod(firstWeekOfYear, lastWeekOfYear, startWeek) || this.basicService.isInPeriod(firstWeekOfYear, lastWeekOfYear, lastWeek) ||
            (this.basicService.weekIsLessThan(startWeek, firstWeekOfYear) && this.basicService.weekIsGreaterThan(lastWeek, lastWeekOfYear))) {

            //beginnt schon vor dem aktuellen Jahr?
            if (this.basicService.weekIsLessThan(startWeek, firstWeekOfYear)) {
                diff = this.basicService.calculateWeekDiff(startWeek, firstWeekOfYear);
            } else {
                diff = 0;
            }

            //geht über das Jahr hinaus?
            if (this.basicService.weekIsGreaterThan(lastWeek, lastWeekOfYear)) {
                diff = diff + this.basicService.calculateWeekDiff(lastWeek, lastWeekOfYear);
            }

            periodUI.duration = this.basicService.calculateWeekDiff(startWeek, lastWeek) - diff + 1;
            if (periodUI.duration > 0) {
                periodUI.name = this.record.getName();
                periodUI.text = this.record.lengthOnBed.toString();
                periodUI.color = this.record.culture.color;
                this.periods.push(periodUI);
            }
        }


        //hinten noch Luft?
        if (!lastWeek) {
            for (let i = 0; i < lastWeekOfYear.week; i++) {
                this.periods.push(this.buildEmptyPeriod(1));
            }
        } else if (this.basicService.weekIsLessThan(lastWeek, lastWeekOfYear)) {
            let numEmptyPeriods = this.basicService.calculateWeekDiff(recordPeriods.lastHarvestWeek, lastWeekOfYear);
            for (let i = 0; i < numEmptyPeriods; i++) {
                this.periods.push(this.buildEmptyPeriod(1));
            }
        }

    }

    public createForKWBedmeters(bedAssign: BedAssign, bed: Bed, record: Record) {
        let currentPosition: number = 1;
        let periodUI: PeriodUI;

        if (currentPosition < bedAssign.positionOnBed) {
            for (let i = 0; i < bedAssign.positionOnBed - currentPosition; i++) {
                periodUI = new PeriodUI();
                periodUI.duration = 1;
                periodUI.color = '';
                periodUI.name = '';
                this.periods.push(periodUI);
            }
        }
        periodUI = new PeriodUI();
        periodUI.duration = bedAssign.lengthOnBed;
        periodUI.name = record.getName();
        periodUI.color = record.culture.color;
        periodUI.opacity = 1.0;
        this.periods.push(periodUI);
        currentPosition = bedAssign.positionOnBed + bedAssign.lengthOnBed;

        if (currentPosition <= bed.length) {
            for (let i = 0; i < bed.length - currentPosition + 1; i++) {
                periodUI = new PeriodUI();
                periodUI.duration = 1;
                periodUI.color = '';
                periodUI.name = '';
                this.periods.push(periodUI);
            }
        }

    }
}