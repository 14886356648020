import * as tslib_1 from "tslib";
import { Constants } from './constants';
import { Observable } from '../technical-aspects/observable';
var FilteredToDoList = /** @class */ (function (_super) {
    tslib_1.__extends(FilteredToDoList, _super);
    function FilteredToDoList(objects) {
        var _this = _super.call(this) || this;
        _this._cultureDates = true;
        _this._personId = '';
        _this._types = new Array();
        _this.checkAllValue = true;
        _this.isFiltered = false;
        _this.completeObjects = objects;
        _this.update();
        return _this;
    }
    FilteredToDoList.prototype.replaceObjects = function (objects) {
        this.completeObjects = objects;
        this.update();
    };
    Object.defineProperty(FilteredToDoList.prototype, "types", {
        get: function () {
            return this._types;
        },
        set: function (value) {
            this._types = value;
        },
        enumerable: true,
        configurable: true
    });
    FilteredToDoList.prototype.addType = function (type) {
        if (!this._types.includes(type)) {
            this._types.push(type);
            this.update();
        }
    };
    FilteredToDoList.prototype.removeType = function (type) {
        var tmp = new Array();
        this._types.forEach(function (element) {
            if (element != type) {
                tmp.push(element);
            }
        });
        this._types = tmp;
        this.update();
    };
    FilteredToDoList.prototype.update = function () {
        this.objects = this.completeObjects;
        this.objects = this.filterByDates(this.objects);
        this.objects = this.filterByPersonId(this.objects);
        this.objects = this.filterByTypes(this.objects);
        if (this.objects.length != this.completeObjects.length) {
            this.isFiltered = true;
        }
        else {
            this.isFiltered = false;
        }
        this.hasChanged();
    };
    FilteredToDoList.prototype.filterByDates = function (objects) {
        var set = new Array();
        if (!this._cultureDates) {
            objects.forEach(function (toDo) {
                if (toDo.toDoType != Constants.TODO_RECORD_DATES) {
                    set.push(toDo);
                }
            });
        }
        else {
            objects.forEach(function (toDo) {
                set.push(toDo);
            });
        }
        return set;
    };
    FilteredToDoList.prototype.filterByPersonId = function (objects) {
        var _this = this;
        var set = new Array();
        if (this._personId != Constants.NOT_RELEVANT) {
            objects.forEach(function (toDo) {
                if (toDo.person == _this._personId) {
                    set.push(toDo);
                }
            });
        }
        else {
            objects.forEach(function (toDo) {
                set.push(toDo);
            });
        }
        return set;
    };
    FilteredToDoList.prototype.filterByTypes = function (objects) {
        var _this = this;
        var set = new Array();
        if (this._types.length > 0) {
            objects.forEach(function (toDo) {
                if (_this._types.includes(toDo.type)) {
                    set.push(toDo);
                }
            });
            return set;
        }
        else {
            return objects;
        }
    };
    Object.defineProperty(FilteredToDoList.prototype, "personId", {
        get: function () {
            return this._personId;
        },
        set: function (value) {
            if (value != this._personId) {
                this._personId = value;
                this.update();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilteredToDoList.prototype, "cultureDates", {
        get: function () {
            return this._cultureDates;
        },
        set: function (value) {
            if (value != this._cultureDates) {
                this._cultureDates = value;
                this.update();
            }
        },
        enumerable: true,
        configurable: true
    });
    FilteredToDoList.prototype.reset = function () {
        this._cultureDates = true;
        this._personId = '';
        this._types = new Array();
        this.checkAllValue = true;
        this.update();
    };
    return FilteredToDoList;
}(Observable));
export { FilteredToDoList };
