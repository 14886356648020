import { Component, OnInit, Input } from '@angular/core';
import { FieldTextDTO } from 'src/app/app-core/dto/field-text-dto';
import { ApplicationService } from 'src/app/services/application.service';
import { KeyValueDTO } from 'src/app/app-core/dto/key-value-dto';

@Component({
  selector: 'app-number-unit-select-field',
  templateUrl: './number-unit-select-field.component.html',
  styleUrls: ['./number-unit-select-field.component.css']
})
export class NumberUnitSelectFieldComponent implements OnInit {

  @Input() id: String;
  @Input() object;
  @Input() valueName: String;
  @Input() unitValueName: String;
  @Input() keyValueList: Array<KeyValueDTO>;
  @Input() colsLabel: String = 'col-sm-4';
  @Input() colsField: String = 'col-sm-8';
  @Input() btnIcon: String;
  @Input() btnMethod: String;
  @Input() step: number = 0.1;
  @Input() min: number = 0;
  @Input() useWarningClass = false;
  @Input() btnClass = this.myApplication.theme.btnOutline;


  private _disable: boolean = false;


  public fieldDTO: FieldTextDTO;
  public btnDTO: FieldTextDTO;


  constructor(public myApplication: ApplicationService) { }

  public get disable(): boolean {
    return this._disable;
  }

  onBlur(event) {
    event.target.value = this.value;
  }

  public get value(): string {
    let value = this.object[this.valueName.toString()].toString();
    value = value.replace('.', ',');
    return value;
  }
  public set value(value: string) {
    value = value.replace(new RegExp(',', 'g'), '.');
    let num = Number(value);
    if ((!isNaN(num))) {
      this.object[this.valueName.toString()] = num;
    }
  }

  getWarningClass() {
    if (this.useWarningClass && this.object[this.valueName.toString()] == 0) {
      return 'border-warning'
    }
  }

  @Input()
  public set disable(value: boolean) {
    this._disable = value;
  }

  ngOnInit() {
    if (!this.id) {
      this.id = this.valueName;
    }
    this.fieldDTO = this.myApplication.textDTOs.get(this.id);
    if (!this.fieldDTO) {
      this.fieldDTO = new FieldTextDTO(this.id, this.id, '');
    }
    this.btnDTO = this.myApplication.textDTOs.get(this.id + '.btnTooltip');

    this.keyValueList.forEach(element => {
      if (!element.value || element.value == '') {
        let textDTO = this.myApplication.textDTOs.get(this.id + '.' + element.key);
        if (textDTO) {
          element.value = textDTO.text;
        } else {
          element.value = this.id + '.' + element.key;
        }
      }
    });

  }

  onClick() {
    this.object[this.btnMethod.toString()]();
  }
}

