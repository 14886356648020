import * as tslib_1 from "tslib";
import { AbstractObject } from '../technical-aspects/abstract-object';
import { SeedEO } from '../entity-objects/seed-eo';
import { Constants } from './constants';
var Seed = /** @class */ (function (_super) {
    tslib_1.__extends(Seed, _super);
    function Seed() {
        var _this = _super.call(this) || this;
        _this.newWithNewSortSeed = false;
        return _this;
    }
    Seed.prototype.update = function () {
    };
    Seed.prototype.initialize = function () {
        this.eo = new SeedEO();
    };
    Object.defineProperty(Seed.prototype, "seedEO", {
        get: function () {
            return this.eo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Seed.prototype, "sortSeedId", {
        get: function () {
            return this.seedEO.sortSeedId;
        },
        set: function (value) {
            this.seedEO.sortSeedId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Seed.prototype, "done", {
        get: function () {
            return this.seedEO.done;
        },
        set: function (value) {
            this.seedEO.done = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Seed.prototype, "sortId", {
        get: function () {
            return this.seedEO.sortId;
        },
        set: function (value) {
            this.seedEO.sortId = value;
        },
        enumerable: true,
        configurable: true
    });
    Seed.prototype.getSort = function () {
        return this.daoSorts.getObjectById(this.sortId);
    };
    Seed.prototype.getSortSeed = function () {
        return this.daoSortSeed.getObjectById(this.sortSeedId);
    };
    Seed.prototype.getManufacuringYear = function () {
        return this.getSortSeed().manufacturingYear;
    };
    Object.defineProperty(Seed.prototype, "quantityUnit", {
        get: function () {
            return this.seedEO.quantityUnit;
        },
        set: function (value) {
            this.seedEO.quantityUnit = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Seed.prototype, "quantity", {
        get: function () {
            return this.seedEO.quantity;
        },
        set: function (value) {
            this.seedEO.quantity = value;
        },
        enumerable: true,
        configurable: true
    });
    Seed.prototype.getNumCorn = function () {
        if (this.quantityUnit == Constants.UNIT_KORN) {
            return this.quantity;
        }
        else {
            if (this.getTKG() > 0) {
                return Math.round(1000 / this.getTKG() * this.quantity);
            }
            else {
                return 0;
            }
        }
    };
    Seed.prototype.getWeight = function () {
        if (this.quantityUnit == Constants.UNIT_GRAMM) {
            return this.quantity;
        }
        else if (this.getTKG() > 0 && this.quantityUnit == Constants.UNIT_KORN) {
            var weight = this.quantity / 1000 * this.getTKG();
            return weight;
        }
        else {
            return 0;
        }
    };
    Seed.prototype.getTKG = function () {
        return this.getSortSeed().tkg;
    };
    Seed.prototype.getSeedValue = function () {
        return this.getSortSeed().seedValue;
    };
    Seed.prototype.getSeedUnit = function () {
        return this.getSortSeed().seedUnit;
    };
    Seed.prototype.getQuantityText = function () {
        return this.quantity + ' ' + this.quantityUnit;
    };
    Object.defineProperty(Seed.prototype, "otherUnit", {
        get: function () {
            return this.seedEO.otherUnit;
        },
        set: function (value) {
            this.seedEO.otherUnit = value;
        },
        enumerable: true,
        configurable: true
    });
    return Seed;
}(AbstractObject));
export { Seed };
