import { Record } from './record';
import { ToDoList } from './todo-list';
import { DAOToDos } from '../dao/dao-todos';
import { ToDo } from './todo';
import { Constants } from './constants';

export class ToDoListRecord extends ToDoList {

    public listWithSeriesMaster: Array<ToDo>;

    constructor(protected daoToDos: DAOToDos, public record: Record) {
        super(daoToDos);

        this.updateList();
    }

    protected updateList() {
        this.toDos = new Array<ToDo>();
        this.listWithSeriesMaster = new Array<ToDo>();
        this.daoToDos.objects.forEach(element => {
            let todo = <ToDo>element;
            if (todo.parentType == Constants.PARENT_TYPE_RECORD) {
                if (todo.parentId == this.record.id) {
                    if (todo.isSeriesMaster() == false) {
                        this.toDos.push(todo);
                    }
                    this.listWithSeriesMaster.push(todo);
                }
            }
        });
    }

    clone(record: Record) {

        //   record.todoList.toDos.forEach(toDo => {
        record.todoList.listWithSeriesMaster.forEach(toDo => {
            if (toDo.toDoType != Constants.TODO_RECORD_DATES) {
                //bei Serienterminen nur den Master clonen, die Serienelemente muessen neu berechnet werden.
                if (!toDo.series || (toDo.series && toDo.isSeriesMaster())) {
                    let newToDo = <ToDo>this.daoToDos.createNewInstance();
                    newToDo.toDoType = toDo.toDoType;
                    newToDo.type = toDo.type;
                    newToDo.time = toDo.time;
                    newToDo.timeReference = toDo.timeReference;
                    newToDo.timeType = toDo.timeType;
                    newToDo.timeUnit = toDo.timeUnit;
                    newToDo.parentType = Constants.PARENT_TYPE_RECORD;
                    newToDo.parentId = this.record.id;
                    newToDo.note = toDo.note;
                    newToDo.person = toDo.person;
                    newToDo.color = toDo.color;
                    newToDo.name = toDo.name;
                    newToDo.quantity = toDo.quantity;
                    newToDo.quantityUnit = toDo.quantityUnit;

                    if (toDo.isSeriesMaster()) {
                        newToDo.seriesMaster = newToDo.id;
                    }
                    newToDo.seriesIntervall = toDo.seriesIntervall;
                    newToDo.seriesIntervallUnit = toDo.seriesIntervallUnit;

                    newToDo.endTime = toDo.endTime;
                    newToDo.endTimeUnit = toDo.endTimeUnit;
                    newToDo.endTimeType = toDo.endTimeType;
                    newToDo.endTimeReference = toDo.endTimeReference;

                    if (toDo.isSeriesMaster()) {
                        newToDo.generateSeriesToDos();
                    }
                }
            }
        });
    }

    deleteRecordDates() {
        let toDelete = new Array<ToDo>();

        this.toDos.forEach(toDo => {
            if (toDo.toDoType == Constants.TODO_RECORD_DATES) {
                toDelete.push(toDo);
            }
        });

        toDelete.forEach(toDo => {
            this.daoToDos.deleteObject(toDo);
        });

        this.updateList();
    }

    deleteAllToDos() {
        let toDelete = new Array<ToDo>();

        this.toDos.forEach(toDo => {
            toDelete.push(toDo);
        });

        toDelete.forEach(toDo => {
            this.daoToDos.deleteObject(toDo);
        });

        this.record.buildCultureDateToDos();
        this.updateList();
        this.record.myApplication.messageDTO.setSuccessWithId('ToDo.deleteAll.Info');
    }
}