import { AbstractObject } from '../technical-aspects/abstract-object';
import { BedAssignEO } from '../entity-objects/bed-assign-eo';
import { PeriodsUIList } from './periods-ui-list';
import { Bed } from './bed';
import { DAOBeds } from '../dao/dao-beds';
import { Record } from './record';
import { DAOAreas } from '../dao/dao-areas';
import { Area } from './area';

export class BedAssign extends AbstractObject {

    private _periodsUIKWBedmeters: PeriodsUIList;
    private _bed: Bed;
    public daoBeds: DAOBeds;
    public daoAreas: DAOAreas
    public record: Record;


    constructor() {
        super();
    }

    update() {

    }

    initialize() {
        this.eo = new BedAssignEO();
    }

    public get startTS(): number {
        return this.bedAssignEO.startTS;
    }
    public set startTS(value: number) {
        this.bedAssignEO.startTS = value;
    }
    
    public get cultureEndTS(): number {
        return this.bedAssignEO.cultureEndTS;
    }
    public set cultureEndTS(value: number) {
        this.bedAssignEO.cultureEndTS = value;
    }

    public get bed(): Bed {
        if (!this._bed) {
            this._bed = <Bed> this.daoBeds.getObjectById(this.bedId);
        }
        return this._bed;
    }
    public set bed(value: Bed) {
        this._bed = value;
    }

    getArea(): Area {
        return <Area> this.daoAreas.getObjectById(this.bed.areaId);
    }

    getAreaId(): String {
        return this.bed.areaId;
    }

    public get periodsUIKWBedmeters(): PeriodsUIList {
        if (!this._periodsUIKWBedmeters) {
            this.buildPeriodsUIKWBedmeters();
        }
        return this._periodsUIKWBedmeters;
    }

    protected buildPeriodsUIKWBedmeters() {
        this._periodsUIKWBedmeters = new PeriodsUIList(this.myApplication, this.basicService, null);
        this._periodsUIKWBedmeters.createForKWBedmeters(this, this.bed, this.record);
    }

    protected get bedAssignEO(): BedAssignEO {
        return <BedAssignEO>this.eo;
    }

    public get parentId(): String {
        return this.bedAssignEO.parentId;
    }
    public set parentId(value: String) {
        this.bedAssignEO.parentId = value;
    }

    public get bedId(): String {
        return this.bedAssignEO.bedId;
    }
    public set bedId(value: String) {
        this.bedAssignEO.bedId = value;
    }

    public get positionOnBed(): number {
        return this.bedAssignEO.positionOnBed;
    }
    public set positionOnBed(value: number) {
        if (value != this.bedAssignEO.positionOnBed) {
            this.bedAssignEO.positionOnBed = value;
            this.buildPeriodsUIKWBedmeters();
        }
    }

    public get lengthOnBed(): number {
        return this.bedAssignEO.lengthOnBed;
    }
    public set lengthOnBed(value: number) {
        if (value != this.bedAssignEO.lengthOnBed) {
            this.bedAssignEO.lengthOnBed = value;
            this.buildPeriodsUIKWBedmeters();
        }
    }
}