import * as tslib_1 from "tslib";
import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';
var SortEO = /** @class */ (function (_super) {
    tslib_1.__extends(SortEO, _super);
    function SortEO() {
        var _this = _super.call(this) || this;
        _this.cultureId = '';
        _this.name = '';
        _this.useOwnPeriods = false;
        _this.seed = 4;
        _this.grow = 6;
        _this.harvest = 2;
        _this.useOwnDistances = false;
        _this.plantDistance = 0;
        _this.rowDistance = 0;
        _this.numRows = 0;
        _this.note = '';
        _this.seedRate = 100;
        _this.useOwnDetails = false;
        _this.useOtherSeedUnit = false;
        return _this;
    }
    return SortEO;
}(AbstractEntityObject));
export { SortEO };
