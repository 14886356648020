import * as tslib_1 from "tslib";
import { ThemeDTO } from './theme-dto';
var GreenThemeDTO = /** @class */ (function (_super) {
    tslib_1.__extends(GreenThemeDTO, _super);
    function GreenThemeDTO() {
        var _this = _super.call(this) || this;
        _this.background = 'bg-success';
        _this.btnOutlineNav = 'btn-outline-light';
        _this.btnOutline = 'btn-outline-success';
        _this.btn = 'btn-success';
        _this.badge = 'badge-success';
        return _this;
    }
    return GreenThemeDTO;
}(ThemeDTO));
export { GreenThemeDTO };
