import { Component, OnInit, Input } from '@angular/core';
import { ApplicationService } from 'src/app/services/application.service';
import { KeyValueDTO } from 'src/app/app-core/dto/key-value-dto';
import { Constants } from 'src/app/app-core/business-logic/constants';
import { SeedSet } from 'src/app/app-core/business-logic/seed-set';
import { Sort } from 'src/app/app-core/business-logic/sort';
import { SortSeed } from 'src/app/app-core/business-logic/sort-seed';
import { Seed } from 'src/app/app-core/business-logic/seed';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-seeds',
  templateUrl: './seeds.component.html',
  styleUrls: ['./seeds.component.css']
})
export class SeedsComponent implements OnInit {

  @Input() seedSet: SeedSet;
  @Input() sort: Sort;
  public unitList = new Array<KeyValueDTO>();
  public years = new Array<KeyValueDTO>();
  public units = new Array<KeyValueDTO>();
  public seedUnits = new Array<KeyValueDTO>();

  public actionCreate: boolean = false;
  public actionDelete: boolean = false;
  public actionEdit: boolean = false;

  constructor(public myApplication: ApplicationService,
    public permissionsService: PermissionsService) {
    this.initUnits();
    this.initSeedUnits();
    this.checkAllowedActions();
  }

  ngOnInit() {
    this.initQuantityUnits();
    this.initYears();
  }

  private initQuantityUnits() {
    this.unitList.push(new KeyValueDTO(Constants.UNIT_GRAMM));
    this.unitList.push(new KeyValueDTO(Constants.UNIT_KORN));
  }

  create(sortSeed: SortSeed) {
    this.initYears();
    this.initUnits();
    this.sort.seedSet.createWithSortSeed(sortSeed);
  }
  createOtherUnit(sortSeed: SortSeed) {
    this.initYears();
    this.initUnits();
    this.initSeedUnits();
    this.sort.seedSet.otherUnit = true;
    this.sort.seedSet.createWithSortSeed(sortSeed);
  }

  createNew(otherUnit: boolean) {
    this.initYears();
    let sortSeed = this.sort.sortSeedSet.createSortSeed();
    sortSeed.otherUnit = otherUnit;
    let seed = this.sort.seedSet.createWithSortSeed(sortSeed);
    seed.newWithNewSortSeed = true;
    this.myApplication.save();
  }


  delete(seed: Seed) {
    this.sort.seedSet.deleteSeed(seed);
  }

  get all(): boolean {
    return this.seedSet.all;
  }

  set all(value: boolean) {
    this.seedSet.all = value;
  }

  private initYears() {
    this.years = new Array<KeyValueDTO>();
    let keyValueDTO: KeyValueDTO
    for (let i = 2018; i < this.myApplication.year + 15; i++) {
      keyValueDTO = new KeyValueDTO(i.toString(), i.toString());
      if (this.sort.sortSeedSet.yearExists(i)) {
        // keyValueDTO.disabled = true;
        keyValueDTO.italic = true;
      }
      this.years.push(keyValueDTO);
    }
  }

  modelChanged(value) {
    if (value == 'manufacturingYear') {
      this.initYears();
    }
  }


  private initUnits() {
    this.units = new Array<KeyValueDTO>();

    this.units.push(new KeyValueDTO('a', this.myApplication.getText('ToDo.quantity.a')));
    this.units.push(new KeyValueDTO('b', this.myApplication.getText('ToDo.quantity.b')));
    this.units.push(new KeyValueDTO('c', this.myApplication.getText('ToDo.quantity.c')));
    this.units.push(new KeyValueDTO('d', this.myApplication.getText('ToDo.quantity.d')));
    this.units.push(new KeyValueDTO('j', this.myApplication.getText('ToDo.quantity.j')));
    this.units.push(new KeyValueDTO('k', this.myApplication.getText('ToDo.quantity.k')));
  }

  private initSeedUnits() {
    this.seedUnits = new Array<KeyValueDTO>();

    this.seedUnits.push(new KeyValueDTO('kg', this.myApplication.getText('Unit.kg')));
    this.seedUnits.push(new KeyValueDTO('g', this.myApplication.getText('Unit.g')));
  }

  checkAllowedActions() {
    this.actionCreate = this.permissionsService.checkPermissionForAction('seeds', 'create', 'seedInventory');
    this.actionDelete = this.permissionsService.checkPermissionForAction('seeds', 'delete', 'seedInventory');
    this.actionEdit = this.permissionsService.checkPermissionForAction('seeds', 'edit', 'seedInventory');
  }

  checkIfTkgIsRelevant() {
    //Wenn TKG an der Sorte in irgendeinem Jahr gepflegt, dann anzeigen
    //und wenn keine abweichende Einheit gepflegt, dann ebenfalls anzeigen
    let isRelevant: boolean = false;
    if (this.sort.checkIfTkgIsUsed()) {
      isRelevant = true;
    } else if (!this.sort.checkIfOtherUnitIsUsed()) {
      isRelevant = true;
    }
    return isRelevant;
  }

  checkIfOtherUnitIsRelevant() {
    //Tabelle fuer abweichende Einheiten nur anzeigen, wenn an der Sorte welche gepflegt sind
    let isRelevant: boolean = false;
    if (this.sort.checkIfOtherUnitIsUsed()) {
      isRelevant = true;
    }
    return isRelevant;
  }

}