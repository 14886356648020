<ng-template #deleteConfirm>
  <app-confirm [callbackOwner]="callbackOwner" [callback]="callback" [object]="object" [question]="confirmQuestion">
  </app-confirm>
</ng-template>

<ng-container *ngIf="iconName == 'trash-alt'">
  <button [id]="id" class="btn btn-sm mr-1 {{silentClass}}" [ngClass]="btnClass" type="button" [disabled]="disable"
    [ngbPopover]="deleteConfirm" [autoClose]="true" [popoverTitle]="popupTitle" #popover="ngbPopover"
    [attr.data-dismiss]="dataDismiss" (click)="stopEvent($event)" container="body">
    <fa *ngIf="iconName" [name]="iconName" [ngbTooltip]="fieldDTO.help" data-placement="bottom"></fa> {{ fieldDTO.text }}
  </button>
</ng-container>
<ng-container *ngIf="iconName != 'trash-alt'">
  <button [id]="id" class="btn btn-sm mr-1 {{silentClass}}" [ngClass]="btnClass" type="button"
    [disabled]="disable" (click)="onClick($event)" [attr.data-dismiss]="dataDismiss" data-placement="bottom"
    [ngbTooltip]="fieldDTO.help" container="body">
    <fa *ngIf="iconName" [name]="iconName"></fa> {{ fieldDTO.text }}
  </button>
</ng-container>