import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';

export class PersonEO extends AbstractEntityObject {

    public name: String;
    public avatar: String;
    public workHours: number;
    public uname: String;

    constructor() {
        super();
        this.name = '';
        this.avatar = '017-question-4.png';
        this.workHours = 40;
        this.uname = '';
    }
  }