import * as tslib_1 from "tslib";
import { DAO } from '../technical-aspects/dao';
import { Seed } from '../business-logic/seed';
var DAOSeeds = /** @class */ (function (_super) {
    tslib_1.__extends(DAOSeeds, _super);
    function DAOSeeds() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DAOSeeds.prototype.createInstance = function () {
        var newInstance = new Seed();
        newInstance.daoSorts = this.daoService.getDAO('sorts');
        newInstance.daoSortSeed = this.daoService.getDAO('sortSeeds');
        return newInstance;
    };
    DAOSeeds.prototype.getSeedsForSortSeed = function (id) {
        var list = new Array();
        this.objects.forEach(function (object) {
            var seed = object;
            if (seed.sortSeedId == id) {
                list.push(seed);
            }
        });
        return list;
    };
    return DAOSeeds;
}(DAO));
export { DAOSeeds };
