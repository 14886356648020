import { environment } from '../../environments/environment';
import { LoginDTO } from '../app-core/dto/login-dto';
import { Http, RequestOptions, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { ChangePWDTO } from '../app-core/dto/change-pw-dto';
import { ApplicationService } from './application.service';
import { InvitationDTO } from '../app-core/dto/invitation-dto';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RepositoryChooseComponent } from '../ui-components/repository-choose/repository-choose.component';
import { CookieService } from 'ngx-cookie-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
import * as i2 from "./application.service";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "ngx-cookie-service";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(http, myApplication, modalService, cookieService) {
        this.http = http;
        this.myApplication = myApplication;
        this.modalService = modalService;
        this.cookieService = cookieService;
        this.isOK = false;
        this.isInCheck = true;
        this.inLoginProcess = false;
        this.roles = new Array();
        this.needsAgreement = false;
        this.inChangeProcess = false;
    }
    AuthenticationService.prototype.hasRole = function (roleName) {
        var i = 0;
        var role = this.roles[i];
        while (role) {
            if (role == roleName) {
                return true;
            }
            i = i + 1;
            role = this.roles[i];
        }
        return false;
    };
    AuthenticationService.prototype.readTokenFromLocal = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var login = new Object();
            login['token'] = _this.cookieService.get('Gemuese-Anbauplaner.token');
            login['repid'] = _this.cookieService.get('Gemuese-Anbauplaner.repid');
            if (login['token']) {
                _this.validateToken(login['token']).then(function (validation) {
                    if (!validation) {
                        _this.logout();
                        reject();
                        return;
                    }
                    _this.loginData = new LoginDTO();
                    _this.loginData.uname = _this.cookieService.get('Gemuese-Anbauplaner.uname');
                    _this.setRepository(login);
                    resolve();
                });
            }
            else {
                reject();
            }
        });
    };
    // public set daoService(value: DaoService) {
    //   this._daoService = value;
    //   let login = new Object();
    //   login['token'] = this.cookieService.get('Gemuese-Anbauplaner.token');
    //   login['repid'] = this.cookieService.get('Gemuese-Anbauplaner.repid');
    //   if (login['token']) {
    //     this.validateToken(login['token']).then((validation: any) => {
    //       if (!validation) {
    //         this.logout();
    //         return;
    //       }
    //       this.loginData = new LoginDTO();
    //       this.loginData.uname = this.cookieService.get('Gemuese-Anbauplaner.uname');
    //       this.setRepository(login);
    //       this.isInCheck = false;
    //       // this.refreshToken();
    //     });
    //   } else {
    //     this.isInCheck = false;
    //   }
    // }
    AuthenticationService.prototype.getHeaders = function () {
        var header = new Headers({
            'Content-Type': 'application/json'
        });
        return header;
    };
    AuthenticationService.prototype.getHeadersAuthenticated = function () {
        var header = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.token
        });
        return header;
    };
    AuthenticationService.prototype.validateToken = function (token) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var options = new RequestOptions();
            var header = new Headers({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            });
            options.headers = header;
            _this.http.get(environment.apiV2 + 'tokenValidation', options).pipe(map(function (resp) { return resp.json(); }))
                .subscribe(function (res) {
                resolve(res.validation);
            }, function (err) {
                resolve(false);
            });
        });
    };
    AuthenticationService.prototype.refreshToken = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var options = new RequestOptions();
            var header = new Headers({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + _this.token
            });
            options.headers = header;
            _this.http.patch(environment.apiV2 + 'token', null, options).pipe(map(function (resp) { return resp.json(); }))
                .subscribe(function (res) {
                resolve(res.newToken);
            }, function (err) {
                // this.logout();
            });
        });
    };
    AuthenticationService.prototype.logout = function () {
        localStorage.removeItem('tokenV2');
        this.cookieService.delete('Gemuese-Anbauplaner.repid', '/');
        this.cookieService.delete('Gemuese-Anbauplaner.token', '/');
        this.cookieService.delete('Gemuese-Anbauplaner.uname', '/');
        this.cookieService.delete('Gemuese-Anbauplaner.repid', '/test');
        this.cookieService.delete('Gemuese-Anbauplaner.token', '/test');
        this.cookieService.delete('Gemuese-Anbauplaner.uname', '/test');
        this.cookieService.delete('Gemuese-Anbauplaner.repid', '/cons');
        this.cookieService.delete('Gemuese-Anbauplaner.token', '/cons');
        this.cookieService.delete('Gemuese-Anbauplaner.uname', '/cons');
        this.cookieService.delete('Gemuese-Anbauplaner.repid', '/app');
        this.cookieService.delete('Gemuese-Anbauplaner.token', '/app');
        this.cookieService.delete('Gemuese-Anbauplaner.uname', '/app');
        var options = new RequestOptions();
        options.headers = this.getHeadersAuthenticated();
        if (this.loginData) {
            this.http.delete(environment.apiV2 + 'login', options).pipe()
                .subscribe(function (res) {
            }, function (err) {
                //this.errorText = err; egal
            });
        }
        this.isOK = false;
        this.token = null;
        this.isInCheck = false;
    };
    AuthenticationService.prototype.sendAgreement = function (agreementDTO) {
        var _this = this;
        var options = new RequestOptions();
        options.headers = this.getHeadersAuthenticated();
        var payload = JSON.stringify(agreementDTO);
        this.errorText = "";
        this.http.post(environment.apiV2 + 'agreement', payload, options).pipe(map(function (resp) { return resp.json(); }))
            .subscribe(function (res) {
            if (!res['error']) {
                _this.myApplication.messageDTO.setSuccessWithId('ThanksALot');
            }
            else {
            }
        }, function (err) {
            _this.errorText = err;
            console.log(err);
        });
    };
    AuthenticationService.prototype.userActivation = function (id) {
        var _this = this;
        var options = new RequestOptions();
        options.headers = this.getHeaders();
        var payload = JSON.stringify(new InvitationDTO(id));
        this.errorText = "";
        this.http.post(environment.apiV2 + 'userActivation', payload, options).pipe(map(function (resp) { return resp.json(); }))
            .subscribe(function (res) {
            if (!res['error']) {
                _this.successText = _this.myApplication.getTextSimple('UserHasBeenActivated');
            }
            else {
                _this.errorText = _this.myApplication.getTextSimple('FM.UserHasNotBeenActivated');
            }
        }, function (err) {
            _this.errorText = err;
        });
    };
    AuthenticationService.prototype.invitationAccept = function (id) {
        var _this = this;
        var options = new RequestOptions();
        options.headers = this.getHeaders();
        var payload = JSON.stringify(new InvitationDTO(id));
        this.errorText = "";
        this.http.post(environment.apiV2 + 'invitationAcceptance', payload, options).pipe(map(function (resp) { return resp.json(); }))
            .subscribe(function (res) {
            if (!res['error']) {
                _this.myApplication.messageDTO.setSuccessWithId('InvitationAccepted');
            }
            else {
                _this.myApplication.messageDTO.setErrorWithId('FM.InvitationNotAccepted');
            }
        }, function (err) {
            _this.errorText = err;
        });
    };
    AuthenticationService.prototype.sendInvitation = function (uname) {
        var _this = this;
        var login = new LoginDTO();
        login.uname = uname;
        var options = new RequestOptions();
        options.headers = this.getHeadersAuthenticated();
        var payload = JSON.stringify(login);
        this.errorText = "";
        this.http.post(environment.apiV2 + 'invitation', payload, options).pipe(map(function (resp) { return resp.json(); }))
            .subscribe(function (res) {
            if (!res['error']) {
                _this.myApplication.messageDTO.setSuccessWithId('InvitationSent');
            }
            else {
                _this.myApplication.messageDTO.setErrorWithId('FM.InvitationNotSent');
            }
        }, function (err) {
            _this.errorText = err;
        });
    };
    AuthenticationService.prototype.pwReset = function (login) {
        var _this = this;
        this.successText = null;
        var options = new RequestOptions();
        options.headers = this.getHeaders();
        var payload = JSON.stringify(login);
        this.errorText = "";
        this.http.post(environment.apiV2 + 'passwordReset', payload, options).pipe(map(function (resp) { return resp.json(); }))
            .subscribe(function (res) {
            if (!res['error']) {
                _this.successText = _this.myApplication.getTextSimple('PWForgotOK');
            }
            else {
                _this.errorText = res['message'];
            }
            if (_this.loginData) {
                _this.loginData.password = '';
            }
        }, function (err) {
            _this.errorText = err;
            if (_this.loginData) {
                _this.loginData.password = '';
            }
        });
    };
    AuthenticationService.prototype.sendActivationLink = function (login) {
        var _this = this;
        if (!login.uname || login.uname.length == 0) {
            this.errorText = this.myApplication.getTextSimple('FM.insertEMail');
            return;
        }
        var options = new RequestOptions();
        options.headers = this.getHeaders();
        var payload = JSON.stringify(login);
        this.errorText = "";
        this.http.post(environment.apiV2 + 'activationMail', payload, options).pipe(map(function (resp) { return resp.json(); }))
            .subscribe(function (res) {
            _this.successText = _this.myApplication.getTextSimple('ActivationMail.sent');
        }, function (err) {
            _this.errorText = _this.myApplication.getTextSimple('FM.activationMail');
        });
    };
    AuthenticationService.prototype.login = function (login) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            localStorage.removeItem('tokenV2');
            _this.successText = null;
            _this.inLoginProcess = true;
            _this.loginData = login;
            var options = new RequestOptions();
            options.headers = _this.getHeaders();
            var payload = JSON.stringify(login);
            _this.errorText = "";
            _this.http.post(environment.apiV2 + 'login', payload, options).pipe(map(function (resp) { return resp.json(); }))
                .subscribe(function (res) {
                if (res['error']) {
                    _this.errorText = res['message'];
                    _this.inLoginProcess = false;
                    _this.loginData.password = '';
                    return;
                }
                _this.token = res['token'];
                //permissions übernehmen
                // let roleList = res['permissions'];
                // let i = 0;
                // let role = roleList[0];
                // while (role) {
                //   this.roles.push(role['roleName']);
                //   i = i + 1;
                //   role = roleList[i];
                // }
                _this.possibleLogins = res['repositorys'];
                _this.needsAgreement = false;
                if (res['needsAgreement']) {
                    _this.needsAgreement = true;
                }
                if (res['language'] && res['language'] != _this.myApplication.selectedLanguage) {
                    _this.myApplication.changeLanguage(res['language']);
                }
                if (_this.possibleLogins.length > 1) {
                    _this.inLoginProcess = false;
                    _this.loginData.password = '';
                    var modalRef = _this.modalService.open(RepositoryChooseComponent);
                    modalRef.componentInstance.possibleRepositorys = _this.possibleLogins;
                    modalRef.componentInstance.authenticationService = _this;
                    modalRef.componentInstance.resolve = resolve;
                    return;
                }
                if (_this.possibleLogins.length == 1) {
                    _this.setRepository(_this.possibleLogins[0]);
                    _this.inLoginProcess = false;
                    _this.loginData.password = '';
                    resolve(null);
                }
                reject();
            }, function (err) {
                _this.errorText = err;
                _this.inLoginProcess = false;
                _this.loginData.password = '';
                reject();
            });
        });
    };
    AuthenticationService.prototype.setRepository = function (login) {
        localStorage.setItem('tokenV2', login.token);
        this.cookieService.set('Gemuese-Anbauplaner.repid', login.repid, 3);
        this.cookieService.set('Gemuese-Anbauplaner.token', login.token, 3);
        this.cookieService.set('Gemuese-Anbauplaner.uname', this.loginData.uname.toString(), 3);
        if (localStorage.getItem('tokenV2') != login.token) {
            localStorage.clear();
            localStorage.setItem('tokenV2', login.token);
        }
        this.loginData.id = login.repid;
        this.token = login.token;
        this.possibleLogins = null;
        this.loginData.password = '';
        this.isOK = true;
    };
    AuthenticationService.prototype.changePW = function (oldPassword, newPassword) {
        var _this = this;
        this.inChangeProcess = true;
        var changeDTO = new ChangePWDTO();
        changeDTO.oldPassword = oldPassword;
        changeDTO.newPassword = newPassword;
        var options = new RequestOptions();
        options.headers = this.getHeadersAuthenticated();
        var payload = JSON.stringify(changeDTO);
        this.errorText = "";
        this.http.post(environment.apiV2 + 'userCredentials', payload, options).pipe(map(function (resp) { return resp.json(); }))
            .subscribe(function (res) {
            if (!res['error']) {
                _this.myApplication.messageDTO.setSuccessWithId('PWChanged');
            }
            else {
                _this.myApplication.messageDTO.setErrorWithId('FM.PWoldWrong');
            }
            _this.inChangeProcess = false;
        }, function (err) {
            _this.myApplication.messageDTO.setErrorWithId('FM.PWChangeFailure');
            _this.inChangeProcess = false;
        });
    };
    AuthenticationService.prototype.registerRepository = function (registerDTO, callback) {
        var options = new RequestOptions();
        options.headers = this.getHeadersAuthenticated();
        var country = this.myApplication.getCountry(registerDTO.country);
        if (country) {
            registerDTO.countryCode = country.code;
        }
        else {
            registerDTO.countryCode = '';
        }
        var payload = JSON.stringify(registerDTO);
        var json = JSON.parse(payload);
        json.country = registerDTO.country;
        if (!json.legalForm) {
            json.legalForm = null;
        }
        json.language = this.myApplication.selectedLanguage;
        this.http.post(environment.apiV2 + 'registration', json, options).pipe(map(function (resp) { return resp.json(); }))
            .subscribe(function (res) {
            if (res['repoId']) {
                callback.registrationDone(res['repoId']);
            }
            else {
                callback.registrationDone('');
            }
        }, function (err) {
            callback.registrationDone('');
        });
    };
    AuthenticationService.ngInjectableDef = i0.defineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.inject(i1.Http), i0.inject(i2.ApplicationService), i0.inject(i3.NgbModal), i0.inject(i4.CookieService)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
