import * as tslib_1 from "tslib";
import { ToDoList } from './todo-list';
import { Constants } from './constants';
var ToDoListBed = /** @class */ (function (_super) {
    tslib_1.__extends(ToDoListBed, _super);
    function ToDoListBed(daoToDos, bed, daoBeds) {
        var _this = _super.call(this, daoToDos) || this;
        _this.daoToDos = daoToDos;
        _this.bed = bed;
        // this.daoToDos.objects.forEach(element => {
        //     let todo = <ToDo>element;
        //     if (todo.parentType == Constants.PARENT_TYPE_BED) {
        //         if (todo.parentId == this.bed.id) {
        //             if (todo.isSeriesMaster() == false) {
        //                 this.toDos.push(todo);
        //             }
        //         }
        //     }
        // });
        _this.daoBeds = daoBeds;
        _this.daoToDos.objects.forEach(function (element) {
            var todo = element;
            if (todo.parentType == Constants.PARENT_TYPE_AREA) {
                if (todo.parentId == _this.bed.areaId) {
                    if (todo.bedIdStart && todo.bedIdStart == _this.bed.id) {
                        if (todo.isSeriesMaster() == false) {
                            _this.toDos.push(todo);
                        }
                    }
                    else if (todo.bedIdEnd && todo.bedIdEnd == _this.bed.id) {
                        if (todo.isSeriesMaster() == false) {
                            _this.toDos.push(todo);
                        }
                    }
                    else if (todo.bedIdStart && todo.bedIdEnd && todo.bedIdStart != '' && todo.bedIdEnd != '') {
                        if (_this.daoBeds.getObjectById(todo.bedIdStart).orderNum <= _this.bed.orderNum &&
                            _this.bed.orderNum <= _this.daoBeds.getObjectById(todo.bedIdEnd).orderNum) {
                            if (todo.isSeriesMaster() == false) {
                                _this.toDos.push(todo);
                            }
                        }
                    }
                }
            }
        });
        return _this;
    }
    return ToDoListBed;
}(ToDoList));
export { ToDoListBed };
