<ng-container *ngIf="!withoutTitle">
  <div class="form-row">
    <div class="form-group col-sm-4" *ngIf="fieldDTO.text != ''">
      <label class="col-form-label col-form-label-sm text-secondary" [for]="id">{{ fieldDTO.text }}</label>
    </div>
    <div class="col-sm-8">

      <div class="input-group input-group-sm" [ngbTooltip]="fieldDTO.help" placement="top" [openDelay]="1000">
        <select class="form-control form-control-sm" [disabled]="disable" [id]="id"
          [(ngModel)]="object[valueName.toString()]" (ngModelChange)="modelChanged(valueName)">
          <ng-container *ngFor="let keyValue of keyValueList">
            <option *ngIf="!keyValue.disabled && !keyValue.italic" [value]="keyValue.key">{{ keyValue.value }}</option>
            <option *ngIf="keyValue.disabled  && !keyValue.italic" [value]="keyValue.key" disabled>{{ keyValue.value }}
            </option>
            <option *ngIf="!keyValue.disabled && keyValue.italic" [value]="keyValue.key" class="italicStyle">{{
              keyValue.value }}</option>
          </ng-container>
        </select>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="withoutTitle">
  <div class="form-row">
    <div class="col-sm-12">

      <div class="input-group input-group-sm" [ngbTooltip]="fieldDTO.help" placement="top" [openDelay]="1000">
        <select class="form-control form-control-sm" [disabled]="disable" [id]="id"
          [(ngModel)]="object[valueName.toString()]" (ngModelChange)="modelChanged(valueName)">
          <ng-container *ngFor="let keyValue of keyValueList">
            <option *ngIf="!keyValue.disabled && !keyValue.italic" [value]="keyValue.key">{{ keyValue.value }}</option>
            <option *ngIf="keyValue.disabled  && !keyValue.italic" [value]="keyValue.key" disabled>{{ keyValue.value }}
            </option>
            <option *ngIf="!keyValue.disabled && keyValue.italic" [value]="keyValue.key" class="italicStyle">{{
              keyValue.value }}</option>
          </ng-container>
        </select>
      </div>
    </div>
  </div>
</ng-container>