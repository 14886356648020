export class AbstractEntityObject {
    public id: String;
    public db: any;

    constructor() {
        this.id = '';
    }

    clone(): any {
        let attributeNames = Object.getOwnPropertyNames(this)

        let cloneEo = new Object();

        let attName;

        for (let i = 0; i < attributeNames.length; i++) {
            attName = attributeNames[i];
            if (attName.includes("_")) {
                attName = attName.substring(1);
            }
            if (attName.includes("Date")) {
                cloneEo[attName] = new Date();
                cloneEo[attName].setTime(this[attName].getTime());
            } else {
                cloneEo[attName] = this[attName];
            }
        }
        cloneEo['db'] = null;
        return cloneEo;
    }
}