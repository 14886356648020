import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';

export class PartsEO extends AbstractEntityObject{

    public startWeek: number;
    public startYear: number;
    public endWeek: number;
    public endYear: number;
    public parts: number;

    constructor() {
        super();
        this.startWeek = 0;
        this.endWeek = 0;
        this.startYear = 0;
        this.endYear = 0;
        this.parts = 0;
    }
  }