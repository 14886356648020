import { Save } from './save';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

export class SaveUpdate extends Save {

    save() {
        this.http.patch(this.route, this.data, this.options).pipe(
        )
            .subscribe(
                () => {

                },
                err => {
                    console.log('There was an error updating ressource ' + this.route + '.', err);
                }
            );
    }

    writeError(error: HttpErrorResponse) {
        console.log('error');
    }
}