import { Part } from './part';
import { WeekInYearDTO } from '../dto/week-in-year-dto';

export class FilteredParts {

    public currentKW: number;
    public currentYear: number;

    constructor() {
        // this.currentYear = new Date().getFullYear();
        // this.currentKW = weekNumber(new Date());
    }


    public getPartsForPartType(partTypeId: String, parts: Array<Part>): Array<Part> {
        let filteredParts: Array<Part> = new Array();
        parts.forEach(element => {
            if (element.partTypeId == partTypeId) {
                filteredParts.push(element);
            }
        });
        return filteredParts;
    }

    public getCurrentPartsNumParts(parts: Array<Part>, week: WeekInYearDTO): number {
        this.currentYear = week.year;
        this.currentKW = week.week;
        let relevantParts: Array<Part> = new Array();
        let currentNumPart: number = 0;
        parts.forEach(element => {
            if ((element.startKW <= this.currentKW && element.startYear <= this.currentYear || this.currentYear > element.startYear) &&
                (element.endKW >= this.currentKW && element.endYear >= this.currentYear || this.currentYear < element.endYear)) {
                relevantParts.push(element);
            }
        });
        relevantParts.forEach(element => {
            currentNumPart = currentNumPart + element.numParts;
        });
        return currentNumPart;
    }


} 