import * as tslib_1 from "tslib";
import { Observable } from '../technical-aspects/observable';
import { EventHandler } from '../technical-aspects/event-handler';
var BedAssignList = /** @class */ (function (_super) {
    tslib_1.__extends(BedAssignList, _super);
    function BedAssignList(parentId, record, daoBedAssign, myApplication) {
        var _this = _super.call(this) || this;
        _this.parentId = parentId;
        _this.record = record;
        _this.daoBedAssign = daoBedAssign;
        _this.bedAssigns = new Array();
        _this.buildList();
        _this.handleRecordDatesChanged();
        myApplication.eventHandler.register(EventHandler.EVENT_RECORD_DATES_CHANGED, _this);
        return _this;
    }
    BedAssignList.prototype.handleRecordDatesChanged = function () {
        var _this = this;
        this.bedAssigns.forEach(function (bedAssign) {
            _this.setTS(bedAssign);
        });
    };
    BedAssignList.prototype.setTS = function (bedAssign) {
        bedAssign.startTS = this.record.recordPeriods.growDate.getTime() / 1000;
        bedAssign.cultureEndTS = this.record.recordPeriods.getCultureEnd().getTime() / 1000;
    };
    BedAssignList.prototype.deleteAll = function () {
        var _this = this;
        this.bedAssigns.forEach(function (element) {
            _this.daoBedAssign.deleteObject(element);
        });
        this.bedAssigns = new Array();
    };
    BedAssignList.prototype.getAreas = function () {
        var _this = this;
        var areas = new Array();
        this.bedAssigns.forEach(function (bedAssign) {
            var area = bedAssign.getArea();
            if (area && !_this.areaExists(area, areas)) {
                areas.push(area);
            }
        });
        return areas;
    };
    BedAssignList.prototype.areaExists = function (area, areas) {
        for (var i = 0; i < areas.length; i++) {
            if (area == areas[i]) {
                return true;
            }
        }
        return false;
    };
    BedAssignList.prototype.removeAllBedAssigns = function () {
        var _this = this;
        this.bedAssigns.forEach(function (element) {
            _this.daoBedAssign.deleteObject(element);
            element.bed.buildRecordSet();
            _this.record.periodsUIAreaBed = null;
        });
        this.buildList();
    };
    BedAssignList.prototype.isOnBed = function (bedId) {
        var found = false;
        this.bedAssigns.forEach(function (bedAssign) {
            if (bedAssign.bedId == bedId) {
                found = true;
            }
        });
        return found;
    };
    BedAssignList.prototype.isOnArea = function (areaId) {
        var found = false;
        this.bedAssigns.forEach(function (bedAssign) {
            if (bedAssign.getArea().id == areaId) {
                found = true;
            }
        });
        return found;
    };
    BedAssignList.prototype.getAssignment = function (bed) {
        var assignment;
        for (var i = 0; i < this.bedAssigns.length; i++) {
            assignment = this.bedAssigns[i];
            if (assignment.bedId == bed.id) {
                return assignment;
            }
        }
        return null;
    };
    BedAssignList.prototype.getPositionOnBed = function (bedId) {
        var position = -1;
        this.bedAssigns.forEach(function (bedAssign) {
            if (bedAssign.bedId == bedId) {
                position = bedAssign.positionOnBed;
            }
        });
        return position;
    };
    BedAssignList.prototype.getLengthOnBed = function (bedId) {
        var length = -1;
        this.bedAssigns.forEach(function (bedAssign) {
            if (bedAssign.bedId == bedId) {
                length = bedAssign.lengthOnBed;
            }
        });
        return length;
    };
    BedAssignList.prototype.add = function (bedAssign) {
        bedAssign.record = this.record;
        this.bedAssigns.push(bedAssign);
    };
    BedAssignList.prototype.plantOnBed = function (bed, positionOnBed, lengthOnBed, record, planForward) {
        var bedAssign = this.getAssignment(bed);
        var neu = false;
        if (bedAssign == null) {
            bedAssign = this.daoBedAssign.createNewInstance();
            this.add(bedAssign);
            neu = true;
        }
        bedAssign.parentId = record.id;
        bedAssign.record = record;
        bedAssign.bedId = bed.id;
        this.setTS(bedAssign);
        if (neu) {
            if (planForward) {
                bedAssign.positionOnBed = positionOnBed;
            }
            else {
                bedAssign.positionOnBed = positionOnBed - lengthOnBed + 1;
                if (bedAssign.positionOnBed < 1) {
                    bedAssign.positionOnBed = 1;
                }
            }
        }
        bedAssign.lengthOnBed = bedAssign.lengthOnBed + lengthOnBed;
        if (bedAssign.positionOnBed + bedAssign.lengthOnBed - 1 > bed.length) {
            bedAssign.lengthOnBed = bed.length - bedAssign.positionOnBed + 1;
        }
        this.record.periodsUIAreaBed = null;
        bed.buildRecordSet();
    };
    BedAssignList.prototype.plant = function (recordAssignDTO) {
        var bed = recordAssignDTO.getStartBed();
        if (!bed) {
            this.record.myApplication.messageDTO.setErrorWithId('FM.noStartBedFound');
            return;
        }
        this.record.areaId = bed.areaId;
        var notPlannedLength = this.getLengthUnPlanned();
        var positionOnBed = recordAssignDTO.positionOnBed;
        if (positionOnBed > bed.length) {
            this.record.myApplication.messageDTO.setErrorWithId('FM.bedFull', bed.length);
            return;
        }
        while (notPlannedLength > 0 && bed) {
            var freeOnBed = this.calculateFreeSpaceOnBed(bed, positionOnBed, recordAssignDTO.planForward);
            var planOnBed = notPlannedLength;
            if (notPlannedLength > freeOnBed) {
                planOnBed = freeOnBed;
            }
            this.plantOnBed(bed, positionOnBed, planOnBed, this.record, recordAssignDTO.planForward);
            if (!this.isFullPlanned() && recordAssignDTO.planNext) {
                notPlannedLength = this.getLengthUnPlanned();
                if (recordAssignDTO.planForward) {
                    positionOnBed = 1;
                    bed = bed.getNextBedOnArea();
                }
                else {
                    bed = bed.getPreviousBedOnArea();
                    positionOnBed = bed.length;
                }
            }
            else {
                notPlannedLength = 0;
                bed = null;
            }
        }
        if (this.getLengthUnPlanned() > 0) {
            this.record.myApplication.messageDTO.setWarningWithId('FM.bedMToBig');
        }
    };
    BedAssignList.prototype.getLengthUnPlanned = function () {
        return this.record.lengthOnBed - this.getLengthPlanned();
    };
    BedAssignList.prototype.calculateFreeSpaceOnBed = function (bed, positionOnBed, planForward) {
        console.log(planForward);
        if (planForward) {
            return bed.length - positionOnBed + 1;
        }
        else {
            return positionOnBed;
        }
    };
    BedAssignList.prototype.delete = function (bedAssign) {
        this.daoBedAssign.deleteObject(bedAssign);
    };
    BedAssignList.prototype.removeFromBed = function (bedId) {
        var _this = this;
        var bed;
        this.bedAssigns.forEach(function (bedAssign) {
            if (bedAssign.bedId == bedId) {
                bed = bedAssign.bed;
                _this.daoBedAssign.deleteObject(bedAssign);
            }
        });
        this.buildList();
        bed.buildRecordSet();
    };
    BedAssignList.prototype.buildList = function () {
        var _this = this;
        this.bedAssigns = new Array();
        this.daoBedAssign.objects.forEach(function (element) {
            var bedAssign = element;
            if (bedAssign.parentId == _this.parentId) {
                _this.add(bedAssign);
            }
        });
    };
    BedAssignList.prototype.isFullPlanned = function () {
        var sum = this.getLengthPlanned();
        if (sum >= this.record.lengthOnBed && this.record.lengthOnBed > 0) {
            return true;
        }
        else {
            return false;
        }
    };
    BedAssignList.prototype.getLengthPlanned = function () {
        var sum = 0;
        this.bedAssigns.forEach(function (bedAssign) {
            sum = sum + bedAssign.lengthOnBed;
        });
        return sum;
    };
    BedAssignList.prototype.isAnywherePlanned = function () {
        if (this.bedAssigns.length > 0) {
            return true;
        }
        else {
            return false;
        }
    };
    BedAssignList.prototype.moveToBed = function (from, to) {
        var assignment = this.getAssignment(from);
        if (!assignment) {
            return true;
        }
        if (this.fitsOnBed(assignment, to)) {
            assignment.bedId = to.id;
            this.buildList();
            from.buildRecordSet();
            to.buildRecordSet();
            return true;
        }
        return false;
    };
    BedAssignList.prototype.fitsOnBed = function (assignment, to) {
        return (to.length >= (assignment.positionOnBed + assignment.lengthOnBed - 1));
    };
    return BedAssignList;
}(Observable));
export { BedAssignList };
