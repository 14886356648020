import { Component, Injectable, OnInit } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { DaoService } from './services/dao.service';
import { ApplicationService } from './services/application.service';
import { DAOToDos } from './app-core/dao/dao-todos';
import { Record } from './app-core/business-logic/record';
import { DAORecords } from './app-core/dao/dao-records';
import { RepositoryService } from './services/repository.service';
import { Router, ActivatedRoute, Params, UrlSegment, NavigationEnd, NavigationStart } from '@angular/router';
import { ModalService } from './services/modal.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { PermissionsService } from './services/permissions.service';
import { WizardService } from './services/wizard.service';
import { AppInitializerService } from './services/app-initializer.service';
import { timer } from 'rxjs';
import { HelpingDataService } from './services/helping-data.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements EventListenerObject, OnInit {

  showWithoutHead = false;
  localTokenChecked = false;

  bulkIds = [];


  constructor(public authentication: AuthenticationService, public daoService: DaoService, public myApplication: ApplicationService,
    public repoService: RepositoryService, private router: Router, private myModalService: ModalService,
    private modalService: NgbModal, public permissionsSrv: PermissionsService,
    private wizardSrv: WizardService, public appInitSrv: AppInitializerService,
    public helper: HelpingDataService) {

    window.addEventListener('message', this);

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if ((e.url == '/unsubscribe')) {
          this.showWithoutHead = true;
        }
      }
    });
  }

  ngOnInit(): void {
    this.myApplication.readTexts().then(() => {
      this.authentication.readTokenFromLocal()
        .then(() => {

          this.router.events.subscribe((e) => {
            if (e instanceof NavigationStart) {
              this.helper.deleteBackFunctions();
            }
          });

          this.appInitSrv.initialize();
        })
        .finally(() => {
          this.localTokenChecked = true;
        });
    });

    if (!environment.production) { //token 2 x verteilen
      timer(5000).subscribe(() => {
        this.sendToken();
        timer(5000).subscribe(() => {
          this.sendToken();
        });
      });
    }
  }

  private sendToken() {
    let list = document.getElementsByTagName('iframe');
    for (let i = 0, max = list.length; i < max; i++) {
      let iframe = <HTMLIFrameElement>list[i];
      iframe.contentWindow.postMessage({ "message": "sendToken", "token": this.authentication.token }, '*');
    }
  }

  public getDAOToDos(): DAOToDos {
    return <DAOToDos>this.daoService.getDAO('todos');
  }

  public getSelectedRecord(): Record {
    let dao = this.daoService.getDAO('records');
    if (!dao) {
      return null;
    }
    return (<DAORecords>dao).selectedRecord;
  }

  handleEvent(event: MessageEvent): void {
    if (event.data.message == 'error') {
      console.log('parent', event.data.content);
    }

    if (event.data.operation == 'changeroute') {
      this.router.navigateByUrl(event.data.route);

      if (event.data.changePrice) { //jump to company and show changePrice dialog
        timer(2000).subscribe(() => {
          let iframe = (<HTMLIFrameElement>document.getElementById("company"));
          if (iframe) {
            iframe.contentWindow.postMessage({ "message": "changePrice", "targetPrice": 35 }, environment.companyModule);
          }
        });
      }
    } else if (event.data.message == 'showToDo') {
      let modalRef = this.myModalService.createModalTodoV2(event.data.toDoId, event.data.context);
    } else if (event.data.message == 'createToDo') {
      if (event.data.context == 'bulk' && event.data.bulkIds) {
        this.bulkIds = event.data.bulkIds;
      } else {
        this.bulkIds = [];
      }
      let modalRef = this.myModalService.createModalTodoCreate(event.data.recordId, event.data.context, event.data.templateId,
        event.data.templateName, event.data.areaId, event.data.bedId);
    } else if (event.data.message == 'closeToDo') {
      //Meldung fuer wie viele Saetze der Todo angelegt wurde fuer die Bulkverarbeitung werfen
      if (event.data.context == 'bulk') {
        //alle Saetze konnten den Todo erhalten
        if (event.data.numRecords == this.bulkIds.length) {
          this.myApplication.messageDTO.setSuccessWithId('TabView.infoTodoAllRecordsChanged', event.data.numRecords);
        } else {
          //einige Saetze waren vom Anbautyp nicht fuer den Todo geeignet
          this.myApplication.messageDTO.setSuccessWithId('TabView.infoTodoRecordsChanged', event.data.numRecords, this.bulkIds.length);
        }

      }
      let iframe = (<HTMLIFrameElement>document.getElementById("gardening-cal"));
      if (iframe) {
        iframe.contentWindow.postMessage({ "message": "recordToDosChanged" }, environment.gardeningCalModule);
      }
      iframe = (<HTMLIFrameElement>document.getElementById("calendar"));
      if (iframe) {
        iframe.contentWindow.postMessage({ "message": "recordToDosChanged" }, '*');
      }
      iframe = (<HTMLIFrameElement>document.getElementById("areasv2"));
      if (iframe) {
        iframe.contentWindow.postMessage({ "message": "areaToDosChanged" }, environment.areasModule + '/' + this.myApplication.year.toString());
        iframe.contentWindow.postMessage({ "message": "recordToDosChanged" }, environment.areasModule + '/' + this.myApplication.year.toString());
      }
      iframe = (<HTMLIFrameElement>document.getElementById("deliveryPlan"));
      if (iframe) {
        iframe.contentWindow.postMessage({ "message": "areaToDosChanged" }, environment.deliveryPlanModule + '/' + this.myApplication.year.toString());
      }

      this.bulkIds = [];
      this.modalService.dismissAll();
    } else if (event.data.message == 'closeToDoNoAction' || event.data.message == 'closeWizard') {
      this.modalService.dismissAll();
      if (event.data.message == 'closeWizard') {
        this.wizardSrv.readOverview();
      }
    } else if (event.data.message == 'createGardeningCal') {
      this.modalService.dismissAll();
      let modalRef = this.myModalService.createGardeningCal();
    } else if (event.data.message == 'createRecord') {
      this.modalService.dismissAll();
      if (event.data.cultureId) {
        let modalRef = this.myModalService.createRecordCreateV2(event.data.cultureId);
      } else {
        let modalRef = this.myModalService.createRecordCreateV2();
      }
    } else if (event.data.message == 'createCulture') {
      this.modalService.dismissAll();
      let modalRef = this.myModalService.createCultureV2();
    } else if (event.data.message == 'closeRecordCreate') {
      let iframe = (<HTMLIFrameElement>document.getElementById("gardening-cal"));
      if (iframe) {
        iframe.contentWindow.postMessage({ "message": "recordChanged" }, environment.gardeningCalModule);
      }
      iframe = (<HTMLIFrameElement>document.getElementById("areasv2"));
      if (iframe) {
        iframe.contentWindow.postMessage({ "message": "recordChanged" }, environment.areasModule + '/#/home/' + this.myApplication.year.toString());
      }
      iframe = (<HTMLIFrameElement>document.getElementById("deliveryPlan"));
      if (iframe) {
        iframe.contentWindow.postMessage({ "message": "recordChanged" }, environment.deliveryPlanModule + this.myApplication.year.toString());
      }
      iframe = (<HTMLIFrameElement>document.getElementById("culturesV2"));
      if (iframe) {
        iframe.contentWindow.postMessage({ "message": "recordChanged" }, environment.cultureModule + '/cultureList/' + this.myApplication.year.toString());
      }
      this.modalService.dismissAll();

      if (event.data.numCreated && event.data.numCreated > 0) {
        if (event.data.numCreated == 1) {
          this.myApplication.messageDTO.setSuccessWithId('Records.oneCreated');
        } else {
          this.myApplication.messageDTO.setSuccessWithId('Records.numCreated', event.data.numCreated);
        }
      }
    } else if (event.data.message == 'closeCultureCreate') {
      let iframe = (<HTMLIFrameElement>document.getElementById("culturesV2"));
      if (iframe) {
        iframe.contentWindow.postMessage({ "message": "selectCulture", "cultureId": event.data.cultureId }, environment.cultureModule + '/cultureList/' + this.myApplication.year.toString());
      }
      this.modalService.dismissAll();
    } else if (event.data.message == 'pleaseSendBulkIds') {
      let iframe = (<HTMLIFrameElement>document.getElementById("themeframe")); //name of modal iframe...
      if (iframe) {
        iframe.contentWindow.postMessage({ "message": "sendBulkIds", "bulkIds": this.bulkIds }, '*');
      }
    } else if (event.data.message == 'logout') {
      this.authentication.logout();
    } else if (event.data.message == 'changeYear') {
      if (this.myApplication.year != event.data.context) {
        this.myApplication.year = event.data.context;
      }
    }
  }
}
