import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';


export class BedAssignEO extends AbstractEntityObject {

    public parentId: String;
    public bedId: String;
    public positionOnBed: number;
    public lengthOnBed: number;
    
    public startTS: number;
    public cultureEndTS: number;


    constructor() {
        super();
        this.parentId = '';
        this.bedId = '';
        this.positionOnBed = 0;
        this.lengthOnBed = 0;

        this.startTS = 0;
        this.cultureEndTS = 0;
    }
}