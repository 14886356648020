export class RegisterDTO {
  
    company: String = '';
    firstName: String = '';
    lastName: String = '';
    repositoryName: String = '';
    system: String = '1';
    numParts: number = 120;
    uname: String = '';
    mail: String = '';
    pw: String = '';
    email: String = '';
    emailConfirm: String = '';
    password: String = '';
    agb: boolean = false;
    dp: boolean = false;
    birthDate = new Date();
    isOver18 = false;
    price: number = 0;
    currency: String = 'EUR';
    agreementVersion = 1;
    paymentMethod = 'A';
    street: String = '';
    plz: String = '';
    city: String = '';
    private _country: String = 'Deutschland';
    countryCode = '';
    ustId: String = '';
    tel: String;
    flatRateFarmer = false;
    companyNumber = '';
    promoCode: string;
    legalForm: string;
    foundVia: String = '';
    foundViaTxt: String = '';


    public get country(): String {
        return this._country;
    }
    public set country(value: String) {
        this._country = value;
        this.flatRateFarmer = false;
    }
}