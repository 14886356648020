import { Http, RequestOptions } from '@angular/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { WizardStartComponent } from '../ui-components/wizard-start/wizard-start.component';
import { ApplicationService } from './application.service';
import { AuthenticationService } from './authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
import * as i2 from "./authentication.service";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "./application.service";
var WizardService = /** @class */ (function () {
    function WizardService(http, authenticationService, modalService, myApplication) {
        this.http = http;
        this.authenticationService = authenticationService;
        this.modalService = modalService;
        this.myApplication = myApplication;
    }
    WizardService.prototype.readOverview = function () {
        var _this = this;
        var options = new RequestOptions();
        options.headers = this.authenticationService.getHeadersAuthenticated();
        return new Promise(function (resolve, reject) {
            _this.http.get(environment.apiV2 + 'wizard/start/overview', options).pipe(map(function (resp) { return resp.json(); }))
                .subscribe(function (result) {
                _this.data = result;
                if (!_this.data.firstShowDone) {
                    _this.showWizard();
                }
            });
        });
    };
    WizardService.prototype.setFirstShowDone = function () {
        var options = new RequestOptions();
        options.headers = this.authenticationService.getHeadersAuthenticated();
        this.http.patch(environment.apiV2 + 'wizard/start/firstDisplay', null, options).pipe()
            .subscribe(function () {
        }, function (err) {
            console.log('There was an error updating wizard.', err);
        });
    };
    WizardService.prototype.showWizard = function () {
        if (this.myApplication.legalAgreeShown) {
            this.myApplication.showWizardOnCloseAgree = true;
            console.log('wizard', this.myApplication.showWizardOnCloseAgree);
            return;
        }
        var ngbModalOptions = {
            windowClass: "wizardModal",
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
            centered: true
        };
        var modalRef = this.modalService.open(WizardStartComponent, ngbModalOptions);
        this.setFirstShowDone();
    };
    WizardService.ngInjectableDef = i0.defineInjectable({ factory: function WizardService_Factory() { return new WizardService(i0.inject(i1.Http), i0.inject(i2.AuthenticationService), i0.inject(i3.NgbModal), i0.inject(i4.ApplicationService)); }, token: WizardService, providedIn: "root" });
    return WizardService;
}());
export { WizardService };
