import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LegalAgreeComponent } from '../ui-components/app-info/legal/legal-agree/legal-agree.component';
import { ModalComponent } from '../ui-components/modal/modal.component';
import { environment } from 'src/environments/environment';
import { ApplicationService } from './application.service';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "./application.service";
var ModalService = /** @class */ (function () {
    function ModalService(modalService, myApplication) {
        this.modalService = modalService;
        this.myApplication = myApplication;
    }
    ModalService.prototype.createModalTodoV2 = function (id, context) {
        var ngbModalOptions = {
            windowClass: "xlModal",
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
            centered: true
        };
        var modalRef = this.modalService.open(ModalComponent, ngbModalOptions);
        modalRef.componentInstance.myUrl = environment.toDoModule + '/#/toDos/' + id + '/' + context;
        return modalRef;
    };
    ModalService.prototype.createGardeningCal = function () {
        var ngbModalOptions = {
            windowClass: "xlModal",
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
            centered: true
        };
        var modalRef = this.modalService.open(ModalComponent, ngbModalOptions);
        modalRef.componentInstance.myUrl = environment.cultureModule + '/gardeningCalCreate/' + this.myApplication.year;
        return modalRef;
    };
    ModalService.prototype.createRecordCreateV2 = function (cultureId) {
        var ngbModalOptions = {
            windowClass: "xlModal",
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
            centered: true
        };
        var modalRef = this.modalService.open(ModalComponent, ngbModalOptions);
        if (cultureId) {
            modalRef.componentInstance.myUrl = environment.recordModule + '/#/create/' + cultureId;
        }
        else {
            modalRef.componentInstance.myUrl = environment.cultureModule + '/recordCreate/' + this.myApplication.year;
        }
        return modalRef;
    };
    ModalService.prototype.createCultureV2 = function () {
        var ngbModalOptions = {
            windowClass: "xlModal",
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
            centered: true
        };
        var modalRef = this.modalService.open(ModalComponent, ngbModalOptions);
        modalRef.componentInstance.myUrl = environment.cultureModule + '/cultureCreate';
        return modalRef;
    };
    ModalService.prototype.createModalTodoCreate = function (id, context, templateId, templateName, areaId, bedId) {
        var ngbModalOptions = {
            windowClass: "xlModal",
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
            centered: true
        };
        var modalRef = this.modalService.open(ModalComponent, ngbModalOptions);
        if (context == 'area') {
            if (bedId) {
                if (templateName) {
                    modalRef.componentInstance.myUrl = environment.toDoModule + '/#/toDo/bed/' + areaId + '/' + bedId + '/' + context + '/name/' + templateName;
                }
                else if (areaId) {
                    modalRef.componentInstance.myUrl = environment.toDoModule + '/#/toDo/bed/' + areaId + '/' + bedId + '/' + context;
                }
            }
            else {
                if (templateName) {
                    modalRef.componentInstance.myUrl = environment.toDoModule + '/#/toDo/area/' + areaId + '/' + context + '/name/' + templateName;
                }
                else if (areaId) {
                    modalRef.componentInstance.myUrl = environment.toDoModule + '/#/toDo/area/' + areaId + '/' + context;
                }
            }
        }
        else if (context == 'record') {
            if (templateId) {
                modalRef.componentInstance.myUrl = environment.toDoModule + '/#/toDo/record/' + id + '/' + context + '/id/' + templateId;
            }
            else if (templateName) {
                modalRef.componentInstance.myUrl = environment.toDoModule + '/#/toDo/record/' + id + '/' + context + '/name/' + templateName;
            }
            else {
                modalRef.componentInstance.myUrl = environment.toDoModule + '/#/toDo/record/' + id + '/' + context;
            }
        }
        else if (context == 'calendar') {
            if (templateName) {
                modalRef.componentInstance.myUrl = environment.toDoModule + '/#/toDo/calendar/' + context + '/name/' + templateName;
            }
            else {
                modalRef.componentInstance.myUrl = environment.toDoModule + '/#/toDo/calendar/' + context;
            }
        }
        else if (context == 'bulk') {
            if (templateId) {
                modalRef.componentInstance.myUrl = environment.toDoModule + '/#/toDo/bulk/id/' + templateId;
            }
            else if (templateName) {
                modalRef.componentInstance.myUrl = environment.toDoModule + '/#/toDo/bulk/name/' + templateName;
            }
            else {
                modalRef.componentInstance.myUrl = environment.toDoModule + '/#/toDo/bulk';
            }
        }
        return modalRef;
    };
    ModalService.prototype.createRecordCreation = function (cultureId) {
        var ngbModalOptions = {
            windowClass: "xlModal",
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
            centered: true
        };
        var modalRef = this.modalService.open(ModalComponent, ngbModalOptions);
        if (cultureId) {
            modalRef.componentInstance.myUrl = environment.recordModule + '/#/record-create/' + cultureId;
        }
        else {
            modalRef.componentInstance.myUrl = environment.recordModule + '/#/record-create';
        }
        return modalRef;
    };
    ModalService.prototype.createAgreement = function () {
        var ngbModalOptions = {
            windowClass: "xlModal",
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
            centered: true
        };
        var modalRef = this.modalService.open(LegalAgreeComponent, ngbModalOptions);
        modalRef.componentInstance.modal = modalRef;
        return modalRef;
    };
    ModalService.ngInjectableDef = i0.defineInjectable({ factory: function ModalService_Factory() { return new ModalService(i0.inject(i1.NgbModal), i0.inject(i2.ApplicationService)); }, token: ModalService, providedIn: "root" });
    return ModalService;
}());
export { ModalService };
