import { Record } from './record';
import { Listener } from '../technical-aspects/listener';
import { BasicService } from 'src/app/services/basic.service';
import { ApplicationService } from 'src/app/services/application.service';
import { WeekInYearDTO } from '../dto/week-in-year-dto';
import { Observable } from '../technical-aspects/observable';
import { Constants } from './constants';


export class FilteredRecordSet extends Observable implements Listener {

    private _kulturFilter: String;
    private _stateFilter: String;
    private _sort = 2;
    private _actWeek: number;
    private _fromKW: number;
    private _toKW: number;
    private _freeTextFilter: string;
    private _color: number = 2;
    private _areaId: String = '<empty>';
    private _bedId: String = '<empty>';
    private _calType = '';
    private _cultureType = '';
    private _consumerType = '';
    private _fullPlanned = '';
    private _hasSort = '';
    private _bedM = '';
    private _isInPeriod = '';
    private _tags: Array<string>;

    public objects: Array<Record>;
    public completeObjects: Array<Record>;
    public useBedFilter = false;

    public isFiltered = false;


    public constructor(objects: Array<Record>,
        private myApplication: ApplicationService, private basicService: BasicService) {

        super();

        this.replaceObjects(objects);
        myApplication.addObserver(this);
        this._actWeek = this.basicService.getWeek(new Date()).week;
    }

    public uncheckAll() {
        this.completeObjects.forEach(record => {
            record.checked = false;
        });
    }

    public get tags(): Array<string> {
        if (!this._tags) {
            this._tags = new Array<string>();
        }
        return this._tags;
    }

    public set tags(value: Array<string>) {
        this._tags = value;
        this.update();
    }

    public addTag(tag: string) {
        this.tags.push(tag);
        this.update();
    }

    public removeTag(tag: string) {
        const index: number = this._tags.indexOf(tag);
        if (index !== -1) {
            this._tags.splice(index, 1);
        }
        this.update();
    }

    public reset() {
        this._kulturFilter = '';
        this._stateFilter = '';
        this._sort = 2;
        this._actWeek = this.basicService.getWeek(new Date()).week;
        this._fromKW = 0;
        this._toKW = 0;
        this._freeTextFilter = '';
        this._areaId = '<empty>';
        this._bedId = '<empty>';
        this._calType = '';
        this._consumerType = '';
        this._fullPlanned = '';
        this._hasSort = '';
        this._bedM = '';
        this._isInPeriod = '';
        this.tags = null;
        this._cultureType = Constants.NOT_RELEVANT;
        this.update();
    }

    public numNotFullPlanned(): number {
        this.reset();
        let sum = 0;
        this.objects.forEach(record => {
            if (!record.bedAssignList.isFullPlanned()) {
                sum = sum + 1;
            }
        });
        return sum;
    }

    public numNoSort(): number {
        this.reset();
        let sum = 0;
        this.objects.forEach(record => {
            if (!record.sort) {
                sum = sum + 1;
            }
        });
        return sum;
    }

    public numNoBedM(): number {
        this.reset();
        let sum = 0;
        this.objects.forEach(record => {
            if (record.lengthOnBed <= 0) {
                sum = sum + 1;
            }
        });
        return sum;
    }

    public getNumChecked() {
        let i = 0;
        let record: Record;

        for (let j=0; j<this.objects.length; j++) {
            record = this.objects[j];
            if (record.checked == true && record.recordPeriods.isInYear(this.myApplication.year)) {
                i = i + 1;
            }
        }
        return i;
    }

    public get isInPeriod() {
        return this._isInPeriod;
    }
    public set isInPeriod(value) {
        this._isInPeriod = value;
        this.update();
    }

    public get fullPlanned() {
        return this._fullPlanned;
    }
    public set fullPlanned(value) {
        this._fullPlanned = value;
        this.update();
    }

    public get cultureType() {
        return this._cultureType;
    }
    public set cultureType(value) {
        this._cultureType = value;
        this.update();
    }

    public get bedM() {
        return this._bedM;
    }
    public set bedM(value) {
        this._bedM = value;
        this.update();
    }

    public get hasSort() {
        return this._hasSort;
    }
    public set hasSort(value) {
        this._hasSort = value;
        this.update();
    }

    public get consumerType() {
        return this._consumerType;
    }
    public set consumerType(value) {
        this._consumerType = value;
        this.update();
    }

    public get areaId(): String {
        return this._areaId;
    }
    public set areaId(value: String) {
        this._areaId = value;
        if (this._areaId != '' && this.areaId != '<empty>') {
            this.useBedFilter = true;
        } else {
            this.useBedFilter = false;
        }
        this._bedId = '<empty>';
        this.update();
        this.hasChanged();
    }

    public get calType() {
        return this._calType;
    }
    public set calType(value) {
        this._calType = value;
        this.update();
    }

    public get bedId(): String {
        return this._bedId;
    }
    public set bedId(value: String) {
        this._bedId = value;
        this.update();
    }

    public copyToNextYear() {
        this.objects.forEach(record => {
            record.cloneToNextYear();
        });
        this.myApplication.messageDTO.setSuccessWithId('NumberCreatedNewRecordsYear', this.objects.length);
    }

    public get kulturFilter(): String {
        return this._kulturFilter;
    }

    public set kulturFilter(value: String) {
        this._kulturFilter = value;
        this.update();
    }

    public get color(): number {
        return this._color;
    }
    public set color(value: number) {
        this._color = value;
        this.update();
    }

    public get stateFilter(): String {
        return this._stateFilter;
    }
    public set stateFilter(value: String) {
        this._stateFilter = value;
        this.update();
    }

    public get sort() {
        return this._sort;
    }

    public set sort(value) {
        this._sort = value;
        this.update();
    }

    public get fromKW(): number {
        return this._fromKW;
    }

    public set fromKW(value: number) {
        this._fromKW = value;
        this.update();
    }

    public get toKW(): number {
        return this._toKW;
    }

    public set toKW(value: number) {
        this._toKW = value;
        this.update();
    }

    public get freeTextFilter(): string {
        return this._freeTextFilter;
    }
    public set freeTextFilter(value: string) {
        this._freeTextFilter = value;
        this.update();
    }

    public addObject(object: Record) {
        this.completeObjects.push(object);
    }

    public replaceObjects(objects: Array<Record>) {
        this.completeObjects = objects;
        if (!this.completeObjects) {
            this.completeObjects = new Array();
        }
        this.update();
    }

    justThisWeek() {
        this.fromKW = this.toKW = this._actWeek;
    }

    wholeYear() {
        this.fromKW = 1;
        this.toKW = this.basicService.getNumWeeks(this.myApplication.year);
    }

    public update() {
        this.objects = this.filterByYear(this.completeObjects);

        let numAfterYearFilter = this.objects.length;

        this.objects = this.filterByKultur(this.objects);
        this.objects = this.filterByState(this.objects);
        // this.objects = this.filterByKW(this.objects);
        this.objects = this.filterByAreaId(this.objects);
        this.objects = this.filterByBedId(this.objects);
        this.objects = this.filterByCalType(this.objects);
        this.objects = this.filterByConsumerType(this.objects);
        this.objects = this.filterByFullPlanned(this.objects);
        this.objects = this.filterByHasSort(this.objects);
        this.objects = this.filterByNoBedM(this.objects);
        this.objects = this.filterByCultureType(this.objects);
        this.objects = this.filterByIsInPeriod(this.objects);
        this.objects = this.filterByTags(this.objects);

        let sort = this.sort;
        this.objects = this.objects.sort(function (one, two) {
            let compare = 0;
            if (sort == 1) { //alphabetisch
                compare = one.getRecordName().localeCompare(two.getRecordName().toString());
            } else if (sort == 2) { //Aussaat/Pflanzdatum
                compare = (one.recordPeriods.growDate.getTime() + one.recordNum) - (two.recordPeriods.growDate.getTime() + two.recordNum);
            } else if (sort == 3) {
                compare = one.state.toString().localeCompare(two.state.toString());
            } else if (sort == 4) {
                compare = one.state.toString().localeCompare(two.state.toString());
                if (compare == 0) {
                    if (one.recordPeriods.getStartDate() > two.recordPeriods.getStartDate()) {
                        compare = 1;
                    } else if (one.recordPeriods.getStartDate() < two.recordPeriods.getStartDate()) {
                        compare = -1
                    } else {
                        compare = 0;
                    }
                }
            } else if (sort == 5) { // next action
                if (this.basicService.weekIsGreaterThan(one.recordPeriods.getNextActionWeek(), two.recordPeriods.getNextActionWeek())) {
                    return 1;
                }
                if (this.basicService.weekEquals(one.recordPeriods.getNextActionWeek(), two.recordPeriods.getNextActionWeek())) {
                    return 0;
                } else {
                    return -1;
                }
            }
            else if (sort == 6) { //Kultur
                compare = one.getRecordName().toString().localeCompare(two.getRecordName().toString());
            }
            else if (sort == 7) { //Erntedatum
                compare = one.recordPeriods.harvestDate.getTime() - two.recordPeriods.harvestDate.getTime();
                if (compare == 0) {
                    compare = one.recordPeriods.harvestLength - two.recordPeriods.harvestLength;
                }
            }
            else if (sort == 8) { //Pflanzdatum /Aussaatdatum bei Direktsaat = growDate
                compare = one.recordPeriods.growDate.getTime() - two.recordPeriods.growDate.getTime();
            }
            else if (sort == 9) { //Anzuchtdatum (Anzucht, Pflanzung/Direktsaat)
                let oneValue = '';
                let twoValue = '';
                if (one.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
                    oneValue = 'A' + one.recordPeriods.seedDate.getTime().toString();
                } else {
                    oneValue = 'B' + one.recordPeriods.growDate.getTime().toString();;
                }

                if (two.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
                    twoValue = 'A' + two.recordPeriods.seedDate.getTime().toString();
                } else {
                    twoValue = 'B' + two.recordPeriods.growDate.getTime().toString();;
                }
                
                compare = oneValue.localeCompare(twoValue);
            }
            return compare;
        });

        if (numAfterYearFilter != this.objects.length) {
            this.isFiltered = true;
        } else {
            this.isFiltered = false;
        }
    }

    private filterByTags(list: Array<Record>): Array<Record> {
        if (this.tags.length == 0) {
            return list;
        }

        this.tags.forEach(tag => {
            let newList = new Array<Record>();
            list.forEach(record => {
                if (record.includesTag(tag)) {
                    newList.push(record);
                }
            });
            list = newList;
        });
        return list;
    }

    private filterByIsInPeriod(list: Array<Record>) {
        if (this._isInPeriod == '<empty>' || this._isInPeriod == Constants.NOT_RELEVANT) {
            return this.filterByKW(list);
        }
        let resultSet = new Array<Record>();

        let startWeek;
        if (this.fromKW < 1) {
            startWeek = new WeekInYearDTO(1, this.myApplication.year);
        } else {
            startWeek = new WeekInYearDTO(this.fromKW, this.myApplication.year);
        }

        let endWeek;
        if (this.toKW < 1) {
            endWeek = new WeekInYearDTO(this.basicService.getNumWeeks(this.myApplication.year), this.myApplication.year);
        } else {
            endWeek = new WeekInYearDTO(this.toKW, this.myApplication.year);
        }

        list.forEach(record => {
            let week;
            if (this._isInPeriod == Constants.CULTURE_TYPE_ANZUCHT && record.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
                week = record.recordPeriods.seedWeek;
            } else if (this._isInPeriod == 'P') { // irgendeine Pflanzung/Direktsaat
                week = record.recordPeriods.growWeek;
            }
            if (week && this.basicService.isInPeriod(startWeek, endWeek, week)) {
                resultSet.push(record);
            }

            if (this._isInPeriod == 'S') { // Anzucht/Direktsaat/Pflanzung
                if (this.basicService.isInPeriod(startWeek, endWeek, record.recordPeriods.getStartWeek()) ||
                    this.basicService.isInPeriod(startWeek, endWeek, record.recordPeriods.growWeek)) {
                    resultSet.push(record);
                }
            }

            if (this._isInPeriod == 'E') {
                if (this.basicService.periodsOverlaps(startWeek, endWeek, record.recordPeriods.harvestWeek, record.recordPeriods.lastHarvestWeek)) {
                    resultSet.push(record);
                }
            }
        });

        return resultSet;
    }

    private filterByCultureType(list: Array<Record>) {
        if (this._cultureType == Constants.NOT_RELEVANT) {
            return list;
        }
        let resultSet = new Array<Record>();

        list.forEach(record => {
            if (this._cultureType == record.cultureType) {
                resultSet.push(record);
            }
        });

        return resultSet;
    }

    private filterByHasSort(list: Array<Record>) {
        if (this.hasSort == Constants.NOT_RELEVANT) {
            return list;
        }
        let resultSet = new Array<Record>();

        list.forEach(record => {
            if (this.hasSort == Constants.YES &&
                record.sort != '' ||
                this.hasSort == Constants.NO &&
                record.sort == '') {
                resultSet.push(record);
            }
        });

        return resultSet;
    }
    private filterByFullPlanned(list: Array<Record>) {
        if (this.fullPlanned == Constants.NOT_RELEVANT) {
            return list;
        }
        let resultSet = new Array<Record>();

        list.forEach(record => {
            if (this.fullPlanned == Constants.YES &&
                record.bedAssignList.isFullPlanned() ||
                this.fullPlanned == Constants.NO &&
                !record.bedAssignList.isFullPlanned() ||
                this.fullPlanned == Constants.NO_BEDMETERS &&
                record.lengthOnBed <= 0) {
                resultSet.push(record);
            }
        });

        return resultSet;
    }
    private filterByNoBedM(list: Array<Record>) {
        if (this.bedM == Constants.NOT_RELEVANT) {
            return list;
        }
        let resultSet = new Array<Record>();

        list.forEach(record => {
            if (this.bedM == Constants.YES &&
                record.lengthOnBed > 0 ||
                this.bedM == Constants.NO &&
                record.lengthOnBed <= 0) {
                resultSet.push(record);
            }
        });

        return resultSet;
    }

    private filterByAreaId(list: Array<Record>) {
        if (this.areaId == '<empty>') {
            return list;
        }
        let resultSet = new Array<Record>();

        list.forEach(record => {
            if (this.areaId == record.areaId) {
                resultSet.push(record);
            }
        });

        return resultSet;
    }

    private filterByCalType(list: Array<Record>) {
        if (this.calType == '') {
            return list;
        }
        let resultSet = new Array<Record>();

        list.forEach(record => {
            if (this.calType == 'A' && record.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
                resultSet.push(record);
            }
        });

        return resultSet;
    }

    private filterByConsumerType(list: Array<Record>) {
        if (this.consumerType == '') {
            return list;
        }
        let resultSet = new Array<Record>();

        list.forEach(record => {
            if (this.consumerType == record.culture.consumerType) {
                resultSet.push(record);
            }
        });

        return resultSet;
    }

    private filterByBedId(list: Array<Record>) {
        if (this.bedId == '<empty>') {
            return list;
        }
        let resultSet = new Array<Record>();

        list.forEach(record => {
            if (record.bedAssignList.isOnBed(this.bedId)) {
                resultSet.push(record);
            }
        });

        return resultSet;
    }

    private filterByKW(list: Array<Record>) {
        let resultSet = new Array<Record>();
        if (!this.fromKW || !this.toKW) {
            return list;
        }
        let fromWeek = new WeekInYearDTO(this.fromKW, this.myApplication.year);
        let toWeek = new WeekInYearDTO(this.toKW, this.myApplication.year);

        list.forEach(element => {
            let startWeek = element.recordPeriods.getStartWeek();
            let endWeek = element.recordPeriods.lastHarvestWeek;
            if (this.basicService.periodsOverlaps(fromWeek, toWeek, startWeek, endWeek)) {
                resultSet.push(element);
            }
        });

        return resultSet;
    }

    private filterByYear(lRecord: Array<Record>) {
        let resultSet = new Array<Record>();
        lRecord.forEach(record => {
            if (record.recordPeriods.isInYear(this.myApplication.year)) {
                resultSet.push(record);
            }
        });

        return resultSet;
    }

    private filterByKultur(lRecord: Array<Record>) {
        let resultSet = new Array<Record>();
        if (this.kulturFilter && this.kulturFilter != '') {
            lRecord.forEach(element => {
                if (element.culture.id == this.kulturFilter) {
                    resultSet.push(element);
                }
            });
        }
        else {
            resultSet = lRecord;
        }
        return resultSet;
    }

    private filterByState(lRecord: Record[]) {
        let resultSet = new Array<Record>();
        if (this.stateFilter && this.stateFilter != '') {
            lRecord.forEach(element => {
                if (element.state == this.stateFilter) {
                    resultSet.push(element);
                }
            });
        }
        else {
            resultSet = lRecord;
        }
        return resultSet;
    }
}