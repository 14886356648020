
export class PeriodUI {

    private _color: String;
    duration: number;
    private _name: String;
    opacity: number;
    pIndex: number;
    text: String;
    firstPeriod: false;


    constructor() {
        this.color = 'bg-success';
    }

    public get name(): String {
        return this._name;
    }
    public set name(value: String) {
        this._name = value;
        this.init();
    }

    public get color(): String {
        return this._color;
    }

    public set color(value: String) {
        if (value && this.opacity != 1) {
            this._color = this.colorLuminance(value, this.opacity);
        } else {
            this._color = value;
        }
        this.opacity = 1;
    }

    protected init() {
        if (this._name == 'Anzucht') { //keine Texte
            this.opacity = 0.12;
        } else if (this._name == 'Wachstum') {
            this.opacity = 1.0;
        } else if (this._name == 'Ernte') {
            this.opacity = 0.07;
        } else {
            this.opacity = 0;
        }
    }

    colorLuminance(hex, lum) {

        // validate hex string
        hex = String(hex).replace(/[^0-9a-f]/gi, '');
        // if (hex.length < 6) {
        //     hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        // }
        // lum = lum || 0;

        // convert to decimal and change luminosity
        let rgb = "#", c, i;
        for (i = 0; i < 3; i++) {
            c = parseInt(hex.substr(i * 2, 2), 16);
            c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
            rgb += ("00" + c).substr(c.length);
        }

        return rgb;
    }
}