import { Area } from '../business-logic/area';
import { DAO } from '../technical-aspects/dao';
import { DAOBeds } from './dao-beds';
import { DAORecords } from './dao-records';
import { DAOToDos } from './dao-todos';
import { AbstractObject } from '../technical-aspects/abstract-object';

export class DAOAreas extends DAO {

    protected createInstance() {
        let newInstance = new Area();
        newInstance.daoBeds = <DAOBeds>this.daoService.getDAO('beds');
        newInstance.daoRecords = <DAORecords>this.daoService.getDAO('records');
        newInstance.daoToDos = <DAOToDos>this.daoService.getDAO('todos');
        return newInstance;
    }

    public createNewInstance(): AbstractObject {
        let object = super.createNewInstance();
        (<Area> object).name = '<' + this.myApplication.getText('new') + '>';
        return object;
    }

    public deleteObject(object: AbstractObject) {
        let dao = <DAOBeds>this.daoService.getDAO('beds');
        let beds = dao.getBedsForArea(object.id);
        for (let i=0; i<beds.length; i++) {
            dao.deleteObject(beds[i]);
        }
        super.deleteObject(object);
    }
}