import { DAO } from '../technical-aspects/dao';
import { Bed } from '../business-logic/bed';
import { DAORecords } from './dao-records';
import { AbstractObject } from '../technical-aspects/abstract-object';
import { DAORepository } from './dao-repository';
import { DAOToDos } from './dao-todos';

export class DAOBeds extends DAO {
    public selectedBed: Bed;

    protected createInstance() {
        let newInstance = new Bed();
        newInstance.daoRecords = <DAORecords>this.daoService.getDAO('records');
        newInstance.daoBeds = this;
        newInstance.daoToDos = <DAOToDos>this.daoService.getDAO('todos');
        return newInstance;
    }

    getSumM2() {
        let sum = 0;
        this.objects.forEach(element => {
            let bed = <Bed> element;
            sum = sum + bed.getM2();
        });
        return Math.round(sum * 100) / 100;
    }

    getBedsForArea(areaId: String): Array<Bed> {
        let set = new Array<Bed>();

        this.objects.forEach(element => {
            let bed = <Bed> element;
            if (bed.areaId == areaId) {
                set.push(bed);
            }
        });
        set.sort(function (one, two) {
            return one.orderNum - two.orderNum;
        });

        for (let i=1; i<=set.length; i++) {
            set[i-1].orderNum = i;
        }
        
        return set;
    }

    public deleteObject(object: AbstractObject) {
        (<Bed>object).deleteBedAssigns();
        super.deleteObject(object);
        let areaId = (<Bed> object).areaId;
        this.renumber(areaId);
    }

    protected renumber(areaId: String) {
        let set = this.getBedsForArea(areaId);
        for (let i=1; i<=set.length; i++) {
            set[i-1].orderNum = i;
        }
    }

    public createNewInstance(): AbstractObject {
        let repository = (<DAORepository> this.daoService.getDAO('repository')).getRepository();

        let object = <Bed>super.createNewInstance();
        object.width = repository.bedWidth;;
        object.length = repository.bedLength;
        return object;
    }

    public isSelected(bed: Bed): boolean {
        if (bed == this.selectedBed) {
            return true;
        } else {
            return false;
        }
    }
}