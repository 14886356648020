/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./gardening-cal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./gardening-cal.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../services/application.service";
import * as i5 from "../../services/dao.service";
import * as i6 from "../../services/breadcrumb.service";
var styles_GardeningCalComponent = [i0.styles];
var RenderType_GardeningCalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GardeningCalComponent, data: {} });
export { RenderType_GardeningCalComponent as RenderType_GardeningCalComponent };
export function View_GardeningCalComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_GardeningCalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-gardening-cal", [], null, null, null, View_GardeningCalComponent_0, RenderType_GardeningCalComponent)), i1.ɵdid(1, 245760, null, 0, i2.GardeningCalComponent, [i3.DomSanitizer, i4.ApplicationService, i5.DaoService, i6.BreadcrumbService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GardeningCalComponentNgFactory = i1.ɵccf("app-gardening-cal", i2.GardeningCalComponent, View_GardeningCalComponent_Host_0, {}, {}, []);
export { GardeningCalComponentNgFactory as GardeningCalComponentNgFactory };
