<div class="form-row">
  <div class="form-group col-sm-5" [ngbTooltip]="fieldDTO.help" placement="top" [openDelay]="1000">
    <label class="col-form-label col-form-label-sm form-check-label text-secondary"
      [for]="id">{{ fieldDTO.text }}</label>
  </div>
  <div class="col-sm-1">
    <div class="input-group input-group-sm" [ngbTooltip]="fieldDTO.help" placement="top" [openDelay]="1000">
      <input class="form-check-input form-control-sm" type="checkbox" [(ngModel)]="object[valueName.toString()]" [id]="id" (click)="$event.stopPropagation();"
        [disabled]="disable">
    </div>
  </div>
</div>