/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../button/button.component.ngfactory";
import * as i3 from "../button/button.component";
import * as i4 from "../../../services/application.service";
import * as i5 from "./confirm.component";
var styles_ConfirmComponent = [i0.styles];
var RenderType_ConfirmComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmComponent, data: {} });
export { RenderType_ConfirmComponent as RenderType_ConfirmComponent };
export function View_ConfirmComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", " "])), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-button", [["data-dismiss", "modal"]], null, null, null, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(2, 114688, null, 0, i3.ButtonComponent, [i4.ApplicationService], { iconName: [0, "iconName"], id: [1, "id"], callback: [2, "callback"], callbackOwner: [3, "callbackOwner"], object: [4, "object"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-button", [["data-dismiss", "modal"]], null, null, null, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(4, 114688, null, 0, i3.ButtonComponent, [i4.ApplicationService], { iconName: [0, "iconName"], id: [1, "id"], callback: [2, "callback"], callbackOwner: [3, "callbackOwner"], object: [4, "object"], stopEventing: [5, "stopEventing"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "check"; var currVal_2 = "Confirm.yes"; var currVal_3 = _co.callback; var currVal_4 = _co.callbackOwner; var currVal_5 = _co.object; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = "ban"; var currVal_7 = "Confirm.no"; var currVal_8 = "noAction"; var currVal_9 = _co; var currVal_10 = _co.object; var currVal_11 = _co.stopEventingOnCancel; _ck(_v, 4, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.question; _ck(_v, 0, 0, currVal_0); }); }
export function View_ConfirmComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirm", [], null, null, null, View_ConfirmComponent_0, RenderType_ConfirmComponent)), i1.ɵdid(1, 114688, null, 0, i5.ConfirmComponent, [i4.ApplicationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmComponentNgFactory = i1.ɵccf("app-confirm", i5.ConfirmComponent, View_ConfirmComponent_Host_0, { callbackOwner: "callbackOwner", callback: "callback", object: "object", question: "question", stopEventingOnCancel: "stopEventingOnCancel" }, {}, []);
export { ConfirmComponentNgFactory as ConfirmComponentNgFactory };
