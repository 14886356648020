import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';
import { Constants } from '../business-logic/constants';
import { BasicService } from 'src/app/services/basic.service';

export class HarvestEO extends AbstractEntityObject {

    public recordId: String;
    public quantity: number;
    public quantityUnit: String;
    public harvestDate: Date;
    public kind: String;

    constructor() {
        super();
        this.recordId = '';
        this.quantity = 0;
        this.quantityUnit = Constants.UNIT_KILOGRAMM;
        this.harvestDate = this.normalizeDate(new Date());
        this.kind = Constants.HARVEST_KIND_MARKED;
    }

    public normalizeDate(value: Date): Date {
        value.setHours(12);
        value.setMinutes(0);
        value.setSeconds(0);
        value.setMilliseconds(0);
        return value;
      }
  }