import { OnInit } from '@angular/core';
import { FieldTextDTO } from 'src/app/app-core/dto/field-text-dto';
import { ApplicationService } from 'src/app/services/application.service';
var SelectFieldComponent = /** @class */ (function () {
    function SelectFieldComponent(myApplication) {
        this.myApplication = myApplication;
        this.withoutTitle = false;
        this.min = 1;
        this._disable = false;
    }
    Object.defineProperty(SelectFieldComponent.prototype, "disable", {
        get: function () {
            return this._disable;
        },
        set: function (value) {
            this._disable = value;
        },
        enumerable: true,
        configurable: true
    });
    SelectFieldComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.id) {
            this.id = this.valueName;
        }
        this.fieldDTO = this.myApplication.textDTOs.get(this.id);
        if (!this.fieldDTO) {
            this.fieldDTO = new FieldTextDTO(this.id, this.id, '');
        }
        this.keyValueList.forEach(function (element) {
            if (!element.value || element.value == '') {
                var valueDTO = _this.myApplication.textDTOs.get(_this.id + '.' + element.key);
                if (valueDTO) {
                    element.value = valueDTO.text;
                }
                else {
                    element.value = _this.id + '.' + element.key;
                    console.log("ID fehlt:" + element.value);
                }
            }
        });
    };
    SelectFieldComponent.prototype.modelChanged = function (value) {
        if (this.modelChangedObject) {
            this.modelChangedObject.modelChanged(value);
        }
    };
    return SelectFieldComponent;
}());
export { SelectFieldComponent };
