import { BasicService } from 'src/app/services/basic.service';
import { Constants } from './constants';

export class CalculatorLengthOnBed {

  constructor(protected numRows: number, protected quantity: number, protected plantDistance: number, protected basicService: BasicService,
    protected seedRate: number, protected cultureType: String) {

  }

  public calculate(): number {
    if (this.quantity != 0) {
      if (this.numRows == 0) {
        this.numRows = 1;
      }
      //erstmal fuer Anzucht die Keimrate beruecksichtigen und die Anzahl Pflanzen verringern
      if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
        this.quantity = this.quantity / 100 * this.seedRate;
        this.quantity = Math.floor(this.quantity);
      }
      return this.basicService.ownCeil((this.quantity / this.numRows * this.plantDistance / 100));
    } else {
      return 0;
    }
  }
}