import { Component, OnInit, Input } from '@angular/core';
import { ApplicationService } from 'src/app/services/application.service';
import { FieldTextDTO } from 'src/app/app-core/dto/field-text-dto';
import { Feedback } from 'src/app/app-core/business-logic/feedback';
import { KeyValueDTO } from 'src/app/app-core/dto/key-value-dto';

@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.css']
})
export class FeedbackModalComponent implements OnInit {

  @Input() callbackOwner;
  @Input() callback;
  @Input() question: String = '';
  @Input() id;
  @Input() feedback: Feedback;
  
  questionDTO: FieldTextDTO;
  public feedbackViews = new Array<KeyValueDTO>();
  
  constructor(public myApplication: ApplicationService) { 
    this.initFeedbackViews();
  }

  private initFeedbackViews() {
    this.feedbackViews.push(new KeyValueDTO('Allgemein'));
    this.feedbackViews.push(new KeyValueDTO('Home/Dashboard'));
    this.feedbackViews.push(new KeyValueDTO('Anbau-Kalender'));
    this.feedbackViews.push(new KeyValueDTO('Aufgaben'));
    this.feedbackViews.push(new KeyValueDTO('Kulturen'));
    this.feedbackViews.push(new KeyValueDTO('Schläge'));
    this.feedbackViews.push(new KeyValueDTO('Saatgut'));
    this.feedbackViews.push(new KeyValueDTO('Anteile/Solawi'));
    this.feedbackViews.push(new KeyValueDTO('Einstellungen'));
    this.feedbackViews.push(new KeyValueDTO('Sonstiges',));
  }

  ngOnInit() {
    this.questionDTO = this.myApplication.textDTOs.get(this.question);
    if (!this.questionDTO) {
      this.questionDTO = new FieldTextDTO(this.question, this.question, '');
    }
  }

  noAction() {
    
  }
}
