import { Component, ComponentFactoryResolver, HostListener, OnInit } from '@angular/core';
import { Listener } from '@angular/core/src/render3/discovery_utils';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { EventHandler } from 'src/app/app-core/technical-aspects/event-handler';
import { ApplicationService } from 'src/app/services/application.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-areasv2',
  templateUrl: './areasv2.component.html',
  styleUrls: ['./areasv2.component.css']
})
export class Areasv2Component implements OnInit {

  public url: SafeResourceUrl;
  public url2: SafeResourceUrl;

  innerHeight = 1100;


  constructor(public sanitizer: DomSanitizer, public myApplication: ApplicationService, private router: Router, private breadcrumbSrv: BreadcrumbService,
    private authSrv: AuthenticationService) {
    this.breadcrumbSrv.breadcrumb = this.myApplication.getText('Feedback.view.Schläge').toString();

    this.myApplication.showSplitScreenBtn = true;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerHeight = window.innerHeight - 60;
  }

  update() {

  }

  ngOnInit(): void {
    let container = document.getElementById('areasv2');
    if (container) {
      container.style.display = "";

      let iframe = <HTMLIFrameElement>container;
      if (!environment.production) {
        iframe.contentWindow.postMessage({ "message": "sendToken", "token": this.authSrv.token }, '*');
      }

      //refresh helping data. who knows....
      iframe.contentWindow.postMessage({ "message": "refreshData" }, '*');

      let fromRecord = localStorage.getItem('fromRecord');
      if (fromRecord) {
        iframe.contentWindow.postMessage({ "message": "fromRecord", "content": fromRecord }, '*');
      }      
      localStorage.removeItem('fromRecord');
    }
    container = document.getElementById('areasv2_2');
    if (container) {
      container.style.display = "";

      let iframe = <HTMLIFrameElement>container;
      //refresh helping data. who knows....
      iframe.contentWindow.postMessage({ "message": "refreshData" }, '*');
    }
    this.onResize(null);
  }

  ngOnDestroy() {
    let container = document.getElementById('areasv2');
    if (container) {
      container.style.display = "none";
    }
    container = document.getElementById('areasv2_2');
    if (container) {
      container.style.display = "none";
    }

    this.myApplication.showSplitScreenBtn = false;
  }
}
