import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';

export class AreaEO extends AbstractEntityObject {

    public name: String;
    public description: String;
    public type: String;
    public pathWidth: number;


    constructor() {
        super();
        this.name = '';
        this.description = '';
        this.type = 'F';
        this.pathWidth = 0;
    }
  }