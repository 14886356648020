import { DAO } from '../technical-aspects/dao';
import { Culture } from '../business-logic/culture';
import { DAOSorts } from './dao-sorts';
import { AbstractObject } from '../technical-aspects/abstract-object';
import { DAORecords } from './dao-records';
import { DAOTemplate } from './dao-template';
import { first } from 'rxjs/operators';

export class DAOCultures extends DAO {

    public selectedCulture: Culture;


    protected createInstance(): AbstractObject {
        let newInstance = new Culture();
        newInstance.daoSorts = <DAOSorts>this.daoService.getDAO('sorts');
        newInstance.daoRecords = <DAORecords>this.daoService.getDAO('records');
        newInstance.daoTemplate = <DAOTemplate>this.daoService.getDAO('template');
        newInstance.daoCultures = this;
        return newInstance;
    }

    public createNewInstance(): AbstractObject {
        let object = <Culture>super.createNewInstance();
        object.color = this.basicService.randomColor();
        return object;
    }

    public getCultureNextToChar(char: string): Culture {
        let array = Array.from(this.objects.values()).sort(function(one, two) {
            return (<Culture>one).name.localeCompare( (<Culture>two).name.toString() ) ;
        });
        let fits = null;
        for (let i=0; i<array.length; i++) {
            fits = array[i];
            let firstChar: String = fits.name.substring(0,1);
            firstChar = firstChar.toLowerCase();
            if (firstChar >= char) {
                return fits;
            }
        }
        return fits;
    }

    public isSelected(culture: Culture): boolean {
        if (culture == this.selectedCulture) {
            return true;
        } else {
            return false;
        }
    }

    public deleteObject(object: AbstractObject) {
        let culture = <Culture>object;
        if (culture.isDeletable()) {
            culture.deleteChildren();
            super.deleteObject(object);
        }
    }

    public getCultureByName(name: String): Culture {
        if (!name) {
            return null;
        }
        let culture: Culture;
        let array = Array.from(this.objects.values());
        for (let i=0; i<array.length; i++) {
            culture = <Culture> array[i];
            if (culture.name == name) {
                return culture;
            }
        }
        return null;
    }
}