import * as tslib_1 from "tslib";
import { DAO } from '../technical-aspects/dao';
import { ToDo } from '../business-logic/todo';
import { FilteredToDoList } from '../business-logic/filtered-todo-list';
var DAOToDos = /** @class */ (function (_super) {
    tslib_1.__extends(DAOToDos, _super);
    function DAOToDos() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._objectSet = new FilteredToDoList(Array.from(_this.objects.values()));
        _this.allTypes = new Array();
        return _this;
    }
    DAOToDos.prototype.isSelected = function (todo) {
        if (todo == this.selectedToDo) {
            return true;
        }
        else {
            return false;
        }
    };
    Object.defineProperty(DAOToDos.prototype, "objectSet", {
        get: function () {
            return this._objectSet;
        },
        set: function (value) {
            this._objectSet = value;
        },
        enumerable: true,
        configurable: true
    });
    DAOToDos.prototype.buildAllTypes = function () {
        var _this = this;
        this.allTypes = new Array();
        this.objects.forEach(function (todo) {
            _this.allTypes.push(todo.type);
        });
        this.allTypes.sort();
        var tmp = new Array();
        var lastValue = null;
        for (var i = 0; i < this.allTypes.length; i++) {
            if (lastValue != this.allTypes[i] || i == 0) {
                tmp.push(this.allTypes[i]);
                lastValue = this.allTypes[i];
            }
        }
        this.allTypes = tmp;
    };
    DAOToDos.prototype.createInstance = function () {
        var newInstance = new ToDo();
        newInstance.daoRecords = this.daoService.getDAO('records');
        newInstance.daoPersons = this.daoService.getDAO('persons');
        newInstance.daoBeds = this.daoService.getDAO('beds');
        newInstance.daoAreas = this.daoService.getDAO('areas');
        newInstance.daoToDos = this.daoService.getDAO('todos');
        return newInstance;
    };
    DAOToDos.prototype.createNewInstance = function () {
        var object = _super.prototype.createNewInstance.call(this);
        this.updateLocal();
        return object;
    };
    DAOToDos.prototype.assembleObjects = function () {
        _super.prototype.assembleObjects.call(this);
        this.updateLocal();
    };
    DAOToDos.prototype.deleteObject = function (object) {
        _super.prototype.deleteObject.call(this, object);
        this.updateLocal();
    };
    DAOToDos.prototype.updateLocal = function () {
        this._objectSet.replaceObjects(Array.from(this.objects.values()));
        this.buildAllTypes();
    };
    return DAOToDos;
}(DAO));
export { DAOToDos };
