<div class="form-row">
  <div class="form-group col-sm-4">
    <label class="col-form-label col-form-label-sm text-secondary" [for]="fieldId">{{ fieldDTO.text }}</label>
  </div>
  <div class="col-sm-8">
    <div class="input-group input-group-sm" [ngbTooltip]="fieldDTO.help" placement="top" [openDelay]="1000">
      <input class="form-control form-control-sm" type="text"
        [(ngModel)]="object[valueName.toString()]" [id]="fieldId" [placeholder]="fieldDTO.text" [disabled]="disable">

      <div *ngIf="btnIcon" class="input-group-append">
        <button class="btn btn-sm" [ngClass]="myApplication.theme.btnOutline" [disabled]="disable"
          (click)="onClick()" type="button" [ngbTooltip]="btnDTO.text">
          <fa [name]="btnIcon"></fa>
        </button>
      </div>
    </div>
  </div>
</div>