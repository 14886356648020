var TicketDTO = /** @class */ (function () {
    function TicketDTO() {
        this.summary = '';
        this.description = 'Was läuft falsch?\n\n\nWie sollte es laufen?\n\n\n';
        this.category = 'Bug';
        this.priority = 'normal';
        this.partComponents = '';
        this.reporter = '';
        this._release = '';
    }
    Object.defineProperty(TicketDTO.prototype, "release", {
        get: function () {
            return this._release;
        },
        set: function (value) {
            this._release = value;
            if (this._release == 'Raum der Wünsche') {
                this.category = 'Vorschlag';
            }
        },
        enumerable: true,
        configurable: true
    });
    TicketDTO.prototype.toJson = function () {
        var json = {};
        json["summary"] = this.summary;
        json["description"] = this.description;
        json["project"] = { "name": "Anbauplaner" };
        json["category"] = { "name": this.category };
        json["priority"] = { "name": this.priority };
        json["reporter"] = { "name": this.reporter };
        json["target_version"] = { "name": this.release };
        json["custom_fields"] = [
            {
                "field": {
                    "id": 3,
                    "name": "Handbuchrelevant"
                },
                "value": false
            },
            {
                "field": {
                    "id": 1,
                    "name": "Komponente"
                },
                "value": "Desktop-App"
            },
            {
                "field": {
                    "id": 2,
                    "name": "Teilkomponente"
                },
                "value": ' ' + this.partComponents + ' '
            }
        ];
        return json;
    };
    return TicketDTO;
}());
export { TicketDTO };
