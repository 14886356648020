<nav class="navbar navbar-expand-sm fixed-top pl-2 ml-0" [ngClass]="myApplication.theme.background">
  <div class="navbar-collapse collapse dual-collapse2">

    <ng-container *ngIf="!repoService.isExpired">
      <ul class="navbar-nav ml-0">
        <li class="nav-item"><button class="btn btn-sm 0 mr-3" [ngClass]="myApplication.theme.btn" id="btn-next"
            placement="right" [ngbTooltip]="myApplication.getHelpText('Dashboard')" type="button"
            (click)="onNavigateTo('/dashboard')">
            <fa name="home"></fa>
          </button></li>
        <ng-container *ngIf="actionShowGardeningCal">
          <li class="nav-item"><button class="btn btn-sm" [ngClass]="myApplication.theme.btn"
              (click)="onNavigateTo('/gardening-cal')" id="btn-next" type="button" placement="bottom"
              [ngbTooltip]="myApplication.getText('Feedback.view.Anbau-Kalender')">
              <fa name="calendar-check"></fa>
            </button></li>
        </ng-container>

        <ng-container *ngIf="actionShowToDoCal">
          <li class="nav-item">
            <button class="btn btn-sm ml-1" [ngClass]="myApplication.theme.btn" (click)="onNavigateTo('/calendar')"
              id="btn-next" type="button" placement="bottom" [ngbTooltip]="myApplication.getText('AufgabenKalender')">
              <fa name="clipboard-list"></fa>
            </button>
          </li>
        </ng-container>

        <ng-container *ngIf="actionShowCulture || actionShowAreas || actionShowDeliveryPlan">
          <li class="nav-item dropdown"><button class="btn btn-sm ml-1 dropdown-toggle"
              [ngClass]="myApplication.theme.btn" id="menu_planning" type="button">
              <fa name="book-open"></fa> {{ myApplication.getText('planning') }}
            </button>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <ng-container *ngIf="actionShowCulture">
                <a class="dropdown-item" id="menu_cultures" routerLink="/culturesV2">
                  <fa name="leaf"></fa> {{ myApplication.getText('Feedback.view.Kulturen') }}
                </a>
              </ng-container>
              <ng-container *ngIf="actionShowAreas">
                <a class="dropdown-item" routerLink="/areasv2">
                  <fa name="globe-europe"></fa> {{ myApplication.getText('Feedback.view.Schläge') }}
                </a>
              </ng-container>
            </div>
          </li>
        </ng-container>

        <ng-container *ngIf="actionShowConsumer || actionShowSeeds || actionShowStatistics">
          <li class="nav-item dropdown"><button class="btn btn-sm ml-1 dropdown-toggle"
              [ngClass]="myApplication.theme.btn" id="btn-next" type="button">
              <fa name="chart-line"></fa> {{ myApplication.getText('Data') }}
            </button>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a *ngIf="actionShowHarvest" class="dropdown-item" routerLink="/harvestListV2">
                <fa name="carrot"></fa> {{ myApplication.getText('Harvest') }}
              </a>
              <a *ngIf="actionShowDelivery" class="dropdown-item" routerLink="/deliveryV2">
                <fa name="shipping-fast"></fa> {{ myApplication.getText('Delivery') }}
              </a>
              <ng-container *ngIf="actionShowStorage">
                <a class="dropdown-item" routerLink="/storage">
                  <fa name="cube"></fa> {{ myApplication.getText('Lager') }}
                </a>
              </ng-container>
              <ng-container *ngIf="actionShowConsumer">
                <a class="dropdown-item" routerLink="/consumer">
                  <fa name="address-card"></fa> {{ myApplication.getText('Consumers') }}
                </a>
              </ng-container>
              <ng-container *ngIf="actionShowSeeds">
                <a class="dropdown-item" routerLink="/seed-inventory">
                  <fa name="dolly-flatbed"></fa> {{ myApplication.getText('Feedback.view.Saatgut') }}
                </a>
              </ng-container>
              <ng-container *ngIf="actionShowStatistics">
                <a class="dropdown-item" routerLink="/statistic">
                  <fa name="chart-pie"></fa> {{ myApplication.getText('Statistik') }}
                </a>
              </ng-container>
            </div>
          </li>
        </ng-container>

        <li class="nav-item mr-2"><button class="btn btn-sm" [ngClass]="myApplication.theme.btn"
            (click)="onNavigateTo('/company')" id="btn-next" type="button" placement="bottom"
            [ngbTooltip]="myApplication.getText('userSettings.settings')">
            <fa name="cog"></fa>
          </button></li>
        <li *ngIf="!myApplication.showSplitScreenBtn || myApplication.showYearChoose">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <button class="btn btn-sm btn-year-toggle" [ngClass]="myApplication.theme.btn" id="btn-previous"
                type="button" (click)="myApplication.previousYear()">
                <fa name="chevron-left"></fa>
              </button>
            </div>
            <input class="form-control form-control-sm my_right_aligned" size="5" [(ngModel)]="myApplication.year"
              id="weekChoose">
            <div class="input-group-append">
              <button class="btn btn-sm btn-year-toggle" [ngClass]="myApplication.theme.btn" id="btn-next" type="button"
                (click)="myApplication.nextYear()">
                <fa name="chevron-right"></fa>
              </button>
            </div>
          </div>
        </li>


        <li *ngIf="myApplication.showSplitScreenBtn" class="nav-item ml-2">
          <button class="btn btn-sm btn-success"
            [ngClass]="{'splitScreenActive': this.myApplication.splitScreen}"
            (click)="onClickSplitScreen()"
            id="btn-next" type="button" placement="bottom" [ngbTooltip]="myApplication.getHelpText('splitscreen')">
            <fa name="columns"></fa>
          </button>
        </li>

        <li class="nav-item ml-2"><button class="btn btn-sm" [ngClass]="myApplication.theme.btn" (click)="showWizard()"
            id="btn-wizard" type="button" placement="bottom" [ngbTooltip]="myApplication.getHelpText('wizard')">
            <fa name="magic"></fa>
            <sup *ngIf="wizardSrv.data && wizardSrv.data.numOpened > 0"><span
                class="badge badge-pill badge-danger text-white ml-2 message-badge">{{wizardSrv.data.numOpened}}</span></sup>
          </button></li>

        <li *ngIf="this.repoService.repository.infoText" class="text-white bg-info border border-white px-3 pt-0">
          <small>{{this.repoService.repository.infoText}}
          </small>
          <button type="button" class="close btn btn-sm ml-2 my-0" aria-label="Close"
            (click)="this.myApplication.repository.closeInfoText()">
            <h5><span aria-hidden="true">&times;</span></h5>
          </button>
        </li>

        <!-- <li *ngIf="!environment.production">
          <span class="badge text-white border border-white badge-info p-2 ml-2">{{ environment.obj.name }}
          </span>
        </li>

        <li *ngIf="!environment.production">
          <a class="badge text-white border border-white badge-info ml-2" data-toggle="modal" data-target="#bug">
            Bug melden <fa name="fas fa-bug"></fa>
          </a>
        </li> -->

      </ul>

      <div class="text-white ml-4">{{ breadcrumbSrv.breadcrumb }}</div>

      <ul class="navbar-nav ml-auto">
        <li *ngIf="myApplication.availableLanguages.length > 1" class="nav-item dropdown"><button
            class="btn btn-sm ml-1 dropdown-toggle" [ngClass]="myApplication.theme.btn" id="btn-next" type="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ myApplication.selectedLanguage }}
          </button>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a *ngFor="let language of myApplication.availableLanguages" class="dropdown-item"
              (click)="switchLanguage(language)">
              {{ language }}
            </a>
          </div>
        </li>

        <li class="nav-item dropleft dropdown"><button class="btn btn-sm ml-1 dropdown-toggle"
            [ngClass]="myApplication.theme.btn" id="btn-next" type="button" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false" placement="bottom" [ngbTooltip]="myApplication.getHelpText('AppInfo')">
            <fa name="info"></fa>
          </button>
          <div class="dropdown-menu dropdown-menu-left" aria-labelledby="navbarDropdownMenuLink">
            <div class="dropdown-header" href="">Version {{ myApplication.version }}</div>
            <div class="dropdown-header" href="">{{ myApplication.getText('LoginUser') }} {{
              repoService.repository.currentUser }}</div>
            <div class="dropdown-divider"></div>

            <a class="dropdown-item" target="ap_feedback"
              href="https://www.micro-farm-planner.com/{{myApplication.selectedLanguage}}/feedback">
              {{ myApplication.getText('Feedback') }}
            </a>
            <a class="dropdown-item" href="https://www.micro-farm-planner.com/{{myApplication.selectedLanguage}}/manual" target="userManual">{{
              myApplication.getText('userManual') }}</a>

            <a class="dropdown-item" href="https://micro-farm-planner.com/{{myApplication.selectedLanguage}}/" target="aap_trainings">{{
              myApplication.getText('trainings.appointments') }}</a>
            <a class="dropdown-item" href="https://calendly.com/micro-farm-planner/" target="ap_appointment">{{
              myApplication.getText('trainings.appointments.personal') }}</a>

            <a class="dropdown-item" href="{{this.urlFAQ}}" target="ap_faq">{{
              myApplication.getText('FAQ') }}</a>

              <a class="dropdown-item" href="{{this.urlReleaseNotes}}" target="releaseNotes">
                Release notes
              </a>

            <div class="dropdown-divider"></div>

            <a class="dropdown-item" href="https://m.micro-farm-planner.com/" target="mobileApp">{{
              myApplication.getText('mobileApp') }}</a>

            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="https://www.facebook.com/Anbauplaner" target="ap_facebook">
              <i class='fab fa-facebook-square fa-lg'></i> facebook
            </a>
            <a class="dropdown-item" href="https://www.instagram.com/anbauplaner/" target="ap_instagram">
              <i class='fab fa-instagram fa-lg'></i> instagram
            </a>
            <div class="dropdown-divider"></div>

            <a class="dropdown-item" href="{{urlDataProtection}}" target="impressum">{{
              myApplication.getText('Impressum') }}</a>
            <a class="dropdown-item" href="{{urlAGB}}" target="agb">{{
              myApplication.getText('AGB') }}</a>
            <a class="dropdown-item" routerLink="/license">
              {{ myApplication.getText('licenses') }}
            </a>
            <div class="dropdown-divider"></div>
            
            <div class="dropdown-header" href="">build {{ environment.buildTS }} {{ environment.buildDate }}</div>
          </div>
        </li>
      </ul>
    </ng-container>

    <button class="btn btn-sm ml-2" [ngClass]="myApplication.theme.btn" type="button" placement="bottom"
      ngbTooltip="logout" (click)="logout()" id="logoutBtn">
      <fa name="sign-out-alt"></fa>
    </button>
  </div>
</nav>

<div>
  <div class="container-fluid">
    <div class="row">
      <div class="col main topPadding p-0">
        <router-outlet></router-outlet>

        <div class="d-flex flex-row">
          <div class="flex-fill">
            <iframe id="gardening-cal" class="iframe" [src]="urlGardeningCal" [style.height.px]="innerHeight"
              style="display: none;"></iframe>

            <iframe id="areasv2" [src]="urlAreas" [class.iframe-halfsize]="myApplication.splitScreen"
              [class.iframe]="!myApplication.splitScreen" [style.height.px]="innerHeight"
              style="display: none;"></iframe>
          </div>
          <div class="flex-fill areasv2_2" style="display: none;">
            <iframe id="areasv2_2" [src]="urlAreas2" class="iframe-halfsize ml-1"
              [style.height.px]="innerHeight"
              style="display: none;"></iframe>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<app-feedback-modal *ngIf="feedback" [id]="'feedback'" [callbackOwner]="this" [callback]="'sendFeedback'"
  [question]="myApplication.getHelpText('Feedback')" [feedback]="feedback"></app-feedback-modal>

<app-bug [id]="'bug'"></app-bug>