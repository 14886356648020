<div class="container-fluid">
    <div *ngIf="!registrationScreen" class="d-flex justify-content-center bg row my-4">

        <div class="card m-2">
            <div class="card-header">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item dropdown"><button class="btn btn-sm ml-1 dropdown-toggle"
                            [ngClass]="myApplication.theme.btnOutline" id="btn-next" type="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{ myApplication.selectedLanguage }}
                        </button>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <a *ngFor="let language of myApplication.availableLanguages" class="dropdown-item"
                                (click)="switchLanguage(language)">
                                {{ language }}
                            </a>
                        </div>
                    </li>
                </ul>

                <div id="lLogin" class="text-center">
                    <img [src]="logo">
                </div>
                <P><strong>{{ myApplication.getText('Login.title') }}</strong></P>

                <app-text-field [id]="'Login.uname'" [object]="loginDTO" [valueName]="'uname'" [disable]="false">
                </app-text-field>

                <div class="form-row">
                    <div class="form-group col-sm-4">
                        <label class="col-form-label col-form-label-sm text-secondary" [for]="id">{{
                            myApplication.getText('Login.password') }}</label>
                    </div>
                    <div class="col-sm-8">
                        <div class="input-group input-group-sm" [ngbTooltip]="'Mindestens 7 Zeichen lang.'"
                            placement="top" [openDelay]="1000">
                            <input id="pw" class="form-control form-control-sm" type="password"
                                [(ngModel)]="loginDTO.password" [placeholder]="myApplication.getText('Login.password')"
                                (keyup.enter)="login()">
                        </div>
                    </div>
                </div>


                <button class="btn btn-sm mr-1" id="btnLogin" (click)="login()"
                    [ngClass]="myApplication.theme.btnOutline">
                    <span *ngIf="authentication.inLoginProcess" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                    <span *ngIf="authentication.inLoginProcess" class="sr-only">Loading...</span>
                    <fa *ngIf="!authentication.inLoginProcess" name="play"></fa>
                    {{ myApplication.getText('Login.title') }}
                </button>

                <div class="form-group row mt-4 ml-1">
                    <div class="alert alert-danger" id="loginMessage" role="alert" *ngIf="authentication.errorText">
                        <fa name="exclamation-triangle"></fa> {{authentication.errorText}}
                    </div>

                    <div *ngIf="authentication.successText" class="alert alert-success" id="LoginMessageSuccess"
                        role="alert">
                        <fa name="check-circle"></fa> {{authentication.successText}}
                    </div>
                </div>

                <P>
                    <app-button [iconName]="'user-plus'" [id]="'showRegistration'" [callback]="'showRegistration'"
                        [callbackOwner]="this" [disable]="false">
                    </app-button>
                </P>

                <BR>
                <button type="button" class="btn btn-link" (click)="loginForgotten()"><small>{{
                        myApplication.getText('Login.passwordForgotten') }}</small></button>

                <button type="button" class="btn btn-link" (click)="sendActivationLink()"><small>{{
                        myApplication.getText('Login.activationLink') }}</small></button>
            </div>
        </div>
    </div>


    <app-registration *ngIf="registrationScreen"></app-registration>


</div>

<div class="container-fluid">
    <div class="row text-center text-white mt-2 mb-4">
        <div class="col-sm-12">
            <a href="{{urlDataProtection}}" target="impressum">{{
                myApplication.getText('Impressum') }}</a>&nbsp;&nbsp;&nbsp;
            <a href="{{urlAGB}}" target="agb">
                {{ myApplication.getText('AGB') }}</a>
        </div>
    </div>
</div>