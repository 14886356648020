import { Component, OnInit, Input } from '@angular/core';
import { FieldTextDTO } from 'src/app/app-core/dto/field-text-dto';
import { ApplicationService } from 'src/app/services/application.service';

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.css']
})
export class TextFieldComponent implements OnInit {

  private _id = "";
  @Input() object;
  @Input() valueName: String;
  @Input() btnIcon: String;
  @Input() btnObject;
  @Input() btnMethod: String;

  public fieldId = "";

  public fieldDTO: FieldTextDTO;
  public btnDTO: FieldTextDTO;
  private _disable: boolean = false;


  constructor(public myApplication: ApplicationService) { }

  public get disable(): boolean {
      return this._disable;
  }
  @Input()
  public set disable(value: boolean) {
    this._disable = value;
  }

  @Input()
  public set id(value: string) {
    if (!value) {
      this._id = this.valueName.toString();
    }
    this._id = value;
    
    this.fieldDTO = this.myApplication.textDTOs.get(this._id);
    if (!this.fieldDTO) {
      this.fieldDTO = new FieldTextDTO(this._id, this._id, '');
    }
    this.btnDTO = this.myApplication.textDTOs.get(this._id + '.btnTooltip');

    this.fieldId = this._id.replace('.', '-');
  }
  public get id(): string {
    return this._id;
  }

  ngOnInit() {
  }

  onClick() {
    this.btnObject[this.btnMethod.toString()](this.object);
  }
}
