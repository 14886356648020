import { DAO } from '../technical-aspects/dao';
import { Person } from '../business-logic/person';
import { AbstractObject } from '../technical-aspects/abstract-object';

export class DAOPersons extends DAO {

    protected createInstance() {
        let newInstance = new Person();
        return newInstance;
    }

    public createNewInstance(): AbstractObject {
        let object = super.createNewInstance();
        (<Person>object).name = '<' + this.myApplication.getText('new') + '>';
        return object;
    }
}