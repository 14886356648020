import { Component, OnInit } from '@angular/core';
import { ApplicationService } from 'src/app/services/application.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AgreementDTO } from 'src/app/app-core/dto/agreement-dto';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { WizardService } from 'src/app/services/wizard.service';
import { ModalService } from 'src/app/services/modal.service';
import { ModalComponent } from 'src/app/ui-components/modal/modal.component';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-legal-agree',
  templateUrl: './legal-agree.component.html',
  styleUrls: ['./legal-agree.component.css']
})
export class LegalAgreeComponent implements OnInit {

  public agreement: AgreementDTO;
  public cssClass = this.myApplication.theme.btnOutline;
  public tooltipAgree = this.myApplication.getText('Legal.Agree');
  public tooltipDecline = this.myApplication.getText('Legal.Decline');

  modal: NgbModalRef;


  constructor(public myApplication: ApplicationService,
    private modalService: NgbModal, private authentication: AuthenticationService,
    private wizardSrv: WizardService) {

    this.agreement = new AgreementDTO(1);

    myApplication.legalAgreeShown = true;
  }

  ngOnInit() {
  }

  annehmen() {
    this.agreement.agb = true;
    this.agreement.dp = true;
    this.authentication.sendAgreement(this.agreement);
    this.myApplication.legalAgreeShown = false;

    if (!this.myApplication.showWizardOnCloseAgree) {
      // this.wizardSrv.showWizard();

      //neu: Anbauplan erstellen statt Wizard
      let ngbModalOptions: NgbModalOptions = {
        windowClass: "xlModal",
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
        centered: true
      };
  
      const modalRef = this.modalService.open(ModalComponent, ngbModalOptions);
      modalRef.componentInstance.myUrl = environment.cultureModule + '/gardeningCalCreate';

      this.myApplication.showWizardOnCloseAgree = false;
    }

    this.modal.dismiss();
  }

  ablehnen() {
    this.authentication.logout();
    this.myApplication.legalAgreeShown = false;
    this.modalService.dismissAll();
  }
}
