import { Injectable } from '@angular/core';
import { Http, RequestOptions } from '@angular/http';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { WizardStartComponent } from '../ui-components/wizard-start/wizard-start.component';
import { ApplicationService } from './application.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class WizardService {

  data: any;

  constructor(private http: Http, private authenticationService: AuthenticationService,
      private modalService: NgbModal, private myApplication: ApplicationService) { 
  }

  public readOverview() {
    const options = new RequestOptions();
    options.headers = this.authenticationService.getHeadersAuthenticated();

    return new Promise<void>((resolve, reject) => {
      this.http.get(environment.apiV2 + 'wizard/start/overview', options).pipe(
        map(resp => resp.json())
      )
        .subscribe(result => {
          this.data = result;

          if (!this.data.firstShowDone) {
            this.showWizard();
          }
        });
    });
  }

  public setFirstShowDone() {
    let options = new RequestOptions();

    options.headers = this.authenticationService.getHeadersAuthenticated();
    this.http.patch(environment.apiV2 + 'wizard/start/firstDisplay', null, options).pipe(
    )
      .subscribe(
        () => {

        },
        err => {
          console.log('There was an error updating wizard.', err);
        }
      );
  }

  showWizard() {
    if (this.myApplication.legalAgreeShown) {
      this.myApplication.showWizardOnCloseAgree = true;
      console.log('wizard', this.myApplication.showWizardOnCloseAgree)
      return;
    }

    let ngbModalOptions: NgbModalOptions = {
      windowClass: "wizardModal",
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true
    };

    const modalRef = this.modalService.open(WizardStartComponent, ngbModalOptions);

    this.setFirstShowDone();
  }
}
