<div class="card mx-0 my-2 seed-card" [style.border-left-color]="sort.culture.color"
  [style.border-left-width]="'thick'">
  <div class="card-header" data-toggle="collapse" [attr.data-target]="'#sort' + sort.id" aria-expanded="false"
    [attr.aria-controls]="'sort' + sort.id" (click)="headerClicked()">


    <ng-container *ngIf="!editTitle">
      <small class="card-title" (click)="titleClicked()"><B>{{ sort.name }}</B></small>
      <app-button *ngIf="sort.isDeleteable() && actionDeleteSorts" [iconName]="'trash-alt'" [id]="'Sort.delete'" [callback]="'delete'"
        [callbackOwner]="this" [object]="sort" [silent]="true" [stopEventing]="true">
      </app-button>
    </ng-container>

    <ng-container *ngIf="editTitle">
      <div class="input-group input-group-sm col-sm-4 m-0 p-0">
        <input class="form-control form-control-sm" type="text" [(ngModel)]="sort.name"
          [id]="'Sort.name'">
        <app-button *ngIf="actionEditSort" [iconName]="'check'" [id]="'Sort.endEditTitle'" [callback]="'endTitleEditing'"
          [callbackOwner]="this" [silent]="true">
        </app-button>

        <app-button *ngIf="sort.isDeleteable() && actionDeleteSorts" [iconName]="'trash-alt'" [id]="'Sort.delete'" [callback]="'delete'"
          [callbackOwner]="this" [object]="sort" [silent]="true" [stopEventing]="true">
        </app-button>
      </div>
    </ng-container>
  </div>

  <div class="card-body collapse" [id]="'sort' + sort.id">
    <div class="row">
      <div class="col col-sm-12">
        <app-seeds [seedSet]="sort.seedSet" [sort]="sort"></app-seeds>
      </div>
    </div>
  </div>
</div>