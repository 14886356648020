import { Component, OnInit } from '@angular/core';
import { ApplicationService } from 'src/app/services/application.service';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.css']
})
export class LicenseComponent implements OnInit {

  txtLicenses = this.myApplication.getText('licenses');

  
  constructor(private myApplication: ApplicationService) { }

  ngOnInit() {
  }

}
