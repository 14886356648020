import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { LoginDTO } from '../../app-core/dto/login-dto';
import { DaoService } from '../../services/dao.service';
import { ApplicationService } from 'src/app/services/application.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { AppInitializerService } from 'src/app/services/app-initializer.service';


@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})
export class StartComponent implements OnInit {

  loginDTO = new LoginDTO();

  signedIn: boolean;
  user: any;
  greeting: string;
  idSupplied: boolean = false;
  registrationScreen = false;
  showLegal = false;
  public titleAppend = '';
  public promoCode;
  logo = "./assets/images/Logo2.png";

  public urlAGB = 'https://www.micro-farm-planner.com/agb/';
  public urlDataProtection = 'https://www.micro-farm-planner.com/impressum-und-datenschutz/';


  constructor(public myApplication: ApplicationService, private daoService: DaoService, public authentication: AuthenticationService,
    private location: Location, router: Router, private cookieService: CookieService, private appInitSrv: AppInitializerService) {

    if (location.path().includes('/lang')) {
      let langu = this.getLanguFromPath();
      if (langu && langu != myApplication.selectedLanguage) {

        this.switchLanguage(langu);
      }
    }

    if (location.path().includes('/start')) {
      let token = this.getId();
      if (token) {
        this.cookieService.delete('Gemuese-Anbauplaner.repid', '/');
        this.cookieService.delete('Gemuese-Anbauplaner.token', '/');
        this.cookieService.delete('Gemuese-Anbauplaner.uname', '/');

        this.cookieService.delete('Gemuese-Anbauplaner.repid', '/test');
        this.cookieService.delete('Gemuese-Anbauplaner.token', '/test');
        this.cookieService.delete('Gemuese-Anbauplaner.uname', '/test');

        this.cookieService.delete('Gemuese-Anbauplaner.repid', '/app');
        this.cookieService.delete('Gemuese-Anbauplaner.token', '/app');
        this.cookieService.delete('Gemuese-Anbauplaner.uname', '/app');

        localStorage.removeItem('tokenV2');
      }
    }

    if (location.path().includes('/register')) {
      this.registrationScreen = true;
      this.promoCode = this.getPromoCode();
    }

    if (location.path().includes('/invitation')) {
      this.authentication.logout();
      this.authentication.invitationAccept(this.getId());
      router.navigateByUrl('/dashboard');
    }
    if (location.path().includes('/userActivation')) {
      this.authentication.logout();
      this.authentication.userActivation(this.getId());
      router.navigateByUrl('/dashboard');
    }

    if (environment.obj) {
      this.titleAppend = environment.obj.name.toString();
    }

    if (location.path().includes('/start')) {
      let token = this.getId();
      if (token) {
        let tokenSplit = token.split('&');
        if (!tokenSplit[0].includes('start') && tokenSplit.length >= 3) {
          let loginData = new Object();
          loginData["token"] = tokenSplit[0];
          loginData["repid"] = tokenSplit[1];
          loginData["uname"] = tokenSplit[2];

          let loginDTO = new LoginDTO();
          loginDTO.uname = loginData["uname"];
          this.authentication.loginData = loginDTO;
          
          this.authentication.setRepository(loginData);
          this.appInitSrv.initialize();
          this.loginDTO.password = "";

          router.navigateByUrl('/dashboard');
        }
      }
    }

    if (myApplication.selectedLanguage != 'de') {
      this.urlAGB = 'https://www.micro-farm-planner.com/en/gtc';
      this.urlDataProtection = 'https://www.micro-farm-planner.com/en/imprint-and-data-protection/';
    }
  }

  switchLanguage(newLanguage) {
    this.myApplication.changeLanguage(newLanguage);
    window.location.reload();
  }

  private getId() {
    let url = this.location.path().toString();
    let position = url.indexOf('/', 1) + 1;
    return url.substring(position, url.length);
  }

  private getLanguFromPath() {
    let url = this.location.path().toString();
    let position = url.indexOf('lang/', 1) + 5;
    return url.substring(position, url.length);
  }

  private getPromoCode() {
    let url = this.location.path().toString();
    let position = url.indexOf('/', 1) + 1;
    return url.substring(position, url.length);
  }

  loginForgotten() {
    this.authentication.pwReset(this.loginDTO);
  }

  ngOnInit() {
    this.appInitSrv.initialized = false;
    
    if (this.myApplication.selectedLanguage != 'de') {
      this.logo = "./assets/images/Logo_en.png";
    }
  }

  showRegistration() {
    this.registrationScreen = true;
  }

  login() {
    this.authentication.login(this.loginDTO).then(() => {
      this.appInitSrv.initialize();
      this.loginDTO.password = "";
    });
  }

  sendActivationLink() {
    this.authentication.sendActivationLink(this.loginDTO);
  }
}