import * as tslib_1 from "tslib";
import { DAO } from '../technical-aspects/dao';
import { BedAssign } from '../business-logic/bed-assign';
var DAOBedAssigns = /** @class */ (function (_super) {
    tslib_1.__extends(DAOBedAssigns, _super);
    function DAOBedAssigns() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DAOBedAssigns.prototype.createInstance = function () {
        var newInstance = new BedAssign();
        newInstance.daoBeds = this.daoService.getDAO('beds');
        newInstance.daoAreas = this.daoService.getDAO('areas');
        return newInstance;
    };
    return DAOBedAssigns;
}(DAO));
export { DAOBedAssigns };
