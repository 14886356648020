import { RequestOptions, Headers, Http } from '@angular/http';


export abstract class Save {

    route: string;
    data: any;
    options: RequestOptions;
    http: Http;


    public abstract save();
}