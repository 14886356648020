import * as tslib_1 from "tslib";
import { AbstractObject } from '../technical-aspects/abstract-object';
import { HarvestEO } from '../entity-objects/harvest-eo';
var Harvest = /** @class */ (function (_super) {
    tslib_1.__extends(Harvest, _super);
    function Harvest() {
        return _super.call(this) || this;
    }
    Harvest.prototype.update = function () {
    };
    Object.defineProperty(Harvest.prototype, "harvestEO", {
        get: function () {
            return this.eo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Harvest.prototype, "recordId", {
        get: function () {
            return this.harvestEO.recordId;
        },
        set: function (value) {
            this.harvestEO.recordId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Harvest.prototype, "kind", {
        get: function () {
            return this.harvestEO.kind;
        },
        set: function (value) {
            this.harvestEO.kind = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Harvest.prototype, "quantity", {
        get: function () {
            return this.harvestEO.quantity;
        },
        set: function (value) {
            this.harvestEO.quantity = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Harvest.prototype, "quantityUnit", {
        get: function () {
            return this.harvestEO.quantityUnit;
        },
        set: function (value) {
            this.harvestEO.quantityUnit = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Harvest.prototype, "harvestDate", {
        get: function () {
            return this.harvestEO.harvestDate;
        },
        set: function (value) {
            this.harvestEO.harvestDate = this.basicService.normalizeDate(value);
        },
        enumerable: true,
        configurable: true
    });
    Harvest.prototype.initialize = function () {
        this.eo = new HarvestEO();
    };
    return Harvest;
}(AbstractObject));
export { Harvest };
