/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wizard-start.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./wizard-start.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../services/application.service";
var styles_WizardStartComponent = [i0.styles];
var RenderType_WizardStartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WizardStartComponent, data: {} });
export { RenderType_WizardStartComponent as RenderType_WizardStartComponent };
export function View_WizardStartComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "iframe", [["id", "wizardStart"]], [[8, "src", 5], [4, "height", "px"]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; var currVal_1 = _co.innerHeight; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_WizardStartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-wizard-start", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_WizardStartComponent_0, RenderType_WizardStartComponent)), i1.ɵdid(1, 114688, null, 0, i2.WizardStartComponent, [i3.DomSanitizer, i4.ApplicationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WizardStartComponentNgFactory = i1.ɵccf("app-wizard-start", i2.WizardStartComponent, View_WizardStartComponent_Host_0, {}, {}, []);
export { WizardStartComponentNgFactory as WizardStartComponentNgFactory };
