var PeriodUI = /** @class */ (function () {
    function PeriodUI() {
        this.color = 'bg-success';
    }
    Object.defineProperty(PeriodUI.prototype, "name", {
        get: function () {
            return this._name;
        },
        set: function (value) {
            this._name = value;
            this.init();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PeriodUI.prototype, "color", {
        get: function () {
            return this._color;
        },
        set: function (value) {
            if (value && this.opacity != 1) {
                this._color = this.colorLuminance(value, this.opacity);
            }
            else {
                this._color = value;
            }
            this.opacity = 1;
        },
        enumerable: true,
        configurable: true
    });
    PeriodUI.prototype.init = function () {
        if (this._name == 'Anzucht') { //keine Texte
            this.opacity = 0.12;
        }
        else if (this._name == 'Wachstum') {
            this.opacity = 1.0;
        }
        else if (this._name == 'Ernte') {
            this.opacity = 0.07;
        }
        else {
            this.opacity = 0;
        }
    };
    PeriodUI.prototype.colorLuminance = function (hex, lum) {
        // validate hex string
        hex = String(hex).replace(/[^0-9a-f]/gi, '');
        // if (hex.length < 6) {
        //     hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        // }
        // lum = lum || 0;
        // convert to decimal and change luminosity
        var rgb = "#", c, i;
        for (i = 0; i < 3; i++) {
            c = parseInt(hex.substr(i * 2, 2), 16);
            c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
            rgb += ("00" + c).substr(c.length);
        }
        return rgb;
    };
    return PeriodUI;
}());
export { PeriodUI };
