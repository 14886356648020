export class NeedsPlantsDTO {
    public name: String = '';
    public sort: String = '';
    public plantsPerBox = 0;
    public quantity = 0;
    public numBoxes: number = 0;
    public numBoxesUnrounded: number = 0;
    public week: number;
    public vendor: String = '';
    public hint: String = '';
    public date: Date = new Date();
}