import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';
import { Constants } from '../business-logic/constants';

export class SeedEO extends AbstractEntityObject{

    public sortSeedId: String;
    public sortId: String;
    public quantity: number;
    public quantityUnit: String;
    public done: boolean;
    public otherUnit: boolean;


    constructor() {
        super();
        this.sortSeedId = '';
        this.sortId = '';
        this.quantity = 0;
        this.quantityUnit = Constants.UNIT_GRAMM;
        this.done = false;
        this.otherUnit = false;
    }
  }