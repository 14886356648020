import { ThemeDTO } from './theme-dto';

export class GreenThemeDTO extends ThemeDTO{

    constructor() { 
        super();
        this.background = 'bg-success';
        this.btnOutlineNav = 'btn-outline-light';
        this.btnOutline = 'btn-outline-success';
        this.btn = 'btn-success';
        this.badge = 'badge-success';
      }
}