import * as tslib_1 from "tslib";
import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';
import { Constants } from '../business-logic/constants';
var TemplateEO = /** @class */ (function (_super) {
    tslib_1.__extends(TemplateEO, _super);
    function TemplateEO() {
        var _this = _super.call(this) || this;
        _this.parentId = '';
        _this.predefType = '';
        _this.type = '';
        _this.typeId = '';
        _this.name = '';
        _this.quantity = 0;
        _this.quantityUnit = '';
        _this.time = 0;
        _this.timeUnit = 'Tage';
        _this.timeType = 'n';
        _this.timeReference = 'w';
        _this.color = '';
        _this.note = '';
        _this.active = true;
        _this.isModel = false;
        _this.isSeries = false;
        _this.seriesIntervall = 0;
        _this.seriesIntervallUnit = 'T';
        _this.endTime = 0;
        _this.endTimeUnit = 'Tage';
        _this.endTimeType = 'v';
        _this.endTimeReference = Constants.TEMPLATE_TIME_REF_ERNTE;
        return _this;
        //  this.endDueDate = new Date();
    }
    return TemplateEO;
}(AbstractEntityObject));
export { TemplateEO };
