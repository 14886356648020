import * as tslib_1 from "tslib";
import { AbstractObject } from '../technical-aspects/abstract-object';
import { TemplateEO } from '../entity-objects/template-eo';
import { Constants } from './constants';
import { EventHandler } from '../technical-aspects/event-handler';
var Template = /** @class */ (function (_super) {
    tslib_1.__extends(Template, _super);
    function Template() {
        var _this = _super.call(this) || this;
        _this.selected = false;
        _this.checkedForRecord = false;
        _this.fromTemplate = false;
        return _this;
    }
    Template.prototype.usesNameQuantity = function () {
        if (this.type == 'Düngung' || this.type == 'Pflanzenschutz') {
            return true;
        }
        else {
            return false;
        }
    };
    Template.prototype.isDeleteable = function () {
        if (this.predefType != '') {
            return false;
        }
        else {
            return true;
        }
    };
    Template.prototype.isPredefined = function () {
        if (this.predefType != '') {
            return true;
        }
        else {
            return false;
        }
    };
    Template.prototype.adoptDataFrom = function (model) {
        this.type = model.type;
        this.name = model.name;
        this.note = model.note;
        this.timeReference = model.timeReference;
        this.timeType = model.timeType;
        this.quantity = model.quantity;
        this.quantityUnit = model.quantityUnit;
        this.time = model.time;
        this.timeUnit = model.timeUnit;
        this.color = model.color;
        this.active = model.active;
        this.isModel = false;
        this.isSeries = model.isSeries;
        this.seriesIntervall = model.seriesIntervall;
        this.seriesIntervallUnit = model.seriesIntervallUnit;
        this.endTime = model.endTime;
        this.endTimeReference = model.endTimeReference;
        this.endTimeUnit = model.endTimeUnit;
        this.endTimeType = model.endTimeType;
    };
    Template.prototype.initialize = function () {
        this.eo = new TemplateEO();
    };
    Object.defineProperty(Template.prototype, "templateEO", {
        get: function () {
            return this.eo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Template.prototype, "parentId", {
        get: function () {
            return this.templateEO.parentId;
        },
        set: function (value) {
            this.templateEO.parentId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Template.prototype, "predefType", {
        get: function () {
            return this.templateEO.predefType;
        },
        set: function (value) {
            this.templateEO.predefType = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Template.prototype, "type", {
        get: function () {
            return this.templateEO.type;
        },
        set: function (value) {
            this.templateEO.type = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Template.prototype, "typeId", {
        get: function () {
            return this.templateEO.typeId;
        },
        set: function (value) {
            this.templateEO.typeId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Template.prototype, "name", {
        get: function () {
            return this.templateEO.name;
        },
        set: function (value) {
            if (this.templateEO.name != value) {
                this.templateEO.name = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Template.prototype, "isModel", {
        get: function () {
            return this.templateEO.isModel;
        },
        set: function (value) {
            if (this.templateEO.isModel != value) {
                this.templateEO.isModel = value;
                this.myApplication.eventHandler.raiseEvent(EventHandler.TEMPLATE_MODELS_SET_CHANGED);
            }
        },
        enumerable: true,
        configurable: true
    });
    Template.prototype.toggleIsModel = function () {
        this.isModel = !this.isModel;
    };
    Object.defineProperty(Template.prototype, "quantity", {
        get: function () {
            return this.templateEO.quantity;
        },
        set: function (value) {
            this.templateEO.quantity = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Template.prototype, "quantityUnit", {
        get: function () {
            return this.templateEO.quantityUnit;
        },
        set: function (value) {
            this.templateEO.quantityUnit = value;
        },
        enumerable: true,
        configurable: true
    });
    Template.prototype.getQuantityUnitText = function () {
        var textDTO = this.myApplication.textDTOs.get('Template.quantity.' + this.templateEO.quantityUnit);
        if (textDTO) {
            return textDTO.text;
        }
        else {
            return '';
        }
    };
    Object.defineProperty(Template.prototype, "time", {
        get: function () {
            return this.templateEO.time;
        },
        set: function (value) {
            this.templateEO.time = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Template.prototype, "timeUnit", {
        get: function () {
            return this.templateEO.timeUnit;
        },
        set: function (value) {
            this.templateEO.timeUnit = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Template.prototype, "timeType", {
        get: function () {
            return this.templateEO.timeType;
        },
        set: function (value) {
            this.templateEO.timeType = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Template.prototype, "timeReference", {
        get: function () {
            return this.templateEO.timeReference;
        },
        set: function (value) {
            this.templateEO.timeReference = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Template.prototype, "color", {
        get: function () {
            return this.templateEO.color;
        },
        set: function (value) {
            this.templateEO.color = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Template.prototype, "note", {
        get: function () {
            return this.templateEO.note;
        },
        set: function (value) {
            this.templateEO.note = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Template.prototype, "active", {
        get: function () {
            return this.templateEO.active;
        },
        set: function (value) {
            this.templateEO.active = value;
            this.checkedForRecord = value;
        },
        enumerable: true,
        configurable: true
    });
    Template.prototype.update = function () {
    };
    Template.prototype.getTimeTypeText = function () {
        return this.myApplication.textDTOs.get('Template.timeType.' + this.timeType).text;
    };
    Template.prototype.getTimeReferenceText = function () {
        return this.myApplication.textDTOs.get('Template.timeReference.' + this.timeReference).text;
    };
    Object.defineProperty(Template.prototype, "isSeries", {
        get: function () {
            return this.templateEO.isSeries;
        },
        set: function (value) {
            this.templateEO.isSeries = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Template.prototype, "seriesIntervall", {
        get: function () {
            return this.templateEO.seriesIntervall;
        },
        set: function (value) {
            this.templateEO.seriesIntervall = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Template.prototype, "seriesIntervallUnit", {
        get: function () {
            return this.templateEO.seriesIntervallUnit;
        },
        set: function (value) {
            this.templateEO.seriesIntervallUnit = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Template.prototype, "endTime", {
        get: function () {
            return this.templateEO.endTime;
        },
        set: function (value) {
            this.templateEO.endTime = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Template.prototype, "endTimeUnit", {
        get: function () {
            return this.templateEO.endTimeUnit;
        },
        set: function (value) {
            this.templateEO.endTimeUnit = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Template.prototype, "endTimeType", {
        get: function () {
            return this.templateEO.endTimeType;
        },
        set: function (value) {
            this.templateEO.endTimeType = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Template.prototype, "endTimeReference", {
        get: function () {
            return this.templateEO.endTimeReference;
        },
        set: function (value) {
            this.templateEO.endTimeReference = value;
        },
        enumerable: true,
        configurable: true
    });
    Template.prototype.createToDO = function () {
        var todo = this.daoToDos.createNewInstance();
        if (this.isPredefined()) {
            todo.toDoType = Constants.TODO_RECORD_DATES;
        }
        else {
            todo.toDoType = Constants.TODO_RELATIVE;
        }
        todo.type = this.type;
        todo.name = this.name;
        todo.note = this.note;
        todo.color = this.color;
        todo.quantity = this.quantity;
        todo.quantityUnit = this.quantityUnit;
        todo.time = this.time;
        todo.timeUnit = this.timeUnit;
        todo.timeType = this.timeType;
        todo.timeReference = this.timeReference;
        if (this.isSeries) {
            todo.toDoType = Constants.TODO_SERIES_RELATIVE;
            todo.seriesMaster = todo.id;
            todo.seriesIntervall = this.seriesIntervall;
            todo.seriesIntervallUnit = this.seriesIntervallUnit;
            todo.endTime = this.endTime;
            todo.endTimeUnit = this.endTimeUnit;
            todo.endTimeType = this.endTimeType;
            todo.endTimeReference = this.endTimeReference;
        }
        return todo;
    };
    Template.prototype.clone = function () {
        var template = this.daoTemplate.createNewInstance();
        template.parentId = this.parentId;
        template.type = this.type;
        template.name = this.name;
        template.quantity = this.quantity;
        template.quantityUnit = this.quantityUnit;
        template.time = this.time;
        template.timeUnit = this.timeUnit;
        template.timeType = this.timeType;
        template.timeReference = this.timeReference;
        template.color = this.color;
        template.note = this.note;
        template.active = this.active;
        return template;
    };
    return Template;
}(AbstractObject));
export { Template };
