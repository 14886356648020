import { AbstractObject } from '../technical-aspects/abstract-object';
import { PersonEO } from '../entity-objects/person-eo';

export class Person extends AbstractObject {

    constructor() {
        super();
    }

    update() {

    }

    initialize() {
        this.eo = new PersonEO();
    }

    protected get personEO(): PersonEO {
        return <PersonEO>this.eo;
    }

    public get name(): String {
        return this.personEO.name;
    }
    public set name(value: String) {
        this.personEO.name = value;
    }

    public get avatar(): String {
        return this.personEO.avatar;
    }
    public set avatar(value: String) {
        this.personEO.avatar = value;
    }

    public get workHours(): number {
        return this.personEO.workHours;
    }
    public set workHours(value: number) {
        this.personEO.workHours = value;
    }

    public get uname(): String {
        return this.personEO.uname;
    }
    public set uname(value: String) {
        this.personEO.uname = value;
    }
}