import * as tslib_1 from "tslib";
import { Area } from '../business-logic/area';
import { DAO } from '../technical-aspects/dao';
var DAOAreas = /** @class */ (function (_super) {
    tslib_1.__extends(DAOAreas, _super);
    function DAOAreas() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DAOAreas.prototype.createInstance = function () {
        var newInstance = new Area();
        newInstance.daoBeds = this.daoService.getDAO('beds');
        newInstance.daoRecords = this.daoService.getDAO('records');
        newInstance.daoToDos = this.daoService.getDAO('todos');
        return newInstance;
    };
    DAOAreas.prototype.createNewInstance = function () {
        var object = _super.prototype.createNewInstance.call(this);
        object.name = '<' + this.myApplication.getText('new') + '>';
        return object;
    };
    DAOAreas.prototype.deleteObject = function (object) {
        var dao = this.daoService.getDAO('beds');
        var beds = dao.getBedsForArea(object.id);
        for (var i = 0; i < beds.length; i++) {
            dao.deleteObject(beds[i]);
        }
        _super.prototype.deleteObject.call(this, object);
    };
    return DAOAreas;
}(DAO));
export { DAOAreas };
