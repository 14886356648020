import { Component, OnInit, Input } from '@angular/core';
import { ApplicationService } from 'src/app/services/application.service';
import { FieldTextDTO } from 'src/app/app-core/dto/field-text-dto';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {

  @Input() iconName: String;
  @Input() id: String;
  @Input() callback: String;
  @Input() callbackOwner: String;
  @Input() object: any;
  @Input() silent = false;
  @Input() dataDismiss = '';
  @Input() stopEventing = false;
  @Input() btnClass = this.myApplication.theme.btnOutline;
  @Input() popupTitle = this.myApplication.getTextSimple('Confirm.delete');
  @Input() confirmQuestion = this.myApplication.getTextSimple('Confirm.deleteQuestion');

  private _disable: boolean = false;
  public fieldDTO: FieldTextDTO;
  public silentClass = '';


  constructor(public myApplication: ApplicationService) { }

  ngOnInit() {
    this.fieldDTO = this.myApplication.textDTOs.get(this.id);
    if (!this.fieldDTO) {
      this.fieldDTO = new FieldTextDTO(this.id, '', '');
    }
    if (this.silent) {
      this.silentClass = 'silentButton';
    }
  }

  stopEvent(event) {
    if (this.stopEventing) {
      event.stopPropagation();
    }
  }

  onClick(event) {
    if (this.object) {
      this.callbackOwner[this.callback.toString()](this.object);
    } else {
      this.callbackOwner[this.callback.toString()]();
    }
    if (this.stopEventing) {
      event.stopPropagation();
    }
  }

  public get disable(): boolean {
    return this._disable;
  }
  @Input()
  public set disable(value: boolean) {
    this._disable = value;
  }
}
