import { Component, OnInit, Input } from '@angular/core';
import { ApplicationService } from 'src/app/services/application.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {

  @Input() callbackOwner;
  @Input() callback;
  @Input() object: any;
  @Input() question: String = this.myApplication.getTextSimple('Confirm.deleteQuestion');
  @Input() stopEventingOnCancel = true;

  constructor(private myApplication: ApplicationService) { }

  ngOnInit() {
  }

  noAction() {
    
  }
}
