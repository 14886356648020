import { ApplicationService } from 'src/app/services/application.service';
import { BasicService } from 'src/app/services/basic.service';
import { WeekInYearDTO } from '../dto/week-in-year-dto';
import { Constants } from './constants';
import { PartsService } from 'src/app/services/parts.service';
import { FilteredParts } from './filteredParts';
import { RepositoryService } from 'src/app/services/repository.service';


export class CalculatorQuantity {

    constructor(protected harvestMethod: String, protected harvestQuantity: number, protected numHarvestWeeks: number,
        protected neededQuantity: number, protected harvestWeek: WeekInYearDTO,
        protected alternativeDeliveryWeeks: boolean, protected safety: number,
        protected plantDistance: number, protected rowDistance: number,
        protected myApplication: ApplicationService, protected basicService: BasicService,
        protected seedRate: number, protected cultureType: String,
        public partsService: PartsService, public extraQuantity, public repoService: RepositoryService, public harvestUnit) {

    }

    public calculate(): number {

        let endQuantity = 0;

        let maxQuantity: number = 0;
        let sumQuantity: number = 0;
        let localMax: number = 0;

        let partTypes = this.partsService.partTypes;
        let parts = this.partsService.parts;
        let filteredParts: FilteredParts = new FilteredParts();
        let test = this.repoService.repository.system;
        if (!this.alternativeDeliveryWeeks) {
            if (this.numHarvestWeeks == 0) {
                endQuantity = 0;
                if (this.myApplication) {
                    this.myApplication.messageDTO.setErrorWithId('FM.numHarvestWeeksIsZero');
                }
                return 0;
            }
            for (let i = 1; i <= this.numHarvestWeeks; i++) {
                let myNumParts: number = 0;
                localMax = 0;
                // nur fuer SoLaWi nach Paketen/Anteilen schauen
                if (this.repoService.repository.system == Constants.SYSTEM_SOLAWI) {
                    //jetzt neu die Anteile mit Wertigkeit
                    partTypes.forEach(element => {
                        let myParts = filteredParts.getPartsForPartType(element.id, parts);
                        myNumParts = filteredParts.getCurrentPartsNumParts(myParts, this.harvestWeek);

                        //jetzt Summen aufaddieren -> dabei fuer Bund und Stueck auf Ganzzahl runden
                        if (this.harvestUnit == Constants.UNIT_PIECE || this.harvestUnit == Constants.UNIT_BUND) {
                            sumQuantity = sumQuantity + (myNumParts * Math.ceil(this.neededQuantity * element.score));
                        } else {
                            sumQuantity = sumQuantity + (myNumParts * (this.neededQuantity * element.score));
                        }

                        //fuer max-Werte -> dabei fuer Bund und Stueck auf Ganzzahl runden
                        if (this.harvestUnit == Constants.UNIT_PIECE || this.harvestUnit == Constants.UNIT_BUND) {
                            localMax = localMax + (myNumParts * Math.ceil(this.neededQuantity * element.score));
                        } else {
                            localMax = localMax + (myNumParts * (this.neededQuantity * element.score));
                        }

                    });
                } else {
                    //jetzt Summen aufaddieren
                    sumQuantity = sumQuantity + this.neededQuantity;
                    //fuer max-Werte
                    localMax = this.neededQuantity;

                }

                if (localMax > maxQuantity) {
                    maxQuantity = localMax;
                }

                if (sumQuantity == 0 || !sumQuantity) {
                    endQuantity = 0;
                    if (this.myApplication && this.harvestWeek) {
                        this.myApplication.messageDTO.setErrorWithId('FM.numPartsIsZero', (this.harvestWeek.week.toString() + '/' + this.harvestWeek.year.toString()));
                    }
                    return 0;
                }

                if (this.neededQuantity == 0) {
                    endQuantity = 0;
                    if (this.myApplication) {
                        this.myApplication.messageDTO.setErrorWithId('FM.neededHarvestQuantityIsZero');
                    }
                    return 0;
                }

                if (this.harvestWeek) {
                    this.harvestWeek = this.basicService.addWeeks(this.harvestWeek, 1);
                }
            }


        }
        else {
            if (this.neededQuantity == 0) {
                endQuantity = 0;
                if (this.myApplication) {
                    this.myApplication.messageDTO.setErrorWithId('FM.delivertyQuantityIsZero');
                }
                return 0;
            }
            if (this.numHarvestWeeks == 0) {
                endQuantity = 0;
                if (this.myApplication) {
                    this.myApplication.messageDTO.setErrorWithId('FM.numDeliveryWeeksIsZero');
                }
                return 0;
            }
            let myNumParts: number = 0;
            // nur fuer SoLaWi nach Paketen/Anteilen schauen
            if (this.repoService.repository.system == Constants.SYSTEM_SOLAWI) {
                //jetzt neu die Anteile mit Wertigkeit
                partTypes.forEach(element => {
                    let myParts = filteredParts.getPartsForPartType(element.id, parts);
                    myNumParts = filteredParts.getCurrentPartsNumParts(myParts, this.harvestWeek);

                    //jetzt Summen aufaddieren -> dabei fuer Bund und Stueck auf Ganzzahl runden
                    if (this.harvestUnit == Constants.UNIT_PIECE || this.harvestUnit == Constants.UNIT_BUND) {
                        sumQuantity = sumQuantity + (myNumParts * Math.ceil(this.neededQuantity * element.score) * this.numHarvestWeeks);
                    } else {
                        sumQuantity = sumQuantity + (myNumParts * (this.neededQuantity * element.score) * this.numHarvestWeeks);
                    }
                    //fuer max-Werte
                    if (this.harvestUnit == Constants.UNIT_PIECE || this.harvestUnit == Constants.UNIT_BUND) {
                        localMax = localMax + (myNumParts * Math.ceil(this.neededQuantity * element.score));
                    } else {
                        localMax = localMax + (myNumParts * Math.ceil(this.neededQuantity * element.score));
                    }

                });
            } else {
                //jetzt Summen aufaddieren
                sumQuantity = sumQuantity + this.neededQuantity * this.numHarvestWeeks;

                //fuer max-Werte
                localMax = this.neededQuantity;

            }

            if (localMax > maxQuantity) {
                maxQuantity = localMax;
            }

        }
        //wenn zusaetzliche Erntemenge je Lieferwoche ausgewaehlt, dann jetzt noch dazurechnen (ohne Anteilsbindung)  nur fuer SoLaWi      
        if (this.extraQuantity && this.extraQuantity > 0 && this.repoService.repository.system == Constants.SYSTEM_SOLAWI) {
            sumQuantity = sumQuantity + (this.extraQuantity * this.numHarvestWeeks);
            maxQuantity = maxQuantity + this.extraQuantity;
        }

        if (this.harvestMethod == Constants.HARVEST_METHOD_ABSOLUT) {
            endQuantity = sumQuantity;
        }
        else if (this.harvestMethod == Constants.HARVEST_METHOD_WEEK) {
            endQuantity = this.calculateWithHarvestQuantity(maxQuantity, this.harvestQuantity);
        }
        else if (this.harvestMethod == Constants.HARVEST_METHOD_M2) {
            endQuantity = this.calculateQuantityForHarvestMethodM2(sumQuantity, this.harvestQuantity);
        }
        else if (this.harvestMethod == Constants.HARVEST_METHOD_M2_WEEK) {
            endQuantity = this.calculateQuantityForHarvestMethodM2(maxQuantity, this.harvestQuantity); // gleiche Methode, anderer Input
        }
        endQuantity = this.calculateSafety(endQuantity, this.safety);
        // endQuantity = Math.floor(endQuantity);
        endQuantity = Math.ceil(endQuantity);

        //und jetzt noch fuer Anzucht die Keimrate beruecksichtigen
        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
            endQuantity = endQuantity / this.seedRate * 100;
            endQuantity = Math.ceil(endQuantity);
        }

        return endQuantity;
    }

    public calculateWithHarvestQuantity(inQuantity: number, harvestQuantity: number): number {
        let quantity: number = 0;

        if (harvestQuantity == 0) {
            quantity = 0;
            if (this.myApplication) {
                this.myApplication.messageDTO.setErrorWithId('FM.harvestQuantityIsZero');
            }
            return 0;
        }
        quantity = inQuantity / harvestQuantity;
        return quantity;
    }

    private calculateQuantityForHarvestMethodM2(sumQuantity: number, harvestQuantity: number): number {
        let quantity: number = 0;
        let neededM2: number = 0;
        let plantPerM2: number = 0;
        neededM2 = this.calculateWithHarvestQuantity(sumQuantity, harvestQuantity);
        plantPerM2 = this.calculatePlantPerM2();
        quantity = plantPerM2 * neededM2;
        return quantity;
    }

    private calculateSafety(quantity: number, safety: number): number {
        if (safety > 0) {
            // quantity = quantity + (quantity * safety / 100);
            quantity = quantity / (100 - safety) * 100;
        }
        return quantity;
    }

    public calculatePlantPerM2(): number {
        let plantPerColumn: number = 0;
        let plantPerRow: number = 0;
        let plantPerM2: number = 0;

        if (this.plantDistance == 0) {
            if (this.myApplication) {
                this.myApplication.messageDTO.setErrorWithId('FM.plantDistanceIsZero');
            }
            return 0;
        }
        if (this.rowDistance == 0) {
            if (this.myApplication) {
                this.myApplication.messageDTO.setErrorWithId('FM.rowDistanceIsZero');
            }
            return 0;
        }
        plantPerColumn = 100 / this.plantDistance;
        //plantPerColumn = Math.floor(plantPerColumn);
        plantPerRow = 100 / this.rowDistance;
        //plantPerRow = Math.floor(plantPerRow);
        plantPerM2 = plantPerColumn * plantPerRow;
        return plantPerM2;
    }
}