import { DAO } from '../technical-aspects/dao';
import { Sort } from '../business-logic/sort';
import { DAOCultures } from './dao-cultures';
import { DAOSeeds } from './dao-seeds';
import { DAOSortSeeds } from './dao-sort-seeds';
import { AbstractObject } from '../technical-aspects/abstract-object';

export class DAOSorts extends DAO {

    protected createInstance() {
        let newInstance = new Sort();
        newInstance.daoCultures = <DAOCultures>this.daoService.getDAO('cultures');
        newInstance.daoSeeds = <DAOSeeds>this.daoService.getDAO('seeds');
        newInstance.daoSortSeeds = <DAOSortSeeds>this.daoService.getDAO('sortSeeds');
        return newInstance;
    }

    public deleteObject(object: AbstractObject) {
        let sort = <Sort>object;
        sort.deleteChildren();
        super.deleteObject(object);
    }
}