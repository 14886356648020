import { DAORecords } from '../dao/dao-records';
import { DAOSeeds } from '../dao/dao-seeds';
import { Record } from './record';
import { Constants } from './constants';
import { BasicService } from 'src/app/services/basic.service';
import { WeekInYearDTO } from '../dto/week-in-year-dto';
import { NeedsPlantsDTO } from '../dto/needs-plants-dto';


export class NeedsCalculatorPlants {

    public plantsNeeds = new Array<NeedsPlantsDTO>();


    constructor(protected daoRecords: DAORecords, protected daoSeeds: DAOSeeds, protected basicService: BasicService) {
    }

    calculate() {
        this.calculatePlants(false);
    }

    calculateDay() {
        this.calculatePlants(true);
    }

    getCSVList(): Array<string> {
        let list = new Array<string>();
        let object: any;
        // let myApplication = this.daoRecords.myApplication;

        this.plantsNeeds.forEach(need => {
            object = new Object();
            object.name = need.name;
            object.sort = need.sort;
            object.quantity = need.quantity;
            object.numBoxes = need.numBoxes;
            object.numBoxesUnrounded = need.numBoxesUnrounded;
            object.kw = need.week;
            list.push(object);
        });

        return list;
    }

    protected calculatePlants(perDay: boolean) {
        let myApplication = this.daoRecords.myApplication;
        let needs: NeedsPlantsDTO;

        this.daoRecords.objects.forEach(element => {
            let record = <Record>element;
            if (record.cultureType == Constants.CULTURE_TYPE_PFLANZKAUF && record.recordPeriods.growDate.getFullYear() == myApplication.year) {
                needs = this.getByName(this.plantsNeeds, record, record.recordPeriods.growWeek, perDay);
                needs.quantity = needs.quantity + record.quantity;
                needs.plantsPerBox = record.plantsPerBox;
            }
        });

        this.plantsNeeds.forEach(needs => {
            if (needs.quantity > 0 && needs.plantsPerBox > 0) {
                needs.numBoxesUnrounded = Math.ceil(needs.quantity / needs.plantsPerBox * 100) / 100; //Rundung auf 2 Stellen
                needs.numBoxes = Math.ceil(needs.quantity / needs.plantsPerBox);
            } else {
                needs.numBoxesUnrounded = 0;
                needs.numBoxes = 0;
            }
        });

        if (!perDay) {
            this.plantsNeeds.sort(function (one, two) {
                let compare = one.week - two.week;
                if (compare == 0) {
                    return one.name.localeCompare(two.name.toString());
                }
                return compare;
            });
        } else {
            this.plantsNeeds.sort(function (one, two) {
                let compare = one.date.getTime() - two.date.getTime();
                if (compare == 0) {
                    return one.name.localeCompare(two.name.toString());
                }
                return compare;
            });
        }
    }

    protected getByName(list: Array<NeedsPlantsDTO>, record: Record, plantWeek: WeekInYearDTO, perDay: boolean): NeedsPlantsDTO {
        for (let i = 0; i < list.length; i++) {
            if (!perDay) {
                if (list[i].name == record.culture.name && list[i].sort == record.sort && list[i].week == plantWeek.week) {
                    return list[i];
                }
            } else {
                if (list[i].name == record.culture.name && list[i].sort == record.sort && list[i].week == plantWeek.week
                    && list[i].date.getTime() == record.recordPeriods.growDate.getTime()) {
                    return list[i];
                }
            }
        }
        let needs = new NeedsPlantsDTO();
        needs.name = record.culture.name;
        needs.sort = record.sort;
        needs.week = plantWeek.week;
        needs.date = new Date(record.recordPeriods.growDate.getTime());
        list.push(needs);
        return needs;
    }
}