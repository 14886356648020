import * as tslib_1 from "tslib";
import { DAO } from '../technical-aspects/dao';
import { Feedback } from '../business-logic/feedback';
var DAOFeedback = /** @class */ (function (_super) {
    tslib_1.__extends(DAOFeedback, _super);
    function DAOFeedback() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DAOFeedback.prototype.createInstance = function () {
        var newInstance = new Feedback();
        return newInstance;
    };
    DAOFeedback.prototype.saveDB = function () {
        var data;
        if (this.newObjects.size > 0) {
            var filledObjects_1 = new Array();
            this.newObjects.forEach(function (element) {
                if (element.comment != '') {
                    filledObjects_1.push(element);
                }
            });
            if (filledObjects_1.length > 0) {
                data = this.buildJSON(Array.from(filledObjects_1), true);
                this.insert(data);
                this.newObjects.forEach(function (object) {
                    object.resetDBState();
                });
                this.newObjects.clear();
            }
        }
    };
    return DAOFeedback;
}(DAO));
export { DAOFeedback };
