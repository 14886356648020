<div class="form-row">
  <div class="form-group" [ngClass]="colsLabel">
    <label class="col-form-label col-form-label-sm text-secondary" [for]="id">{{ fieldDTO.text }}</label>
  </div>
  <div [ngClass]="colsField">
    <div class="input-group input-group-sm" [ngbTooltip]="fieldDTO.help" placement="top" [openDelay]="1000">

      <input *ngIf="ngbPopoverContent" class="form-control form-control-sm my_right_aligned {{getWarningClass()}}"
        type="text" [(ngModel)]="value" [id]="id" [placeholder]="fieldDTO.text"
        [disabled]="disable" pattern="[0-9]+([\.|,][0-9]{1,2})?" [ngbPopover]="ngbPopoverContent"
        [popoverTitle]="ngbPopoverTitle" triggers="mouseenter:mouseleave" (blur)="onBlur($event)" container="body">

      <input *ngIf="!ngbPopoverContent" class="form-control form-control-sm my_right_aligned {{getWarningClass()}}"
        type="text" [(ngModel)]="value" [id]="id" [placeholder]="fieldDTO.text"
        [disabled]="disable" pattern="[0-9]+([\.|,][0-9]{1,2})?" (blur)="onBlur($event)">


      <div *ngIf="unit" class="input-group-append text-muted">
        <span class="input-group-text {{unitClass}}" id="inputGroup-sizing-sm">{{ unit }}</span>
      </div>
      <div *ngIf="btnIcon" class="input-group-append">
        <button class="btn btn-sm" [ngClass]="btnClass" [disabled]="!lockingService.isLocked" (click)="onClick()"
          type="button" [ngbTooltip]="btnDTO.help" placement="top">
          <fa [name]="btnIcon"></fa>
        </button>
      </div>
    </div>

  </div>
</div>