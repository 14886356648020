<div class="modal-header">
  <h5 class="modal-title" id="chooseRepoTitle">
    {{ myApplication.getText('Repositorys.Multiple') }}</h5>
</div>

<div class="modal-body">
  <app-select-field [id]="'Repository.choose'" [object]="this" [valueName]="'repoChoosen'" [keyValueList]="repositorys" [disable]="false">
  </app-select-field>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-sm mr-1" [ngClass]="myApplication.theme.btnOutline" (click)="close()"
    [ngbTooltip]="'auswählen'">
    <fa name="check"></fa>
  </button>
</div>