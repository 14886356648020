import { Environment } from 'src/app/app-core/technical-aspects/environment';

export const environment = {
  production: false,
  stage: 'cons',
  obj: new Environment('cons', 'Konsolidierungs-Umgebung'),
  api: './api/public/',
  apiV2: './APIPlannerRecords/public/api/',
  apiAdmin: 'admin/APIAdminV2/public/api/unsubscribe/',
  folder: 'test',
  token: '',
  expireDate: '2020-02-01 07:00:00',

  statisticModule: './statisticModule',
  harvestModule: './harvestModule',
  harvestModuleV2: './harvestModuleV2/#/harvestList',
  companyModule: './companyModule/#/home/',
  consumerModule: './consumerModule',
  gardeningCalModule: '/gardeningCalModule',
  toDoModule: './toDosModule',
  recordModule: './recordModule',
  areasModule: '/areasModule',
  deliveryPlanModule: '/deliveryPlanModule/#/home/',
  storageModule: './storageModule',
  deliveryModule: '/deliveryModule/#',

  wizardModule: '/wizardModule',
  cultureModule: '/culturesModule/#',

  bucktracking: 'https://micro-farm-planner.com/mantis/api/rest/',

  buildDate: '17.01.24 11:40:41',
  buildTS: '1705488041'
};
