import { WeekInYearDTO } from './week-in-year-dto';

export class MonthDTO {
    name: String;
    duration: number;
    firstWeek: WeekInYearDTO;
    lastWeek: WeekInYearDTO;


    constructor(name: String, duration: number) {
        this.name = name;
        this.duration = duration;
    }

    // get lastWeek() {
    //     return this.firstWeek + this.duration - 1;
    // }
}