import { Component, OnInit, Input } from '@angular/core';
import { ApplicationService } from 'src/app/services/application.service';
import { FieldTextDTO } from 'src/app/app-core/dto/field-text-dto';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {

  @Input() callbackOwner;
  @Input() callback;
  @Input() object: any;
  @Input() question: String = '';
  @Input() id;
  questionDTO: FieldTextDTO;

  constructor(public myApplication: ApplicationService, private modalService: NgbModal) { }

  ngOnInit() {
    this.questionDTO = this.myApplication.textDTOs.get(this.question);
    if (!this.questionDTO) {
      this.questionDTO = new FieldTextDTO(this.question, this.question, '');
    }
  }

  noAction() {
    this.modalService.dismissAll();
  }

  yes() {
    this.callbackOwner[this.callback.toString()](this.object);
    this.modalService.dismissAll();
  }
}
