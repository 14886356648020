import { DAO } from '../technical-aspects/dao';
import { SortSeed } from '../business-logic/sort-seed';
import { AbstractObject } from '../technical-aspects/abstract-object';
import { DAOSeeds } from './dao-seeds';
import { EventHandler } from '../technical-aspects/event-handler';


export class DAOSortSeeds extends DAO {

    protected createInstance() {
        let newInstance = new SortSeed();
        return newInstance;
    }

    public getSortSeedsForSort(sortId: String): Array<SortSeed> {
        let sortSeeds = new Array<SortSeed>();

        this.objects.forEach(object => {
            let sortSeed = <SortSeed>object;
            if (sortSeed.sortId == sortId) {
                sortSeeds.push(sortSeed);
            }
        });

        return sortSeeds;
    }

    findSortSeed(year: number, manufacturer: String): SortSeed {
        let sortSeed = <SortSeed>this.objects.values().next().value;
        while (sortSeed) {
            if (sortSeed.manufacturingYear == year && sortSeed.manufacturer == manufacturer) {
                return sortSeed;
            }
            sortSeed = <SortSeed>this.objects.values().next().value;
        }
        return null;
    }

    public deleteObject(object: AbstractObject) {
        let daoSeeds = <DAOSeeds>this.daoService.getDAO('seeds');
        let found = false;
        let list = daoSeeds.getSeedsForSortSeed(object.id);
        list.forEach(element => {
            daoSeeds.deleteObject(element);
            found = true;
        });
        if (found) {
            let sortSeed = <SortSeed> object;
            this.myApplication.eventHandler.raiseEvent(EventHandler.SEED_DELETED_EXTERNAL, sortSeed.sortId);
        }
        super.deleteObject(object);
    }
}