import { Component, OnInit, Input } from '@angular/core';
import { ApplicationService } from 'src/app/services/application.service';
import { NeedsCalculator } from 'src/app/app-core/business-logic/needs-calculator';
import { GeneratePdfService } from 'src/app/services/generate-pdf.service';
import { NeedsCalculatorPlants } from 'src/app/app-core/business-logic/needs-calculator-plants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GenerateCsvService } from 'src/app/services/generate-csv.service';
import { SeedsExcel } from 'src/app/app-core/business-logic/seeds-excel';
import { RepositoryService } from 'src/app/services/repository.service';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-needs-modal',
  templateUrl: './needs-modal.component.html',
  styleUrls: ['./needs-modal.component.css']
})
export class NeedsModalComponent implements OnInit {

  @Input() needsCalculator: NeedsCalculator;
  @Input() needsCalculatorPlants: NeedsCalculatorPlants;
  @Input() perDay: boolean = false;
  public seedTitle = this.myApplication.getText('Seed.needed') + ' ' + this.myApplication.year;
  public plantsTitle = this.myApplication.getText('PlantBuy') + ' ' + this.myApplication.year;

  public actionExport: boolean = false;

  constructor(public myApplication: ApplicationService, public pdf: GeneratePdfService, private modalService: NgbModal,
    public repoService: RepositoryService, public permissionsService: PermissionsService) {

    this.checkAllowedActions();
  }

  ngOnInit() {
  }

  exportExcel() {
    let excel = new SeedsExcel(this.myApplication);
    // console.log(this.needsCalculator, this.needsCalculatorPlants);
    excel.export(this.needsCalculator, this.needsCalculatorPlants, this.perDay);


  }

  close() {
    this.modalService.dismissAll();
  }

  checkIfNecessary(): boolean {
    if (this.needsCalculator.seedNeedsOtherUnit && this.needsCalculator.seedNeedsOtherUnit.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  checkAllowedActions() {
    this.actionExport = this.permissionsService.checkPermissionForAction('seeds', 'export', 'seedInventory');
  }
}
