import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApplicationService } from 'src/app/services/application.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from 'src/app/services/repository.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { CookieService } from 'ngx-cookie-service';
import { UserSettings } from 'src/app/app-core/business-logic/user-settings';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  date = new Date();
  public actionShowGardeningCal: boolean = false;
  public actionShowToDoCal: boolean = false;
  public actionShowStatistics: boolean = false;
  public urlManual = 'https://www.micro-farm-planner.com/de/manual/';
  public urlReleaseNotes = 'https://www.micro-farm-planner.com/de/press/';


  constructor(public myApplication: ApplicationService, private _router: Router,
    public repoService: RepositoryService, public dashboardSrv: DashboardService,
    public permissionsService: PermissionsService, private route: ActivatedRoute, private cookieService: CookieService,
    private userSettingsService: UserSettingsService,
    private breadcrumbSrv: BreadcrumbService) {

    this.breadcrumbSrv.breadcrumb = 'Home';
    this.checkAllowedActions();
    this.dashboardSrv.readData();

    this.route.params.subscribe(params => {
      let langu;
      langu = params['langu'];
      let languS = cookieService.get('Gemuese-Anbauplaner.language');

      if (langu && langu != languS) {
        this.myApplication.changeLanguage(langu)
        this.userSettingsService.savePreferredLanguage(langu);
      }

      // Redirect to de/en micro-farm planner manual page depending on selected user language
      if (myApplication.selectedLanguage == 'de') {
        this.urlManual = 'https://www.micro-farm-planner.com/de/manual/'
      }
      else  {
        this.urlManual = 'https://www.micro-farm-planner.com/en/manual/';
        this.urlReleaseNotes = 'https://www.micro-farm-planner.com/en/press/';
      }
    });
  }

  ngOnInit() {
  }

  getCurrentMonthText(): String {
    return this.myApplication.year.toString() + (new Date().getMonth() + 1).toString();
  }

  public navigateCalNotFullPlanned() {
    this.setGardeningCalFilterNotFullPlanned();
    this._router.navigateByUrl("/gardening-cal");
  }

  public navigateCalNoSort() {
    this.setGardeningCalFilterNoSort();
    this._router.navigateByUrl("/gardening-cal");
  }

  private setGardeningCalFilterNotFullPlanned() {
    let filter = JSON.parse(localStorage.getItem('gardeningCalFilter'));
    localStorage.removeItem('gardeningCalFilter');
    let json: any = new Object();
    json.fullPlanned = ["nein"];

    if (filter) {
      json.sortOption = filter.sortOption;
    }
    json = JSON.stringify(json);
    localStorage.setItem('gardeningCalFilter', json);

    let container = document.getElementById('gardening-cal');
    if (container) {
      let iframe = (<HTMLIFrameElement>container);
      iframe.contentWindow.postMessage({ "message": "setGardeningCalFilter" }, '*');
    }
  }

  private setGardeningCalFilterNoSort() {
    let filter = JSON.parse(localStorage.getItem('gardeningCalFilter'));
    localStorage.removeItem('gardeningCalFilter');
    let json: any = new Object();
    json.hasSort = ["nein"];
    
    if (filter) {
      json.sortOption = filter.sortOption;
    }
    json = JSON.stringify(json);
    localStorage.setItem('gardeningCalFilter', json);

    let container = document.getElementById('gardening-cal');
    if (container) {
      let iframe = (<HTMLIFrameElement>container);
      iframe.contentWindow.postMessage({ "message": "setGardeningCalFilter" }, '*');
    }

  }


  private checkAllowedActions() {
    this.actionShowGardeningCal = this.permissionsService.checkPermissionForAction('gardeningCal', 'show', null);
    this.actionShowToDoCal = this.isToDoCalendarAllowed();
    this.actionShowStatistics = this.isStatisticAllowed();
  }

  private isToDoCalendarAllowed(): boolean {
    if (this.permissionsService.checkPermissionForAction('toDoCalendar', 'showAll', null) ||
      this.permissionsService.checkPermissionForAction('toDoCalendar', 'showJustOwn', null)) {
      return true;
    } else {
      return false;
    }
  }

  private isStatisticAllowed(): boolean {
    if (this.permissionsService.checkPermissionForAction('statistics', 'show', 'area') ||
      this.permissionsService.checkPermissionForAction('statistics', 'show', 'culture') ||
      this.permissionsService.checkPermissionForAction('statistics', 'show', 'crop') ||
      this.permissionsService.checkPermissionForAction('statistics', 'show', 'nursery')) {
      return true;
    } else {
      return false;
    }
  }
}
