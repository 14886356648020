import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';

export class FeedbackEO extends AbstractEntityObject {

    public uname: String;
    public view: String;
    public comment: String;

    constructor() {
        super();
        this.uname = '';
        this.view = '';
        this.comment = '';
    }
  }