import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EventHandler } from 'src/app/app-core/technical-aspects/event-handler';
import { ApplicationService } from 'src/app/services/application.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-harvest-v2',
  templateUrl: './harvest-v2.component.html',
  styleUrls: ['./harvest-v2.component.css']
})
export class HarvestV2Component implements OnInit {

  public url: SafeResourceUrl;
  pShowHarvest = this.permissionsService.checkPermissionForAction('harvest', 'show', null);

  innerHeight = 1100;


  constructor(public sanitizer: DomSanitizer, private myApplication: ApplicationService, private breadcrumbSrv: BreadcrumbService,
    private permissionsService: PermissionsService) {

    this.breadcrumbSrv.breadcrumb = this.myApplication.getText('Harvest').toString();
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.harvestModuleV2 + '/' + this.myApplication.year);

    this.myApplication.addObserver(this);
    this.myApplication.eventHandler.register(EventHandler.LANGUAGE_CHANGED, this);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerHeight = window.innerHeight - 60;
  }
  
  ngOnInit() {
    this.onResize(null);
  }

  update() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.harvestModuleV2 + '/' + this.myApplication.year);
  }

  ngOnDestroy() {
    this.update();
    this.myApplication.removeObserver(this);
  }

  handleLanguageChanged() {
    this.breadcrumbSrv.breadcrumb = this.myApplication.getText('Harvest').toString();
    let iframe: any = document.getElementById('harvestList2');
    iframe.contentWindow.location.reload();
  }
}
