import { DAO } from '../technical-aspects/dao';
import { Parts } from '../business-logic/parts';
import { WeekInYearDTO } from '../dto/week-in-year-dto';
import { Constants } from '../business-logic/constants';

export class DAOParts extends DAO {

    protected createInstance() {
        return new Parts();
    }

    public getPartsInWeek(weekIn: WeekInYearDTO): number {
        if (this.daoService.repoService.repository.system != Constants.SYSTEM_SOLAWI) {
            return 1;
        }

        let parts: Parts;
        let num;
        this.objects.forEach(element => {
            parts = <Parts>element;
            if (this.basicService.isInPeriod(parts.getStartWeek(), parts.getEndWeek(), weekIn)) {
                num = parts.parts;
            }
        });
        return num;
    }

}