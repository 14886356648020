import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from 'src/app/app-core/technical-aspects/event-handler';
import { ApplicationService } from 'src/app/services/application.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.css']
})
export class DeliveryComponent implements OnInit {

  public url: SafeResourceUrl;
  public urlString = environment.deliveryModule + '/deliveryList/' + this.myApplication.year;
  
  pShowDelivery = this.permissionsService.checkPermissionForAction('delivery', 'show', null);

  innerHeight = 1100;



  constructor(public sanitizer: DomSanitizer, private myApplication: ApplicationService, private breadcrumbSrv: BreadcrumbService, 
    private route: ActivatedRoute, private permissionsService: PermissionsService) {

    this.breadcrumbSrv.breadcrumb = this.myApplication.getText('Delivery').toString();
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlString);

    this.myApplication.addObserver(this);
    this.myApplication.eventHandler.register(EventHandler.LANGUAGE_CHANGED, this);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerHeight = window.innerHeight - 60;
  }
  
  ngOnInit(): void {
    let id;
    this.route.params.subscribe(params => {
      id = params['id'];
      if (id) {
        this.urlString = environment.deliveryModule + '/deliveryV2/' + id;
        this.loadUrl();
        this.onResize(null);
      }
    });
    this.onResize(null);
  }

  private loadUrl() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlString);
  }

  update() {
    //from year change
    this.urlString = environment.deliveryModule + '/deliveryList/' + this.myApplication.year;
    this.loadUrl();
  }

  ngOnDestroy() {
    this.loadUrl();
    this.myApplication.removeObserver(this);
  }

  handleLanguageChanged() {
    this.breadcrumbSrv.breadcrumb = this.myApplication.getText('Delivery').toString();
    let iframe: any = document.getElementById('delivery');
    iframe.contentWindow.location.reload();
  }
}
