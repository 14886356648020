/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./modal.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../services/application.service";
import * as i5 from "@angular/router";
var styles_ModalComponent = [i0.styles];
var RenderType_ModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalComponent, data: {} });
export { RenderType_ModalComponent as RenderType_ModalComponent };
export function View_ModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "iframe", [["class", "myIframe"], ["id", "themeframe"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 0, 0, currVal_0); }); }
export function View_ModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal", [], null, null, null, View_ModalComponent_0, RenderType_ModalComponent)), i1.ɵdid(1, 245760, null, 0, i2.ModalComponent, [i3.DomSanitizer, i4.ApplicationService, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalComponentNgFactory = i1.ɵccf("app-modal", i2.ModalComponent, View_ModalComponent_Host_0, { myUrl: "myUrl", toDoContext: "toDoContext" }, {}, []);
export { ModalComponentNgFactory as ModalComponentNgFactory };
