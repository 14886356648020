import * as tslib_1 from "tslib";
import { FilteredRecordSet } from '../business-logic/filtered-record-set';
import { Record } from '../business-logic/record';
import { DAO } from '../technical-aspects/dao';
import { EventHandler } from '../technical-aspects/event-handler';
var DAORecords = /** @class */ (function (_super) {
    tslib_1.__extends(DAORecords, _super);
    function DAORecords(http, authenticationService, name, myApplication, daoService, basicService, partsService) {
        var _this = _super.call(this, http, authenticationService, name, myApplication, daoService, basicService) || this;
        _this.partsService = partsService;
        _this.lastQuickId = 0;
        _this._objectSet = new FilteredRecordSet(null, _this.myApplication, _this.basicService);
        _this.myApplication.eventHandler.register(EventHandler.EVENT_RECORD_START_CHANGED, _this);
        return _this;
    }
    DAORecords.prototype.handleRecordStartChanged = function (id) {
        this.renumber(id);
    };
    DAORecords.prototype.assembleObjects = function () {
        _super.prototype.assembleObjects.call(this);
        this.updateLocal();
    };
    DAORecords.prototype.isSelected = function (record) {
        if (record == this.selectedRecord) {
            return true;
        }
        else {
            return false;
        }
    };
    DAORecords.prototype.getNumRecordsForCulture = function (cultureId, allYears) {
        return this.getRecordsForCulture(cultureId, allYears).length;
    };
    DAORecords.prototype.getRecordsForCulture = function (cultureId, allYears) {
        var _this = this;
        var resultSet = new Array();
        this.objects.forEach(function (object) {
            var record = object;
            if (record.cultureId == cultureId && allYears ||
                record.cultureId == cultureId && record.recordPeriods.isInYear(_this.myApplication.year)) {
                resultSet.push(record);
            }
        });
        return resultSet;
    };
    DAORecords.prototype.getRecordsForBed = function (bedId) {
        var resultSet = new Array();
        this.objects.forEach(function (object) {
            var record = object;
            if (record.bedAssignList.isOnBed(bedId)) {
                resultSet.push(record);
            }
        });
        return resultSet;
    };
    Object.defineProperty(DAORecords.prototype, "objectSet", {
        get: function () {
            return this._objectSet;
        },
        set: function (value) {
            this._objectSet = value;
        },
        enumerable: true,
        configurable: true
    });
    DAORecords.prototype.setData = function (data) {
        _super.prototype.setData.call(this, data);
    };
    DAORecords.prototype.deleteObject = function (object) {
        var culture = object.culture;
        var record = object;
        record.todoList.delete();
        record.bedAssignList.deleteAll();
        record.recordPeriods.delete();
        _super.prototype.deleteObject.call(this, object);
        this.updateLocal();
        this.renumber(culture.id);
        this.myApplication.eventHandler.raiseEvent(EventHandler.EVENT_RECORD_DELETED, object.id);
    };
    DAORecords.prototype.updateLocal = function () {
        this.objectSet.replaceObjects(Array.from(this.objects.values()));
    };
    DAORecords.prototype.createInstance = function () {
        var newInstance = new Record();
        newInstance.daoRecords = this;
        newInstance.daoCultures = this.daoService.getDAO('cultures');
        newInstance.daoSorts = this.daoService.getDAO('sorts');
        newInstance.daoParts = this.daoService.getDAO('parts');
        newInstance.daoAreas = this.daoService.getDAO('areas');
        newInstance.daoBeds = this.daoService.getDAO('beds');
        newInstance.daoBedAssigns = this.daoService.getDAO('bedAssigns');
        newInstance.daoToDos = this.daoService.getDAO('todos');
        newInstance.partsService = this.partsService;
        newInstance.repoService = this.daoService.repoService;
        return newInstance;
    };
    DAORecords.prototype.searchByQuickId = function (qid) {
        var foundObj;
        this.objects.forEach(function (object) {
            var record = object;
            if (record.quickId == qid) {
                foundObj = record;
                return;
            }
        });
        return foundObj;
    };
    DAORecords.prototype.createNewInstance = function () {
        var object = _super.prototype.createNewInstance.call(this);
        this.objectSet.addObject(object);
        object.quickId = this.getNewQuickId();
        return object;
    };
    DAORecords.prototype.getNewQuickId = function () {
        var _this = this;
        if (this.lastQuickId == 0) {
            this.objects.forEach(function (element) {
                if (element.quickId > _this.lastQuickId) {
                    _this.lastQuickId = element.quickId;
                }
            });
        }
        this.lastQuickId = this.lastQuickId + 1;
        return this.lastQuickId;
    };
    DAORecords.prototype.renumber = function (cultureId) {
        var renumList = new Array();
        this.objects.forEach(function (object) {
            var record = object;
            if (record.cultureId == cultureId) {
                renumList.push(record);
            }
        });
        renumList = renumList.sort(function (one, two) {
            return one.recordPeriods.growDate.getTime() - two.recordPeriods.growDate.getTime();
        });
        var i = 0;
        var year = -1;
        renumList.forEach(function (object) {
            var record = object;
            if (record.recordPeriods.getStartWeek().year != year) {
                i = 1;
                year = record.recordPeriods.getStartWeek().year;
            }
            record.recordNum = i;
            i = i + 1;
        });
    };
    DAORecords.prototype.readData = function () {
        var promise = _super.prototype.readData.call(this);
        promise.then(function () {
            // this.assembleObjects();
        });
        return promise;
    };
    DAORecords.prototype.saveDB = function () {
        //nein, hier nicht mehr
    };
    return DAORecords;
}(DAO));
export { DAORecords };
