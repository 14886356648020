import { Http, RequestOptions, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApplicationService } from './application.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
import * as i2 from "./application.service";
var BugtrackingService = /** @class */ (function () {
    function BugtrackingService(http, myApplication) {
        this.http = http;
        this.myApplication = myApplication;
    }
    BugtrackingService.prototype.createBug = function (ticketDTO) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var options = new RequestOptions();
            options.headers = new Headers({
                'Content-Type': 'application/json',
                'Authorization': 'BDpAIIsdRtyLJvMzgytzzcYcj81iKSrr',
                'Access-Control-Allow-Headers': 'authorization, content-type',
                'Access-Control-Allow-Methods': 'POST,GET,OPTIONS,DELETE'
            });
            _this.http.post(environment.bucktracking + 'issues/', ticketDTO.toJson(), options).pipe(map(function (resp) { return resp.json(); }))
                .subscribe(function (res) {
                _this.myApplication.messageDTO.setSuccess('Bug ' + res.issue.id + ' wurde erstellt.');
                resolve();
            });
        });
    };
    BugtrackingService.ngInjectableDef = i0.defineInjectable({ factory: function BugtrackingService_Factory() { return new BugtrackingService(i0.inject(i1.Http), i0.inject(i2.ApplicationService)); }, token: BugtrackingService, providedIn: "root" });
    return BugtrackingService;
}());
export { BugtrackingService };
