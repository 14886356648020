<ng-container *ngIf="dashboardSrv.dataReady">
  <h4 class="text-center text-muted mt-2">{{ myApplication.getText('welcome', dashboardSrv.data.person,
    repoService.repository.name) }}</h4>
  <h4 class="text-center text-muted mt-2">{{date | date: 'dd.MM.yyyy' }}</h4>

  <div *ngIf="dashboardSrv.data" class="container-fluid">
    <div class="row m-4 justify-content-center">
      <div class="card-deck justify-content-center">

        <!-- info mobile app -->
        <div class="card m-4" style="width: 18rem;">
          <div class="card-header text-muted">
            <div class="row">
              <div class="col-sm text-center">
                <B>{{ myApplication.getText('mobileApp') }}</B>
              </div>
            </div>
          </div>

          <img class="card-img-top qrcode" src="./assets/images/dashboard4.png">

          <div class="card-body bg-light text-muted">
            <div class="my_left_aligned p-0">
              <p class="card-text text-muted">
                {{ myApplication.getTextSimple('mobileApp.Hint') }}
              </p>
              <p class="card-text text-muted text-center">
                <a href="https://m.micro-farm-planner.com/"
                  target="mobileAppMicroFarmPlanner">https://m.micro-farm-planner.com/</a>
              </p>
              <p class="card-text text-muted">
                {{ myApplication.getHelpText('mobileApp.Hint') }}
              </p>
              <p class="card-text text-muted text-center">
                <a [href]="urlManual" target="userManual">{{
                  myApplication.getText('userManual') }}</a>
              </p>
              <BR>
              <BR>
            </div>
          </div>
        </div>

        <div class="card m-4" style="width: 18rem;">
          <div class="card-header text-muted">
            <div class="row">
              <div class="col-sm text-center">
                <B>{{ myApplication.getText('AnbauPlanung') }}</B>
              </div>
            </div>
          </div>

          <img class="card-img-top" src="./assets/images/dashboard1.png" alt="Card image cap">

          <div class="card-body bg-light text-muted">
            <div class="my_left_aligned p-0">
              {{ myApplication.getText('Dashboard.numRecords', dashboardSrv.data.numRecords | number : '1.0-0',
              dashboardSrv.data.numCultures | number : '1.0-0', dashboardSrv.data.numSorts | number : '1.0-0') }}
            </div>

            <ng-container *ngIf="dashboardSrv.data.numNotFullPlanned > 0">
              <div *ngIf="actionShowGardeningCal" class="my_left_aligned p-0 pt-2 btn-link navigation"
                (click)="navigateCalNotFullPlanned()">
                {{ myApplication.getText('Records.numUnplanned', dashboardSrv.data.numNotFullPlanned | number :
                '1.0-0')
                }}
              </div>
              <div *ngIf="!actionShowGardeningCal" class="my_left_aligned p-0 pt-2">
                {{ myApplication.getText('Records.numUnplanned', dashboardSrv.data.numNotFullPlanned | number :
                '1.0-0')
                }}
              </div>
            </ng-container>

            <ng-container *ngIf=" dashboardSrv.data.numNoSort> 0">
              <div *ngIf="actionShowGardeningCal" class="my_left_aligned p-0 pt-2 btn-link navigation"
                (click)="navigateCalNoSort()">
                {{ myApplication.getText('Records.numNoSort', dashboardSrv.data.numNoSort | number : '1.0-0') }}
              </div>
              <div *ngIf="!actionShowGardeningCal" class="my_left_aligned p-0 pt-2">
                {{ myApplication.getText('Records.numNoSort', dashboardSrv.data.numNoSort | number : '1.0-0') }}
              </div>
            </ng-container>
            <BR>
            <BR>
          </div>

          <div *ngIf="actionShowGardeningCal" class="card-footer text-center">
            <a routerLink="/gardening-cal" class="btn btn-sm" [ngClass]="myApplication.theme.btnOutline">{{
              myApplication.getText('ToAnbauKalender') }}</a>
          </div>
        </div>

        <div class="card m-4" style="width: 18rem;">
          <div class="card-header text-muted">
            <div class="row">
              <div class="col-sm text-center">
                <B>{{ myApplication.getText('AufgabenKalender') }}</B>
              </div>
            </div>
          </div>

          <img class="card-img-top" src="./assets/images/dashboard2.png" alt="Card image cap">

          <div class="card-body bg-light text-muted">

            <p *ngIf="dashboardSrv.data.numToDos > 0" class="card-text text-muted">
              {{ myApplication.getText('ToDos.numToday', dashboardSrv.data.numToDos | number : '1.0-0') }}
            </p>
            <p *ngIf="dashboardSrv.data.numToDos == 0" class="card-text text-muted">
              {{ myApplication.getText('ToDos.noToday') }}
            </p>
          </div>

          <div *ngIf="actionShowToDoCal" class="card-footer text-center">
            <a routerLink="/calendar" class="btn btn-sm" [ngClass]="myApplication.theme.btnOutline">{{
              myApplication.getText('ToToDos') }}</a>
          </div>
        </div>

        <div class="card m-4" style="width: 18rem;">
          <div class="card-header text-muted">
            <div class="row">
              <div class="col-sm text-center">
                <B>{{ myApplication.getText('CompanyInfos') }}</B>
              </div>
            </div>
          </div>

          <img class="card-img-top" src="./assets/images/dashboard3.png" alt="Card image cap">

          <div class="card-body bg-light text-muted">

            <p class="card-text text-muted">
              {{ myApplication.getText('Company.NumArea', dashboardSrv.data.surface | number : '1.0-1') }}
            </p>

          </div>

          <div *ngIf="actionShowStatistics" class="card-footer text-center">
            <a routerLink="/statistic" class="btn btn-sm" [ngClass]="myApplication.theme.btnOutline">{{
              myApplication.getText('ToStatistik') }}</a>
          </div>
        </div>

        <!-- release-card -->
        <div class="card m-4" style="width: 18rem;">
          <div class="card-header text-muted">
            <div class="row">
              <div class="col-sm text-center">
                <B>Release {{myApplication.version}}</B>
              </div>
            </div>
          </div>

          <img class="card-img-top" src="./assets/images/dashboard5.png" alt="Card image cap">

          <div class="card-body bg-light text-muted">
            <div class="my_left_aligned p-0">
              <ul>
                <li> {{myApplication.getText('Dashboard.releaseText1')}} </li>
                <li> {{myApplication.getText('Dashboard.releaseText2')}} </li>
                <li> {{myApplication.getText('Dashboard.releaseText3')}} </li>
                <li> {{myApplication.getText('Dashboard.releaseText4')}} </li>
                <!-- <li> {{myApplication.getText('Dashboard.releaseText5')}} </li> -->
                <!-- <li> {{myApplication.getText('Dashboard.releaseText6')}} </li> -->
              </ul>
              <a [href]="urlReleaseNotes" target="releaseNotes">Release notes</a>
              <BR>
            </div>
          </div>
        </div>

        <!-- permascale -->
        <div class="card m-4" style="width: 18rem;">
          <div class="card-header text-muted">
            <div class="row">
              <div class="col-sm text-center">
                <B>{{myApplication.getText('Dashboard.permaScaleTitle')}}</B>
              </div>
            </div>
          </div>

          <img class="card-img-top" src="./assets/images/permascaleklein.png" alt="Card image scale">

          <div class="card-body bg-light text-muted">
            <div class="my_left_aligned p-0">
              <p class="card-text text-muted text-center">{{myApplication.getText('Dashboard.permaScaleDesc')}} </p>
              <p class="card-text text-muted text-center">
                <a href="https://permarobotics.com/de/permascale"
                  target="permaScale">https://permarobotics.com/de/permascale</a>
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</ng-container>