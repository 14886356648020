import { AuthenticationService } from 'src/app/services/authentication.service';
import { Observable } from './observable';
import { AbstractObject } from './abstract-object';
import { RequestOptions, Headers, Http } from '@angular/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { ApplicationService } from 'src/app/services/application.service';
import { DaoService } from 'src/app/services/dao.service';
import { BasicService } from 'src/app/services/basic.service';
import { AbstractEntityObject } from './abstract-entity-object';
import { SaveInsert } from './save-insert';
import { SaveUpdate } from './save-update';
import { SaveDelete } from './save-delete';
import { isBoolean } from 'util';


export abstract class DAO extends Observable {

    public objects = new Map<String, AbstractObject>();
    public newObjects = new Map<String, AbstractObject>();
    public changedObjects = new Map<String, AbstractObject>();
    public deletedObjects = new Map<String, AbstractObject>();

    public selectedObject: AbstractObject;


    constructor(protected http: Http, protected authenticationService: AuthenticationService, public name: String,
        public myApplication: ApplicationService, protected daoService: DaoService, protected basicService: BasicService) {
        super();
    }

    public isSelected(object: AbstractObject): boolean {
        if (object == this.selectedObject) {
            return true;
        } else {
            return false;
        }
    }

    public readData(): Promise<boolean> {
        this.objects = new Map<String, AbstractObject>();
        this.newObjects = new Map<String, AbstractObject>();
        this.changedObjects = new Map<String, AbstractObject>();
        this.deletedObjects = new Map<String, AbstractObject>();
        return this.read();
    }

    public save() {
        this.saveDB();
    }

    public getObjectById(id: String): Object {
        return this.objects.get(id);
    }

    public deleteObject(object: AbstractObject) {
        this.deletedObjects.set(object.id, object);
        this.changedObjects.delete(object.id);
        this.newObjects.delete(object.id);
        this.objects.delete(object.id);
        this.hasChanged();
        this.saveDBDelete();
    }

    public createNewInstance(): AbstractObject {
        let object = this.createInstance();
        object.initialize();
        object.id = DAO.getNewId();
        this.assembleObject(object);
        object.setDefaultValues();
        this.objects.set(object.id, object);
        this.newObjects.set(object.id, object);
        this.hasChanged();
        return object;
    }

    public assembleObjects() {
        this.objects.forEach(object => {
            this.assembleObject(object);
        });
    }

    protected assembleObject(object: AbstractObject) {
        object.myApplication = this.myApplication;
        object.repoService = this.daoService.repoService;
        object.basicService = this.basicService;
        object.update();
    }

    protected saveDB() {
        let data;

        if (this.changedObjects.size > 0) {
            data = this.buildJSON(Array.from(this.changedObjects.values()));
            if (data.length > 2) {
                this.updateBackend(data);
                this.changedObjects.forEach(object => {
                    object.resetDBState();
                });
            }
        }

        if (this.newObjects.size > 0) {
            data = this.buildJSON(Array.from(this.newObjects.values()), true);
            this.newObjects.forEach(object => {
                object.resetDBState();
                this.changedObjects.set(object.id, object);
            });
            this.newObjects.clear();

            this.insert(data);
        }

        // this.saveDBDelete();
    }

    public saveDBInsert(object: AbstractObject) {
        let data;

        let array = new Array();
        array.push(object);
        data = this.buildJSON(array, true);
        this.insert(data);
    }

    public saveDBUpdate(object: AbstractObject) {
        let data;

        if (object.hasDataChanged()) {
            let array = new Array();
            array.push(object);
            data = this.buildJSON(array);
            this.updateBackend(data);
            object.resetDBState();
        }
    }

    public saveDBDelete() {
        let data;

        if (this.deletedObjects.size > 0) {
            data = this.buildJSON(Array.from(this.deletedObjects.values()), true);
            this.deletedObjects.clear();

            this.delete(data);
        }
    }

    protected buildJSON(list: Array<AbstractObject>, force: boolean = false): any {
        let eoList = new Array();

        list.forEach(element => {
            // element.eo = this.correctNullValues(element.eo);
            if (force || element.hasDataChanged()) {
                eoList.push(element.eo);
            }
        });
        //return JSON.stringify(list, this.replacer);
        return JSON.stringify(eoList);
    }

    correctNullValues(eo: AbstractEntityObject): AbstractEntityObject {
        let attributeNames = Object.getOwnPropertyNames(eo);

        attributeNames.forEach(attribute => {

            if (eo[attribute] == null) {
                eo[attribute] = 0;
            }
        });
        return eo;
    }

    protected replacer(key: String, value: any): any {
        if (key.includes("Service") || key.includes("list") || key.includes("init") || key == 'init' || key.includes("l_") || key.includes("Set")) {
            return undefined;
        }
        return value;
    }

    protected setData(data: any) {
        let i = 0;
        let object;
        data = data['objects'];
        if (data && data != '[]') {
            let jsonObjectIn = data; //JSON.parse(data);
            let jsonObject = jsonObjectIn[i];

            while (jsonObject) {
                object = this.createInstance();
                object.setInit(true);
                object.initialize();
                object = this.readObject(object, jsonObject);

                this.objects.set(object.id, object);
                this.changedObjects.set(object.id, object);

                i++;
                jsonObject = jsonObjectIn[i];
            }
        }
        else {
            //console.log("no data");
        }
    }

    protected abstract createInstance(): AbstractObject;

    protected readObject(object: AbstractObject, data: any): AbstractObject {
        let attributeNames = Object.getOwnPropertyNames(object.eo)
        let attName;
        let jsonName;

        for (let i = 0; i < attributeNames.length; i++) {
            attName = attributeNames[i];
            jsonName = attName;
            if (attName.includes("_")) {
                attName = attName.substring(1);
            }
            if (data[jsonName] == null) {
                if (jsonName.includes('_')) {
                    jsonName = jsonName.substring(1);
                }
                if (isBoolean(object.eo[attName])) {
                    object.eo[attName] = false;
                }
            }
            if (attName.includes("Date")) {
                object.eo[attName] = new Date(data[jsonName]);
                object.eo[attName] = this.basicService.normalizeDate(object.eo[attName]);
            } else {
                if (!attName.includes('Service') && data[jsonName]) {
                    if (typeof (object.eo[attName]) == 'number') {
                        object.eo[attName] = Number.parseFloat(data[jsonName]);
                    } else {
                        object.eo[attName] = data[jsonName];
                    }
                }
            }
        }
        object.eo.db = object.eo.clone();
        return object;
    }

    public static getNewId(): String {
        return Math.random().toString().substr(2) + Math.random().toString().substr(2);
    }

    protected getHeaders(): Headers {
        return this.authenticationService.getHeadersAuthenticated();
    }

    protected read(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let options = new RequestOptions();
            options.headers = this.getHeaders();

            this.http.get(environment.apiV2 + 'v1/' + this.name, options).pipe(
                map(resp => resp.json())
            )
                .subscribe(
                    res => {
                        this.setData(res);
                        this.hasChanged();
                        resolve(true);
                    },
                    err => {
                        console.log('There was an error reading ressource ' + this.name + '.', err);
                        reject();
                    }
                );
        });
    }

    protected insert(data) {
        let save = new SaveInsert();
        save.data = data;
        save.http = this.http;
        let options = new RequestOptions();
        options.headers = this.getHeaders();
        save.options = options;
        save.route = environment.apiV2 + 'v1/' + this.name;

        this.daoService.saveQueue.push(save);
    }

    public refresh() {
        return new Promise((resolve, reject) => {
            this.readData().then(() => {
                this.assembleObjects();
                resolve(null);
            });
        });
    }

    protected updateBackend(data) {
        let save = new SaveUpdate();
        save.data = data;
        save.http = this.http;
        let options = new RequestOptions();
        options.headers = this.getHeaders();
        save.options = options;
        save.route = environment.apiV2 + 'v1/' + this.name;

        this.daoService.saveQueue.push(save);
    }

    protected delete(data) {
        let save = new SaveDelete();
        save.data = data;
        save.http = this.http;
        let options = new RequestOptions();
        options.headers = this.getHeaders();
        options.body = data;
        save.options = options;
        save.route = environment.apiV2 + 'v1/' + this.name;

        this.daoService.saveQueue.push(save);
    }
}