<!-- <div class="modal fade" [id]="id" tabindex="-1" role="dialog" aria-labelledby="confirmModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document"> -->
<!-- <div class="modal-content"> -->
<div class="modal-header">
  <h5 class="modal-title" id="confirmModalLabel">{{ myApplication.getText('Repository.ConfirmQuestion') }}</h5>
</div>
<div class="modal-body">
  {{ questionDTO.text }}
</div>
<div class="modal-footer">
  <app-button [iconName]="'check'" [id]="'Confirm.yes'" [callback]="'yes'" [callbackOwner]="this">
  </app-button>

  <app-button [iconName]="'ban'" [id]="'Confirm.no'" [callback]="'noAction'" [callbackOwner]="this">
  </app-button>
</div>
<!-- </div> -->
<!-- </div>
</div> -->