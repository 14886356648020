import * as tslib_1 from "tslib";
import { Observable } from '../technical-aspects/observable';
import { Constants } from './constants';
import { EventHandler } from '../technical-aspects/event-handler';
var RecordPeriods = /** @class */ (function (_super) {
    tslib_1.__extends(RecordPeriods, _super);
    function RecordPeriods(recordEO, basicService, _culture, myApplication, record) {
        var _this = _super.call(this) || this;
        _this.recordEO = recordEO;
        _this.basicService = basicService;
        _this._culture = _culture;
        _this.myApplication = myApplication;
        _this.record = record;
        _this.handleRecordDatesChanged();
        _this.myApplication.eventHandler.register(EventHandler.EVENT_RECORD_DATES_CHANGED, _this);
        return _this;
    }
    RecordPeriods.prototype.handleRecordDatesChanged = function () {
        if (this.basicService) {
            this.recordEO.seedTS = this.basicService.getUTCTimestamp(this.recordEO.seedDate);
            this.recordEO.growTS = this.basicService.getUTCTimestamp(this.recordEO.growDate);
            this.recordEO.harvestTS = this.basicService.getUTCTimestamp(this.recordEO.harvestDate);
            this.recordEO.cultureEndTS = this.basicService.getUTCTimestamp(this.getCultureEnd());
        }
    };
    RecordPeriods.prototype.delete = function () {
    };
    RecordPeriods.prototype.getStartDate = function () {
        if (this.recordEO.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
            return this.seedDate;
        }
        else {
            return this.growDate;
        }
    };
    RecordPeriods.prototype.getNextActionWeek = function () {
        if (this.recordEO.state == Constants.STATE_PLANNED) {
            return this.getStartWeek();
        }
        else if (this.recordEO.state == Constants.STATE_ANZUCHT) {
            return this.growWeek;
        }
        else if (this.recordEO.state == Constants.STATE_PLANTED) {
            return this.harvestWeek;
        }
    };
    RecordPeriods.prototype.getStartWeek = function () {
        return this.basicService.getWeek(this.getStartDate());
    };
    Object.defineProperty(RecordPeriods.prototype, "seedDate", {
        get: function () {
            return this.recordEO.seedDate;
        },
        set: function (value) {
            var seedLength = this.seedLength;
            var growLength = this.growLength;
            value = this.basicService.normalizeDate(value);
            this.recordEO.seedDate.setTime(value.getTime());
            this.recordEO.growDate.setTime(this.recordEO.seedDate.getTime());
            this.recordEO.growDate.setDate(this.recordEO.growDate.getDate() + (7 * seedLength));
            this.recordEO.harvestDate.setTime(this.recordEO.growDate.getTime());
            this.recordEO.harvestDate.setDate(this.recordEO.harvestDate.getDate() + (7 * growLength));
            this.myApplication.eventHandler.raiseEvent(EventHandler.EVENT_RECORD_START_CHANGED, this.record.cultureId);
            this.myApplication.eventHandler.raiseEvent(EventHandler.EVENT_RECORD_DATES_CHANGED, this.record.id);
            this.hasChanged();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecordPeriods.prototype, "seedWeek", {
        get: function () {
            return this.basicService.getWeek(this.seedDate);
        },
        set: function (value) {
            this.seedDate = this.basicService.getFirstDateOfWeek(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecordPeriods.prototype, "lastSeedWeek", {
        get: function () {
            return this.basicService.subtractWeeks(this.growWeek, 1);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecordPeriods.prototype, "seedLength", {
        get: function () {
            return this.basicService.calculateWeekDiff(this.seedWeek, this.growWeek);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecordPeriods.prototype, "growDate", {
        get: function () {
            return this.recordEO.growDate;
        },
        set: function (value) {
            value = this.basicService.normalizeDate(value);
            if (this.recordEO.cultureType != Constants.CULTURE_TYPE_ANZUCHT || value.getTime() >= this.seedDate.getTime()) {
                var growLength = this.growLength;
                this.recordEO.growDate.setTime(value.getTime());
                this.recordEO.harvestDate.setTime(this.recordEO.growDate.getTime());
                this.recordEO.harvestDate.setDate(this.recordEO.harvestDate.getDate() + (7 * growLength));
                this.recordEO.growDate.setTime(value.getTime());
                if (this.recordEO.cultureType != Constants.CULTURE_TYPE_ANZUCHT) {
                    this.recordEO.seedDate.setTime(value.getTime());
                }
                this.myApplication.eventHandler.raiseEvent(EventHandler.EVENT_RECORD_START_CHANGED, this.record.cultureId);
                this.myApplication.eventHandler.raiseEvent(EventHandler.EVENT_RECORD_DATES_CHANGED, this.record.id);
                this.hasChanged();
            }
            else {
                this.myApplication.messageDTO.setErrorWithId('FM.growDate');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecordPeriods.prototype, "growWeek", {
        get: function () {
            return this.basicService.getWeek(this.growDate);
        },
        set: function (value) {
            this.growDate = this.basicService.getFirstDateOfWeek(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecordPeriods.prototype, "lastGrowWeek", {
        get: function () {
            return this.basicService.subtractWeeks(this.harvestWeek, 1);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecordPeriods.prototype, "growLength", {
        get: function () {
            return this.basicService.calculateWeekDiff(this.growWeek, this.harvestWeek);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecordPeriods.prototype, "harvestDate", {
        get: function () {
            return this.recordEO.harvestDate;
        },
        set: function (value) {
            value = this.basicService.normalizeDate(value);
            if (this.recordEO.harvestDate.getTime() != value.getTime()) {
                if (value.getTime() >= this.growDate.getTime()) {
                    this.recordEO.harvestDate.setTime(value.getTime());
                    //bestehende Ernteverteilung an neues Datum anpassen
                    // this.updateHarvestDistributionByDate();
                    this.hasChanged();
                    this.myApplication.eventHandler.raiseEvent(EventHandler.EVENT_RECORD_DATES_CHANGED, this.record.id);
                    this.record.calculateQuantities();
                }
                else {
                    this.myApplication.messageDTO.setErrorWithId('FM.harvestDate');
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecordPeriods.prototype, "harvestWeek", {
        get: function () {
            return this.basicService.getWeek(this.harvestDate);
        },
        set: function (value) {
            this.harvestDate = this.basicService.getFirstDateOfWeek(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecordPeriods.prototype, "lastHarvestWeek", {
        get: function () {
            return this.basicService.addWeeks(this.harvestWeek, this.harvestLength - 1);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecordPeriods.prototype, "harvestLength", {
        get: function () {
            return this.recordEO.harvestLength;
        },
        set: function (value) {
            if (this.recordEO.harvestLength != value) {
                this.recordEO.harvestLength = value;
                //bestehende Ernteverteilung an Anzahl Wochen anpassen
                //this.updateHarvestDistributionByLength();
                this.hasChanged();
                this.myApplication.eventHandler.raiseEvent(EventHandler.EVENT_RECORD_DATES_CHANGED, this.record.id);
                this.record.calculateQuantities();
            }
        },
        enumerable: true,
        configurable: true
    });
    RecordPeriods.prototype.getCultureEnd = function () {
        var cultureEnd = new Date();
        cultureEnd.setTime(this.harvestDate.getTime());
        cultureEnd.setDate(cultureEnd.getDate() + (this.harvestLength * 7) - 1);
        return this.basicService.normalizeDate(cultureEnd);
    };
    Object.defineProperty(RecordPeriods.prototype, "harvestQuantity", {
        get: function () {
            return this.recordEO.harvestQuantity;
        },
        set: function (value) {
            this.recordEO.harvestQuantity = value;
            this.record.calculateQuantities();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecordPeriods.prototype, "neededHarvestQuantity", {
        get: function () {
            return this.recordEO.neededHarvestQuantity;
        },
        set: function (value) {
            this.recordEO.neededHarvestQuantity = value;
            this.record.calculateQuantities();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecordPeriods.prototype, "totalHarvestQuantity", {
        get: function () {
            return this.recordEO.totalHarvestQuantity;
        },
        set: function (value) {
            this.recordEO.totalHarvestQuantity = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecordPeriods.prototype, "harvestUnit", {
        get: function () {
            return this.recordEO.harvestUnit;
        },
        set: function (value) {
            this.recordEO.harvestUnit = value;
            this.record.calculateQuantities();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecordPeriods.prototype, "numOfDeliveryWeeks", {
        get: function () {
            return this.recordEO.numOfDeliveryWeeks;
        },
        set: function (value) {
            if (this.recordEO.numOfDeliveryWeeks != value) {
                this.recordEO.numOfDeliveryWeeks = value;
                this.record.calculateQuantities();
            }
        },
        enumerable: true,
        configurable: true
    });
    RecordPeriods.prototype.periodsDifferFromCulture = function () {
        if (this.seedLength == this._culture.seed &&
            this.growLength == this._culture.grow &&
            this.harvestLength == this._culture.harvest) {
            return false;
        }
        else {
            return true;
        }
    };
    RecordPeriods.prototype.periodsDifferFromSort = function () {
        var sort = this.record.getSort();
        if (!sort) {
            return false;
        }
        if (this.seedLength == sort.seed &&
            this.growLength == sort.grow &&
            this.harvestLength == sort.harvest) {
            return false;
        }
        else {
            return true;
        }
    };
    Object.defineProperty(RecordPeriods.prototype, "averageQuantity", {
        get: function () {
            return this.recordEO.averageQuantity;
        },
        set: function (value) {
            this.recordEO.averageQuantity = value;
            this.record.calculateQuantities();
        },
        enumerable: true,
        configurable: true
    });
    RecordPeriods.prototype.createFromRecordCreateDTO = function (recordCreateDTO) {
        this.seedDate = new Date(recordCreateDTO.startDate.getTime());
        this.growDate = new Date(recordCreateDTO.startDate.getTime());
        this.harvestDate = new Date(recordCreateDTO.startDate.getTime());
        this.calculateDates(recordCreateDTO);
        this.harvestLength = recordCreateDTO.harvest;
        this.harvestQuantity = recordCreateDTO.harvestQuantity;
        this.harvestUnit = recordCreateDTO.harvestUnit;
        this.neededHarvestQuantity = recordCreateDTO.averageQuantity;
        this.alternativeDelivery = recordCreateDTO.useDeliveryWeeks;
        this.numOfDeliveryWeeks = recordCreateDTO.numOfDeliveryWeeks;
        this.averageQuantity = recordCreateDTO.averageQuantity;
    };
    RecordPeriods.prototype.calculateDates = function (recordCreateDTO) {
        if (recordCreateDTO.planningDateType == Constants.TODO_TYPE_ANZUCHT) {
            this.growDate.setDate(this.growDate.getDate() + (7 * recordCreateDTO.seed));
            this.harvestDate.setDate(this.harvestDate.getDate() + (7 * (recordCreateDTO.seed + recordCreateDTO.grow)));
        }
        else if (recordCreateDTO.planningDateType == Constants.TODO_TYPE_AUSSAAT ||
            recordCreateDTO.planningDateType == Constants.TODO_TYPE_PFLANZUNG) {
            this.seedDate.setDate(this.growDate.getDate() - (7 * recordCreateDTO.seed));
            this.harvestDate.setDate(this.harvestDate.getDate() + (7 * (recordCreateDTO.grow)));
        }
        else if (recordCreateDTO.planningDateType == Constants.TODO_TYPE_ERNTE) {
            this.growDate.setDate(this.harvestDate.getDate() - (7 * recordCreateDTO.grow));
            if (recordCreateDTO.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
                this.seedDate.setDate(this.harvestDate.getDate() - (7 * (recordCreateDTO.seed + recordCreateDTO.grow)));
            }
            else {
                this.seedDate.setTime(this.growDate.getTime());
            }
        }
    };
    RecordPeriods.prototype.clone = function (recordPeriods) {
        this.seedDate = new Date(recordPeriods.seedDate.getTime());
        this.growDate = new Date(recordPeriods.growDate.getTime());
        this.harvestDate = new Date(recordPeriods.harvestDate.getTime());
        this.harvestLength = recordPeriods.harvestLength;
        this.harvestQuantity = recordPeriods.harvestQuantity;
        this.neededHarvestQuantity = recordPeriods.neededHarvestQuantity;
        this.harvestUnit = recordPeriods.harvestUnit;
        this.alternativeDelivery = recordPeriods.alternativeDelivery;
        this.numOfDeliveryWeeks = recordPeriods.numOfDeliveryWeeks;
        this.averageQuantity = recordPeriods.averageQuantity;
    };
    RecordPeriods.prototype.cloneToNextYear = function (recordPeriods) {
        var week, week2;
        week = recordPeriods.getStartWeek();
        week.year = week.year + 1;
        if (week.week == 53) {
            week.week = 52;
        }
        if (this.recordEO.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
            week2 = this.basicService.addWeeks(week, recordPeriods.seedLength);
            this.seedWeek = week;
            this.growWeek = week2;
        }
        else {
            this.seedWeek = week;
            this.growWeek = week;
        }
        week = this.basicService.addWeeks(this.growWeek, recordPeriods.growLength);
        this.harvestWeek = week;
        this.harvestLength = recordPeriods.harvestLength;
        this.harvestQuantity = recordPeriods.harvestQuantity;
        this.harvestUnit = recordPeriods.harvestUnit;
        this.neededHarvestQuantity = recordPeriods.neededHarvestQuantity;
        this.numOfDeliveryWeeks = recordPeriods.numOfDeliveryWeeks;
        this.averageQuantity = recordPeriods.averageQuantity;
    };
    RecordPeriods.prototype.shift = function (numWeeks) {
        this.seedDate.setDate(this.seedDate.getDate() + (7 * numWeeks));
        this.growDate.setDate(this.growDate.getDate() + (7 * numWeeks));
        this.harvestDate.setDate(this.harvestDate.getDate() + (7 * numWeeks));
        this.myApplication.eventHandler.raiseEvent(EventHandler.EVENT_RECORD_START_CHANGED, this.record.cultureId);
        this.myApplication.eventHandler.raiseEvent(EventHandler.EVENT_RECORD_DATES_CHANGED, this.record.id);
        this.hasChanged();
    };
    RecordPeriods.prototype.calculateFirstWeekOfHarvest = function () {
        var harvestDate = this.harvestDate;
        return this.basicService.getWeek(harvestDate);
    };
    RecordPeriods.prototype.setGrowDateBySeedLength = function (length) {
        var week = this.basicService.addWeeks(this.seedWeek, length);
        this.growDate = this.basicService.getFirstDateOfWeek(week);
    };
    RecordPeriods.prototype.setHarvestDateByGrowLength = function (length) {
        var week = this.basicService.addWeeks(this.growWeek, length);
        this.harvestDate = this.basicService.getFirstDateOfWeek(week);
    };
    RecordPeriods.prototype.isInYear = function (year) {
        var startWeek = this.getStartWeek();
        if (startWeek == null) {
            return false;
        }
        return this.isInYearWithStart(year, startWeek);
    };
    RecordPeriods.prototype.growsInYear = function (year) {
        var startWeek = this.growWeek;
        if (startWeek == null) {
            return false;
        }
        return this.isInYearWithStart(year, startWeek);
    };
    RecordPeriods.prototype.isInYearWithStart = function (year, startWeek) {
        var lastWeek = this.lastHarvestWeek;
        if (startWeek.year == year || lastWeek.year == year ||
            (startWeek.year < year && lastWeek.year > year)) {
            return true;
        }
        else {
            return false;
        }
    };
    Object.defineProperty(RecordPeriods.prototype, "alternativeDelivery", {
        get: function () {
            return this.recordEO.alternativeDelivery;
        },
        set: function (value) {
            this.recordEO.alternativeDelivery = value;
            if (this.alternativeDelivery) {
                this.numOfDeliveryWeeks = this.harvestLength;
            }
            this.hasChanged();
            this.record.calculateQuantities();
        },
        enumerable: true,
        configurable: true
    });
    RecordPeriods.prototype.isAlternativeDeliverySelected = function () {
        if (this.alternativeDelivery == true) {
            return true;
        }
        else {
            return false;
        }
    };
    Object.defineProperty(RecordPeriods.prototype, "extraQuantity", {
        get: function () {
            return this.recordEO.extraQuantity;
        },
        set: function (value) {
            this.recordEO.extraQuantity = value;
            this.record.calculateQuantities();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecordPeriods.prototype, "useExtraQuantity", {
        get: function () {
            return this.recordEO.useExtraQuantity;
        },
        set: function (value) {
            console.log(value, this.extraQuantity);
            this.recordEO.useExtraQuantity = value;
            if (!value) {
                this.extraQuantity = 0;
            }
            console.log(value, this.extraQuantity);
        },
        enumerable: true,
        configurable: true
    });
    return RecordPeriods;
}(Observable));
export { RecordPeriods };
