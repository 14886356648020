import { AbstractObject } from '../technical-aspects/abstract-object';
import { CultureEO } from '../entity-objects/culture-eo';
import { Sort } from './sort';
import { DAOSorts } from '../dao/dao-sorts';
import { Constants } from './constants';
import { DAORecords } from '../dao/dao-records';
import { DAOCultures } from '../dao/dao-cultures';
import { Template } from './template';
import { DAOTemplate } from '../dao/dao-template';
import { TagBuilder } from './helper/tag-builder';
import { Record } from '../business-logic/record';
import { element } from '@angular/core/src/render3';


export class Culture extends AbstractObject {

    public sorts: Array<Sort>;
    private _templates: Array<Template>;
    public daoSorts: DAOSorts;
    public daoRecords: DAORecords;
    public daoCultures: DAOCultures;
    public daoTemplate: DAOTemplate;
    public harvestMethodText: String;
    public harvestUnitText: String;

    public harvestQuantityLabel: String;
    public unitLabel: String;

    private _tagList: Array<string>;


    constructor() {
        super();
    }

    // transferTemplates() {
    //     this.templates.forEach(element => {
    //         element.checkedForRecord = element.active;
    //     });

    //     let records = this.daoRecords.getRecordsForCulture(this.id, true);
    //     records.forEach(element => {
    //         let record = <Record>element;
    //         if (record.getYear() >= this.myApplication.year) {
    //             record.createToDos(this.templates);
    //         }
    //     });
    //     this.myApplication.messageDTO.setSuccessWithId('TemplatesTransferInfo', this.myApplication.year);
    // }

    // transferTemplate(template: Template) {

    //     template.checkedForRecord = template.active;
    //     let templates: Array<Template> = new Array();
    //     templates.push(template);
    //     let records = this.daoRecords.getRecordsForCulture(this.id, true);
    //     records.forEach(element => {
    //         let record = <Record>element;
    //         if (record.getYear() >= this.myApplication.year) {
    //             record.createToDos(templates);
    //         }
    //     });
    //     this.myApplication.messageDTO.setSuccessWithId('TemplateTransferInfo', this.myApplication.year);
    // }

    hasCultureInfoWarnings(): boolean {
        if ((this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seed <= 0) ||
            this.grow < 1 ||
            this.harvest < 1 ||
            this.plantDistance <= 0 ||
            this.rowDistance <= 0 ||
            this.numRows <= 0 ||
            this.hasQuickpotWarnings() ||
            this.hasPlantBoxesWarnings()) {
            return true;
        } else {
            return false;
        }
    }

    hasAnbauErnteWarnings(): boolean {
        if (
            // (this.neededHarvestQuantity <= 0 &&
            //     !this.alternativeDeliverySelected) ||
            (this.numOfDeliveryWeeks <= 0 &&
                this.alternativeDeliverySelected)
        ) {
            return true;
        } else {
            return false;
        }
    }

    hasWarnings(): boolean {
        if (this.hasCultureInfoWarnings() ||
            this.hasAnbauErnteWarnings()) {
            return true;
        } else {
            return false;
        }
    }


    getWarnings(): Array<String> {
        let warnings = new Array<String>();

        warnings = warnings.concat(this.getAnbauErnteWarnings(), this.getCultureInfoWarnings());

        return warnings;
    }

    getAnbauErnteWarnings(): Array<String> {
        let warnings = new Array<String>();

        // if (this.neededHarvestQuantity <= 0 &&
        //     !this.alternativeDeliverySelected) {
        //     warnings.push(this.myApplication.getText('FM.neededHarvestQuantityIsZero'));
        // }

        if (this.numOfDeliveryWeeks <= 0 &&
            this.alternativeDeliverySelected) {
            warnings.push(this.myApplication.getText('FM.numDeliveryWeeksIsZero'));
        }

        return warnings;
    }

    getCultureInfoWarnings(): Array<String> {
        let warnings = new Array<String>();

        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seed <= 0) {
            warnings.push(this.myApplication.getText('FM.seedIsZero'));
        }

        if (this.plantDistance <= 0) {
            warnings.push(this.myApplication.getText('FM.plantDistanceIsZero'));
        }

        if (this.rowDistance <= 0) {
            warnings.push(this.myApplication.getText('FM.rowDistanceIsZero'));
        }

        if (this.numRows <= 0) {
            warnings.push(this.myApplication.getText('FM.numRowsIsZero'));
        }

        if (this.grow < 1) {
            warnings.push(this.myApplication.getText('FM.growIsZero'));
        }

        if (this.harvest < 1) {
            warnings.push(this.myApplication.getText('FM.numHarvestWeeksIsZero'));
        }

        if (this.hasQuickpotWarnings()) {
            warnings.push(this.myApplication.getText('FM.QuickPotInfosMissing'));
        }

        if (this.hasPlantBoxesWarnings()) {
            warnings.push(this.myApplication.getText('FM.BoxInfoMissing'));
        }

        return warnings;
    }

    public hasQuickpotWarnings(): boolean {
        if (this.cultureType != Constants.CULTURE_TYPE_ANZUCHT) {
            return false;
        }

        if (this.quickpotSize <= 0 || this.seedsPerQuickpot <= 0) {
            return true;
        } else {
            return false;
        }
    }

    public hasPlantBoxesWarnings(): boolean {
        if (this.cultureType != Constants.CULTURE_TYPE_PFLANZKAUF) {
            return false;
        }

        if (this.plantsPerBox <= 0) {
            return true;
        } else {
            return false;
        }
    }

    addTags(tags: string) {
        if (this.cultureEO.tags != '') {
            this.cultureEO.tags = this.cultureEO.tags.toString() + ' ' + tags.toString();
        } else {
            this.cultureEO.tags = tags.toString();
        }

        this._tagList = new TagBuilder().buildTags(this.cultureEO.tags);
    }

    public get tagList(): Array<string> {
        if (!this._tagList) {
            this._tagList = new TagBuilder().buildTags(this.cultureEO.tags);
        }
        return this._tagList;
    }

    get plantsPerBox(): number {
        return this.cultureEO.plantsPerBox;
    }
    set plantsPerBox(value: number) {
        this.cultureEO.plantsPerBox = value;
    }

    get tags(): String {
        return this.cultureEO.tags;
    }

    set tags(value: String) {
        this.cultureEO.tags = '';
        this.addTags(value.toString());
    }

    deleteChildren() {
        if (this.isDeletable()) {
            this.sorts.forEach(element => {
                this.daoSorts.deleteObject(element);
            });
            this.templates.forEach(element => {
                this.daoTemplate.deleteObject(element);
            });
        }
    }

    public copy(): Culture {
        let newInstance = <Culture>this.daoCultures.createNewInstance();

        newInstance.name = this.name + ' (' + this.myApplication.getTextSimple('Copy') + ')';
        newInstance.consumerType = this.consumerType;
        newInstance.cultureType = this.cultureType;
        newInstance.harvestMethod = this.harvestMethod;
        newInstance.harvestUnit = this.harvestUnit;

        newInstance.plantDistance = this.plantDistance;
        newInstance.rowDistance = this.rowDistance;
        newInstance.plantDeepth = this.plantDeepth;
        newInstance.numRows = this.numRows;
        newInstance.quickpotSize = this.quickpotSize;
        newInstance.seedsPerQuickpot = this.seedsPerQuickpot;
        newInstance.harvestQuantity = this.harvestQuantity;
        newInstance.neededHarvestQuantity = this.neededHarvestQuantity;
        newInstance.safety = this.safety;

        newInstance.color = this.basicService.randomColor();
        newInstance.note = this.note;

        newInstance.seed = this.seed;
        newInstance.grow = this.grow;
        newInstance.harvest = this.harvest;

        newInstance.averageQuantity = this.averageQuantity;
        newInstance.tags = this.tags;
        newInstance.alternativeDeliverySelected = this.alternativeDeliverySelected;
        newInstance.plantsPerBox = this.plantsPerBox;
        newInstance.seedRate = this.seedRate;
        newInstance.numOfDeliveryWeeks = this.numOfDeliveryWeeks;

        this.copyTemplates(newInstance);
        newInstance.copySorts(this);

        this.myApplication.save();

        return newInstance;
    }

    protected copyTemplates(culture: Culture) {
        this.templates.forEach(template => {
            if (!template.isPredefined()) {
                let newTemplate = template.clone();
                newTemplate.parentId = culture.id;
                //culture.templates.push(newTemplate); geht von ganz alleine
            }
        });
    }

    public get templates(): Array<Template> {
        if (!this._templates) {
            this.buildTemplates();
        }
        return this._templates;
    }
    public set templates(value: Array<Template>) {
        this._templates = value;
    }

    public isDeletable(): boolean {
        if (this.daoRecords.getNumRecordsForCulture(this.id, true) > 0) {
            return false;
        }
        // for (let i = 0; i < this.sorts.length; i++) {
        //     if (!this.sorts[i].isDeleteable()) {
        //         return false;
        //     }
        // }
        return true;
    }

    public getSortNames(): Array<String> {
        let sortNames = new Array<String>();
        this.sorts.forEach(sort => {
            sortNames.push(sort.name);
        });
        return sortNames;
    }

    protected initLabels() {
        // this.harvestQuantityLabel = 'Erwartete Erntemenge'
        // if (this.harvestMethod != Constants.HARVEST_METHOD_ABSOLUT) {
        //     this.harvestQuantityLabel = this.harvestQuantityLabel + ' ' + this.harvestMethodText;
        // }
        // this.harvestQuantityLabel = this.harvestQuantityLabel + ' in ' + this.harvestUnitText;

        // this.unitLabel = 'Benötigte Erntemenge pro Anteil';
        // if (this.harvestUnit != Constants.UNIT_PIECE) {
        //     this.unitLabel = this.unitLabel + ' in ' + this.harvestUnitText;
        // }
    }

    update() {
        this.templates = null;
        this.sorts = new Array();
        this.daoSorts.objects.forEach(element => {
            let sort = <Sort>element;
            if (sort.cultureId == this.id) {
                this.sorts.push(sort);
            }
        });
        this.sorts.sort(function (one, two) {
            return one.name.localeCompare(two.name.toString());
        });
        this.sortTemplates();
    }

    protected buildTemplates() {
        this._templates = new Array();
        this.daoTemplate.removeObserver(this); //kein update jetzt bitte

        this.daoTemplate.objects.forEach(element => {
            let template = <Template>element;
            if (template.parentId == this.id) {
                template.addObserver(this);
                this._templates.push(template);
            }
        });

        this.createPredefinedTemplateAnzuchtIfNeeded();
        this.createPredefinedTemplatePflanzungIfNeeded();
        this.createPredefinedTemplateAussaatIfNeeded();

        this.sortTemplates();

        this.daoTemplate.addObserver(this);
    }

    sortTemplates() {
        if (this._templates) {

            this._templates = this._templates.sort(function (one, two) {
                return one.type.localeCompare(two.type.toString());
            });

        }
    }

    private createPredefinedTemplate(predefType: String): Template {
        let template = <Template>this.daoTemplate.createNewInstance();
        template.parentId = this.id;
        template.predefType = predefType;
        template.type = this.myApplication.getText('ToDo.type.' + predefType);
        return template;
    }

    private createPredefinedTemplateAussaatIfNeeded() {
        if (this.cultureType == Constants.CULTURE_TYPE_DIREKTSAAT && !this.predefinedTemplateExists(Constants.TODO_TYPE_AUSSAAT)) {
            let template = this.createPredefinedTemplate(Constants.TODO_TYPE_AUSSAAT);
            template.color = Constants.COLOR_PLANTED;
            template.timeReference = Constants.TEMPLATE_TIME_REF_PFLANZUNG;
            this._templates.push(template);
        }
    }

    private createPredefinedTemplatePflanzungIfNeeded() {
        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && !this.predefinedTemplateExists(Constants.TODO_TYPE_PFLANZUNG)
            || this.cultureType == Constants.CULTURE_TYPE_PFLANZKAUF && !this.predefinedTemplateExists(Constants.TODO_TYPE_PFLANZUNG)) {
            let template = this.createPredefinedTemplate(Constants.TODO_TYPE_PFLANZUNG);
            template.color = Constants.COLOR_PLANTED;
            template.timeReference = Constants.TEMPLATE_TIME_REF_PFLANZUNG;
            this._templates.push(template);
        }
    }

    private createPredefinedTemplateAnzuchtIfNeeded() {
        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && !this.predefinedTemplateExists(Constants.TODO_TYPE_ANZUCHT)) {
            let template = this.createPredefinedTemplate(Constants.TODO_TYPE_ANZUCHT);
            template.color = Constants.COLOR_ANZUCHT;
            template.timeReference = Constants.TEMPLATE_TIME_REF_ANZUCHT;
            this._templates.push(template);
        }
    }

    protected predefinedTemplateExists(predefType: String): boolean {
        for (let i = 0; i < this._templates.length; i++) {
            if (this._templates[i].predefType == predefType) {
                return true;
            }
        }
        return false;
    }

    protected deletePredefinedTemplates() {
        this.daoTemplate.removeObserver(this);
        for (let i = 0; i < this.templates.length; i++) {
            if (this.templates[i].predefType == Constants.TODO_TYPE_ANZUCHT ||
                this.templates[i].predefType == Constants.TODO_TYPE_AUSSAAT ||
                this.templates[i].predefType == Constants.TODO_TYPE_PFLANZUNG) {

                this.daoTemplate.deleteObject(this.templates[i]);
            }
        }
        this.daoTemplate.addObserver(this);
    }

    initialize() {
        this.eo = new CultureEO();
        this.daoSorts.addObserver(this);
        this.daoTemplate.addObserver(this);
    }

    setDefaultValues() {
        //this.name = '<' + this.myApplication.getTextSimple('newCulture') + '>';
        this.consumerType = 'S';
        this.harvestMethod = 'A';
        this.harvestUnit = 'S';
    }

    createSort(): Sort {
        let sort = <Sort>this.daoSorts.createNewInstance();
        sort.cultureId = this.id;
        sort.name = '???';
        this.sorts.push(sort);
        this.sorts.sort(function (one, two) {
            return one.name.localeCompare(two.name.toString());
        });

        this.daoSorts.selectedObject = sort;
        sort.isNew = true;
        this.hasChanged();

        //this.myApplication.save();

        return sort;
    }

    copySorts(culture: Culture) {
        culture.sorts.forEach(sortToCopy => {
            this.createSortBySort(sortToCopy);
        });
    }

    createSortBySort(sortIn: Sort): Sort {
        let sort = <Sort>this.daoSorts.createNewInstance();
        sort.cultureId = this.id;
        this.sorts.push(sort);
        this.sorts.sort(function (one, two) {
            return one.name.localeCompare(two.name.toString());
        });

        this.daoSorts.selectedObject = sort;

        sort.name = sortIn.name;

        sort.useOwnPeriods = sortIn.useOwnPeriods;

        sort.seed = sortIn.seed;
        sort.grow = sortIn.grow;
        sort.harvest = sortIn.harvest;

        sort.useOwnDistances = sortIn.useOwnDistances;
        sort.plantDistance = sortIn.plantDistance;
        sort.rowDistance = sortIn.rowDistance;
        sort.numRows = sortIn.numRows;
        sort.note = sortIn.note;
        sort.useOwnDetails = sortIn.useOwnDetails;
        sort.seedRate = sortIn.seedRate;

        this.hasChanged();

        return sort;
    }

    createTemplate(): Template {
        let template = <Template>this.daoTemplate.createNewInstance();
        template.parentId = this.id;
        template.type = '<' + this.myApplication.getTextSimple('newTemplate') + '>';
        template.selected = true;
        this.templates = null;

        template.isNew = true;
        this.hasChanged();

        this.myApplication.save();

        return template;
    }

    createTemplateByTemplate(model: Template): Template {
        let template = this.createTemplate();
        template.adoptDataFrom(model);
        template.fromTemplate = true;

        this.hasChanged();

        this.myApplication.save();

        return template;
    }

    createTemplateByTemplateId(id: string): Template {
        let template = this.createTemplate();
        template.typeId = id;
        template.type = this.myApplication.getText(id);
        template.fromTemplate = true;

        this.hasChanged();

        this.myApplication.save();

        return template;
    }

    public get cultureEO(): CultureEO {
        return <CultureEO>this.eo;
    }

    public get name() {
        return this.cultureEO.name;
    }
    public set name(value) {
        this.cultureEO.name = value;
    }

    public get seed() {
        return this.cultureEO.seed;
    }
    public set seed(value) {
        if (value != this.cultureEO.seed) {
            this.cultureEO.seed = value;
            this.hasChanged();
        }
    }

    public get grow() {
        return this.cultureEO.grow;
    }
    public set grow(value) {
        if (value != this.cultureEO.grow) {
            this.cultureEO.grow = value;
            this.hasChanged();
        }
    }

    public get harvest() {
        return this.cultureEO.harvest;
    }
    public set harvest(value) {
        if (value != this.cultureEO.harvest) {
            this.cultureEO.harvest = value;
            this.hasChanged();
        }
    }

    public get cultureType() {
        return this.cultureEO.cultureType;
    }
    public set cultureType(value) {
        if (this.cultureEO.cultureType != value) {
            this.cultureEO.cultureType = value;
            this.deletePredefinedTemplates();
            this.templates = null;
        }
    }

    public get plantDistance() {
        return this.cultureEO.plantDistance;
    }
    public set plantDistance(value) {
        this.cultureEO.plantDistance = value;
    }

    public get rowDistance() {
        return this.cultureEO.rowDistance;
    }
    public set rowDistance(value) {
        this.cultureEO.rowDistance = value;
    }

    public get plantDeepth() {
        return this.cultureEO.plantDeepth;
    }
    public set plantDeepth(value) {
        this.cultureEO.plantDeepth = value;
    }

    public get numRows() {
        return this.cultureEO.numRows;
    }
    public set numRows(value) {
        this.cultureEO.numRows = Math.floor(value);
    }

    public get quickpotSize() {
        return this.cultureEO.quickpotSize;
    }
    public set quickpotSize(value) {
        this.cultureEO.quickpotSize = value;
    }
    public get quickpotName() {
        return this.cultureEO.quickpotName;
    }
    public set quickpotName(value) {
        this.cultureEO.quickpotName = value;
    }
    public get quickpotId() {
        return this.cultureEO.quickpotId;
    }
    public set quickpotId(value) {
        this.cultureEO.quickpotId = value;
    }

    public get seedsPerQuickpot() {
        return this.cultureEO.seedsPerQuickpot;
    }
    public set seedsPerQuickpot(value) {
        this.cultureEO.seedsPerQuickpot = value;
    }

    public get harvestQuantity() {
        return this.cultureEO.harvestQuantity;
    }
    public set harvestQuantity(value) {
        this.cultureEO.harvestQuantity = value;
    }

    public get neededHarvestQuantity() {
        return this.cultureEO.neededHarvestQuantity;
    }
    public set neededHarvestQuantity(value) {
        this.cultureEO.neededHarvestQuantity = value;
    }

    public get safety() {
        return this.cultureEO.safety;
    }
    public set safety(value) {
        this.cultureEO.safety = value;
    }

    public get color() {
        return this.cultureEO.color;
    }
    public set color(value) {
        this.cultureEO.color = value;
    }

    public get note() {
        return this.cultureEO.note;
    }
    public set note(value) {
        this.cultureEO.note = value;
    }

    public get consumerType() {
        return this.cultureEO.consumerType;
    }
    public set consumerType(value) {
        this.cultureEO.consumerType = value;
    }

    public get consumerTypeT() {
        return '';
        // let lconsumerType = this.consumerType;
        // if (lconsumerType == 'S') {
        //     return 'Starkzehrer';
        // } else if (lconsumerType == 'M') {
        //     return 'Mittelzehrer';
        // } else if (lconsumerType == 'W') {
        //     return 'Schwachzehrer';
        // }
    }

    public get harvestMethod() {
        return this.cultureEO.harvestMethod;
    }
    public set harvestMethod(value) {
        this.cultureEO.harvestMethod = value;

        if (this.cultureEO.harvestMethod == Constants.HARVEST_METHOD_ABSOLUT) {
            this.harvestUnit = Constants.UNIT_PIECE;
        }
        if (this.harvestMethod != Constants.HARVEST_METHOD_M2 && this.harvestUnit == Constants.UNIT_BUND) {
            this.harvestUnit = Constants.UNIT_PIECE;
        }
        this.initLabels();
    }

    public get harvestUnit() {
        return this.cultureEO.harvestUnit;
    }
    public set harvestUnit(value) {
        this.cultureEO.harvestUnit = value;

        // if (this.cultureEO.harvestUnit == Constants.UNIT_PIECE) {
        //     this.harvestUnitText = 'Stück';
        // } else if (this.cultureEO.harvestUnit == Constants.UNIT_GRAMM) {
        //     this.harvestUnitText = 'g';
        // } else if (this.cultureEO.harvestUnit == Constants.UNIT_KILOGRAMM) {
        //     this.harvestUnitText = 'kg';
        // } else {
        //     this.harvestUnitText = 'other';
        // }

        this.initLabels();
    }

    public getNumberOfActiveTemplates(): number {
        let num: number = 0;
        this.templates.forEach(element => {
            if (element.active == true) {
                num = num + 1;
            }
        });
        return num;
    }

    public getNumberOfCheckedForRecordTemplates(): number {
        let num: number = 0;
        this.templates.forEach(element => {
            if (element.checkedForRecord == true) {
                num = num + 1;
            }
        });
        return num;
    }

    public getSort(sortName: String): Sort {
        for (let i = 0; i < this.sorts.length; i++) {
            let name1 = this.sorts[i].name;
            if (name1 && sortName && name1.trim() == sortName.trim()) {
                return this.sorts[i];
            }
        }
        return null;
    }

    public calculateQuantity(lengthOnBed: number): number {
        let quantity: number;
        quantity = (lengthOnBed * this.numRows * 100) / this.plantDistance;
        return quantity;
    }

    public calculateLengthOnBed(quantity: number): number {
        let lengthOnBed: number;
        lengthOnBed = (quantity * this.plantDistance) / (this.numRows * 100);
        return lengthOnBed;
    }

    public get numOfDeliveryWeeks(): number {
        return this.cultureEO.numOfDeliveryWeeks;
    }
    public set numOfDeliveryWeeks(value: number) {
        this.cultureEO.numOfDeliveryWeeks = value;
    }

    public get averageQuantity(): number {
        return this.cultureEO.averageQuantity;
    }
    public set averageQuantity(value: number) {
        this.cultureEO.averageQuantity = value;
    }

    public get alternativeDeliverySelected(): boolean {
        return this.cultureEO.alternativeDeliverySelected;
    }

    public set alternativeDeliverySelected(value: boolean) {
        this.cultureEO.alternativeDeliverySelected = value;
        if (this.cultureEO.alternativeDeliverySelected) {
            this.numOfDeliveryWeeks = this.harvest;
        }
    }

    public get seedRate(): number {
        return this.cultureEO.seedRate;
    }
    public set seedRate(value: number) {
        this.cultureEO.seedRate = value;
    }
}