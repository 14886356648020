import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { ApplicationService } from 'src/app/services/application.service';
import { environment } from 'src/environments/environment';
import { RepositoryService } from 'src/app/services/repository.service';
import { DAOPersons } from 'src/app/app-core/dao/dao-persons';
import { DaoService } from 'src/app/services/dao.service';
import { DAOUserSettings } from 'src/app/app-core/dao/dao-user-settings';
import { DAOParts } from 'src/app/app-core/dao/dao-parts';
import { EventHandler } from 'src/app/app-core/technical-aspects/event-handler';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { timer } from 'rxjs';
import { Listener } from 'src/app/app-core/technical-aspects/listener';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit, OnDestroy, Listener {

  public url: SafeResourceUrl;
  private daoPersons: DAOPersons;
  private daoUserSettings: DAOUserSettings;
  private daoParts: DAOParts;

  innerHeight = 1100;


  constructor(public sanitizer: DomSanitizer, private repoService: RepositoryService, daoService: DaoService, private myApplication: ApplicationService,
    private userSettingsService: UserSettingsService, public permissionsService: PermissionsService,
    private breadcrumbSrv: BreadcrumbService,
    private authSrv: AuthenticationService) {

    this.breadcrumbSrv.breadcrumb = this.myApplication.getText('userSettings.settings').toString();
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.companyModule + this.myApplication.year);
    this.daoPersons = <DAOPersons>daoService.getDAO('persons');
    this.daoUserSettings = <DAOUserSettings>daoService.getDAO('userSettings');
    this.daoParts = <DAOParts>daoService.getDAO('parts');
    this.myApplication.eventHandler.register(EventHandler.LANGUAGE_CHANGED, this);

    this.myApplication.addObserver(this);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerHeight = window.innerHeight - 60;
  }

  ngOnInit() {
    this.onResize(null);

    if (!environment.production) {
      let iframe = (<HTMLIFrameElement>document.getElementById("company"));
      if (iframe) {
        timer(1000).subscribe(() => {
          iframe.contentWindow.postMessage({ "message": "sendToken", "token": this.authSrv.token }, '*');
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.repoService.refresh();
    // this.daoPersons.readData();
    // this.daoUserSettings.refresh();
    this.userSettingsService.readData();
    this.permissionsService.readPermissions();
    // this.daoParts.readData();
    this.myApplication.eventHandler.deRegister(EventHandler.LANGUAGE_CHANGED, this);
    this.myApplication.removeObserver(this);
  }

  handleLanguageChanged() {
    this.breadcrumbSrv.breadcrumb = this.myApplication.getText('userSettings.settings').toString();
  }

  update() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.companyModule + this.myApplication.year);
  }
}