import * as tslib_1 from "tslib";
import { DAO } from '../technical-aspects/dao';
import { Parts } from '../business-logic/parts';
import { Constants } from '../business-logic/constants';
var DAOParts = /** @class */ (function (_super) {
    tslib_1.__extends(DAOParts, _super);
    function DAOParts() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DAOParts.prototype.createInstance = function () {
        return new Parts();
    };
    DAOParts.prototype.getPartsInWeek = function (weekIn) {
        var _this = this;
        if (this.daoService.repoService.repository.system != Constants.SYSTEM_SOLAWI) {
            return 1;
        }
        var parts;
        var num;
        this.objects.forEach(function (element) {
            parts = element;
            if (_this.basicService.isInPeriod(parts.getStartWeek(), parts.getEndWeek(), weekIn)) {
                num = parts.parts;
            }
        });
        return num;
    };
    return DAOParts;
}(DAO));
export { DAOParts };
