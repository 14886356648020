import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Constants } from 'src/app/app-core/business-logic/constants';
import { EventHandler } from 'src/app/app-core/technical-aspects/event-handler';
import { Listener } from 'src/app/app-core/technical-aspects/listener';
import { ApplicationService } from 'src/app/services/application.service';
import { BasicService } from 'src/app/services/basic.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { DaoService } from 'src/app/services/dao.service';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { environment } from 'src/environments/environment';
import { weekNumber, weekNumberYear } from 'weeknumber';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit, OnDestroy, Listener, EventListenerObject {
  public url: SafeResourceUrl;
  private userSettings;

  private noUpdate = false;

  innerHeight = 1100;


  constructor(public sanitizer: DomSanitizer, private myApplication: ApplicationService, daoService: DaoService, private basicService: BasicService,
    private userSettingsService: UserSettingsService, private breadcrumbSrv: BreadcrumbService) {
    this.breadcrumbSrv.breadcrumb = this.myApplication.getText('AufgabenKalender').toString();
    this.userSettings = this.userSettingsService.userSettings;
    this.userSettingsService.readData().then(() => {
      this.userSettings = this.userSettingsService.userSettings;
      if (this.userSettings) {
        if (this.userSettings.calendarView == Constants.CALENDAR_VIEW_MONTH) {
          this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.getUrl('month'));
        } else if (this.userSettings.calendarView == Constants.CALENDAR_VIEW_WEEK) {
          this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.getUrl('week'));
        } else if (this.userSettings.calendarView == Constants.CALENDAR_VIEW_DAY) {
          this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.getUrl('day'));
        } else { //default ggf für admin
          this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.getUrl('month'));
        }
      } else {
        this.url = this.getUrl('month');
      }
    });

    this.myApplication.addObserver(this);
    this.myApplication.eventHandler.register(EventHandler.LANGUAGE_CHANGED, this);

    window.addEventListener('message', this);
  }

  private getUrl(mode: string) {
    // if (environment.stage == 'dev') {
      if (mode == 'month') {
        return environment.toDoModule + '/#/calendarV2/month/' + this.getCurrentMonthText();
      }
      if (mode == 'week') {
        return environment.toDoModule + '/#/calendarV2/week/' + this.getCurrentWeekText();
      }
      if (mode == 'day') {
        return environment.toDoModule + '/#/calendarV2/day/' + this.getCurrentDayText();
      }
    // } 
    // if (mode == 'month') {
    //   return environment.toDoModule + '/#/calendar/month/' + this.getCurrentMonthText();
    // }
    // if (mode == 'week') {
    //   return environment.toDoModule + '/#/calendar/week/' + this.getCurrentWeekText();
    // }
    // if (mode == 'day') {
    //   return environment.toDoModule + '/#/calendar/day/' + this.getCurrentDayText();
    // }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerHeight = window.innerHeight - 60;
  }

  ngOnInit() {
    this.onResize(null);
  }

  handleEvent(event: MessageEvent): void {
    if (event.data.message == 'closeToDo') {
      let iframe = (<HTMLIFrameElement>document.getElementById("calendar"));
      if (iframe) {
        iframe.contentWindow.postMessage({ "message": "closeToDo" }, '*');
      }
    } else if (event.data.message == 'changeYear') {
      this.noUpdate = true;
      this.myApplication.removeObserver(this);
      if (this.myApplication.year != event.data.context) {
        this.myApplication.year = event.data.context;
      }
      this.myApplication.addObserver(this);
      this.noUpdate = false;
    }
  }

  getCurrentMonthText(): String {
    let month = (new Date().getMonth() + 1).toString();
    if (month.length == 1) {
      month = '0' + month;
    }
    return this.myApplication.year.toString() + month;
  }

  getCurrentWeekText(): String {
    let week = this.basicService.getWeek(new Date()).week.toString();
    if (week.length == 1) {
      week = '0' + week;
    }
    return (weekNumberYear(new Date()).year.toString() + week);
  }

  getCurrentDayText(): String {
    let date = new Date();
    date.setFullYear(this.myApplication.year);
    return date.getFullYear().toString() + '-' + (date.getMonth() + 1).toString() + '-' + date.getDate().toString();
  }

  handleLanguageChanged() {
    this.breadcrumbSrv.breadcrumb = this.myApplication.getText('AufgabenKalender').toString();

    let iframe = (<HTMLIFrameElement>document.getElementById("calendar"));
    if (iframe) {
      iframe.contentWindow.postMessage({ "message": "languageChanged" }, '*');
    }
  }

  update() {
    if (this.noUpdate) {
      return;
    }

    let iframe = (<HTMLIFrameElement>document.getElementById("calendar"));
    if (iframe) {
      iframe.contentWindow.postMessage({ "message": "yearChanged", "content": this.myApplication.year }, '*');
    }
  }

  ngOnDestroy() {
    this.myApplication.eventHandler.deRegister(EventHandler.LANGUAGE_CHANGED, this);
    this.myApplication.removeObserver(this);
  }
}
