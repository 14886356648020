import { Event } from './event';


export class EventHandler {

    protected eventHandler = new Map<String, Array<any>>();
    protected disabled = false;
    protected collectedEvents = new Map<String, Event>();

    public static readonly EVENT_RECORD_START_CHANGED = 'RecordStartChanged';
    public static readonly EVENT_RECORD_CREATED = 'RecordCreated';
    public static readonly EVENT_RECORD_DELETED = 'RecordDeleted';
    public static readonly EVENT_RECORD_DATES_CHANGED = 'RecordDatesChanged';
    public static readonly APP_YEAR_CHANGED = 'AppYearChanged';
    public static readonly SEED_DELETED_EXTERNAL = 'SeedDeletedExternal';
    public static readonly TEMPLATE_MODELS_SET_CHANGED = 'ModelsSetChanged';
    public static readonly EVENT_CULTURE_CREATED = 'CultureCreated';
    public static readonly LANGUAGE_CHANGED = 'LanguageChanged';

    constructor() {

    }

    register(event: String, listener: any) {
        this.getEventHandlerList(event).push(listener);
    }

    deRegister(event: String, listener: any) {
        let list = this.getEventHandlerList(event);
        let newList = new Array<any>();
        for (let i=0; i<list.length; i++) {
            if (list[i] != listener) {
                newList.push(list[i]);
            }
        }
        this.eventHandler.set(event, newList);
    }

    raiseEvent(event: String, parameter?: String) {
        if (this.disabled) {
            let key = event;
            if (parameter) {
                key = key.toString() + parameter.toString();
            }
            if (!this.collectedEvents.get(key)) {
                let eventObject = new Event();
                eventObject.name = event;
                this.collectedEvents.set(key, eventObject);
            }
            return;
        }

        let list = this.getEventHandlerList(event);
        list.forEach(element => {
            if (parameter) {
                element['handle' + event.toString()](parameter);
            } else {
                element['handle' + event.toString()]();
            }
        });
    }

    protected getEventHandlerList(event: String): Array<any> {
        let list = this.eventHandler.get(event);
        if (!list) {
            list = new Array<any>();
            this.eventHandler.set(event, list);
        }
        return list;
    }

    disableEvents() {
        this.disabled = true;
    }

    activateEvents() {
        this.disabled = false;
        Array.from(this.collectedEvents.values()).forEach(event => {
            this.raiseEvent(event.name, event.parameter);
        });
        this.collectedEvents = new Map();
    }
}