import * as tslib_1 from "tslib";
import { DAO } from '../technical-aspects/dao';
import { Template } from '../business-logic/template';
var DAOTemplate = /** @class */ (function (_super) {
    tslib_1.__extends(DAOTemplate, _super);
    function DAOTemplate() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DAOTemplate.prototype.createInstance = function () {
        var newInstance = new Template();
        newInstance.daoToDos = this.daoService.getDAO('todos');
        newInstance.daoTemplate = this;
        return newInstance;
    };
    return DAOTemplate;
}(DAO));
export { DAOTemplate };
