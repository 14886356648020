import * as tslib_1 from "tslib";
import { DAO } from '../technical-aspects/dao';
import { SortSeed } from '../business-logic/sort-seed';
import { EventHandler } from '../technical-aspects/event-handler';
var DAOSortSeeds = /** @class */ (function (_super) {
    tslib_1.__extends(DAOSortSeeds, _super);
    function DAOSortSeeds() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DAOSortSeeds.prototype.createInstance = function () {
        var newInstance = new SortSeed();
        return newInstance;
    };
    DAOSortSeeds.prototype.getSortSeedsForSort = function (sortId) {
        var sortSeeds = new Array();
        this.objects.forEach(function (object) {
            var sortSeed = object;
            if (sortSeed.sortId == sortId) {
                sortSeeds.push(sortSeed);
            }
        });
        return sortSeeds;
    };
    DAOSortSeeds.prototype.findSortSeed = function (year, manufacturer) {
        var sortSeed = this.objects.values().next().value;
        while (sortSeed) {
            if (sortSeed.manufacturingYear == year && sortSeed.manufacturer == manufacturer) {
                return sortSeed;
            }
            sortSeed = this.objects.values().next().value;
        }
        return null;
    };
    DAOSortSeeds.prototype.deleteObject = function (object) {
        var daoSeeds = this.daoService.getDAO('seeds');
        var found = false;
        var list = daoSeeds.getSeedsForSortSeed(object.id);
        list.forEach(function (element) {
            daoSeeds.deleteObject(element);
            found = true;
        });
        if (found) {
            var sortSeed = object;
            this.myApplication.eventHandler.raiseEvent(EventHandler.SEED_DELETED_EXTERNAL, sortSeed.sortId);
        }
        _super.prototype.deleteObject.call(this, object);
    };
    return DAOSortSeeds;
}(DAO));
export { DAOSortSeeds };
