import { AbstractObject } from '../technical-aspects/abstract-object';
import { TemplateEO } from '../entity-objects/template-eo';
import { ToDo } from './todo';
import { DAOToDos } from '../dao/dao-todos';
import { Constants } from './constants';
import { DAOTemplate } from '../dao/dao-template';
import { EventHandler } from '../technical-aspects/event-handler';

export class Template extends AbstractObject {

    public daoToDos: DAOToDos;
    public daoTemplate: DAOTemplate;
    public selected: boolean = false;
    public checkedForRecord: boolean = false;
    public fromTemplate = false;


    constructor() {
        super();
    }

    public usesNameQuantity() {
        if (this.type == 'Düngung' || this.type == 'Pflanzenschutz') {
            return true;
        } else {
            return false;
        }
    }

    isDeleteable() {
        if (this.predefType != '') {
            return false;
        } else {
            return true;
        }
    }

    isPredefined() {
        if (this.predefType != '') {
            return true;
        } else {
            return false;
        }
    }

    public adoptDataFrom(model: Template) {
        this.type = model.type;
        this.name = model.name;
        this.note = model.note;
        this.timeReference = model.timeReference;
        this.timeType = model.timeType;
        this.quantity = model.quantity;
        this.quantityUnit = model.quantityUnit;
        this.time = model.time;
        this.timeUnit = model.timeUnit;
        this.color = model.color;
        this.active = model.active;
        this.isModel = false;

        this.isSeries = model.isSeries;
        this.seriesIntervall = model.seriesIntervall;
        this.seriesIntervallUnit = model.seriesIntervallUnit;
        this.endTime = model.endTime;
        this.endTimeReference = model.endTimeReference;
        this.endTimeUnit = model.endTimeUnit;
        this.endTimeType = model.endTimeType;
    }

    initialize() {
        this.eo = new TemplateEO();
    }

    protected get templateEO(): TemplateEO {
        return <TemplateEO>this.eo;
    }

    public get parentId(): String {
        return this.templateEO.parentId;
    }
    public set parentId(value: String) {
        this.templateEO.parentId = value;
    }

    public get predefType(): String {
        return this.templateEO.predefType;
    }
    public set predefType(value: String) {
        this.templateEO.predefType = value;
    }

    public get type(): String {
        return this.templateEO.type;
    }
    public set type(value: String) {
        this.templateEO.type = value;
    }

    public get typeId(): String {
        return this.templateEO.typeId;
    }
    public set typeId(value: String) {
        this.templateEO.typeId = value;
    }

    public get name(): String {
        return this.templateEO.name;
    }
    public set name(value: String) {
        if (this.templateEO.name != value) {
            this.templateEO.name = value;
        }
    }

    public get isModel(): boolean {
        return this.templateEO.isModel;
    }
    public set isModel(value: boolean) {
        if (this.templateEO.isModel != value) {
            this.templateEO.isModel = value;
            this.myApplication.eventHandler.raiseEvent(EventHandler.TEMPLATE_MODELS_SET_CHANGED);
        }
    }
    public toggleIsModel() {
        this.isModel = ! this.isModel;
    }

    public get quantity(): number {
        return this.templateEO.quantity;
    }
    public set quantity(value: number) {
        this.templateEO.quantity = value;
    }

    public get quantityUnit(): String {
        return this.templateEO.quantityUnit;
    }
    public set quantityUnit(value: String) {
        this.templateEO.quantityUnit = value;
    }

    public getQuantityUnitText(): String {
        let textDTO = this.myApplication.textDTOs.get('Template.quantity.' + this.templateEO.quantityUnit);
        if (textDTO) {
            return textDTO.text;
        } else {
            return '';
        }
    }

    public get time(): number {
        return this.templateEO.time;
    }
    public set time(value: number) {
        this.templateEO.time = value;
    }

    public get timeUnit(): String {
        return this.templateEO.timeUnit;
    }
    public set timeUnit(value: String) {
        this.templateEO.timeUnit = value;
    }

    public get timeType(): String {
        return this.templateEO.timeType;
    }
    public set timeType(value: String) {
        this.templateEO.timeType = value;
    }

    public get timeReference(): String {
        return this.templateEO.timeReference;
    }
    public set timeReference(value: String) {
        this.templateEO.timeReference = value;
    }

    public get color(): String {
        return this.templateEO.color;
    }
    public set color(value: String) {
        this.templateEO.color = value;
    }

    public get note(): String {
        return this.templateEO.note;
    }
    public set note(value: String) {
        this.templateEO.note = value;
    }

    public get active(): boolean {
        return this.templateEO.active;
    }
    public set active(value: boolean) {
        this.templateEO.active = value;
        this.checkedForRecord = value;
    }

    update() {

    }

    getTimeTypeText(): String {
        return this.myApplication.textDTOs.get('Template.timeType.' + this.timeType).text;
    }

    getTimeReferenceText(): String {
        return this.myApplication.textDTOs.get('Template.timeReference.' + this.timeReference).text;
    }

    public get isSeries(): boolean {
        return this.templateEO.isSeries;
    }
    public set isSeries(value: boolean) {
        this.templateEO.isSeries = value;
    }

    public get seriesIntervall(): number {
        return this.templateEO.seriesIntervall;
    }
    public set seriesIntervall(value: number) {
        this.templateEO.seriesIntervall = value;
    }

    public get seriesIntervallUnit(): String {
        return this.templateEO.seriesIntervallUnit;
    }
    public set seriesIntervallUnit(value: String) {
        this.templateEO.seriesIntervallUnit = value;
    }

    public get endTime(): number {
        return this.templateEO.endTime;
    }
    public set endTime(value: number) {
        this.templateEO.endTime = value;
    }

    public get endTimeUnit(): String {
        return this.templateEO.endTimeUnit;
    }
    public set endTimeUnit(value: String) {
        this.templateEO.endTimeUnit = value;
    }

    public get endTimeType(): String {
        return this.templateEO.endTimeType;
    }
    public set endTimeType(value: String) {
        this.templateEO.endTimeType = value;
    }

    public get endTimeReference(): String {
        return this.templateEO.endTimeReference;
    }
    public set endTimeReference(value: String) {
        this.templateEO.endTimeReference = value;
    }

    createToDO(): ToDo {
        let todo = <ToDo>this.daoToDos.createNewInstance();
        if (this.isPredefined()) {
            todo.toDoType = Constants.TODO_RECORD_DATES;
        } else {
            todo.toDoType = Constants.TODO_RELATIVE;
        }
        todo.type = this.type;
        todo.name = this.name;
        todo.note = this.note;
        todo.color = this.color;
        todo.quantity = this.quantity;
        todo.quantityUnit = this.quantityUnit;
        todo.time = this.time;
        todo.timeUnit = this.timeUnit;
        todo.timeType = this.timeType;
        todo.timeReference = this.timeReference;
        if (this.isSeries) {
            todo.toDoType = Constants.TODO_SERIES_RELATIVE;
            todo.seriesMaster = todo.id;
            todo.seriesIntervall = this.seriesIntervall;
            todo.seriesIntervallUnit = this.seriesIntervallUnit;

            todo.endTime = this.endTime;
            todo.endTimeUnit = this.endTimeUnit;
            todo.endTimeType = this.endTimeType;
            todo.endTimeReference = this.endTimeReference;
        }
        return todo;
    }

    clone(): Template {
        let template = <Template>this.daoTemplate.createNewInstance();
        template.parentId = this.parentId;
        template.type = this.type;

        template.name = this.name;
        template.quantity = this.quantity;
        template.quantityUnit = this.quantityUnit;

        template.time = this.time;
        template.timeUnit = this.timeUnit;
        template.timeType = this.timeType;
        template.timeReference = this.timeReference;
        template.color = this.color;

        template.note = this.note;
        template.active = this.active;

        return template;
    }

}