import { AuthenticationService } from './authentication.service';
import { Http, RequestOptions } from '@angular/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { RepositoryDTO } from '../app-core/dto/repository-dto';
import { Constants } from '../app-core/business-logic/constants';
import { BasicService } from './basic.service';
import * as i0 from "@angular/core";
import * as i1 from "./authentication.service";
import * as i2 from "@angular/http";
import * as i3 from "./basic.service";
var RepositoryService = /** @class */ (function () {
    function RepositoryService(authenticationService, http, basicService) {
        this.authenticationService = authenticationService;
        this.http = http;
        this.basicService = basicService;
        this.repository = new RepositoryDTO();
        this.loading = true;
        this.isExpired = false;
    }
    RepositoryService.prototype.readData = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var options = new RequestOptions();
            options.headers = _this.authenticationService.getHeadersAuthenticated();
            _this.http.get(environment.apiV2 + 'repository', options).pipe(map(function (resp) { return resp.json(); }))
                .subscribe(function (res) {
                var repositoryJSON = res;
                _this.repository = new RepositoryDTO();
                _this.repository.name = repositoryJSON['name'];
                _this.repository.package = repositoryJSON['package'];
                _this.repository.partLimit = Number.parseFloat(repositoryJSON['partLimitname']);
                _this.repository.system = repositoryJSON['system'];
                _this.repository.bedWidth = Number.parseFloat(repositoryJSON['bedWidth']);
                _this.repository.bedLength = Number.parseFloat(repositoryJSON['bedLength']);
                _this.repository.firstName = repositoryJSON['firstName'];
                _this.repository.lastName = repositoryJSON['lastName'];
                _this.repository.agbAndDp = repositoryJSON['agbAndDp'];
                _this.repository.createdAndAgreedTS = repositoryJSON['createdAndAgreedTS'];
                _this.repository.infoText = repositoryJSON['infoText'];
                _this.repository.pathWidth = repositoryJSON['pathWidth'];
                _this.repository.type = repositoryJSON['type'];
                _this.repository.repoState = repositoryJSON['repoState'];
                _this.repository.expires = Number.parseInt(repositoryJSON['expires']);
                if (!repositoryJSON['preferences']) {
                    _this.repository.preferences = { "quickpotList": [] };
                }
                else {
                    _this.repository.preferences = repositoryJSON['preferences'];
                }
                _this.isExpired = _this.isExpiredRepo();
                _this.repository.permittedModules = repositoryJSON['permittedModules'];
                _this.repository.currentUser = repositoryJSON['currentUser'];
                ;
                _this.loading = false;
                resolve();
            });
        });
    };
    RepositoryService.prototype.isInactive = function () {
        return this.repository.repoState == 'I';
    };
    RepositoryService.prototype.isGekuendigt = function () {
        return this.repository.repoState == 'C';
    };
    RepositoryService.prototype.refresh = function () {
        return this.readData(); //returns promise
    };
    RepositoryService.prototype.isQuickpotInList = function (culture) {
        if (!this.findQuickpotById(culture.quickpotId)) {
            if (!this.findQuickpotByName(culture.quickpotName)) {
                if (!this.findQuickpotBySize(culture.quickpotSize)) {
                    return false;
                }
                else {
                    return true;
                }
            }
            else {
                return true;
            }
        }
        else {
            return true;
        }
    };
    RepositoryService.prototype.findQuickpot = function (culture) {
        var quickpot = this.findQuickpotById(culture.quickpotId);
        if (!quickpot) {
            quickpot = this.findQuickpotByName(culture.quickpotName);
            // if (!quickpot) {
            //   // quickpot = this.findQuickpotBySize(culture.quickpotSize);
            //   // if (!quickpot) {
            //     return null;
            //   } else {
            //     return quickpot;
            //   }
            // } else {
            return quickpot;
            // }
        }
        else {
            return quickpot;
        }
    };
    RepositoryService.prototype.findQuickpotById = function (id) {
        for (var i = 0; i < this.repository.preferences.quickpotList.length; i++) {
            if (this.repository.preferences.quickpotList[i].id == id && !this.repository.preferences.quickpotList[i].deleted) {
                return this.repository.preferences.quickpotList[i];
            }
        }
        return null;
    };
    RepositoryService.prototype.findQuickpotByName = function (name) {
        for (var i = 0; i < this.repository.preferences.quickpotList.length; i++) {
            if (this.repository.preferences.quickpotList[i].name == name && !this.repository.preferences.quickpotList[i].deleted) {
                return this.repository.preferences.quickpotList[i];
            }
        }
        return null;
    };
    RepositoryService.prototype.findQuickpotBySize = function (size) {
        for (var i = 0; i < this.repository.preferences.quickpotList.length; i++) {
            if (this.repository.preferences.quickpotList[i].qSize == size && !this.repository.preferences.quickpotList[i].deleted) {
                return this.repository.preferences.quickpotList[i];
            }
        }
        return null;
    };
    RepositoryService.prototype.isExpiredRepo = function () {
        if (this.repository.repoState == 'I' || this.repository.repoState == 'C') { //inactive oder gekündigt
            return true;
        }
        if (this.repository.package != Constants.PACKAGE_TEST || this.repository.type == Constants.REPO_GROUP_CHILD) {
            return false;
        }
        var today = new Date();
        if (this.basicService.getUTCTimestamp(today) > this.repository.expires) {
            return true;
        }
        return false;
    };
    RepositoryService.ngInjectableDef = i0.defineInjectable({ factory: function RepositoryService_Factory() { return new RepositoryService(i0.inject(i1.AuthenticationService), i0.inject(i2.Http), i0.inject(i3.BasicService)); }, token: RepositoryService, providedIn: "root" });
    return RepositoryService;
}());
export { RepositoryService };
