import { Component, Input, OnInit } from '@angular/core';
import { RegisterDTO } from 'src/app/app-core/dto/register-dto';
import { ApplicationService } from 'src/app/services/application.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { KeyValueDTO } from 'src/app/app-core/dto/key-value-dto';
import { Constants } from 'src/app/app-core/business-logic/constants';
import { Options, ChangeContext } from 'ng5-slider';
import { timer } from 'rxjs';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

  public register = new RegisterDTO();
  public systems = new Array<KeyValueDTO>();
  public errorText: String = '';
  public successText: String = '';
  private wait: boolean = false;
  public done = false;
  public paymentMethods = new Array<KeyValueDTO>();
  public foundVias = new Array<KeyValueDTO>();
  public countries = new Array<KeyValueDTO>();

  logo = "./assets/images/Logo2.png";

  public urlAGB = 'https://www.micro-farm-planner.com/agb/';
  public urlDataProtection = 'https://www.micro-farm-planner.com/impressum-und-datenschutz/';
  public urlInvest = 'https://www.micro-farm-planner.com/de/preise/';


  options: Options;


  onUserChange(changeContext: ChangeContext): void {
    if (changeContext.value <= 19) {
      this.register.price = 0;
    }
  }

  constructor(public myApplication: ApplicationService, public authentication: AuthenticationService) {
    this.initSystems();
    this.initPaymentMethods();
    this.initCountries();
    this.initFoundVias();


    if (myApplication.selectedLanguage != 'de') {
      this.urlAGB = 'https://www.micro-farm-planner.com/en/gtc';
      this.urlDataProtection = 'https://www.micro-farm-planner.com/en/imprint-and-data-protection/';
      this.urlInvest = 'https://www.micro-farm-planner.com/en/pricing/'
    }
  }

  switchLanguage(newLanguage) {
    this.myApplication.changeLanguage(newLanguage);
    window.location.reload();
  }

  ngOnInit() {
    if (this.myApplication.selectedLanguage != 'de') {
      this.logo = "./assets/images/Logo_en.png";
    }

    this.options = {
      floor: 0,
      ceil: 100,
      showSelectionBar: true,
      selectionBarGradient: {
        from: 'white',
        to: 'green'
      },
      translate: (value: number): string => {
        if (value <= 19) {
          return '<small>' + this.myApplication.getText('Register.registerTest') + '</small>';
        }
        let type = this.myApplication.getText('Einstiegspreis');
        if (value >= 35) {
          type = this.myApplication.getText('Kostendeckend');
        }
        if (value >= 40) {
          type = this.myApplication.getText('Sponsor');
        }
        if (value == 100) {
          type = this.myApplication.getText('Premium-Sponsor');
        }
        return '<small>' + type + ': ' + value + ' ' + this.myApplication.getText('€/Monat') + '</small>';
      },
      ticksArray: [0, 20, 35, 40, 100],
      getLegend: (value: number): string => {
        if (value == 19) {
          return '<small>' + this.myApplication.getText('Register.registerTest') + '</small>';
        }
        return null;
      }
    };
  }

  private initSystems() {
    this.systems.push(new KeyValueDTO(Constants.SYSTEM_SOLAWI));
    this.systems.push(new KeyValueDTO(Constants.SYSTEM_MARKET_GARDENING));
    this.systems.push(new KeyValueDTO(Constants.SYTEM_ERWERBS_GEMUESE));
  }

  private initPaymentMethods() {
    this.paymentMethods.push(new KeyValueDTO(Constants.PAYMENT_METHOD_MONTHLY));
    this.paymentMethods.push(new KeyValueDTO(Constants.PAYMENT_METHOD_QUATERLY));
    this.paymentMethods.push(new KeyValueDTO(Constants.PAYMENT_METHOD_SEMIANNUAL));
    this.paymentMethods.push(new KeyValueDTO(Constants.PAYMENT_METHOD_ANNUALY));
  }

  private initFoundVias() {
    this.foundVias.push(new KeyValueDTO('Z', 'Öko-Feldtage'));
    this.foundVias.push(new KeyValueDTO('M', 'Facebook'));
    this.foundVias.push(new KeyValueDTO('F', this.myApplication.getText('Register.friends')));
    this.foundVias.push(new KeyValueDTO('G', 'Google'));
    this.foundVias.push(new KeyValueDTO('I', 'Instagram'));
    this.foundVias.push(new KeyValueDTO('L', 'Linkedin'));
    this.foundVias.push(new KeyValueDTO('E', this.myApplication.getText('Register.event')));
    this.foundVias.push(new KeyValueDTO('S', this.myApplication.getText('Register.solawi')));
    this.foundVias.push(new KeyValueDTO('Y', 'Youtube'));
    this.foundVias.push(new KeyValueDTO('O', this.myApplication.getText('Register.other')));
  }


  public isEU() {
    let country = this.myApplication.getCountry(this.register.country);
    if (country) {
      return country.isEU;
    } else {
      return false;
    }
  }

  private initCountries() {
    this.myApplication.countries.forEach(country => {
      this.countries.push(new KeyValueDTO(country.name, this.myApplication.getText('Country.' + country.name)));
    });
    this.countries.push(new KeyValueDTO('other', this.myApplication.getText('Country.other')));

    this.countries.sort(function (one, two) {
      return one.value.localeCompare(two.value.toString());
    });
  }

  registerNow() {
    if (this.wait) {
      return;
    }

    this.wait = true;
    const source = timer(300);
    source.subscribe(val => {
      this.wait = false;
    });

    if (this.checkInputs()) {
      this.errorText = '';
      this.authentication.registerRepository(this.register, this);
    }

    //Token übernehmen, Dashboard starten
  }

  private checkInputs(): boolean {
    this.errorText = '';
    if (this.register.email.length < 5 || !this.register.email.includes('@') || !this.register.email.includes('.')) {
      this.errorText = this.myApplication.getText('FM.register.email');
      return false;
    }
    if (this.register.company.length <= 3) {
      this.errorText = this.myApplication.getText('FM.register.companynameToShort');
      return false;
    }

    if (!this.register.agb || !this.register.dp) {
      this.errorText = this.myApplication.getText('FM.register.agbConfirm');
      return false;
    }
    if (this.register.password.length < 7) {
      this.errorText = this.myApplication.getText('FM.register.passwordToShort');
      return false;
    }
    if (this.register.mail != '') {
      this.errorText = this.myApplication.getText('FM.register.spam');
      return false;
    }
    if (this.register.pw != '') {
      this.errorText = this.myApplication.getText('FM.register.spam');
      return false;
    }
    if (!this.is18()) {
      this.errorText = this.myApplication.getText('FM.register.18');
      return false;
    }

    if (!this.register.ustId) {
      this.register.ustId = '';
    }

    if (this.register.email.toLowerCase() != this.register.emailConfirm.toLowerCase()) {
      this.errorText = this.myApplication.getText('FM.register.emailConfirm');
      return false;
    }

    if (!this.register.country || this.register.country == '' || this.register.country == ' ') {
      this.myApplication.messageDTO.setErrorWithId('FM.register.country');
      return false;
    }

    return true;
  }

  is18() {
    if (this.register.isOver18) {
      return true;
    } else {
      return false;
    }
  }

  registrationDone(repo: String) {
    if (repo != '') {
      this.successText = this.myApplication.getText('Register.OK');
      this.done = true;
    } else {
      this.errorText = this.myApplication.getText('FM.register.backend');
    }
  }
}
