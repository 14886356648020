import { Component, OnInit, Input } from '@angular/core';
import { KeyValueDTO } from 'src/app/app-core/dto/key-value-dto';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ApplicationService } from 'src/app/services/application.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppInitializerService } from 'src/app/services/app-initializer.service';

@Component({
  selector: 'app-repository-choose',
  templateUrl: './repository-choose.component.html',
  styleUrls: ['./repository-choose.component.css']
})
export class RepositoryChooseComponent implements OnInit {

  @Input() possibleRepositorys;
  @Input() authenticationService: AuthenticationService;
  @Input() resolve: any;


  public repoChoosen;
  public repositorys = new Array<KeyValueDTO>();


  constructor(public myApplication: ApplicationService, private modalService: NgbModal) { }

  ngOnInit() {
    this.initRepositorys();
  }

  private initRepositorys() {
    for (let i = 0; i < this.possibleRepositorys.length; i++) {
      this.repositorys.push(new KeyValueDTO(i.toString(), this.possibleRepositorys[i].name));
    }
  }

  close() {
    if (this.repoChoosen && this.repoChoosen != '') {
      let repo = this.possibleRepositorys[parseInt(this.repoChoosen)];
      this.authenticationService.setRepository(repo);
      this.resolve();
      this.modalService.dismissAll();
    }
  }
}
