import * as tslib_1 from "tslib";
import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';
var BedEO = /** @class */ (function (_super) {
    tslib_1.__extends(BedEO, _super);
    function BedEO() {
        var _this = _super.call(this) || this;
        _this.areaId = '';
        _this.orderNum = 1;
        _this.width = 1;
        _this.length = 1;
        _this.name = '';
        return _this;
    }
    return BedEO;
}(AbstractEntityObject));
export { BedEO };
