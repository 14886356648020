import { Component, OnInit } from '@angular/core';
import { Http, RequestOptions, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { ApplicationService } from 'src/app/services/application.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.css']
})
export class UnsubscribeComponent implements OnInit {

  logo = "./assets/images/Logo_en.png";
  wait = false;
  mail = '';
  message = '';

  constructor(myApplication: ApplicationService, private http: Http) { }

  ngOnInit() {
  }

  unsubscribe() {
    this.wait = true;

    const options = new RequestOptions();
    options.headers = new Headers({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': 'authorization, content-type',
      'Access-Control-Allow-Methods': 'POST,GET,OPTIONS,DELETE'
    });
    this.http.get(environment.apiAdmin + this.mail, options).pipe(
      map(resp => resp.json())
    )
      .subscribe(res => {
        this.wait = false;
        this.message = 'Du wurdest vom Newsletter abgemeldet. Du kannst die Einstellung in deinen Profilinformationen ändern. ';
        + 'You have been unsubscribed from the newsletter. You can change the setting in your profile information.';
      },
        err => {
          this.wait = false;
          this.message = 'Es ist ein Fehler aufgetreten. Eventuell existiert die Emailadresse nicht. An error has occurred. Perhaps the email address does not exist.'
        });
  }
}
