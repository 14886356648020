import * as tslib_1 from "tslib";
import { AbstractObject } from '../technical-aspects/abstract-object';
import { WeekInYearDTO } from '../dto/week-in-year-dto';
import { PartsEO } from '../entity-objects/parts-eo';
var Parts = /** @class */ (function (_super) {
    tslib_1.__extends(Parts, _super);
    function Parts() {
        return _super.call(this) || this;
    }
    Parts.prototype.update = function () {
    };
    Parts.prototype.initialize = function () {
        this.eo = new PartsEO();
    };
    Object.defineProperty(Parts.prototype, "partsEO", {
        get: function () {
            return this.eo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Parts.prototype, "startWeek", {
        get: function () {
            return this.partsEO.startWeek;
        },
        set: function (value) {
            this.partsEO.startWeek = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Parts.prototype, "startYear", {
        get: function () {
            return this.partsEO.startYear;
        },
        set: function (value) {
            this.partsEO.startYear = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Parts.prototype, "endWeek", {
        get: function () {
            return this.partsEO.endWeek;
        },
        set: function (value) {
            this.partsEO.endWeek = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Parts.prototype, "endYear", {
        get: function () {
            return this.partsEO.endYear;
        },
        set: function (value) {
            this.partsEO.endYear = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Parts.prototype, "parts", {
        get: function () {
            return this.partsEO.parts;
        },
        set: function (value) {
            this.partsEO.parts = value;
        },
        enumerable: true,
        configurable: true
    });
    Parts.prototype.getStartWeek = function () {
        return new WeekInYearDTO(this.startWeek, this.startYear);
    };
    Parts.prototype.getEndWeek = function () {
        return new WeekInYearDTO(this.endWeek, this.endYear);
    };
    Parts.prototype.getStart = function () {
        var text = this.myApplication.getText('CalendarWeek') + ' ' + this.startWeek + ' ' + this.startYear;
        return text;
    };
    Parts.prototype.getEnd = function () {
        var text = this.myApplication.getText('CalendarWeek') + ' ' + this.endWeek + ' ' + this.endYear;
        return text;
    };
    return Parts;
}(AbstractObject));
export { Parts };
