import * as tslib_1 from "tslib";
import { ToDoList } from './todo-list';
import { Constants } from './constants';
var ToDoListRecord = /** @class */ (function (_super) {
    tslib_1.__extends(ToDoListRecord, _super);
    function ToDoListRecord(daoToDos, record) {
        var _this = _super.call(this, daoToDos) || this;
        _this.daoToDos = daoToDos;
        _this.record = record;
        _this.updateList();
        return _this;
    }
    ToDoListRecord.prototype.updateList = function () {
        var _this = this;
        this.toDos = new Array();
        this.listWithSeriesMaster = new Array();
        this.daoToDos.objects.forEach(function (element) {
            var todo = element;
            if (todo.parentType == Constants.PARENT_TYPE_RECORD) {
                if (todo.parentId == _this.record.id) {
                    if (todo.isSeriesMaster() == false) {
                        _this.toDos.push(todo);
                    }
                    _this.listWithSeriesMaster.push(todo);
                }
            }
        });
    };
    ToDoListRecord.prototype.clone = function (record) {
        var _this = this;
        //   record.todoList.toDos.forEach(toDo => {
        record.todoList.listWithSeriesMaster.forEach(function (toDo) {
            if (toDo.toDoType != Constants.TODO_RECORD_DATES) {
                //bei Serienterminen nur den Master clonen, die Serienelemente muessen neu berechnet werden.
                if (!toDo.series || (toDo.series && toDo.isSeriesMaster())) {
                    var newToDo = _this.daoToDos.createNewInstance();
                    newToDo.toDoType = toDo.toDoType;
                    newToDo.type = toDo.type;
                    newToDo.time = toDo.time;
                    newToDo.timeReference = toDo.timeReference;
                    newToDo.timeType = toDo.timeType;
                    newToDo.timeUnit = toDo.timeUnit;
                    newToDo.parentType = Constants.PARENT_TYPE_RECORD;
                    newToDo.parentId = _this.record.id;
                    newToDo.note = toDo.note;
                    newToDo.person = toDo.person;
                    newToDo.color = toDo.color;
                    newToDo.name = toDo.name;
                    newToDo.quantity = toDo.quantity;
                    newToDo.quantityUnit = toDo.quantityUnit;
                    if (toDo.isSeriesMaster()) {
                        newToDo.seriesMaster = newToDo.id;
                    }
                    newToDo.seriesIntervall = toDo.seriesIntervall;
                    newToDo.seriesIntervallUnit = toDo.seriesIntervallUnit;
                    newToDo.endTime = toDo.endTime;
                    newToDo.endTimeUnit = toDo.endTimeUnit;
                    newToDo.endTimeType = toDo.endTimeType;
                    newToDo.endTimeReference = toDo.endTimeReference;
                    if (toDo.isSeriesMaster()) {
                        newToDo.generateSeriesToDos();
                    }
                }
            }
        });
    };
    ToDoListRecord.prototype.deleteRecordDates = function () {
        var _this = this;
        var toDelete = new Array();
        this.toDos.forEach(function (toDo) {
            if (toDo.toDoType == Constants.TODO_RECORD_DATES) {
                toDelete.push(toDo);
            }
        });
        toDelete.forEach(function (toDo) {
            _this.daoToDos.deleteObject(toDo);
        });
        this.updateList();
    };
    ToDoListRecord.prototype.deleteAllToDos = function () {
        var _this = this;
        var toDelete = new Array();
        this.toDos.forEach(function (toDo) {
            toDelete.push(toDo);
        });
        toDelete.forEach(function (toDo) {
            _this.daoToDos.deleteObject(toDo);
        });
        this.record.buildCultureDateToDos();
        this.updateList();
        this.record.myApplication.messageDTO.setSuccessWithId('ToDo.deleteAll.Info');
    };
    return ToDoListRecord;
}(ToDoList));
export { ToDoListRecord };
