import { EventHandler } from '../technical-aspects/event-handler';
var SeedSet = /** @class */ (function () {
    function SeedSet(daoSeeds, daoSortSeed, sortId, myApplication) {
        this.daoSeeds = daoSeeds;
        this.daoSortSeed = daoSortSeed;
        this.sortId = sortId;
        this.myApplication = myApplication;
        this._all = false;
        this._otherUnit = false;
        myApplication.eventHandler.register(EventHandler.SEED_DELETED_EXTERNAL, this);
    }
    SeedSet.prototype.handleSeedDeletedExternal = function (parameter) {
        if (parameter == this.sortId) {
            this._seeds = null;
            this._seedsFiltered = null;
            this._seedsFilteredOtherUnit = null;
        }
    };
    Object.defineProperty(SeedSet.prototype, "all", {
        get: function () {
            return this._all;
        },
        set: function (value) {
            if (value != this._all) {
                this._all = value;
                this.buildSeedsFiltered();
            }
        },
        enumerable: true,
        configurable: true
    });
    SeedSet.prototype.buildSeedsFiltered = function () {
        var _this = this;
        this._seedsFiltered = new Array();
        this._seedsFilteredOtherUnit = new Array();
        this.seeds.forEach(function (seed) {
            if ((!seed.done || _this.all) && !seed.getSortSeed().otherUnit) {
                seed.newWithNewSortSeed = false;
                _this._seedsFiltered.push(seed);
            }
            if ((!seed.done || _this.all) && seed.getSortSeed().otherUnit) {
                seed.newWithNewSortSeed = false;
                _this._seedsFilteredOtherUnit.push(seed);
            }
        });
        this._seedsFiltered = this.sortSeeds(this._seedsFiltered);
        this._seedsFilteredOtherUnit = this.sortSeeds(this._seedsFilteredOtherUnit);
    };
    SeedSet.prototype.sortSeeds = function (list) {
        return list.sort(function (one, two) {
            return one.getSortSeed().manufacturingYear - two.getSortSeed().manufacturingYear;
        });
    };
    Object.defineProperty(SeedSet.prototype, "seeds", {
        get: function () {
            var _this = this;
            if (!this._seeds) {
                this._seeds = new Array();
                this.daoSeeds.objects.forEach(function (element) {
                    var seed = element;
                    if (seed.sortId == _this.sortId) {
                        _this._seeds.push(seed);
                    }
                });
            }
            return this._seeds;
        },
        set: function (value) {
            this._seeds = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SeedSet.prototype, "seedsFiltered", {
        get: function () {
            if (!this._seedsFiltered) {
                this.buildSeedsFiltered();
            }
            return this._seedsFiltered;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SeedSet.prototype, "seedsFilteredOtherUnit", {
        get: function () {
            if (!this._seedsFilteredOtherUnit) {
                this.buildSeedsFiltered();
            }
            return this._seedsFilteredOtherUnit;
        },
        enumerable: true,
        configurable: true
    });
    // public create(year: number, manufacturer: String) {
    //     let newInstance = <Seed>this.daoSeeds.createNewInstance();
    //     newInstance.sortId = this.sortId;
    //     this.daoSortSeed.findSortSeed(year, manufacturer);
    //     this.buildSeedsFiltered();
    // }
    SeedSet.prototype.createWithSortSeed = function (sortSeed) {
        var newInstance = this.daoSeeds.createNewInstance();
        newInstance.sortId = this.sortId;
        newInstance.sortSeedId = sortSeed.id;
        this.myApplication.save();
        this.seeds.push(newInstance);
        this.buildSeedsFiltered();
        return newInstance;
    };
    SeedSet.prototype.deleteSeed = function (seed) {
        this.daoSeeds.deleteObject(seed);
        this._seeds = null;
        this._seedsFiltered = null;
    };
    Object.defineProperty(SeedSet.prototype, "otherUnit", {
        get: function () {
            return this._otherUnit;
        },
        set: function (value) {
            if (value != this._otherUnit) {
                this._otherUnit = value;
                this.buildSeedsFiltered();
            }
        },
        enumerable: true,
        configurable: true
    });
    return SeedSet;
}());
export { SeedSet };
