import * as tslib_1 from "tslib";
import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';
import { Constants } from '../business-logic/constants';
var ToDoEO = /** @class */ (function (_super) {
    tslib_1.__extends(ToDoEO, _super);
    function ToDoEO() {
        var _this = _super.call(this) || this;
        _this.toDoType = Constants.TODO_STANDALONE;
        _this.cultureDatType = '';
        _this.type = '';
        _this.name = '';
        _this.quantity = 0;
        _this.quantityUnit = 'a';
        _this.time = 0;
        _this.timeUnit = 'Tage';
        _this.timeType = 'n';
        _this.timeReference = Constants.TEMPLATE_TIME_REF_PFLANZUNG;
        _this.dueDate = new Date();
        _this.person = '';
        _this.color = '';
        _this.state = '';
        _this.note = '';
        _this.parentType = '';
        _this.parentId = '';
        _this.seriesMaster = '';
        _this.seriesIntervall = 0;
        _this.seriesIntervallUnit = 'T';
        _this.endTime = 0;
        _this.endTimeUnit = 'Tage';
        _this.endTimeType = 'v';
        _this.endTimeReference = Constants.TEMPLATE_TIME_REF_ERNTE;
        _this.endDueDate = new Date();
        _this.bedIdStart = '';
        _this.bedIdEnd = '';
        _this.neededTime = 0;
        return _this;
    }
    return ToDoEO;
}(AbstractEntityObject));
export { ToDoEO };
