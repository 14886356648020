import { Constants } from './constants';
import { FilteredParts } from './filteredParts';
var CalculatorQuantity = /** @class */ (function () {
    function CalculatorQuantity(harvestMethod, harvestQuantity, numHarvestWeeks, neededQuantity, harvestWeek, alternativeDeliveryWeeks, safety, plantDistance, rowDistance, myApplication, basicService, seedRate, cultureType, partsService, extraQuantity, repoService, harvestUnit) {
        this.harvestMethod = harvestMethod;
        this.harvestQuantity = harvestQuantity;
        this.numHarvestWeeks = numHarvestWeeks;
        this.neededQuantity = neededQuantity;
        this.harvestWeek = harvestWeek;
        this.alternativeDeliveryWeeks = alternativeDeliveryWeeks;
        this.safety = safety;
        this.plantDistance = plantDistance;
        this.rowDistance = rowDistance;
        this.myApplication = myApplication;
        this.basicService = basicService;
        this.seedRate = seedRate;
        this.cultureType = cultureType;
        this.partsService = partsService;
        this.extraQuantity = extraQuantity;
        this.repoService = repoService;
        this.harvestUnit = harvestUnit;
    }
    CalculatorQuantity.prototype.calculate = function () {
        var _this = this;
        var endQuantity = 0;
        var maxQuantity = 0;
        var sumQuantity = 0;
        var localMax = 0;
        var partTypes = this.partsService.partTypes;
        var parts = this.partsService.parts;
        var filteredParts = new FilteredParts();
        var test = this.repoService.repository.system;
        if (!this.alternativeDeliveryWeeks) {
            if (this.numHarvestWeeks == 0) {
                endQuantity = 0;
                if (this.myApplication) {
                    this.myApplication.messageDTO.setErrorWithId('FM.numHarvestWeeksIsZero');
                }
                return 0;
            }
            var _loop_1 = function (i) {
                var myNumParts = 0;
                localMax = 0;
                // nur fuer SoLaWi nach Paketen/Anteilen schauen
                if (this_1.repoService.repository.system == Constants.SYSTEM_SOLAWI) {
                    //jetzt neu die Anteile mit Wertigkeit
                    partTypes.forEach(function (element) {
                        var myParts = filteredParts.getPartsForPartType(element.id, parts);
                        myNumParts = filteredParts.getCurrentPartsNumParts(myParts, _this.harvestWeek);
                        //jetzt Summen aufaddieren -> dabei fuer Bund und Stueck auf Ganzzahl runden
                        if (_this.harvestUnit == Constants.UNIT_PIECE || _this.harvestUnit == Constants.UNIT_BUND) {
                            sumQuantity = sumQuantity + (myNumParts * Math.ceil(_this.neededQuantity * element.score));
                        }
                        else {
                            sumQuantity = sumQuantity + (myNumParts * (_this.neededQuantity * element.score));
                        }
                        //fuer max-Werte -> dabei fuer Bund und Stueck auf Ganzzahl runden
                        if (_this.harvestUnit == Constants.UNIT_PIECE || _this.harvestUnit == Constants.UNIT_BUND) {
                            localMax = localMax + (myNumParts * Math.ceil(_this.neededQuantity * element.score));
                        }
                        else {
                            localMax = localMax + (myNumParts * (_this.neededQuantity * element.score));
                        }
                    });
                }
                else {
                    //jetzt Summen aufaddieren
                    sumQuantity = sumQuantity + this_1.neededQuantity;
                    //fuer max-Werte
                    localMax = this_1.neededQuantity;
                }
                if (localMax > maxQuantity) {
                    maxQuantity = localMax;
                }
                if (sumQuantity == 0 || !sumQuantity) {
                    endQuantity = 0;
                    if (this_1.myApplication && this_1.harvestWeek) {
                        this_1.myApplication.messageDTO.setErrorWithId('FM.numPartsIsZero', (this_1.harvestWeek.week.toString() + '/' + this_1.harvestWeek.year.toString()));
                    }
                    return { value: 0 };
                }
                if (this_1.neededQuantity == 0) {
                    endQuantity = 0;
                    if (this_1.myApplication) {
                        this_1.myApplication.messageDTO.setErrorWithId('FM.neededHarvestQuantityIsZero');
                    }
                    return { value: 0 };
                }
                if (this_1.harvestWeek) {
                    this_1.harvestWeek = this_1.basicService.addWeeks(this_1.harvestWeek, 1);
                }
            };
            var this_1 = this;
            for (var i = 1; i <= this.numHarvestWeeks; i++) {
                var state_1 = _loop_1(i);
                if (typeof state_1 === "object")
                    return state_1.value;
            }
        }
        else {
            if (this.neededQuantity == 0) {
                endQuantity = 0;
                if (this.myApplication) {
                    this.myApplication.messageDTO.setErrorWithId('FM.delivertyQuantityIsZero');
                }
                return 0;
            }
            if (this.numHarvestWeeks == 0) {
                endQuantity = 0;
                if (this.myApplication) {
                    this.myApplication.messageDTO.setErrorWithId('FM.numDeliveryWeeksIsZero');
                }
                return 0;
            }
            var myNumParts_1 = 0;
            // nur fuer SoLaWi nach Paketen/Anteilen schauen
            if (this.repoService.repository.system == Constants.SYSTEM_SOLAWI) {
                //jetzt neu die Anteile mit Wertigkeit
                partTypes.forEach(function (element) {
                    var myParts = filteredParts.getPartsForPartType(element.id, parts);
                    myNumParts_1 = filteredParts.getCurrentPartsNumParts(myParts, _this.harvestWeek);
                    //jetzt Summen aufaddieren -> dabei fuer Bund und Stueck auf Ganzzahl runden
                    if (_this.harvestUnit == Constants.UNIT_PIECE || _this.harvestUnit == Constants.UNIT_BUND) {
                        sumQuantity = sumQuantity + (myNumParts_1 * Math.ceil(_this.neededQuantity * element.score) * _this.numHarvestWeeks);
                    }
                    else {
                        sumQuantity = sumQuantity + (myNumParts_1 * (_this.neededQuantity * element.score) * _this.numHarvestWeeks);
                    }
                    //fuer max-Werte
                    if (_this.harvestUnit == Constants.UNIT_PIECE || _this.harvestUnit == Constants.UNIT_BUND) {
                        localMax = localMax + (myNumParts_1 * Math.ceil(_this.neededQuantity * element.score));
                    }
                    else {
                        localMax = localMax + (myNumParts_1 * Math.ceil(_this.neededQuantity * element.score));
                    }
                });
            }
            else {
                //jetzt Summen aufaddieren
                sumQuantity = sumQuantity + this.neededQuantity * this.numHarvestWeeks;
                //fuer max-Werte
                localMax = this.neededQuantity;
            }
            if (localMax > maxQuantity) {
                maxQuantity = localMax;
            }
        }
        //wenn zusaetzliche Erntemenge je Lieferwoche ausgewaehlt, dann jetzt noch dazurechnen (ohne Anteilsbindung)  nur fuer SoLaWi      
        if (this.extraQuantity && this.extraQuantity > 0 && this.repoService.repository.system == Constants.SYSTEM_SOLAWI) {
            sumQuantity = sumQuantity + (this.extraQuantity * this.numHarvestWeeks);
            maxQuantity = maxQuantity + this.extraQuantity;
        }
        if (this.harvestMethod == Constants.HARVEST_METHOD_ABSOLUT) {
            endQuantity = sumQuantity;
        }
        else if (this.harvestMethod == Constants.HARVEST_METHOD_WEEK) {
            endQuantity = this.calculateWithHarvestQuantity(maxQuantity, this.harvestQuantity);
        }
        else if (this.harvestMethod == Constants.HARVEST_METHOD_M2) {
            endQuantity = this.calculateQuantityForHarvestMethodM2(sumQuantity, this.harvestQuantity);
        }
        else if (this.harvestMethod == Constants.HARVEST_METHOD_M2_WEEK) {
            endQuantity = this.calculateQuantityForHarvestMethodM2(maxQuantity, this.harvestQuantity); // gleiche Methode, anderer Input
        }
        endQuantity = this.calculateSafety(endQuantity, this.safety);
        // endQuantity = Math.floor(endQuantity);
        endQuantity = Math.ceil(endQuantity);
        //und jetzt noch fuer Anzucht die Keimrate beruecksichtigen
        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
            endQuantity = endQuantity / this.seedRate * 100;
            endQuantity = Math.ceil(endQuantity);
        }
        return endQuantity;
    };
    CalculatorQuantity.prototype.calculateWithHarvestQuantity = function (inQuantity, harvestQuantity) {
        var quantity = 0;
        if (harvestQuantity == 0) {
            quantity = 0;
            if (this.myApplication) {
                this.myApplication.messageDTO.setErrorWithId('FM.harvestQuantityIsZero');
            }
            return 0;
        }
        quantity = inQuantity / harvestQuantity;
        return quantity;
    };
    CalculatorQuantity.prototype.calculateQuantityForHarvestMethodM2 = function (sumQuantity, harvestQuantity) {
        var quantity = 0;
        var neededM2 = 0;
        var plantPerM2 = 0;
        neededM2 = this.calculateWithHarvestQuantity(sumQuantity, harvestQuantity);
        plantPerM2 = this.calculatePlantPerM2();
        quantity = plantPerM2 * neededM2;
        return quantity;
    };
    CalculatorQuantity.prototype.calculateSafety = function (quantity, safety) {
        if (safety > 0) {
            // quantity = quantity + (quantity * safety / 100);
            quantity = quantity / (100 - safety) * 100;
        }
        return quantity;
    };
    CalculatorQuantity.prototype.calculatePlantPerM2 = function () {
        var plantPerColumn = 0;
        var plantPerRow = 0;
        var plantPerM2 = 0;
        if (this.plantDistance == 0) {
            if (this.myApplication) {
                this.myApplication.messageDTO.setErrorWithId('FM.plantDistanceIsZero');
            }
            return 0;
        }
        if (this.rowDistance == 0) {
            if (this.myApplication) {
                this.myApplication.messageDTO.setErrorWithId('FM.rowDistanceIsZero');
            }
            return 0;
        }
        plantPerColumn = 100 / this.plantDistance;
        //plantPerColumn = Math.floor(plantPerColumn);
        plantPerRow = 100 / this.rowDistance;
        //plantPerRow = Math.floor(plantPerRow);
        plantPerM2 = plantPerColumn * plantPerRow;
        return plantPerM2;
    };
    return CalculatorQuantity;
}());
export { CalculatorQuantity };
