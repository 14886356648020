import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { BasicService } from '../../services/basic.service';
import { ApplicationService } from '../../services/application.service';
import { DaoService } from '../../services/dao.service';
import { DAORecords } from 'src/app/app-core/dao/dao-records';
import { DAOFeedback } from 'src/app/app-core/dao/dao-feedback';
import { Feedback } from 'src/app/app-core/business-logic/feedback';
import { ModalService } from 'src/app/services/modal.service';
import { RepositoryService } from 'src/app/services/repository.service';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { PartsService } from 'src/app/services/parts.service';
import { environment } from 'src/environments/environment';
import { PermissionsService } from 'src/app/services/permissions.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { WizardStartComponent } from '../wizard-start/wizard-start.component';
import { WizardService } from 'src/app/services/wizard.service';
import { Constants } from 'src/app/app-core/business-logic/constants';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Listener } from 'src/app/app-core/technical-aspects/listener';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css']
})
export class MainNavComponent implements Listener, OnInit {

  searchText: number;
  menuOpened = false;
  private daoFeedback: DAOFeedback;
  public feedback: Feedback;
  public environment;
  public deliveryPlanPermitted = true;

  public actionShowGardeningCal: boolean = false;
  public actionShowToDoCal: boolean = false;
  public actionShowCulture: boolean = false;
  public actionShowAreas: boolean = false;
  public actionShowDeliveryPlan: boolean = false;
  public actionShowConsumer: boolean = false;
  public actionShowSeeds: boolean = false;
  public actionShowStatistics: boolean = false;
  public actionShowStorage: boolean = false;
  public actionShowHarvest: boolean = false;
  public actionShowDelivery: boolean = false;

  public urlAGB = 'https://www.micro-farm-planner.com/agb/';
  public urlDataProtection = 'https://www.micro-farm-planner.com/impressum-und-datenschutz/';
  public urlFAQ;
  urlUserManual = 'https://www.micro-farm-planner.com/manual';
  public urlReleaseNotes = 'https://www.micro-farm-planner.com/de/press/';

  public urlAreas: SafeResourceUrl;
  public urlAreas2: SafeResourceUrl;
  public urlGardeningCal: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.gardeningCalModule);

  innerHeight = 1100;


  constructor(
    public basicService: BasicService,
    public myApplication: ApplicationService,
    private router: Router,
    public authenticationService: AuthenticationService,
    private daoService: DaoService,
    _activeRoute: ActivatedRoute,
    private ownModalService: ModalService,
    public repoService: RepositoryService,
    private userSettingsService: UserSettingsService,
    public partsService: PartsService,
    public permissionsService: PermissionsService,
    public breadcrumbSrv: BreadcrumbService,
    private modalService: NgbModal,
    public wizardSrv: WizardService,
    private sanitizer: DomSanitizer) {

    this.myApplication.addObserver(this);
    wizardSrv.readOverview();

    this.environment = environment;

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }

    this.daoFeedback = <DAOFeedback>daoService.getDAO('feedback');
    this.createFeedback();


    router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        daoService.save();
        daoService.saveToBackend(true);
      }
    });

    this.repoService.repository.permittedModules.forEach(element => {
      if (element.module == 'deliveryPlan') {
        this.deliveryPlanPermitted = element.permitted;
      }
    });

    if (myApplication.selectedLanguage != 'de') {
      this.urlAGB = 'https://www.micro-farm-planner.com/en/gtc';
      this.urlDataProtection = 'https://www.micro-farm-planner.com/en/imprint-and-data-protection/';
    }

    // URL für Youtube-Tutorial ermitteln
    if (myApplication.selectedLanguage == 'de') {
      if (this.repoService.repository.system == Constants.SYSTEM_SOLAWI) {
        // this.urlYoutubeTutorial = 'https://www.youtube.com/playlist?list=PLWLYDyANTGp3woc_bgcjyD42_SoF4z-pR';
      } else if (this.repoService.repository.system == Constants.SYSTEM_MARKET_GARDENING ||
        this.repoService.repository.system == Constants.SYSTEM_PRIVATE ||
        this.repoService.repository.system == Constants.SYTEM_ERWERBS_GEMUESE) {
        // this.urlYoutubeTutorial = 'https://www.youtube.com/playlist?list=PLWLYDyANTGp0oyyGN6Sew8Iygs-YO9fbP';
      }
    } else if (myApplication.selectedLanguage != 'de') {
      if (this.repoService.repository.system == Constants.SYSTEM_SOLAWI) {
        // this.urlYoutubeTutorial = 'https://www.youtube.com/playlist?list=PLWLYDyANTGp0KBMxPF2kdmkYLqXoRFTNr';
      } else if (this.repoService.repository.system == Constants.SYSTEM_MARKET_GARDENING ||
        this.repoService.repository.system == Constants.SYSTEM_PRIVATE ||
        this.repoService.repository.system == Constants.SYTEM_ERWERBS_GEMUESE) {
        // this.urlYoutubeTutorial = 'https://www.youtube.com/playlist?list=PLWLYDyANTGp2J9KNfHc6IagyrqWqLlX3h';
      }
    }

    // URL für FAQ ermitteln   
    if (myApplication.selectedLanguage == 'de') {
      this.urlFAQ = 'https://www.micro-farm-planner.com/de/faq/';
    } else if (myApplication.selectedLanguage != 'de') {
      this.urlFAQ = 'https://www.micro-farm-planner.com/en/faq/';
      this.urlReleaseNotes = 'https://www.micro-farm-planner.com/en/press/';
    }


    this.setUserManualUrl();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerHeight = window.innerHeight - 60;
  }

  onClickSplitScreen() {
    this.myApplication.splitScreen = !this.myApplication.splitScreen;
    this.myApplication.showYearChoose = !this.myApplication.splitScreen;

    let iframe = document.getElementById('areasv2_2');
    if (iframe) {
      iframe.style.display = "";
    }
    let container = <HTMLElement>document.getElementsByClassName('areasv2_2').item(0);
    if (container) {
      if (this.myApplication.splitScreen) {
        container.style.display = "";
      } else {
        container.style.display = "none";
      }
    }
  }

  update() {
    this.urlAreas = this.sanitizer.bypassSecurityTrustResourceUrl(environment.areasModule + '/#/home/' + this.myApplication.year.toString());
    this.urlAreas2 = this.sanitizer.bypassSecurityTrustResourceUrl(environment.areasModule + '/#/home/' + this.myApplication.year.toString());

    let iframe = (<HTMLIFrameElement>document.getElementById("gardening-cal"));
    if (iframe) {
      iframe.contentWindow.postMessage({ "message": "changeYear", "year": this.myApplication.year }, '*');
    }
  }

  private setUserManualUrl() {
    if (this.myApplication.selectedLanguage) {
      this.urlUserManual = 'https://www.micro-farm-planner.com/' + this.myApplication.selectedLanguage + '/manual';
    }
  }

  showWizard() {
    let ngbModalOptions: NgbModalOptions = {
      windowClass: "wizardModal",
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true
    };

    const modalRef = this.modalService.open(WizardStartComponent, ngbModalOptions);
  }

  switchLanguage(newLanguage) {
    this.myApplication.changeLanguage(newLanguage);
    this.userSettingsService.savePreferredLanguage(newLanguage).then(() => {
      window.location.reload();
    });
  }

  ngOnInit() {
    this.innerHeight = window.innerHeight - 60;
    this.update();

    this.checkAllowedActions();

    if (this.authenticationService.needsAgreement) {
      this.ownModalService.createAgreement();
    }
    if (this.repoService.isExpired || this.repoService.isInactive() || this.repoService.isGekuendigt()) {
      this.router.navigateByUrl('company');
    }
    this.myApplication.loading = false;
  }

  toggle() {
    this.menuOpened = !this.menuOpened;
  }

  public search() {
    let record = (<DAORecords>this.daoService.getDAO('records')).searchByQuickId(this.searchText);
    if (record) {
      this.router.navigateByUrl("/record/" + record.id);
    }
  }

  save() {
    this.daoService.save();
  }

  getCurrentMonthText(): String {
    return this.myApplication.year.toString() + (new Date().getMonth() + 1).toString();
  }

  getCurrentWeekText(): String {
    return (this.basicService.getWeek(new Date()).week.toString() + '-' + this.myApplication.year.toString());
  }

  getCurrentDayText(): String {
    let date = new Date();
    date.setFullYear(this.myApplication.year);
    return date.getFullYear().toString() + '-' + (date.getMonth() + 1).toString() + '-' + date.getDay().toString();
  }

  change() {

  }

  show() {
    this.save();

  }

  logout() {
    this.myApplication.loading = true;
    this.save();
    this.daoService.saveToBackend(true);
    this.authenticationService.logout();
    this.daoService.reset();
    this.myApplication.loading = false;
  }

  getChangeText() {
    return '';
  }

  createFeedback() {
    this.feedback = <Feedback>this.daoFeedback.createNewInstance();
    this.feedback.uname = this.authenticationService.loginData.uname;
  }

  openFeedback() {
    let contact = window.open('https://micro-farm-planner.com/kontakt/', 'feedback');
    contact.onload = function () {
      let emailField = contact.document.getElementsByName('your-email').item(0);
    }
  }

  sendFeedback() {
    if (this.feedback.comment != '') {
      this.daoFeedback.save();
      this.myApplication.messageDTO.setSuccessWithId('Feeback.thx');
    } else {
      this.myApplication.messageDTO.setWarningWithId('FM.Feedbackempty');
    }
  }

  onNavigateTo(route: string) {
    //   if (route.includes('gardening-cal')) { //iframe ist schon geladen. Url 

    //   }

    this.router.navigateByUrl(route);
  }

  checkAllowedActions() {
    this.actionShowGardeningCal = this.permissionsService.checkPermissionForAction('gardeningCal', 'show', null);
    this.actionShowToDoCal = this.checkToDoCalenderIsAllowed();
    this.actionShowCulture = this.permissionsService.checkPermissionForAction('culture', 'show', null);
    this.actionShowAreas = this.permissionsService.checkPermissionForAction('area', 'show', null);
    this.actionShowDeliveryPlan = true;//this.permissionsService.checkPermissionForAction('deliveryPlan', 'show', null);
    this.actionShowConsumer = this.checkConsumerIsAllowed();
    this.actionShowSeeds = this.checkSeedsIsAllowed();
    this.actionShowStatistics = this.checkStatisticsIsAllowed();
    this.actionShowStorage = this.checkStorageIsAllowed();
    this.actionShowHarvest = this.permissionsService.checkPermissionForAction('harvest', 'show', null);
    this.actionShowDelivery = this.permissionsService.checkPermissionForAction('delivery', 'show', null);
  }

  checkToDoCalenderIsAllowed(): boolean {
    if (this.permissionsService.checkPermissionForAction('toDoCalendar', 'showAll', null) ||
      this.permissionsService.checkPermissionForAction('toDoCalendar', 'showJustOwn', null)) {
      return true;
    } else {
      return false;
    }
  }

  checkConsumerIsAllowed(): boolean {
    if (this.permissionsService.checkPermissionForAction('consumer', 'show', null) ||
      this.permissionsService.checkPermissionForAction('deliveryPlace', 'show', null) ||
      this.permissionsService.checkPermissionForAction('parts', 'show', null)) {
      return true;
    } else {
      return false;
    }
  }

  checkSeedsIsAllowed(): boolean {
    if (this.permissionsService.checkPermissionForAction('seeds', 'show', 'seedInventory') ||
      this.permissionsService.checkPermissionForAction('seeds', 'show', 'seedOrder')) {
      return true;
    } else {
      return false;
    }
  }

  checkStatisticsIsAllowed(): boolean {
    if (this.permissionsService.checkPermissionForAction('statistics', 'show', 'culture') ||
      this.permissionsService.checkPermissionForAction('statistics', 'show', 'area') ||
      this.permissionsService.checkPermissionForAction('statistics', 'show', 'crop') ||
      this.permissionsService.checkPermissionForAction('statistics', 'show', 'nursery')) {
      return true;
    } else {
      return false;
    }
  }

  checkStorageIsAllowed(): boolean {
    if (this.permissionsService.checkPermissionForAction('store', 'show', 'inventory') || this.permissionsService.checkPermissionForAction('store', 'show', 'masterData')) {
      return true;
    } else {
      return false;
    }
  }
}
