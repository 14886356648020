import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import { map } from 'rxjs/operators';
import { Http, RequestOptions } from '@angular/http';
import * as i0 from "@angular/core";
import * as i1 from "./authentication.service";
import * as i2 from "@angular/http";
var HelpingDataService = /** @class */ (function () {
    function HelpingDataService(authenticationService, http) {
        this.authenticationService = authenticationService;
        this.http = http;
    }
    HelpingDataService.prototype.deleteBackFunctions = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var options = new RequestOptions();
            options.headers = _this.authenticationService.getHeadersAuthenticated();
            _this.http.delete(environment.apiV2 + 'backFunction', options).pipe(map(function (resp) { return resp.json(); }))
                .subscribe(function (data) {
                resolve();
            });
        });
    };
    HelpingDataService.ngInjectableDef = i0.defineInjectable({ factory: function HelpingDataService_Factory() { return new HelpingDataService(i0.inject(i1.AuthenticationService), i0.inject(i2.Http)); }, token: HelpingDataService, providedIn: "root" });
    return HelpingDataService;
}());
export { HelpingDataService };
