import { WeekInYearDTO } from '../app-core/dto/week-in-year-dto';
import { weekNumber, weekNumberYear } from 'weeknumber';
import * as i0 from "@angular/core";
var BasicService = /** @class */ (function () {
    function BasicService() {
        this.today = new Date();
        this.mobileMode = false;
        if (window.innerWidth < 768) {
            this.mobileMode = true;
        }
    }
    BasicService.prototype.getUTCTimestamp = function (date) {
        if (!date) {
            date = new Date();
        }
        var utc = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
        return utc.getTime() / 1000;
    };
    BasicService.prototype.randomColor = function () {
        var color = Math.floor(0x1000000 * Math.random()).toString(16);
        return '#' + ('000000' + color).slice(-6);
    };
    BasicService.prototype.parseDate = function (input, format) {
        if (input.length != 10 && input.length != 8) {
            return null;
        }
        var day = input.substring(0, 2);
        var month = input.substring(3, 5);
        var year = input.substring(6);
        if (year.length == 2 && input.length == 8) {
            year = '20' + year;
            var newInput = input.substring(0, 6);
            input = newInput + year;
        }
        if (isNaN(Number(day)) ||
            isNaN(Number(month)) ||
            isNaN(Number(year))) {
            return null;
        }
        format = format || 'dd.mm.yyyy'; // default format
        var parts = input.match(/(\d+)/g), i = 0, fmt = {};
        // extract date-part indexes from the format
        format.replace(/(yyyy|dd|mm)/g, function (part) { fmt[part] = i++; });
        return new Date(Number.parseInt(parts[fmt['yyyy']]), Number.parseInt(parts[fmt['mm']]) - 1, Number.parseInt(parts[fmt['dd']]));
    };
    BasicService.prototype.isInPeriod = function (startWeek, endWeek, week) {
        if ((week.week >= startWeek.week && week.year >= startWeek.year || week.year > startWeek.year)
            && (week.week <= endWeek.week && week.year <= endWeek.year || week.year < endWeek.year)) {
            return true;
        }
        else {
            return false;
        }
    };
    BasicService.prototype.periodsOverlaps = function (startWeek, endWeek, startWeek2, endWeek2) {
        if (this.isInPeriod(startWeek, endWeek, startWeek2) || this.isInPeriod(startWeek, endWeek, endWeek2) ||
            this.isInPeriod(startWeek2, endWeek2, startWeek) || this.isInPeriod(startWeek2, endWeek2, endWeek)) {
            return true;
        }
        else {
            return false;
        }
    };
    BasicService.prototype.normalizeDate = function (value) {
        value.setHours(12);
        value.setMinutes(0);
        value.setSeconds(0);
        value.setMilliseconds(0);
        return value;
    };
    BasicService.prototype.isDateInWeek = function (date, week) {
        return this.weekEquals(this.getWeek(date), week);
    };
    BasicService.prototype.getLastDateOfMonth = function (month, year) {
        var date = new Date(year, month + 1, 1); //day 0 in Month + 1
        date.setDate(date.getDate() - 1);
        return date;
    };
    BasicService.prototype.getFirstDateOfMonth = function (month, year) {
        var date = new Date(year, month, 1);
        return date;
    };
    BasicService.prototype.isLastWeekOfAMonth = function (months, week) {
        var i = 0;
        while (i < months.length) {
            if (week == months[i].lastWeek.week) {
                return true;
            }
            i = i + 1;
        }
        return false;
    };
    BasicService.prototype.weekIsGreaterThan = function (week1, week2) {
        if (week1.year > week2.year) {
            return true;
        }
        else if (week1.year == week2.year && week1.week > week2.week) {
            return true;
        }
        else {
            return false;
        }
    };
    BasicService.prototype.calculateWeekDiff = function (week1, week2) {
        var w1;
        var w2;
        if (this.weekIsGreaterThan(week1, week2)) {
            w1 = week1;
            w2 = week2;
        }
        else {
            w1 = week2;
            w2 = week1;
        }
        var diff = 0;
        while (this.weekIsGreaterThan(w1, w2)) {
            w2 = this.addWeeks(w2, 1);
            diff = diff + 1;
        }
        return diff;
    };
    BasicService.prototype.weekIsLessThan = function (week1, week2) {
        if (!this.weekIsGreaterThan(week1, week2) && !this.weekEquals(week1, week2)) {
            return true;
        }
        else {
            return false;
        }
    };
    BasicService.prototype.addWeeks = function (weekInYear, weeks) {
        var newWeek = new WeekInYearDTO(weekInYear.week, weekInYear.year);
        newWeek.week = newWeek.week + weeks;
        var numWeeks = this.getNumWeeks(newWeek.year);
        while (newWeek.week > numWeeks) {
            newWeek.year = newWeek.year + 1;
            newWeek.week = newWeek.week - numWeeks;
            numWeeks = this.getNumWeeks(newWeek.year);
        }
        return newWeek;
    };
    BasicService.prototype.subtractWeeks = function (weekInYear, weeks) {
        var newWeek = new WeekInYearDTO(weekInYear.week, weekInYear.year);
        var sub = weeks;
        while (sub > 0) {
            newWeek.week = newWeek.week - 1;
            if (newWeek.week <= 0) {
                newWeek.year = newWeek.year - 1;
                newWeek.week = this.getNumWeeks(newWeek.year);
            }
            sub = sub - 1;
        }
        return newWeek;
    };
    BasicService.prototype.weekEquals = function (week1, week2) {
        if (week1.week == week2.week && week1.year == week2.year) {
            return true;
        }
        else {
            return false;
        }
    };
    BasicService.prototype.getNewId = function () {
        return Math.random().toString().substr(2) + Math.random().toString().substr(2);
    };
    BasicService.prototype.getNumWeeks = function (year) {
        if (this.getFirstWeekDay(year) == 3 || this.getLastWeekDay(year) == 3) {
            return 53;
        }
        return 52;
    };
    BasicService.prototype.getRandomColor = function () {
        var color = Math.floor(0x1000000 * Math.random()).toString(16);
        return '#' + ('000000' + color).slice(-6);
    };
    BasicService.prototype.getWeek = function (date) {
        // let week = new WeekInYearDTO(Math.ceil((this.getFirstWeekDay(date.getFullYear()) + this.getDayOfYear(date)) / 7), date.getFullYear());    
        var week = new WeekInYearDTO(weekNumber(date), weekNumberYear(date).year);
        // if (week.week > this.getNumWeeks(week.year)) {
        //   week.week = 1;
        //   week.year = week.year + 1;
        // }
        // let weeknumber = weekNumber(date);
        // console.log(weeknumber);
        return week;
    };
    BasicService.prototype.getFirstDateOfWeek = function (week) {
        var date = new Date();
        date.setDate(1);
        date.setMonth(0);
        date.setFullYear(week.year);
        var firstWeekDay = this.getFirstWeekDay(week.year);
        var diff = firstWeekDay;
        if (firstWeekDay > 3) { //Montag bis Donnerstag
            diff = 7 - firstWeekDay;
        }
        var numDays;
        if (week.week == 1) {
            if (firstWeekDay <= 3) { //Montag bis Donnerstag
                date.setDate(date.getDate() - diff);
            }
            else {
                date.setDate(date.getDate() + diff);
            }
            return date;
        }
        else {
            if (firstWeekDay <= 3) {
                numDays = Math.round((week.week - 1) * 7 - diff);
            }
            else {
                numDays = Math.round((week.week - 1) * 7 + diff);
            }
            date.setDate(date.getDate() + numDays);
            return date;
        }
    };
    BasicService.prototype.getLastDateOfWeek = function (week) {
        var date = this.getFirstDateOfWeek(week);
        date.setDate(date.getDate() + 6);
        return date;
    };
    BasicService.prototype.getDayOfYear = function (date) {
        var days;
        if (this.isLeapYear(date.getFullYear())) {
            days = [0, 31, 60, 91, 121, 152, 182, 213, 244, 274, 305, 335];
        }
        else {
            days = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
        }
        var dayOfYear = date.getDate();
        dayOfYear = dayOfYear + days[date.getMonth()];
        return dayOfYear;
    };
    BasicService.prototype.isLeapYear = function (year) {
        if ((year % 4 == 0 && year % 100 != 0) || (year % 400 == 0)) {
            return true;
        }
        else {
            return false;
        }
    };
    BasicService.prototype.getFirstWeekDay = function (year) {
        var date = new Date();
        date.setDate(1);
        date.setMonth(0);
        date.setFullYear(year);
        return this.getWeekDay(date);
        // let num = date.getDay();
        // if (num == 0) {
        //   num = 7;
        // }
        // num = num - 1;
        // return num;
    };
    BasicService.prototype.getWeekDay = function (date) {
        var num = date.getDay();
        if (num == 0) {
            num = 7;
        }
        num = num - 1;
        return num;
    };
    BasicService.prototype.getLastWeekDay = function (year) {
        var date = new Date();
        date.setDate(1);
        date.setMonth(11);
        date.setDate(31);
        date.setFullYear(year);
        return this.getWeekDay(date);
    };
    BasicService.prototype.ownCeil = function (inNum) {
        var outNum = Math.round(inNum * 100);
        return Math.ceil(outNum / 100);
    };
    BasicService.ngInjectableDef = i0.defineInjectable({ factory: function BasicService_Factory() { return new BasicService(); }, token: BasicService, providedIn: "root" });
    return BasicService;
}());
export { BasicService };
