import * as tslib_1 from "tslib";
import { DAO } from '../technical-aspects/dao';
import { Harvest } from '../business-logic/harvest';
var DAOHarvest = /** @class */ (function (_super) {
    tslib_1.__extends(DAOHarvest, _super);
    function DAOHarvest() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DAOHarvest.prototype.createInstance = function () {
        var newInstance = new Harvest();
        return newInstance;
    };
    DAOHarvest.prototype.getHarvestforRecord = function (recordId) {
        var set = new Array();
        this.objects.forEach(function (element) {
            var harvest = element;
            if (recordId == harvest.recordId) {
                set.push(harvest);
            }
        });
        set.sort(function (one, two) {
            return one.harvestDate.getTime() - two.harvestDate.getTime();
        });
        return set;
    };
    return DAOHarvest;
}(DAO));
export { DAOHarvest };
