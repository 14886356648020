import * as tslib_1 from "tslib";
import { DAO } from '../technical-aspects/dao';
import { Sort } from '../business-logic/sort';
var DAOSorts = /** @class */ (function (_super) {
    tslib_1.__extends(DAOSorts, _super);
    function DAOSorts() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DAOSorts.prototype.createInstance = function () {
        var newInstance = new Sort();
        newInstance.daoCultures = this.daoService.getDAO('cultures');
        newInstance.daoSeeds = this.daoService.getDAO('seeds');
        newInstance.daoSortSeeds = this.daoService.getDAO('sortSeeds');
        return newInstance;
    };
    DAOSorts.prototype.deleteObject = function (object) {
        var sort = object;
        sort.deleteChildren();
        _super.prototype.deleteObject.call(this, object);
    };
    return DAOSorts;
}(DAO));
export { DAOSorts };
