/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./repository-choose.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../basic-components/select-field/select-field.component.ngfactory";
import * as i3 from "../basic-components/select-field/select-field.component";
import * as i4 from "../../services/application.service";
import * as i5 from "@angular/common";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "../../../../node_modules/angular-font-awesome/dist/angular-font-awesome.ngfactory";
import * as i8 from "angular-font-awesome";
import * as i9 from "./repository-choose.component";
var styles_RepositoryChooseComponent = [i0.styles];
var RenderType_RepositoryChooseComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RepositoryChooseComponent, data: {} });
export { RenderType_RepositoryChooseComponent as RenderType_RepositoryChooseComponent };
export function View_RepositoryChooseComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [["class", "modal-title"], ["id", "chooseRepoTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-select-field", [], null, null, null, i2.View_SelectFieldComponent_0, i2.RenderType_SelectFieldComponent)), i1.ɵdid(5, 114688, null, 0, i3.SelectFieldComponent, [i4.ApplicationService], { id: [0, "id"], object: [1, "object"], valueName: [2, "valueName"], keyValueList: [3, "keyValueList"], disable: [4, "disable"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 16777216, null, null, 4, "button", [["class", "btn btn-sm mr-1"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(9, 212992, null, 0, i6.NgbTooltip, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i6.NgbTooltipConfig, i1.NgZone, i5.DOCUMENT, i1.ChangeDetectorRef], { ngbTooltip: [0, "ngbTooltip"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "fa", [["name", "check"]], null, null, null, i7.View_AngularFontAwesomeComponent_0, i7.RenderType_AngularFontAwesomeComponent)), i1.ɵdid(11, 114688, null, 0, i8.AngularFontAwesomeComponent, [], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "Repository.choose"; var currVal_2 = _co; var currVal_3 = "repoChoosen"; var currVal_4 = _co.repositorys; var currVal_5 = false; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = "btn btn-sm mr-1"; var currVal_7 = _co.myApplication.theme.btnOutline; _ck(_v, 8, 0, currVal_6, currVal_7); var currVal_8 = "ausw\u00E4hlen"; _ck(_v, 9, 0, currVal_8); var currVal_9 = "check"; _ck(_v, 11, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.myApplication.getText("Repositorys.Multiple"); _ck(_v, 2, 0, currVal_0); }); }
export function View_RepositoryChooseComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-repository-choose", [], null, null, null, View_RepositoryChooseComponent_0, RenderType_RepositoryChooseComponent)), i1.ɵdid(1, 114688, null, 0, i9.RepositoryChooseComponent, [i4.ApplicationService, i6.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RepositoryChooseComponentNgFactory = i1.ɵccf("app-repository-choose", i9.RepositoryChooseComponent, View_RepositoryChooseComponent_Host_0, { possibleRepositorys: "possibleRepositorys", authenticationService: "authenticationService", resolve: "resolve" }, {}, []);
export { RepositoryChooseComponentNgFactory as RepositoryChooseComponentNgFactory };
