import { ToDo } from './todo';
import { DAOToDos } from '../dao/dao-todos';


export class ToDoList {

    public toDos = new Array<ToDo>();

    constructor(protected daoToDos: DAOToDos) {

    }

    delete() {
        this.toDos.forEach(element => {
            this.daoToDos.deleteObject(element);
        });
        this.toDos = new Array<ToDo>();
    }
}