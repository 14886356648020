<div class="modal-header">
  <h5 class="modal-title" id="NeedsTitle">{{ myApplication.getText('Seed.title', myApplication.year) }}</h5>
</div>
<div class="modal-body" id="NeedsContentList">
  <div class="my_right_aligned">
    <app-button
      *ngIf="(needsCalculator.seedNeeds.length > 0 || needsCalculator.seedNeedsOtherUnit.length > 0 || needsCalculatorPlants.plantsNeeds.length > 0) && actionExport"
      [iconName]="'file-excel'" [id]="'Needs.XLSX'" [callback]="'exportExcel'" [callbackOwner]="this"></app-button>

    <button type="button" class="btn btn-sm ml-2 mr-1" [ngClass]="myApplication.theme.btnOutline" (click)="close()"
      [ngbTooltip]="myApplication.getText('close')">
      <fa name="check"></fa>
    </button>
  </div>

  <strong class="text-muted">{{ seedTitle }} {{ repoService.repository.name }} - www.micro-farm-planner.com</strong>

  <table class="table">
    <thead>
      <tr>
        <th scope="col">{{ myApplication.getText('RecordsCreateDTO.cultureId') }}</th>
        <th scope="col">{{ myApplication.getText('Record.sort') }}</th>
        <th scope="col" class="my_right_aligned">{{ myApplication.getText('Seed.need') }} {{
          myApplication.getText('Unit.Korn') }}</th>
        <th scope="col" class="my_right_aligned">{{ myApplication.getText('Seed.tkg') }} g</th>
        <th scope="col" class="my_right_aligned">{{ myApplication.getText('Seed.weight') }} g</th>
        <th scope="col" class="my_right_aligned">{{ myApplication.getText('Seed.existingWeight') }} g</th>
        <th scope="col" class="my_right_aligned">{{ myApplication.getText('Seed.existingWeight') }}
          {{myApplication.getText('Seed.quantity.Korn')}}</th>
        <th scope="col" class="my_right_aligned">{{ myApplication.getText('Seed.buyQuantity') }}</th>
        <th scope="col" class="my_right_aligned">{{ myApplication.getText('Seed.buyWeight') }} g</th>
        <th scope="col">{{ myApplication.getText('Seed.vendor') }}</th>
        <th scope="col">{{ myApplication.getText('Seed.hint') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let needs of needsCalculator.seedNeeds">
        <td scope="row">{{ needs.name }}</td>
        <td>{{ needs.sort }}</td>
        <td class="my_right_aligned">{{ needs.quantity | number : '1.0-3' }}</td>
        <td class="my_right_aligned">{{ needs.tkg | number : '1.0-3' }}</td>
        <td class="my_right_aligned">{{ needs.weight | number : '1.0-3'}}</td>
        <td class="my_right_aligned">{{ needs.existingWeight | number : '1.0-3'}}</td>
        <td class="my_right_aligned">{{ needs.existingQuantity | number : '1.0-3'}}</td>
        <td class="my_right_aligned">{{ needs.buyQuantity | number : '1.0-3' }}</td>
        <td class="my_right_aligned">{{ needs.buyWeight | number : '1.0-3'}}</td>
        <td>{{ needs.vendor }}</td>
        <td>{{ needs.hint }}</td>
      </tr>
    </tbody>
  </table>
</div>
<ng-container *ngIf="checkIfNecessary()">
  <div class="modal-body" id="NeedsContentLisOtherUnits">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">{{ myApplication.getText('RecordsCreateDTO.cultureId') }}</th>
          <th scope="col">{{ myApplication.getText('Record.sort') }}</th>
          <th scope="col" class="my_right_aligned">{{ myApplication.getText('Seed.seedValue') }}</th>
          <th scope="col" class="my_right_aligned">{{ myApplication.getText('Seed.need') }}</th>
          <th scope="col" class="my_right_aligned">{{ myApplication.getText('Seed.weight') }}</th>
          <th scope="col" class="my_right_aligned">{{ myApplication.getText('Seed.existingWeight') }}</th>
          <th scope="col" class="my_right_aligned">{{ myApplication.getText('Seed.buyWeight') }}</th>
          <th scope="col">{{ myApplication.getText('Seed.vendor') }}</th>
          <th scope="col">{{ myApplication.getText('Seed.hint') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let needs of needsCalculator.seedNeedsOtherUnit">
          <td scope="row">{{ needs.name }}</td>
          <td>{{ needs.sort }}</td>
          <td class="my_right_aligned">{{ needs.seedValue | number : '1.0-0' }} {{
            myApplication.getText('ToDo.quantity.' + needs.seedUnit) }}</td>
          <td class="my_right_aligned">{{ needs.quantity | number : '1.0-3' }} m²</td>
          <td class="my_right_aligned">{{ needs.weight | number : '1.0-3'}} {{ myApplication.getText('Unit.' +
            needs.weightUnit) }}</td>
          <td class="my_right_aligned">{{ needs.existingWeight | number : '1.0-3'}} {{ myApplication.getText('Unit.' +
            needs.existingWeightUnit) }}</td>
          <td class="my_right_aligned">{{ needs.buyWeight | number : '1.0-3'}} {{ myApplication.getText('Unit.' +
            needs.weightUnit) }}</td>
          <td>{{ needs.vendor }}</td>
          <td>{{ needs.hint }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>
<div class="modal-body" id="NeedsContentListPlants">
  <strong class="text-muted">{{ plantsTitle }} {{ repoService.repository.name }} - www.micro-farm-planner.com</strong>
  <table class="table">
    <thead>
      <tr>
        <th scope="col">{{ myApplication.getText('RecordsCreateDTO.cultureId') }}</th>
        <th scope="col">{{ myApplication.getText('Record.sort') }}</th>
        <th scope="col" class="my_right_aligned">{{ myApplication.getText('Plant.quantity') }}</th>
        <th scope="col" class="my_right_aligned">{{ myApplication.getText('Export.numBoxPlants') }}</th>
        <th scope="col" class="my_right_aligned">{{ myApplication.getText('CalendarWeek') }}</th>
        <th *ngIf="perDay" scope="col" class="my_right_aligned">{{ myApplication.getText('Seed.date') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let needs of needsCalculatorPlants.plantsNeeds">
        <td scope="row">{{ needs.name }}</td>
        <td>{{ needs.sort }}</td>
        <td class="my_right_aligned">{{ needs.quantity | number : '1.0-0' }} {{ myApplication.getText('Unit.plants') }}
        </td>
        <td class="my_right_aligned">{{ needs.numBoxes | number : '1.0-0'}} ({{ needs.numBoxesUnrounded | number : '1.0-2'}})</td>
        <td class="my_right_aligned">{{ needs.week }}</td>
        <td *ngIf="perDay" class="my_right_aligned">{{ needs.date | date: 'dd.MM.yyyy' }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="modal-footer">
  <!-- <app-button [iconName]="'file-pdf'" [id]="'Needs.PDF'" [callback]="'click'" [callbackOwner]="this"></app-button>
  <app-button [iconName]="'file-pdf'" [id]="'NeedsPlants.PDF'" [callback]="'pdfPlants'" [callbackOwner]="this"></app-button> -->

  <!-- <app-button *ngIf="needsCalculator.seedNeeds.length > 0" [iconName]="'file-csv'" [id]="'Needs.CSV'"
    [callback]="'csvSeeds'" [callbackOwner]="this"></app-button>
  <app-button *ngIf="needsCalculatorPlants.plantsNeeds.length > 0" [iconName]="'file-csv'" [id]="'NeedsPlants.CSV'"
    [callback]="'csvPlants'" [callbackOwner]="this"></app-button> -->
  <app-button
    *ngIf="(needsCalculator.seedNeeds.length > 0 || needsCalculatorPlants.plantsNeeds.length > 0) && actionExport"
    [iconName]="'file-excel'" [id]="'Needs.XLSX'" [callback]="'exportExcel'" [callbackOwner]="this"></app-button>

  <button type="button" class="btn btn-sm ml-1 mr-1" [ngClass]="myApplication.theme.btnOutline" (click)="close()"
    [ngbTooltip]="myApplication.getText('close')">
    <fa name="check"></fa>
  </button>
</div>