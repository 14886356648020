import * as tslib_1 from "tslib";
import { SortSeedEO } from '../entity-objects/sort-seed-eo';
import { AbstractObject } from '../technical-aspects/abstract-object';
var SortSeed = /** @class */ (function (_super) {
    tslib_1.__extends(SortSeed, _super);
    function SortSeed() {
        return _super.call(this) || this;
    }
    SortSeed.prototype.update = function () {
    };
    SortSeed.prototype.initialize = function () {
        this.eo = new SortSeedEO();
    };
    Object.defineProperty(SortSeed.prototype, "sortSeedEO", {
        get: function () {
            return this.eo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SortSeed.prototype, "sortId", {
        get: function () {
            return this.sortSeedEO.sortId;
        },
        set: function (value) {
            this.sortSeedEO.sortId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SortSeed.prototype, "manufacturer", {
        get: function () {
            return this.sortSeedEO.manufacturer;
        },
        set: function (value) {
            this.sortSeedEO.manufacturer = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SortSeed.prototype, "tkg", {
        get: function () {
            return this.sortSeedEO.tkg;
        },
        set: function (value) {
            this.sortSeedEO.tkg = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SortSeed.prototype, "manufacturingYear", {
        get: function () {
            return this.sortSeedEO.manufacturingYear;
        },
        set: function (value) {
            this.sortSeedEO.manufacturingYear = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SortSeed.prototype, "seedValue", {
        get: function () {
            return this.sortSeedEO.seedValue;
        },
        set: function (value) {
            this.sortSeedEO.seedValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SortSeed.prototype, "seedUnit", {
        get: function () {
            return this.sortSeedEO.seedUnit;
        },
        set: function (value) {
            this.sortSeedEO.seedUnit = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SortSeed.prototype, "otherUnit", {
        get: function () {
            return this.sortSeedEO.otherUnit;
        },
        set: function (value) {
            this.sortSeedEO.otherUnit = value;
        },
        enumerable: true,
        configurable: true
    });
    return SortSeed;
}(AbstractObject));
export { SortSeed };
