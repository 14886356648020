import { AbstractObject } from '../technical-aspects/abstract-object';
import { Bed } from './bed';
import { DAOBeds } from '../dao/dao-beds';
import { AreaEO } from '../entity-objects/area-eo';
import { FilteredRecordSet } from './filtered-record-set';
import { DAORecords } from '../dao/dao-records';
import { Record } from './record';
import { ToDoListArea } from './todo-list-area';
import { DAOToDos } from '../dao/dao-todos';
import { Constants } from './constants';

export class Area extends AbstractObject {

  public recordSet: FilteredRecordSet;
  public daoBeds: DAOBeds;
  public daoRecords: DAORecords;
  public daoToDos: DAOToDos;
  private _todoList: ToDoListArea;
  public typeText: String = '';


  constructor() {
    super();
  }

  update() {
    this.daoToDos.addObserver(this);
    this.todoList = null;
    this.updateTypeText();
  }

  protected get areaEO(): AreaEO {
    return <AreaEO>this.eo;
  }

  public get name(): String {
    return this.areaEO.name;
  }
  public set name(value: String) {
    this.areaEO.name = value;
  }

  public get pathWidth(): number {
    return this.areaEO.pathWidth;
  }
  public set pathWidth(value: number) {
    this.areaEO.pathWidth = value;
  }

  public getNumPathes(): number {
    if (this.type = Constants.AREA_TYPE_FREILAND) {
      return this.getNumBeds() + 1;
    } else {
      return this.getNumBeds() - 1;
    }
  }

  public get description(): String {
    return this.areaEO.description;
  }
  public set description(value: String) {
    this.areaEO.description = value;
  }

  public get type(): String {
    return this.areaEO.type;
  }
  public set type(value: String) {
    this.areaEO.type = value;
    this.updateTypeText();
  }

  private updateTypeText() {
    this.typeText = this.myApplication.textDTOs.get('Area.type.' + this.type).text;
  }

  initialize() {
    this.eo = new AreaEO();
  }

  public getRecordSet(): FilteredRecordSet {
    if (!this.recordSet) {
      let objects = new Array();

      this.daoRecords.objects.forEach(element => {
        let record: Record;
        record = <Record>element;
        if (record.areaId == this.id) {
          objects.push(record);
        }
      });
      this.recordSet = new FilteredRecordSet(objects, this.myApplication, this.basicService);
    }
    return this.recordSet;
  }

  public createNewBed(): Bed {
    let set = new Array<Bed>();
    let size = this.daoBeds.getBedsForArea(this.id).length;
    let bed = <Bed>this.daoBeds.createNewInstance();
    set.push(bed);
    bed.areaId = this.id;
    bed.orderNum = size + 1;

    return bed;
  }

  public getNumBeds(): number {
    return this.daoBeds.getBedsForArea(this.id).length;
  }

  public getSumBedM(): number {
    let sum = 0;

    let objects = this.daoBeds.getBedsForArea(this.id);
    objects.forEach(bed => {
      sum = sum + bed.length;
    });
    return Math.round(sum * 100) / 100;
  }

  public getM2(): number {
    let sum = 0;

    let objects = this.daoBeds.getBedsForArea(this.id);
    objects.forEach(bed => {
      sum = sum + bed.getM2();
    });
    return sum;
  }

  public getM2Paths(): number {
    let sum = 0;

    let objects = this.daoBeds.getBedsForArea(this.id);

    for (let i = 0; i < objects.length; i++) {
      if (this.type == Constants.AREA_TYPE_FREILAND && i == 0) { //doppelt
        sum = sum + (objects[i].length * this.pathWidth);
      }
      if (this.type == Constants.AREA_TYPE_FREILAND || i < objects.length - 1) {
        sum = sum + (objects[i].length * this.pathWidth);
      }
    }
    return sum;
  }

  getM2Sum(): number {
    return this.getM2() + this.getM2Paths();
  }

  public get todoList(): ToDoListArea {
    if (!this._todoList) {
      this._todoList = new ToDoListArea(this.daoToDos, this);

      this._todoList.toDos = this._todoList.toDos.sort(function (one, two) {
        let compare = 0;
        if (one.dueDate > two.dueDate) {
          compare = 1;
        } else if (one.dueDate < two.dueDate) {
          compare = -1
        } else {
          compare = 0;
        }

        return compare;
      });
    }
    return this._todoList;
  }
  public set todoList(value: ToDoListArea) {
    this._todoList = value;
  }

  public isDeleteable() {
    let beds = this.daoBeds.getBedsForArea(this.id);
    if (beds.length == 0) {
      return true;
    } else {
      for (let i = 0; i < beds.length; i++) {
        let bed = beds[i];
        if (!bed.isDeleteable()) {
          return false;
        }
      };
      return true;
    }
  }
}