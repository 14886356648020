import * as tslib_1 from "tslib";
import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';
import { Constants } from '../business-logic/constants';
var SeedEO = /** @class */ (function (_super) {
    tslib_1.__extends(SeedEO, _super);
    function SeedEO() {
        var _this = _super.call(this) || this;
        _this.sortSeedId = '';
        _this.sortId = '';
        _this.quantity = 0;
        _this.quantityUnit = Constants.UNIT_GRAMM;
        _this.done = false;
        _this.otherUnit = false;
        return _this;
    }
    return SeedEO;
}(AbstractEntityObject));
export { SeedEO };
