import * as tslib_1 from "tslib";
import { DAO } from '../technical-aspects/dao';
import { Bed } from '../business-logic/bed';
var DAOBeds = /** @class */ (function (_super) {
    tslib_1.__extends(DAOBeds, _super);
    function DAOBeds() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DAOBeds.prototype.createInstance = function () {
        var newInstance = new Bed();
        newInstance.daoRecords = this.daoService.getDAO('records');
        newInstance.daoBeds = this;
        newInstance.daoToDos = this.daoService.getDAO('todos');
        return newInstance;
    };
    DAOBeds.prototype.getSumM2 = function () {
        var sum = 0;
        this.objects.forEach(function (element) {
            var bed = element;
            sum = sum + bed.getM2();
        });
        return Math.round(sum * 100) / 100;
    };
    DAOBeds.prototype.getBedsForArea = function (areaId) {
        var set = new Array();
        this.objects.forEach(function (element) {
            var bed = element;
            if (bed.areaId == areaId) {
                set.push(bed);
            }
        });
        set.sort(function (one, two) {
            return one.orderNum - two.orderNum;
        });
        for (var i = 1; i <= set.length; i++) {
            set[i - 1].orderNum = i;
        }
        return set;
    };
    DAOBeds.prototype.deleteObject = function (object) {
        object.deleteBedAssigns();
        _super.prototype.deleteObject.call(this, object);
        var areaId = object.areaId;
        this.renumber(areaId);
    };
    DAOBeds.prototype.renumber = function (areaId) {
        var set = this.getBedsForArea(areaId);
        for (var i = 1; i <= set.length; i++) {
            set[i - 1].orderNum = i;
        }
    };
    DAOBeds.prototype.createNewInstance = function () {
        var repository = this.daoService.getDAO('repository').getRepository();
        var object = _super.prototype.createNewInstance.call(this);
        object.width = repository.bedWidth;
        ;
        object.length = repository.bedLength;
        return object;
    };
    DAOBeds.prototype.isSelected = function (bed) {
        if (bed == this.selectedBed) {
            return true;
        }
        else {
            return false;
        }
    };
    return DAOBeds;
}(DAO));
export { DAOBeds };
