import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';

export class SortEO extends AbstractEntityObject {

    public name: String;
    public cultureId: String;
    public useOwnPeriods: boolean;

    public seed: number;
    public grow: number;
    public harvest: number;

    public useOwnDistances: boolean;
    public plantDistance: number;
    public rowDistance: number;
    public numRows: number;
    public note: String;
    public seedRate: number;
    public useOwnDetails: boolean;
    public useOtherSeedUnit: boolean;


    constructor() {
        super();
        this.cultureId = '';
        this.name = '';

        this.useOwnPeriods = false;

        this.seed = 4;
        this.grow = 6;
        this.harvest = 2;

        this.useOwnDistances = false;
        this.plantDistance = 0;
        this.rowDistance = 0;
        this.numRows = 0;
        this.note = '';
        this.seedRate = 100;
        this.useOwnDetails = false;
        this.useOtherSeedUnit = false;
    }
}