import { Component, OnInit, Input } from '@angular/core';
import { FieldTextDTO } from 'src/app/app-core/dto/field-text-dto';
import { ApplicationService } from 'src/app/services/application.service';
import { KeyValueDTO } from 'src/app/app-core/dto/key-value-dto';

@Component({
  selector: 'app-select-field',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.css']
})
export class SelectFieldComponent implements OnInit {

  @Input() id: String;
  @Input() object;
  @Input() valueName: String;
  @Input() keyValueList: Array<KeyValueDTO>;
  @Input() modelChangedObject;
  @Input() withoutTitle: boolean = false;

  public min: number = 1;
  public fieldDTO: FieldTextDTO;
  private _disable: boolean = false;
  

  constructor(public myApplication: ApplicationService) {

  }

  public get disable(): boolean {
      return this._disable;
     }
  @Input()
  public set disable(value: boolean) {
    this._disable = value;
  }

  ngOnInit() {
    if (!this.id) {
      this.id = this.valueName;
    }
    this.fieldDTO = this.myApplication.textDTOs.get(this.id);
    if (!this.fieldDTO) {
      this.fieldDTO = new FieldTextDTO(this.id, this.id, '');
    }
    this.keyValueList.forEach(element => {
      if (!element.value || element.value == '') {
        let valueDTO = this.myApplication.textDTOs.get(this.id + '.' + element.key);
        if (valueDTO) {
          element.value = valueDTO.text;
        } else {
          element.value = this.id + '.' + element.key;
          console.log("ID fehlt:" + element.value);
        }
      }
    });
  }

  public modelChanged(value) {
    if (this.modelChangedObject) {
      this.modelChangedObject.modelChanged(value);
    }
  }

}
