import * as tslib_1 from "tslib";
import { AbstractObject } from '../technical-aspects/abstract-object';
import { BedAssignEO } from '../entity-objects/bed-assign-eo';
import { PeriodsUIList } from './periods-ui-list';
var BedAssign = /** @class */ (function (_super) {
    tslib_1.__extends(BedAssign, _super);
    function BedAssign() {
        return _super.call(this) || this;
    }
    BedAssign.prototype.update = function () {
    };
    BedAssign.prototype.initialize = function () {
        this.eo = new BedAssignEO();
    };
    Object.defineProperty(BedAssign.prototype, "startTS", {
        get: function () {
            return this.bedAssignEO.startTS;
        },
        set: function (value) {
            this.bedAssignEO.startTS = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BedAssign.prototype, "cultureEndTS", {
        get: function () {
            return this.bedAssignEO.cultureEndTS;
        },
        set: function (value) {
            this.bedAssignEO.cultureEndTS = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BedAssign.prototype, "bed", {
        get: function () {
            if (!this._bed) {
                this._bed = this.daoBeds.getObjectById(this.bedId);
            }
            return this._bed;
        },
        set: function (value) {
            this._bed = value;
        },
        enumerable: true,
        configurable: true
    });
    BedAssign.prototype.getArea = function () {
        return this.daoAreas.getObjectById(this.bed.areaId);
    };
    BedAssign.prototype.getAreaId = function () {
        return this.bed.areaId;
    };
    Object.defineProperty(BedAssign.prototype, "periodsUIKWBedmeters", {
        get: function () {
            if (!this._periodsUIKWBedmeters) {
                this.buildPeriodsUIKWBedmeters();
            }
            return this._periodsUIKWBedmeters;
        },
        enumerable: true,
        configurable: true
    });
    BedAssign.prototype.buildPeriodsUIKWBedmeters = function () {
        this._periodsUIKWBedmeters = new PeriodsUIList(this.myApplication, this.basicService, null);
        this._periodsUIKWBedmeters.createForKWBedmeters(this, this.bed, this.record);
    };
    Object.defineProperty(BedAssign.prototype, "bedAssignEO", {
        get: function () {
            return this.eo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BedAssign.prototype, "parentId", {
        get: function () {
            return this.bedAssignEO.parentId;
        },
        set: function (value) {
            this.bedAssignEO.parentId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BedAssign.prototype, "bedId", {
        get: function () {
            return this.bedAssignEO.bedId;
        },
        set: function (value) {
            this.bedAssignEO.bedId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BedAssign.prototype, "positionOnBed", {
        get: function () {
            return this.bedAssignEO.positionOnBed;
        },
        set: function (value) {
            if (value != this.bedAssignEO.positionOnBed) {
                this.bedAssignEO.positionOnBed = value;
                this.buildPeriodsUIKWBedmeters();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BedAssign.prototype, "lengthOnBed", {
        get: function () {
            return this.bedAssignEO.lengthOnBed;
        },
        set: function (value) {
            if (value != this.bedAssignEO.lengthOnBed) {
                this.bedAssignEO.lengthOnBed = value;
                this.buildPeriodsUIKWBedmeters();
            }
        },
        enumerable: true,
        configurable: true
    });
    return BedAssign;
}(AbstractObject));
export { BedAssign };
