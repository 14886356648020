import { FilteredRecordSet } from '../business-logic/filtered-record-set';
import { Record } from '../business-logic/record';
import { DAO } from '../technical-aspects/dao';
import { DAOCultures } from './dao-cultures';
import { DAOSorts } from './dao-sorts';
import { AbstractObject } from '../technical-aspects/abstract-object';
import { DAOParts } from './dao-parts';
import { DAOBeds } from './dao-beds';
import { DAOBedAssigns } from './dao-bed-assigns';
import { DAOToDos } from './dao-todos';
import { DAOAreas } from './dao-areas';
import { Http } from '@angular/http';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ApplicationService } from 'src/app/services/application.service';
import { DaoService } from 'src/app/services/dao.service';
import { BasicService } from 'src/app/services/basic.service';
import { EventHandler } from '../technical-aspects/event-handler';
import { PartsService } from 'src/app/services/parts.service';


export class DAORecords extends DAO {

    public lastQuickId: number = 0;
    private _objectSet: FilteredRecordSet = new FilteredRecordSet(null, this.myApplication, this.basicService);
    public selectedRecord: Record;


    constructor(http: Http, authenticationService: AuthenticationService, name: String,
        myApplication: ApplicationService, daoService: DaoService, basicService: BasicService, public partsService: PartsService) {

        super(http, authenticationService, name,
            myApplication, daoService, basicService);

        this.myApplication.eventHandler.register(EventHandler.EVENT_RECORD_START_CHANGED, this);
    }

    handleRecordStartChanged(id: String) {
        this.renumber(id);
    }

    public assembleObjects() {
        super.assembleObjects();
        this.updateLocal();
    }

    public isSelected(record: Record): boolean {
        if (record == this.selectedRecord) {
            return true;
        } else {
            return false;
        }
    }

    public getNumRecordsForCulture(cultureId: String, allYears?: boolean): number {
        return this.getRecordsForCulture(cultureId, allYears).length;
    }

    public getRecordsForCulture(cultureId: String, allYears?: boolean): Array<Record> {
        let resultSet = new Array<Record>();
        this.objects.forEach(object => {
            let record = <Record>object;
            if (record.cultureId == cultureId && allYears ||
                record.cultureId == cultureId && record.recordPeriods.isInYear(this.myApplication.year)) {
                resultSet.push(record);
            }
        });
        return resultSet;
    }

    public getRecordsForBed(bedId: String): Array<Record> {
        let resultSet = new Array<Record>();
        this.objects.forEach(object => {
            let record = <Record>object;
            if (record.bedAssignList.isOnBed(bedId)) {
                resultSet.push(record);
            }
        });
        return resultSet;
    }

    public get objectSet(): FilteredRecordSet {
        return this._objectSet;
    }

    public set objectSet(value: FilteredRecordSet) {
        this._objectSet = value;
    }

    protected setData(data: any) {
        super.setData(data);
    }

    public deleteObject(object: AbstractObject) {
        let culture = (<Record>object).culture;
        let record = <Record>object;
        record.todoList.delete();
        record.bedAssignList.deleteAll();
        record.recordPeriods.delete();
        super.deleteObject(object);
        this.updateLocal();
        this.renumber(culture.id);
        this.myApplication.eventHandler.raiseEvent(EventHandler.EVENT_RECORD_DELETED, object.id);
    }

    protected updateLocal() {
        this.objectSet.replaceObjects(<Record[]>Array.from(this.objects.values()));
    }

    protected createInstance() {
        let newInstance = new Record();
        newInstance.daoRecords = this;
        newInstance.daoCultures = <DAOCultures>this.daoService.getDAO('cultures');
        newInstance.daoSorts = <DAOSorts>this.daoService.getDAO('sorts');
        newInstance.daoParts = <DAOParts>this.daoService.getDAO('parts');
        newInstance.daoAreas = <DAOAreas>this.daoService.getDAO('areas');
        newInstance.daoBeds = <DAOBeds>this.daoService.getDAO('beds');
        newInstance.daoBedAssigns = <DAOBedAssigns>this.daoService.getDAO('bedAssigns');
        newInstance.daoToDos = <DAOToDos>this.daoService.getDAO('todos');
        newInstance.partsService = this.partsService;
        newInstance.repoService = this.daoService.repoService;

        return newInstance;
    }

    public searchByQuickId(qid: number): Record {
        let foundObj;
        this.objects.forEach(object => {
            let record = <Record>object;
            if (record.quickId == qid) {
                foundObj = record;
                return;
            }
        });
        return foundObj;
    }

    public createNewInstance(): AbstractObject {
        let object = <Record>super.createNewInstance();
        this.objectSet.addObject(object);
        object.quickId = this.getNewQuickId();
        return object;
    }

    public getNewQuickId(): number {
        if (this.lastQuickId == 0) {
            this.objects.forEach(element => {
                if ((<Record>element).quickId > this.lastQuickId) {
                    this.lastQuickId = (<Record>element).quickId;
                }
            });
        }
        this.lastQuickId = this.lastQuickId + 1;
        return this.lastQuickId;
    }

    public renumber(cultureId: String) {
        let renumList = new Array<Record>();
        this.objects.forEach(object => {
            let record = <Record>object;
            if (record.cultureId == cultureId) {
                renumList.push(record);
            }
        });

        renumList = renumList.sort(function (one, two) {
            return one.recordPeriods.growDate.getTime() - two.recordPeriods.growDate.getTime();
        });

        let i = 0;
        let year = -1;
        renumList.forEach(object => {
            let record = <Record>object;
            if (record.recordPeriods.getStartWeek().year != year) {
                i = 1;
                year = record.recordPeriods.getStartWeek().year;
            }
            record.recordNum = i;
            i = i + 1;
        });
    }

    public readData(): Promise<boolean> {
        let promise = super.readData();
        promise.then(() => {
            // this.assembleObjects();
        });
        return promise;
    }

    protected saveDB() {
        //nein, hier nicht mehr
    }
}