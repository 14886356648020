import { DAORecords } from '../dao/dao-records';
import { DAOSeeds } from '../dao/dao-seeds';
import { Record } from './record';
import { Constants } from './constants';
import { NeedsDTO } from '../dto/needs-dto';
import { Seed } from './seed';
import { BasicService } from 'src/app/services/basic.service';
import { ApplicationService } from 'src/app/services/application.service';
import { Sort } from './sort';


export class NeedsCalculator {

    public seedNeeds = new Array<NeedsDTO>();
    public seedNeedsOtherUnit = new Array<NeedsDTO>();
    private year: number


    constructor(protected daoRecords: DAORecords, protected daoSeeds: DAOSeeds, protected basicService: BasicService, private myApplication: ApplicationService) {

    }

    calculate() {
        this.calculateSeeds();
        this.calculateSeedsOtherUnit();
    }

    protected calculateSeeds() {
        let myApplication = this.daoRecords.myApplication;
        this.year = myApplication.year;
        let needs: NeedsDTO;
        this.daoRecords.objects.forEach(element => {
            let record = <Record>element;
            if (record.culture) {
                if ((record.cultureType != Constants.CULTURE_TYPE_PFLANZKAUF && record.recordPeriods.seedDate.getFullYear() == this.year)) {
                    //nur wenn TKG-Sorte
                    if (!record.sort || !record.culture.getSort(record.sort) || (record.sort &&
                        !this.isSortSeedYearUseOtherSeedUnit(record))) {
                        needs = this.getByName(this.seedNeeds, record);
                        needs.quantity = needs.quantity + record.getNeededSeeds();
                        needs.buyQuantity = needs.quantity;
                        let recordSort = record.getSort();
                        if (recordSort) {
                            let sortSeed = recordSort.sortSeedSet.getSortSeedForYear(this.year)
                            if (!sortSeed) {
                                sortSeed = recordSort.sortSeedSet.getSortSeedBeforYear(this.year)
                                if (sortSeed) {
                                    needs.hint = myApplication.getText('Seeds.old');
                                }
                            }
                            if (sortSeed) {
                                needs.tkg = sortSeed.tkg;
                                needs.vendor = sortSeed.manufacturer;
                            }
                        }
                        else {
                            needs.hint = myApplication.getText('Needs.FM.noSort');
                        }
                    }
                }
            } else { //geloeschte Kultur
                if ((record.cultureType != Constants.CULTURE_TYPE_PFLANZKAUF && record.recordPeriods.seedDate.getFullYear() == this.year)) {
                    needs = this.getByName(this.seedNeeds, record);
                    needs.quantity = needs.quantity + record.getNeededSeeds();
                    needs.buyQuantity = needs.quantity;
                    needs.hint = myApplication.getText('Culture.deleted');
                }
            }
        });

        //Gewichte berechnen & runden
        this.seedNeeds.forEach(needs => {
            needs.quantity = Math.ceil(needs.quantity);
            needs.buyQuantity = Math.ceil(needs.buyQuantity);
            needs.weight = needs.quantity / 1000 * needs.tkg;


            needs.buyWeight = Math.ceil(needs.weight);

            this.appendExistingSeeds(needs);
            if (needs.buyWeight < 0) {
                needs.buyWeight = 0;
            }
            if (needs.buyQuantity < 0) {
                needs.buyQuantity = 0;
            }
            needs.buyWeight = needs.tkg / 1000 * needs.buyQuantity;
            needs.weight = needs.weight * 10 / 10;
            needs.buyWeight = needs.buyWeight * 10 / 10;
            needs.existingWeight = needs.existingWeight * 10 / 10;
        });

        this.seedNeeds.sort(function (one, two) {
            let compare = (<NeedsDTO>one).name.toString().localeCompare((<NeedsDTO>two).name.toString());
            return compare;
        });

    }

    private isUseOtherSeedUnit(record) {
        return (record.sort && record.culture.getSort(record.sort) && record.culture.getSort(record.sort).useOtherSeedUnit);
    }

    private isSortSeedYearUseOtherSeedUnit(record) {
        if (!record.sort) {
            return false;
        }
        let sort: Sort = record.culture.getSort(record.sort)
        if (!sort) {
            return false;
        }

        let year = this.myApplication.year;
        let sortSeed = sort.sortSeedSet.getSortSeedForYear(year);

        if (!sortSeed) {
            sortSeed = sort.sortSeedSet.getSortSeedBeforYear(year);
        }
        if (!sortSeed) {
            return false;
        }

        return (sortSeed.otherUnit);
    }

    protected calculateSeedsOtherUnit() {
        let myApplication = this.daoRecords.myApplication;
        this.year = myApplication.year;
        let needs: NeedsDTO;
        this.daoRecords.objects.forEach(element => {
            let record = <Record>element;
            if (record.culture) {
                if ((record.cultureType != Constants.CULTURE_TYPE_PFLANZKAUF && record.recordPeriods.seedDate.getFullYear() == this.year)) {
                    if (this.isSortSeedYearUseOtherSeedUnit(record)) {
                        needs = this.getByName(this.seedNeedsOtherUnit, record);
                        needs.quantity = needs.quantity + record.getNeededSeedsOtherUnit();
                        needs.buyQuantity = needs.quantity;
                        let recordSort = record.getSort();
                        if (recordSort) {
                            let sortSeed = recordSort.sortSeedSet.getSortSeedForYear(this.year)
                            if (!sortSeed) {
                                sortSeed = recordSort.sortSeedSet.getSortSeedBeforYear(this.year)
                                if (sortSeed) {
                                    needs.hint = myApplication.getText('Seeds.old');
                                }
                            }
                            if (sortSeed) {
                                needs.seedValue = sortSeed.seedValue;
                                needs.seedUnit = sortSeed.seedUnit;
                                needs.vendor = sortSeed.manufacturer;
                            }
                        }
                        else {
                            needs.hint = myApplication.getText('Needs.FM.noSort');
                        }
                    }
                }
            } else { //geloeschte Kultur -> Satz ist schon unter TKG ausgegeben hier nicht nochmal

            }
        });

        //Gewichte berechnen & runden
        this.seedNeedsOtherUnit.forEach(needs => {
            let seedValue: number = 0;
            needs.quantity = Math.ceil(needs.quantity);
            needs.buyQuantity = Math.ceil(needs.buyQuantity);
            if (needs.seedUnit == 'a' || needs.seedUnit == 'c') {
                // pro ha
                // Faktor 10.000
                seedValue = needs.seedValue / 10000;
            } else if (needs.seedUnit == 'b' || needs.seedUnit == 'd') {
                // pro m2
                // hier nichts umrechnen
                seedValue = needs.seedValue;
            } else if (needs.seedUnit == 'i' || needs.seedUnit == 'j') {
                //pro Ar
                // Faktor 100
                seedValue = needs.seedValue / 100;
            }
            // needs.weight = Math.ceil(seedValue * needs.quantity);
            // needs.weight = Math.ceil(needs.weight);
            needs.weight = (seedValue * needs.quantity);
            needs.weight = (needs.weight);
            if (needs.seedUnit == 'a' || needs.seedUnit == 'b' || needs.seedUnit == 'j') {
                needs.weightUnit = 'kg';
            } else if (needs.seedUnit == 'c' || needs.seedUnit == 'd' || needs.seedUnit == 'k') {
                needs.weightUnit = 'g';
            }

            needs.buyWeight = Math.ceil(needs.weight);
            this.appendExistingSeedsOtherUnit(needs);
            if (needs.buyWeight < 0) {
                needs.buyWeight = 0;
            }
            if (needs.buyQuantity < 0) {
                needs.buyQuantity = 0;
            }
            //wenn Gewichteinheit gleich dann einfach abziehen, ansonsten umrechnen
            if (needs.existingWeightUnit == needs.weightUnit) {
                needs.buyWeight = needs.weight - needs.existingWeight;
            } else {
                if (needs.existingWeightUnit == 'g' && needs.weightUnit == 'kg') {
                    needs.buyWeight = needs.weight - (needs.existingWeight / 1000);
                } else if (needs.existingWeightUnit == 'kg' && needs.weightUnit == 'g') {
                    needs.buyWeight = needs.weight - (needs.existingWeight * 1000);
                }
            }

            if (needs.buyWeight < 0) {
                needs.buyWeight = 0;
            }

            needs.existingWeight = Math.ceil(needs.existingWeight * 10) / 10;
        });

        this.seedNeedsOtherUnit.sort(function (one, two) {
            let compare = (<NeedsDTO>one).name.toString().localeCompare((<NeedsDTO>two).name.toString());
            return compare;
        });

    }

    protected appendExistingSeeds(needs: NeedsDTO) {
        this.daoSeeds.objects.forEach(element => {
            let seeds = <Seed>element;
            if (seeds.getSort()) { //Sorte zu Saatgut ist geloescht, ohne Loeschung Saatgut - sollte eigentlich nicht moeglich sein
                let name1 = this.getNameTrimmed(seeds.getSort().getFullName().toString());
                let name2 = this.getNameTrimmed(needs.name + ' ' + needs.sort);
                if (seeds.getSort() && name1 == name2 && !seeds.done) {
                    if (seeds.getTKG() || seeds.quantityUnit == Constants.UNIT_KORN) {
                        needs.existingWeight = needs.existingWeight + seeds.getWeight();
                        needs.buyQuantity = needs.buyQuantity - seeds.getNumCorn();
                        needs.existingQuantity = seeds.getNumCorn();

                    } else {
                        let infoText = this.myApplication.getText('Needs.FM.noTKG').toString()
                        if (!needs.hint.includes(infoText)) {
                            needs.hint = needs.hint + infoText;
                        }
                    }
                }
            }
        });
    }

    private getNameTrimmed(name: string) {
        if (name) {
            return name.trim();
        }
        return '';
    }

    protected appendExistingSeedsOtherUnit(needs: NeedsDTO) {
        this.daoSeeds.objects.forEach(element => {
            let seeds = <Seed>element;
            if (seeds.getSort()) { //Sorte zu Saatgut ist geloescht, ohne Loeschung Saatgut - sollte eigentlich nicht moeglich sein
                let name1 = this.getNameTrimmed(seeds.getSort().getFullName().toString());
                let name2 = this.getNameTrimmed(needs.name + ' ' + needs.sort);

                if (seeds.getSort() && name1 == name2 && !seeds.done) {
                    if (seeds.getSeedValue()) {
                        if (seeds.getSortSeed().otherUnit) {
                            // nur benutzen wenn other unit
                            //wenn Einheiten gleich hinzurechnen ansonsten auf eine Einheit bringen
                            if (seeds.quantityUnit == needs.existingWeightUnit) {
                                needs.existingWeight = needs.existingWeight + seeds.quantity;
                                needs.existingWeightUnit = seeds.quantityUnit;
                            } else {
                                if (seeds.quantityUnit == 'g' && needs.existingWeightUnit == 'kg') {
                                    needs.existingWeight = needs.existingWeight + (seeds.quantity / 1000);
                                } else if (seeds.quantityUnit == 'kg' && needs.existingWeightUnit == 'g') {
                                    needs.existingWeight = (needs.existingWeight / 1000) + seeds.quantity;
                                    needs.existingWeightUnit = seeds.quantityUnit;
                                }
                            }
                        } else {
                            let infoText = this.myApplication.getText('Needs.FM.TKGnotUsed').toString()
                            if (!needs.hint.includes(infoText)) {
                                needs.hint = needs.hint + infoText;
                            }
                        }
                    } else {

                    }
                }
            }
        });
    }

    protected getByName(list: Array<NeedsDTO>, record: Record): NeedsDTO {
        let needs = new NeedsDTO();
        if (record.culture) {
            for (let i = 0; i < list.length; i++) {
                if (list[i].name == record.culture.name && list[i].sort == record.sort) {
                    return list[i];
                }
            }

            needs.name = record.culture.name;
            needs.sort = record.sort;
            list.push(needs);
        } else {
            needs.name = this.myApplication.getText('Culture.deleted');
            needs.sort = record.sort;
            list.push(needs);
        }
        return needs;
    }
}