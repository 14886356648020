<div class="card m-2 seed-card" [style.border-left-color]="culture.color" [style.border-left-width]="'thick'">
  <div class="card-header" data-toggle="collapse" [attr.data-target]="'#culture' + culture.id" aria-expanded="false"
    [attr.aria-controls]="'culture' + culture.id" (click)="headerClicked()">

    <h6 class="card-title">{{ culture.name }}
      <small class="card-subtitle mb-2 mr-2 text-muted">
      </small>
    </h6>
  </div>

  <div class="card-body collapse mx-0" [id]="'culture' + culture.id">
    <ng-container *ngIf="actionCreateSorts">
      <button class="btn btn-sm ml-1" [ngClass]="myApplication.theme.btnOutline" type="button"
        (click)="createSort(culture)" [ngbTooltip]="'Sorte anlegen'">
        <fa name="plus"></fa>
      </button>
    </ng-container>

    <div class="row">
      <div class="col col-sm-12">
        <!-- {{sort.name}} -->
        <app-sort-seed *ngFor="let sort of culture.sorts" [sort]="sort"></app-sort-seed>
      </div>
    </div>
  </div>
</div>