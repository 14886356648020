import { ApplicationService } from 'src/app/services/application.service';
import { BasicService } from 'src/app/services/basic.service';
import { RecordCreateDTO } from '../dto/record-create-dto';
import { WeekInYearDTO } from '../dto/week-in-year-dto';
import { RecordEO } from '../entity-objects/record-eo';
import { Observable } from '../technical-aspects/observable';
import { Constants } from './constants';
import { Culture } from './culture';
import { HarvestDistribution } from './harvest-distribution';
import { Record } from './record';
import { EventHandler } from '../technical-aspects/event-handler';


export class RecordPeriods extends Observable {

  private _harvestDistributions: Array<HarvestDistribution>;
  // private _alternativeDeliverySelected: boolean = false;
  public harvestUnitText: String;


  constructor(public recordEO: RecordEO, public basicService: BasicService, private _culture: Culture,
    private myApplication: ApplicationService,
    private record: Record) {

    super();

    this.handleRecordDatesChanged();
    this.myApplication.eventHandler.register(EventHandler.EVENT_RECORD_DATES_CHANGED, this);
  }

  handleRecordDatesChanged() {
    if (this.basicService) {
      this.recordEO.seedTS = this.basicService.getUTCTimestamp(this.recordEO.seedDate);
      this.recordEO.growTS = this.basicService.getUTCTimestamp(this.recordEO.growDate);
      this.recordEO.harvestTS = this.basicService.getUTCTimestamp(this.recordEO.harvestDate);
      this.recordEO.cultureEndTS = this.basicService.getUTCTimestamp(this.getCultureEnd());
    }
  }

  delete() {
  }

  public getStartDate(): Date {
    if (this.recordEO.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
      return this.seedDate;
    } else {
      return this.growDate;
    }
  }


  getNextActionWeek(): WeekInYearDTO {
    if (this.recordEO.state == Constants.STATE_PLANNED) {
      return this.getStartWeek();
    } else if (this.recordEO.state == Constants.STATE_ANZUCHT) {
      return this.growWeek;
    } else if (this.recordEO.state == Constants.STATE_PLANTED) {
      return this.harvestWeek;
    }
  }

  public getStartWeek(): WeekInYearDTO {
    return this.basicService.getWeek(this.getStartDate());
  }

  public get seedDate(): Date {
    return this.recordEO.seedDate;
  }
  public set seedDate(value: Date) {
    let seedLength = this.seedLength;
    let growLength = this.growLength;

    value = this.basicService.normalizeDate(value);
    this.recordEO.seedDate.setTime(value.getTime());
    this.recordEO.growDate.setTime(this.recordEO.seedDate.getTime());
    this.recordEO.growDate.setDate(this.recordEO.growDate.getDate() + (7 * seedLength));

    this.recordEO.harvestDate.setTime(this.recordEO.growDate.getTime());
    this.recordEO.harvestDate.setDate(this.recordEO.harvestDate.getDate() + (7 * growLength));

    this.myApplication.eventHandler.raiseEvent(EventHandler.EVENT_RECORD_START_CHANGED, this.record.cultureId);
    this.myApplication.eventHandler.raiseEvent(EventHandler.EVENT_RECORD_DATES_CHANGED, this.record.id);

    this.hasChanged();
  }

  public get seedWeek(): WeekInYearDTO {
    return this.basicService.getWeek(this.seedDate);
  }
  public set seedWeek(value: WeekInYearDTO) {
    this.seedDate = this.basicService.getFirstDateOfWeek(value);
  }
  public get lastSeedWeek(): WeekInYearDTO {
    return this.basicService.subtractWeeks(this.growWeek, 1);
  }
  public get seedLength(): number {
    return this.basicService.calculateWeekDiff(this.seedWeek, this.growWeek);
  }

  public get growDate(): Date {
    return this.recordEO.growDate;
  }
  public set growDate(value: Date) {
    value = this.basicService.normalizeDate(value);

    if (this.recordEO.cultureType != Constants.CULTURE_TYPE_ANZUCHT || value.getTime() >= this.seedDate.getTime()) {
      let growLength = this.growLength;
      this.recordEO.growDate.setTime(value.getTime());

      this.recordEO.harvestDate.setTime(this.recordEO.growDate.getTime());
      this.recordEO.harvestDate.setDate(this.recordEO.harvestDate.getDate() + (7 * growLength));
      this.recordEO.growDate.setTime(value.getTime());

      if (this.recordEO.cultureType != Constants.CULTURE_TYPE_ANZUCHT) {
        this.recordEO.seedDate.setTime(value.getTime());
      }

      this.myApplication.eventHandler.raiseEvent(EventHandler.EVENT_RECORD_START_CHANGED, this.record.cultureId);
      this.myApplication.eventHandler.raiseEvent(EventHandler.EVENT_RECORD_DATES_CHANGED, this.record.id);

      this.hasChanged();
    } else {
      this.myApplication.messageDTO.setErrorWithId('FM.growDate');
    }
  }
  public get growWeek(): WeekInYearDTO {
    return this.basicService.getWeek(this.growDate);
  }
  public set growWeek(value: WeekInYearDTO) {
    this.growDate = this.basicService.getFirstDateOfWeek(value);
  }
  public get lastGrowWeek(): WeekInYearDTO {
    return this.basicService.subtractWeeks(this.harvestWeek, 1);
  }
  public get growLength(): number {
    return this.basicService.calculateWeekDiff(this.growWeek, this.harvestWeek);
  }

  public get harvestDate(): Date {
    return this.recordEO.harvestDate;
  }
  public set harvestDate(value: Date) {
    value = this.basicService.normalizeDate(value);
    if (this.recordEO.harvestDate.getTime() != value.getTime()) {

      if (value.getTime() >= this.growDate.getTime()) {
        this.recordEO.harvestDate.setTime(value.getTime());
        //bestehende Ernteverteilung an neues Datum anpassen
        // this.updateHarvestDistributionByDate();
        this.hasChanged();
        this.myApplication.eventHandler.raiseEvent(EventHandler.EVENT_RECORD_DATES_CHANGED, this.record.id);

        this.record.calculateQuantities();
      } else {
        this.myApplication.messageDTO.setErrorWithId('FM.harvestDate');
      }
    }
  }
  public get harvestWeek(): WeekInYearDTO {
    return this.basicService.getWeek(this.harvestDate);
  }
  public set harvestWeek(value: WeekInYearDTO) {
    this.harvestDate = this.basicService.getFirstDateOfWeek(value);
  }
  public get lastHarvestWeek(): WeekInYearDTO {
    return this.basicService.addWeeks(this.harvestWeek, this.harvestLength - 1);
  }

  public get harvestLength(): number {
    return this.recordEO.harvestLength;
  }
  public set harvestLength(value: number) {
    if (this.recordEO.harvestLength != value) {
      this.recordEO.harvestLength = value;
      //bestehende Ernteverteilung an Anzahl Wochen anpassen
      //this.updateHarvestDistributionByLength();
      this.hasChanged();
      this.myApplication.eventHandler.raiseEvent(EventHandler.EVENT_RECORD_DATES_CHANGED, this.record.id);

      this.record.calculateQuantities();
    }
  }

  getCultureEnd(): Date {
    let cultureEnd = new Date();
    cultureEnd.setTime(this.harvestDate.getTime());
    cultureEnd.setDate(cultureEnd.getDate() + (this.harvestLength * 7) - 1);
    return this.basicService.normalizeDate(cultureEnd);
  }

  public get harvestQuantity(): number {
    return this.recordEO.harvestQuantity;
  }
  public set harvestQuantity(value: number) {
    this.recordEO.harvestQuantity = value;
    this.record.calculateQuantities();
  }

  public get neededHarvestQuantity(): number {
    return this.recordEO.neededHarvestQuantity;
  }
  public set neededHarvestQuantity(value: number) {
    this.recordEO.neededHarvestQuantity = value;
    this.record.calculateQuantities();
  }

  public get totalHarvestQuantity(): number {
    return this.recordEO.totalHarvestQuantity;
  }
  public set totalHarvestQuantity(value: number) {
    this.recordEO.totalHarvestQuantity = value;
  }

  public get harvestUnit(): String {
    return this.recordEO.harvestUnit;
  }
  public set harvestUnit(value: String) {
    this.recordEO.harvestUnit = value;
    this.record.calculateQuantities();
  }

  public get numOfDeliveryWeeks(): number {
    return this.recordEO.numOfDeliveryWeeks;
  }
  public set numOfDeliveryWeeks(value: number) {
    if (this.recordEO.numOfDeliveryWeeks != value) {
      this.recordEO.numOfDeliveryWeeks = value;
      this.record.calculateQuantities();
    }
  }

  public periodsDifferFromCulture(): boolean {
    if (this.seedLength == this._culture.seed &&
      this.growLength == this._culture.grow &&
      this.harvestLength == this._culture.harvest) {
      return false;
    } else {
      return true;
    }
  }

  public periodsDifferFromSort(): boolean {
    let sort = this.record.getSort();
    if (!sort) {
      return false;
    }
    if (this.seedLength == sort.seed &&
      this.growLength == sort.grow &&
      this.harvestLength == sort.harvest) {
      return false;
    } else {
      return true;
    }
  }

  public get averageQuantity(): number {
    return this.recordEO.averageQuantity;
  }
  public set averageQuantity(value: number) {
    this.recordEO.averageQuantity = value;
    this.record.calculateQuantities();
  }

  public createFromRecordCreateDTO(recordCreateDTO: RecordCreateDTO) {
    this.seedDate = new Date(recordCreateDTO.startDate.getTime());
    this.growDate = new Date(recordCreateDTO.startDate.getTime());
    this.harvestDate = new Date(recordCreateDTO.startDate.getTime());

    this.calculateDates(recordCreateDTO);

    this.harvestLength = recordCreateDTO.harvest;
    this.harvestQuantity = recordCreateDTO.harvestQuantity;
    this.harvestUnit = recordCreateDTO.harvestUnit;
    this.neededHarvestQuantity = recordCreateDTO.averageQuantity;
    this.alternativeDelivery = recordCreateDTO.useDeliveryWeeks;
    this.numOfDeliveryWeeks = recordCreateDTO.numOfDeliveryWeeks;
    this.averageQuantity = recordCreateDTO.averageQuantity;
  }

  private calculateDates(recordCreateDTO: RecordCreateDTO) {
    if (recordCreateDTO.planningDateType == Constants.TODO_TYPE_ANZUCHT) {

      this.growDate.setDate(this.growDate.getDate() + (7 * recordCreateDTO.seed));
      this.harvestDate.setDate(this.harvestDate.getDate() + (7 * (recordCreateDTO.seed + recordCreateDTO.grow)));

    } else if (recordCreateDTO.planningDateType == Constants.TODO_TYPE_AUSSAAT ||
      recordCreateDTO.planningDateType == Constants.TODO_TYPE_PFLANZUNG) {

      this.seedDate.setDate(this.growDate.getDate() - (7 * recordCreateDTO.seed));
      this.harvestDate.setDate(this.harvestDate.getDate() + (7 * (recordCreateDTO.grow)));

    } else if (recordCreateDTO.planningDateType == Constants.TODO_TYPE_ERNTE) {

      this.growDate.setDate(this.harvestDate.getDate() - (7 * recordCreateDTO.grow));
      if (recordCreateDTO.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {

        this.seedDate.setDate(this.harvestDate.getDate() - (7 * (recordCreateDTO.seed + recordCreateDTO.grow)));

      } else {

        this.seedDate.setTime(this.growDate.getTime());
      }
    }
  }

  public clone(recordPeriods: RecordPeriods) {
    this.seedDate = new Date(recordPeriods.seedDate.getTime());
    this.growDate = new Date(recordPeriods.growDate.getTime());
    this.harvestDate = new Date(recordPeriods.harvestDate.getTime());
    this.harvestLength = recordPeriods.harvestLength;
    this.harvestQuantity = recordPeriods.harvestQuantity;
    this.neededHarvestQuantity = recordPeriods.neededHarvestQuantity;
    this.harvestUnit = recordPeriods.harvestUnit;
    this.alternativeDelivery = recordPeriods.alternativeDelivery;
    this.numOfDeliveryWeeks = recordPeriods.numOfDeliveryWeeks;
    this.averageQuantity = recordPeriods.averageQuantity;
  }

  public cloneToNextYear(recordPeriods: RecordPeriods) {
    let week, week2: WeekInYearDTO;

    week = recordPeriods.getStartWeek();
    week.year = week.year + 1;
    if (week.week == 53) {
      week.week = 52;
    }
    if (this.recordEO.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
      week2 = this.basicService.addWeeks(week, recordPeriods.seedLength);
      this.seedWeek = week;
      this.growWeek = week2;

    } else {
      this.seedWeek = week;
      this.growWeek = week;
    }
    week = this.basicService.addWeeks(this.growWeek, recordPeriods.growLength);
    this.harvestWeek = week;

    this.harvestLength = recordPeriods.harvestLength;
    this.harvestQuantity = recordPeriods.harvestQuantity;
    this.harvestUnit = recordPeriods.harvestUnit;
    this.neededHarvestQuantity = recordPeriods.neededHarvestQuantity;
    this.numOfDeliveryWeeks = recordPeriods.numOfDeliveryWeeks;
    this.averageQuantity = recordPeriods.averageQuantity;
  }

  public shift(numWeeks: number) {
    this.seedDate.setDate(this.seedDate.getDate() + (7 * numWeeks));
    this.growDate.setDate(this.growDate.getDate() + (7 * numWeeks));
    this.harvestDate.setDate(this.harvestDate.getDate() + (7 * numWeeks));

    this.myApplication.eventHandler.raiseEvent(EventHandler.EVENT_RECORD_START_CHANGED, this.record.cultureId);
    this.myApplication.eventHandler.raiseEvent(EventHandler.EVENT_RECORD_DATES_CHANGED, this.record.id);

    this.hasChanged();
  }

  public calculateFirstWeekOfHarvest(): WeekInYearDTO {
    let harvestDate = this.harvestDate;

    return this.basicService.getWeek(harvestDate);
  }

  public setGrowDateBySeedLength(length: number) {
    let week = this.basicService.addWeeks(this.seedWeek, length);
    this.growDate = this.basicService.getFirstDateOfWeek(week);
  }

  public setHarvestDateByGrowLength(length: number) {
    let week = this.basicService.addWeeks(this.growWeek, length);
    this.harvestDate = this.basicService.getFirstDateOfWeek(week);
  }

  public isInYear(year: number): boolean {
    let startWeek = this.getStartWeek();
    if (startWeek == null) {
      return false;
    }
    return this.isInYearWithStart(year, startWeek);
  }

  public growsInYear(year: number): boolean {
    let startWeek = this.growWeek;
    if (startWeek == null) {
      return false;
    }
    return this.isInYearWithStart(year, startWeek);
  }

  protected isInYearWithStart(year: number, startWeek: WeekInYearDTO): boolean {
    let lastWeek = this.lastHarvestWeek;
    if (startWeek.year == year || lastWeek.year == year ||
      (startWeek.year < year && lastWeek.year > year)) {
      return true;
    } else {
      return false;
    }
  }

  public get alternativeDelivery(): boolean {
    return this.recordEO.alternativeDelivery;
  }

  public set alternativeDelivery(value: boolean) {
    this.recordEO.alternativeDelivery = value;
    if (this.alternativeDelivery) {
      this.numOfDeliveryWeeks = this.harvestLength;
    }
    this.hasChanged();
    this.record.calculateQuantities();
  }

  public isAlternativeDeliverySelected(): boolean {
    if (this.alternativeDelivery == true) {
      return true;
    }
    else {
      return false;
    }
  }

  public get extraQuantity(): number {
    return this.recordEO.extraQuantity;
  }
  public set extraQuantity(value: number) {
    this.recordEO.extraQuantity = value;
    this.record.calculateQuantities();
  }

  public get useExtraQuantity(): boolean {
    return this.recordEO.useExtraQuantity;
  }
  public set useExtraQuantity(value: boolean) {
    console.log(value, this.extraQuantity);
    this.recordEO.useExtraQuantity = value;
    if (!value) {
      this.extraQuantity = 0;
    }
    console.log(value, this.extraQuantity);
  }
}