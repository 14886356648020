import { AbstractObject } from '../technical-aspects/abstract-object';
import { BedEO } from '../entity-objects/bed-eo';
import { FilteredRecordSet } from './filtered-record-set';
import { DAORecords } from '../dao/dao-records';
import { Record } from './record';
import { DAOBeds } from '../dao/dao-beds';
import { ToDoListBed } from './todo-list-bed';
import { DAOToDos } from '../dao/dao-todos';


export class Bed extends AbstractObject {

  protected recordSet: FilteredRecordSet;
  public daoRecords: DAORecords;
  public daoBeds: DAOBeds;
  public sumMeters: number = 0;
  public daoToDos: DAOToDos;
  private _todoList: ToDoListBed;
  public selected = false;
  public marked = false;
  public checked = false;


  
  constructor() {
    super();
  }

  update() {
    this.myApplication.addObserver(this);
    this.daoToDos.addObserver(this);
    this.todoList = null;
    this.recordSet = null;
  }

  public isDeleteable(): boolean {
    let allRecords = Array.from(this.daoRecords.objects.values());
    let record: Record;

    for (let i = 0; i < allRecords.length; i++) {
      record = <Record>allRecords[i];
      if (record.bedAssignList.isOnBed(this.id)) {
        return false;
      }
    }
    return true;
  }

  deleteBedAssigns() {
    let records = this.daoRecords.getRecordsForBed(this.id);
    records.forEach(record => {
      record.bedAssignList.removeFromBed(this.id);
    });
  }

  public getNextBedOnArea(): Bed {
    let beds = this.daoBeds.getBedsForArea(this.areaId);
    let i = 0;
    let bed = beds[i];

    while (bed && bed != this) {
      i = i + 1;
      bed = beds[i];
    }
    bed = beds[i + 1];
    return bed;
  }

  public getName(): string {
    if (this.name) {
      return this.name;
    } else {
      return this.orderNum.toString();
    }
  }

  public getPreviousBedOnArea(): Bed {
    let beds = this.daoBeds.getBedsForArea(this.areaId);
    let i = 0;
    let bed = beds[i];

    while (bed && bed != this) {
      i = i + 1;
      bed = beds[i];
    }
    bed = beds[i - 1];
    return bed;
  }

  public getM2() {
    return this.length * this.width;
  }

  protected get bedEO(): BedEO {
    return <BedEO>this.eo;
  }

  public get areaId(): String {
    return this.bedEO.areaId;
  }
  public set areaId(value: String) {
    this.bedEO.areaId = value;
  }

  public get name(): string {
    return this.bedEO.name;
  }
  public set name(value: string) {
    this.bedEO.name = value;
  }

  public get orderNum(): number {
    return this.bedEO.orderNum;
  }
  public set orderNum(value: number) {
    this.bedEO.orderNum = value;
  }

  public get width(): number {
    return this.bedEO.width;
  }
  public set width(value: number) {
    this.bedEO.width = value;
  }

  public get length(): number {
    return this.bedEO.length;
  }
  public set length(value: number) {
    this.bedEO.length = value;
    this.hasChanged();
  }

  public initialize() {
    this.eo = new BedEO();
    this.recordSet = null;
  }

  public getRecordSet(): FilteredRecordSet {
    if (!this.recordSet) {
      let objects = new Array();

      this.daoRecords.objects.forEach(element => {
        let record: Record;
        record = <Record>element;
        if (record.bedAssignList.isOnArea(this.areaId) && record.bedAssignList.isOnBed(this.id)) {

          if (record.recordPeriods.growsInYear(this.myApplication.year)) {

            objects.push(record);
          };
        }
      });

      this.recordSet = new FilteredRecordSet(objects, this.myApplication, this.basicService);
      this.recordSet.objects = objects;
      this.recordSet.completeObjects = objects;
      this.sortRecordSet();
    }

    return this.recordSet;
  }

  public get recordSetProp(): FilteredRecordSet {
    return this.getRecordSet();
  }

  sortRecordSet() {
    this.recordSet.objects = this.recordSet.objects.sort(this.compareOnBed(this));
    this.recordSet.completeObjects = this.recordSet.objects;
  }

  compareOnBed(bed: Bed) {
    return function (one: Record, two: Record) {
      return one.bedAssignList.getAssignment(bed).positionOnBed - two.bedAssignList.getAssignment(bed).positionOnBed;
    }
  }

  protected getSumMeters(): number {
    let sum = 0;
    this.recordSet.objects.forEach(element => {
      sum = sum + element.lengthOnBed;
    });
    return sum;
  }

  public calculateStartOnBed() {

  }

  public buildRecordSet() {

    if (this.recordSet) {
      let records: Array<Record> = new Array;

      this.daoRecords.objects.forEach(element => {
        let record: Record;
        record = <Record>element;

        if (record.bedAssignList.isOnArea(this.areaId) && record.bedAssignList.isOnBed(this.id)) {
          records.push(record);
        }
      });

      this.recordSet.replaceObjects(records);
    }
    else {
      this.getRecordSet();
    }

    this.hasChanged();
  }

  public getNumberOfRecordsForUI() {
    let numberOfRecords = this.getRecordSet().objects.length;

    // if ( numberOfRecords == 0) {
    //   numberOfRecords = 1;
    // }

    return numberOfRecords;
  }

  public get todoList(): ToDoListBed {
    if (!this._todoList) {
      this._todoList = new ToDoListBed(this.daoToDos, this, this.daoBeds);

      this._todoList.toDos = this._todoList.toDos.sort(function (one, two) {
        let compare = 0;
        if (one.dueDate > two.dueDate) {
          compare = 1;
        } else if (one.dueDate < two.dueDate) {
          compare = -1
        } else {
          compare = 0;
        }

        return compare;
      });
    }
    return this._todoList;
  }
  public set todoList(value: ToDoListBed) {
    this._todoList = value;
  }

}