import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';

export class BedEO extends AbstractEntityObject{

    public areaId: String;
    public orderNum: number;
    public width: number;
    public length: number;
    public name: string;
    

    constructor() {
        super();
        this.areaId = '';
        this.orderNum = 1;
        this.width = 1;
        this.length = 1;
        this.name = '';
    }
  }