import * as tslib_1 from "tslib";
import { ToDoList } from './todo-list';
import { Constants } from './constants';
var ToDoListArea = /** @class */ (function (_super) {
    tslib_1.__extends(ToDoListArea, _super);
    function ToDoListArea(daoToDos, area) {
        var _this = _super.call(this, daoToDos) || this;
        _this.daoToDos = daoToDos;
        _this.area = area;
        _this.daoToDos.objects.forEach(function (element) {
            var todo = element;
            if (todo.parentType == Constants.PARENT_TYPE_AREA) {
                if (todo.parentId == _this.area.id) {
                    if (!todo.bedIdStart) {
                        if (todo.isSeriesMaster() == false) {
                            _this.toDos.push(todo);
                        }
                    }
                }
            }
        });
        return _this;
    }
    ToDoListArea.prototype.update = function () {
        var _this = this;
        this.toDos = new Array();
        this.daoToDos.objects.forEach(function (element) {
            var todo = element;
            if (todo.parentType == Constants.PARENT_TYPE_AREA) {
                if (todo.parentId == _this.area.id) {
                    if (!todo.bedIdStart) {
                        if (todo.isSeriesMaster() == false) {
                            _this.toDos.push(todo);
                        }
                    }
                }
            }
        });
        this.toDos = this.toDos.sort(function (one, two) {
            var compare = one.dueDate.getTime() - (two.dueDate.getTime());
            return compare;
        });
    };
    return ToDoListArea;
}(ToDoList));
export { ToDoListArea };
