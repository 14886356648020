import { DAO } from '../technical-aspects/dao';
import { ToDo } from '../business-logic/todo';
import { DAORecords } from './dao-records';
import { DAOPersons } from './dao-persons';
import { DAOBeds } from './dao-beds';
import { DAOAreas } from './dao-areas';
import { FilteredToDoList } from '../business-logic/filtered-todo-list';
import { AbstractObject } from '../technical-aspects/abstract-object';

export class DAOToDos extends DAO {

    private _objectSet: FilteredToDoList = new FilteredToDoList(<Array<ToDo>>Array.from(this.objects.values()));
    public selectedToDo: ToDo;
    public allTypes = new Array<String>();


    public isSelected(todo: ToDo): boolean {
        if (todo == this.selectedToDo) {
            return true;
        } else {
            return false;
        }
    }

    public get objectSet(): FilteredToDoList {
        return this._objectSet;
    }

    public set objectSet(value: FilteredToDoList) {
        this._objectSet = value;
    }

    private buildAllTypes() {
        this.allTypes = new Array<String>();
        this.objects.forEach(todo => {
            this.allTypes.push((<ToDo>todo).type);
        });
        this.allTypes.sort();

        let tmp = new Array<String>();
        let lastValue = null;
        for (let i = 0; i < this.allTypes.length; i++) {
            if (lastValue != this.allTypes[i] || i == 0) {
                tmp.push(this.allTypes[i]);
                lastValue = this.allTypes[i];
            }
        }
        this.allTypes = tmp;
    }

    protected createInstance() {
        let newInstance = new ToDo();
        newInstance.daoRecords = <DAORecords>this.daoService.getDAO('records');
        newInstance.daoPersons = <DAOPersons>this.daoService.getDAO('persons');
        newInstance.daoBeds = <DAOBeds>this.daoService.getDAO('beds');
        newInstance.daoAreas = <DAOAreas>this.daoService.getDAO('areas');
        newInstance.daoToDos = <DAOToDos>this.daoService.getDAO('todos');
        return newInstance;
    }

    public createNewInstance(): AbstractObject {
        let object = <ToDo>super.createNewInstance();
        this.updateLocal();
        return object;
    }

    public assembleObjects() {
        super.assembleObjects();
        this.updateLocal();
    }

    public deleteObject(object: AbstractObject) {
        super.deleteObject(object);
        this.updateLocal();
    }

    protected updateLocal() {
        this._objectSet.replaceObjects(<ToDo[]>Array.from(this.objects.values()));
        this.buildAllTypes();
    }
}