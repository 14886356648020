import * as tslib_1 from "tslib";
import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';
var PartsEO = /** @class */ (function (_super) {
    tslib_1.__extends(PartsEO, _super);
    function PartsEO() {
        var _this = _super.call(this) || this;
        _this.startWeek = 0;
        _this.endWeek = 0;
        _this.startYear = 0;
        _this.endYear = 0;
        _this.parts = 0;
        return _this;
    }
    return PartsEO;
}(AbstractEntityObject));
export { PartsEO };
