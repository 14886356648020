import { Injectable } from '@angular/core';
import { Observable } from '../app-core/technical-aspects/observable';
import { GreenThemeDTO } from '../app-core/dto/green-theme-dto';
import { ThemeDTO } from '../app-core/dto/theme-dto';
import { MessageDTO } from '../app-core/dto/message-dto';
import { FieldTextDTO } from '../app-core/dto/field-text-dto';
import { BasicService } from './basic.service';
import { DaoService } from './dao.service';
import { MonthDTO } from '../app-core/dto/month-dto';
import { EventHandler } from '../app-core/technical-aspects/event-handler';
import { ToastrService } from 'ngx-toastr';
import { RequestOptions, Headers, Http } from '@angular/http';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ApplicationService extends Observable {

  private _year: number;
  public yearDate: Date;
  public theme: ThemeDTO;
  public messageDTO: MessageDTO;
  public textDTOs = new Map<String, FieldTextDTO>();
  public currentKW: number;
  //public repository: Repository;
  public loading = true;
  protected daoService: DaoService;
  public eventHandler = new EventHandler();

  public availableLanguages = new Array<string>();
  public selectedLanguage = 'de';

  public version = '2.4.5';
  public splitScreen = false;
  public showSplitScreenBtn = false;
  public showYearChoose = true;
  public countries;

  public legalAgreeShown = false;
  public showWizardOnCloseAgree = false;


  constructor(private http: Http, protected basicService: BasicService, toastr: ToastrService, private cookieService: CookieService) {

    super();

    if (this.cookieService) {
      this.selectedLanguage = this.cookieService.get('Gemuese-Anbauplaner.language');
    }
    if (!this.selectedLanguage || this.selectedLanguage == '') {
      this.selectedLanguage = 'de';
    }

    this.getAvailableLanguages();
    this.readCountries();

    this.yearDate = new Date();
    this.year = this.yearDate.getFullYear();
    this.currentKW = basicService.getWeek(this.yearDate).week;

    this.theme = new GreenThemeDTO();
    this.messageDTO = new MessageDTO(this, toastr);
  }


  protected getAvailableLanguages() { //Liefert eine Liste aller bereits angelegten Sprachen

    this.availableLanguages = new Array<string>();
    this.availableLanguages.push('de');
    this.availableLanguages.push('en');
    this.availableLanguages.push('es');
    this.availableLanguages.push('fr');
    this.availableLanguages.push('it');
  }

  public save() {
    this.daoService.save();
  }

  setDAOService(daoService: DaoService) {
    this.daoService = daoService;
  }

  public readCountries() {
    if (!this.http) {
      return;
    }

    this.http.get("./assets/i18n/countries.json").pipe(
      map(resp => resp.json())
    )
      .subscribe(
        data => {
          this.countries = data;
        });
  }

  public getCountry(name) {
    for (let i = 0; i < this.countries.length; i++) {
      if (this.countries[i].name == name) {
        return this.countries[i];
      }
    }
    return null;
  }

  public readTexts() {
    return new Promise<void>((resolve, reject) => {
      this.http.get("./assets/i18n/" + this.selectedLanguage + environment.buildTS + ".json").pipe(
        map(resp => resp.json())
      )
        .subscribe(
          data => {
            this.textDTOs = new Map<String, FieldTextDTO>();

            let outJson: any = [];
            let backendJson: any = new Object();
            data.sort(function (one, two) {
              let id1 = Object.keys(one)[0];
              let id2 = Object.keys(two)[0];
              let compare = id1.localeCompare(id2);
              if (compare == 0) {
                return two[Object.keys(two)[0]].text.localeCompare(one[Object.keys(one)[0]].text);
              }
              return compare;
            });

            // let dataClean = [];
            let numBefore = 0;

            let before = '';
            for (let i = 0; i < data.length; i++) {

              let obj: any = new Object();
              obj[Object.keys(data[i])[0]] = data[i][Object.keys(data[i])[0]];

              if (before == Object.keys(data[i])[0]) {
                console.log('double entry: ', before);
                numBefore += 1;

                // if (obj[Object.keys(data[i])[0]]["text"] == '' && obj[Object.keys(data[i])[0]]["help"] == '') {
                //   //console.log('empty');
                // } else {
                // dataClean.push(obj);
                // }
                // } else {
                // dataClean.push(obj);
              }

              before = Object.keys(data[i])[0];

              // if (Object.keys(data[i])[1] != null) {
              //   numDouble += 1;

              //   let obj: any = new Object();
              //   obj[Object.keys(data[i])[1]] = data[i][Object.keys(data[i])[1]];
              //   dataClean.push(obj);
              // }
            }

            console.log('Doubles: ', numBefore);
            // dataClean.sort(function(one, two) {
            //   let id1 = Object.keys(one)[0];
            //   let id2 = Object.keys(two)[0];
            //   return id1.localeCompare(id2);
            // });
            // console.log(dataClean);

            data.forEach(element => {
              let id = Object.keys(element)[0];
              let text = element[id].text;
              let help = element[id].help;

              this.textDTOs.set(id, new FieldTextDTO(id, text, help));
              let newJsonObject = { "id": id, "text": text, "help": help };
              outJson.push(newJsonObject);

              // backendJson[id + ".text"] = text;
              // backendJson[id + ".help"] = help;
            });

            // console.log(JSON.stringify(backendJson));

            localStorage.removeItem('Gemuese-Anbauplaner.texts');
            localStorage.setItem('Gemuese-Anbauplaner.langu', this.selectedLanguage);

            this.eventHandler.raiseEvent(EventHandler.LANGUAGE_CHANGED);

            this.loading = false;
            resolve();
          });
    });
  }

  changeLanguage(newLanguage: string) {
    this.selectedLanguage = newLanguage;
    this.cookieService.set('Gemuese-Anbauplaner.language', this.selectedLanguage, 31);
    this.readTexts();
  }

  public getHelpText(id: String): String {
    let textDTO = this.textDTOs.get(id);
    if (textDTO) {
      return textDTO.help;
    }
    else {
      console.log('Help ' + id + ' fehlt.');
      return null;
    }
  }

  public getText(id: String, parameter1?: any, parameter2?: any, parameter3?: any, parameter4?: any, parameter5?: any, parameter6?: any,
    parameter7?: any, parameter8?: any): String {
    let textDTO = this.textDTOs.get(id);
    if (!textDTO) {
      console.log('Text ' + id + ' fehlt.');
      return null;
    }

    let text = this.textDTOs.get(id).text;
    text = text.replace('@p1', parameter1);
    text = text.replace('@p2', parameter2);
    text = text.replace('@p3', parameter3);
    text = text.replace('@p4', parameter4);
    text = text.replace('@p5', parameter5);
    text = text.replace('@p6', parameter6);
    text = text.replace('@p7', parameter7);
    text = text.replace('@p8', parameter8);
    return text;
  }

  public getTextSimple(id: String, parameter1?: any, parameter2?: any): string {
    return this.getText(id, parameter1, parameter2).toString();
  }

  public get year(): number {
    return this._year;
  }
  public set year(value: number) {
    this._year = value;
    this.update();
    this.eventHandler.raiseEvent(EventHandler.APP_YEAR_CHANGED);
    this.hasChanged();
  }

  public setYearExternal(year: number) { //keine Listener-Benachrichtigung
    this._year = year;
  }

  protected update() {
    this.yearDate.setFullYear(this.year);
  }

  nextYear() {
    let newYear = Number.parseInt(this.year.toString());
    this.year = newYear + 1;
  }

  previousYear() {
    let newYear = Number.parseInt(this.year.toString());
    if (newYear > 2018) {
      this.year = newYear - 1;
    }
  }

  getMonthNames(): string[] {
    let monthNames = [this.getTextSimple('Januar'),
    this.getTextSimple('Februar'),
    this.getTextSimple('März'),
    this.getTextSimple('April'),
    this.getTextSimple('Mai'),
    this.getTextSimple('Juni'),
    this.getTextSimple('Juli'),
    this.getTextSimple('August'),
    this.getTextSimple('September'),
    this.getTextSimple('Oktober'),
    this.getTextSimple('November'),
    this.getTextSimple('Dezember')];
    return monthNames;
  }

  getWeekDayNames(): string[] {
    let names = [this.getTextSimple('Montag'),
    this.getTextSimple('Dienstag'),
    this.getTextSimple('Mittwoch'),
    this.getTextSimple('Donnerstag'),
    this.getTextSimple('Freitag'),
    this.getTextSimple('Samstag'),
    this.getTextSimple('Sonntag')];
    return names;
  }

  getMonths(year: number): Array<MonthDTO> {
    let periods = new Array<MonthDTO>();
    let period: MonthDTO;
    let lastWeekOfMonth;
    let lastDateOfMonth;
    let firstDateOfMonth;
    const month = this.getMonthNames();
    let weeksBefore = 0;

    for (let i = 0; i < 12; i++) {
      lastDateOfMonth = this.basicService.getLastDateOfMonth(i, year);
      firstDateOfMonth = this.basicService.getFirstDateOfMonth(i, year);
      lastWeekOfMonth = this.basicService.getWeek(lastDateOfMonth);

      period = new MonthDTO(this.getTextSimple(month[i]), 0);
      period.lastWeek = lastWeekOfMonth;
      period.firstWeek = this.basicService.getWeek(firstDateOfMonth);

      if (i == 11) {
        let weeksMax = this.basicService.getNumWeeks(year);
        period.duration = weeksMax - weeksBefore;
      } else {
        if (this.basicService.getWeekDay(lastDateOfMonth) >= 3) {
          period.duration = lastWeekOfMonth.week - weeksBefore;
        } else {
          period.duration = lastWeekOfMonth.week - weeksBefore - 1;
        }
      }
      weeksBefore = weeksBefore + period.duration;

      periods.push(period);
    }
    return periods;
  }
}
