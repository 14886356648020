import * as tslib_1 from "tslib";
import { Observable } from './observable';
var AbstractObject = /** @class */ (function (_super) {
    tslib_1.__extends(AbstractObject, _super);
    function AbstractObject() {
        var _this = _super.call(this) || this;
        _this.isNew = false;
        return _this;
    }
    Object.defineProperty(AbstractObject.prototype, "id", {
        get: function () {
            return this.eo.id;
        },
        set: function (value) {
            this.eo.id = value;
        },
        enumerable: true,
        configurable: true
    });
    AbstractObject.prototype.setDefaultValues = function () {
    };
    AbstractObject.prototype.initDone = function () {
    };
    AbstractObject.prototype.toJSON = function () {
        var _this = this;
        var attributeNames = Object.getOwnPropertyNames(this);
        var attName;
        var obj = new Object();
        attributeNames.forEach(function (attribute) {
            var attName = attribute;
            if (attName.substr(0, 1) == '_') {
                attName = attName.substr(1);
            }
            obj[attName] = _this[attribute];
        });
        return obj;
    };
    AbstractObject.prototype.hasDataChanged = function () {
        var attributeNames = Object.getOwnPropertyNames(this.eo);
        var attName;
        for (var i = 0; i < attributeNames.length; i++) {
            attName = attributeNames[i];
            if (attName != 'db') {
                if (attName.includes("Date") && this.eo[attName].getTime() != this.eo.db[attName].getTime()) {
                    // console.log(attName);
                    return true;
                }
                else if (!attName.includes("Date") && this.eo[attName] != this.eo.db[attName]) {
                    // console.log(attName);
                    return true;
                }
            }
        }
        return false;
    };
    AbstractObject.prototype.resetDBState = function () {
        this.eo.db = this.eo.clone();
    };
    AbstractObject.prototype.fromJSON = function (data) {
    };
    return AbstractObject;
}(Observable));
export { AbstractObject };
