import * as tslib_1 from "tslib";
import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';
var AreaEO = /** @class */ (function (_super) {
    tslib_1.__extends(AreaEO, _super);
    function AreaEO() {
        var _this = _super.call(this) || this;
        _this.name = '';
        _this.description = '';
        _this.type = 'F';
        _this.pathWidth = 0;
        return _this;
    }
    return AreaEO;
}(AbstractEntityObject));
export { AreaEO };
