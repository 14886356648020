import { DAO } from '../technical-aspects/dao';
import { Repository } from '../business-logic/repository';

export class DAORepository extends DAO {

    protected createInstance() {
        let newInstance = new Repository();
        return newInstance;
    }

    public getRepository(): Repository {
        let repository = <Repository> this.getObjectById(this.authenticationService.loginData.id);
        return repository;
    }
}