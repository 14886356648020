import { AbstractObject } from '../technical-aspects/abstract-object';
import { FeedbackEO } from '../entity-objects/feedback-eo';


export class Feedback extends AbstractObject {

  constructor() {
    super();
  }

  update() {
  }

  protected get feedbackEO(): FeedbackEO {
    return <FeedbackEO>this.eo;
  }

  public get uname(): String {
    return this.feedbackEO.uname;
  }
  public set uname(value: String) {
    this.feedbackEO.uname = value;
  }

  public get view(): String {
    return this.feedbackEO.view;
  }
  public set view(value: String) {
    this.feedbackEO.view = value;
  }

  public get comment(): String {
    return this.feedbackEO.comment;
  }
  public set comment(value: String) {
    this.feedbackEO.comment = value;
  }

  initialize() {
    this.eo = new FeedbackEO();
  }
}