<div class="modal fade" [id]="id" role="dialog" aria-labelledby="confirmModalLabel" aria-hidden="false">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 *ngIf="ticketDTO.category == 'Bug' && ticketDTO.priority == 'hoch'" class="modal-title" style="color:red;"
          id="confirmModalLabel">
          <fa name="bug"></fa> {{ticketDTO.category}} einstellen
        </h5>
        <h5 *ngIf="ticketDTO.category != 'Bug' || ticketDTO.priority != 'hoch'" class="modal-title"
          id="confirmModalLabel">
          <fa name="bug"></fa> {{ticketDTO.category}} einstellen
        </h5>
      </div>
      <div class="modal-body">

        <P>
        <div class="row">
          <div class="col-sm-4">
            <select class="form-control form-control-sm mb-3" [(ngModel)]="ticketDTO.category">
              <ng-container *ngFor="let keyValue of categories">
                <option *ngIf="!keyValue.disabled" [value]="keyValue.key">{{ keyValue.value }}</option>
                <option *ngIf="keyValue.disabled" [value]="keyValue.key" disabled>{{ keyValue.value }}</option>
              </ng-container>
            </select>
          </div>
          <div class="col-sm-4">
            <select class="form-control form-control-sm mb-3" [(ngModel)]="ticketDTO.priority">
              <ng-container *ngFor="let keyValue of priorities">
                <option *ngIf="!keyValue.disabled" [value]="keyValue.key">{{ keyValue.value }}</option>
                <option *ngIf="keyValue.disabled" [value]="keyValue.key" disabled>{{ keyValue.value }}</option>
              </ng-container>
            </select>
          </div>
          <div class="col-sm-4">
            <select class="form-control form-control-sm mb-3" [(ngModel)]="ticketDTO.release">
              <ng-container *ngFor="let keyValue of releases">
                <option *ngIf="!keyValue.disabled" [value]="keyValue.key">{{ keyValue.value }}</option>
                <option *ngIf="keyValue.disabled" [value]="keyValue.key" disabled>{{ keyValue.value }}</option>
              </ng-container>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4">
            <select class="form-control form-control-sm mb-3" [(ngModel)]="ticketDTO.partComponents">
              <option value="Anbaukalender">Anbaukalender</option>
              <option value="Kulturen">Kulturen</option>
              <option value="Sätze">Sätze</option>
              <option value="Satzanlage">Satzanlage</option>
              <option value="Statistik">Statistik</option>
              <option value="Saatgut">Saatgut</option>
              <option value="Kunden">Kunden</option>
              <option value="Ernte">Ernte</option>
              <option value="Ernte">ErnteV2</option>
              <option value="Lieferung">Lager</option>
              <option value="Lieferung">Lieferung</option>
              <option value="Lieferung">LieferungV2</option>  
              <option value="Lieferung">Lieferplanung</option>
              <option value="Einstellungen">Einstellungen</option>
              <option value="Aufgaben">Aufgaben</option>
              <option value="Aufgaben-Vorlagen">Aufgaben-Vorlagen</option>
              <option value="Aufgaben-Kalender">Aufgaben-Kalender</option>
              <option value="Admin">Admin</option>
              <option value="Doku">Doku</option>
            </select>
          </div>
          <div class="col-sm-4">
            <select class="form-control form-control-sm mb-3" [(ngModel)]="ticketDTO.reporter">
              <option value="Urs">Urs</option>
              <option value="Philip">Philip</option>
              <option value="Yasmin">Yasmin</option>
              <option value="Lawrence">Jan</option>
              <option value="Lawrence">Elmar</option>
              <option value="Lawrence">Lawrence</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <input class="form-control form-control-sm" type="text"
              [(ngModel)]="ticketDTO.summary" id="Bug.summary" placeholder="Zusammenfassung (Titel)">
          </div>
        </div>

        <textarea class="form-control form-control-sm my-3 bg-white" [(ngModel)]="ticketDTO.description" rows="15"
          placeholder="Was läuft falsch? Wie sollte es laufen?" #name="ngModel"></textarea>

      </div>
      <div class="modal-footer">
        <app-button [iconName]="'bug'" [id]="'Feedback.send'" [callback]="'send'" [callbackOwner]="this"
          [dataDismiss]="'modal'">
        </app-button>

        <app-button [iconName]="'times'" [id]="'Confirm.no'" [callback]="'noAction'" [callbackOwner]="this"
          [dataDismiss]="'modal'">
        </app-button>
      </div>
    </div>
  </div>
</div>