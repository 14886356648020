import { OnInit } from '@angular/core';
import { ApplicationService } from 'src/app/services/application.service';
import { FieldTextDTO } from 'src/app/app-core/dto/field-text-dto';
var NumberUnitFieldComponent = /** @class */ (function () {
    function NumberUnitFieldComponent(myApplication) {
        this.myApplication = myApplication;
        this.colsLabel = 'col-sm-4';
        this.colsField = 'col-sm-8';
        this.step = 1;
        this.min = 0;
        this.useWarningClass = false;
        this.usePrimaryClass = false;
        this.warningValue = 0;
        this._disable = false;
    }
    Object.defineProperty(NumberUnitFieldComponent.prototype, "disable", {
        get: function () {
            return this._disable;
        },
        set: function (value) {
            this._disable = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NumberUnitFieldComponent.prototype, "value", {
        get: function () {
            if (!this.object[this.valueName.toString()]) {
                return '0';
            }
            var value = this.object[this.valueName.toString()].toString();
            value = value.replace('.', ',');
            return value;
        },
        set: function (value) {
            value = value.replace(new RegExp(',', 'g'), '.');
            var num = Number(value);
            if ((!isNaN(num)) && num >= this.min) {
                this.object[this.valueName.toString()] = num;
            }
        },
        enumerable: true,
        configurable: true
    });
    NumberUnitFieldComponent.prototype.onBlur = function (event) {
        event.target.value = this.value;
    };
    NumberUnitFieldComponent.prototype.getWarningClass = function () {
        if (this.usePrimaryClass) {
            return 'border-primary';
        }
        else {
            if (this.useWarningClass && this.object[this.valueName.toString()] == this.warningValue) {
                return 'border-warning';
            }
        }
    };
    NumberUnitFieldComponent.prototype.keyup = function (event) {
        if (event.key == ',') {
            event.key = '.';
            this.object[this.valueName.toString()] += event.target.value + '.';
        }
    };
    NumberUnitFieldComponent.prototype.ngOnInit = function () {
        if (!this.textId) {
            this.textId = this.id;
        }
        if (!this.id) {
            this.id = this.valueName;
        }
        this.fieldDTO = this.myApplication.textDTOs.get(this.textId);
        if (!this.fieldDTO) {
            this.fieldDTO = new FieldTextDTO(this.textId, this.textId, '');
        }
        this.btnDTO = this.myApplication.textDTOs.get(this.textId + '.btnTooltip');
        if (!this.btnClass) {
            this.btnClass = this.myApplication.theme.btnOutline;
        }
    };
    NumberUnitFieldComponent.prototype.onClick = function () {
        if (this.btnObject) {
            this.btnObject[this.btnMethod.toString()]();
        }
        else {
            this.object[this.btnMethod.toString()]();
        }
    };
    return NumberUnitFieldComponent;
}());
export { NumberUnitFieldComponent };
