import { Injectable } from '@angular/core';
import { Http, RequestOptions } from '@angular/http';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import { map, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  public permissions;
  public ready: boolean = false;

  constructor(private http: Http, private authentificationService: AuthenticationService) {

  }

  readPermissions() {
    return new Promise((resolve, reject) => {
      const options = new RequestOptions();
      options.headers = this.authentificationService.getHeadersAuthenticated();
      this.http.get(environment.apiV2 + 'userPermissions', options).pipe(
        retry(3),
        map(resp => resp.json())
      )
        .subscribe(
          res => {
            this.permissions = res;
            this.ready = true;
            resolve(null);
          });
    });
  }

  checkPermissionForAction(myObject, myAction, myUnderObject): boolean {

    let result: boolean = false;

    this.permissions.forEach(object => {
      if (object.name == myObject) {
        if (myUnderObject) {
          object.objects.forEach(underObject => {
            if (underObject.name == myUnderObject) {
              underObject.actions.forEach(action => {
                if (action.name == myAction) {
                  if (action.value) {
                    result = true;
                  }
                }
              });
            }
          });
        } else {
          object.actions.forEach(action => {
            if (action.name == myAction) {
              if (action.value) {
                result = true;
              }
            }

          });
        }
      }
    });
    return result;
  }


}
