import { Observable } from './observable';
import { AbstractEntityObject } from './abstract-entity-object';
import { Listener } from './listener';
import { ApplicationService } from 'src/app/services/application.service';
import { BasicService } from 'src/app/services/basic.service';
import { RepositoryService } from 'src/app/services/repository.service';


export abstract class AbstractObject extends Observable implements Listener {

    public eo: AbstractEntityObject;
    public myApplication: ApplicationService;
    public basicService: BasicService;
    public repoService: RepositoryService;
    public isNew = false;
    

    constructor() {
        super();
    }

    abstract update();

    abstract initialize();

    public get id(): String {
        return this.eo.id;
    }
    public set id(value: String) {
        this.eo.id = value;
    }

    setDefaultValues() {
    }

    public initDone() {

    }

    toJSON() {
        let attributeNames = Object.getOwnPropertyNames(this);
        let attName;

        let obj = new Object();

        attributeNames.forEach(attribute => {
            let attName = attribute;
            if (attName.substr(0, 1) == '_') {
                attName = attName.substr(1);
            }
            obj[attName] = this[attribute];
        });

        return obj;
    }

    public hasDataChanged(): boolean {
        let attributeNames = Object.getOwnPropertyNames(this.eo);
        let attName;

        for (let i = 0; i < attributeNames.length; i++) {
            attName = attributeNames[i];
            if (attName != 'db') {
                if (attName.includes("Date") && this.eo[attName].getTime() != this.eo.db[attName].getTime()) {
                    // console.log(attName);
                    return true;
                } else if (!attName.includes("Date") && this.eo[attName] != this.eo.db[attName]) {
                    // console.log(attName);
                    return true;
                }
            }
        }

        return false;
    }

    resetDBState() {
        this.eo.db = this.eo.clone();
    }

    fromJSON(data: any) {

    }
}