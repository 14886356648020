import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';
import { Constants } from '../business-logic/constants';


export class ToDoEO extends AbstractEntityObject {

    public toDoType: String;
    public cultureDatType: String;
    public type: String;

    public name: String;
    public quantity: number;
    public quantityUnit: String;

    public time: number;
    public timeUnit: String;
    public timeType: String;
    public timeReference: String;
    public dueDate: Date;
    public person: String;
    public color: String;
    public state: String;

    public note: String;
    public parentType: String;
    public parentId: String;    

    public seriesMaster: String;
    public seriesIntervall: number;
    public seriesIntervallUnit: String;

    public endTime: number;
    public endTimeUnit: String;
    public endTimeType: String;
    public endTimeReference: String;
    public endDueDate: Date;
    public areaId: String;
    public bedIdStart: String;
    public bedIdEnd: String;

    public neededTime: number;


    constructor() {
        super();
        this.toDoType = Constants.TODO_STANDALONE;
        this.cultureDatType = '';
        this.type = '';        

        this.name = '';
        this.quantity = 0;
        this.quantityUnit = 'a';

        this.time = 0;
        this.timeUnit = 'Tage';
        this.timeType = 'n';
        this.timeReference = Constants.TEMPLATE_TIME_REF_PFLANZUNG;

        this.dueDate = new Date();
        this.person = '';
        this.color = '';
        this.state = '';

        this.note = '';
        this.parentType = '';
        this.parentId = '';

        this.seriesMaster = '';
        this.seriesIntervall = 0;
        this.seriesIntervallUnit = 'T';

        this.endTime = 0;
        this.endTimeUnit = 'Tage';
        this.endTimeType = 'v';
        this.endTimeReference = Constants.TEMPLATE_TIME_REF_ERNTE;
        this.endDueDate = new Date();

        this.bedIdStart = '';
        this.bedIdEnd = '';

        this.neededTime = 0;
    }
}