import { AbstractObject } from '../technical-aspects/abstract-object';
import { SortEO } from '../entity-objects/sort-eo';
import { Culture } from './culture';
import { DAOCultures } from '../dao/dao-cultures';
import { DAOSeeds } from '../dao/dao-seeds';
import { DAOSortSeeds } from '../dao/dao-sort-seeds';
import { SortSeedSet } from './sort-seed-set';
import { SeedSet } from './seed-set';

export class Sort extends AbstractObject {

    public daoCultures: DAOCultures;
    public daoSeeds: DAOSeeds;
    public daoSortSeeds: DAOSortSeeds;
    private _culture: Culture;
    private _sortSeedSet: SortSeedSet;
    private _seedSet: SeedSet;


    constructor() {
        super();
    }

    update() {

    }

    deleteChildren() {
        this.seedSet.seeds.forEach(element => {
            this.daoSeeds.deleteObject(element);
        });
        this.sortSeedSet.sortSeeds.forEach(element => {
            this.daoSortSeeds.deleteObject(element);
        });
    }

    public get plantDistance() {
        return this.sortEO.plantDistance;
    }
    public set plantDistance(value) {
        this.sortEO.plantDistance = value;
    }

    public get rowDistance() {
        return this.sortEO.rowDistance;
    }
    public set rowDistance(value) {
        this.sortEO.rowDistance = value;
    }

    public get numRows() {
        return this.sortEO.numRows;
    }
    public set numRows(value) {
        this.sortEO.numRows = value;
    }

    public get seedSet(): SeedSet {
        if (!this._seedSet) {
            this._seedSet = new SeedSet(this.daoSeeds, this.daoSortSeeds, this.id, this.myApplication);
            this._seedSet.otherUnit = this.useOtherSeedUnit;
        }
        return this._seedSet;
    }
    public set seedSet(value: SeedSet) {
        this._seedSet = value;
    }

    public get sortSeedSet(): SortSeedSet {
        if (!this._sortSeedSet) {
            this._sortSeedSet = new SortSeedSet(this.daoSortSeeds, this.id, this.myApplication);
            //Kennzeichen ob TKG oder andere Einheit weitergeben
            this._sortSeedSet.otherUnit = this.useOtherSeedUnit;
        }
        return this._sortSeedSet;
    }
    public set sortSeedSet(value: SortSeedSet) {
        this._sortSeedSet = value;
    }

    public isDeleteable() {
        if (this.sortSeedSet.getCompleteLength() > 0) {
            return false;
        } else {
            return true;
        }
    }

    public get culture(): Culture {
        if (!this._culture) {
            this.culture = <Culture>this.daoCultures.getObjectById(this.cultureId);
        }
        return this._culture;
    }
    public set culture(value: Culture) {
        this._culture = value;
    }

    initialize() {
        this.eo = new SortEO();
    }

    public getFullName(): String {
        return this.culture.name + ' ' + this.name;
    }

    setDefaultValues() {
        //this.name = '<' + this.myApplication.getText('newSort') + '>';
    }

    protected get sortEO(): SortEO {
        return <SortEO>this.eo;
    }

    public get name(): String {
        return this.sortEO.name;
    }
    public set name(value) {
        this.sortEO.name = value;
    }

    public get cultureId(): String {
        return this.sortEO.cultureId;
    }
    public set cultureId(value: String) {
        this.sortEO.cultureId = value;
        this.culture = <Culture>this.daoCultures.getObjectById(this.cultureId);
    }

    public get useOwnPeriods(): boolean {
        return this.sortEO.useOwnPeriods;
    }
    public set useOwnPeriods(value: boolean) {
        if (value != this.sortEO.useOwnPeriods) {
            this.sortEO.useOwnPeriods = value;

            if (this.sortEO.useOwnPeriods) {
                this.seed = this.culture.seed;
                this.grow = this.culture.grow;
                this.harvest = this.culture.harvest;
            }
        }
    }

    public get note() {
        return this.sortEO.note;
    }
    public set note(value) {
        this.sortEO.note = value;
    }

    public get useOwnDistances(): boolean {
        return this.sortEO.useOwnDistances;
    }
    public set useOwnDistances(value: boolean) {
        if (value != this.sortEO.useOwnDistances) {
            this.sortEO.useOwnDistances = value;

            if (this.sortEO.useOwnDistances) {
                this.plantDistance = this.culture.plantDistance;
                this.rowDistance = this.culture.rowDistance;
                this.numRows = this.culture.numRows;
            }
        }
    }

    public get seed() {
        return this.sortEO.seed;
    }
    public set seed(value) {
        if (value != this.sortEO.seed) {
            this.sortEO.seed = value;
            this.hasChanged();
        }
    }

    public get grow() {
        return this.sortEO.grow;
    }
    public set grow(value) {
        if (value != this.sortEO.grow) {
            this.sortEO.grow = value;
            this.hasChanged();
        }
    }

    public get harvest() {
        return this.sortEO.harvest;
    }
    public set harvest(value) {
        if (value != this.sortEO.harvest) {
            this.sortEO.harvest = value;
            this.hasChanged();
        }
    }

    public get seedRate(): number {
        return this.sortEO.seedRate;
    }
    public set seedRate(value: number) {
        this.sortEO.seedRate = value;
    }

    public get useOwnDetails(): boolean {
        return this.sortEO.useOwnDetails;
    }
    public set useOwnDetails(value: boolean) {
        if (value != this.sortEO.useOwnDetails) {
            this.sortEO.useOwnDetails = value;

            if (this.sortEO.useOwnDetails) {
                this.seedRate = this.culture.seedRate;
            }
        }
    }

    public get useOtherSeedUnit(): boolean {
        return this.sortEO.useOtherSeedUnit;
    }
    public set useOtherSeedUnit(value: boolean) {
        this.sortEO.useOtherSeedUnit = value;
    }

    public checkIfTkgIsUsed(): boolean {
        let isUsed: boolean = false;
        this.sortSeedSet.sortSeeds.forEach(sortSeed => {
            if (!sortSeed.otherUnit) {
                isUsed = true;
            }
        });
        return isUsed;
    }

    public checkIfOtherUnitIsUsed(): boolean {
        let isUsed: boolean = false;
        this.sortSeedSet.sortSeeds.forEach(sortSeed => {
            if (sortSeed.otherUnit == true) {
                isUsed = true;
            }
        });
        return isUsed;
    }
}