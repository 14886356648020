import * as tslib_1 from "tslib";
import { Observable } from './observable';
import { RequestOptions } from '@angular/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { SaveInsert } from './save-insert';
import { SaveUpdate } from './save-update';
import { SaveDelete } from './save-delete';
import { isBoolean } from 'util';
var DAO = /** @class */ (function (_super) {
    tslib_1.__extends(DAO, _super);
    function DAO(http, authenticationService, name, myApplication, daoService, basicService) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.authenticationService = authenticationService;
        _this.name = name;
        _this.myApplication = myApplication;
        _this.daoService = daoService;
        _this.basicService = basicService;
        _this.objects = new Map();
        _this.newObjects = new Map();
        _this.changedObjects = new Map();
        _this.deletedObjects = new Map();
        return _this;
    }
    DAO.prototype.isSelected = function (object) {
        if (object == this.selectedObject) {
            return true;
        }
        else {
            return false;
        }
    };
    DAO.prototype.readData = function () {
        this.objects = new Map();
        this.newObjects = new Map();
        this.changedObjects = new Map();
        this.deletedObjects = new Map();
        return this.read();
    };
    DAO.prototype.save = function () {
        this.saveDB();
    };
    DAO.prototype.getObjectById = function (id) {
        return this.objects.get(id);
    };
    DAO.prototype.deleteObject = function (object) {
        this.deletedObjects.set(object.id, object);
        this.changedObjects.delete(object.id);
        this.newObjects.delete(object.id);
        this.objects.delete(object.id);
        this.hasChanged();
        this.saveDBDelete();
    };
    DAO.prototype.createNewInstance = function () {
        var object = this.createInstance();
        object.initialize();
        object.id = DAO.getNewId();
        this.assembleObject(object);
        object.setDefaultValues();
        this.objects.set(object.id, object);
        this.newObjects.set(object.id, object);
        this.hasChanged();
        return object;
    };
    DAO.prototype.assembleObjects = function () {
        var _this = this;
        this.objects.forEach(function (object) {
            _this.assembleObject(object);
        });
    };
    DAO.prototype.assembleObject = function (object) {
        object.myApplication = this.myApplication;
        object.repoService = this.daoService.repoService;
        object.basicService = this.basicService;
        object.update();
    };
    DAO.prototype.saveDB = function () {
        var _this = this;
        var data;
        if (this.changedObjects.size > 0) {
            data = this.buildJSON(Array.from(this.changedObjects.values()));
            if (data.length > 2) {
                this.updateBackend(data);
                this.changedObjects.forEach(function (object) {
                    object.resetDBState();
                });
            }
        }
        if (this.newObjects.size > 0) {
            data = this.buildJSON(Array.from(this.newObjects.values()), true);
            this.newObjects.forEach(function (object) {
                object.resetDBState();
                _this.changedObjects.set(object.id, object);
            });
            this.newObjects.clear();
            this.insert(data);
        }
        // this.saveDBDelete();
    };
    DAO.prototype.saveDBInsert = function (object) {
        var data;
        var array = new Array();
        array.push(object);
        data = this.buildJSON(array, true);
        this.insert(data);
    };
    DAO.prototype.saveDBUpdate = function (object) {
        var data;
        if (object.hasDataChanged()) {
            var array = new Array();
            array.push(object);
            data = this.buildJSON(array);
            this.updateBackend(data);
            object.resetDBState();
        }
    };
    DAO.prototype.saveDBDelete = function () {
        var data;
        if (this.deletedObjects.size > 0) {
            data = this.buildJSON(Array.from(this.deletedObjects.values()), true);
            this.deletedObjects.clear();
            this.delete(data);
        }
    };
    DAO.prototype.buildJSON = function (list, force) {
        if (force === void 0) { force = false; }
        var eoList = new Array();
        list.forEach(function (element) {
            // element.eo = this.correctNullValues(element.eo);
            if (force || element.hasDataChanged()) {
                eoList.push(element.eo);
            }
        });
        //return JSON.stringify(list, this.replacer);
        return JSON.stringify(eoList);
    };
    DAO.prototype.correctNullValues = function (eo) {
        var attributeNames = Object.getOwnPropertyNames(eo);
        attributeNames.forEach(function (attribute) {
            if (eo[attribute] == null) {
                eo[attribute] = 0;
            }
        });
        return eo;
    };
    DAO.prototype.replacer = function (key, value) {
        if (key.includes("Service") || key.includes("list") || key.includes("init") || key == 'init' || key.includes("l_") || key.includes("Set")) {
            return undefined;
        }
        return value;
    };
    DAO.prototype.setData = function (data) {
        var i = 0;
        var object;
        data = data['objects'];
        if (data && data != '[]') {
            var jsonObjectIn = data; //JSON.parse(data);
            var jsonObject = jsonObjectIn[i];
            while (jsonObject) {
                object = this.createInstance();
                object.setInit(true);
                object.initialize();
                object = this.readObject(object, jsonObject);
                this.objects.set(object.id, object);
                this.changedObjects.set(object.id, object);
                i++;
                jsonObject = jsonObjectIn[i];
            }
        }
        else {
            //console.log("no data");
        }
    };
    DAO.prototype.readObject = function (object, data) {
        var attributeNames = Object.getOwnPropertyNames(object.eo);
        var attName;
        var jsonName;
        for (var i = 0; i < attributeNames.length; i++) {
            attName = attributeNames[i];
            jsonName = attName;
            if (attName.includes("_")) {
                attName = attName.substring(1);
            }
            if (data[jsonName] == null) {
                if (jsonName.includes('_')) {
                    jsonName = jsonName.substring(1);
                }
                if (isBoolean(object.eo[attName])) {
                    object.eo[attName] = false;
                }
            }
            if (attName.includes("Date")) {
                object.eo[attName] = new Date(data[jsonName]);
                object.eo[attName] = this.basicService.normalizeDate(object.eo[attName]);
            }
            else {
                if (!attName.includes('Service') && data[jsonName]) {
                    if (typeof (object.eo[attName]) == 'number') {
                        object.eo[attName] = Number.parseFloat(data[jsonName]);
                    }
                    else {
                        object.eo[attName] = data[jsonName];
                    }
                }
            }
        }
        object.eo.db = object.eo.clone();
        return object;
    };
    DAO.getNewId = function () {
        return Math.random().toString().substr(2) + Math.random().toString().substr(2);
    };
    DAO.prototype.getHeaders = function () {
        return this.authenticationService.getHeadersAuthenticated();
    };
    DAO.prototype.read = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var options = new RequestOptions();
            options.headers = _this.getHeaders();
            _this.http.get(environment.apiV2 + 'v1/' + _this.name, options).pipe(map(function (resp) { return resp.json(); }))
                .subscribe(function (res) {
                _this.setData(res);
                _this.hasChanged();
                resolve(true);
            }, function (err) {
                console.log('There was an error reading ressource ' + _this.name + '.', err);
                reject();
            });
        });
    };
    DAO.prototype.insert = function (data) {
        var save = new SaveInsert();
        save.data = data;
        save.http = this.http;
        var options = new RequestOptions();
        options.headers = this.getHeaders();
        save.options = options;
        save.route = environment.apiV2 + 'v1/' + this.name;
        this.daoService.saveQueue.push(save);
    };
    DAO.prototype.refresh = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.readData().then(function () {
                _this.assembleObjects();
                resolve(null);
            });
        });
    };
    DAO.prototype.updateBackend = function (data) {
        var save = new SaveUpdate();
        save.data = data;
        save.http = this.http;
        var options = new RequestOptions();
        options.headers = this.getHeaders();
        save.options = options;
        save.route = environment.apiV2 + 'v1/' + this.name;
        this.daoService.saveQueue.push(save);
    };
    DAO.prototype.delete = function (data) {
        var save = new SaveDelete();
        save.data = data;
        save.http = this.http;
        var options = new RequestOptions();
        options.headers = this.getHeaders();
        options.body = data;
        save.options = options;
        save.route = environment.apiV2 + 'v1/' + this.name;
        this.daoService.saveQueue.push(save);
    };
    return DAO;
}(Observable));
export { DAO };
