/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./areasv2.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./areasv2.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../services/application.service";
import * as i5 from "@angular/router";
import * as i6 from "../../services/breadcrumb.service";
import * as i7 from "../../services/authentication.service";
var styles_Areasv2Component = [i0.styles];
var RenderType_Areasv2Component = i1.ɵcrt({ encapsulation: 0, styles: styles_Areasv2Component, data: {} });
export { RenderType_Areasv2Component as RenderType_Areasv2Component };
export function View_Areasv2Component_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_Areasv2Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-areasv2", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_Areasv2Component_0, RenderType_Areasv2Component)), i1.ɵdid(1, 245760, null, 0, i2.Areasv2Component, [i3.DomSanitizer, i4.ApplicationService, i5.Router, i6.BreadcrumbService, i7.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Areasv2ComponentNgFactory = i1.ɵccf("app-areasv2", i2.Areasv2Component, View_Areasv2Component_Host_0, {}, {}, []);
export { Areasv2ComponentNgFactory as Areasv2ComponentNgFactory };
