import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DAOCultures } from 'src/app/app-core/dao/dao-cultures';
import { EventHandler } from 'src/app/app-core/technical-aspects/event-handler';
import { Listener } from 'src/app/app-core/technical-aspects/listener';
import { ApplicationService } from 'src/app/services/application.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { DaoService } from 'src/app/services/dao.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cultures-v2',
  templateUrl: './cultures-v2.component.html',
  styleUrls: ['./cultures-v2.component.css']
})
export class CulturesV2Component implements OnInit, Listener {

  public url: SafeResourceUrl;
  pShowCultures = false;

  innerHeight = 1100;

  
  constructor(public sanitizer: DomSanitizer, private myApplication: ApplicationService, private breadcrumbSrv: BreadcrumbService,
    private permissionsService: PermissionsService, private daoService: DaoService,
    private route: ActivatedRoute) {

    this.breadcrumbSrv.breadcrumb = this.myApplication.getText('Feedback.view.Kulturen').toString();
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.cultureModule + '/cultureList/' + this.myApplication.year);

    this.myApplication.addObserver(this);
    this.myApplication.eventHandler.register(EventHandler.LANGUAGE_CHANGED, this);
    
    this.pShowCultures = this.permissionsService.checkPermissionForAction('culture', 'show', null);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerHeight = window.innerHeight - 60;
  }
  
  ngOnInit() {
    this.onResize(null);

    this.route.params.subscribe((params) => {
      let cultureId = params['cultureId'];
      if (cultureId) {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.cultureModule + '/cultureList/' + this.myApplication.year + '/' + cultureId);        
      }
    });
  }

  update() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.cultureModule + '/cultureList/' + this.myApplication.year);
  }

  ngOnDestroy() {
    let daoCultures = <DAOCultures>this.daoService.getDAO('cultures');
    daoCultures.refresh();
    this.update();
    this.myApplication.removeObserver(this);
  }

  handleLanguageChanged() {
    this.breadcrumbSrv.breadcrumb = this.myApplication.getText('Feedback.view.Kulturen').toString();
    let iframe: any = document.getElementById('culturesV2');
    iframe.contentWindow.location.reload();
  }
}
