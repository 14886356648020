import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ApplicationService } from 'src/app/services/application.service';
import { ToastrService } from 'ngx-toastr';


export class MessageDTO {

    static readonly SUCCESS: String = 'success';
    static readonly WARNING: String = 'warning';
    static readonly ERROR: String = 'danger';

    public message: string;
    public header: String;
    public type: String;
    public disappear: number = 6000;
    public asLink: false;
    public link: String;

    private _success = new Subject<string>();


    constructor(private myApplication: ApplicationService, private toastr: ToastrService) {
        this.message = null;
        this.type = MessageDTO.SUCCESS;

        this._success.subscribe((message) => this.message = message);
        this._success.pipe(
            debounceTime(this.disappear)
        ).subscribe(() => {
            this.message = null;
            this.type = '';
        }
        );
    }

    public setMessage(message: string, type: String) {
        if (this.toastr) {
            if (type == MessageDTO.WARNING) {
                this.toastr.warning(message, this.myApplication.getTextSimple('Warnung'));
            } else if (type == MessageDTO.ERROR) {
                this.toastr.error(message, this.myApplication.getTextSimple('Fehler'));
            } else {
                this.toastr.success(message, this.myApplication.getTextSimple('Info'));
            }
        }
    }

    public setSuccess(message: string) {
        this.setMessage(message, MessageDTO.SUCCESS);
    }

    public setSuccessWithId(id: string, parameter1?: any, parameter2?: any) {
        this.setMessage(this.myApplication.getTextSimple(id, parameter1, parameter2), MessageDTO.SUCCESS);
    }

    public setWarning(message: string) {
        this.setMessage(message, MessageDTO.WARNING);
    }

    public setWarningWithId(id: string, parameter1?: any) {
        this.setMessage(this.myApplication.getTextSimple(id, parameter1), MessageDTO.WARNING);
    }

    public setError(message: string) {
        this.setMessage(message, MessageDTO.ERROR);
    }

    public setErrorWithId(id: string, parameter1?: any) {
        this.setMessage(this.myApplication.getTextSimple(id, parameter1), MessageDTO.ERROR);
    }
}