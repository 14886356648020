import Html2Canvas from 'html2canvas';
import * as jspdf from 'jspdf';
import * as i0 from "@angular/core";
var GeneratePdfService = /** @class */ (function () {
    function GeneratePdfService() {
    }
    GeneratePdfService.prototype.resizeTo = function (canvas, pct) {
        var tempCanvas = document.createElement('canvas');
        tempCanvas.width = canvas.width;
        tempCanvas.height = canvas.height;
        var tctx = tempCanvas.getContext("2d");
        tctx.drawImage(canvas, 0, 0);
        canvas.width *= pct;
        canvas.height *= pct;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(tempCanvas, 0, 0, tempCanvas.width, tempCanvas.height, 0, 0, tempCanvas.width * pct, tempCanvas.height * pct);
    };
    GeneratePdfService.prototype.crop = function (canvas, width, height, offset) {
        var ctx = canvas.getContext('2d');
        // get the image data you want to keep.
        // const imageData = ctx.getImageData(0, offset, 297, 210);
        console.log('offset: ', offset);
        console.log('breite: ', width);
        console.log('hoehe: ', height);
        var imageData = ctx.getImageData(0, offset, width, height);
        // create a new canvas same as clipped size and a context
        var newCan = document.createElement('canvas');
        newCan.width = width;
        newCan.height = height;
        var newCtx = newCan.getContext('2d');
        // put the clipped image on the new canvas.
        newCtx.putImageData(imageData, 0, 0);
        return newCan;
    };
    GeneratePdfService.prototype.generatePDF = function (elementName, filename) {
        var _this = this;
        var pdf = new jspdf('l', 'mm', 'a4'); // A4 size page of PDF
        Html2Canvas(document.getElementById('Header')).then(function (canvas) {
            var img_header = canvas.toDataURL("image/PNG");
            return img_header;
        }).then(function (img_header) {
            Html2Canvas(document.getElementById('Aplan')).then(function (canvas) {
                //Header erstellen
                var bufferX = 5;
                var bufferY = 5;
                var imgHeaderProps = pdf.getImageProperties(img_header);
                var pdfWidth = pdf.internal.pageSize.getWidth() - (2 * bufferX);
                var pdfHeight = (imgHeaderProps.height * pdfWidth) / imgHeaderProps.width;
                pdf.addImage(img_header, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight);
                //Saetze erstellen
                var img_data = canvas.toDataURL("image/PNG");
                var imgDataProps = pdf.getImageProperties(img_data);
                var pdfDataHeight = (imgDataProps.height * pdfWidth) / imgDataProps.width;
                var imgDataHeight = imgDataProps.height;
                var maxHeight = imgDataProps.width * 210 / 297;
                var offset_header = 20;
                //nur eine Seite
                if (imgDataHeight + 20 <= maxHeight) {
                    pdf.addImage(img_data, 'PNG', bufferX, 20, pdfWidth, pdfDataHeight);
                }
                //mehrere Seite, dann zeiteilen
                else {
                    var offset = 0;
                    var i = 1;
                    while (imgDataHeight + offset_header > maxHeight) {
                        if (i > 1) {
                            //wenn nicht 1. Durchlauf dann neue Seite anlegen
                            console.log('neue Seite');
                            pdf.addPage();
                            pdf.setPage(i);
                            pdf.addImage(img_header, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight);
                        }
                        //   //const canvasCrop = this.crop(canvas, canvas.width, imgHeight, offset);
                        console.log(offset);
                        var canvasCrop_1 = _this.crop(canvas, imgDataProps.width, 1100, offset);
                        //Teilbild
                        var contentDataURL_1 = canvasCrop_1.toDataURL('image/png');
                        imgDataProps = pdf.getImageProperties(contentDataURL_1);
                        var dataHeigth_1 = (imgDataProps.height * pdfWidth) / imgDataProps.width;
                        pdf.addImage(contentDataURL_1, 'PNG', 5, 20, pdfWidth, dataHeigth_1);
                        //Restbildgroeße
                        imgDataHeight = imgDataHeight - imgDataProps.height;
                        offset = offset + imgDataProps.height;
                        console.log('offset_schleife: ', offset);
                        i = i + 1;
                    }
                    // Header nochmal imgDataProps.height
                    pdf.addPage();
                    pdf.setPage(i);
                    pdf.addImage(img_header, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight);
                    var canvasCrop = _this.crop(canvas, imgDataProps.width, 1100, offset);
                    console.log('offset2: ', offset);
                    var contentDataURL = canvasCrop.toDataURL('image/png');
                    imgDataProps = pdf.getImageProperties(contentDataURL);
                    console.log(imgDataProps.height);
                    var dataHeigth = (imgDataProps.height * pdfWidth) / imgDataProps.width;
                    console.log(i + 1, '. Bild');
                    pdf.addImage(contentDataURL, 'PNG', bufferX, 20, pdfWidth, dataHeigth);
                }
                pdf.save(filename + '.pdf');
                return pdf;
            });
        });
        // const position = 10;
        // let offset  = 0;
        // while (offset + pageHeight < canvas.height) {
        //   while (offset + pdfHeight < canvas.height) {
        //   //const canvasCrop = this.crop(canvas, canvas.width, imgHeight, offset);
        //   const canvasCrop = this.crop(canvas, canvas.width, imgProps.height, offset);
        //   const contentDataURL = canvasCrop.toDataURL('image/png');
        //   this.resizeTo(canvasCrop, 0.1);
        //  // pdf.addImage(contentDataURL, 'PNG', 5, 5, canvasCrop.width, canvasCrop.height);
        //  pdf.addImage(contentDataURL, 'PNG', bufferX, bufferY, canvasCrop.width, canvasCrop.height);
        //   offset = offset + imgProps.height;
        //   if (offset + pdfHeight < canvas.height) {
        //     pdf.addPage();
        //   }
        // }
        // let outString = pdf.output('dataurinewwindow');
        //  let x = window.open();
        //  x.document.location = outString;
        //  }
        // pdf.save(filename + '.pdf');
        // let data = document.getElementById("Aplan");
        // // let data = document.body;
        // html2canvas(data).then(canvas => {
        //   let imgWidth = 208;
        //   let pageHeigth = 295;
        //   let imgHeight = canvas.height * imgWidth / canvas.width;
        //   let heigthLeft = imgHeight;
        //   console.log(imgHeight);
        //   console.log(imgWidth);
        //   const contentDataURL = canvas.toDataURL('image/png');
        //   let pdf = new jspdf('p', 'mm', 'a4');
        //   let position = 0;
        //   pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        //   pdf.save('AnbauPlan.pdf');
        //   // let outString = pdf.output('datauristring');
        //   // let x = window.open();
        //   // x.document.location = outString;
        // });
    };
    GeneratePdfService.ngInjectableDef = i0.defineInjectable({ factory: function GeneratePdfService_Factory() { return new GeneratePdfService(); }, token: GeneratePdfService, providedIn: "root" });
    return GeneratePdfService;
}());
export { GeneratePdfService };
