/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./ui-components/main-nav/main-nav.component.ngfactory";
import * as i3 from "./ui-components/main-nav/main-nav.component";
import * as i4 from "./services/basic.service";
import * as i5 from "./services/application.service";
import * as i6 from "@angular/router";
import * as i7 from "./services/authentication.service";
import * as i8 from "./services/dao.service";
import * as i9 from "./services/modal.service";
import * as i10 from "./services/repository.service";
import * as i11 from "./services/user-settings.service";
import * as i12 from "./services/parts.service";
import * as i13 from "./services/permissions.service";
import * as i14 from "./services/breadcrumb.service";
import * as i15 from "@ng-bootstrap/ng-bootstrap";
import * as i16 from "./services/wizard.service";
import * as i17 from "@angular/platform-browser";
import * as i18 from "./ui-components/start/start.component.ngfactory";
import * as i19 from "./ui-components/start/start.component";
import * as i20 from "@angular/common";
import * as i21 from "ngx-cookie-service";
import * as i22 from "./services/app-initializer.service";
import * as i23 from "./app.component";
import * as i24 from "./services/helping-data.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "spinner"], ["id", "spinner"]], null, null, null, null, null))], null, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-main-nav", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MainNavComponent_0, i2.RenderType_MainNavComponent)), i1.ɵdid(1, 114688, null, 0, i3.MainNavComponent, [i4.BasicService, i5.ApplicationService, i6.Router, i7.AuthenticationService, i8.DaoService, i6.ActivatedRoute, i9.ModalService, i10.RepositoryService, i11.UserSettingsService, i12.PartsService, i13.PermissionsService, i14.BreadcrumbService, i15.NgbModal, i16.WizardService, i17.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-start", [], null, null, null, i18.View_StartComponent_0, i18.RenderType_StartComponent)), i1.ɵdid(1, 114688, null, 0, i19.StartComponent, [i5.ApplicationService, i8.DaoService, i7.AuthenticationService, i20.Location, i6.Router, i21.CookieService, i22.AppInitializerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i20.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(3, 16384, null, 0, i20.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(5, 16384, null, 0, i20.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_4)), i1.ɵdid(7, 16384, null, 0, i20.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.localTokenChecked || (_co.authentication.isOK && !_co.appInitSrv.initialized)); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.authentication.isOK && _co.appInitSrv.initialized); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.showWithoutHead; _ck(_v, 5, 0, currVal_2); var currVal_3 = ((_co.localTokenChecked && !_co.authentication.isOK) && !_co.showWithoutHead); _ck(_v, 7, 0, currVal_3); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i23.AppComponent, [i7.AuthenticationService, i8.DaoService, i5.ApplicationService, i10.RepositoryService, i6.Router, i9.ModalService, i15.NgbModal, i13.PermissionsService, i16.WizardService, i22.AppInitializerService, i24.HelpingDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i23.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
