import { OnInit } from '@angular/core';
import { ApplicationService } from 'src/app/services/application.service';
import { KeyValueDTO } from 'src/app/app-core/dto/key-value-dto';
import { Constants } from 'src/app/app-core/business-logic/constants';
import { SeedSet } from 'src/app/app-core/business-logic/seed-set';
import { Sort } from 'src/app/app-core/business-logic/sort';
import { PermissionsService } from 'src/app/services/permissions.service';
var SeedsComponent = /** @class */ (function () {
    function SeedsComponent(myApplication, permissionsService) {
        this.myApplication = myApplication;
        this.permissionsService = permissionsService;
        this.unitList = new Array();
        this.years = new Array();
        this.units = new Array();
        this.seedUnits = new Array();
        this.actionCreate = false;
        this.actionDelete = false;
        this.actionEdit = false;
        this.initUnits();
        this.initSeedUnits();
        this.checkAllowedActions();
    }
    SeedsComponent.prototype.ngOnInit = function () {
        this.initQuantityUnits();
        this.initYears();
    };
    SeedsComponent.prototype.initQuantityUnits = function () {
        this.unitList.push(new KeyValueDTO(Constants.UNIT_GRAMM));
        this.unitList.push(new KeyValueDTO(Constants.UNIT_KORN));
    };
    SeedsComponent.prototype.create = function (sortSeed) {
        this.initYears();
        this.initUnits();
        this.sort.seedSet.createWithSortSeed(sortSeed);
    };
    SeedsComponent.prototype.createOtherUnit = function (sortSeed) {
        this.initYears();
        this.initUnits();
        this.initSeedUnits();
        this.sort.seedSet.otherUnit = true;
        this.sort.seedSet.createWithSortSeed(sortSeed);
    };
    SeedsComponent.prototype.createNew = function (otherUnit) {
        this.initYears();
        var sortSeed = this.sort.sortSeedSet.createSortSeed();
        sortSeed.otherUnit = otherUnit;
        var seed = this.sort.seedSet.createWithSortSeed(sortSeed);
        seed.newWithNewSortSeed = true;
        this.myApplication.save();
    };
    SeedsComponent.prototype.delete = function (seed) {
        this.sort.seedSet.deleteSeed(seed);
    };
    Object.defineProperty(SeedsComponent.prototype, "all", {
        get: function () {
            return this.seedSet.all;
        },
        set: function (value) {
            this.seedSet.all = value;
        },
        enumerable: true,
        configurable: true
    });
    SeedsComponent.prototype.initYears = function () {
        this.years = new Array();
        var keyValueDTO;
        for (var i = 2018; i < this.myApplication.year + 15; i++) {
            keyValueDTO = new KeyValueDTO(i.toString(), i.toString());
            if (this.sort.sortSeedSet.yearExists(i)) {
                // keyValueDTO.disabled = true;
                keyValueDTO.italic = true;
            }
            this.years.push(keyValueDTO);
        }
    };
    SeedsComponent.prototype.modelChanged = function (value) {
        if (value == 'manufacturingYear') {
            this.initYears();
        }
    };
    SeedsComponent.prototype.initUnits = function () {
        this.units = new Array();
        this.units.push(new KeyValueDTO('a', this.myApplication.getText('ToDo.quantity.a')));
        this.units.push(new KeyValueDTO('b', this.myApplication.getText('ToDo.quantity.b')));
        this.units.push(new KeyValueDTO('c', this.myApplication.getText('ToDo.quantity.c')));
        this.units.push(new KeyValueDTO('d', this.myApplication.getText('ToDo.quantity.d')));
        this.units.push(new KeyValueDTO('j', this.myApplication.getText('ToDo.quantity.j')));
        this.units.push(new KeyValueDTO('k', this.myApplication.getText('ToDo.quantity.k')));
    };
    SeedsComponent.prototype.initSeedUnits = function () {
        this.seedUnits = new Array();
        this.seedUnits.push(new KeyValueDTO('kg', this.myApplication.getText('Unit.kg')));
        this.seedUnits.push(new KeyValueDTO('g', this.myApplication.getText('Unit.g')));
    };
    SeedsComponent.prototype.checkAllowedActions = function () {
        this.actionCreate = this.permissionsService.checkPermissionForAction('seeds', 'create', 'seedInventory');
        this.actionDelete = this.permissionsService.checkPermissionForAction('seeds', 'delete', 'seedInventory');
        this.actionEdit = this.permissionsService.checkPermissionForAction('seeds', 'edit', 'seedInventory');
    };
    SeedsComponent.prototype.checkIfTkgIsRelevant = function () {
        //Wenn TKG an der Sorte in irgendeinem Jahr gepflegt, dann anzeigen
        //und wenn keine abweichende Einheit gepflegt, dann ebenfalls anzeigen
        var isRelevant = false;
        if (this.sort.checkIfTkgIsUsed()) {
            isRelevant = true;
        }
        else if (!this.sort.checkIfOtherUnitIsUsed()) {
            isRelevant = true;
        }
        return isRelevant;
    };
    SeedsComponent.prototype.checkIfOtherUnitIsRelevant = function () {
        //Tabelle fuer abweichende Einheiten nur anzeigen, wenn an der Sorte welche gepflegt sind
        var isRelevant = false;
        if (this.sort.checkIfOtherUnitIsUsed()) {
            isRelevant = true;
        }
        return isRelevant;
    };
    return SeedsComponent;
}());
export { SeedsComponent };
