import * as tslib_1 from "tslib";
import { DAO } from '../technical-aspects/dao';
import { Culture } from '../business-logic/culture';
var DAOCultures = /** @class */ (function (_super) {
    tslib_1.__extends(DAOCultures, _super);
    function DAOCultures() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DAOCultures.prototype.createInstance = function () {
        var newInstance = new Culture();
        newInstance.daoSorts = this.daoService.getDAO('sorts');
        newInstance.daoRecords = this.daoService.getDAO('records');
        newInstance.daoTemplate = this.daoService.getDAO('template');
        newInstance.daoCultures = this;
        return newInstance;
    };
    DAOCultures.prototype.createNewInstance = function () {
        var object = _super.prototype.createNewInstance.call(this);
        object.color = this.basicService.randomColor();
        return object;
    };
    DAOCultures.prototype.getCultureNextToChar = function (char) {
        var array = Array.from(this.objects.values()).sort(function (one, two) {
            return one.name.localeCompare(two.name.toString());
        });
        var fits = null;
        for (var i = 0; i < array.length; i++) {
            fits = array[i];
            var firstChar = fits.name.substring(0, 1);
            firstChar = firstChar.toLowerCase();
            if (firstChar >= char) {
                return fits;
            }
        }
        return fits;
    };
    DAOCultures.prototype.isSelected = function (culture) {
        if (culture == this.selectedCulture) {
            return true;
        }
        else {
            return false;
        }
    };
    DAOCultures.prototype.deleteObject = function (object) {
        var culture = object;
        if (culture.isDeletable()) {
            culture.deleteChildren();
            _super.prototype.deleteObject.call(this, object);
        }
    };
    DAOCultures.prototype.getCultureByName = function (name) {
        if (!name) {
            return null;
        }
        var culture;
        var array = Array.from(this.objects.values());
        for (var i = 0; i < array.length; i++) {
            culture = array[i];
            if (culture.name == name) {
                return culture;
            }
        }
        return null;
    };
    return DAOCultures;
}(DAO));
export { DAOCultures };
