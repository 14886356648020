import { DaoService } from './dao.service';
import { PermissionsService } from './permissions.service';
import { RepositoryService } from './repository.service';
import * as i0 from "@angular/core";
import * as i1 from "./permissions.service";
import * as i2 from "./repository.service";
import * as i3 from "./dao.service";
var AppInitializerService = /** @class */ (function () {
    function AppInitializerService(permissionsSrv, repoSrv, daoService) {
        this.permissionsSrv = permissionsSrv;
        this.repoSrv = repoSrv;
        this.daoService = daoService;
        this.initialized = false;
    }
    AppInitializerService.prototype.initialize = function () {
        var _this = this;
        this.daoService.initialize();
        this.permissionsSrv.readPermissions().then(function () {
            _this.repoSrv.refresh().then(function () {
                _this.initialized = true;
            });
        });
    };
    AppInitializerService.ngInjectableDef = i0.defineInjectable({ factory: function AppInitializerService_Factory() { return new AppInitializerService(i0.inject(i1.PermissionsService), i0.inject(i2.RepositoryService), i0.inject(i3.DaoService)); }, token: AppInitializerService, providedIn: "root" });
    return AppInitializerService;
}());
export { AppInitializerService };
