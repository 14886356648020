import * as tslib_1 from "tslib";
import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';
var FeedbackEO = /** @class */ (function (_super) {
    tslib_1.__extends(FeedbackEO, _super);
    function FeedbackEO() {
        var _this = _super.call(this) || this;
        _this.uname = '';
        _this.view = '';
        _this.comment = '';
        return _this;
    }
    return FeedbackEO;
}(AbstractEntityObject));
export { FeedbackEO };
