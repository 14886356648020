import * as tslib_1 from "tslib";
import { Save } from './save';
var SaveDelete = /** @class */ (function (_super) {
    tslib_1.__extends(SaveDelete, _super);
    function SaveDelete() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SaveDelete.prototype.save = function () {
        var _this = this;
        this.http.delete(this.route, this.options).pipe(
        //map(resp => resp.json())
        )
            .subscribe(function () {
        }, function (err) {
            console.log('There was an error deleting ressource ' + _this.route + '.', err);
        });
    };
    return SaveDelete;
}(Save));
export { SaveDelete };
