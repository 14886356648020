import { AbstractObject } from '../technical-aspects/abstract-object';
import { HarvestEO } from '../entity-objects/harvest-eo';


export class Harvest extends AbstractObject {


    constructor() {
        super();
    }

    update() {

    }


    protected get harvestEO(): HarvestEO {
        return <HarvestEO>this.eo;
    }


    public get recordId(): String {
        return this.harvestEO.recordId;
    }
    public set recordId(value: String) {
        this.harvestEO.recordId = value;
    }
    
    public get kind(): String {
        return this.harvestEO.kind;
    }
    public set kind(value: String) {
        this.harvestEO.kind = value;
    }

    public get quantity(): number {
        return this.harvestEO.quantity;
    }
    public set quantity(value: number) {
        this.harvestEO.quantity = value;
    }

    public get quantityUnit(): String {
        return this.harvestEO.quantityUnit;
    }
    public set quantityUnit(value: String) {
        this.harvestEO.quantityUnit = value;
    }

    public get harvestDate(): Date {
        return this.harvestEO.harvestDate;
    }
    public set harvestDate(value: Date) {
        this.harvestEO.harvestDate = this.basicService.normalizeDate(value);
    }


    initialize() {
        this.eo = new HarvestEO();
    }


}
