/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./statistic.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./statistic.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../services/application.service";
import * as i5 from "@angular/router";
import * as i6 from "../../services/breadcrumb.service";
var styles_StatisticComponent = [i0.styles];
var RenderType_StatisticComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StatisticComponent, data: {} });
export { RenderType_StatisticComponent as RenderType_StatisticComponent };
export function View_StatisticComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "iframe", [["id", "statistics"]], [[8, "src", 5], [4, "height", "px"]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; var currVal_1 = _co.innerHeight; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_StatisticComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-statistic", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_StatisticComponent_0, RenderType_StatisticComponent)), i1.ɵdid(1, 245760, null, 0, i2.StatisticComponent, [i3.DomSanitizer, i4.ApplicationService, i5.Router, i6.BreadcrumbService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StatisticComponentNgFactory = i1.ɵccf("app-statistic", i2.StatisticComponent, View_StatisticComponent_Host_0, {}, {}, []);
export { StatisticComponentNgFactory as StatisticComponentNgFactory };
