import { ToDoList } from './todo-list';
import { DAOToDos } from '../dao/dao-todos';
import { ToDo } from './todo';
import { Constants } from './constants';
import { Area } from './area';

export class ToDoListArea extends ToDoList {

    constructor(protected daoToDos: DAOToDos, public area: Area) {
        super(daoToDos);

        this.daoToDos.objects.forEach(element => {
            let todo = <ToDo>element;
            if (todo.parentType == Constants.PARENT_TYPE_AREA) {
                if (todo.parentId == this.area.id) {
                    if (!todo.bedIdStart) {
                        if (todo.isSeriesMaster() == false) {
                            this.toDos.push(todo);
                        }
                    }
                }
            }
        });

    }

    update() {
        this.toDos = new Array();
        this.daoToDos.objects.forEach(element => {
            let todo = <ToDo>element;
            if (todo.parentType == Constants.PARENT_TYPE_AREA) {
                if (todo.parentId == this.area.id) {
                    if (!todo.bedIdStart) {
                        if (todo.isSeriesMaster() == false) {
                            this.toDos.push(todo);
                        }
                    }
                }
            }
        });

        this.toDos = this.toDos.sort(function (one, two) {
            let compare = (<ToDo>one).dueDate.getTime() - ((<ToDo>two).dueDate.getTime());
            return compare;
        });
    }
}