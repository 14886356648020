import { Component, OnInit, Input, Injectable } from '@angular/core';
import { FieldTextDTO } from 'src/app/app-core/dto/field-text-dto';
import { ApplicationService } from 'src/app/services/application.service';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Listener } from 'src/app/app-core/technical-aspects/listener';
import { KeyValueDTO } from 'src/app/app-core/dto/key-value-dto';
import { isNumber } from 'util';
import { BasicService } from 'src/app/services/basic.service';

@Component({
  selector: 'app-calendar-field',
  templateUrl: './calendar-field.component.html',
  styleUrls: ['./calendar-field.component.css']
})
export class CalendarFieldComponent implements OnInit, Listener {

  @Input() id: String;
  @Input() object;
  @Input() valueName: String;
  @Input() unit: String;
  @Input() unitPrepend: String;
  @Input() valueNamePrepend: String;
  @Input() minDate = { year: 2018, month: 1, day: 1 };
  @Input() unitValueName: String;
  @Input() keyValueList: Array<KeyValueDTO>;
  @Input() inModal: boolean = false;

  public min: number = 1;
  public fieldDTO: FieldTextDTO;
  private init = true;
  private _disable: boolean = false;
  model: NgbDateStruct;
  public useWarningClass = false;

  constructor(public myApplication: ApplicationService, private basicService: BasicService) { }

  ngOnInit() {
    if (!this.id) {
      this.id = this.valueName;
    }
    this.fieldDTO = this.myApplication.textDTOs.get(this.id);
    if (!this.fieldDTO) {
      this.fieldDTO = new FieldTextDTO(this.id, this.id, '');
    }

    this.update();

    try {
      this.object.addObserver(this);
    }
    catch {
      // was solls
    }
  }

  public get disable(): boolean {
    return this._disable;
  }
  @Input()
  public set disable(value: boolean) {
    this._disable = value;
  }

  update() {
    this.init = true;
    this.model = {
      year: this.object[this.valueName.toString()].getFullYear(),
      month: this.object[this.valueName.toString()].getMonth() + 1,
      day: this.object[this.valueName.toString()].getDate()
    };
    this.init = false;
  }

  getWarningClass() {
    if (this.useWarningClass) {
      return 'border-warning';
    }
  }

  onBlur(event) {
    this.update();
  }

  public setValueToObject(event) {
    let dateString = event.target.value.toString();
    let newDate = this.basicService.parseDate(dateString, 'dd.mm.yyyy');
    if (newDate && !isNaN(newDate.getTime())) {
      this.object[this.valueName.toString()] = newDate;
      this.update();
      this.useWarningClass = false;
    } else {
      this.useWarningClass = true;
    }
  }



  public setModelToObject() {
    let newDate = new Date(this.model.year, this.model.month - 1, this.model.day);
    if (!isNaN(newDate.getTime())) {
      this.object[this.valueName.toString()] = newDate;
      this.update();
      this.useWarningClass = false;
    } else {
      this.useWarningClass = true;
    }
  }
}

@Injectable() export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {

  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('.');
      if (dateParts.length === 1 && isNumber(dateParts[0])) {
        return { day: Number.parseInt(dateParts[0]), month: null, year: null };
      } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
        return { day: Number.parseInt(dateParts[0]), month: Number.parseInt(dateParts[1]), year: null };
      } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
        return {
          day: Number.parseInt(dateParts[0]), month: Number.parseInt(dateParts[1]), year: Number.parseInt(dateParts[2])
        };
      }
    } return null;
  }

  format(date: NgbDateStruct): string {
    function padNumber(value: number) {
      if (isNumber(value)) {
        return `0${value}`.slice(-2);
      } else {
        return "";
      }
    }

    return date ? `${isNumber(date.day) ? padNumber(date.day) : ''}.${isNumber(date.month) ? padNumber(date.month) : ''}.${date.year}` : '';
  }
}
