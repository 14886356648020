import { AbstractObject } from '../technical-aspects/abstract-object';
import { RecordEO } from '../entity-objects/record-eo';
import { Culture } from './culture';
import { DAORecords } from '../dao/dao-records';
import { DAOCultures } from '../dao/dao-cultures';
import { DAOSorts } from '../dao/dao-sorts';
import { DAOParts } from '../dao/dao-parts';
import { DAOBeds } from '../dao/dao-beds';
import { PeriodsUIList } from './periods-ui-list';
import { Constants } from './constants';
import { RecordPeriods } from './record-periods';
import { RecordCreateDTO } from '../dto/record-create-dto';
import { DAOBedAssigns } from '../dao/dao-bed-assigns';
import { BedAssignList } from './bed-assign-list';
import { DAOToDos } from '../dao/dao-todos';
import { ToDoListRecord } from './todo-list-record';
import { DAOAreas } from '../dao/dao-areas';
import { Area } from './area';
import { ToDo } from './todo';
import { Sort } from './sort';
import { CalculatorQuantity } from './calculator-quantity';
import { CalculatorLengthOnBed } from './calculator-length-on-bed';
import { CalculatorQuantityWithBedM } from './calculator-quantity-with-bedm';
import { EventHandler } from '../technical-aspects/event-handler';
import { TagBuilder } from './helper/tag-builder';
import { Template } from './template';
import { PartsService } from 'src/app/services/parts.service';
import { FilteredParts } from './filteredParts';
import { RepositoryService } from 'src/app/services/repository.service';


export class Record extends AbstractObject {

  public stateColor: String;
  protected _culture: Culture;
  public daoRecords: DAORecords;
  public daoSorts: DAOSorts;
  public daoCultures: DAOCultures;
  public daoParts: DAOParts;
  public daoAreas: DAOAreas;
  public daoBeds: DAOBeds;
  public daoBedAssigns: DAOBedAssigns;
  public daoToDos: DAOToDos;
  private _selected: boolean = false;
  public partsService: PartsService;
  public repoService: RepositoryService;

  private _bedAssignList: BedAssignList;

  public stateText: String;

  private _recordPeriods: RecordPeriods;
  private _periodsUI: PeriodsUIList;
  private _periodsUIAreaBed: PeriodsUIList;
  private _periodsUIBedMeters: PeriodsUIList;
  private _todoList: ToDoListRecord;
  private _tagList: Array<string>;
  public popoverInfoTextPlant: String;
  public popoverInfoTextBedMeters: String;
  public popoverInfoTextPlantBedMeters: String;
  public popoverInfoTextRounded: String;

  private quantityCalculated1: number;
  private bedMCalculated: number;
  private calculated = false;

  public bedMWarning = false;
  public checked = false;


  constructor() {
    super();
  }

  public readReady() {
    this.myApplication.eventHandler.register(EventHandler.EVENT_RECORD_DATES_CHANGED, this);
  }

  public distancesDifferFromCulture(): boolean {
    if (this.plantDistance == this._culture.plantDistance &&
      this.rowDistance == this._culture.rowDistance &&
      this.numRows == this._culture.numRows) {
      return false;
    } else {
      return true;
    }
  }

  public detailsDifferFromCulture(): boolean {
    if (this.seedRate == this._culture.seedRate) {
      return false;
    }
    else {
      return true;
    }
  }

  hasCultureInfoWarnings(): boolean {
    if (this.recordPeriods.harvestLength < 1 ||
      this.plantDistance <= 0 ||
      this.hasDistanceWarnings() ||
      (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.hasQuickpotWarnings()) ||
      this.hasPlantBoxesWarnings()) {
      return true;
    } else {
      return false;
    }
  }

  public hasQuickpotWarnings(): boolean {
    if (this.quickpotSize <= 0 || this.seedsPerQuickpot <= 0) {
      return true;
    } else {
      return false;
    }
  }

  protected hasDistanceWarnings(): boolean {
    if (this.rowDistance <= 0 ||
      this.numRows <= 0) {
      return true;
    } else {
      return false;
    }
  }

  hasAnbauErnteWarnings(): boolean {
    if ((this.recordPeriods.harvestQuantity <= 0 && this.harvestMethod != Constants.HARVEST_METHOD_ABSOLUT) ||
      this.recordPeriods.neededHarvestQuantity <= 0 ||
      (this.recordPeriods.numOfDeliveryWeeks <= 0 &&
        this.recordPeriods.alternativeDelivery)
    ) {
      return true;
    } else {
      return false;
    }
  }

  hasWarnings(): boolean {
    if (this.hasCultureInfoWarnings() ||
      this.hasAnbauErnteWarnings()
    ) {
      return true;
    } else {
      return false;
    }
  }

  getWarnings(): Array<String> {
    let warnings = new Array<String>();

    warnings = warnings.concat(this.getAnbauErnteWarnings(), this.getCultureInfoWarnings());

    return warnings;
  }

  getAnbauErnteWarnings(): Array<String> {
    let warnings = new Array<String>();

    if (this.recordPeriods.harvestQuantity <= 0 && this.harvestMethod != Constants.HARVEST_METHOD_ABSOLUT) {
      warnings.push(this.myApplication.getText('FM.harvestQuantityIsZero'));
    }

    if (this.recordPeriods.neededHarvestQuantity <= 0) {
      warnings.push(this.myApplication.getText('FM.neededHarvestQuantityIsZero'));
    }

    if (this.recordPeriods.numOfDeliveryWeeks <= 0 &&
      this.recordPeriods.alternativeDelivery) {
      warnings.push(this.myApplication.getText('FM.numDeliveryWeeksIsZero'));
    }

    return warnings;
  }

  getCultureInfoWarnings(): Array<String> {
    let warnings = new Array<String>();

    if (this.recordPeriods.harvestLength < 1) {
      warnings.push(this.myApplication.getText('FM.numHarvestWeeksIsZero'));
    }

    if (this.plantDistance <= 0) {
      warnings.push(this.myApplication.getText('FM.plantDistanceIsZero'));
    }

    if (this.rowDistance <= 0) {
      warnings.push(this.myApplication.getText('FM.rowDistanceIsZero'));
    }

    if (this.numRows <= 0) {
      warnings.push(this.myApplication.getText('FM.numRowsIsZero'));
    }

    if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.hasQuickpotWarnings()) {
      warnings.push(this.myApplication.getText('FM.QuickPotInfosMissing'));
    }

    if (this.hasPlantBoxesWarnings()) {
      warnings.push(this.myApplication.getText('FM.BoxInfoMissing'));
    }

    return warnings;
  }

  quantityFits(): boolean {
    if (!this.calculated) {
      this.quantityCalculated1 = this.calculateQuantity();
      this.bedMCalculated = this.calculateBedM();
      this.calculated = true;
    }
    if (this.bedMCalculated != this.lengthOnBed || this.bedMCalculated == 0) {
      this.bedMWarning = true;
    } else {
      this.bedMWarning = false;
    }
    if (this.quantity == this.quantityCalculated1 && this.quantity > 0) {
      return true;
    } else {
      return false;
    }
  }

  addTags(tags: string) {
    if (this.recordEO.tags != '') {
      this.recordEO.tags = this.recordEO.tags.toString() + ' ' + tags.toString();
    } else {
      this.recordEO.tags = tags.toString();
    }

    this._tagList = new TagBuilder().buildTags(this.recordEO.tags);
  }

  public get tagList(): Array<string> {
    if (!this._tagList) {
      this._tagList = new TagBuilder().buildTags(this.recordEO.tags);
    }
    return this._tagList;
  }

  get tags(): String {
    return this.recordEO.tags;
  }

  includesTag(tag: string): boolean {
    if (this.tags.includes(tag) || this.culture.tags.includes(tag)) {
      return true;
    } else {
      return false;
    }
  }

  getNumQuickpots(): number {
    let numQuickpots = 0;
    if (this.quickpotSize > 0) {
      numQuickpots = Math.ceil(this.quantity / this.quickpotSize);
    }

    return numQuickpots;
  }

  getNumBoxes(): number {
    let num = 0;
    if (this.plantsPerBox > 0) {
      num = Math.ceil(this.quantity / this.plantsPerBox);
    }

    return num;
  }


  set tags(value: String) {
    this.recordEO.tags = '';
    this.addTags(value.toString());
  }

  public calculateQuantities(messages?: boolean) {
    if (this.fixUserInput == Constants.FIX_USER_INPUT_PLANTS) {
      // Pfanzen wurden manuell eingegeben nur Beetmeter neu berechnen
      this.calculateBedMToRecord();
      this.generateInfoTextFixPlants();
    } else if (this.fixUserInput == Constants.FIX_USER_INPUT_BEDMETERS) {
      // Beetmeter wurden manuell eingegeben nur Planzen neu berechnen
      this.calculateQuantityWithBedM();
      this.generateInfoTextFixBedMeters();
    } else {
      // Pfanzen und Beetmeter neu berechnen
      this.calculateQuantityToRecord(messages);
      this.calculateBedMToRecord();
      this.generateInfoText();
    }
  }

  public updateFromSort() {
    let sort = this.getSort();
    if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
      this.recordPeriods.setGrowDateBySeedLength(sort.seed);
    }
    this.recordPeriods.setHarvestDateByGrowLength(sort.grow);
    this.recordPeriods.harvestLength = sort.harvest;

    this.myApplication.messageDTO.setSuccessWithId('CultureTimesUpdated');
  }

  public updateFromSort2() {
    let sort = this.getSort();

    this.plantDistance = sort.plantDistance;
    this.rowDistance = sort.rowDistance;
    this.numRows = sort.numRows;

    this.myApplication.messageDTO.setSuccessWithId('CultureTimesUpdated');
  }

  public updateFromSort4() {
    let sort = this.getSort();

    this.seedRate = sort.seedRate;
    this.myApplication.messageDTO.setSuccessWithId('CultureTimesUpdated');
  }

  public updateFromSort3() {
    this.updateFromSort();
    this.updateFromSort2();
  }

  public updateFromSort5() {
    this.updateFromSort();
    this.updateFromSort4();
  }

  public updateFromSort6() {
    this.updateFromSort2();
    this.updateFromSort4();
  }

  public updateFromSort7() {
    this.updateFromSort();
    this.updateFromSort2();
    this.updateFromSort4();
  }

  public updateFromCulture() {
    if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
      this.recordPeriods.setGrowDateBySeedLength(this.culture.seed);
    }
    this.recordPeriods.setHarvestDateByGrowLength(this.culture.grow);
    this.recordPeriods.harvestLength = this.culture.harvest;
    this.myApplication.messageDTO.setSuccessWithId('CultureTimesUpdated');
  }

  public updateFromCulture2() {
    this.plantDistance = this.culture.plantDistance;
    this.rowDistance = this.culture.rowDistance;
    this.numRows = this.culture.numRows;
  }

  public updateFromCulture3() {
    this.updateFromCulture();
    this.updateFromCulture2();
  }

  public getSeedPeriodText(): String {
    let seedPeriod: String;
    if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
      seedPeriod = this.myApplication.getText('Culture.seed') + ' ' + this.recordPeriods.seedWeek.week + '. - ' +
        this.recordPeriods.lastSeedWeek.week + '. ' + this.myApplication.getText('CalendarWeek');
    }
    return seedPeriod;
  }

  public getGrowPeriodText(): String {
    let growPeriod: String;
    growPeriod = this.myApplication.getText('Culture.grow') + ' ' + this.recordPeriods.growWeek.week + '. - ' + this.recordPeriods.lastGrowWeek.week +
      '. ' + this.myApplication.getText('CalendarWeek');
    return growPeriod;
  }

  public getHarvestPeriodText(): String {
    let harvestPeriod: String;
    harvestPeriod = this.myApplication.getText('Harvest') + ' ' + this.recordPeriods.harvestWeek.week + '. - ' + this.recordPeriods.lastHarvestWeek.week +
      '. ' + this.myApplication.getText('CalendarWeek');

    return harvestPeriod;
  }

  public distancesFilled(): boolean {
    return (this.plantDistance > 0 && this.numRows > 0);
  }

  public anzuchtInfosComplete(): boolean {
    if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
      return (this.quickpotSize > 0 && this.seedsPerQuickpot > 0);
    } else {
      return true;
    }
  }

  public lengthOnBedCorrect(): boolean {
    if (this.lengthOnBed == this.bedAssignList.getLengthPlanned()) {
      return true;
    } else if (this.bedAssignList.getLengthPlanned() != 0) {
      return false;
    }
    else {
      return true;
    }
  }


  public get todoList(): ToDoListRecord {
    if (!this._todoList) {
      this._todoList = new ToDoListRecord(this.daoToDos, this);

      this._todoList.toDos = this._todoList.toDos.sort(function (one, two) {
        let compare = 0;
        if (one.dueDate > two.dueDate) {
          compare = 1;
        } else if (one.dueDate < two.dueDate) {
          compare = -1
        } else {
          compare = 0;
        }

        return compare;
      });
    }
    return this._todoList;
  }
  public set todoList(value: ToDoListRecord) {
    this._todoList = value;
  }

  public get bedAssignList(): BedAssignList {
    if (!this._bedAssignList) {
      this._bedAssignList = new BedAssignList(this.id, this, this.daoBedAssigns, this.myApplication);
    }
    return this._bedAssignList;
  }
  public set bedAssignList(value: BedAssignList) {
    this._bedAssignList = value;
  }

  public get recordPeriods(): RecordPeriods {
    if (!this._recordPeriods) {
      this._recordPeriods = new RecordPeriods(<RecordEO>this.eo, this.basicService, this.culture, this.myApplication, this);
      // this._recordPeriods.addObserver(this);
    }
    return this._recordPeriods;
  }
  public set recordPeriods(value: RecordPeriods) {
    this._recordPeriods = value;
  }

  public get periodsUIAreaBed(): PeriodsUIList {
    if (!this._periodsUIAreaBed) {
      this._periodsUIAreaBed = this.buildPeriodsUIAreaBed();
    }
    return this._periodsUIAreaBed;
  }
  public set periodsUIAreaBed(value: PeriodsUIList) {
    this._periodsUIAreaBed = value;
  }

  public get periodsUIBedMeters(): PeriodsUIList {
    if (!this._periodsUIBedMeters) {
      this._periodsUIBedMeters = this.buildPeriodsUIBedMeters();
    }
    return this._periodsUIBedMeters;
  }
  public set periodsUIBedMeters(value: PeriodsUIList) {
    this._periodsUIBedMeters = value;
  }

  public shift(numWeeks: number) {
    this.recordPeriods.shift(numWeeks);
  }

  private buildPeriodsUIAreaBed(): PeriodsUIList {
    let list = new PeriodsUIList(this.myApplication, this.basicService, this);
    list.createForAreaBed(this.recordPeriods);
    return list;
  }
  private buildPeriodsUIBedMeters(): PeriodsUIList {
    let list = new PeriodsUIList(this.myApplication, this.basicService, this);
    list.createForAreaBed(this.recordPeriods);
    return list;
  }

  public get periodsUI(): PeriodsUIList {
    if (!this._periodsUI) {
      this.buildPeriodsUI();
    }
    return this._periodsUI;
  }
  public set periodsUI(value: PeriodsUIList) {
    this._periodsUI = value;
  }

  public get culture(): Culture {
    if (this._culture == null && this.daoCultures) {
      this._culture = <Culture>this.daoCultures.getObjectById(this.cultureId);
      if (this._culture) {
        this.recordEO.cultureName = this._culture.name.toString();
      }
    }
    return this._culture;
  }
  public set culture(value: Culture) {
    this._culture = value;
    this.cultureId = value.id;
  }

  update() {
    this.myApplication.addObserver(this);
    this.myApplication.eventHandler.register(EventHandler.APP_YEAR_CHANGED, this);
    this.myApplication.eventHandler.register(EventHandler.EVENT_RECORD_DATES_CHANGED, this);
    this.daoToDos.addObserver(this);
    this.periodsUIAreaBed = null;
    this.periodsUIBedMeters = null;
    this.todoList = null;
    this.bedAssignList;
  }

  handleRecordDatesChanged(id: String) {
    if (this.id == id) {
      this.periodsUIAreaBed = null;
      this.periodsUIBedMeters = null;
      this.periodsUI = null;
    }
  }

  handleAppYearChanged() {
    this.periodsUI = null;
  }

  public startsInPeriod(startWeek, lastWeek): boolean {
    return this.basicService.isInPeriod(startWeek, lastWeek, this.recordPeriods.getStartWeek());
  }

  public getYear(): number {
    return this.recordPeriods.getStartWeek().year;
  }

  protected get recordEO(): RecordEO {
    return <RecordEO>this.eo;
  }

  public get cultureId(): String {
    return this.recordEO.cultureId;
  }
  public set cultureId(value: String) {
    this.recordEO.cultureId = value;
    this._culture = null;
  }

  public get sort(): String {
    return this.recordEO.sort;
  }
  public set sort(value: String) {
    this.recordEO.sort = value;
  }

  public get areaId(): String {
    return this.recordEO.areaId;
  }
  public set areaId(value: String) {
    this.recordEO.areaId = value;
  }

  public getArea(): Area {
    return <Area>this.daoAreas.getObjectById(this.areaId);
  }

  public get seedId(): String {
    return this.recordEO.seedId;
  }
  public set seedId(value: String) {
    this.recordEO.seedId = value;
  }

  public get quickId(): number {
    return this.recordEO.quickId;
  }
  public set quickId(value: number) {
    this.recordEO.quickId = value;
  }

  public get recordNum(): number {
    return this.recordEO.recordNum;
  }
  public set recordNum(value: number) {
    this.recordEO.recordNum = value;
  }

  public get state(): String {
    return this.recordEO.state;
  }
  public set state(value: String) {
    this.recordEO.state = value;
  }

  public get quantity(): number {
    return this.recordEO.quantity;
  }
  public set quantity(value: number) {
    if (this.recordEO.quantity != value) {
      this.recordEO.quantity = value;
      this.calculated = false;
      // this.calculateBedMToRecord();
    }
  }

  public get plantDistance(): number {
    return this.recordEO.plantDistance;
  }
  public set plantDistance(value: number) {
    this.recordEO.plantDistance = value;
    this.calculateQuantities();
  }

  public get rowDistance(): number {
    return this.recordEO.rowDistance;
  }
  public set rowDistance(value: number) {
    if (this.recordEO.rowDistance != value) {
      this.recordEO.rowDistance = value;
      this.calculateQuantities();
    }
  }

  public get plantDeepth(): number {
    return this.recordEO.plantDeepth;
  }
  public set plantDeepth(value: number) {
    this.recordEO.plantDeepth = value;
  }

  public get numRows() {
    return this.recordEO.numRows;
  }
  public set numRows(value) {
    if (this.recordEO.numRows != value) {
      this.recordEO.numRows = value;
      this.calculateQuantities();
    }
  }

  public get quickpotSize(): number {
    return this.recordEO.quickpotSize;
  }
  public set quickpotSize(value: number) {
    this.recordEO.quickpotSize = value;
  }

  public get seedsPerQuickpot(): number {
    return this.recordEO.seedsPerQuickpot;
  }
  public set seedsPerQuickpot(value: number) {
    this.recordEO.seedsPerQuickpot = value;
  }

  public get lengthOnBed(): number {
    return this.recordEO.lengthOnBed;
  }
  public set lengthOnBed(value: number) {
    if (value != this.recordEO.lengthOnBed) {
      this.recordEO.lengthOnBed = value;
      this.periodsUIAreaBed = null;
      this.calculated = false;
      if (this.recordEO.lengthOnBed < this.bedAssignList.getLengthPlanned()) {
        this.myApplication.messageDTO.setErrorWithId('FM.PlannedBedMSmaller');
      }
    }
  }

  public get harvestMethod(): String {
    return this.recordEO.harvestMethod;
  }
  public set harvestMethod(value: String) {
    if (this.recordEO.harvestMethod != value) {
      this.recordEO.harvestMethod = value;
      this.calculateQuantities();
    }
  }

  public get note(): String {
    return this.recordEO.note;
  }
  public set note(value: String) {
    this.recordEO.note = value;
  }

  public get safety(): number {
    return this.recordEO.safety;
  }
  public set safety(value: number) {
    if (this.recordEO.safety != value) {
      this.recordEO.safety = value;
      this.calculateQuantities();
    }
  }

  public get cultureType(): String {
    return this.recordEO.cultureType;
  }
  public set cultureType(value: String) {
    if (this.recordEO.cultureType != value) {
      this.recordEO.cultureType = value;
      if (this.cultureType != Constants.CULTURE_TYPE_ANZUCHT) {
        this.recordEO.seedDate.setTime(this.recordEO.growDate.getTime());
      }
      this.myApplication.eventHandler.raiseEvent(EventHandler.EVENT_RECORD_START_CHANGED, this.cultureId);
      this.myApplication.eventHandler.raiseEvent(EventHandler.EVENT_RECORD_DATES_CHANGED, this.id);
      this.buildCultureDateToDos();
      this.calculateQuantities();
    }
  }

  public get fixUserInput(): String {
    return this.recordEO.fixUserInput;
  }
  public set fixUserInput(value: String) {
    this.recordEO.fixUserInput = value;
  }

  public getNeededSeeds(): number {
    let seedsPerQuickpot = this.seedsPerQuickpot;
    if (seedsPerQuickpot < 1) {
      seedsPerQuickpot = 1;
    }
    if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT || this.cultureType == Constants.CULTURE_TYPE_ANZUCHTVERKAUF) {
      return this.quantity * seedsPerQuickpot;
    } else if (this.cultureType == Constants.CULTURE_TYPE_DIREKTSAAT) {
      return this.quantity;
    } else {
      return 0;
    }
  }

  public getNeededSeedsOtherUnit(): number {
    let quantity: number = 0;
    let plantsPerM2: number = 0;
    // Anzahl Pflanzen pro m2 ermitteln
    plantsPerM2 = this.calculatePlantPerM2();
    //Anzahl m2 ermitteln
    quantity = this.quantity / plantsPerM2;

    return quantity;

  }


  public calculatePlantPerM2(): number {
    let plantPerColumn: number = 0;
    let plantPerRow: number = 0;
    let plantPerM2: number = 0;

    if (this.plantDistance == 0) {
      if (this.myApplication) {
        this.myApplication.messageDTO.setErrorWithId('FM.plantDistanceIsZero');
      }
      return 0;
    }
    if (this.rowDistance == 0) {
      if (this.myApplication) {
        this.myApplication.messageDTO.setErrorWithId('FM.rowDistanceIsZero');
      }
      return 0;
    }
    plantPerColumn = 100 / this.plantDistance;
    //plantPerColumn = Math.floor(plantPerColumn);
    plantPerRow = 100 / this.rowDistance;
    //plantPerRow = Math.floor(plantPerRow);
    plantPerM2 = plantPerColumn * plantPerRow;
    return plantPerM2;
  }

  public getRecordName() {
    if (this.culture && this.sort && this.sort != '') {
      return this.culture.name + ' ' + this.recordNum + ' ' + this.sort;
    } else if (this.culture) {
      return this.culture.name + ' ' + this.recordNum;
    } else {
      return 'ich bin ein alter Satz! lösch mich!';
    }
  }

  public getRecordNameWithoutSort() {
    if (this.culture) {
      return this.culture.name + ' ' + this.recordNum;
    } else {
      return 'ich bin ein alter Satz! lösch mich!';
    }
  }

  get plantsPerBox(): number {
    return this.recordEO.plantsPerBox;
  }
  set plantsPerBox(value: number) {
    this.recordEO.plantsPerBox = value;
  }

  public hasPlantBoxesWarnings(): boolean {
    if (this.cultureType != Constants.CULTURE_TYPE_PFLANZKAUF) {
      return false;
    }

    if (this.plantsPerBox <= 0) {
      return true;
    } else {
      return false;
    }
  }

  public getName() {
    return this.getRecordName();
  }

  public getRecordNameWithYear(): String {
    return this.getRecordName() + ' (' + this.recordPeriods.getStartWeek().year + ')';
  }

  public getRecordNameWithoutCulture(): String {
    if (this.sort && this.sort != '') {
      return this.recordNum.toString() + ' ' + this.sort + ' (' + this.recordPeriods.getStartWeek().year + ')';
    } else {
      return this.recordNum.toString() + ' (' + this.recordPeriods.getStartWeek().year + ')';
    }
  }

  public getSort(): Sort {
    for (let i = 0; i < this.culture.sorts.length; i++) {
      let sortTrim = this.culture.sorts[i].name.trim();
      let sortTrim2 = this.sort.trim();
      if (sortTrim == sortTrim2) {
        return this.culture.sorts[i];
      }
    }
    return null;
  }

  public getFullSortName() {
    return this.culture.name + ' ' + this.sort;
  }

  initialize() {
    this.eo = new RecordEO();
  }

  removeFromBed() {
    this.update();
  }

  clone(): Record {
    let newRecord = <Record>this.daoRecords.createNewInstance();
    newRecord.sort = this.sort;
    newRecord.culture = this.culture;
    newRecord.recordEO.cultureType = this.cultureType;
    newRecord.recordNum = this.recordNum;

    newRecord.fixUserInput = this.fixUserInput;

    newRecord.plantDistance = this.plantDistance;
    newRecord.rowDistance = this.rowDistance;
    newRecord.plantDeepth = this.plantDeepth;
    newRecord.numRows = this.numRows;
    newRecord.quickpotSize = this.quickpotSize;
    newRecord.seedsPerQuickpot = this.seedsPerQuickpot;
    newRecord.plantsPerBox = this.plantsPerBox;

    newRecord.lengthOnBed = this.lengthOnBed;
    newRecord.harvestMethod = this.harvestMethod;
    // newRecord.harvestUnit = this.harvestUnit;
    newRecord.note = this.note;
    newRecord.safety = this.safety;
    newRecord.seedRate = this.seedRate;
    newRecord.areaId = this.areaId;


    newRecord.recordPeriods.extraQuantity = this.recordPeriods.extraQuantity;
    newRecord.recordPeriods.useExtraQuantity = this.recordPeriods.useExtraQuantity;

    newRecord.recordPeriods.clone(this.recordPeriods);
    newRecord.todoList.clone(this);

    newRecord.quantity = this.quantity;

    newRecord.addTags(this.tags.toString());
    newRecord.buildCultureDateToDos();
    newRecord.update();
    this.myApplication.eventHandler.raiseEvent(EventHandler.EVENT_RECORD_CREATED, this.id);

    return newRecord;
  }

  cloneToNextYear(): Record {
    let newRecord = this.clone()
    newRecord.recordPeriods.cloneToNextYear(this.recordPeriods);

    newRecord.update();

    return newRecord;
  }

  public createFromRecordCreateDTO(recordCreateDTO: RecordCreateDTO) {
    this.cultureId = recordCreateDTO.culture.id;
    this.culture = recordCreateDTO.culture;
    this.sort = recordCreateDTO.sort;
    if (this.sort == null) {
      this.sort = '';
    }
    this.state = 'P';

    this.recordEO.plantDistance = recordCreateDTO.plantDistance;
    this.recordEO.rowDistance = recordCreateDTO.rowDistance;
    this.recordEO.plantDeepth = recordCreateDTO.culture.plantDeepth;
    this.recordEO.numRows = recordCreateDTO.numRows;
    this.recordEO.quickpotSize = recordCreateDTO.culture.quickpotSize;
    this.recordEO.seedsPerQuickpot = recordCreateDTO.culture.seedsPerQuickpot;
    this.recordEO.plantsPerBox = recordCreateDTO.culture.plantsPerBox;

    this.recordEO.harvestMethod = recordCreateDTO.harvestMethod;

    this.recordEO.cultureType = recordCreateDTO.cultureType;
    this.recordEO.safety = recordCreateDTO.safety;
    this.recordEO.seedRate = recordCreateDTO.seedRate;
    this.recordEO.extraQuantity = recordCreateDTO.extraQuantity;
    this.recordEO.useExtraQuantity = recordCreateDTO.useExtraQuantity;
    this.recordEO.areaId = recordCreateDTO.areaId;

    this.recordPeriods.createFromRecordCreateDTO(recordCreateDTO);
    this.addTags(recordCreateDTO.tags.toString());
    //this.recordPeriods.createHarvestDistribution(); wird durch Länge getriggert

    if (recordCreateDTO.useQuantity || (this.repoService.repository.system != Constants.SYSTEM_SOLAWI)) {
      this.quantity = recordCreateDTO.quantity;
      this.lengthOnBed = recordCreateDTO.lengthOnBed;
      this.fixUserInput = recordCreateDTO.fixUserInput;
    } else {
      this.quantity = this.calculateQuantity();
      this.lengthOnBed = this.calculateBedM();
    }

    this.createToDos(recordCreateDTO.templates);

    this.myApplication.eventHandler.raiseEvent(EventHandler.EVENT_RECORD_CREATED, this.id);
    this.daoRecords.objectSet.update();
  }

  public createToDos(templates: Array<Template>) {
    this.todoList; //aufbauen bitte
    templates.forEach(template => {
      if (template.checkedForRecord) {
        let toDo = template.createToDO();
        toDo.parentType = Constants.PARENT_TYPE_RECORD;
        toDo.parentId = this.id;
        if (toDo.isSeriesMaster()) {
          toDo.generateSeriesToDos();
        }
        //this.todoList.toDos.push(toDo); geht von ganz von selbst, braucht man gar nicht
      }
    });
    this.buildCultureDateToDos();
  }

  public buildCultureDateToDos() {
    let todo: ToDo;

    this.todoList.deleteRecordDates();

    if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
      todo = <ToDo>this.daoToDos.createNewInstance();
      todo.parentType = Constants.PARENT_TYPE_RECORD;
      todo.parentId = this.id;
      todo.toDoType = Constants.TODO_RECORD_DATES;
      todo.type = this.myApplication.getText('ToDo.type.' + Constants.TODO_TYPE_ANZUCHT);
      todo.cultureDatType = Constants.TODO_TYPE_ANZUCHT;
      todo.color = Constants.COLOR_ANZUCHT;
      todo.time = 0;
      todo.timeUnit = Constants.TIME_UNIT_TAGE;
      todo.timeType = Constants.TEMPLATE_PREPOSITION_BEFORE;
      todo.timeReference = Constants.TEMPLATE_TIME_REF_ANZUCHT;
    }

    todo = <ToDo>this.daoToDos.createNewInstance();
    todo.parentType = Constants.PARENT_TYPE_RECORD;
    todo.parentId = this.id;
    todo.toDoType = Constants.TODO_RECORD_DATES;
    if (this.cultureType == Constants.CULTURE_TYPE_DIREKTSAAT) {
      todo.type = this.myApplication.getText('ToDo.type.' + Constants.TODO_TYPE_AUSSAAT);
      todo.cultureDatType = Constants.TODO_TYPE_AUSSAAT;
    } else {
      todo.type = todo.type = this.myApplication.getText('ToDo.type.' + Constants.TODO_TYPE_PFLANZUNG);
      todo.cultureDatType = Constants.TODO_TYPE_PFLANZUNG;
    }
    todo.color = Constants.COLOR_PLANTED;
    todo.time = 0;
    todo.timeUnit = Constants.TIME_UNIT_TAGE;
    todo.timeType = Constants.TEMPLATE_PREPOSITION_BEFORE;
    todo.timeReference = Constants.TEMPLATE_TIME_REF_PFLANZUNG;
  }

  createHarvestFollowingRecord(recordCreateDTO?: RecordCreateDTO): Record {
    let newRecord = this.clone();
    newRecord.shift(this.recordPeriods.harvestLength);

    if (recordCreateDTO) {
      if (recordCreateDTO.useQuantity || (this.repoService.repository.system != Constants.SYSTEM_SOLAWI)) {
        newRecord.quantity = recordCreateDTO.quantity;
        newRecord.lengthOnBed = recordCreateDTO.lengthOnBed;
      }
    }

    return newRecord;
  }

  public calculateBedMToRecord() {
    //Beetmeter berechnen und setzen
    let calcualtedResult = this.calculateBedM();
    this.lengthOnBed = calcualtedResult;
    if (calcualtedResult == 0) {
      this.bedMWarning = true;
    } else {
      this.bedMWarning = false;
    }
  }

  get bedMWarningValue(): number {
    if (this.bedMWarning || !this.quantityFits()) {
      return this.lengthOnBed;
    } else {
      return this.lengthOnBed - 1;
    }
  }

  get quantityWarningValue(): number {
    if (!this.quantityFits() || this.quantity == 0) {
      return this.quantity;
    } else {
      return this.quantity - 1;
    }
  }

  public calculateBedM(): number {
    return new CalculatorLengthOnBed(this.numRows, this.quantity, this.plantDistance, this.basicService, this.seedRate, this.cultureType).calculate();
  }

  public calculateQuantityWithBedM() {
    let calcualtedResult = new CalculatorQuantityWithBedM(this.numRows, this.lengthOnBed, this.plantDistance,
      this.basicService, null, this.seedRate, this.cultureType).calculate();
    if (calcualtedResult > 0) {
      this.quantity = calcualtedResult;
    }
  }

  public calculateQuantityToRecord(messages?: boolean) {
    let calcualtedResult = this.calculateQuantity(messages);
    this.quantityCalculated1 = calcualtedResult;
    if (calcualtedResult == 0 && messages) {
      this.myApplication.messageDTO.setErrorWithId('FM.Record.calculationNotPossible');
    }
    this.quantity = calcualtedResult;
  }

  //Berechne die benoetigte Pflanzenmenge
  public calculateQuantity(messages?: boolean): number {

    let numHarvestWeeks;
    let neededQuantity = this.recordPeriods.neededHarvestQuantity;
    let extraQuantity = this.recordPeriods.extraQuantity;

    if (this.recordPeriods.isAlternativeDeliverySelected() == true) {
      numHarvestWeeks = this.recordPeriods.numOfDeliveryWeeks;
    } else {
      numHarvestWeeks = this.recordPeriods.harvestLength;
    }
    let application = null;
    if (messages) {
      application = this.myApplication;
    }
    let calculator = new CalculatorQuantity(this.harvestMethod, this.getHarvestQuantity(), numHarvestWeeks, neededQuantity, this.recordPeriods.harvestWeek,
      this.recordPeriods.isAlternativeDeliverySelected(), this.safety, this.plantDistance, this.rowDistance, application, this.basicService, this.seedRate,
      this.cultureType, this.partsService, extraQuantity, this.repoService, this.recordPeriods.harvestUnit);
    return calculator.calculate();
  }

  public getHarvestQuantity(): number {
    let harvestQuantity: number = 0;

    harvestQuantity = this.recordPeriods.harvestQuantity;

    return harvestQuantity;
  }

  //Berechne die Samenmenge
  public calculateSeedCapacity(): number {
    let capacity = 0;
    return capacity;
  }

  //Samenmenge reduzieren um die berechnete Samenmenge
  reduceSeed(): number {
    return 0;
  }

  protected buildPeriodsUI() {
    this._periodsUI = new PeriodsUIList(this.myApplication, this.basicService, this);
    this._periodsUI.createFromRecordPeriods(this);
  }

  public get selected(): boolean {
    return this._selected;
  }
  public set selected(value: boolean) {
    this._selected = value;
  }

  public generateInfoText() {
    // Parameter besorgen
    let harvestWeekInYear = this.recordPeriods.harvestWeek;
    let filteredParts: FilteredParts = new FilteredParts();
    let numOfParts = filteredParts.getCurrentPartsNumParts(this.partsService.parts, harvestWeekInYear);
    if ((!numOfParts || numOfParts == 0) && this.repoService.repository.system != Constants.SYSTEM_SOLAWI) {
      numOfParts = 1;
    }
    let harvestWeeks: number;
    if (!this.recordPeriods.alternativeDelivery) {
      harvestWeeks = this.recordPeriods.harvestLength;
    }
    else {
      harvestWeeks = this.recordPeriods.numOfDeliveryWeeks;
    }
    let neededHarvestQuantity = this.recordPeriods.neededHarvestQuantity;
    let extraQuantity = this.recordPeriods.extraQuantity;
    let harvestQuantity = this.recordPeriods.harvestQuantity
    let quantityUnit = this.recordPeriods.harvestUnit;
    let plantDistance = this.plantDistance;
    let rowDistance = this.rowDistance;
    let numRows = this.numRows;
    let safety = this.safety;
    let seedRate = this.seedRate;

    let calculator = new CalculatorQuantity(this.harvestMethod, harvestQuantity,
      harvestWeeks, neededHarvestQuantity, harvestWeekInYear, this.recordPeriods.alternativeDelivery,
      safety, plantDistance, rowDistance, null, this.basicService, this.seedRate, this.cultureType, this.partsService, extraQuantity, this.repoService,
      this.recordPeriods.harvestUnit);
    let numPlants = calculator.calculate();

    let neededM2 = calculator.calculateWithHarvestQuantity((numOfParts * neededHarvestQuantity * harvestWeeks), harvestQuantity);
    let neededM2Week = calculator.calculateWithHarvestQuantity((numOfParts * neededHarvestQuantity), harvestQuantity);
    let plantPerM2 = calculator.calculatePlantPerM2();

    let calculatorBedMeters = new CalculatorLengthOnBed(numRows, numPlants, plantDistance, this.basicService, this.seedRate, this.cultureType);
    let bedMeters = calculatorBedMeters.calculate();
    let plantBedMeters = this.basicService.ownCeil(bedMeters * numRows * 100 / plantDistance);

    // fuer Solawi
    if (this.repoService.repository.system == Constants.SYSTEM_SOLAWI) {

      if (this.harvestMethod == Constants.HARVEST_METHOD_ABSOLUT) {
        // Keimrate relevant?
        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seedRate != 100) {
          this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextPlantPartsSeedRate', numOfParts, harvestWeeks, neededHarvestQuantity,
            this.myApplication.getText('Unit.' + quantityUnit), safety, seedRate, numPlants);
          this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + numPlants + " = " + numOfParts + " * " + harvestWeeks + " * " +
            neededHarvestQuantity + " + " + safety + " % " + this.myApplication.getText('Culture.safety') +
            " + " + seedRate + " % " + this.myApplication.getText('Culture.seedRate') + "). ";
          // zusaetzliche Erntemenge relevant?
          if (this.recordPeriods.useExtraQuantity) {
            this.popoverInfoTextPlant = this.popoverInfoTextPlant + ' ' +
              this.myApplication.getText('Record.infoTextExtraPlants', this.recordPeriods.extraQuantity,
                this.myApplication.getText('Record.recordPeriods.harvestQuantity.' + this.recordPeriods.harvestUnit));
          }
          this.popoverInfoTextPlant = this.popoverInfoTextPlant + ' ' + this.myApplication.getText('Record.infoTextPlantParts2');
        }
        else {
          this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextPlantParts', numOfParts, harvestWeeks, neededHarvestQuantity,
            this.myApplication.getText('Unit.' + quantityUnit), safety, numPlants);
          this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + numPlants + " = " + numOfParts + " * " + harvestWeeks + " * " +
            neededHarvestQuantity + " + " + safety + " % " + this.myApplication.getText('Culture.safety') + "). ";

          // zusaetzliche Erntemenge relevant?
          if (this.recordPeriods.useExtraQuantity) {
            this.popoverInfoTextPlant = this.popoverInfoTextPlant + ' ' +
              this.myApplication.getText('Record.infoTextExtraPlants', this.recordPeriods.extraQuantity,
                this.myApplication.getText('Record.recordPeriods.harvestQuantity.' + this.recordPeriods.harvestUnit));
          }
          this.popoverInfoTextPlant = this.popoverInfoTextPlant + ' ' + this.myApplication.getText('Record.infoTextPlantParts2');
        }

      }
      else if (this.harvestMethod == Constants.HARVEST_METHOD_WEEK) {
        // Keimrate relevant?
        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seedRate != 100) {
          this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextPlantPartsWeekSeedRate', numOfParts, neededHarvestQuantity,
            this.myApplication.getText('Unit.' + quantityUnit), harvestQuantity, this.myApplication.getText('Unit.' + quantityUnit),
            safety, seedRate, numPlants);
          this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + numPlants + " = " + numOfParts + " * " + neededHarvestQuantity + " / " + harvestQuantity +
            " + " + safety + "% " + this.myApplication.getText('Culture.safety') + " + " + seedRate + "% " + this.myApplication.getText('Culture.seedRate') + "). ";

          // zusaetzliche Erntemenge relevant?
          if (this.recordPeriods.useExtraQuantity) {
            this.popoverInfoTextPlant = this.popoverInfoTextPlant + ' ' +
              this.myApplication.getText('Record.infoTextExtraPlants', this.recordPeriods.extraQuantity,
                this.myApplication.getText('Record.recordPeriods.harvestQuantity.' + this.recordPeriods.harvestUnit));
          }
          this.popoverInfoTextPlant = this.popoverInfoTextPlant + ' ' + this.myApplication.getText('Record.infoTextPlantParts2');
        }
        else {
          this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextPlantPartsWeek', numOfParts, neededHarvestQuantity,
            this.myApplication.getText('Unit.' + quantityUnit), harvestQuantity, this.myApplication.getText('Unit.' + quantityUnit),
            safety, numPlants);
          this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + numPlants + " = " + numOfParts + " * " + neededHarvestQuantity + " / " + harvestQuantity +
            " + " + safety + "% " + this.myApplication.getText('Culture.safety') + "). ";
          // zusaetzliche Erntemenge relevant?
          if (this.recordPeriods.useExtraQuantity) {
            this.popoverInfoTextPlant = this.popoverInfoTextPlant + ' ' +
              this.myApplication.getText('Record.infoTextExtraPlants', this.recordPeriods.extraQuantity,
                this.myApplication.getText('Record.recordPeriods.harvestQuantity.' + this.recordPeriods.harvestUnit));
          }
          this.popoverInfoTextPlant = this.popoverInfoTextPlant + ' ' + this.myApplication.getText('Record.infoTextPlantParts2');
        }
      }
      else if (this.harvestMethod == Constants.HARVEST_METHOD_M2) {

        this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextPlantPartsM2', numOfParts, harvestWeeks, neededHarvestQuantity,
          this.myApplication.getText('Unit.' + quantityUnit), harvestQuantity, this.myApplication.getText('Unit.' + quantityUnit),
          neededM2);
        this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + neededM2 + " = " + numOfParts + " * " + neededHarvestQuantity + " * " + harvestWeeks +
          " / " + harvestQuantity + " ). ";
        // zusaetzliche Erntemenge relevant?
        if (this.recordPeriods.useExtraQuantity) {
          this.popoverInfoTextPlant = this.popoverInfoTextPlant + ' ' +
            this.myApplication.getText('Record.infoTextExtraPlants', this.recordPeriods.extraQuantity,
              this.myApplication.getText('Record.recordPeriods.harvestQuantity.' + this.recordPeriods.harvestUnit));
        }
        this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantM22', plantPerM2, plantDistance, rowDistance);
        this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + plantPerM2 + " = ( 100 * 100 ) / (" + plantDistance + " * " + rowDistance + " )";
        // Keimrate relevant?
        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seedRate != 100) {
          this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantM23SeedRate', neededM2,
            plantPerM2, safety, seedRate, numPlants);
        }
        else {
          this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantM23', neededM2, plantPerM2, safety, numPlants);
        }
        this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantParts2');
      }
      else if (this.harvestMethod == Constants.HARVEST_METHOD_M2_WEEK) {
        this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextPlantPartsM2Week', numOfParts, neededHarvestQuantity,
          this.myApplication.getText('Unit.' + quantityUnit), harvestQuantity, this.myApplication.getText('Unit.' + quantityUnit),
          neededM2Week);
        this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + neededM2Week + " = " + numOfParts + " * " + neededHarvestQuantity + " * " +
          " / " + harvestQuantity + " ). ";

        // zusaetzliche Erntemenge relevant?
        if (this.recordPeriods.useExtraQuantity) {
          this.popoverInfoTextPlant = this.popoverInfoTextPlant + ' ' +
            this.myApplication.getText('Record.infoTextExtraPlants', this.recordPeriods.extraQuantity,
              this.myApplication.getText('Record.recordPeriods.harvestQuantity.' + this.recordPeriods.harvestUnit));
        }

        this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantM22', plantPerM2, plantDistance, rowDistance);
        this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + plantPerM2 + " = ( 100 * 100 ) / (" + plantDistance + " * " + rowDistance + " )";
        // Keimrate relevant?
        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seedRate != 100) {
          this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantM23SeedRate', neededM2Week, plantPerM2,
            safety, seedRate, numPlants);
          this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + numPlants + " = " + neededM2Week + " * " + plantPerM2 + " + " +
            seedRate + " % " + this.myApplication.getText('Culture.seedRate') + " )";
        }
        else {
          this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantM23', neededM2Week, plantPerM2, safety, numPlants);
          this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + numPlants + " = " + neededM2Week + " * " + plantPerM2 + " )";
        }

        this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantParts2');
      }
    }

    // nicht SoLaWi
    else {
      if (this.harvestMethod == 'A') {
        // Keimrate relevant?
        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seedRate != 100) {
          this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextPlantSeedRate', harvestWeeks, neededHarvestQuantity,
            this.myApplication.getText('Unit.' + quantityUnit), safety, seedRate, numPlants);
          this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + numPlants + " = " + harvestWeeks + " * " +
            neededHarvestQuantity + " + " + safety + " % " + this.myApplication.getText('Culture.safety') +
            " + " + seedRate + " % " + this.myApplication.getText('Culture.seedRate') + "). ";
        }
        else {
          this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextPlant', harvestWeeks, neededHarvestQuantity,
            this.myApplication.getText('Unit.' + quantityUnit), safety, numPlants);
          this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + numPlants + " = " + harvestWeeks + " * " +
            neededHarvestQuantity + " + " + safety + " % " + this.myApplication.getText('Culture.safety') + "). ";
        }
      }
      else if (this.harvestMethod == Constants.HARVEST_METHOD_WEEK) {
        // Keimrate relevant?
        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seedRate != 100) {
          this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextPlantWeekSeedRate', neededHarvestQuantity,
            this.myApplication.getText('Unit.' + quantityUnit), harvestQuantity, this.myApplication.getText('Unit.' + quantityUnit),
            safety, seedRate, numPlants);
          this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + numPlants + " = " + neededHarvestQuantity + " / " + harvestQuantity +
            " + " + safety + "% " + this.myApplication.getText('Culture.safety') +
            " + " + seedRate + "% " + this.myApplication.getText('Culture.seedRate') + "). "
        }
        else {
          this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextPlantWeek', neededHarvestQuantity,
            this.myApplication.getText('Unit.' + quantityUnit), harvestQuantity, this.myApplication.getText('Unit.' + quantityUnit),
            safety, numPlants);
          this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + numPlants + " = " + neededHarvestQuantity + " / " + harvestQuantity +
            " + " + safety + "% " + this.myApplication.getText('Culture.safety') + "). "
        }
      }
      else if (this.harvestMethod == Constants.HARVEST_METHOD_M2) {
        this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextPlantM2', harvestWeeks, neededHarvestQuantity,
          this.myApplication.getText('Unit.' + quantityUnit), harvestQuantity, this.myApplication.getText('Unit.' + quantityUnit),
          neededM2);
        this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + neededM2 + " = " + neededHarvestQuantity + " * " + harvestWeeks +
          " / " + harvestQuantity + " ). ";

        this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantM22', plantPerM2, plantDistance, rowDistance);
        this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + plantPerM2 + " = ( 100 * 100 ) / (" + plantDistance + " * " + rowDistance + " )";
        // Keimrate relevant?
        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seedRate != 100) {
          this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantM23SeedRate', neededM2, plantPerM2,
            safety, seedRate, numPlants);
        }
        else {
          this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantM23', neededM2, plantPerM2, safety, numPlants);
        }
      }
      else if (this.harvestMethod == Constants.HARVEST_METHOD_M2_WEEK) {
        this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextPlantM2Week', neededHarvestQuantity,
          this.myApplication.getText('Unit.' + quantityUnit), harvestQuantity, this.myApplication.getText('Unit.' + quantityUnit),
          neededM2Week);
        this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + neededM2Week + " = " + neededHarvestQuantity +
          " / " + harvestQuantity + " ). ";

        this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantM22', plantPerM2, plantDistance, rowDistance);
        this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + plantPerM2 + " = ( 100 * 100 ) / (" + plantDistance + " * " + rowDistance + " )";
        // Keimrate relevant?
        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seedRate != 100) {
          this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantM23SeedRate',
            neededM2Week, plantPerM2, safety, seedRate, numPlants);
          this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + numPlants + " = " + neededM2Week + " * " + plantPerM2 + " + " +
            seedRate + " % " + this.myApplication.getText('Culture.seedRate') + " )";
        }
        else {
          this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantM23', neededM2Week, plantPerM2, safety, numPlants);
          this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + numPlants + " = " + neededM2Week + " * " + plantPerM2 + " )";
        }

      }
    }


    // Keimrate relevant?
    if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seedRate != 100) {
      this.popoverInfoTextBedMeters = this.myApplication.getText('Record.infoTextBedMetersSeedRate', bedMeters, numRows, plantDistance, numPlants, seedRate) +
        " ( " + bedMeters + "m = " + numPlants + " / " + numRows + " * " + plantDistance + "cm + " + seedRate + " % " +
        this.myApplication.getText('Culture.seedRate') + ")";

      this.popoverInfoTextPlantBedMeters = this.myApplication.getText('Record.infoTextPlantsBedMeters', bedMeters, plantBedMeters) + " ( " + plantBedMeters +
        " = " + bedMeters + "m * " + numRows + " / " + plantDistance + "cm )";
    }
    else {
      this.popoverInfoTextBedMeters = this.myApplication.getText('Record.infoTextBedMeters', bedMeters, numRows, plantDistance, numPlants) +
        " ( " + bedMeters + "m = " + numPlants + " / " + numRows + " * " + plantDistance + "cm )";

      this.popoverInfoTextPlantBedMeters = this.myApplication.getText('Record.infoTextPlantsBedMeters', bedMeters, plantBedMeters) + " ( " + plantBedMeters +
        " = " + bedMeters + "m * " + numRows + " / " + plantDistance + "cm )";
    }
    this.popoverInfoTextRounded = this.myApplication.getText('Record.infoTextRounded');

  }

  public get seedRate(): number {
    return this.recordEO.seedRate;
  }
  public set seedRate(value: number) {
    this.recordEO.seedRate = value;
    this.calculateQuantities();
  }

  public generateInfoTextFixPlants() {

    let calculatorBedMeters = new CalculatorLengthOnBed(this.numRows, this.quantity, this.plantDistance, this.basicService, this.seedRate, this.cultureType);
    let bedMeters = calculatorBedMeters.calculate();
    let plantBedMeters = this.basicService.ownCeil(bedMeters * this.numRows * 100 / this.plantDistance);

    this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextPlantsFix');

    // Keimrate relevant?
    if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seedRate != 100) {
      this.popoverInfoTextBedMeters = this.myApplication.getText('Record.infoTextBedMetersSeedRate', bedMeters, this.numRows,
        this.plantDistance, this.quantity, this.seedRate) +
        " ( " + bedMeters + "m = " + this.quantity + " / " + this.numRows + " * " + this.plantDistance + "cm + " + this.seedRate + " % " +
        this.myApplication.getText('Culture.seedRate') + ")";

      this.popoverInfoTextPlantBedMeters = this.myApplication.getText('Record.infoTextPlantsBedMeters', bedMeters, plantBedMeters) + " ( " + plantBedMeters +
        " = " + bedMeters + "m * " + this.numRows + " / " + this.plantDistance + "cm )";
    }
    else {
      this.popoverInfoTextBedMeters = this.myApplication.getText('Record.infoTextBedMeters', bedMeters, this.numRows, this.plantDistance, this.quantity) +
        " ( " + bedMeters + "m = " + this.quantity + " / " + this.numRows + " * " + this.plantDistance + "cm )";

      this.popoverInfoTextPlantBedMeters = this.myApplication.getText('Record.infoTextPlantsBedMeters', bedMeters, plantBedMeters) + " ( " + plantBedMeters +
        " = " + bedMeters + "m * " + this.numRows + " / " + this.plantDistance + "cm )";
    }
    this.popoverInfoTextRounded = this.myApplication.getText('Record.infoTextRounded');

  }

  public generateInfoTextFixBedMeters() {
    let calcPlantsPerBedMeters = new CalculatorQuantityWithBedM(this.numRows, this.lengthOnBed,
      this.plantDistance, this.basicService, this.myApplication, this.seedRate, this.cultureType)
    let plantBedMeters = this.basicService.ownCeil(this.lengthOnBed * this.numRows * 100 / this.plantDistance);
    let plantsBedMetersSeedRate = calcPlantsPerBedMeters.calculate();

    this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextBedMetersFix');
    this.popoverInfoTextBedMeters = this.popoverInfoTextPlantBedMeters = this.myApplication.getText('Record.infoTextPlantsBedMeters', this.lengthOnBed,
      plantBedMeters) + " ( " + plantBedMeters + " = " + this.lengthOnBed + "m * " + this.numRows + " / " + this.plantDistance + "cm )";
    if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seedRate != 100) {
      this.popoverInfoTextBedMeters = this.popoverInfoTextBedMeters + " " + this.myApplication.getText('Record.infoTextFixBedMetersSeedRate',
        this.seedRate, plantsBedMetersSeedRate)
    }

    this.popoverInfoTextPlantBedMeters = '';
    this.popoverInfoTextRounded = '';
  }
}