import { AbstractObject } from '../technical-aspects/abstract-object';
import { RepositoryEO } from '../entity-objects/repository-eo';
import { Constants } from './constants';
import { environment } from 'src/environments/environment';

export class Repository extends AbstractObject {

  private showInfoText = true;


  constructor() {
    super();
  }

  update() {
  }

  // isExpired(): boolean { DEPRECATED
  //   if (this.package != Constants.PACKAGE_TEST) {
  //     return false;
  //   }
  //   let expireDate = new Date(this.repositoryEO.createdAndAgreedTS.toString());
  //   expireDate.setDate(expireDate.getDate() + 14);
  //   let today = new Date();
  //   let envExpireDate = new Date(environment.expireDate.toString());
  //   if (today.getTime() > expireDate.getTime() && today.getTime() >= envExpireDate.getTime()) {
  //     return true;
  //   }

  //   return false;
  // }

  protected get repositoryEO(): RepositoryEO {
    return <RepositoryEO>this.eo;
  }

  public get createAndAgreedTs(): String {
    return this.repositoryEO.createdAndAgreedTS;
  }
  public set createAndAgreedTs(value: String) {
    this.repositoryEO.createdAndAgreedTS = value;
  }

  public get name(): String {
    return this.repositoryEO.name;
  }
  public set name(value: String) {
    this.repositoryEO.name = value;
  }

  public get infoText(): String {
    if (this.showInfoText) {
      return this.repositoryEO.infoText;
    } else {
      return null;
    }
  }

  public closeInfoText() {
    this.showInfoText = false;
  }

  public get package(): String {
    return this.repositoryEO.package;
  }
  public set package(value: String) {
    this.repositoryEO.package = value;
  }

  public get partLimit(): number {
    return this.repositoryEO.partLimit;
  }
  public set partLimit(value: number) {
    this.repositoryEO.partLimit = value;
  }

  public get system(): String {
    return this.repositoryEO.system;
  }
  public set system(value: String) {
    this.repositoryEO.system = value;
    this.calculateBedDefaults();
  }

  public get bedWidth(): number {
    return this.repositoryEO.bedWidth;
  }
  public set bedWidth(value: number) {
    this.repositoryEO.bedWidth = value;
  }

  public get bedLength(): number {
    return this.repositoryEO.bedLength;
  }
  public set bedLength(value: number) {
    this.repositoryEO.bedLength = value;
  }

  initialize() {
    this.eo = new RepositoryEO();
  }

  protected calculateBedDefaults() {
    if (this.system == Constants.SYSTEM_SOLAWI) {
      this.bedWidth = 1.2;
      this.bedLength = 30;
    } else if (this.system == Constants.SYSTEM_MARKET_GARDENING) {
      this.bedWidth = 0.75;
      this.bedLength = 30;
    }
  }
}