import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeyValueDTO } from 'src/app/app-core/dto/key-value-dto';
import { TicketDTO } from 'src/app/app-core/dto/ticket-dto';
import { ApplicationService } from 'src/app/services/application.service';
import { BugtrackingService } from 'src/app/services/bugtracking.service';

@Component({
  selector: 'app-bug',
  templateUrl: './bug.component.html',
  styleUrls: ['./bug.component.css']
})
export class BugComponent implements OnInit {
  
  @Input() id;
  categories = new Array<KeyValueDTO>();
  priorities = new Array<KeyValueDTO>();
  releases = new Array<KeyValueDTO>();

  public ticketDTO = new TicketDTO();


  constructor(private bucktracking: BugtrackingService, public myApplication: ApplicationService,
      private modalService: NgbModal) {

        this.categories.push(new KeyValueDTO('Bug', 'Bug'));
    this.categories.push(new KeyValueDTO('Feature', 'Feature'));
    this.categories.push(new KeyValueDTO('Vorschlag', 'Vorschlag'));

    this.priorities.push(new KeyValueDTO('hoch', 'hoch / produktionsverhindernd'));
    this.priorities.push(new KeyValueDTO('normal', 'normal'));
    this.priorities.push(new KeyValueDTO('niedrig', 'niedrig'));

//    this.releases.push(new KeyValueDTO('1.0.7', '1.0.7'));
    this.releases.push(new KeyValueDTO('1.0.9', '1.0.9 Aktuelles Release!'));
    this.releases.push(new KeyValueDTO('1.1.0', '1.1.0'));
    this.releases.push(new KeyValueDTO('1.1.1', '1.1.1'));
    this.releases.push(new KeyValueDTO('Raum der Wünsche', 'Raum der Wünsche'));

    this.ticketDTO.release = '1.0.9';
  }

  ngOnInit() {
  }

  noAction() {
  }

  send() {
    this.bucktracking.createBug(this.ticketDTO).then(() => {
      let oldTicket = this.ticketDTO;
      this.ticketDTO = new TicketDTO();
      this.ticketDTO.release = this.myApplication.version;
      this.ticketDTO.reporter = oldTicket.reporter;
      this.ticketDTO.partComponents = oldTicket.partComponents;
    });
  }
}