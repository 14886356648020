<ng-container *ngIf="showScreen">
    <ng-container *ngIf="actionCalculateSeeds">
        <button class="btn btn-sm ml-2 mr-1 mt-2" [ngClass]="myApplication.theme.btnOutline" type="button"
            (click)="calculate()" data-toggle="modal" data-target="#NeedsList">
            <fa name="calculator"></fa> {{ myApplication.getText('Seed.calculate') }}
        </button>
        <button class="btn btn-sm ml-2 mr-1 mt-2" [ngClass]="myApplication.theme.btnOutline" type="button"
            (click)="calculateDay()" data-toggle="modal" data-target="#NeedsList">
            <fa name="calculator"></fa> {{ myApplication.getText('Seed.calculateDay') }}
        </button>
    </ng-container>
    <ng-container *ngIf="actionExport">
        <button class="btn btn-sm ml-2 mr-1 mt-2" [ngClass]="myApplication.theme.btnOutline" type="button"
            (click)="exportSeedInventory()" data-toggle="modal" data-target="#NeedsList">
            <fa name="file-excel"></fa> {{ myApplication.getText('Seed.export2xslx') }}
        </button>
    </ng-container>

    <app-culture-seed *ngFor="let culture of cultures" [culture]="culture"></app-culture-seed>
</ng-container>