import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';
import { Constants } from '../business-logic/constants';

export class CultureEO extends AbstractEntityObject{

    public name: String;
    public consumerType: String;
    public cultureType: String;
    public harvestMethod: String;
    public harvestUnit: String;
    
    public plantDistance: number;
    public rowDistance: number;
    public plantDeepth: number;
    public numRows: number;
    public quickpotSize: number;
    public quickpotName: string;
    public quickpotId: string;

    public seedsPerQuickpot: number;
    public harvestQuantity: number;
    public neededHarvestQuantity: number;
    public safety: number;

    public color: String;
    public note: String;

    public seed: number;
    public grow: number;
    public harvest: number;
    public numOfDeliveryWeeks: number;
    public averageQuantity: number;
    public tags: String;
    public alternativeDeliverySelected: boolean;
    public plantsPerBox: number;
    public seedRate: number;


    constructor() {
        super();
        this.name = '';
        this.consumerType = Constants.CONSUMER_TYPE_STARKZEHRER;
        this.cultureType = Constants.CULTURE_TYPE_DIREKTSAAT;
        this.harvestMethod = Constants.HARVEST_METHOD_ABSOLUT;
        this.harvestUnit = Constants.UNIT_PIECE;

        this.plantDistance = 0;
        this.rowDistance = 0;
        this.plantDeepth = 0;
        this.numRows = 0;
        this.quickpotSize = 0;
        this.quickpotName = '';
        this.quickpotId = '';

        this.seedsPerQuickpot = 1;
        this.harvestQuantity = 0;
        this.neededHarvestQuantity = 0;
        this.safety = 0;

        this.color = '';
        this.note = '';

        this.seed = 1;
        this.grow = 1;
        this.harvest = 1;
        this.numOfDeliveryWeeks = 0;
        this.averageQuantity = 0;

        this.tags = '';

        this.alternativeDeliverySelected = false;
        this.plantsPerBox = 0;
        this.seedRate = 100;
    }
  }