/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./legal-agree.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../../node_modules/angular-font-awesome/dist/angular-font-awesome.ngfactory";
import * as i5 from "angular-font-awesome";
import * as i6 from "./legal-agree.component";
import * as i7 from "../../../../services/application.service";
import * as i8 from "../../../../services/authentication.service";
import * as i9 from "../../../../services/wizard.service";
var styles_LegalAgreeComponent = [i0.styles];
var RenderType_LegalAgreeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LegalAgreeComponent, data: {} });
export { RenderType_LegalAgreeComponent as RenderType_LegalAgreeComponent };
export function View_LegalAgreeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [["class", "modal-title"], ["id", "popupagpdp"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "P", [["class", "ml-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["href", "https://www.micro-farm-planner.com/impressum-und-datenschutz/"], ["target", "impressum"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\u00A0\u00A0\u00A0 "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "a", [["href", "https://www.micro-farm-planner.com/agb/"], ["target", "agb"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 10, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 16777216, null, null, 4, "button", [["type", "button"]], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.annehmen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 212992, null, 0, i2.NgbTooltip, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i2.NgbTooltipConfig, i1.NgZone, i3.DOCUMENT, i1.ChangeDetectorRef], { ngbTooltip: [0, "ngbTooltip"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "fa", [["name", "check"]], null, null, null, i4.View_AngularFontAwesomeComponent_0, i4.RenderType_AngularFontAwesomeComponent)), i1.ɵdid(14, 114688, null, 0, i5.AngularFontAwesomeComponent, [], { name: [0, "name"] }, null), (_l()(), i1.ɵted(15, null, [" ", " "])), (_l()(), i1.ɵeld(16, 16777216, null, null, 4, "button", [["type", "button"]], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ablehnen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 212992, null, 0, i2.NgbTooltip, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i2.NgbTooltipConfig, i1.NgZone, i3.DOCUMENT, i1.ChangeDetectorRef], { ngbTooltip: [0, "ngbTooltip"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 1, "fa", [["name", "times"]], null, null, null, i4.View_AngularFontAwesomeComponent_0, i4.RenderType_AngularFontAwesomeComponent)), i1.ɵdid(19, 114688, null, 0, i5.AngularFontAwesomeComponent, [], { name: [0, "name"] }, null), (_l()(), i1.ɵted(20, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.tooltipAgree; _ck(_v, 12, 0, currVal_4); var currVal_5 = "check"; _ck(_v, 14, 0, currVal_5); var currVal_8 = _co.tooltipDecline; _ck(_v, 17, 0, currVal_8); var currVal_9 = "times"; _ck(_v, 19, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.myApplication.getText("Legal.AgreeText"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.myApplication.getText("Impressum"); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.myApplication.getText("AGB"); _ck(_v, 9, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "btn btn-sm mr-1 ", _co.cssClass, ""); _ck(_v, 11, 0, currVal_3); var currVal_6 = _co.myApplication.getText("Legal.Agree"); _ck(_v, 15, 0, currVal_6); var currVal_7 = i1.ɵinlineInterpolate(1, "btn btn-sm mr-1 ", _co.cssClass, ""); _ck(_v, 16, 0, currVal_7); var currVal_10 = _co.myApplication.getText("Legal.Decline"); _ck(_v, 20, 0, currVal_10); }); }
export function View_LegalAgreeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-legal-agree", [], null, null, null, View_LegalAgreeComponent_0, RenderType_LegalAgreeComponent)), i1.ɵdid(1, 114688, null, 0, i6.LegalAgreeComponent, [i7.ApplicationService, i2.NgbModal, i8.AuthenticationService, i9.WizardService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LegalAgreeComponentNgFactory = i1.ɵccf("app-legal-agree", i6.LegalAgreeComponent, View_LegalAgreeComponent_Host_0, {}, {}, []);
export { LegalAgreeComponentNgFactory as LegalAgreeComponentNgFactory };
