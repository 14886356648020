import * as tslib_1 from "tslib";
import { Observable } from '../app-core/technical-aspects/observable';
import { GreenThemeDTO } from '../app-core/dto/green-theme-dto';
import { MessageDTO } from '../app-core/dto/message-dto';
import { FieldTextDTO } from '../app-core/dto/field-text-dto';
import { BasicService } from './basic.service';
import { MonthDTO } from '../app-core/dto/month-dto';
import { EventHandler } from '../app-core/technical-aspects/event-handler';
import { ToastrService } from 'ngx-toastr';
import { Http } from '@angular/http';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
import * as i2 from "./basic.service";
import * as i3 from "ngx-toastr";
import * as i4 from "ngx-cookie-service";
var ApplicationService = /** @class */ (function (_super) {
    tslib_1.__extends(ApplicationService, _super);
    function ApplicationService(http, basicService, toastr, cookieService) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.basicService = basicService;
        _this.cookieService = cookieService;
        _this.textDTOs = new Map();
        //public repository: Repository;
        _this.loading = true;
        _this.eventHandler = new EventHandler();
        _this.availableLanguages = new Array();
        _this.selectedLanguage = 'de';
        _this.version = '2.4.5';
        _this.splitScreen = false;
        _this.showSplitScreenBtn = false;
        _this.showYearChoose = true;
        _this.legalAgreeShown = false;
        _this.showWizardOnCloseAgree = false;
        if (_this.cookieService) {
            _this.selectedLanguage = _this.cookieService.get('Gemuese-Anbauplaner.language');
        }
        if (!_this.selectedLanguage || _this.selectedLanguage == '') {
            _this.selectedLanguage = 'de';
        }
        _this.getAvailableLanguages();
        _this.readCountries();
        _this.yearDate = new Date();
        _this.year = _this.yearDate.getFullYear();
        _this.currentKW = basicService.getWeek(_this.yearDate).week;
        _this.theme = new GreenThemeDTO();
        _this.messageDTO = new MessageDTO(_this, toastr);
        return _this;
    }
    ApplicationService.prototype.getAvailableLanguages = function () {
        this.availableLanguages = new Array();
        this.availableLanguages.push('de');
        this.availableLanguages.push('en');
        this.availableLanguages.push('es');
        this.availableLanguages.push('fr');
        this.availableLanguages.push('it');
    };
    ApplicationService.prototype.save = function () {
        this.daoService.save();
    };
    ApplicationService.prototype.setDAOService = function (daoService) {
        this.daoService = daoService;
    };
    ApplicationService.prototype.readCountries = function () {
        var _this = this;
        if (!this.http) {
            return;
        }
        this.http.get("./assets/i18n/countries.json").pipe(map(function (resp) { return resp.json(); }))
            .subscribe(function (data) {
            _this.countries = data;
        });
    };
    ApplicationService.prototype.getCountry = function (name) {
        for (var i = 0; i < this.countries.length; i++) {
            if (this.countries[i].name == name) {
                return this.countries[i];
            }
        }
        return null;
    };
    ApplicationService.prototype.readTexts = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.get("./assets/i18n/" + _this.selectedLanguage + environment.buildTS + ".json").pipe(map(function (resp) { return resp.json(); }))
                .subscribe(function (data) {
                _this.textDTOs = new Map();
                var outJson = [];
                var backendJson = new Object();
                data.sort(function (one, two) {
                    var id1 = Object.keys(one)[0];
                    var id2 = Object.keys(two)[0];
                    var compare = id1.localeCompare(id2);
                    if (compare == 0) {
                        return two[Object.keys(two)[0]].text.localeCompare(one[Object.keys(one)[0]].text);
                    }
                    return compare;
                });
                // let dataClean = [];
                var numBefore = 0;
                var before = '';
                for (var i = 0; i < data.length; i++) {
                    var obj = new Object();
                    obj[Object.keys(data[i])[0]] = data[i][Object.keys(data[i])[0]];
                    if (before == Object.keys(data[i])[0]) {
                        console.log('double entry: ', before);
                        numBefore += 1;
                        // if (obj[Object.keys(data[i])[0]]["text"] == '' && obj[Object.keys(data[i])[0]]["help"] == '') {
                        //   //console.log('empty');
                        // } else {
                        // dataClean.push(obj);
                        // }
                        // } else {
                        // dataClean.push(obj);
                    }
                    before = Object.keys(data[i])[0];
                    // if (Object.keys(data[i])[1] != null) {
                    //   numDouble += 1;
                    //   let obj: any = new Object();
                    //   obj[Object.keys(data[i])[1]] = data[i][Object.keys(data[i])[1]];
                    //   dataClean.push(obj);
                    // }
                }
                console.log('Doubles: ', numBefore);
                // dataClean.sort(function(one, two) {
                //   let id1 = Object.keys(one)[0];
                //   let id2 = Object.keys(two)[0];
                //   return id1.localeCompare(id2);
                // });
                // console.log(dataClean);
                data.forEach(function (element) {
                    var id = Object.keys(element)[0];
                    var text = element[id].text;
                    var help = element[id].help;
                    _this.textDTOs.set(id, new FieldTextDTO(id, text, help));
                    var newJsonObject = { "id": id, "text": text, "help": help };
                    outJson.push(newJsonObject);
                    // backendJson[id + ".text"] = text;
                    // backendJson[id + ".help"] = help;
                });
                // console.log(JSON.stringify(backendJson));
                localStorage.removeItem('Gemuese-Anbauplaner.texts');
                localStorage.setItem('Gemuese-Anbauplaner.langu', _this.selectedLanguage);
                _this.eventHandler.raiseEvent(EventHandler.LANGUAGE_CHANGED);
                _this.loading = false;
                resolve();
            });
        });
    };
    ApplicationService.prototype.changeLanguage = function (newLanguage) {
        this.selectedLanguage = newLanguage;
        this.cookieService.set('Gemuese-Anbauplaner.language', this.selectedLanguage, 31);
        this.readTexts();
    };
    ApplicationService.prototype.getHelpText = function (id) {
        var textDTO = this.textDTOs.get(id);
        if (textDTO) {
            return textDTO.help;
        }
        else {
            console.log('Help ' + id + ' fehlt.');
            return null;
        }
    };
    ApplicationService.prototype.getText = function (id, parameter1, parameter2, parameter3, parameter4, parameter5, parameter6, parameter7, parameter8) {
        var textDTO = this.textDTOs.get(id);
        if (!textDTO) {
            console.log('Text ' + id + ' fehlt.');
            return null;
        }
        var text = this.textDTOs.get(id).text;
        text = text.replace('@p1', parameter1);
        text = text.replace('@p2', parameter2);
        text = text.replace('@p3', parameter3);
        text = text.replace('@p4', parameter4);
        text = text.replace('@p5', parameter5);
        text = text.replace('@p6', parameter6);
        text = text.replace('@p7', parameter7);
        text = text.replace('@p8', parameter8);
        return text;
    };
    ApplicationService.prototype.getTextSimple = function (id, parameter1, parameter2) {
        return this.getText(id, parameter1, parameter2).toString();
    };
    Object.defineProperty(ApplicationService.prototype, "year", {
        get: function () {
            return this._year;
        },
        set: function (value) {
            this._year = value;
            this.update();
            this.eventHandler.raiseEvent(EventHandler.APP_YEAR_CHANGED);
            this.hasChanged();
        },
        enumerable: true,
        configurable: true
    });
    ApplicationService.prototype.setYearExternal = function (year) {
        this._year = year;
    };
    ApplicationService.prototype.update = function () {
        this.yearDate.setFullYear(this.year);
    };
    ApplicationService.prototype.nextYear = function () {
        var newYear = Number.parseInt(this.year.toString());
        this.year = newYear + 1;
    };
    ApplicationService.prototype.previousYear = function () {
        var newYear = Number.parseInt(this.year.toString());
        if (newYear > 2018) {
            this.year = newYear - 1;
        }
    };
    ApplicationService.prototype.getMonthNames = function () {
        var monthNames = [this.getTextSimple('Januar'),
            this.getTextSimple('Februar'),
            this.getTextSimple('März'),
            this.getTextSimple('April'),
            this.getTextSimple('Mai'),
            this.getTextSimple('Juni'),
            this.getTextSimple('Juli'),
            this.getTextSimple('August'),
            this.getTextSimple('September'),
            this.getTextSimple('Oktober'),
            this.getTextSimple('November'),
            this.getTextSimple('Dezember')];
        return monthNames;
    };
    ApplicationService.prototype.getWeekDayNames = function () {
        var names = [this.getTextSimple('Montag'),
            this.getTextSimple('Dienstag'),
            this.getTextSimple('Mittwoch'),
            this.getTextSimple('Donnerstag'),
            this.getTextSimple('Freitag'),
            this.getTextSimple('Samstag'),
            this.getTextSimple('Sonntag')];
        return names;
    };
    ApplicationService.prototype.getMonths = function (year) {
        var periods = new Array();
        var period;
        var lastWeekOfMonth;
        var lastDateOfMonth;
        var firstDateOfMonth;
        var month = this.getMonthNames();
        var weeksBefore = 0;
        for (var i = 0; i < 12; i++) {
            lastDateOfMonth = this.basicService.getLastDateOfMonth(i, year);
            firstDateOfMonth = this.basicService.getFirstDateOfMonth(i, year);
            lastWeekOfMonth = this.basicService.getWeek(lastDateOfMonth);
            period = new MonthDTO(this.getTextSimple(month[i]), 0);
            period.lastWeek = lastWeekOfMonth;
            period.firstWeek = this.basicService.getWeek(firstDateOfMonth);
            if (i == 11) {
                var weeksMax = this.basicService.getNumWeeks(year);
                period.duration = weeksMax - weeksBefore;
            }
            else {
                if (this.basicService.getWeekDay(lastDateOfMonth) >= 3) {
                    period.duration = lastWeekOfMonth.week - weeksBefore;
                }
                else {
                    period.duration = lastWeekOfMonth.week - weeksBefore - 1;
                }
            }
            weeksBefore = weeksBefore + period.duration;
            periods.push(period);
        }
        return periods;
    };
    ApplicationService.ngInjectableDef = i0.defineInjectable({ factory: function ApplicationService_Factory() { return new ApplicationService(i0.inject(i1.Http), i0.inject(i2.BasicService), i0.inject(i3.ToastrService), i0.inject(i4.CookieService)); }, token: ApplicationService, providedIn: "root" });
    return ApplicationService;
}(Observable));
export { ApplicationService };
