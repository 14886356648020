import { OnInit } from '@angular/core';
import { ApplicationService } from 'src/app/services/application.service';
import { FieldTextDTO } from 'src/app/app-core/dto/field-text-dto';
var ButtonComponent = /** @class */ (function () {
    function ButtonComponent(myApplication) {
        this.myApplication = myApplication;
        this.silent = false;
        this.dataDismiss = '';
        this.stopEventing = false;
        this.btnClass = this.myApplication.theme.btnOutline;
        this.popupTitle = this.myApplication.getTextSimple('Confirm.delete');
        this.confirmQuestion = this.myApplication.getTextSimple('Confirm.deleteQuestion');
        this._disable = false;
        this.silentClass = '';
    }
    ButtonComponent.prototype.ngOnInit = function () {
        this.fieldDTO = this.myApplication.textDTOs.get(this.id);
        if (!this.fieldDTO) {
            this.fieldDTO = new FieldTextDTO(this.id, '', '');
        }
        if (this.silent) {
            this.silentClass = 'silentButton';
        }
    };
    ButtonComponent.prototype.stopEvent = function (event) {
        if (this.stopEventing) {
            event.stopPropagation();
        }
    };
    ButtonComponent.prototype.onClick = function (event) {
        if (this.object) {
            this.callbackOwner[this.callback.toString()](this.object);
        }
        else {
            this.callbackOwner[this.callback.toString()]();
        }
        if (this.stopEventing) {
            event.stopPropagation();
        }
    };
    Object.defineProperty(ButtonComponent.prototype, "disable", {
        get: function () {
            return this._disable;
        },
        set: function (value) {
            this._disable = value;
        },
        enumerable: true,
        configurable: true
    });
    return ButtonComponent;
}());
export { ButtonComponent };
