import { AuthenticationService } from './authentication.service';
import { Http } from '@angular/http';
import * as i0 from "@angular/core";
import * as i1 from "./authentication.service";
import * as i2 from "@angular/http";
var PartsService = /** @class */ (function () {
    function PartsService(authenticationService, http) {
        this.authenticationService = authenticationService;
        this.http = http;
        // this.readParts();
    }
    PartsService.prototype.readParts = function () {
        //deprecated
        // this.partTypes = new Array<PartType>();
        // const options = new RequestOptions();
        // options.headers = this.authenticationService.getHeadersAuthenticated();
        // this.http.get(environment.api + 'api/partTypes', options).pipe(
        //   map(resp => resp.json())
        // )
        //   .subscribe(
        //     res => {
        //       //Anteilstypen
        //       let partTypes = res['parts']['partTypes'];
        //       let partType: PartType;
        //       let k = 0;
        //       while (partTypes[k]) {
        //         partType = new PartType();
        //         partType.id = partTypes[k]['id'];
        //         partType.name = partTypes[k]['name'];
        //         partType.note = partTypes[k]['note'];
        //         partType.score = Number.parseFloat(partTypes[k]['score']);
        //         partType.isStandard = partTypes[k]['isStandard'];
        //         this.partTypes.push(partType);
        //         k = k + 1;
        //       }
        //       //Anteile
        //       let parts = res['parts']['parts'];
        //       let part: Part;
        //       let l = 0;
        //       this.parts = new Array();
        //       while (parts[l]) {
        //         part = new Part();
        //         part.id = parts[l]['id'];
        //         part.partTypeId = parts[l]['partTypeId'];
        //         part.startKW = Number.parseInt(parts[l]['startWeek']);
        //         part.startYear = Number.parseInt(parts[l]['startYear']);
        //         part.endKW = Number.parseInt(parts[l]['endWeek']);
        //         part.endYear = Number.parseInt(parts[l]['endYear']);
        //         part.numParts = Number.parseFloat(parts[l]['parts']);
        //         part.startText = part.startKW + '/' + part.startYear;
        //         part.endText = part.endKW + '/' + part.endYear;
        //         part.deliveryPlaceId = parts[l]['deliveryPlaceId'];
        //         part.consumerId = parts[l]['consumerId'];
        //         this.parts.push(part);
        //         l = l + 1;
        //       }
        //     });
    };
    PartsService.ngInjectableDef = i0.defineInjectable({ factory: function PartsService_Factory() { return new PartsService(i0.inject(i1.AuthenticationService), i0.inject(i2.Http)); }, token: PartsService, providedIn: "root" });
    return PartsService;
}());
export { PartsService };
