import { ToDoList } from './todo-list';
import { DAOToDos } from '../dao/dao-todos';
import { ToDo } from './todo';
import { Bed } from './bed';
import { Constants } from './constants';
import { DAOBeds } from '../dao/dao-beds';
import { DaoService } from 'src/app/services/dao.service';

export class ToDoListBed extends ToDoList {
    public daoBeds: DAOBeds;

    constructor(protected daoToDos: DAOToDos, public bed: Bed, daoBeds: DAOBeds) {
        super(daoToDos);

        // this.daoToDos.objects.forEach(element => {
        //     let todo = <ToDo>element;
        //     if (todo.parentType == Constants.PARENT_TYPE_BED) {
        //         if (todo.parentId == this.bed.id) {
        //             if (todo.isSeriesMaster() == false) {
        //                 this.toDos.push(todo);
        //             }
        //         }
        //     }
        // });

        this.daoBeds = daoBeds;

        this.daoToDos.objects.forEach(element => {
            let todo = <ToDo>element;
            if (todo.parentType == Constants.PARENT_TYPE_AREA) {

                if (todo.parentId == this.bed.areaId) {
                    if (todo.bedIdStart && todo.bedIdStart == this.bed.id) {
                        if (todo.isSeriesMaster() == false) {
                            this.toDos.push(todo);
                        }
                    }
                    else if (todo.bedIdEnd && todo.bedIdEnd == this.bed.id) {
                        if (todo.isSeriesMaster() == false) {
                            this.toDos.push(todo);
                        }
                    }
                    else if (todo.bedIdStart && todo.bedIdEnd && todo.bedIdStart != '' && todo.bedIdEnd != '') {
                        if ((<Bed>this.daoBeds.getObjectById(todo.bedIdStart)).orderNum <= this.bed.orderNum &&
                            this.bed.orderNum <= (<Bed>this.daoBeds.getObjectById(todo.bedIdEnd)).orderNum) {
                            if (todo.isSeriesMaster() == false) {
                                this.toDos.push(todo);
                            }
                        }

                    }
                }
            }
        });
    }
}