import * as tslib_1 from "tslib";
import { AbstractObject } from '../technical-aspects/abstract-object';
import { ToDoEO } from '../entity-objects/todo-eo';
import { Constants } from './constants';
var ToDo = /** @class */ (function (_super) {
    tslib_1.__extends(ToDo, _super);
    function ToDo() {
        return _super.call(this) || this;
    }
    ToDo.prototype.update = function () {
    };
    ToDo.prototype.initialize = function () {
        this.eo = new ToDoEO();
    };
    ToDo.prototype.isDeleteable = function () {
        if (this.getRecord() && (this.cultureDatType == Constants.TODO_TYPE_ANZUCHT ||
            this.cultureDatType == Constants.TODO_TYPE_AUSSAAT ||
            this.cultureDatType == Constants.TODO_TYPE_PFLANZUNG)) {
            return false;
        }
        else {
            return true;
        }
    };
    ToDo.prototype.adoptDataFrom = function (model) {
        this.name = model.name;
        this.note = model.note;
        this.timeReference = model.timeReference;
        this.timeType = model.timeType;
        this.quantity = model.quantity;
        this.quantityUnit = model.quantityUnit;
        this.time = model.time;
        this.timeUnit = model.timeUnit;
        this.color = model.color;
    };
    ToDo.prototype.isRelative = function () {
        if (this.toDoType == Constants.TODO_RELATIVE || this.toDoType == Constants.TODO_RECORD_DATES || this.toDoType == Constants.TODO_SERIES_RELATIVE) {
            return true;
        }
        else {
            return false;
        }
    };
    ToDo.prototype.usesNameQuantity = function () {
        if (this.type == 'Düngung' || this.type == 'Pflanzenschutz') {
            return true;
        }
        else {
            return false;
        }
    };
    Object.defineProperty(ToDo.prototype, "toDoEO", {
        get: function () {
            return this.eo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "toDoType", {
        get: function () {
            return this.toDoEO.toDoType;
        },
        set: function (value) {
            this.toDoEO.toDoType = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "cultureDatType", {
        get: function () {
            return this.toDoEO.cultureDatType;
        },
        set: function (value) {
            this.toDoEO.cultureDatType = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "type", {
        get: function () {
            return this.toDoEO.type;
        },
        set: function (value) {
            if (value != this.toDoEO.type) {
                this.toDoEO.type = value;
                this.seriesRelevantAttributHasChanged = true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "neededTime", {
        get: function () {
            return this.toDoEO.neededTime;
        },
        set: function (value) {
            this.toDoEO.neededTime = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "name", {
        get: function () {
            return this.toDoEO.name;
        },
        set: function (value) {
            this.toDoEO.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "quantity", {
        get: function () {
            return this.toDoEO.quantity;
        },
        set: function (value) {
            this.toDoEO.quantity = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "quantityUnit", {
        get: function () {
            return this.toDoEO.quantityUnit;
        },
        set: function (value) {
            this.toDoEO.quantityUnit = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "time", {
        get: function () {
            return this.toDoEO.time;
        },
        set: function (value) {
            if (value != this.toDoEO.time) {
                if (this.isSeriesMaster()) {
                    this.seriesRelevantAttributHasChanged = true;
                }
                this.toDoEO.time = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "timeUnit", {
        get: function () {
            return this.toDoEO.timeUnit;
        },
        set: function (value) {
            if (value != this.toDoEO.timeUnit) {
                if (this.isSeriesMaster()) {
                    this.seriesRelevantAttributHasChanged = true;
                }
                this.toDoEO.timeUnit = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "timeType", {
        get: function () {
            return this.toDoEO.timeType;
        },
        set: function (value) {
            if (value != this.toDoEO.timeType) {
                if (this.isSeriesMaster()) {
                    this.seriesRelevantAttributHasChanged = true;
                }
                this.toDoEO.timeType = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "timeReference", {
        get: function () {
            return this.toDoEO.timeReference;
        },
        set: function (value) {
            if (value != this.toDoEO.timeReference) {
                if (this.isSeriesMaster()) {
                    this.seriesRelevantAttributHasChanged = true;
                }
                this.toDoEO.timeReference = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "color", {
        get: function () {
            return this.toDoEO.color;
        },
        set: function (value) {
            this.toDoEO.color = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "note", {
        get: function () {
            return this.toDoEO.note;
        },
        set: function (value) {
            this.toDoEO.note = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "parentType", {
        get: function () {
            return this.toDoEO.parentType;
        },
        set: function (value) {
            this.toDoEO.parentType = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "parentId", {
        get: function () {
            return this.toDoEO.parentId;
        },
        set: function (value) {
            if (value != this.toDoEO.parentId) {
                if (this.isSeriesMaster()) {
                    this.seriesRelevantAttributHasChanged = true;
                }
                this.toDoEO.parentId = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "parentIdArea", {
        get: function () {
            if (this.parentType == Constants.PARENT_TYPE_AREA) {
                return this.toDoEO.parentId;
            }
            else {
                return '';
            }
        },
        set: function (value) {
            if (value != this.toDoEO.parentId) {
                if (this.isSeriesMaster()) {
                    this.seriesRelevantAttributHasChanged = true;
                }
                this.toDoEO.parentId = value;
                if (value != ' ') {
                    this.parentType = Constants.PARENT_TYPE_AREA;
                    if (this.toDoType == Constants.TODO_STANDALONE) {
                        this.toDoType = Constants.TODO_AREA;
                    }
                    else if (this.toDoType == Constants.TODO_SERIES_STANDALONE) {
                        this.toDoType = Constants.TODO_SERIES_AREA;
                    }
                }
                else if (value == ' ') {
                    this.parentType = ' ';
                    this.bedIdStart = ' ';
                    this.bedIdEnd = ' ';
                    if (this.toDoType == Constants.TODO_AREA) {
                        this.toDoType = Constants.TODO_STANDALONE;
                    }
                    else if (this.toDoType == Constants.TODO_SERIES_AREA) {
                        this.toDoType = Constants.TODO_SERIES_STANDALONE;
                    }
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "parentIdRecord", {
        get: function () {
            if (this.parentType == Constants.PARENT_TYPE_RECORD) {
                return this.toDoEO.parentId;
            }
            else {
                return '';
            }
        },
        set: function (value) {
            if (value != this.toDoEO.parentId) {
                if (this.isSeriesMaster()) {
                    this.seriesRelevantAttributHasChanged = true;
                }
                this.toDoEO.parentId = value;
                if (value != ' ') {
                    this.parentType = Constants.PARENT_TYPE_RECORD;
                    if (this.toDoType == Constants.TODO_STANDALONE) {
                        this.toDoType = Constants.TODO_RECORD_ABSOLUTE;
                    }
                    else if (this.toDoType == Constants.TODO_SERIES_STANDALONE) {
                        this.toDoType = Constants.TODO_SERIES_RECORD_ABSOLUTE;
                    }
                }
                else if (value == ' ') {
                    this.parentType = ' ';
                    if (this.toDoType == Constants.TODO_RELATIVE || this.toDoType == Constants.TODO_RECORD_ABSOLUTE) {
                        this.toDoType = Constants.TODO_STANDALONE;
                    }
                    else if (this.toDoType == Constants.TODO_SERIES_RELATIVE || this.toDoType == Constants.TODO_SERIES_RECORD_ABSOLUTE) {
                        this.toDoType = Constants.TODO_SERIES_STANDALONE;
                    }
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "series", {
        get: function () {
            if (this.seriesMaster != '') {
                return true;
            }
            else {
                return this._series;
            }
        },
        set: function (value) {
            this._series = value;
            if (this.seriesMaster == '') {
                this.seriesMaster = this.id;
                if (this.series == true) {
                    if (this.toDoType == Constants.TODO_RELATIVE) {
                        this.toDoType = Constants.TODO_SERIES_RELATIVE;
                    }
                    else if (this.toDoType == Constants.TODO_STANDALONE) {
                        this.toDoType = Constants.TODO_SERIES_STANDALONE;
                    }
                    else if (this.toDoType == Constants.TODO_BED) {
                        this.toDoType = Constants.TODO_SERIES_BED;
                    }
                    else if (this.toDoType == Constants.TODO_AREA) {
                        this.toDoType = Constants.TODO_SERIES_AREA;
                    }
                    else if (this.toDoType == Constants.TODO_RECORD_ABSOLUTE) {
                        this.toDoType = Constants.TODO_SERIES_RECORD_ABSOLUTE;
                    }
                }
            }
            else {
                if (value == false) {
                    this.seriesMaster = '';
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "endTime", {
        get: function () {
            return this.toDoEO.endTime;
        },
        set: function (value) {
            if (value != this.toDoEO.endTime) {
                if (this.isSeriesMaster()) {
                    this.seriesRelevantAttributHasChanged = true;
                }
                this.toDoEO.endTime = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "endTimeUnit", {
        get: function () {
            return this.toDoEO.endTimeUnit;
        },
        set: function (value) {
            if (value != this.toDoEO.endTimeUnit) {
                if (this.isSeriesMaster()) {
                    this.seriesRelevantAttributHasChanged = true;
                }
                this.toDoEO.endTimeUnit = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "endTimeType", {
        get: function () {
            return this.toDoEO.endTimeType;
        },
        set: function (value) {
            if (value != this.toDoEO.endTimeType) {
                if (this.isSeriesMaster()) {
                    this.seriesRelevantAttributHasChanged = true;
                }
                this.toDoEO.endTimeType = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "endTimeReference", {
        get: function () {
            return this.toDoEO.endTimeReference;
        },
        set: function (value) {
            if (value != this.toDoEO.endTimeReference) {
                if (this.isSeriesMaster()) {
                    this.seriesRelevantAttributHasChanged = true;
                }
                this.toDoEO.endTimeReference = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "seriesMaster", {
        get: function () {
            return this.toDoEO.seriesMaster;
        },
        set: function (value) {
            this.toDoEO.seriesMaster = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "seriesIntervall", {
        get: function () {
            return this.toDoEO.seriesIntervall;
        },
        set: function (value) {
            if (value != this.toDoEO.seriesIntervall) {
                if (this.isSeriesMaster()) {
                    this.seriesRelevantAttributHasChanged = true;
                }
                this.toDoEO.seriesIntervall = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "seriesIntervallUnit", {
        get: function () {
            return this.toDoEO.seriesIntervallUnit;
        },
        set: function (value) {
            if (value != this.toDoEO.seriesIntervallUnit) {
                if (this.isSeriesMaster()) {
                    this.seriesRelevantAttributHasChanged = true;
                }
                this.toDoEO.seriesIntervallUnit = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "person", {
        get: function () {
            return this.toDoEO.person;
        },
        set: function (value) {
            if (value != this.toDoEO.person) {
                this.toDoEO.person = value;
                this.seriesRelevantAttributHasChanged = true;
            }
        },
        enumerable: true,
        configurable: true
    });
    ToDo.prototype.getPersonAvatar = function () {
        var person = this.getPerson();
        if (person) {
            return './assets/icons/avatars/' + person.avatar;
        }
        else {
            return '';
        }
    };
    ToDo.prototype.getPerson = function () {
        return this.daoPersons.getObjectById(this.person);
    };
    ToDo.prototype.getPersonName = function () {
        if (this.getPerson()) {
            return this.getPerson().name;
        }
        else {
            return '';
        }
    };
    Object.defineProperty(ToDo.prototype, "state", {
        get: function () {
            return this.toDoEO.state;
        },
        set: function (value) {
            this.toDoEO.state = value;
        },
        enumerable: true,
        configurable: true
    });
    ToDo.prototype.setDone = function () {
        this.toDoEO.state = Constants.TODO_STATE_DONE;
    };
    ToDo.prototype.isDone = function () {
        if (this.toDoEO.state == Constants.TODO_STATE_DONE) {
            return true;
        }
        else {
            return false;
        }
    };
    Object.defineProperty(ToDo.prototype, "dueDate", {
        get: function () {
            if (this.isRelative()) {
                return this.calculateDueDate();
            }
            else {
                return this.toDoEO.dueDate;
            }
        },
        set: function (value) {
            if (this.toDoType == Constants.TODO_STANDALONE || this.toDoType == Constants.TODO_BED || this.toDoType == Constants.TODO_AREA ||
                this.toDoType == Constants.TODO_SERIES_STANDALONE || this.toDoType == Constants.TODO_SERIES_BED || this.toDoType == Constants.TODO_SERIES_AREA ||
                this.toDoType == Constants.TODO_RECORD_ABSOLUTE || this.toDoType == Constants.TODO_SERIES_RECORD_ABSOLUTE) {
                value = this.basicService.normalizeDate(value);
                if (value != this.toDoEO.dueDate) {
                    if (this.isSeriesMaster()) {
                        this.seriesRelevantAttributHasChanged = true;
                    }
                    this.toDoEO.dueDate.setTime(value.getTime());
                    this.hasChanged();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    ToDo.prototype.calculateDueDate = function () {
        var dueDate = new Date();
        dueDate = this.basicService.normalizeDate(dueDate);
        if (this.parentType == Constants.PARENT_TYPE_RECORD) {
            if (this.parentId != '' && this.getRecord()) {
                dueDate.setTime(this.getRefDate().getTime());
                if (this.timeType == Constants.TEMPLATE_PREPOSITION_BEFORE) {
                    dueDate.setDate(dueDate.getDate() - this.time);
                }
                else if (this.timeType == Constants.TEMPLATE_PREPOSITION_AFTER) {
                    dueDate.setDate(dueDate.getDate() + this.time);
                }
                this.toDoEO.dueDate = this.basicService.normalizeDate(dueDate);
            }
        }
        return dueDate;
    };
    Object.defineProperty(ToDo.prototype, "endDueDate", {
        get: function () {
            if (this.isRelative()) {
                return this.calculateEndDueDate();
            }
            else {
                return this.toDoEO.endDueDate;
            }
        },
        set: function (value) {
            if (this.toDoType == Constants.TODO_STANDALONE || this.toDoType == Constants.TODO_BED || this.toDoType == Constants.TODO_AREA ||
                this.toDoType == Constants.TODO_SERIES_STANDALONE || this.toDoType == Constants.TODO_SERIES_BED || this.toDoType == Constants.TODO_SERIES_AREA ||
                this.toDoType == Constants.TODO_RECORD_ABSOLUTE || this.toDoType == Constants.TODO_SERIES_RECORD_ABSOLUTE) {
                value = this.basicService.normalizeDate(value);
                if (value != this.toDoEO.endDueDate) {
                    if (this.isSeriesMaster()) {
                        this.seriesRelevantAttributHasChanged = true;
                    }
                    this.toDoEO.endDueDate = value;
                    this.hasChanged();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    ToDo.prototype.calculateEndDueDate = function () {
        var endDueDate = new Date();
        endDueDate = this.basicService.normalizeDate(endDueDate);
        if (this.parentType == Constants.PARENT_TYPE_RECORD) {
            if (this.parentId != '' && this.getRecord()) {
                endDueDate.setTime(this.getEndRefDate().getTime());
                if (this.endTimeType == Constants.TEMPLATE_PREPOSITION_BEFORE) {
                    endDueDate.setDate(endDueDate.getDate() - this.endTime);
                }
                else if (this.endTimeType == Constants.TEMPLATE_PREPOSITION_AFTER) {
                    endDueDate.setDate(endDueDate.getDate() + this.endTime);
                }
            }
        }
        return endDueDate;
    };
    ToDo.prototype.getRefDate = function () {
        if (this.timeReference == Constants.TEMPLATE_TIME_REF_ANZUCHT) {
            return this.basicService.normalizeDate(this.getRecord().recordPeriods.seedDate);
        }
        else if (this.timeReference == Constants.TEMPLATE_TIME_REF_PFLANZUNG) {
            return this.basicService.normalizeDate(this.getRecord().recordPeriods.growDate);
        }
        else if (this.timeReference == Constants.TEMPLATE_TIME_REF_ERNTE) {
            return this.basicService.normalizeDate(this.getRecord().recordPeriods.harvestDate);
        }
        else if (this.timeReference == Constants.TEMPLATE_TIME_REF_KULTURENDE) {
            return this.getRecord().recordPeriods.getCultureEnd();
        }
    };
    ToDo.prototype.getEndRefDate = function () {
        if (this.endTimeReference == Constants.TEMPLATE_TIME_REF_ANZUCHT) {
            return this.basicService.normalizeDate(this.getRecord().recordPeriods.seedDate);
        }
        else if (this.endTimeReference == Constants.TEMPLATE_TIME_REF_PFLANZUNG) {
            return this.basicService.normalizeDate(this.getRecord().recordPeriods.growDate);
        }
        else if (this.endTimeReference == Constants.TEMPLATE_TIME_REF_ERNTE) {
            return this.basicService.normalizeDate(this.getRecord().recordPeriods.harvestDate);
        }
        else if (this.endTimeReference == Constants.TEMPLATE_TIME_REF_KULTURENDE) {
            return this.getRecord().recordPeriods.getCultureEnd();
        }
    };
    Object.defineProperty(ToDo.prototype, "bedIdStart", {
        get: function () {
            return this.toDoEO.bedIdStart;
        },
        set: function (value) {
            if (value != this.toDoEO.bedIdStart) {
                if (this.isSeriesMaster()) {
                    this.seriesRelevantAttributHasChanged = true;
                }
                this.toDoEO.bedIdStart = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToDo.prototype, "bedIdEnd", {
        get: function () {
            return this.toDoEO.bedIdEnd;
        },
        set: function (value) {
            if (value != this.toDoEO.bedIdEnd) {
                if (this.isSeriesMaster()) {
                    this.seriesRelevantAttributHasChanged = true;
                }
                this.toDoEO.bedIdEnd = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    ToDo.prototype.getRecord = function () {
        if (this.parentType == Constants.PARENT_TYPE_RECORD) {
            return this.daoRecords.getObjectById(this.parentId);
        }
        else {
            return null;
        }
    };
    ToDo.prototype.getBed = function () {
        if (this.parentType == Constants.PARENT_TYPE_BED) {
            return this.daoBeds.getObjectById(this.parentId);
        }
        else {
            return null;
        }
    };
    ToDo.prototype.getArea = function () {
        if (this.parentType == Constants.PARENT_TYPE_AREA) {
            return this.daoAreas.getObjectById(this.parentId);
        }
        else {
            if (this.parentType == Constants.PARENT_TYPE_BED) {
                var area = this.daoAreas.getObjectById(this.daoBeds.getObjectById(this.parentId).areaId);
                return area;
            }
            return null;
        }
    };
    ToDo.prototype.getDueDateText = function () {
        var dueDate = this.dueDate;
        return dueDate.getDate() + '.' + (dueDate.getMonth() + 1).toString() + '.' + dueDate.getFullYear();
    };
    ToDo.prototype.generateSeriesToDos = function () {
        var toDos = new Array;
        // Aenderungskennzeichen am Serienmaster loeschen - sonst wird immer wieder ueberschrieben
        this.seriesRelevantAttributHasChanged = false;
        // nur fuer SerienMaster
        if (this.isSeriesMaster() && this.seriesIntervall > 0) {
            toDos = this.getChildToDos();
            //wenn es schon ToDos gibt dann diese aendern
            if (toDos.length > 0) {
                this.reNewSeriesToDos(toDos);
            }
            // Neuanlage
            else {
                this.intervall = this.time;
                this.intervallType = this.timeType;
                this.toDoDate = new Date();
                this.toDoDate.setTime(this.dueDate.getTime());
                while (this.toDoDate <= this.endDueDate) {
                    toDos.push(this.generateNewSeriesToDo());
                    this.calculateIntervall();
                }
            }
            return toDos;
        }
        else {
            return new Array();
        }
    };
    ToDo.prototype.getChildToDos = function () {
        var _this = this;
        var toDo;
        var toDos = new Array();
        this.daoToDos.objectSet.objects.forEach(function (element) {
            toDo = element;
            if (toDo.seriesMaster == _this.id) {
                if (toDo.id != _this.id) {
                    toDos.push(toDo);
                }
            }
        });
        return toDos;
    };
    ToDo.prototype.calculateIntervall = function () {
        if (this.seriesIntervallUnit == 'W') {
            this.toDoDate.setDate(this.toDoDate.getDate() + (7 * this.seriesIntervall));
            if (this.intervallType == Constants.TEMPLATE_PREPOSITION_BEFORE) {
                if (this.intervall >= (this.seriesIntervall * 7)) {
                    this.intervall = this.intervall - (this.seriesIntervall * 7);
                }
                else if (this.intervall < this.seriesIntervall * 7) {
                    this.intervall = (this.seriesIntervall * 7) - this.intervall;
                    this.intervallType = Constants.TEMPLATE_PREPOSITION_AFTER;
                }
            }
            else if (this.intervallType == Constants.TEMPLATE_PREPOSITION_AFTER) {
                this.intervall = this.intervall + (this.seriesIntervall * 7);
            }
        }
        else if (this.seriesIntervallUnit == 'T') {
            this.toDoDate.setDate(this.toDoDate.getDate() + this.seriesIntervall);
            if (this.intervallType == Constants.TEMPLATE_PREPOSITION_BEFORE) {
                if (this.intervall >= this.seriesIntervall) {
                    this.intervall = this.intervall - this.seriesIntervall;
                }
                else if (this.intervall < this.seriesIntervall) {
                    this.intervall = this.seriesIntervall - this.intervall;
                    this.intervallType = Constants.TEMPLATE_PREPOSITION_AFTER;
                }
            }
            else if (this.intervallType == Constants.TEMPLATE_PREPOSITION_AFTER) {
                this.intervall = this.intervall + this.seriesIntervall;
            }
        }
    };
    ToDo.prototype.generateNewSeriesToDo = function () {
        var toDo;
        toDo = this.daoToDos.createNewInstance();
        toDo.toDoType = this.toDoType;
        toDo.type = this.type;
        toDo.name = this.name;
        toDo.quantity = this.quantity;
        toDo.quantityUnit = this.quantityUnit;
        if (this.toDoType == Constants.TODO_STANDALONE || this.toDoType == Constants.TODO_BED || this.toDoType == Constants.TODO_AREA ||
            this.toDoType == Constants.TODO_SERIES_STANDALONE || this.toDoType == Constants.TODO_SERIES_BED || this.toDoType == Constants.TODO_SERIES_AREA ||
            this.toDoType == Constants.TODO_RECORD_ABSOLUTE || this.toDoType == Constants.TODO_SERIES_RECORD_ABSOLUTE) {
            toDo.dueDate = new Date;
            toDo.dueDate.setTime(this.toDoDate.getTime());
        }
        else {
            toDo.time = this.intervall;
            toDo.timeUnit = this.timeUnit;
            toDo.timeType = this.intervallType;
            toDo.timeReference = this.timeReference;
        }
        toDo.person = '';
        toDo.color = this.color;
        toDo.state = '';
        toDo.note = this.note;
        toDo.parentType = this.parentType;
        toDo.parentId = this.parentId;
        toDo.bedIdStart = this.bedIdStart;
        toDo.bedIdEnd = this.bedIdEnd;
        toDo.person = this.person;
        toDo.seriesMaster = this.id;
        return toDo;
    };
    ToDo.prototype.isSeriesMaster = function () {
        if (this.seriesMaster == this.id) {
            return true;
        }
        else {
            return false;
        }
    };
    Object.defineProperty(ToDo.prototype, "seriesMasterToDo", {
        get: function () {
            if (!this._seriesMasterToDo) {
                if (this.seriesMaster != '' && !this.isSeriesMaster()) {
                    this._seriesMasterToDo = this.daoToDos.getObjectById(this.seriesMaster);
                }
            }
            return this._seriesMasterToDo;
        },
        enumerable: true,
        configurable: true
    });
    ToDo.prototype.hasSeriesMasterChanged = function () {
        if (this.seriesRelevantAttributHasChanged) {
            return true;
        }
        else {
            return false;
        }
    };
    ToDo.prototype.getQuantityUnitText = function () {
        if (this.quantityUnit != '') {
            var fieldDTO = void 0;
            fieldDTO = this.myApplication.textDTOs.get('ToDo.quantity.' + this.quantityUnit);
            if (fieldDTO) {
                return fieldDTO.text;
            }
            else {
                return '';
            }
        }
        else {
            return '';
        }
    };
    ToDo.prototype.deleteAllDependentToDos = function () {
        var _this = this;
        var toDo;
        // nur wenn am SerienMaster loeschen ausgewaehlt wurde werden abhaengige mit geloescht
        if (this.isSeriesMaster()) {
            this.daoToDos.objectSet.objects.forEach(function (element) {
                toDo = element;
                if (toDo.seriesMaster == _this.id) {
                    // nur loeschen wenn nicht erledigt
                    if (!toDo.isDone()) {
                        _this.daoToDos.deleteObject(toDo);
                    }
                }
            });
        }
    };
    ToDo.prototype.reNewSeriesToDos = function (toDos) {
        var _this = this;
        this.toDoDate = new Date();
        this.toDoDate.setTime(this.dueDate.getTime());
        this.intervall = this.time;
        this.intervallType = this.timeType;
        toDos = toDos.sort(function (one, two) {
            var compare = 0;
            if (one.dueDate > two.dueDate) {
                compare = 1;
            }
            else if (one.dueDate < two.dueDate) {
                compare = -1;
            }
            else {
                compare = 0;
            }
            return compare;
        });
        toDos.forEach(function (element) {
            // ToDo noch nicht erledigt dann aendern oder loeschen        
            if (element.isDone() == false) {
                if (_this.toDoDate > _this.endDueDate) {
                    _this.daoToDos.deleteObject(element);
                }
                else {
                    if (_this.isRelative()) {
                        element.time = _this.intervall;
                        element.timeUnit = _this.timeUnit;
                        element.timeType = _this.intervallType;
                        element.timeReference = _this.timeReference;
                    }
                    else {
                        element.dueDate = new Date;
                        element.dueDate.setTime(_this.toDoDate.getTime());
                    }
                    _this.calculateIntervall();
                }
                element.parentType = _this.parentType;
                element.parentId = _this.parentId;
                element.bedIdStart = _this.bedIdStart;
                element.bedIdEnd = _this.bedIdEnd;
                element.toDoType = _this.toDoType;
                element.type = _this.type;
                element.person = _this.person;
            }
        });
        if (this.toDoDate <= this.endDueDate) {
            while (this.toDoDate <= this.endDueDate) {
                this.generateNewSeriesToDo();
                this.calculateIntervall();
            }
        }
    };
    ToDo.prototype.getAssignedBedsForArea = function (area) {
        var bedAssigns = this.getRecord().bedAssignList.bedAssigns;
        var bedList = new Array();
        var bedList2 = new Array();
        var beds;
        // alle verwendeten Beete des Schlags ermitteln
        bedAssigns.forEach(function (bedAssign) {
            if (bedAssign.getAreaId() == area.id) {
                bedList.push(bedAssign.bed);
            }
        });
        // nach Name oder Nummer sortieren
        bedList = bedList.sort(function (one, two) {
            var compare = one.orderNum.toString().localeCompare(two.orderNum.toString());
            return compare;
        });
        // doppelte raus
        var bedId = '';
        bedList.forEach(function (element) {
            if (bedId != element.id) {
                bedList2.push(element);
                bedId = element.id;
            }
        });
        var firstBed = true;
        if (bedList2.length > 1) {
            beds = this.myApplication.getText('Beds');
        }
        else {
            beds = this.myApplication.getText('RecordSet.bedId');
        }
        bedList2.forEach(function (bed) {
            if (firstBed) {
                beds = beds + ' ' + bed.getName();
                firstBed = false;
            }
            else {
                beds = beds + ', ' + bed.getName();
            }
        });
        return beds;
    };
    return ToDo;
}(AbstractObject));
export { ToDo };
