import { Injectable } from '@angular/core';
import { WeekInYearDTO } from '../app-core/dto/week-in-year-dto';
import { weekNumber, weekNumberYear } from 'weeknumber';
import { MonthDTO } from '../app-core/dto/month-dto';


@Injectable({
  providedIn: 'root',
})
export class BasicService {

  public today = new Date();
  public mobileMode = false;

  constructor() {
    if (window.innerWidth < 768) {
      this.mobileMode = true;
    }
  }

  getUTCTimestamp(date?: Date): number {
    if (!date) {
      date = new Date();
    }
    let utc = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
    return utc.getTime() / 1000;
  }

  randomColor(): String {
    let color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }

  public parseDate(input: string, format?) {
    if (input.length != 10 && input.length != 8) {
      return null;
    }
    let day = input.substring(0, 2);
    let month = input.substring(3, 5);
    let year = input.substring(6);

    if (year.length == 2 && input.length == 8) {
      year = '20' + year;
      let newInput = input.substring(0,6);
      input = newInput + year;
    }

    if (isNaN(Number(day)) ||
        isNaN(Number(month)) ||
        isNaN(Number(year))) {
      return null;
    }
    format = format || 'dd.mm.yyyy'; // default format
    let parts = input.match(/(\d+)/g),
      i = 0, fmt = {};
    // extract date-part indexes from the format
    format.replace(/(yyyy|dd|mm)/g, function (part) { fmt[part] = i++; });

    return new Date(Number.parseInt(parts[fmt['yyyy']]), Number.parseInt(parts[fmt['mm']]) - 1, Number.parseInt(parts[fmt['dd']]));
  }

  isInPeriod(startWeek: WeekInYearDTO, endWeek: WeekInYearDTO, week: WeekInYearDTO) {
    if ((week.week >= startWeek.week && week.year >= startWeek.year || week.year > startWeek.year)
      && (week.week <= endWeek.week && week.year <= endWeek.year || week.year < endWeek.year)) {
      return true;
    } else {
      return false;
    }
  }

  periodsOverlaps(startWeek: WeekInYearDTO, endWeek: WeekInYearDTO, startWeek2: WeekInYearDTO, endWeek2: WeekInYearDTO) {
    if (this.isInPeriod(startWeek, endWeek, startWeek2) || this.isInPeriod(startWeek, endWeek, endWeek2) ||
      this.isInPeriod(startWeek2, endWeek2, startWeek) || this.isInPeriod(startWeek2, endWeek2, endWeek)) {
      return true;
    } else {
      return false;
    }
  }

  normalizeDate(value: Date): Date {
    value.setHours(12);
    value.setMinutes(0);
    value.setSeconds(0);
    value.setMilliseconds(0);
    return value;
  }

  isDateInWeek(date: Date, week: WeekInYearDTO): boolean {
    return this.weekEquals(this.getWeek(date), week);
  }

  getLastDateOfMonth(month: number, year: number): Date {
    let date = new Date(year, month + 1, 1); //day 0 in Month + 1
    date.setDate(date.getDate() - 1)
    return date;
  }

  getFirstDateOfMonth(month: number, year: number): Date {
    let date = new Date(year, month, 1);
    return date;
  }

  isLastWeekOfAMonth(months: Array<MonthDTO>, week: number): boolean {
    let i = 0;
    while (i < months.length) {
      if (week == months[i].lastWeek.week) {
        return true;
      }
      i = i + 1;
    }
    return false;
  }

  weekIsGreaterThan(week1: WeekInYearDTO, week2: WeekInYearDTO) {
    if (week1.year > week2.year) {
      return true;
    } else if (week1.year == week2.year && week1.week > week2.week) {
      return true;
    } else {
      return false;
    }
  }

  calculateWeekDiff(week1: WeekInYearDTO, week2: WeekInYearDTO): number {
    let w1;
    let w2;

    if (this.weekIsGreaterThan(week1, week2)) {
      w1 = week1;
      w2 = week2;
    } else {
      w1 = week2;
      w2 = week1;
    }

    let diff = 0;
    while (this.weekIsGreaterThan(w1, w2)) {
      w2 = this.addWeeks(w2, 1);
      diff = diff + 1;
    }
    return diff;
  }

  weekIsLessThan(week1: WeekInYearDTO, week2: WeekInYearDTO) {
    if (!this.weekIsGreaterThan(week1, week2) && !this.weekEquals(week1, week2)) {
      return true;
    } else {
      return false;
    }
  }

  public addWeeks(weekInYear: WeekInYearDTO, weeks: number): WeekInYearDTO {
    let newWeek = new WeekInYearDTO(weekInYear.week, weekInYear.year);

    newWeek.week = newWeek.week + weeks;
    let numWeeks = this.getNumWeeks(newWeek.year);

    while (newWeek.week > numWeeks) {
      newWeek.year = newWeek.year + 1;
      newWeek.week = newWeek.week - numWeeks;
      numWeeks = this.getNumWeeks(newWeek.year);
    }
    return newWeek;
  }

  public subtractWeeks(weekInYear: WeekInYearDTO, weeks: number): WeekInYearDTO {
    let newWeek = new WeekInYearDTO(weekInYear.week, weekInYear.year);
    let sub = weeks;

    while (sub > 0) {
      newWeek.week = newWeek.week - 1;
      if (newWeek.week <= 0) {
        newWeek.year = newWeek.year - 1;
        newWeek.week = this.getNumWeeks(newWeek.year);
      }
      sub = sub - 1;
    }
    return newWeek;
  }

  weekEquals(week1: WeekInYearDTO, week2: WeekInYearDTO): boolean {
    if (week1.week == week2.week && week1.year == week2.year) {
      return true;
    }
    else {
      return false;
    }
  }

  getNewId(): String {
    return Math.random().toString().substr(2) + Math.random().toString().substr(2);
  }

  getNumWeeks(year: number): number {
    if (this.getFirstWeekDay(year) == 3 || this.getLastWeekDay(year) == 3) {
      return 53;
    }
    return 52;
  }

  getRandomColor() {
    var color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }

  getWeek(date: Date): WeekInYearDTO {
    // let week = new WeekInYearDTO(Math.ceil((this.getFirstWeekDay(date.getFullYear()) + this.getDayOfYear(date)) / 7), date.getFullYear());    
    let week = new WeekInYearDTO(weekNumber(date), weekNumberYear(date).year);
    // if (week.week > this.getNumWeeks(week.year)) {
    //   week.week = 1;
    //   week.year = week.year + 1;
    // }
    // let weeknumber = weekNumber(date);
    // console.log(weeknumber);
    return week;
  }

  getFirstDateOfWeek(week: WeekInYearDTO): Date {
    let date = new Date();
    date.setDate(1);
    date.setMonth(0);
    date.setFullYear(week.year);
    let firstWeekDay = this.getFirstWeekDay(week.year);
    let diff = firstWeekDay;
    if (firstWeekDay > 3) {//Montag bis Donnerstag
      diff = 7 - firstWeekDay;
    }

    let numDays;

    if (week.week == 1) {
      if (firstWeekDay <= 3) {//Montag bis Donnerstag
        date.setDate(date.getDate() - diff);
      } else {
        date.setDate(date.getDate() + diff);
      }
      return date;
    } else {
      if (firstWeekDay <= 3) {
        numDays = Math.round((week.week - 1) * 7 - diff);
      } else {
        numDays = Math.round((week.week - 1) * 7 + diff);
      }
      date.setDate(date.getDate() + numDays);
      return date;
    }
  }

  public getLastDateOfWeek(week: WeekInYearDTO): Date {
    let date = this.getFirstDateOfWeek(week);
    date.setDate(date.getDate() + 6);
    return date;
  }

  getDayOfYear(date: Date): number {
    let days;
    if (this.isLeapYear(date.getFullYear())) {
      days = [0, 31, 60, 91, 121, 152, 182, 213, 244, 274, 305, 335];
    } else {
      days = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
    }
    let dayOfYear = date.getDate();
    dayOfYear = dayOfYear + days[date.getMonth()];
    return dayOfYear;
  }

  public isLeapYear(year: number): boolean {
    if ((year % 4 == 0 && year % 100 != 0) || (year % 400 == 0)) {
      return true;
    } else {
      return false;
    }
  }

  public getFirstWeekDay(year: number): number {
    let date = new Date();
    date.setDate(1);
    date.setMonth(0);
    date.setFullYear(year);
    return this.getWeekDay(date);
    // let num = date.getDay();
    // if (num == 0) {
    //   num = 7;
    // }
    // num = num - 1;
    // return num;
  }

  getWeekDay(date: Date): number {
    let num = date.getDay();
    if (num == 0) {
      num = 7;
    }
    num = num - 1;
    return num;
  }

  getLastWeekDay(year: number): number {
    let date = new Date();
    date.setDate(1);
    date.setMonth(11);
    date.setDate(31);
    date.setFullYear(year);
    return this.getWeekDay(date);
  }

  ownCeil(inNum: number): number {
    let outNum = Math.round(inNum * 100);
    return Math.ceil(outNum / 100);
  }
}
