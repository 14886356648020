import { Component, OnInit, Input } from '@angular/core';
import { Sort } from 'src/app/app-core/business-logic/sort';
import { DaoService } from 'src/app/services/dao.service';
import { DAOSorts } from 'src/app/app-core/dao/dao-sorts';
import { ApplicationService } from 'src/app/services/application.service';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-sort-seed',
  templateUrl: './sort-seed.component.html',
  styleUrls: ['./sort-seed.component.css']
})
export class SortSeedComponent implements OnInit {

  @Input() sort: Sort;
  private daoSorts: DAOSorts;
  public editTitle = false;

  public actionDeleteSorts: boolean = false;
  public actionEditSorts: boolean = false;

  constructor(daoService: DaoService, private myApplication: ApplicationService,
    public permissionsService: PermissionsService) {
    this.daoSorts = <DAOSorts>daoService.getDAO('sorts');
    this.checkAllowedActions();
  }

  ngOnInit() {
  }

  delete(sort: Sort) {
    this.daoSorts.deleteObject(sort);
  }

  titleClicked() {
    this.editTitle = true;
  }

  endTitleEditing() {
    this.editTitle = false;
  }

  headerClicked() {
    this.myApplication.save();
  }

  checkAllowedActions() {
    this.actionDeleteSorts = this.permissionsService.checkPermissionForAction('sorts', 'delete', null);
    this.actionEditSorts = this.permissionsService.checkPermissionForAction('sorts', 'edit', null);
  }
}
