import * as tslib_1 from "tslib";
import { DAO } from '../technical-aspects/dao';
import { Person } from '../business-logic/person';
var DAOPersons = /** @class */ (function (_super) {
    tslib_1.__extends(DAOPersons, _super);
    function DAOPersons() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DAOPersons.prototype.createInstance = function () {
        var newInstance = new Person();
        return newInstance;
    };
    DAOPersons.prototype.createNewInstance = function () {
        var object = _super.prototype.createNewInstance.call(this);
        object.name = '<' + this.myApplication.getText('new') + '>';
        return object;
    };
    return DAOPersons;
}(DAO));
export { DAOPersons };
