import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EventHandler } from 'src/app/app-core/technical-aspects/event-handler';
import { Listener } from 'src/app/app-core/technical-aspects/listener';
import { ApplicationService } from 'src/app/services/application.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-delivery-plan',
  templateUrl: './delivery-plan.component.html',
  styleUrls: ['./delivery-plan.component.css']
})
export class DeliveryPlanComponent implements OnInit, OnDestroy, Listener  {

  public url: SafeResourceUrl;

  innerHeight = 1100;


  constructor(public sanitizer: DomSanitizer, private myApplication: ApplicationService, private breadcrumbSrv: BreadcrumbService) {

    this.breadcrumbSrv.breadcrumb = this.myApplication.getText('DeliveryPlan').toString();
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.deliveryPlanModule + this.myApplication.year.toString());

    this.myApplication.addObserver(this);
    this.myApplication.eventHandler.register(EventHandler.LANGUAGE_CHANGED, this);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerHeight = window.innerHeight - 60;
  }
  
  ngOnInit() {
    this.onResize(null);
  }

  update() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.deliveryPlanModule + this.myApplication.year.toString());
  }

  handleLanguageChanged() {
    this.breadcrumbSrv.breadcrumb = this.myApplication.getText('DeliveryPlan').toString();
    
    let iframe = (<HTMLIFrameElement>document.getElementById("gardening-cal"));
    if (iframe) {
      iframe.contentWindow.postMessage({ "message": "languageChanged" }, '*');
    }
  }

  ngOnDestroy() {
    this.myApplication.eventHandler.deRegister(EventHandler.LANGUAGE_CHANGED, this);
    this.myApplication.removeObserver(this);
  }
}
