import * as tslib_1 from "tslib";
import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';
import { Constants } from '../business-logic/constants';
var RecordEO = /** @class */ (function (_super) {
    tslib_1.__extends(RecordEO, _super);
    function RecordEO() {
        var _this = _super.call(this) || this;
        _this.cultureId = '';
        _this.areaId = '';
        _this.seedId = '';
        _this.quickId = 0;
        _this.recordNum = 0;
        _this.state = 'P';
        _this.quantity = 0;
        _this.plantDistance = 0;
        _this.rowDistance = 0;
        _this.plantDeepth = 0;
        _this.numRows = 0;
        _this.quickpotSize = 0;
        _this.seedsPerQuickpot = 0;
        _this.lengthOnBed = 0;
        _this.harvestMethod = '';
        _this.harvestUnit = '';
        _this.note = '';
        _this.sort = '';
        _this.cultureType = Constants.CULTURE_TYPE_DIREKTSAAT;
        _this.seedDate = new Date();
        _this.growDate = new Date();
        _this.harvestDate = new Date();
        _this.harvestLength = 0;
        _this.harvestQuantity = 0;
        _this.numOfDeliveryWeeks = 0;
        _this.averageQuantity = 0;
        _this.neededHarvestQuantity = 0;
        _this.safety = 0;
        _this.alternativeDelivery = false;
        _this.tags = '';
        _this.plantsPerBox = 0;
        _this.seedTS = 0;
        _this.growTS = 0;
        _this.harvestTS = 0;
        _this.cultureEndTS = 0;
        _this.cultureName = '';
        _this.seedRate = 100;
        _this.extraQuantity = 0;
        _this.useExtraQuantity = false;
        _this.fixUserInput = '';
        _this.totalHarvestQuantity = 0;
        return _this;
    }
    return RecordEO;
}(AbstractEntityObject));
export { RecordEO };
