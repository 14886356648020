import { Injectable } from '@angular/core';
import { RequestOptions, Http } from '@angular/http';
import { AuthenticationService } from './authentication.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  public userSettings;

  constructor(private http: Http, private authenticationService: AuthenticationService) {
    this.readData();
  }

  public savePreferredLanguage(language) {
    let options = new RequestOptions();

    options.headers = this.authenticationService.getHeadersAuthenticated();
    let data;
    data = new Object();

    data.language = language;

    let payload = JSON.stringify(data);
    return new Promise<void>((resolve, reject) => {
      this.http.patch(environment.apiV2 + 'preferredLanguage',  payload, options).pipe(
      )
        .subscribe(
          () => {
            resolve();
          },
          err => {
            console.log('There was an error updating preferredLanguage.', err);
          }
        );
    });
  }

  public readData() {
    const options = new RequestOptions();
    options.headers = this.authenticationService.getHeadersAuthenticated();

    return new Promise<void>((resolve, reject) => {
      this.http.get(environment.apiV2 + 'userSettings', options).pipe(
        map(resp => resp.json())
      )
        .subscribe(result => {
          this.userSettings = result;
          resolve();
        });
    });
  }

}
