import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EventHandler } from 'src/app/app-core/technical-aspects/event-handler';
import { ApplicationService } from 'src/app/services/application.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-storage',
  templateUrl: './storage.component.html',
  styleUrls: ['./storage.component.css']
})
export class StorageComponent implements OnInit {

  public url: SafeResourceUrl;

  innerHeight = 1100;


  constructor(public sanitizer: DomSanitizer, private myApplication: ApplicationService,
    private breadcrumbSrv: BreadcrumbService) {

    this.breadcrumbSrv.breadcrumb = this.myApplication.getText('Role.store').toString();
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.storageModule);

    this.myApplication.eventHandler.register(EventHandler.LANGUAGE_CHANGED, this);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerHeight = window.innerHeight - 60;
  }
  
  ngOnInit() {
    this.onResize(null);
  }

  update() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.storageModule);
  }

  handleLanguageChanged() {
    this.breadcrumbSrv.breadcrumb = this.myApplication.getText('Role.store').toString();
    let iframe: any = document.getElementById('storage');
    iframe.contentWindow.location.reload();
  }
}
