import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DAOBedAssigns } from 'src/app/app-core/dao/dao-bed-assigns';
import { DAORecords } from 'src/app/app-core/dao/dao-records';
import { DAOToDos } from 'src/app/app-core/dao/dao-todos';
import { EventHandler } from 'src/app/app-core/technical-aspects/event-handler';
import { Listener } from 'src/app/app-core/technical-aspects/listener';
import { ApplicationService } from 'src/app/services/application.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { DaoService } from 'src/app/services/dao.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gardening-cal',
  templateUrl: './gardening-cal.component.html',
  styleUrls: ['./gardening-cal.component.css']
})
export class GardeningCalComponent implements OnInit, OnDestroy, Listener {

  constructor(public sanitizer: DomSanitizer, private myApplication: ApplicationService, private daoService: DaoService,
    private breadcrumbSrv: BreadcrumbService) {

    this.breadcrumbSrv.breadcrumb = this.myApplication.getText('Feedback.view.Anbau-Kalender').toString();
  }

  ngOnInit() {
    let container = document.getElementById('gardening-cal');
    if (container) {
      container.style.display = "";
    }

    let iframe = (<HTMLIFrameElement>container);
    iframe.contentWindow.postMessage({ "message": "setVisible" }, '*');
  }

  update() {
  }

  ngOnDestroy() {
    let container = document.getElementById('gardening-cal');
    if (container) {
      container.style.display = "none";
    }
  }
}
