import { Injectable } from '@angular/core';
import Html2Canvas from 'html2canvas';
import * as jspdf from 'jspdf';


@Injectable({
  providedIn: 'root'
})

export class GeneratePdfService {

  constructor() { }

  private resizeTo(canvas, pct) {
    let tempCanvas = document.createElement('canvas');
    tempCanvas.width = canvas.width;
    tempCanvas.height = canvas.height;

    let tctx = tempCanvas.getContext("2d");
    tctx.drawImage(canvas, 0, 0);
    canvas.width *= pct;
    canvas.height *= pct;

    let ctx = canvas.getContext('2d');
    ctx.drawImage(tempCanvas, 0, 0, tempCanvas.width, tempCanvas.height, 0, 0,
      tempCanvas.width * pct, tempCanvas.height * pct);
  }

  private crop(canvas, width, height, offset) {
    const ctx = canvas.getContext('2d');

    // get the image data you want to keep.
    // const imageData = ctx.getImageData(0, offset, 297, 210);
    console.log('offset: ', offset);
    console.log('breite: ', width);
    console.log('hoehe: ', height);

    const imageData = ctx.getImageData(0, offset, width, height);

    // create a new canvas same as clipped size and a context
    const newCan = document.createElement('canvas');
    newCan.width = width;
    newCan.height = height;
    const newCtx = newCan.getContext('2d');

    // put the clipped image on the new canvas.
    newCtx.putImageData(imageData, 0, 0);

    return newCan;
  }

  public generatePDF(elementName: String, filename: String) {
    let pdf = new jspdf('l', 'mm', 'a4'); // A4 size page of PDF

    Html2Canvas(document.getElementById('Header')).then(canvas => {

      var img_header = canvas.toDataURL("image/PNG");
      return img_header;

    }).then((img_header) => {
      Html2Canvas(document.getElementById('Aplan')).then(canvas => {

        //Header erstellen
        const bufferX = 5;
        const bufferY = 5;
        const imgHeaderProps = (<any>pdf).getImageProperties(img_header);
        const pdfWidth = pdf.internal.pageSize.getWidth() - (2 * bufferX);
        const pdfHeight = (imgHeaderProps.height * pdfWidth) / imgHeaderProps.width;
        pdf.addImage(img_header, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight);

        //Saetze erstellen
        var img_data = canvas.toDataURL("image/PNG");
        let imgDataProps = (<any>pdf).getImageProperties(img_data);
        const pdfDataHeight = (imgDataProps.height * pdfWidth) / imgDataProps.width;

        let imgDataHeight = imgDataProps.height;
        let maxHeight = imgDataProps.width * 210 / 297;
        let offset_header = 20;

        //nur eine Seite
        if (imgDataHeight + 20 <= maxHeight) {
          pdf.addImage(img_data, 'PNG', bufferX, 20, pdfWidth, pdfDataHeight);
        }
        //mehrere Seite, dann zeiteilen
        else {
          let offset = 0;
          let i = 1;

          while(imgDataHeight + offset_header > maxHeight) {
            if (i > 1) {
              //wenn nicht 1. Durchlauf dann neue Seite anlegen
              console.log('neue Seite');
              pdf.addPage();
              pdf.setPage(i);
              pdf.addImage(img_header, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight);
            }
            //   //const canvasCrop = this.crop(canvas, canvas.width, imgHeight, offset);
            console.log(offset);
            const canvasCrop = this.crop(canvas, imgDataProps.width, 1100, offset);

            //Teilbild
            let contentDataURL = canvasCrop.toDataURL('image/png');
            imgDataProps = (<any>pdf).getImageProperties(contentDataURL);
            let dataHeigth = (imgDataProps.height * pdfWidth) / imgDataProps.width;

            pdf.addImage(contentDataURL, 'PNG', 5, 20, pdfWidth, dataHeigth);

            //Restbildgroeße
            imgDataHeight = imgDataHeight - imgDataProps.height;
            offset = offset + imgDataProps.height;
            console.log('offset_schleife: ', offset);
            i = i + 1;

          }

          // Header nochmal imgDataProps.height
          pdf.addPage();
          pdf.setPage(i);
          pdf.addImage(img_header, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight);

          const canvasCrop = this.crop(canvas, imgDataProps.width, 1100, offset);
          console.log('offset2: ', offset);
          const contentDataURL = canvasCrop.toDataURL('image/png');

          imgDataProps = (<any>pdf).getImageProperties(contentDataURL);
          console.log(imgDataProps.height);
          let dataHeigth = (imgDataProps.height * pdfWidth) / imgDataProps.width;
          console.log(i + 1, '. Bild');
          pdf.addImage(contentDataURL, 'PNG', bufferX, 20, pdfWidth, dataHeigth);
        }

        pdf.save(filename + '.pdf');
        return pdf;

      });
    });


    // const position = 10;

    // let offset  = 0;

    // while (offset + pageHeight < canvas.height) {
    //   while (offset + pdfHeight < canvas.height) {
    //   //const canvasCrop = this.crop(canvas, canvas.width, imgHeight, offset);
    //   const canvasCrop = this.crop(canvas, canvas.width, imgProps.height, offset);

    //   const contentDataURL = canvasCrop.toDataURL('image/png');
    //   this.resizeTo(canvasCrop, 0.1);

    //  // pdf.addImage(contentDataURL, 'PNG', 5, 5, canvasCrop.width, canvasCrop.height);
    //  pdf.addImage(contentDataURL, 'PNG', bufferX, bufferY, canvasCrop.width, canvasCrop.height);
    //   offset = offset + imgProps.height;

    //   if (offset + pdfHeight < canvas.height) {
    //     pdf.addPage();
    //   }
    // }

    // let outString = pdf.output('dataurinewwindow');
    //  let x = window.open();
    //  x.document.location = outString;
    //  }

    // pdf.save(filename + '.pdf');

    // let data = document.getElementById("Aplan");
    // // let data = document.body;
    // html2canvas(data).then(canvas => {
    //   let imgWidth = 208;
    //   let pageHeigth = 295;
    //   let imgHeight = canvas.height * imgWidth / canvas.width;
    //   let heigthLeft = imgHeight;

    //   console.log(imgHeight);
    //   console.log(imgWidth);

    //   const contentDataURL = canvas.toDataURL('image/png');

    //   let pdf = new jspdf('p', 'mm', 'a4');
    //   let position = 0;
    //   pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);

    //   pdf.save('AnbauPlan.pdf');
    //   // let outString = pdf.output('datauristring');
    //   // let x = window.open();
    //   // x.document.location = outString;
    // });

  }
}
