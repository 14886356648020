import { DAOSeeds } from '../dao/dao-seeds';
import { Seed } from './seed';
import { ApplicationService } from 'src/app/services/application.service';
import { DAOSortSeeds } from '../dao/dao-sort-seeds';
import { SortSeed } from './sort-seed';
import { EventHandler } from '../technical-aspects/event-handler';

export class SeedSet {

    private _seeds: Array<Seed>;
    private _seedsFiltered: Array<Seed>;
    private _seedsFilteredOtherUnit: Array<Seed>;
    private _all = false;
    private _otherUnit = false;


    constructor(protected daoSeeds: DAOSeeds, protected daoSortSeed: DAOSortSeeds, protected sortId: String, private myApplication: ApplicationService) {
        myApplication.eventHandler.register(EventHandler.SEED_DELETED_EXTERNAL, this);
    }

    handleSeedDeletedExternal(parameter: String) {
        if (parameter == this.sortId) {
            this._seeds = null;
            this._seedsFiltered = null;
            this._seedsFilteredOtherUnit = null;
        }
    }

    public get all() {
        return this._all;
    }
    public set all(value) {
        if (value != this._all) {
            this._all = value;
            this.buildSeedsFiltered();
        }
    }

    protected buildSeedsFiltered() {
        this._seedsFiltered = new Array<Seed>();
        this._seedsFilteredOtherUnit = new Array<Seed>();
        this.seeds.forEach(seed => {
            if ((!seed.done || this.all) && !seed.getSortSeed().otherUnit) {
                seed.newWithNewSortSeed = false;
                this._seedsFiltered.push(seed);
            }
            if ((!seed.done || this.all) && seed.getSortSeed().otherUnit) {
                seed.newWithNewSortSeed = false;
                this._seedsFilteredOtherUnit.push(seed);
            }
        });
        this._seedsFiltered = this.sortSeeds(this._seedsFiltered);
        this._seedsFilteredOtherUnit = this.sortSeeds(this._seedsFilteredOtherUnit);


    }

    private sortSeeds(list: Array<Seed>): Array<Seed> {
        return list.sort(function (one, two) {
            return one.getSortSeed().manufacturingYear - two.getSortSeed().manufacturingYear;
        });
    }

    public get seeds(): Array<Seed> {
        if (!this._seeds) {
            this._seeds = new Array<Seed>();
            this.daoSeeds.objects.forEach(element => {
                let seed = <Seed>element;
                if (seed.sortId == this.sortId) {
                    this._seeds.push(seed);
                }
            });
        }
        return this._seeds;
    }

    public get seedsFiltered(): Array<Seed> {
        if (!this._seedsFiltered) {
            this.buildSeedsFiltered();
        }
        return this._seedsFiltered;
    }

    public set seeds(value: Array<Seed>) {
        this._seeds = value;
    }

    public get seedsFilteredOtherUnit(): Array<Seed> {
        if (!this._seedsFilteredOtherUnit) {
            this.buildSeedsFiltered();
        }
        return this._seedsFilteredOtherUnit;
    }



    // public create(year: number, manufacturer: String) {
    //     let newInstance = <Seed>this.daoSeeds.createNewInstance();
    //     newInstance.sortId = this.sortId;
    //     this.daoSortSeed.findSortSeed(year, manufacturer);
    //     this.buildSeedsFiltered();
    // }

    public createWithSortSeed(sortSeed: SortSeed): Seed {
        let newInstance = <Seed>this.daoSeeds.createNewInstance();
        newInstance.sortId = this.sortId;
        newInstance.sortSeedId = sortSeed.id;
        this.myApplication.save();
        this.seeds.push(newInstance);
        this.buildSeedsFiltered();
        return newInstance;
    }


    deleteSeed(seed: Seed) {
        this.daoSeeds.deleteObject(seed);
        this._seeds = null;
        this._seedsFiltered = null;
    }

    public get otherUnit() {
        return this._otherUnit;
    }
    public set otherUnit(value) {
        if (value != this._otherUnit) {
            this._otherUnit = value;
            this.buildSeedsFiltered();
        }
    }
}