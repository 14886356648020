<div class="form-row">
  <div *ngIf="fieldDTO.text" class="form-group" [ngClass]="colsLabel">
    <label class="col-form-label col-form-label-sm text-secondary" [for]="id">{{ fieldDTO.text }}</label>
  </div>
  <div [ngClass]="colsField">
    <div class="input-group input-group-sm" [ngbTooltip]="fieldDTO.help" placement="top" [openDelay]="1000">

      <input class="form-control form-control-sm my_right_aligned {{getWarningClass()}}" type="text"
        [(ngModel)]="value" [id]="id" [placeholder]="fieldDTO.text" [disabled]="disable"
        [min]="min" [step]="step" pattern="[0-9]+([\.|,][0-9]{1,2})?" (blur)="onBlur($event)">

      <div *ngIf="unitValueName" class="input-group-append text-muted">
        <div class="input-group-append text-muted">
          <select class="form-control form-control-sm" [disabled]="disable" [id]="id + 'select'"
            [(ngModel)]="object[unitValueName.toString()]">
            <option *ngFor="let keyValue of keyValueList" [value]="keyValue.key">{{ keyValue.value }}</option>
          </select>
        </div>
      </div>
      <div *ngIf="btnIcon" class="input-group-append">
        <button class="btn btn-sm" [ngClass]="btnClass" (click)="onClick()"
          type="button" [ngbTooltip]="btnDTO.text">
          <fa [name]="btnIcon"></fa>
        </button>
      </div>
    </div>

  </div>
</div>