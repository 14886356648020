import * as tslib_1 from "tslib";
import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';
var BedAssignEO = /** @class */ (function (_super) {
    tslib_1.__extends(BedAssignEO, _super);
    function BedAssignEO() {
        var _this = _super.call(this) || this;
        _this.parentId = '';
        _this.bedId = '';
        _this.positionOnBed = 0;
        _this.lengthOnBed = 0;
        _this.startTS = 0;
        _this.cultureEndTS = 0;
        return _this;
    }
    return BedAssignEO;
}(AbstractEntityObject));
export { BedAssignEO };
