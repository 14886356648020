import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ApplicationService } from 'src/app/services/application.service';
import { Router } from '@angular/router';
import { EventHandler } from 'src/app/app-core/technical-aspects/event-handler';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit, EventListenerObject {

  @Input() myUrl: string;
  @Input() toDoContext: String;
  public url: SafeResourceUrl;

  constructor(public sanitizer: DomSanitizer, private myApplication: ApplicationService, private router: Router) {
    window.addEventListener('message', this);
  }

  handleEvent(event: MessageEvent): void {
    if (event.data.message == 'heigth') {
      let iframe = document.getElementById('themeframe');
      if (iframe) {
        iframe.style.border = "none";
        let height = event.data.value;
        iframe.style.height = height + 'px';
        // iframe.style.height = "100% !important";
        iframe.style.width = "100%";
      }
    }
  }

  ngOnInit() {
    let iframe = document.getElementById('themeframe');
    if (iframe) {
      iframe.style.border = "none";
      iframe.style.height = '200px';
      iframe.style.width = "100%";
    }

    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.myUrl);

    this.myApplication.addObserver(this);
    this.myApplication.eventHandler.register(EventHandler.LANGUAGE_CHANGED, this);
  }

  ngOnDestroy() {
    this.myApplication.eventHandler.deRegister(EventHandler.LANGUAGE_CHANGED, this);
  }

  update() {

    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.myUrl);

    let iframe = document.getElementById('themeframe');
    if (iframe == null) {
      return;
    }
    let iWindow = (<HTMLIFrameElement>iframe).contentWindow;
  }

  handleLanguageChanged() {
    let iframe = (<HTMLIFrameElement>document.getElementById("themeframe"));
    if (iframe) {
      iframe.contentWindow.postMessage({ "message": "languageChanged" }, this.myUrl);

    }
  }
}
