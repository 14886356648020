import * as tslib_1 from "tslib";
import { WeekInYearDTO } from '../dto/week-in-year-dto';
import { Observable } from '../technical-aspects/observable';
import { Constants } from './constants';
var FilteredRecordSet = /** @class */ (function (_super) {
    tslib_1.__extends(FilteredRecordSet, _super);
    function FilteredRecordSet(objects, myApplication, basicService) {
        var _this = _super.call(this) || this;
        _this.myApplication = myApplication;
        _this.basicService = basicService;
        _this._sort = 2;
        _this._color = 2;
        _this._areaId = '<empty>';
        _this._bedId = '<empty>';
        _this._calType = '';
        _this._cultureType = '';
        _this._consumerType = '';
        _this._fullPlanned = '';
        _this._hasSort = '';
        _this._bedM = '';
        _this._isInPeriod = '';
        _this.useBedFilter = false;
        _this.isFiltered = false;
        _this.replaceObjects(objects);
        myApplication.addObserver(_this);
        _this._actWeek = _this.basicService.getWeek(new Date()).week;
        return _this;
    }
    FilteredRecordSet.prototype.uncheckAll = function () {
        this.completeObjects.forEach(function (record) {
            record.checked = false;
        });
    };
    Object.defineProperty(FilteredRecordSet.prototype, "tags", {
        get: function () {
            if (!this._tags) {
                this._tags = new Array();
            }
            return this._tags;
        },
        set: function (value) {
            this._tags = value;
            this.update();
        },
        enumerable: true,
        configurable: true
    });
    FilteredRecordSet.prototype.addTag = function (tag) {
        this.tags.push(tag);
        this.update();
    };
    FilteredRecordSet.prototype.removeTag = function (tag) {
        var index = this._tags.indexOf(tag);
        if (index !== -1) {
            this._tags.splice(index, 1);
        }
        this.update();
    };
    FilteredRecordSet.prototype.reset = function () {
        this._kulturFilter = '';
        this._stateFilter = '';
        this._sort = 2;
        this._actWeek = this.basicService.getWeek(new Date()).week;
        this._fromKW = 0;
        this._toKW = 0;
        this._freeTextFilter = '';
        this._areaId = '<empty>';
        this._bedId = '<empty>';
        this._calType = '';
        this._consumerType = '';
        this._fullPlanned = '';
        this._hasSort = '';
        this._bedM = '';
        this._isInPeriod = '';
        this.tags = null;
        this._cultureType = Constants.NOT_RELEVANT;
        this.update();
    };
    FilteredRecordSet.prototype.numNotFullPlanned = function () {
        this.reset();
        var sum = 0;
        this.objects.forEach(function (record) {
            if (!record.bedAssignList.isFullPlanned()) {
                sum = sum + 1;
            }
        });
        return sum;
    };
    FilteredRecordSet.prototype.numNoSort = function () {
        this.reset();
        var sum = 0;
        this.objects.forEach(function (record) {
            if (!record.sort) {
                sum = sum + 1;
            }
        });
        return sum;
    };
    FilteredRecordSet.prototype.numNoBedM = function () {
        this.reset();
        var sum = 0;
        this.objects.forEach(function (record) {
            if (record.lengthOnBed <= 0) {
                sum = sum + 1;
            }
        });
        return sum;
    };
    FilteredRecordSet.prototype.getNumChecked = function () {
        var i = 0;
        var record;
        for (var j = 0; j < this.objects.length; j++) {
            record = this.objects[j];
            if (record.checked == true && record.recordPeriods.isInYear(this.myApplication.year)) {
                i = i + 1;
            }
        }
        return i;
    };
    Object.defineProperty(FilteredRecordSet.prototype, "isInPeriod", {
        get: function () {
            return this._isInPeriod;
        },
        set: function (value) {
            this._isInPeriod = value;
            this.update();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilteredRecordSet.prototype, "fullPlanned", {
        get: function () {
            return this._fullPlanned;
        },
        set: function (value) {
            this._fullPlanned = value;
            this.update();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilteredRecordSet.prototype, "cultureType", {
        get: function () {
            return this._cultureType;
        },
        set: function (value) {
            this._cultureType = value;
            this.update();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilteredRecordSet.prototype, "bedM", {
        get: function () {
            return this._bedM;
        },
        set: function (value) {
            this._bedM = value;
            this.update();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilteredRecordSet.prototype, "hasSort", {
        get: function () {
            return this._hasSort;
        },
        set: function (value) {
            this._hasSort = value;
            this.update();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilteredRecordSet.prototype, "consumerType", {
        get: function () {
            return this._consumerType;
        },
        set: function (value) {
            this._consumerType = value;
            this.update();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilteredRecordSet.prototype, "areaId", {
        get: function () {
            return this._areaId;
        },
        set: function (value) {
            this._areaId = value;
            if (this._areaId != '' && this.areaId != '<empty>') {
                this.useBedFilter = true;
            }
            else {
                this.useBedFilter = false;
            }
            this._bedId = '<empty>';
            this.update();
            this.hasChanged();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilteredRecordSet.prototype, "calType", {
        get: function () {
            return this._calType;
        },
        set: function (value) {
            this._calType = value;
            this.update();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilteredRecordSet.prototype, "bedId", {
        get: function () {
            return this._bedId;
        },
        set: function (value) {
            this._bedId = value;
            this.update();
        },
        enumerable: true,
        configurable: true
    });
    FilteredRecordSet.prototype.copyToNextYear = function () {
        this.objects.forEach(function (record) {
            record.cloneToNextYear();
        });
        this.myApplication.messageDTO.setSuccessWithId('NumberCreatedNewRecordsYear', this.objects.length);
    };
    Object.defineProperty(FilteredRecordSet.prototype, "kulturFilter", {
        get: function () {
            return this._kulturFilter;
        },
        set: function (value) {
            this._kulturFilter = value;
            this.update();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilteredRecordSet.prototype, "color", {
        get: function () {
            return this._color;
        },
        set: function (value) {
            this._color = value;
            this.update();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilteredRecordSet.prototype, "stateFilter", {
        get: function () {
            return this._stateFilter;
        },
        set: function (value) {
            this._stateFilter = value;
            this.update();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilteredRecordSet.prototype, "sort", {
        get: function () {
            return this._sort;
        },
        set: function (value) {
            this._sort = value;
            this.update();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilteredRecordSet.prototype, "fromKW", {
        get: function () {
            return this._fromKW;
        },
        set: function (value) {
            this._fromKW = value;
            this.update();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilteredRecordSet.prototype, "toKW", {
        get: function () {
            return this._toKW;
        },
        set: function (value) {
            this._toKW = value;
            this.update();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilteredRecordSet.prototype, "freeTextFilter", {
        get: function () {
            return this._freeTextFilter;
        },
        set: function (value) {
            this._freeTextFilter = value;
            this.update();
        },
        enumerable: true,
        configurable: true
    });
    FilteredRecordSet.prototype.addObject = function (object) {
        this.completeObjects.push(object);
    };
    FilteredRecordSet.prototype.replaceObjects = function (objects) {
        this.completeObjects = objects;
        if (!this.completeObjects) {
            this.completeObjects = new Array();
        }
        this.update();
    };
    FilteredRecordSet.prototype.justThisWeek = function () {
        this.fromKW = this.toKW = this._actWeek;
    };
    FilteredRecordSet.prototype.wholeYear = function () {
        this.fromKW = 1;
        this.toKW = this.basicService.getNumWeeks(this.myApplication.year);
    };
    FilteredRecordSet.prototype.update = function () {
        this.objects = this.filterByYear(this.completeObjects);
        var numAfterYearFilter = this.objects.length;
        this.objects = this.filterByKultur(this.objects);
        this.objects = this.filterByState(this.objects);
        // this.objects = this.filterByKW(this.objects);
        this.objects = this.filterByAreaId(this.objects);
        this.objects = this.filterByBedId(this.objects);
        this.objects = this.filterByCalType(this.objects);
        this.objects = this.filterByConsumerType(this.objects);
        this.objects = this.filterByFullPlanned(this.objects);
        this.objects = this.filterByHasSort(this.objects);
        this.objects = this.filterByNoBedM(this.objects);
        this.objects = this.filterByCultureType(this.objects);
        this.objects = this.filterByIsInPeriod(this.objects);
        this.objects = this.filterByTags(this.objects);
        var sort = this.sort;
        this.objects = this.objects.sort(function (one, two) {
            var compare = 0;
            if (sort == 1) { //alphabetisch
                compare = one.getRecordName().localeCompare(two.getRecordName().toString());
            }
            else if (sort == 2) { //Aussaat/Pflanzdatum
                compare = (one.recordPeriods.growDate.getTime() + one.recordNum) - (two.recordPeriods.growDate.getTime() + two.recordNum);
            }
            else if (sort == 3) {
                compare = one.state.toString().localeCompare(two.state.toString());
            }
            else if (sort == 4) {
                compare = one.state.toString().localeCompare(two.state.toString());
                if (compare == 0) {
                    if (one.recordPeriods.getStartDate() > two.recordPeriods.getStartDate()) {
                        compare = 1;
                    }
                    else if (one.recordPeriods.getStartDate() < two.recordPeriods.getStartDate()) {
                        compare = -1;
                    }
                    else {
                        compare = 0;
                    }
                }
            }
            else if (sort == 5) { // next action
                if (this.basicService.weekIsGreaterThan(one.recordPeriods.getNextActionWeek(), two.recordPeriods.getNextActionWeek())) {
                    return 1;
                }
                if (this.basicService.weekEquals(one.recordPeriods.getNextActionWeek(), two.recordPeriods.getNextActionWeek())) {
                    return 0;
                }
                else {
                    return -1;
                }
            }
            else if (sort == 6) { //Kultur
                compare = one.getRecordName().toString().localeCompare(two.getRecordName().toString());
            }
            else if (sort == 7) { //Erntedatum
                compare = one.recordPeriods.harvestDate.getTime() - two.recordPeriods.harvestDate.getTime();
                if (compare == 0) {
                    compare = one.recordPeriods.harvestLength - two.recordPeriods.harvestLength;
                }
            }
            else if (sort == 8) { //Pflanzdatum /Aussaatdatum bei Direktsaat = growDate
                compare = one.recordPeriods.growDate.getTime() - two.recordPeriods.growDate.getTime();
            }
            else if (sort == 9) { //Anzuchtdatum (Anzucht, Pflanzung/Direktsaat)
                var oneValue = '';
                var twoValue = '';
                if (one.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
                    oneValue = 'A' + one.recordPeriods.seedDate.getTime().toString();
                }
                else {
                    oneValue = 'B' + one.recordPeriods.growDate.getTime().toString();
                    ;
                }
                if (two.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
                    twoValue = 'A' + two.recordPeriods.seedDate.getTime().toString();
                }
                else {
                    twoValue = 'B' + two.recordPeriods.growDate.getTime().toString();
                    ;
                }
                compare = oneValue.localeCompare(twoValue);
            }
            return compare;
        });
        if (numAfterYearFilter != this.objects.length) {
            this.isFiltered = true;
        }
        else {
            this.isFiltered = false;
        }
    };
    FilteredRecordSet.prototype.filterByTags = function (list) {
        if (this.tags.length == 0) {
            return list;
        }
        this.tags.forEach(function (tag) {
            var newList = new Array();
            list.forEach(function (record) {
                if (record.includesTag(tag)) {
                    newList.push(record);
                }
            });
            list = newList;
        });
        return list;
    };
    FilteredRecordSet.prototype.filterByIsInPeriod = function (list) {
        var _this = this;
        if (this._isInPeriod == '<empty>' || this._isInPeriod == Constants.NOT_RELEVANT) {
            return this.filterByKW(list);
        }
        var resultSet = new Array();
        var startWeek;
        if (this.fromKW < 1) {
            startWeek = new WeekInYearDTO(1, this.myApplication.year);
        }
        else {
            startWeek = new WeekInYearDTO(this.fromKW, this.myApplication.year);
        }
        var endWeek;
        if (this.toKW < 1) {
            endWeek = new WeekInYearDTO(this.basicService.getNumWeeks(this.myApplication.year), this.myApplication.year);
        }
        else {
            endWeek = new WeekInYearDTO(this.toKW, this.myApplication.year);
        }
        list.forEach(function (record) {
            var week;
            if (_this._isInPeriod == Constants.CULTURE_TYPE_ANZUCHT && record.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
                week = record.recordPeriods.seedWeek;
            }
            else if (_this._isInPeriod == 'P') { // irgendeine Pflanzung/Direktsaat
                week = record.recordPeriods.growWeek;
            }
            if (week && _this.basicService.isInPeriod(startWeek, endWeek, week)) {
                resultSet.push(record);
            }
            if (_this._isInPeriod == 'S') { // Anzucht/Direktsaat/Pflanzung
                if (_this.basicService.isInPeriod(startWeek, endWeek, record.recordPeriods.getStartWeek()) ||
                    _this.basicService.isInPeriod(startWeek, endWeek, record.recordPeriods.growWeek)) {
                    resultSet.push(record);
                }
            }
            if (_this._isInPeriod == 'E') {
                if (_this.basicService.periodsOverlaps(startWeek, endWeek, record.recordPeriods.harvestWeek, record.recordPeriods.lastHarvestWeek)) {
                    resultSet.push(record);
                }
            }
        });
        return resultSet;
    };
    FilteredRecordSet.prototype.filterByCultureType = function (list) {
        var _this = this;
        if (this._cultureType == Constants.NOT_RELEVANT) {
            return list;
        }
        var resultSet = new Array();
        list.forEach(function (record) {
            if (_this._cultureType == record.cultureType) {
                resultSet.push(record);
            }
        });
        return resultSet;
    };
    FilteredRecordSet.prototype.filterByHasSort = function (list) {
        var _this = this;
        if (this.hasSort == Constants.NOT_RELEVANT) {
            return list;
        }
        var resultSet = new Array();
        list.forEach(function (record) {
            if (_this.hasSort == Constants.YES &&
                record.sort != '' ||
                _this.hasSort == Constants.NO &&
                    record.sort == '') {
                resultSet.push(record);
            }
        });
        return resultSet;
    };
    FilteredRecordSet.prototype.filterByFullPlanned = function (list) {
        var _this = this;
        if (this.fullPlanned == Constants.NOT_RELEVANT) {
            return list;
        }
        var resultSet = new Array();
        list.forEach(function (record) {
            if (_this.fullPlanned == Constants.YES &&
                record.bedAssignList.isFullPlanned() ||
                _this.fullPlanned == Constants.NO &&
                    !record.bedAssignList.isFullPlanned() ||
                _this.fullPlanned == Constants.NO_BEDMETERS &&
                    record.lengthOnBed <= 0) {
                resultSet.push(record);
            }
        });
        return resultSet;
    };
    FilteredRecordSet.prototype.filterByNoBedM = function (list) {
        var _this = this;
        if (this.bedM == Constants.NOT_RELEVANT) {
            return list;
        }
        var resultSet = new Array();
        list.forEach(function (record) {
            if (_this.bedM == Constants.YES &&
                record.lengthOnBed > 0 ||
                _this.bedM == Constants.NO &&
                    record.lengthOnBed <= 0) {
                resultSet.push(record);
            }
        });
        return resultSet;
    };
    FilteredRecordSet.prototype.filterByAreaId = function (list) {
        var _this = this;
        if (this.areaId == '<empty>') {
            return list;
        }
        var resultSet = new Array();
        list.forEach(function (record) {
            if (_this.areaId == record.areaId) {
                resultSet.push(record);
            }
        });
        return resultSet;
    };
    FilteredRecordSet.prototype.filterByCalType = function (list) {
        var _this = this;
        if (this.calType == '') {
            return list;
        }
        var resultSet = new Array();
        list.forEach(function (record) {
            if (_this.calType == 'A' && record.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
                resultSet.push(record);
            }
        });
        return resultSet;
    };
    FilteredRecordSet.prototype.filterByConsumerType = function (list) {
        var _this = this;
        if (this.consumerType == '') {
            return list;
        }
        var resultSet = new Array();
        list.forEach(function (record) {
            if (_this.consumerType == record.culture.consumerType) {
                resultSet.push(record);
            }
        });
        return resultSet;
    };
    FilteredRecordSet.prototype.filterByBedId = function (list) {
        var _this = this;
        if (this.bedId == '<empty>') {
            return list;
        }
        var resultSet = new Array();
        list.forEach(function (record) {
            if (record.bedAssignList.isOnBed(_this.bedId)) {
                resultSet.push(record);
            }
        });
        return resultSet;
    };
    FilteredRecordSet.prototype.filterByKW = function (list) {
        var _this = this;
        var resultSet = new Array();
        if (!this.fromKW || !this.toKW) {
            return list;
        }
        var fromWeek = new WeekInYearDTO(this.fromKW, this.myApplication.year);
        var toWeek = new WeekInYearDTO(this.toKW, this.myApplication.year);
        list.forEach(function (element) {
            var startWeek = element.recordPeriods.getStartWeek();
            var endWeek = element.recordPeriods.lastHarvestWeek;
            if (_this.basicService.periodsOverlaps(fromWeek, toWeek, startWeek, endWeek)) {
                resultSet.push(element);
            }
        });
        return resultSet;
    };
    FilteredRecordSet.prototype.filterByYear = function (lRecord) {
        var _this = this;
        var resultSet = new Array();
        lRecord.forEach(function (record) {
            if (record.recordPeriods.isInYear(_this.myApplication.year)) {
                resultSet.push(record);
            }
        });
        return resultSet;
    };
    FilteredRecordSet.prototype.filterByKultur = function (lRecord) {
        var _this = this;
        var resultSet = new Array();
        if (this.kulturFilter && this.kulturFilter != '') {
            lRecord.forEach(function (element) {
                if (element.culture.id == _this.kulturFilter) {
                    resultSet.push(element);
                }
            });
        }
        else {
            resultSet = lRecord;
        }
        return resultSet;
    };
    FilteredRecordSet.prototype.filterByState = function (lRecord) {
        var _this = this;
        var resultSet = new Array();
        if (this.stateFilter && this.stateFilter != '') {
            lRecord.forEach(function (element) {
                if (element.state == _this.stateFilter) {
                    resultSet.push(element);
                }
            });
        }
        else {
            resultSet = lRecord;
        }
        return resultSet;
    };
    return FilteredRecordSet;
}(Observable));
export { FilteredRecordSet };
