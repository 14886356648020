import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Http, RequestOptions } from '@angular/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { RepositoryDTO } from '../app-core/dto/repository-dto';
import { Constants } from '../app-core/business-logic/constants';
import { BasicService } from './basic.service';
import { Culture } from '../app-core/business-logic/culture';

@Injectable({
  providedIn: 'root'
})
export class RepositoryService {

  public repository = new RepositoryDTO();
  public loading = true;
  public isExpired = false;


  constructor(private authenticationService: AuthenticationService, private http: Http, private basicService: BasicService) {
  }


  private readData(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const options = new RequestOptions();
      options.headers = this.authenticationService.getHeadersAuthenticated();
      this.http.get(environment.apiV2 + 'repository', options).pipe(
        map(resp => resp.json())
      )
        .subscribe(
          res => {
            let repositoryJSON = res;

            this.repository = new RepositoryDTO();
            this.repository.name = repositoryJSON['name'];
            this.repository.package = repositoryJSON['package'];
            this.repository.partLimit = Number.parseFloat(repositoryJSON['partLimitname']);
            this.repository.system = repositoryJSON['system'];
            this.repository.bedWidth = Number.parseFloat(repositoryJSON['bedWidth']);
            this.repository.bedLength = Number.parseFloat(repositoryJSON['bedLength']);
            this.repository.firstName = repositoryJSON['firstName'];
            this.repository.lastName = repositoryJSON['lastName'];
            this.repository.agbAndDp = repositoryJSON['agbAndDp'];
            this.repository.createdAndAgreedTS = repositoryJSON['createdAndAgreedTS'];
            this.repository.infoText = repositoryJSON['infoText'];
            this.repository.pathWidth = repositoryJSON['pathWidth'];
            this.repository.type = repositoryJSON['type'];
            this.repository.repoState = repositoryJSON['repoState'];
            this.repository.expires = Number.parseInt(repositoryJSON['expires']);
            if (!repositoryJSON['preferences']) {
              this.repository.preferences = { "quickpotList": [] };
            } else {
              this.repository.preferences = repositoryJSON['preferences'];
            }

            this.isExpired = this.isExpiredRepo();
            this.repository.permittedModules = repositoryJSON['permittedModules'];
            this.repository.currentUser = repositoryJSON['currentUser'];;

            this.loading = false;
            resolve();
          }
        );
    });
  }

  isInactive(): boolean {
    return this.repository.repoState == 'I';
  }

  isGekuendigt(): boolean {
    return this.repository.repoState == 'C';
  }

  refresh(): Promise<void> {
    return this.readData(); //returns promise
  }

  public isQuickpotInList(culture: Culture) {
    if (!this.findQuickpotById(culture.quickpotId)) {
      if (!this.findQuickpotByName(culture.quickpotName)) {
        if (!this.findQuickpotBySize(culture.quickpotSize)) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  public findQuickpot(culture: Culture) {
    let quickpot = this.findQuickpotById(culture.quickpotId);
    if (!quickpot) {
      quickpot = this.findQuickpotByName(culture.quickpotName);
      // if (!quickpot) {
      //   // quickpot = this.findQuickpotBySize(culture.quickpotSize);
      //   // if (!quickpot) {
      //     return null;
      //   } else {
      //     return quickpot;
      //   }
      // } else {
      return quickpot;
      // }
    } else {
      return quickpot;
    }
  }

  public findQuickpotById(id) {
    for (let i = 0; i < this.repository.preferences.quickpotList.length; i++) {
      if (this.repository.preferences.quickpotList[i].id == id && !this.repository.preferences.quickpotList[i].deleted) {
        return this.repository.preferences.quickpotList[i];
      }
    }
    return null;
  }
  private findQuickpotByName(name) {
    for (let i = 0; i < this.repository.preferences.quickpotList.length; i++) {
      if (this.repository.preferences.quickpotList[i].name == name && !this.repository.preferences.quickpotList[i].deleted) {
        return this.repository.preferences.quickpotList[i];
      }
    }
    return null;
  }
  private findQuickpotBySize(size) {
    for (let i = 0; i < this.repository.preferences.quickpotList.length; i++) {
      if (this.repository.preferences.quickpotList[i].qSize == size && !this.repository.preferences.quickpotList[i].deleted) {
        return this.repository.preferences.quickpotList[i];
      }
    }
    return null;
  }

  private isExpiredRepo(): boolean {
    if (this.repository.repoState == 'I' || this.repository.repoState == 'C') { //inactive oder gekündigt
      return true;
    }

    if (this.repository.package != Constants.PACKAGE_TEST || this.repository.type == Constants.REPO_GROUP_CHILD) {
      return false;
    }
    let today = new Date();
    if (this.basicService.getUTCTimestamp(today) > this.repository.expires) {
      return true;
    }

    return false;
  }
}
