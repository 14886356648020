import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LegalAgreeComponent } from '../ui-components/app-info/legal/legal-agree/legal-agree.component';
import { ModalComponent } from '../ui-components/modal/modal.component';
import { environment } from 'src/environments/environment';
import { ApplicationService } from './application.service';


@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private modalService: NgbModal, private myApplication: ApplicationService) { }

  public createModalTodoV2(id: String, context: String): NgbModalRef {

    let ngbModalOptions: NgbModalOptions = {
      windowClass: "xlModal",
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true
    };

    const modalRef = this.modalService.open(ModalComponent, ngbModalOptions);
    modalRef.componentInstance.myUrl = environment.toDoModule + '/#/toDos/' + id + '/' + context;

    return modalRef;
  }

  public createGardeningCal(): NgbModalRef {

    let ngbModalOptions: NgbModalOptions = {
      windowClass: "xlModal",
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true
    };

    const modalRef = this.modalService.open(ModalComponent, ngbModalOptions);
    modalRef.componentInstance.myUrl = environment.cultureModule + '/gardeningCalCreate/' + this.myApplication.year;

    return modalRef;
  }

  public createRecordCreateV2(cultureId?: string): NgbModalRef {

    let ngbModalOptions: NgbModalOptions = {
      windowClass: "xlModal",
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true
    };

    const modalRef = this.modalService.open(ModalComponent, ngbModalOptions);
    if (cultureId) {
      modalRef.componentInstance.myUrl = environment.recordModule + '/#/create/' + cultureId;
    } else {
      modalRef.componentInstance.myUrl = environment.cultureModule + '/recordCreate/' + this.myApplication.year;
    }

    return modalRef;
  }

  public createCultureV2(): NgbModalRef {

    let ngbModalOptions: NgbModalOptions = {
      windowClass: "xlModal",
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true
    };

    const modalRef = this.modalService.open(ModalComponent, ngbModalOptions);
    modalRef.componentInstance.myUrl = environment.cultureModule + '/cultureCreate';

    return modalRef;
  }

  public createModalTodoCreate(id: String, context: String, templateId: String, templateName: String, areaId: String, bedId: String): NgbModalRef {
    let ngbModalOptions: NgbModalOptions = {
      windowClass: "xlModal",
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true
    };

    const modalRef = this.modalService.open(ModalComponent, ngbModalOptions);

    if (context == 'area') {
      if (bedId) {
        if (templateName) {
          modalRef.componentInstance.myUrl = environment.toDoModule + '/#/toDo/bed/' + areaId + '/' + bedId + '/' + context + '/name/' + templateName;
        } else if (areaId) {
          modalRef.componentInstance.myUrl = environment.toDoModule + '/#/toDo/bed/' + areaId + '/' + bedId + '/' + context;
        }
      } else {
        if (templateName) {
          modalRef.componentInstance.myUrl = environment.toDoModule + '/#/toDo/area/' + areaId + '/' + context + '/name/' + templateName;
        } else if (areaId) {
          modalRef.componentInstance.myUrl = environment.toDoModule + '/#/toDo/area/' + areaId + '/' + context;
        }
      }

    } else if (context == 'record') {
      if (templateId) {
        modalRef.componentInstance.myUrl = environment.toDoModule + '/#/toDo/record/' + id + '/' + context + '/id/' + templateId;
      } else if (templateName) {
        modalRef.componentInstance.myUrl = environment.toDoModule + '/#/toDo/record/' + id + '/' + context + '/name/' + templateName;
      } else {
        modalRef.componentInstance.myUrl = environment.toDoModule + '/#/toDo/record/' + id + '/' + context;
      }
    } else if (context == 'calendar') {
      if (templateName) {
        modalRef.componentInstance.myUrl = environment.toDoModule + '/#/toDo/calendar/' + context + '/name/' + templateName;
      } else {
        modalRef.componentInstance.myUrl = environment.toDoModule + '/#/toDo/calendar/' + context;
      }
    } else if (context == 'bulk') {
      if (templateId) {
        modalRef.componentInstance.myUrl = environment.toDoModule + '/#/toDo/bulk/id/' + templateId;
      } else if (templateName) {
        modalRef.componentInstance.myUrl = environment.toDoModule + '/#/toDo/bulk/name/' + templateName;
      } else {
        modalRef.componentInstance.myUrl = environment.toDoModule + '/#/toDo/bulk';
      }
    }


    return modalRef;
  }

  public createRecordCreation(cultureId?: string): NgbModalRef {

    let ngbModalOptions: NgbModalOptions = {
      windowClass: "xlModal",
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true
    };

    const modalRef = this.modalService.open(ModalComponent, ngbModalOptions);
    if (cultureId) {
      modalRef.componentInstance.myUrl = environment.recordModule + '/#/record-create/' + cultureId;
    } else {
      modalRef.componentInstance.myUrl = environment.recordModule + '/#/record-create';
    }

    return modalRef;
  }

  public createAgreement(): NgbModalRef {

    let ngbModalOptions: NgbModalOptions = {
      windowClass: "xlModal",
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true
    };

    const modalRef = this.modalService.open(LegalAgreeComponent, ngbModalOptions);
    modalRef.componentInstance.modal = modalRef;

    return modalRef;
  }
}
