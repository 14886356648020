import { RequestOptions, Http } from '@angular/http';
import { AuthenticationService } from './authentication.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
import * as i2 from "./authentication.service";
var UserSettingsService = /** @class */ (function () {
    function UserSettingsService(http, authenticationService) {
        this.http = http;
        this.authenticationService = authenticationService;
        this.readData();
    }
    UserSettingsService.prototype.savePreferredLanguage = function (language) {
        var _this = this;
        var options = new RequestOptions();
        options.headers = this.authenticationService.getHeadersAuthenticated();
        var data;
        data = new Object();
        data.language = language;
        var payload = JSON.stringify(data);
        return new Promise(function (resolve, reject) {
            _this.http.patch(environment.apiV2 + 'preferredLanguage', payload, options).pipe()
                .subscribe(function () {
                resolve();
            }, function (err) {
                console.log('There was an error updating preferredLanguage.', err);
            });
        });
    };
    UserSettingsService.prototype.readData = function () {
        var _this = this;
        var options = new RequestOptions();
        options.headers = this.authenticationService.getHeadersAuthenticated();
        return new Promise(function (resolve, reject) {
            _this.http.get(environment.apiV2 + 'userSettings', options).pipe(map(function (resp) { return resp.json(); }))
                .subscribe(function (result) {
                _this.userSettings = result;
                resolve();
            });
        });
    };
    UserSettingsService.ngInjectableDef = i0.defineInjectable({ factory: function UserSettingsService_Factory() { return new UserSettingsService(i0.inject(i1.Http), i0.inject(i2.AuthenticationService)); }, token: UserSettingsService, providedIn: "root" });
    return UserSettingsService;
}());
export { UserSettingsService };
