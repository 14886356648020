import { Save } from './save';


export class SaveInsert extends Save {

    save() {
        this.http.post(this.route, this.data, this.options).pipe(
            //map(resp => resp.json())
        )
            .subscribe(
                () => {

                },
                err => {
                    console.log('There was an error inserting ressource ' + this.route + '.', err);
                }
            );
    }
}