import { Http, RequestOptions } from '@angular/http';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import { map, retry } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
import * as i2 from "./authentication.service";
var PermissionsService = /** @class */ (function () {
    function PermissionsService(http, authentificationService) {
        this.http = http;
        this.authentificationService = authentificationService;
        this.ready = false;
    }
    PermissionsService.prototype.readPermissions = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var options = new RequestOptions();
            options.headers = _this.authentificationService.getHeadersAuthenticated();
            _this.http.get(environment.apiV2 + 'userPermissions', options).pipe(retry(3), map(function (resp) { return resp.json(); }))
                .subscribe(function (res) {
                _this.permissions = res;
                _this.ready = true;
                resolve(null);
            });
        });
    };
    PermissionsService.prototype.checkPermissionForAction = function (myObject, myAction, myUnderObject) {
        var result = false;
        this.permissions.forEach(function (object) {
            if (object.name == myObject) {
                if (myUnderObject) {
                    object.objects.forEach(function (underObject) {
                        if (underObject.name == myUnderObject) {
                            underObject.actions.forEach(function (action) {
                                if (action.name == myAction) {
                                    if (action.value) {
                                        result = true;
                                    }
                                }
                            });
                        }
                    });
                }
                else {
                    object.actions.forEach(function (action) {
                        if (action.name == myAction) {
                            if (action.value) {
                                result = true;
                            }
                        }
                    });
                }
            }
        });
        return result;
    };
    PermissionsService.ngInjectableDef = i0.defineInjectable({ factory: function PermissionsService_Factory() { return new PermissionsService(i0.inject(i1.Http), i0.inject(i2.AuthenticationService)); }, token: PermissionsService, providedIn: "root" });
    return PermissionsService;
}());
export { PermissionsService };
