import { Component, OnInit, ViewChild } from '@angular/core';
import { DaoService } from 'src/app/services/dao.service';
import { DAOSeeds } from 'src/app/app-core/dao/dao-seeds';
import { Seed } from 'src/app/app-core/business-logic/seed';
import { ApplicationService } from 'src/app/services/application.service';
import { Listener } from 'src/app/app-core/technical-aspects/listener';
import { NeedsCalculator } from 'src/app/app-core/business-logic/needs-calculator';
import { DAORecords } from 'src/app/app-core/dao/dao-records';
import { BasicService } from 'src/app/services/basic.service';
import { DAOCultures } from 'src/app/app-core/dao/dao-cultures';
import { Culture } from 'src/app/app-core/business-logic/culture';
import { NeedsCalculatorPlants } from 'src/app/app-core/business-logic/needs-calculator-plants';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NeedsModalComponent } from './needs-modal/needs-modal.component';
import { SeedsInventoryExcel } from 'src/app/app-core/business-logic/seeds-inventory-excel';
import { PermissionsService } from 'src/app/services/permissions.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { DAOSorts } from 'src/app/app-core/dao/dao-sorts';


@Component({
  selector: 'app-seed-inventory',
  templateUrl: './seed-inventory.component.html',
  styleUrls: ['./seed-inventory.component.css']
})

export class SeedInventoryComponent implements OnInit, Listener {

  public daoSeeds: DAOSeeds;
  public daoRecords: DAORecords;
  public daoCultures: DAOCultures;
  public cultures: Array<Culture>;
  public list: Array<Seed>;
  public showScreen: boolean = false;

  public actionCalculateSeeds: boolean = false;
  public actionExport: boolean = false;

  @ViewChild('popover') popover;


  constructor(daoService: DaoService, public myApplication: ApplicationService, private basicService: BasicService,
    private modalService: NgbModal,
    public permissionsService: PermissionsService,
    private breadcrumbSrv: BreadcrumbService) {

    this.breadcrumbSrv.breadcrumb = this.myApplication.getText('Feedback.view.Saatgut').toString();

    this.daoSeeds = <DAOSeeds>daoService.getDAO('seeds');
    this.daoRecords = <DAORecords>daoService.getDAO('records');
    this.daoRecords.refresh();
    this.daoCultures = <DAOCultures>daoService.getDAO('cultures');
    this.daoCultures.refresh().then(() => {
      this.cultures = <Array<Culture>>Array.from(this.daoCultures.objects.values());
      this.cultures = this.cultures.sort(function (one, two) {
        return one.name.localeCompare(two.name.toString());
      });

      let daoSortSeeds = <DAOSorts>daoService.getDAO('sortSeeds');
      daoSortSeeds.refresh().then(() => {
        this.daoSeeds.refresh().then(() => {
          let daoSorts = <DAOSorts>daoService.getDAO('sorts');
          daoSorts.refresh().then(() => {
            this.update();
            this.showScreen = true;
          });
        });
      });
    });


    this.checkAllowedActions();
    this.daoSeeds.addObserver(this);
  }

  update() {
    this.buildList();
  }

  ngOnInit() {
    // this.buildList();
  }

  protected buildList() {
    this.list = <Array<Seed>>Array.from(this.daoSeeds.objects.values());
  }

  public calculate() {
    let needsCalculator = new NeedsCalculator(this.daoRecords, this.daoSeeds, this.basicService, this.myApplication);
    needsCalculator.calculate();
    let needsPlantCalculator = new NeedsCalculatorPlants(this.daoRecords, this.daoSeeds, this.basicService);
    needsPlantCalculator.calculate();

    let ngbModalOptions: NgbModalOptions = {
      windowClass: "xlModal",
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    };

    const modalRef = this.modalService.open(NeedsModalComponent, ngbModalOptions);
    modalRef.componentInstance.needsCalculatorPlants = needsPlantCalculator;
    modalRef.componentInstance.needsCalculator = needsCalculator;
  }

  public calculateDay() {
    let needsCalculator = new NeedsCalculator(this.daoRecords, this.daoSeeds, this.basicService, this.myApplication);
    needsCalculator.calculate();
    let needsPlantCalculator = new NeedsCalculatorPlants(this.daoRecords, this.daoSeeds, this.basicService);
    needsPlantCalculator.calculateDay();

    let ngbModalOptions: NgbModalOptions = {
      windowClass: "xlModal",
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    };

    const modalRef = this.modalService.open(NeedsModalComponent, ngbModalOptions);
    modalRef.componentInstance.needsCalculatorPlants = needsPlantCalculator;
    modalRef.componentInstance.needsCalculator = needsCalculator;
    modalRef.componentInstance.perDay = true;
  }

  public exportSeedInventory() {
    let excel = new SeedsInventoryExcel(this.myApplication);
    excel.export(this.cultures);
  }

  checkAllowedActions() {
    this.actionCalculateSeeds = this.permissionsService.checkPermissionForAction('seeds', 'calculateSeeds', 'seedInventory');
    this.actionExport = this.permissionsService.checkPermissionForAction('seeds', 'export', 'seedInventory');
  }
}
