import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { NgbModule, NgbDatepickerModule, NgbAlertModule, NgbPopoverModule, NgbTabsetModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap'

import { AppComponent } from './app.component';
import { MainNavComponent } from './ui-components/main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { StartComponent } from './ui-components/start/start.component';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './ui-components/dashboard/dashboard.component';
import { ChartModule } from 'angular2-chartjs';
import { LicenseComponent } from './ui-components/app-info/license/license.component';
import { NumberUnitFieldComponent } from './ui-components/basic-components/number-unit-field/number-unit-field.component';
import { TextFieldComponent } from './ui-components/basic-components/text-field/text-field.component';
import { CheckFieldComponent } from './ui-components/basic-components/check-field/check-field.component';
import { SortListComponent } from './ui-components/sort-list/sort-list.component';
import { SelectFieldComponent } from './ui-components/basic-components/select-field/select-field.component';
import { CollapseButtonComponent } from './ui-components/basic-components/collapse-button/collapse-button.component';
import { CalendarFieldComponent, NgbDateCustomParserFormatter } from './ui-components/basic-components/calendar-field/calendar-field.component';
import { NumberUnitSelectFieldComponent } from './ui-components/basic-components/number-unit-select-field/number-unit-select-field.component';
import { ButtonComponent } from './ui-components/basic-components/button/button.component';
import { SeedInventoryComponent } from './ui-components/seed-inventory/seed-inventory.component';
import { SeedComponent } from './ui-components/seed/seed.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NeedsModalComponent } from './ui-components/seed-inventory/needs-modal/needs-modal.component';
import { LicenseAngularComponent } from './ui-components/app-info/license/license-angular/license-angular.component';
import { ConfirmComponent } from './ui-components/basic-components/confirm/confirm.component';
import { ConfirmModalComponent } from './ui-components/basic-components/confirm-modal/confirm-modal.component';
import { FeedbackModalComponent } from './ui-components/main-nav/feedback-modal/feedback-modal.component';
import { RegistrationComponent } from './ui-components/registration/registration.component';
import { NoteComponent } from './ui-components/basic-components/note/note.component';
import { LicenseNgbootstrapComponent } from './ui-components/app-info/license/license-ngbootstrap/license-ngbootstrap.component';
import { SortComponent } from './ui-components/sort/sort.component';
import { RepositoryChooseComponent } from './ui-components/repository-choose/repository-choose.component';
import { LegalAgreeComponent } from './ui-components/app-info/legal/legal-agree/legal-agree.component';
import { CookieService } from 'ngx-cookie-service';
import { SortSeedsComponent } from './ui-components/sort-seeds/sort-seeds.component';
import { CultureSeedComponent } from './ui-components/culture-seed/culture-seed.component';
import { SortSeedComponent } from './ui-components/sort-seed/sort-seed.component';
import { SeedsComponent } from './ui-components/seeds/seeds.component';
import { registerLocaleData, DecimalPipe } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { ToastrModule } from 'ngx-toastr';
import { StatisticComponent } from './ui-components/statistic/statistic.component';
import { CompanyComponent } from './ui-components/company/company.component';
import { NumberUnitFieldSimpleComponent } from './ui-components/basic-components/number-unit-field-simple/number-unit-field-simple.component';
import { DurationFieldComponent } from './ui-components/basic-components/duration-field/duration-field.component';
import { ConsumerComponent } from './ui-components/consumer/consumer.component';
import { GardeningCalComponent } from './ui-components/gardening-cal/gardening-cal.component';
import { ModalComponent } from './ui-components/modal/modal.component';
import { Areasv2Component } from './ui-components/areasv2/areasv2.component';
import { CalendarComponent } from './ui-components/calendar/calendar.component';
import { environment } from 'src/environments/environment';
import { BugComponent } from './ui-components/main-nav/bug/bug.component';
import { DeliveryPlanComponent } from './ui-components/delivery-plan/delivery-plan.component';
import { StorageComponent } from './ui-components/storage/storage.component';
import { HarvestV2Component } from './ui-components/harvest-v2/harvest-v2.component';
import { DeliveryComponent } from './ui-components/delivery/delivery.component';
import { WizardStartComponent } from './ui-components/wizard-start/wizard-start.component';
import { CulturesV2Component } from './ui-components/cultures-v2/cultures-v2.component';
import { UnsubscribeComponent } from './ui-components/unsubscribe/unsubscribe.component';


registerLocaleData(localeDe, 'de-DE');


@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    StartComponent,
    DashboardComponent,
    LicenseComponent,
    NumberUnitFieldComponent,
    TextFieldComponent,
    CheckFieldComponent,
    SortListComponent,
    SelectFieldComponent,
    CalendarFieldComponent,
    CollapseButtonComponent,
    NumberUnitSelectFieldComponent,
    ButtonComponent,
    SeedInventoryComponent,
    SeedComponent,
    NeedsModalComponent,
    LicenseAngularComponent,
    ConfirmComponent,
    ConfirmModalComponent,
    FeedbackModalComponent,
    RegistrationComponent,
    NoteComponent,
    LicenseNgbootstrapComponent,
    SortComponent,
    RepositoryChooseComponent,
    LegalAgreeComponent,
    SortSeedsComponent,
    CultureSeedComponent,
    SortSeedComponent,
    SeedsComponent,
    StatisticComponent,
    CompanyComponent,
    NumberUnitFieldSimpleComponent,
    DurationFieldComponent,
    ConsumerComponent,
    GardeningCalComponent,
    ModalComponent,
    Areasv2Component,
    CalendarComponent,
    BugComponent,
    DeliveryPlanComponent,
    StorageComponent,
    HarvestV2Component,
    DeliveryComponent,
    WizardStartComponent,
    CulturesV2Component,
    UnsubscribeComponent
  ],

  providers: [
    CookieService,
    DecimalPipe,
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    // { provide: ErrorHandler, useClass: MyErrorHandler },
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    LayoutModule,
    AngularFontAwesomeModule,
    NgbModule,
    NgbAlertModule,
    NgbPopoverModule,
    NgbModalModule,
    NgbTabsetModule,

    FormsModule,

    ReactiveFormsModule,

    ToastrModule.forRoot( { positionClass: 'toast-bottom-center' } ),

    HttpModule,
    HttpClientModule,
    FormsModule,
    ChartModule,

    NgbDatepickerModule,
  ],
  bootstrap: [AppComponent],

  entryComponents: [
    ModalComponent,
    RepositoryChooseComponent,
    LegalAgreeComponent,
    NeedsModalComponent,
    ConfirmModalComponent,
    WizardStartComponent
  ]

})

export class AppModule {
  constructor() {
    environment.gardeningCalModule = this.getRelativeUrl(environment.gardeningCalModule);
    environment.deliveryPlanModule = this.getRelativeUrl(environment.deliveryPlanModule);
    environment.deliveryModule = this.getRelativeUrl(environment.deliveryModule);
    environment.areasModule = this.getRelativeUrl(environment.areasModule);
    environment.wizardModule = this.getRelativeUrl(environment.wizardModule);
    environment.cultureModule = this.getRelativeUrl(environment.cultureModule);
  }

  public getRelativeUrl(url: string) {
    if (!url.includes('localhost')) {
      url = window.location.href.split('/#')[0] + url;
      return url;
    }
    return url;
  }
}
