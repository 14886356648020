import { OnInit } from '@angular/core';
import { FieldTextDTO } from 'src/app/app-core/dto/field-text-dto';
import { ApplicationService } from 'src/app/services/application.service';
var TextFieldComponent = /** @class */ (function () {
    function TextFieldComponent(myApplication) {
        this.myApplication = myApplication;
        this._id = "";
        this.fieldId = "";
        this._disable = false;
    }
    Object.defineProperty(TextFieldComponent.prototype, "disable", {
        get: function () {
            return this._disable;
        },
        set: function (value) {
            this._disable = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextFieldComponent.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (value) {
            if (!value) {
                this._id = this.valueName.toString();
            }
            this._id = value;
            this.fieldDTO = this.myApplication.textDTOs.get(this._id);
            if (!this.fieldDTO) {
                this.fieldDTO = new FieldTextDTO(this._id, this._id, '');
            }
            this.btnDTO = this.myApplication.textDTOs.get(this._id + '.btnTooltip');
            this.fieldId = this._id.replace('.', '-');
        },
        enumerable: true,
        configurable: true
    });
    TextFieldComponent.prototype.ngOnInit = function () {
    };
    TextFieldComponent.prototype.onClick = function () {
        this.btnObject[this.btnMethod.toString()](this.object);
    };
    return TextFieldComponent;
}());
export { TextFieldComponent };
