<div class="row">
  <div class="col-sm-4">
    <app-check-field [id]="'Seeds.done'" [object]="this" [valueName]="'all'"></app-check-field>
  </div>
</div>

<!-- fuer TKG -->
<ng-container *ngIf="checkIfTkgIsRelevant()">
  <div class="table-responsive">
    <table class="table table-sm table-striped table-hover table-condensed text-white">
      <thead [ngClass]="myApplication.theme.background">
        <tr class="d-flex">
          <td scope="col" class="col-1">
            <ng-container *ngIf="actionCreate">
              <button class="btn btn-sm ml-1" type="button" data-toggle="dropdown" [ngClass]="myApplication.theme.btn"
                [ngbTooltip]="myApplication.getTextSimple('Seed.create')"
                container="body" data-boundary="window">
                <fa name="plus"></fa>
              </button>
              <div class="dropdown-menu scrollable-menu">
                <a *ngFor="let sortSeed of sort.sortSeedSet.sortSeedsTkg" class="dropdown-item"
                  (click)="create(sortSeed)">{{ sortSeed.manufacturingYear + ' (' + sortSeed.manufacturer + ')' }}</a>
                <div role="separator" class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="createNew(false)">{{ myApplication.getText('Saatgutdaten.create') }}</a>
              </div>
            </ng-container>
          </td>
          <td class="col-1 my_right_aligned">
            {{ myApplication.getTextSimple('Seed.manufacturingYear') }}
          </td>
          <td class="my_right_aligned col-2">{{ myApplication.getTextSimple('Seed.manufacturer') }}</td>
          <td class="my_right_aligned col-2">{{ myApplication.getTextSimple('Seed.tkg') }}</td>
          <td class="my_right_aligned col-2">{{ myApplication.getTextSimple('Seed.quantity') }}</td>
          <td class="my_right_aligned col-2">{{ myApplication.getTextSimple('Seed.numCorn') }}</td>
          <td class="col-1"> {{ myApplication.getTextSimple('Seed.done') }}</td>
          <td class="col-1"></td>
        </tr>
      </thead>
      <tbody>
        <tr class="d-flex" *ngFor="let seed of seedSet.seedsFiltered">
          <ng-container *ngIf="!seed.newWithNewSortSeed">
            <td class="col-1"></td>
            <td class="my_right_aligned col-1">
              <app-text-field [id]="'SortSeed.manufacturingYear'" [object]="seed.getSortSeed()"
                [valueName]="'manufacturingYear'" [disable]="true">
              </app-text-field>
            </td>
            <td class="my_right_aligned col-2">
              <app-text-field [id]="'SortSeed.manufacturer'" [object]="seed.getSortSeed()" [valueName]="'manufacturer'"
                [disable]="true">
              </app-text-field>
            </td>
          </ng-container>

          <ng-container *ngIf="seed.newWithNewSortSeed">
            <td class="col-1"></td>
            <td class="my_right_aligned col-1">
              <app-select-field [id]="'SortSeed.manufacturingYear'" [object]="seed.getSortSeed()"
                [valueName]="'manufacturingYear'" [keyValueList]="years" [disable]="!actionEdit">
              </app-select-field>
            </td>
            <td class="my_right_aligned col-2">
              <app-text-field [id]="'SortSeed.manufacturer'" [object]="seed.getSortSeed()" [valueName]="'manufacturer'" [disable]="!actionEdit">
              </app-text-field>
            </td>
          </ng-container>
          <td class="my_right_aligned col-2">
            <app-number-unit-field [id]="'SortSeed.tkg'" [object]="seed.getSortSeed()" [valueName]="'tkg'" [unit]="'g'"
              [step]="0.001" [disable]="!actionEdit">
            </app-number-unit-field>
          </td>
          <td class="my_right_aligned col-2">
            <app-number-unit-select-field [id]="'Seed.quantityNoText'" [object]="seed" [valueName]="'quantity'"
              [unitValueName]="'quantityUnit'" [keyValueList]="unitList" [disable]="!actionEdit"></app-number-unit-select-field>
          </td>
          <td class="my_right_aligned text-muted col-2">
            {{ seed.getNumCorn() }}
            {{ myApplication.getText('Unit.Korn') }}
          </td>
          <td class="my_right_aligned col-1">
            <app-check-field [id]="'Seed.doneField'" [object]="seed" [valueName]="'done'" [disable]="!actionEdit"></app-check-field>
          </td>

          <td class="col-1">
            <app-button *ngIf="actionDelete" [iconName]="'trash-alt'" [id]="'Seed.delete'" [callback]="'delete'" [callbackOwner]="this"
              [object]="seed" [silent]="true">
            </app-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>

<!-- fuer abweichende Einheiten -->
<ng-container *ngIf="checkIfOtherUnitIsRelevant()">
  <div class="table-responsive">
    <table class="table table-sm table-striped table-hover table-condensed text-white">
      <thead [ngClass]="myApplication.theme.background">
        <tr class="d-flex">
          <td scope="col" class="col-1">
            <ng-container *ngIf="actionCreate">
            <button class="btn btn-sm ml-1" type="button" data-toggle="dropdown" [ngClass]="myApplication.theme.btn"
              [ngbTooltip]="myApplication.getTextSimple('Seed.create')"
              container="body" data-boundary="window">
              <fa name="plus"></fa>
            </button>
            <div class="dropdown-menu scrollable-menu">
              <a *ngFor="let sortSeed of sort.sortSeedSet.sortSeedsOtherUnit" class="dropdown-item"
                (click)="createOtherUnit(sortSeed)">{{ sortSeed.manufacturingYear + ' (' + sortSeed.manufacturer + ')'
                }}</a>
              <div role="separator" class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="createNew(true)">{{ myApplication.getText('Saatgutdaten.create') }}</a>
            </div>
            </ng-container>
          </td>
          <td class="col-1 my_right_aligned">
            {{ myApplication.getTextSimple('Seed.manufacturingYear') }}
          </td>
          <td class="my_right_aligned col-2">{{ myApplication.getTextSimple('Seed.manufacturer') }}</td>
          <td class="my_right_aligned col-2">{{ myApplication.getTextSimple('Seed.seedValue') }}</td>
          <td class="my_right_aligned col-2">{{ myApplication.getTextSimple('Seed.quantity') }}</td>

          <td class="col-1"> {{ myApplication.getTextSimple('Seed.done') }}</td>
          <td class="col-1"></td>
        </tr>
      </thead>
      <tbody>
        <tr class="d-flex" *ngFor="let seed of seedSet.seedsFilteredOtherUnit">
          <ng-container *ngIf="!seed.newWithNewSortSeed">
            <td class="col-1"></td>
            <td class="my_right_aligned col-1">
              <app-text-field [id]="'SortSeed.manufacturingYear'" [object]="seed.getSortSeed()"
                [valueName]="'manufacturingYear'" [disable]="true">
              </app-text-field>
            </td>
            <td class="my_right_aligned col-2">
              <app-text-field [id]="'SortSeed.manufacturer'" [object]="seed.getSortSeed()" [valueName]="'manufacturer'"
                [disable]="true">
              </app-text-field>
            </td>
          </ng-container>

          <ng-container *ngIf="seed.newWithNewSortSeed">
            <td class="col-1"></td>
            <td class="my_right_aligned col-1">
              <app-select-field [id]="'SortSeed.manufacturingYear'" [object]="seed.getSortSeed()"
                [valueName]="'manufacturingYear'" [keyValueList]="years" [disable]="!actionEdit">
              </app-select-field>
            </td>
            <td class="my_right_aligned col-2">
              <app-text-field [id]="'SortSeed.manufacturer'" [object]="seed.getSortSeed()" [valueName]="'manufacturer'" [disable]="!actionEdit">
              </app-text-field>
            </td>
          </ng-container>
          <td class="my_right_aligned col-2">
            <app-number-unit-select-field [id]="'SortSeed.seedValue'" [object]="seed.getSortSeed()"
              [valueName]="'seedValue'" [unitValueName]="'seedUnit'" [keyValueList]="units" [disable]="!actionEdit">
            </app-number-unit-select-field>
          </td>

          <td class="my_right_aligned col-2">
            <app-number-unit-select-field [id]="'Seed.quantityNoText'" [object]="seed" [valueName]="'quantity'"
              [unitValueName]="'quantityUnit'" [keyValueList]="seedUnits" [disable]="!actionEdit"></app-number-unit-select-field>
          </td>
          <td class="my_right_aligned col-1">
            <app-check-field [id]="'Seed.doneField'" [object]="seed" [valueName]="'done'" [disable]="!actionEdit"></app-check-field>
          </td>

          <td class="col-1">
            <app-button *ngIf="actionDelete" [iconName]="'trash-alt'" [id]="'Seed.delete'" [callback]="'delete'" [callbackOwner]="this"
              [object]="seed" [silent]="true">
            </app-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>