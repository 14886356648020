import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';

export class RepositoryEO extends AbstractEntityObject{

    public name: String;
    public package: String;
    public partLimit: number;
    public system: String;
    public bedWidth: number;
    public bedLength: number;
    public firstName: String;
    public lastName: String;
    public agbAndDp: String;
    public createdAndAgreedTS: String;
    public infoText: String;
    
    
    constructor() {
        super();
        this.name = '';
        this.package = '';
        this.partLimit = 0;
        this.system = '1';
        this.bedWidth = 0.75;
        this.bedLength = 30;
        this.firstName = '';
        this.lastName = '';
        this.agbAndDp = '';
        this.createdAndAgreedTS = '';
        this.infoText = null;
    }
  }