import { Component, OnInit, Input } from '@angular/core';
import { Culture } from 'src/app/app-core/business-logic/culture';
import { ApplicationService } from 'src/app/services/application.service';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-culture-seed',
  templateUrl: './culture-seed.component.html',
  styleUrls: ['./culture-seed.component.css']
})
export class CultureSeedComponent implements OnInit {

  @Input() culture: Culture;
  public actionCreateSorts: boolean = false;

  constructor(public myApplication: ApplicationService, public permissionsService: PermissionsService) { 
    this.checkAllowedActions();
  }

  ngOnInit() {
  }

  public createSort(culture: Culture) {
    culture.createSort();
  }

  headerClicked() {
    this.myApplication.save();
  }

  checkAllowedActions() {
    this.actionCreateSorts = this.permissionsService.checkPermissionForAction('sorts', 'create', null);
  }

}
