import { Listener } from '../technical-aspects/listener';
import { ToDo } from './todo';
import { Constants } from './constants';
import { Observable } from '../technical-aspects/observable';


export class FilteredToDoList extends Observable implements Listener {

    protected completeObjects: Array<ToDo>;
    public objects: Array<ToDo>;
    private _cultureDates = true;
    private _personId = '';
    private _types = new Array<String>();
    public checkAllValue = true;
    public isFiltered = false;


    constructor(objects: Array<ToDo>) {
        super();
        this.completeObjects = objects;
        this.update();
    }

    replaceObjects(objects: Array<ToDo>) {
        this.completeObjects = objects;
        this.update();
    }

    public get types() {
        return this._types;
    }
    public set types(value) {
        this._types = value;
    }
    public addType(type: String) {
        if (!this._types.includes(type)) {
            this._types.push(type);
            this.update();
        }
    }
    public removeType(type: String) {
        let tmp = new Array<String>();
        this._types.forEach(element => {
            if (element != type) {
                tmp.push(element);
            }
        });
        this._types = tmp;
        this.update();
    }

    update() {
        this.objects = this.completeObjects;
        this.objects = this.filterByDates(this.objects);
        this.objects = this.filterByPersonId(this.objects);
        this.objects = this.filterByTypes(this.objects);

        if (this.objects.length != this.completeObjects.length) {
            this.isFiltered = true;
        } else {
            this.isFiltered = false;
        }

        this.hasChanged();
    }

    protected filterByDates(objects: Array<ToDo>): Array<ToDo> {
        let set = new Array<ToDo>();
        if (!this._cultureDates) {
            objects.forEach(toDo => {
                if (toDo.toDoType != Constants.TODO_RECORD_DATES) {
                    set.push(toDo);
                }
            });
        } else {
            objects.forEach(toDo => {
                set.push(toDo);
            });
        }
        return set;
    }

    protected filterByPersonId(objects: Array<ToDo>): Array<ToDo> {
        let set = new Array<ToDo>();
        if (this._personId != Constants.NOT_RELEVANT) {
            objects.forEach(toDo => {
                if (toDo.person == this._personId) {
                    set.push(toDo);
                }
            });
        } else {
            objects.forEach(toDo => {
                set.push(toDo);
            });
        }
        return set;
    }

    protected filterByTypes(objects: Array<ToDo>): Array<ToDo> {
        let set = new Array<ToDo>();
        if (this._types.length > 0) {
            objects.forEach(toDo => {
                if (this._types.includes(toDo.type)) {
                    set.push(toDo);
                }
            });
            return set;
        } else {
            return objects;
        }
    }

    public get personId() {
        return this._personId;
    }
    public set personId(value) {
        if (value != this._personId) {
            this._personId = value;
            this.update();
        }
    }

    public get cultureDates() {
        return this._cultureDates;
    }
    public set cultureDates(value) {
        if (value != this._cultureDates) {
            this._cultureDates = value;
            this.update();
        }
    }

    reset() {
        this._cultureDates = true;
        this._personId = '';
        this._types = new Array<String>();
        this.checkAllValue = true;
        this.update();
    }
}