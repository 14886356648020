import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';
import { Constants } from '../business-logic/constants';

export class RecordEO extends AbstractEntityObject{

    public cultureId: String;
    public areaId: String;
    public seedId: String;
    public quickId: number;
    public recordNum: number;
    public state: String;
    public quantity: number;
    public plantDistance: number;
    public rowDistance: number;
    public plantDeepth: number;
    public numRows: number;
    public quickpotSize: number;
    public seedsPerQuickpot: number;
    public lengthOnBed: number;
    public harvestMethod: String;
    public harvestUnit: String;
    public note: String;
    public sort: String;
    public cultureType: String;
    public seedDate: Date;
    public growDate: Date;
    public harvestDate: Date;
    public harvestLength: number;
    public harvestQuantity: number;
    public harvestQuantityUnit: String;
    public numOfDeliveryWeeks: number;
    public averageQuantity: number;
    public neededHarvestQuantity: number;
    public safety: number;
    public alternativeDelivery: boolean;
    public tags: String;
    public plantsPerBox: number;

    public seedTS: number;
    public growTS: number;
    public harvestTS: number;
    public cultureEndTS: number;
    public cultureName: string;
    public seedRate: number;

    public extraQuantity: number;
    public useExtraQuantity: boolean;
    public fixUserInput: String;
    public totalHarvestQuantity: number;


    constructor() {
        super();
        this.cultureId = '';
        this.areaId = ''; 
        this.seedId = '';
        this.quickId = 0;
        this.recordNum = 0;
        this.state = 'P';
        this.quantity = 0;
        this.plantDistance = 0;
        this.rowDistance = 0;
        this.plantDeepth = 0;
        this.numRows = 0;
        this.quickpotSize = 0;
        this.seedsPerQuickpot = 0;
        this.lengthOnBed = 0;
        this.harvestMethod = '';
        this.harvestUnit = '';
        this.note = '';
        this.sort = '';
        this.cultureType = Constants.CULTURE_TYPE_DIREKTSAAT;

        this.seedDate = new Date();
        this.growDate = new Date();
        this.harvestDate = new Date();
        this.harvestLength = 0;
        this.harvestQuantity = 0;
        this.numOfDeliveryWeeks = 0;
        this.averageQuantity = 0;
        this.neededHarvestQuantity = 0;
        this.safety = 0;
        this.alternativeDelivery = false;

        this.tags = '';
        this.plantsPerBox = 0;

        this.seedTS = 0;
        this.growTS = 0;
        this.harvestTS = 0;
        this.cultureEndTS = 0;
        this.cultureName = '';
        this.seedRate = 100;

        this.extraQuantity = 0;
        this.useExtraQuantity = false;
        this.fixUserInput = '';
        this.totalHarvestQuantity = 0;
    }
  }