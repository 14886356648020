import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ApplicationService } from 'src/app/services/application.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-wizard-start',
  templateUrl: './wizard-start.component.html',
  styleUrls: ['./wizard-start.component.css']
})
export class WizardStartComponent implements OnInit {

  public url: SafeResourceUrl;

  innerHeight = 1100;


  constructor(public sanitizer: DomSanitizer, public myApplication: ApplicationService) {

    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.wizardModule);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerHeight = window.innerHeight - 60;
  }
  
  ngOnInit() {
    this.onResize(null);
  }
}