import * as tslib_1 from "tslib";
import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';
import { Constants } from '../business-logic/constants';
var CultureEO = /** @class */ (function (_super) {
    tslib_1.__extends(CultureEO, _super);
    function CultureEO() {
        var _this = _super.call(this) || this;
        _this.name = '';
        _this.consumerType = Constants.CONSUMER_TYPE_STARKZEHRER;
        _this.cultureType = Constants.CULTURE_TYPE_DIREKTSAAT;
        _this.harvestMethod = Constants.HARVEST_METHOD_ABSOLUT;
        _this.harvestUnit = Constants.UNIT_PIECE;
        _this.plantDistance = 0;
        _this.rowDistance = 0;
        _this.plantDeepth = 0;
        _this.numRows = 0;
        _this.quickpotSize = 0;
        _this.quickpotName = '';
        _this.quickpotId = '';
        _this.seedsPerQuickpot = 1;
        _this.harvestQuantity = 0;
        _this.neededHarvestQuantity = 0;
        _this.safety = 0;
        _this.color = '';
        _this.note = '';
        _this.seed = 1;
        _this.grow = 1;
        _this.harvest = 1;
        _this.numOfDeliveryWeeks = 0;
        _this.averageQuantity = 0;
        _this.tags = '';
        _this.alternativeDeliverySelected = false;
        _this.plantsPerBox = 0;
        _this.seedRate = 100;
        return _this;
    }
    return CultureEO;
}(AbstractEntityObject));
export { CultureEO };
