import { Injectable } from '@angular/core';
import { Http, RequestOptions, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApplicationService } from './application.service';

@Injectable({
  providedIn: 'root'
})
export class BugtrackingService {

  constructor(private http: Http, private myApplication: ApplicationService) { }

  createBug(ticketDTO) {
    return new Promise<void>((resolve, reject) => {
      const options = new RequestOptions();
      options.headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'BDpAIIsdRtyLJvMzgytzzcYcj81iKSrr',
        'Access-Control-Allow-Headers': 'authorization, content-type',
        'Access-Control-Allow-Methods': 'POST,GET,OPTIONS,DELETE'
      });
      this.http.post(environment.bucktracking + 'issues/', ticketDTO.toJson(), options).pipe(
        map(resp => resp.json())
      )
        .subscribe(res => {
          this.myApplication.messageDTO.setSuccess('Bug ' + res.issue.id + ' wurde erstellt.');
          resolve();
        });
    });
  }
}
