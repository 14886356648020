/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../confirm/confirm.component.ngfactory";
import * as i3 from "../confirm/confirm.component";
import * as i4 from "../../../services/application.service";
import * as i5 from "../../../../../node_modules/angular-font-awesome/dist/angular-font-awesome.ngfactory";
import * as i6 from "angular-font-awesome";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "@angular/common";
import * as i9 from "./button.component";
var styles_ButtonComponent = [i0.styles];
var RenderType_ButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonComponent, data: {} });
export { RenderType_ButtonComponent as RenderType_ButtonComponent };
function View_ButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirm", [], null, null, null, i2.View_ConfirmComponent_0, i2.RenderType_ConfirmComponent)), i1.ɵdid(1, 114688, null, 0, i3.ConfirmComponent, [i4.ApplicationService], { callbackOwner: [0, "callbackOwner"], callback: [1, "callback"], object: [2, "object"], question: [3, "question"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.callbackOwner; var currVal_1 = _co.callback; var currVal_2 = _co.object; var currVal_3 = _co.confirmQuestion; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_ButtonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 2, "fa", [["data-placement", "bottom"]], null, null, null, i5.View_AngularFontAwesomeComponent_0, i5.RenderType_AngularFontAwesomeComponent)), i1.ɵdid(1, 114688, null, 0, i6.AngularFontAwesomeComponent, [], { name: [0, "name"] }, null), i1.ɵdid(2, 212992, null, 0, i7.NgbTooltip, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i7.NgbTooltipConfig, i1.NgZone, i8.DOCUMENT, i1.ChangeDetectorRef], { placement: [0, "placement"], ngbTooltip: [1, "ngbTooltip"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconName; _ck(_v, 1, 0, currVal_0); var currVal_1 = "bottom"; var currVal_2 = _co.fieldDTO.help; _ck(_v, 2, 0, currVal_1, currVal_2); }, null); }
function View_ButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 5, "button", [["container", "body"], ["type", "button"]], [[8, "id", 0], [8, "disabled", 0], [1, "data-dismiss", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stopEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i8.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(3, 737280, [["popover", 4]], 0, i7.NgbPopover, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i7.NgbPopoverConfig, i1.NgZone, i8.DOCUMENT, i1.ChangeDetectorRef], { autoClose: [0, "autoClose"], ngbPopover: [1, "ngbPopover"], popoverTitle: [2, "popoverTitle"], container: [3, "container"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonComponent_3)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵinlineInterpolate(1, "btn btn-sm mr-1 ", _co.silentClass, ""); var currVal_4 = _co.btnClass; _ck(_v, 2, 0, currVal_3, currVal_4); var currVal_5 = true; var currVal_6 = i1.ɵnov(_v.parent, 0); var currVal_7 = _co.popupTitle; var currVal_8 = "body"; _ck(_v, 3, 0, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = _co.iconName; _ck(_v, 5, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.id; var currVal_1 = _co.disable; var currVal_2 = _co.dataDismiss; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_10 = _co.fieldDTO.text; _ck(_v, 6, 0, currVal_10); }); }
function View_ButtonComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fa", [], null, null, null, i5.View_AngularFontAwesomeComponent_0, i5.RenderType_AngularFontAwesomeComponent)), i1.ɵdid(1, 114688, null, 0, i6.AngularFontAwesomeComponent, [], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconName; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ButtonComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 5, "button", [["container", "body"], ["data-placement", "bottom"], ["type", "button"]], [[8, "id", 0], [8, "disabled", 0], [1, "data-dismiss", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i8.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(3, 212992, null, 0, i7.NgbTooltip, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i7.NgbTooltipConfig, i1.NgZone, i8.DOCUMENT, i1.ChangeDetectorRef], { placement: [0, "placement"], container: [1, "container"], ngbTooltip: [2, "ngbTooltip"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonComponent_5)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵinlineInterpolate(1, "btn btn-sm mr-1 ", _co.silentClass, ""); var currVal_4 = _co.btnClass; _ck(_v, 2, 0, currVal_3, currVal_4); var currVal_5 = "bottom"; var currVal_6 = "body"; var currVal_7 = _co.fieldDTO.help; _ck(_v, 3, 0, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.iconName; _ck(_v, 5, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.id; var currVal_1 = _co.disable; var currVal_2 = _co.dataDismiss; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_9 = _co.fieldDTO.text; _ck(_v, 6, 0, currVal_9); }); }
export function View_ButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, [["deleteConfirm", 2]], null, 0, null, View_ButtonComponent_1)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonComponent_2)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonComponent_4)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.iconName == "trash-alt"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.iconName != "trash-alt"); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-button", [], null, null, null, View_ButtonComponent_0, RenderType_ButtonComponent)), i1.ɵdid(1, 114688, null, 0, i9.ButtonComponent, [i4.ApplicationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ButtonComponentNgFactory = i1.ɵccf("app-button", i9.ButtonComponent, View_ButtonComponent_Host_0, { iconName: "iconName", id: "id", callback: "callback", callbackOwner: "callbackOwner", object: "object", silent: "silent", dataDismiss: "dataDismiss", stopEventing: "stopEventing", btnClass: "btnClass", popupTitle: "popupTitle", confirmQuestion: "confirmQuestion", disable: "disable" }, {}, []);
export { ButtonComponentNgFactory as ButtonComponentNgFactory };
