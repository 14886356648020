import { Component, HostListener, OnInit } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { ApplicationService } from 'src/app/services/application.service';
import { environment } from 'src/environments/environment';
import { EventHandler } from 'src/app/app-core/technical-aspects/event-handler';
import { PartsService } from 'src/app/services/parts.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-consumer',
  templateUrl: './consumer.component.html',
  styleUrls: ['./consumer.component.css']
})
export class ConsumerComponent implements OnInit {

  public url: SafeResourceUrl;

  innerHeight = 1100;

  
  constructor(public sanitizer: DomSanitizer, private myApplication: ApplicationService, public partsService: PartsService,
    private breadcrumbSrv: BreadcrumbService) {

    this.breadcrumbSrv.breadcrumb = this.myApplication.getText('Consumers').toString();
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.consumerModule + '/#/home/' + this.myApplication.year.toString());
    this.myApplication.eventHandler.register(EventHandler.LANGUAGE_CHANGED, this);
    this.myApplication.addObserver(this);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerHeight = window.innerHeight - 60;
  }
  
  ngOnInit() {
    this.onResize(null);
  }

  ngOnDestroy(): void {
    // this.partsService.readParts();
    this.myApplication.eventHandler.deRegister(EventHandler.LANGUAGE_CHANGED, this);
  }

  update() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.consumerModule + '/#/home/' + this.myApplication.year.toString());

    let iframe = document.getElementById('consumer');
    if (iframe == null) {
      return;
    }
    let iWindow = (<HTMLIFrameElement>iframe).contentWindow;
  }

  handleLanguageChanged() {
    this.breadcrumbSrv.breadcrumb = this.myApplication.getText('Consumers').toString();
    
    let iframe = (<HTMLIFrameElement>document.getElementById("consumer"));
    if (iframe) {
      iframe.contentWindow.postMessage({ "message": "languageChanged" }, '*');
    }
  }

}
