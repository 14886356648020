import * as tslib_1 from "tslib";
import { AbstractObject } from '../technical-aspects/abstract-object';
import { RecordEO } from '../entity-objects/record-eo';
import { PeriodsUIList } from './periods-ui-list';
import { Constants } from './constants';
import { RecordPeriods } from './record-periods';
import { BedAssignList } from './bed-assign-list';
import { ToDoListRecord } from './todo-list-record';
import { CalculatorQuantity } from './calculator-quantity';
import { CalculatorLengthOnBed } from './calculator-length-on-bed';
import { CalculatorQuantityWithBedM } from './calculator-quantity-with-bedm';
import { EventHandler } from '../technical-aspects/event-handler';
import { TagBuilder } from './helper/tag-builder';
import { FilteredParts } from './filteredParts';
var Record = /** @class */ (function (_super) {
    tslib_1.__extends(Record, _super);
    function Record() {
        var _this = _super.call(this) || this;
        _this._selected = false;
        _this.calculated = false;
        _this.bedMWarning = false;
        _this.checked = false;
        return _this;
    }
    Record.prototype.readReady = function () {
        this.myApplication.eventHandler.register(EventHandler.EVENT_RECORD_DATES_CHANGED, this);
    };
    Record.prototype.distancesDifferFromCulture = function () {
        if (this.plantDistance == this._culture.plantDistance &&
            this.rowDistance == this._culture.rowDistance &&
            this.numRows == this._culture.numRows) {
            return false;
        }
        else {
            return true;
        }
    };
    Record.prototype.detailsDifferFromCulture = function () {
        if (this.seedRate == this._culture.seedRate) {
            return false;
        }
        else {
            return true;
        }
    };
    Record.prototype.hasCultureInfoWarnings = function () {
        if (this.recordPeriods.harvestLength < 1 ||
            this.plantDistance <= 0 ||
            this.hasDistanceWarnings() ||
            (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.hasQuickpotWarnings()) ||
            this.hasPlantBoxesWarnings()) {
            return true;
        }
        else {
            return false;
        }
    };
    Record.prototype.hasQuickpotWarnings = function () {
        if (this.quickpotSize <= 0 || this.seedsPerQuickpot <= 0) {
            return true;
        }
        else {
            return false;
        }
    };
    Record.prototype.hasDistanceWarnings = function () {
        if (this.rowDistance <= 0 ||
            this.numRows <= 0) {
            return true;
        }
        else {
            return false;
        }
    };
    Record.prototype.hasAnbauErnteWarnings = function () {
        if ((this.recordPeriods.harvestQuantity <= 0 && this.harvestMethod != Constants.HARVEST_METHOD_ABSOLUT) ||
            this.recordPeriods.neededHarvestQuantity <= 0 ||
            (this.recordPeriods.numOfDeliveryWeeks <= 0 &&
                this.recordPeriods.alternativeDelivery)) {
            return true;
        }
        else {
            return false;
        }
    };
    Record.prototype.hasWarnings = function () {
        if (this.hasCultureInfoWarnings() ||
            this.hasAnbauErnteWarnings()) {
            return true;
        }
        else {
            return false;
        }
    };
    Record.prototype.getWarnings = function () {
        var warnings = new Array();
        warnings = warnings.concat(this.getAnbauErnteWarnings(), this.getCultureInfoWarnings());
        return warnings;
    };
    Record.prototype.getAnbauErnteWarnings = function () {
        var warnings = new Array();
        if (this.recordPeriods.harvestQuantity <= 0 && this.harvestMethod != Constants.HARVEST_METHOD_ABSOLUT) {
            warnings.push(this.myApplication.getText('FM.harvestQuantityIsZero'));
        }
        if (this.recordPeriods.neededHarvestQuantity <= 0) {
            warnings.push(this.myApplication.getText('FM.neededHarvestQuantityIsZero'));
        }
        if (this.recordPeriods.numOfDeliveryWeeks <= 0 &&
            this.recordPeriods.alternativeDelivery) {
            warnings.push(this.myApplication.getText('FM.numDeliveryWeeksIsZero'));
        }
        return warnings;
    };
    Record.prototype.getCultureInfoWarnings = function () {
        var warnings = new Array();
        if (this.recordPeriods.harvestLength < 1) {
            warnings.push(this.myApplication.getText('FM.numHarvestWeeksIsZero'));
        }
        if (this.plantDistance <= 0) {
            warnings.push(this.myApplication.getText('FM.plantDistanceIsZero'));
        }
        if (this.rowDistance <= 0) {
            warnings.push(this.myApplication.getText('FM.rowDistanceIsZero'));
        }
        if (this.numRows <= 0) {
            warnings.push(this.myApplication.getText('FM.numRowsIsZero'));
        }
        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.hasQuickpotWarnings()) {
            warnings.push(this.myApplication.getText('FM.QuickPotInfosMissing'));
        }
        if (this.hasPlantBoxesWarnings()) {
            warnings.push(this.myApplication.getText('FM.BoxInfoMissing'));
        }
        return warnings;
    };
    Record.prototype.quantityFits = function () {
        if (!this.calculated) {
            this.quantityCalculated1 = this.calculateQuantity();
            this.bedMCalculated = this.calculateBedM();
            this.calculated = true;
        }
        if (this.bedMCalculated != this.lengthOnBed || this.bedMCalculated == 0) {
            this.bedMWarning = true;
        }
        else {
            this.bedMWarning = false;
        }
        if (this.quantity == this.quantityCalculated1 && this.quantity > 0) {
            return true;
        }
        else {
            return false;
        }
    };
    Record.prototype.addTags = function (tags) {
        if (this.recordEO.tags != '') {
            this.recordEO.tags = this.recordEO.tags.toString() + ' ' + tags.toString();
        }
        else {
            this.recordEO.tags = tags.toString();
        }
        this._tagList = new TagBuilder().buildTags(this.recordEO.tags);
    };
    Object.defineProperty(Record.prototype, "tagList", {
        get: function () {
            if (!this._tagList) {
                this._tagList = new TagBuilder().buildTags(this.recordEO.tags);
            }
            return this._tagList;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "tags", {
        get: function () {
            return this.recordEO.tags;
        },
        set: function (value) {
            this.recordEO.tags = '';
            this.addTags(value.toString());
        },
        enumerable: true,
        configurable: true
    });
    Record.prototype.includesTag = function (tag) {
        if (this.tags.includes(tag) || this.culture.tags.includes(tag)) {
            return true;
        }
        else {
            return false;
        }
    };
    Record.prototype.getNumQuickpots = function () {
        var numQuickpots = 0;
        if (this.quickpotSize > 0) {
            numQuickpots = Math.ceil(this.quantity / this.quickpotSize);
        }
        return numQuickpots;
    };
    Record.prototype.getNumBoxes = function () {
        var num = 0;
        if (this.plantsPerBox > 0) {
            num = Math.ceil(this.quantity / this.plantsPerBox);
        }
        return num;
    };
    Record.prototype.calculateQuantities = function (messages) {
        if (this.fixUserInput == Constants.FIX_USER_INPUT_PLANTS) {
            // Pfanzen wurden manuell eingegeben nur Beetmeter neu berechnen
            this.calculateBedMToRecord();
            this.generateInfoTextFixPlants();
        }
        else if (this.fixUserInput == Constants.FIX_USER_INPUT_BEDMETERS) {
            // Beetmeter wurden manuell eingegeben nur Planzen neu berechnen
            this.calculateQuantityWithBedM();
            this.generateInfoTextFixBedMeters();
        }
        else {
            // Pfanzen und Beetmeter neu berechnen
            this.calculateQuantityToRecord(messages);
            this.calculateBedMToRecord();
            this.generateInfoText();
        }
    };
    Record.prototype.updateFromSort = function () {
        var sort = this.getSort();
        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
            this.recordPeriods.setGrowDateBySeedLength(sort.seed);
        }
        this.recordPeriods.setHarvestDateByGrowLength(sort.grow);
        this.recordPeriods.harvestLength = sort.harvest;
        this.myApplication.messageDTO.setSuccessWithId('CultureTimesUpdated');
    };
    Record.prototype.updateFromSort2 = function () {
        var sort = this.getSort();
        this.plantDistance = sort.plantDistance;
        this.rowDistance = sort.rowDistance;
        this.numRows = sort.numRows;
        this.myApplication.messageDTO.setSuccessWithId('CultureTimesUpdated');
    };
    Record.prototype.updateFromSort4 = function () {
        var sort = this.getSort();
        this.seedRate = sort.seedRate;
        this.myApplication.messageDTO.setSuccessWithId('CultureTimesUpdated');
    };
    Record.prototype.updateFromSort3 = function () {
        this.updateFromSort();
        this.updateFromSort2();
    };
    Record.prototype.updateFromSort5 = function () {
        this.updateFromSort();
        this.updateFromSort4();
    };
    Record.prototype.updateFromSort6 = function () {
        this.updateFromSort2();
        this.updateFromSort4();
    };
    Record.prototype.updateFromSort7 = function () {
        this.updateFromSort();
        this.updateFromSort2();
        this.updateFromSort4();
    };
    Record.prototype.updateFromCulture = function () {
        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
            this.recordPeriods.setGrowDateBySeedLength(this.culture.seed);
        }
        this.recordPeriods.setHarvestDateByGrowLength(this.culture.grow);
        this.recordPeriods.harvestLength = this.culture.harvest;
        this.myApplication.messageDTO.setSuccessWithId('CultureTimesUpdated');
    };
    Record.prototype.updateFromCulture2 = function () {
        this.plantDistance = this.culture.plantDistance;
        this.rowDistance = this.culture.rowDistance;
        this.numRows = this.culture.numRows;
    };
    Record.prototype.updateFromCulture3 = function () {
        this.updateFromCulture();
        this.updateFromCulture2();
    };
    Record.prototype.getSeedPeriodText = function () {
        var seedPeriod;
        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
            seedPeriod = this.myApplication.getText('Culture.seed') + ' ' + this.recordPeriods.seedWeek.week + '. - ' +
                this.recordPeriods.lastSeedWeek.week + '. ' + this.myApplication.getText('CalendarWeek');
        }
        return seedPeriod;
    };
    Record.prototype.getGrowPeriodText = function () {
        var growPeriod;
        growPeriod = this.myApplication.getText('Culture.grow') + ' ' + this.recordPeriods.growWeek.week + '. - ' + this.recordPeriods.lastGrowWeek.week +
            '. ' + this.myApplication.getText('CalendarWeek');
        return growPeriod;
    };
    Record.prototype.getHarvestPeriodText = function () {
        var harvestPeriod;
        harvestPeriod = this.myApplication.getText('Harvest') + ' ' + this.recordPeriods.harvestWeek.week + '. - ' + this.recordPeriods.lastHarvestWeek.week +
            '. ' + this.myApplication.getText('CalendarWeek');
        return harvestPeriod;
    };
    Record.prototype.distancesFilled = function () {
        return (this.plantDistance > 0 && this.numRows > 0);
    };
    Record.prototype.anzuchtInfosComplete = function () {
        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
            return (this.quickpotSize > 0 && this.seedsPerQuickpot > 0);
        }
        else {
            return true;
        }
    };
    Record.prototype.lengthOnBedCorrect = function () {
        if (this.lengthOnBed == this.bedAssignList.getLengthPlanned()) {
            return true;
        }
        else if (this.bedAssignList.getLengthPlanned() != 0) {
            return false;
        }
        else {
            return true;
        }
    };
    Object.defineProperty(Record.prototype, "todoList", {
        get: function () {
            if (!this._todoList) {
                this._todoList = new ToDoListRecord(this.daoToDos, this);
                this._todoList.toDos = this._todoList.toDos.sort(function (one, two) {
                    var compare = 0;
                    if (one.dueDate > two.dueDate) {
                        compare = 1;
                    }
                    else if (one.dueDate < two.dueDate) {
                        compare = -1;
                    }
                    else {
                        compare = 0;
                    }
                    return compare;
                });
            }
            return this._todoList;
        },
        set: function (value) {
            this._todoList = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "bedAssignList", {
        get: function () {
            if (!this._bedAssignList) {
                this._bedAssignList = new BedAssignList(this.id, this, this.daoBedAssigns, this.myApplication);
            }
            return this._bedAssignList;
        },
        set: function (value) {
            this._bedAssignList = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "recordPeriods", {
        get: function () {
            if (!this._recordPeriods) {
                this._recordPeriods = new RecordPeriods(this.eo, this.basicService, this.culture, this.myApplication, this);
                // this._recordPeriods.addObserver(this);
            }
            return this._recordPeriods;
        },
        set: function (value) {
            this._recordPeriods = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "periodsUIAreaBed", {
        get: function () {
            if (!this._periodsUIAreaBed) {
                this._periodsUIAreaBed = this.buildPeriodsUIAreaBed();
            }
            return this._periodsUIAreaBed;
        },
        set: function (value) {
            this._periodsUIAreaBed = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "periodsUIBedMeters", {
        get: function () {
            if (!this._periodsUIBedMeters) {
                this._periodsUIBedMeters = this.buildPeriodsUIBedMeters();
            }
            return this._periodsUIBedMeters;
        },
        set: function (value) {
            this._periodsUIBedMeters = value;
        },
        enumerable: true,
        configurable: true
    });
    Record.prototype.shift = function (numWeeks) {
        this.recordPeriods.shift(numWeeks);
    };
    Record.prototype.buildPeriodsUIAreaBed = function () {
        var list = new PeriodsUIList(this.myApplication, this.basicService, this);
        list.createForAreaBed(this.recordPeriods);
        return list;
    };
    Record.prototype.buildPeriodsUIBedMeters = function () {
        var list = new PeriodsUIList(this.myApplication, this.basicService, this);
        list.createForAreaBed(this.recordPeriods);
        return list;
    };
    Object.defineProperty(Record.prototype, "periodsUI", {
        get: function () {
            if (!this._periodsUI) {
                this.buildPeriodsUI();
            }
            return this._periodsUI;
        },
        set: function (value) {
            this._periodsUI = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "culture", {
        get: function () {
            if (this._culture == null && this.daoCultures) {
                this._culture = this.daoCultures.getObjectById(this.cultureId);
                if (this._culture) {
                    this.recordEO.cultureName = this._culture.name.toString();
                }
            }
            return this._culture;
        },
        set: function (value) {
            this._culture = value;
            this.cultureId = value.id;
        },
        enumerable: true,
        configurable: true
    });
    Record.prototype.update = function () {
        this.myApplication.addObserver(this);
        this.myApplication.eventHandler.register(EventHandler.APP_YEAR_CHANGED, this);
        this.myApplication.eventHandler.register(EventHandler.EVENT_RECORD_DATES_CHANGED, this);
        this.daoToDos.addObserver(this);
        this.periodsUIAreaBed = null;
        this.periodsUIBedMeters = null;
        this.todoList = null;
        this.bedAssignList;
    };
    Record.prototype.handleRecordDatesChanged = function (id) {
        if (this.id == id) {
            this.periodsUIAreaBed = null;
            this.periodsUIBedMeters = null;
            this.periodsUI = null;
        }
    };
    Record.prototype.handleAppYearChanged = function () {
        this.periodsUI = null;
    };
    Record.prototype.startsInPeriod = function (startWeek, lastWeek) {
        return this.basicService.isInPeriod(startWeek, lastWeek, this.recordPeriods.getStartWeek());
    };
    Record.prototype.getYear = function () {
        return this.recordPeriods.getStartWeek().year;
    };
    Object.defineProperty(Record.prototype, "recordEO", {
        get: function () {
            return this.eo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "cultureId", {
        get: function () {
            return this.recordEO.cultureId;
        },
        set: function (value) {
            this.recordEO.cultureId = value;
            this._culture = null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "sort", {
        get: function () {
            return this.recordEO.sort;
        },
        set: function (value) {
            this.recordEO.sort = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "areaId", {
        get: function () {
            return this.recordEO.areaId;
        },
        set: function (value) {
            this.recordEO.areaId = value;
        },
        enumerable: true,
        configurable: true
    });
    Record.prototype.getArea = function () {
        return this.daoAreas.getObjectById(this.areaId);
    };
    Object.defineProperty(Record.prototype, "seedId", {
        get: function () {
            return this.recordEO.seedId;
        },
        set: function (value) {
            this.recordEO.seedId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "quickId", {
        get: function () {
            return this.recordEO.quickId;
        },
        set: function (value) {
            this.recordEO.quickId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "recordNum", {
        get: function () {
            return this.recordEO.recordNum;
        },
        set: function (value) {
            this.recordEO.recordNum = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "state", {
        get: function () {
            return this.recordEO.state;
        },
        set: function (value) {
            this.recordEO.state = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "quantity", {
        get: function () {
            return this.recordEO.quantity;
        },
        set: function (value) {
            if (this.recordEO.quantity != value) {
                this.recordEO.quantity = value;
                this.calculated = false;
                // this.calculateBedMToRecord();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "plantDistance", {
        get: function () {
            return this.recordEO.plantDistance;
        },
        set: function (value) {
            this.recordEO.plantDistance = value;
            this.calculateQuantities();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "rowDistance", {
        get: function () {
            return this.recordEO.rowDistance;
        },
        set: function (value) {
            if (this.recordEO.rowDistance != value) {
                this.recordEO.rowDistance = value;
                this.calculateQuantities();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "plantDeepth", {
        get: function () {
            return this.recordEO.plantDeepth;
        },
        set: function (value) {
            this.recordEO.plantDeepth = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "numRows", {
        get: function () {
            return this.recordEO.numRows;
        },
        set: function (value) {
            if (this.recordEO.numRows != value) {
                this.recordEO.numRows = value;
                this.calculateQuantities();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "quickpotSize", {
        get: function () {
            return this.recordEO.quickpotSize;
        },
        set: function (value) {
            this.recordEO.quickpotSize = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "seedsPerQuickpot", {
        get: function () {
            return this.recordEO.seedsPerQuickpot;
        },
        set: function (value) {
            this.recordEO.seedsPerQuickpot = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "lengthOnBed", {
        get: function () {
            return this.recordEO.lengthOnBed;
        },
        set: function (value) {
            if (value != this.recordEO.lengthOnBed) {
                this.recordEO.lengthOnBed = value;
                this.periodsUIAreaBed = null;
                this.calculated = false;
                if (this.recordEO.lengthOnBed < this.bedAssignList.getLengthPlanned()) {
                    this.myApplication.messageDTO.setErrorWithId('FM.PlannedBedMSmaller');
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "harvestMethod", {
        get: function () {
            return this.recordEO.harvestMethod;
        },
        set: function (value) {
            if (this.recordEO.harvestMethod != value) {
                this.recordEO.harvestMethod = value;
                this.calculateQuantities();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "note", {
        get: function () {
            return this.recordEO.note;
        },
        set: function (value) {
            this.recordEO.note = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "safety", {
        get: function () {
            return this.recordEO.safety;
        },
        set: function (value) {
            if (this.recordEO.safety != value) {
                this.recordEO.safety = value;
                this.calculateQuantities();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "cultureType", {
        get: function () {
            return this.recordEO.cultureType;
        },
        set: function (value) {
            if (this.recordEO.cultureType != value) {
                this.recordEO.cultureType = value;
                if (this.cultureType != Constants.CULTURE_TYPE_ANZUCHT) {
                    this.recordEO.seedDate.setTime(this.recordEO.growDate.getTime());
                }
                this.myApplication.eventHandler.raiseEvent(EventHandler.EVENT_RECORD_START_CHANGED, this.cultureId);
                this.myApplication.eventHandler.raiseEvent(EventHandler.EVENT_RECORD_DATES_CHANGED, this.id);
                this.buildCultureDateToDos();
                this.calculateQuantities();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "fixUserInput", {
        get: function () {
            return this.recordEO.fixUserInput;
        },
        set: function (value) {
            this.recordEO.fixUserInput = value;
        },
        enumerable: true,
        configurable: true
    });
    Record.prototype.getNeededSeeds = function () {
        var seedsPerQuickpot = this.seedsPerQuickpot;
        if (seedsPerQuickpot < 1) {
            seedsPerQuickpot = 1;
        }
        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT || this.cultureType == Constants.CULTURE_TYPE_ANZUCHTVERKAUF) {
            return this.quantity * seedsPerQuickpot;
        }
        else if (this.cultureType == Constants.CULTURE_TYPE_DIREKTSAAT) {
            return this.quantity;
        }
        else {
            return 0;
        }
    };
    Record.prototype.getNeededSeedsOtherUnit = function () {
        var quantity = 0;
        var plantsPerM2 = 0;
        // Anzahl Pflanzen pro m2 ermitteln
        plantsPerM2 = this.calculatePlantPerM2();
        //Anzahl m2 ermitteln
        quantity = this.quantity / plantsPerM2;
        return quantity;
    };
    Record.prototype.calculatePlantPerM2 = function () {
        var plantPerColumn = 0;
        var plantPerRow = 0;
        var plantPerM2 = 0;
        if (this.plantDistance == 0) {
            if (this.myApplication) {
                this.myApplication.messageDTO.setErrorWithId('FM.plantDistanceIsZero');
            }
            return 0;
        }
        if (this.rowDistance == 0) {
            if (this.myApplication) {
                this.myApplication.messageDTO.setErrorWithId('FM.rowDistanceIsZero');
            }
            return 0;
        }
        plantPerColumn = 100 / this.plantDistance;
        //plantPerColumn = Math.floor(plantPerColumn);
        plantPerRow = 100 / this.rowDistance;
        //plantPerRow = Math.floor(plantPerRow);
        plantPerM2 = plantPerColumn * plantPerRow;
        return plantPerM2;
    };
    Record.prototype.getRecordName = function () {
        if (this.culture && this.sort && this.sort != '') {
            return this.culture.name + ' ' + this.recordNum + ' ' + this.sort;
        }
        else if (this.culture) {
            return this.culture.name + ' ' + this.recordNum;
        }
        else {
            return 'ich bin ein alter Satz! lösch mich!';
        }
    };
    Record.prototype.getRecordNameWithoutSort = function () {
        if (this.culture) {
            return this.culture.name + ' ' + this.recordNum;
        }
        else {
            return 'ich bin ein alter Satz! lösch mich!';
        }
    };
    Object.defineProperty(Record.prototype, "plantsPerBox", {
        get: function () {
            return this.recordEO.plantsPerBox;
        },
        set: function (value) {
            this.recordEO.plantsPerBox = value;
        },
        enumerable: true,
        configurable: true
    });
    Record.prototype.hasPlantBoxesWarnings = function () {
        if (this.cultureType != Constants.CULTURE_TYPE_PFLANZKAUF) {
            return false;
        }
        if (this.plantsPerBox <= 0) {
            return true;
        }
        else {
            return false;
        }
    };
    Record.prototype.getName = function () {
        return this.getRecordName();
    };
    Record.prototype.getRecordNameWithYear = function () {
        return this.getRecordName() + ' (' + this.recordPeriods.getStartWeek().year + ')';
    };
    Record.prototype.getRecordNameWithoutCulture = function () {
        if (this.sort && this.sort != '') {
            return this.recordNum.toString() + ' ' + this.sort + ' (' + this.recordPeriods.getStartWeek().year + ')';
        }
        else {
            return this.recordNum.toString() + ' (' + this.recordPeriods.getStartWeek().year + ')';
        }
    };
    Record.prototype.getSort = function () {
        for (var i = 0; i < this.culture.sorts.length; i++) {
            var sortTrim = this.culture.sorts[i].name.trim();
            var sortTrim2 = this.sort.trim();
            if (sortTrim == sortTrim2) {
                return this.culture.sorts[i];
            }
        }
        return null;
    };
    Record.prototype.getFullSortName = function () {
        return this.culture.name + ' ' + this.sort;
    };
    Record.prototype.initialize = function () {
        this.eo = new RecordEO();
    };
    Record.prototype.removeFromBed = function () {
        this.update();
    };
    Record.prototype.clone = function () {
        var newRecord = this.daoRecords.createNewInstance();
        newRecord.sort = this.sort;
        newRecord.culture = this.culture;
        newRecord.recordEO.cultureType = this.cultureType;
        newRecord.recordNum = this.recordNum;
        newRecord.fixUserInput = this.fixUserInput;
        newRecord.plantDistance = this.plantDistance;
        newRecord.rowDistance = this.rowDistance;
        newRecord.plantDeepth = this.plantDeepth;
        newRecord.numRows = this.numRows;
        newRecord.quickpotSize = this.quickpotSize;
        newRecord.seedsPerQuickpot = this.seedsPerQuickpot;
        newRecord.plantsPerBox = this.plantsPerBox;
        newRecord.lengthOnBed = this.lengthOnBed;
        newRecord.harvestMethod = this.harvestMethod;
        // newRecord.harvestUnit = this.harvestUnit;
        newRecord.note = this.note;
        newRecord.safety = this.safety;
        newRecord.seedRate = this.seedRate;
        newRecord.areaId = this.areaId;
        newRecord.recordPeriods.extraQuantity = this.recordPeriods.extraQuantity;
        newRecord.recordPeriods.useExtraQuantity = this.recordPeriods.useExtraQuantity;
        newRecord.recordPeriods.clone(this.recordPeriods);
        newRecord.todoList.clone(this);
        newRecord.quantity = this.quantity;
        newRecord.addTags(this.tags.toString());
        newRecord.buildCultureDateToDos();
        newRecord.update();
        this.myApplication.eventHandler.raiseEvent(EventHandler.EVENT_RECORD_CREATED, this.id);
        return newRecord;
    };
    Record.prototype.cloneToNextYear = function () {
        var newRecord = this.clone();
        newRecord.recordPeriods.cloneToNextYear(this.recordPeriods);
        newRecord.update();
        return newRecord;
    };
    Record.prototype.createFromRecordCreateDTO = function (recordCreateDTO) {
        this.cultureId = recordCreateDTO.culture.id;
        this.culture = recordCreateDTO.culture;
        this.sort = recordCreateDTO.sort;
        if (this.sort == null) {
            this.sort = '';
        }
        this.state = 'P';
        this.recordEO.plantDistance = recordCreateDTO.plantDistance;
        this.recordEO.rowDistance = recordCreateDTO.rowDistance;
        this.recordEO.plantDeepth = recordCreateDTO.culture.plantDeepth;
        this.recordEO.numRows = recordCreateDTO.numRows;
        this.recordEO.quickpotSize = recordCreateDTO.culture.quickpotSize;
        this.recordEO.seedsPerQuickpot = recordCreateDTO.culture.seedsPerQuickpot;
        this.recordEO.plantsPerBox = recordCreateDTO.culture.plantsPerBox;
        this.recordEO.harvestMethod = recordCreateDTO.harvestMethod;
        this.recordEO.cultureType = recordCreateDTO.cultureType;
        this.recordEO.safety = recordCreateDTO.safety;
        this.recordEO.seedRate = recordCreateDTO.seedRate;
        this.recordEO.extraQuantity = recordCreateDTO.extraQuantity;
        this.recordEO.useExtraQuantity = recordCreateDTO.useExtraQuantity;
        this.recordEO.areaId = recordCreateDTO.areaId;
        this.recordPeriods.createFromRecordCreateDTO(recordCreateDTO);
        this.addTags(recordCreateDTO.tags.toString());
        //this.recordPeriods.createHarvestDistribution(); wird durch Länge getriggert
        if (recordCreateDTO.useQuantity || (this.repoService.repository.system != Constants.SYSTEM_SOLAWI)) {
            this.quantity = recordCreateDTO.quantity;
            this.lengthOnBed = recordCreateDTO.lengthOnBed;
            this.fixUserInput = recordCreateDTO.fixUserInput;
        }
        else {
            this.quantity = this.calculateQuantity();
            this.lengthOnBed = this.calculateBedM();
        }
        this.createToDos(recordCreateDTO.templates);
        this.myApplication.eventHandler.raiseEvent(EventHandler.EVENT_RECORD_CREATED, this.id);
        this.daoRecords.objectSet.update();
    };
    Record.prototype.createToDos = function (templates) {
        var _this = this;
        this.todoList; //aufbauen bitte
        templates.forEach(function (template) {
            if (template.checkedForRecord) {
                var toDo = template.createToDO();
                toDo.parentType = Constants.PARENT_TYPE_RECORD;
                toDo.parentId = _this.id;
                if (toDo.isSeriesMaster()) {
                    toDo.generateSeriesToDos();
                }
                //this.todoList.toDos.push(toDo); geht von ganz von selbst, braucht man gar nicht
            }
        });
        this.buildCultureDateToDos();
    };
    Record.prototype.buildCultureDateToDos = function () {
        var todo;
        this.todoList.deleteRecordDates();
        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT) {
            todo = this.daoToDos.createNewInstance();
            todo.parentType = Constants.PARENT_TYPE_RECORD;
            todo.parentId = this.id;
            todo.toDoType = Constants.TODO_RECORD_DATES;
            todo.type = this.myApplication.getText('ToDo.type.' + Constants.TODO_TYPE_ANZUCHT);
            todo.cultureDatType = Constants.TODO_TYPE_ANZUCHT;
            todo.color = Constants.COLOR_ANZUCHT;
            todo.time = 0;
            todo.timeUnit = Constants.TIME_UNIT_TAGE;
            todo.timeType = Constants.TEMPLATE_PREPOSITION_BEFORE;
            todo.timeReference = Constants.TEMPLATE_TIME_REF_ANZUCHT;
        }
        todo = this.daoToDos.createNewInstance();
        todo.parentType = Constants.PARENT_TYPE_RECORD;
        todo.parentId = this.id;
        todo.toDoType = Constants.TODO_RECORD_DATES;
        if (this.cultureType == Constants.CULTURE_TYPE_DIREKTSAAT) {
            todo.type = this.myApplication.getText('ToDo.type.' + Constants.TODO_TYPE_AUSSAAT);
            todo.cultureDatType = Constants.TODO_TYPE_AUSSAAT;
        }
        else {
            todo.type = todo.type = this.myApplication.getText('ToDo.type.' + Constants.TODO_TYPE_PFLANZUNG);
            todo.cultureDatType = Constants.TODO_TYPE_PFLANZUNG;
        }
        todo.color = Constants.COLOR_PLANTED;
        todo.time = 0;
        todo.timeUnit = Constants.TIME_UNIT_TAGE;
        todo.timeType = Constants.TEMPLATE_PREPOSITION_BEFORE;
        todo.timeReference = Constants.TEMPLATE_TIME_REF_PFLANZUNG;
    };
    Record.prototype.createHarvestFollowingRecord = function (recordCreateDTO) {
        var newRecord = this.clone();
        newRecord.shift(this.recordPeriods.harvestLength);
        if (recordCreateDTO) {
            if (recordCreateDTO.useQuantity || (this.repoService.repository.system != Constants.SYSTEM_SOLAWI)) {
                newRecord.quantity = recordCreateDTO.quantity;
                newRecord.lengthOnBed = recordCreateDTO.lengthOnBed;
            }
        }
        return newRecord;
    };
    Record.prototype.calculateBedMToRecord = function () {
        //Beetmeter berechnen und setzen
        var calcualtedResult = this.calculateBedM();
        this.lengthOnBed = calcualtedResult;
        if (calcualtedResult == 0) {
            this.bedMWarning = true;
        }
        else {
            this.bedMWarning = false;
        }
    };
    Object.defineProperty(Record.prototype, "bedMWarningValue", {
        get: function () {
            if (this.bedMWarning || !this.quantityFits()) {
                return this.lengthOnBed;
            }
            else {
                return this.lengthOnBed - 1;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "quantityWarningValue", {
        get: function () {
            if (!this.quantityFits() || this.quantity == 0) {
                return this.quantity;
            }
            else {
                return this.quantity - 1;
            }
        },
        enumerable: true,
        configurable: true
    });
    Record.prototype.calculateBedM = function () {
        return new CalculatorLengthOnBed(this.numRows, this.quantity, this.plantDistance, this.basicService, this.seedRate, this.cultureType).calculate();
    };
    Record.prototype.calculateQuantityWithBedM = function () {
        var calcualtedResult = new CalculatorQuantityWithBedM(this.numRows, this.lengthOnBed, this.plantDistance, this.basicService, null, this.seedRate, this.cultureType).calculate();
        if (calcualtedResult > 0) {
            this.quantity = calcualtedResult;
        }
    };
    Record.prototype.calculateQuantityToRecord = function (messages) {
        var calcualtedResult = this.calculateQuantity(messages);
        this.quantityCalculated1 = calcualtedResult;
        if (calcualtedResult == 0 && messages) {
            this.myApplication.messageDTO.setErrorWithId('FM.Record.calculationNotPossible');
        }
        this.quantity = calcualtedResult;
    };
    //Berechne die benoetigte Pflanzenmenge
    Record.prototype.calculateQuantity = function (messages) {
        var numHarvestWeeks;
        var neededQuantity = this.recordPeriods.neededHarvestQuantity;
        var extraQuantity = this.recordPeriods.extraQuantity;
        if (this.recordPeriods.isAlternativeDeliverySelected() == true) {
            numHarvestWeeks = this.recordPeriods.numOfDeliveryWeeks;
        }
        else {
            numHarvestWeeks = this.recordPeriods.harvestLength;
        }
        var application = null;
        if (messages) {
            application = this.myApplication;
        }
        var calculator = new CalculatorQuantity(this.harvestMethod, this.getHarvestQuantity(), numHarvestWeeks, neededQuantity, this.recordPeriods.harvestWeek, this.recordPeriods.isAlternativeDeliverySelected(), this.safety, this.plantDistance, this.rowDistance, application, this.basicService, this.seedRate, this.cultureType, this.partsService, extraQuantity, this.repoService, this.recordPeriods.harvestUnit);
        return calculator.calculate();
    };
    Record.prototype.getHarvestQuantity = function () {
        var harvestQuantity = 0;
        harvestQuantity = this.recordPeriods.harvestQuantity;
        return harvestQuantity;
    };
    //Berechne die Samenmenge
    Record.prototype.calculateSeedCapacity = function () {
        var capacity = 0;
        return capacity;
    };
    //Samenmenge reduzieren um die berechnete Samenmenge
    Record.prototype.reduceSeed = function () {
        return 0;
    };
    Record.prototype.buildPeriodsUI = function () {
        this._periodsUI = new PeriodsUIList(this.myApplication, this.basicService, this);
        this._periodsUI.createFromRecordPeriods(this);
    };
    Object.defineProperty(Record.prototype, "selected", {
        get: function () {
            return this._selected;
        },
        set: function (value) {
            this._selected = value;
        },
        enumerable: true,
        configurable: true
    });
    Record.prototype.generateInfoText = function () {
        // Parameter besorgen
        var harvestWeekInYear = this.recordPeriods.harvestWeek;
        var filteredParts = new FilteredParts();
        var numOfParts = filteredParts.getCurrentPartsNumParts(this.partsService.parts, harvestWeekInYear);
        if ((!numOfParts || numOfParts == 0) && this.repoService.repository.system != Constants.SYSTEM_SOLAWI) {
            numOfParts = 1;
        }
        var harvestWeeks;
        if (!this.recordPeriods.alternativeDelivery) {
            harvestWeeks = this.recordPeriods.harvestLength;
        }
        else {
            harvestWeeks = this.recordPeriods.numOfDeliveryWeeks;
        }
        var neededHarvestQuantity = this.recordPeriods.neededHarvestQuantity;
        var extraQuantity = this.recordPeriods.extraQuantity;
        var harvestQuantity = this.recordPeriods.harvestQuantity;
        var quantityUnit = this.recordPeriods.harvestUnit;
        var plantDistance = this.plantDistance;
        var rowDistance = this.rowDistance;
        var numRows = this.numRows;
        var safety = this.safety;
        var seedRate = this.seedRate;
        var calculator = new CalculatorQuantity(this.harvestMethod, harvestQuantity, harvestWeeks, neededHarvestQuantity, harvestWeekInYear, this.recordPeriods.alternativeDelivery, safety, plantDistance, rowDistance, null, this.basicService, this.seedRate, this.cultureType, this.partsService, extraQuantity, this.repoService, this.recordPeriods.harvestUnit);
        var numPlants = calculator.calculate();
        var neededM2 = calculator.calculateWithHarvestQuantity((numOfParts * neededHarvestQuantity * harvestWeeks), harvestQuantity);
        var neededM2Week = calculator.calculateWithHarvestQuantity((numOfParts * neededHarvestQuantity), harvestQuantity);
        var plantPerM2 = calculator.calculatePlantPerM2();
        var calculatorBedMeters = new CalculatorLengthOnBed(numRows, numPlants, plantDistance, this.basicService, this.seedRate, this.cultureType);
        var bedMeters = calculatorBedMeters.calculate();
        var plantBedMeters = this.basicService.ownCeil(bedMeters * numRows * 100 / plantDistance);
        // fuer Solawi
        if (this.repoService.repository.system == Constants.SYSTEM_SOLAWI) {
            if (this.harvestMethod == Constants.HARVEST_METHOD_ABSOLUT) {
                // Keimrate relevant?
                if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seedRate != 100) {
                    this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextPlantPartsSeedRate', numOfParts, harvestWeeks, neededHarvestQuantity, this.myApplication.getText('Unit.' + quantityUnit), safety, seedRate, numPlants);
                    this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + numPlants + " = " + numOfParts + " * " + harvestWeeks + " * " +
                        neededHarvestQuantity + " + " + safety + " % " + this.myApplication.getText('Culture.safety') +
                        " + " + seedRate + " % " + this.myApplication.getText('Culture.seedRate') + "). ";
                    // zusaetzliche Erntemenge relevant?
                    if (this.recordPeriods.useExtraQuantity) {
                        this.popoverInfoTextPlant = this.popoverInfoTextPlant + ' ' +
                            this.myApplication.getText('Record.infoTextExtraPlants', this.recordPeriods.extraQuantity, this.myApplication.getText('Record.recordPeriods.harvestQuantity.' + this.recordPeriods.harvestUnit));
                    }
                    this.popoverInfoTextPlant = this.popoverInfoTextPlant + ' ' + this.myApplication.getText('Record.infoTextPlantParts2');
                }
                else {
                    this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextPlantParts', numOfParts, harvestWeeks, neededHarvestQuantity, this.myApplication.getText('Unit.' + quantityUnit), safety, numPlants);
                    this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + numPlants + " = " + numOfParts + " * " + harvestWeeks + " * " +
                        neededHarvestQuantity + " + " + safety + " % " + this.myApplication.getText('Culture.safety') + "). ";
                    // zusaetzliche Erntemenge relevant?
                    if (this.recordPeriods.useExtraQuantity) {
                        this.popoverInfoTextPlant = this.popoverInfoTextPlant + ' ' +
                            this.myApplication.getText('Record.infoTextExtraPlants', this.recordPeriods.extraQuantity, this.myApplication.getText('Record.recordPeriods.harvestQuantity.' + this.recordPeriods.harvestUnit));
                    }
                    this.popoverInfoTextPlant = this.popoverInfoTextPlant + ' ' + this.myApplication.getText('Record.infoTextPlantParts2');
                }
            }
            else if (this.harvestMethod == Constants.HARVEST_METHOD_WEEK) {
                // Keimrate relevant?
                if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seedRate != 100) {
                    this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextPlantPartsWeekSeedRate', numOfParts, neededHarvestQuantity, this.myApplication.getText('Unit.' + quantityUnit), harvestQuantity, this.myApplication.getText('Unit.' + quantityUnit), safety, seedRate, numPlants);
                    this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + numPlants + " = " + numOfParts + " * " + neededHarvestQuantity + " / " + harvestQuantity +
                        " + " + safety + "% " + this.myApplication.getText('Culture.safety') + " + " + seedRate + "% " + this.myApplication.getText('Culture.seedRate') + "). ";
                    // zusaetzliche Erntemenge relevant?
                    if (this.recordPeriods.useExtraQuantity) {
                        this.popoverInfoTextPlant = this.popoverInfoTextPlant + ' ' +
                            this.myApplication.getText('Record.infoTextExtraPlants', this.recordPeriods.extraQuantity, this.myApplication.getText('Record.recordPeriods.harvestQuantity.' + this.recordPeriods.harvestUnit));
                    }
                    this.popoverInfoTextPlant = this.popoverInfoTextPlant + ' ' + this.myApplication.getText('Record.infoTextPlantParts2');
                }
                else {
                    this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextPlantPartsWeek', numOfParts, neededHarvestQuantity, this.myApplication.getText('Unit.' + quantityUnit), harvestQuantity, this.myApplication.getText('Unit.' + quantityUnit), safety, numPlants);
                    this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + numPlants + " = " + numOfParts + " * " + neededHarvestQuantity + " / " + harvestQuantity +
                        " + " + safety + "% " + this.myApplication.getText('Culture.safety') + "). ";
                    // zusaetzliche Erntemenge relevant?
                    if (this.recordPeriods.useExtraQuantity) {
                        this.popoverInfoTextPlant = this.popoverInfoTextPlant + ' ' +
                            this.myApplication.getText('Record.infoTextExtraPlants', this.recordPeriods.extraQuantity, this.myApplication.getText('Record.recordPeriods.harvestQuantity.' + this.recordPeriods.harvestUnit));
                    }
                    this.popoverInfoTextPlant = this.popoverInfoTextPlant + ' ' + this.myApplication.getText('Record.infoTextPlantParts2');
                }
            }
            else if (this.harvestMethod == Constants.HARVEST_METHOD_M2) {
                this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextPlantPartsM2', numOfParts, harvestWeeks, neededHarvestQuantity, this.myApplication.getText('Unit.' + quantityUnit), harvestQuantity, this.myApplication.getText('Unit.' + quantityUnit), neededM2);
                this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + neededM2 + " = " + numOfParts + " * " + neededHarvestQuantity + " * " + harvestWeeks +
                    " / " + harvestQuantity + " ). ";
                // zusaetzliche Erntemenge relevant?
                if (this.recordPeriods.useExtraQuantity) {
                    this.popoverInfoTextPlant = this.popoverInfoTextPlant + ' ' +
                        this.myApplication.getText('Record.infoTextExtraPlants', this.recordPeriods.extraQuantity, this.myApplication.getText('Record.recordPeriods.harvestQuantity.' + this.recordPeriods.harvestUnit));
                }
                this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantM22', plantPerM2, plantDistance, rowDistance);
                this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + plantPerM2 + " = ( 100 * 100 ) / (" + plantDistance + " * " + rowDistance + " )";
                // Keimrate relevant?
                if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seedRate != 100) {
                    this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantM23SeedRate', neededM2, plantPerM2, safety, seedRate, numPlants);
                }
                else {
                    this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantM23', neededM2, plantPerM2, safety, numPlants);
                }
                this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantParts2');
            }
            else if (this.harvestMethod == Constants.HARVEST_METHOD_M2_WEEK) {
                this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextPlantPartsM2Week', numOfParts, neededHarvestQuantity, this.myApplication.getText('Unit.' + quantityUnit), harvestQuantity, this.myApplication.getText('Unit.' + quantityUnit), neededM2Week);
                this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + neededM2Week + " = " + numOfParts + " * " + neededHarvestQuantity + " * " +
                    " / " + harvestQuantity + " ). ";
                // zusaetzliche Erntemenge relevant?
                if (this.recordPeriods.useExtraQuantity) {
                    this.popoverInfoTextPlant = this.popoverInfoTextPlant + ' ' +
                        this.myApplication.getText('Record.infoTextExtraPlants', this.recordPeriods.extraQuantity, this.myApplication.getText('Record.recordPeriods.harvestQuantity.' + this.recordPeriods.harvestUnit));
                }
                this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantM22', plantPerM2, plantDistance, rowDistance);
                this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + plantPerM2 + " = ( 100 * 100 ) / (" + plantDistance + " * " + rowDistance + " )";
                // Keimrate relevant?
                if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seedRate != 100) {
                    this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantM23SeedRate', neededM2Week, plantPerM2, safety, seedRate, numPlants);
                    this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + numPlants + " = " + neededM2Week + " * " + plantPerM2 + " + " +
                        seedRate + " % " + this.myApplication.getText('Culture.seedRate') + " )";
                }
                else {
                    this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantM23', neededM2Week, plantPerM2, safety, numPlants);
                    this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + numPlants + " = " + neededM2Week + " * " + plantPerM2 + " )";
                }
                this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantParts2');
            }
        }
        // nicht SoLaWi
        else {
            if (this.harvestMethod == 'A') {
                // Keimrate relevant?
                if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seedRate != 100) {
                    this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextPlantSeedRate', harvestWeeks, neededHarvestQuantity, this.myApplication.getText('Unit.' + quantityUnit), safety, seedRate, numPlants);
                    this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + numPlants + " = " + harvestWeeks + " * " +
                        neededHarvestQuantity + " + " + safety + " % " + this.myApplication.getText('Culture.safety') +
                        " + " + seedRate + " % " + this.myApplication.getText('Culture.seedRate') + "). ";
                }
                else {
                    this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextPlant', harvestWeeks, neededHarvestQuantity, this.myApplication.getText('Unit.' + quantityUnit), safety, numPlants);
                    this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + numPlants + " = " + harvestWeeks + " * " +
                        neededHarvestQuantity + " + " + safety + " % " + this.myApplication.getText('Culture.safety') + "). ";
                }
            }
            else if (this.harvestMethod == Constants.HARVEST_METHOD_WEEK) {
                // Keimrate relevant?
                if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seedRate != 100) {
                    this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextPlantWeekSeedRate', neededHarvestQuantity, this.myApplication.getText('Unit.' + quantityUnit), harvestQuantity, this.myApplication.getText('Unit.' + quantityUnit), safety, seedRate, numPlants);
                    this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + numPlants + " = " + neededHarvestQuantity + " / " + harvestQuantity +
                        " + " + safety + "% " + this.myApplication.getText('Culture.safety') +
                        " + " + seedRate + "% " + this.myApplication.getText('Culture.seedRate') + "). ";
                }
                else {
                    this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextPlantWeek', neededHarvestQuantity, this.myApplication.getText('Unit.' + quantityUnit), harvestQuantity, this.myApplication.getText('Unit.' + quantityUnit), safety, numPlants);
                    this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + numPlants + " = " + neededHarvestQuantity + " / " + harvestQuantity +
                        " + " + safety + "% " + this.myApplication.getText('Culture.safety') + "). ";
                }
            }
            else if (this.harvestMethod == Constants.HARVEST_METHOD_M2) {
                this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextPlantM2', harvestWeeks, neededHarvestQuantity, this.myApplication.getText('Unit.' + quantityUnit), harvestQuantity, this.myApplication.getText('Unit.' + quantityUnit), neededM2);
                this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + neededM2 + " = " + neededHarvestQuantity + " * " + harvestWeeks +
                    " / " + harvestQuantity + " ). ";
                this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantM22', plantPerM2, plantDistance, rowDistance);
                this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + plantPerM2 + " = ( 100 * 100 ) / (" + plantDistance + " * " + rowDistance + " )";
                // Keimrate relevant?
                if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seedRate != 100) {
                    this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantM23SeedRate', neededM2, plantPerM2, safety, seedRate, numPlants);
                }
                else {
                    this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantM23', neededM2, plantPerM2, safety, numPlants);
                }
            }
            else if (this.harvestMethod == Constants.HARVEST_METHOD_M2_WEEK) {
                this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextPlantM2Week', neededHarvestQuantity, this.myApplication.getText('Unit.' + quantityUnit), harvestQuantity, this.myApplication.getText('Unit.' + quantityUnit), neededM2Week);
                this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + neededM2Week + " = " + neededHarvestQuantity +
                    " / " + harvestQuantity + " ). ";
                this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantM22', plantPerM2, plantDistance, rowDistance);
                this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + plantPerM2 + " = ( 100 * 100 ) / (" + plantDistance + " * " + rowDistance + " )";
                // Keimrate relevant?
                if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seedRate != 100) {
                    this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantM23SeedRate', neededM2Week, plantPerM2, safety, seedRate, numPlants);
                    this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + numPlants + " = " + neededM2Week + " * " + plantPerM2 + " + " +
                        seedRate + " % " + this.myApplication.getText('Culture.seedRate') + " )";
                }
                else {
                    this.popoverInfoTextPlant = this.popoverInfoTextPlant + " " + this.myApplication.getText('Record.infoTextPlantM23', neededM2Week, plantPerM2, safety, numPlants);
                    this.popoverInfoTextPlant = this.popoverInfoTextPlant + " ( " + numPlants + " = " + neededM2Week + " * " + plantPerM2 + " )";
                }
            }
        }
        // Keimrate relevant?
        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seedRate != 100) {
            this.popoverInfoTextBedMeters = this.myApplication.getText('Record.infoTextBedMetersSeedRate', bedMeters, numRows, plantDistance, numPlants, seedRate) +
                " ( " + bedMeters + "m = " + numPlants + " / " + numRows + " * " + plantDistance + "cm + " + seedRate + " % " +
                this.myApplication.getText('Culture.seedRate') + ")";
            this.popoverInfoTextPlantBedMeters = this.myApplication.getText('Record.infoTextPlantsBedMeters', bedMeters, plantBedMeters) + " ( " + plantBedMeters +
                " = " + bedMeters + "m * " + numRows + " / " + plantDistance + "cm )";
        }
        else {
            this.popoverInfoTextBedMeters = this.myApplication.getText('Record.infoTextBedMeters', bedMeters, numRows, plantDistance, numPlants) +
                " ( " + bedMeters + "m = " + numPlants + " / " + numRows + " * " + plantDistance + "cm )";
            this.popoverInfoTextPlantBedMeters = this.myApplication.getText('Record.infoTextPlantsBedMeters', bedMeters, plantBedMeters) + " ( " + plantBedMeters +
                " = " + bedMeters + "m * " + numRows + " / " + plantDistance + "cm )";
        }
        this.popoverInfoTextRounded = this.myApplication.getText('Record.infoTextRounded');
    };
    Object.defineProperty(Record.prototype, "seedRate", {
        get: function () {
            return this.recordEO.seedRate;
        },
        set: function (value) {
            this.recordEO.seedRate = value;
            this.calculateQuantities();
        },
        enumerable: true,
        configurable: true
    });
    Record.prototype.generateInfoTextFixPlants = function () {
        var calculatorBedMeters = new CalculatorLengthOnBed(this.numRows, this.quantity, this.plantDistance, this.basicService, this.seedRate, this.cultureType);
        var bedMeters = calculatorBedMeters.calculate();
        var plantBedMeters = this.basicService.ownCeil(bedMeters * this.numRows * 100 / this.plantDistance);
        this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextPlantsFix');
        // Keimrate relevant?
        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seedRate != 100) {
            this.popoverInfoTextBedMeters = this.myApplication.getText('Record.infoTextBedMetersSeedRate', bedMeters, this.numRows, this.plantDistance, this.quantity, this.seedRate) +
                " ( " + bedMeters + "m = " + this.quantity + " / " + this.numRows + " * " + this.plantDistance + "cm + " + this.seedRate + " % " +
                this.myApplication.getText('Culture.seedRate') + ")";
            this.popoverInfoTextPlantBedMeters = this.myApplication.getText('Record.infoTextPlantsBedMeters', bedMeters, plantBedMeters) + " ( " + plantBedMeters +
                " = " + bedMeters + "m * " + this.numRows + " / " + this.plantDistance + "cm )";
        }
        else {
            this.popoverInfoTextBedMeters = this.myApplication.getText('Record.infoTextBedMeters', bedMeters, this.numRows, this.plantDistance, this.quantity) +
                " ( " + bedMeters + "m = " + this.quantity + " / " + this.numRows + " * " + this.plantDistance + "cm )";
            this.popoverInfoTextPlantBedMeters = this.myApplication.getText('Record.infoTextPlantsBedMeters', bedMeters, plantBedMeters) + " ( " + plantBedMeters +
                " = " + bedMeters + "m * " + this.numRows + " / " + this.plantDistance + "cm )";
        }
        this.popoverInfoTextRounded = this.myApplication.getText('Record.infoTextRounded');
    };
    Record.prototype.generateInfoTextFixBedMeters = function () {
        var calcPlantsPerBedMeters = new CalculatorQuantityWithBedM(this.numRows, this.lengthOnBed, this.plantDistance, this.basicService, this.myApplication, this.seedRate, this.cultureType);
        var plantBedMeters = this.basicService.ownCeil(this.lengthOnBed * this.numRows * 100 / this.plantDistance);
        var plantsBedMetersSeedRate = calcPlantsPerBedMeters.calculate();
        this.popoverInfoTextPlant = this.myApplication.getText('Record.infoTextBedMetersFix');
        this.popoverInfoTextBedMeters = this.popoverInfoTextPlantBedMeters = this.myApplication.getText('Record.infoTextPlantsBedMeters', this.lengthOnBed, plantBedMeters) + " ( " + plantBedMeters + " = " + this.lengthOnBed + "m * " + this.numRows + " / " + this.plantDistance + "cm )";
        if (this.cultureType == Constants.CULTURE_TYPE_ANZUCHT && this.seedRate != 100) {
            this.popoverInfoTextBedMeters = this.popoverInfoTextBedMeters + " " + this.myApplication.getText('Record.infoTextFixBedMetersSeedRate', this.seedRate, plantsBedMetersSeedRate);
        }
        this.popoverInfoTextPlantBedMeters = '';
        this.popoverInfoTextRounded = '';
    };
    return Record;
}(AbstractObject));
export { Record };
