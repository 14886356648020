var RegisterDTO = /** @class */ (function () {
    function RegisterDTO() {
        this.company = '';
        this.firstName = '';
        this.lastName = '';
        this.repositoryName = '';
        this.system = '1';
        this.numParts = 120;
        this.uname = '';
        this.mail = '';
        this.pw = '';
        this.email = '';
        this.emailConfirm = '';
        this.password = '';
        this.agb = false;
        this.dp = false;
        this.birthDate = new Date();
        this.isOver18 = false;
        this.price = 0;
        this.currency = 'EUR';
        this.agreementVersion = 1;
        this.paymentMethod = 'A';
        this.street = '';
        this.plz = '';
        this.city = '';
        this._country = 'Deutschland';
        this.countryCode = '';
        this.ustId = '';
        this.flatRateFarmer = false;
        this.companyNumber = '';
        this.foundVia = '';
        this.foundViaTxt = '';
    }
    Object.defineProperty(RegisterDTO.prototype, "country", {
        get: function () {
            return this._country;
        },
        set: function (value) {
            this._country = value;
            this.flatRateFarmer = false;
        },
        enumerable: true,
        configurable: true
    });
    return RegisterDTO;
}());
export { RegisterDTO };
