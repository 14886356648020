import { Http, RequestOptions } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "./authentication.service";
import * as i2 from "@angular/http";
var DashboardService = /** @class */ (function () {
    function DashboardService(authenticationService, http) {
        this.authenticationService = authenticationService;
        this.http = http;
        this.dataReady = false;
    }
    DashboardService.prototype.readData = function () {
        var _this = this;
        this.dataReady = false;
        var options = new RequestOptions();
        options.headers = this.authenticationService.getHeadersAuthenticated();
        var year = new Date().getFullYear();
        this.http.get(environment.apiV2 + 'dashboardData/' + year, options).pipe(map(function (resp) { return resp.json(); }))
            .subscribe(function (result) {
            _this.data = result;
            _this.dataReady = true;
        });
    };
    DashboardService.ngInjectableDef = i0.defineInjectable({ factory: function DashboardService_Factory() { return new DashboardService(i0.inject(i1.AuthenticationService), i0.inject(i2.Http)); }, token: DashboardService, providedIn: "root" });
    return DashboardService;
}());
export { DashboardService };
