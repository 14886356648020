import { DAO } from '../technical-aspects/dao';
import { Seed } from '../business-logic/seed';
import { DAOSorts } from './dao-sorts';
import { DAOSortSeeds } from './dao-sort-seeds';


export class DAOSeeds extends DAO {

    protected createInstance() {
        let newInstance = new Seed();
        newInstance.daoSorts = <DAOSorts>this.daoService.getDAO('sorts');
        newInstance.daoSortSeed = <DAOSortSeeds>this.daoService.getDAO('sortSeeds');
        return newInstance;
    }

    public getSeedsForSortSeed(id: String): Array<Seed> {
        let list = new Array<Seed>();
        this.objects.forEach(object => {
            let seed = <Seed> object;
            if (seed.sortSeedId == id) {
                list.push(seed);
            }
        });
        return list;
    }
}