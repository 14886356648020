import { SortSeedEO } from '../entity-objects/sort-seed-eo';
import { AbstractObject } from '../technical-aspects/abstract-object';


export class SortSeed extends AbstractObject {

    constructor() {
        super();
    }

    update() {

    }

    initialize() {
        this.eo = new SortSeedEO();
    }
    
    protected get sortSeedEO(): SortSeedEO {
        return <SortSeedEO>this.eo;
    }

    public get sortId(): String {
        return this.sortSeedEO.sortId;
    }
    public set sortId(value: String) {
        this.sortSeedEO.sortId = value;
    }

    public get manufacturer(): String {
        return this.sortSeedEO.manufacturer;
    }
    public set manufacturer(value: String) {
        this.sortSeedEO.manufacturer = value;
    }

    public get tkg(): number {
        return this.sortSeedEO.tkg;
    }
    public set tkg(value: number) {
        this.sortSeedEO.tkg = value;
    }

    public get manufacturingYear(): number {
        return this.sortSeedEO.manufacturingYear;
    }
    public set manufacturingYear(value: number) {
        this.sortSeedEO.manufacturingYear = value;
    }

    public get seedValue(): number {
        return this.sortSeedEO.seedValue;
    }
    public set seedValue(value: number) {
        this.sortSeedEO.seedValue = value;
    }

    public get seedUnit(): String {
        return this.sortSeedEO.seedUnit;
    }
    public set seedUnit(value: String) {
        this.sortSeedEO.seedUnit = value;
    }

    public get otherUnit(): boolean {
        return this.sortSeedEO.otherUnit;
    }
    public set otherUnit(value: boolean) {
        this.sortSeedEO.otherUnit = value;
    }
}