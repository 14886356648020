import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ApplicationService } from 'src/app/services/application.service';
import { Listener } from 'src/app/app-core/technical-aspects/listener';
import { EventHandler } from 'src/app/app-core/technical-aspects/event-handler';
import { Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-statistic',
  templateUrl: './statistic.component.html',
  styleUrls: ['./statistic.component.css']
})
export class StatisticComponent implements OnInit, OnDestroy, Listener {

  public url: SafeResourceUrl;

  innerHeight = 1100;


  constructor(public sanitizer: DomSanitizer, private myApplication: ApplicationService, private router: Router,
    private breadcrumbSrv: BreadcrumbService) {

    this.breadcrumbSrv.breadcrumb = this.myApplication.getText('Statistik').toString();
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.statisticModule + '/#/home/' + this.myApplication.year.toString());

    this.myApplication.addObserver(this);
    this.myApplication.eventHandler.register(EventHandler.LANGUAGE_CHANGED, this);
  }


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerHeight = window.innerHeight - 60;
  }

  ngOnInit(): void {
    this.update();
    this.onResize(null);
  }

  update() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.statisticModule + '/#/home/' + this.myApplication.year.toString());

    let iframe = document.getElementById('statistics');
    if (iframe == null) {
      return;
    }
    let iWindow = (<HTMLIFrameElement>iframe).contentWindow;
  }

  ngOnDestroy() {
    this.update();
    this.myApplication.eventHandler.deRegister(EventHandler.LANGUAGE_CHANGED, this);
  }

  handleLanguageChanged() {
    this.breadcrumbSrv.breadcrumb = this.myApplication.getText('Statistik').toString();
    
    let iframe = (<HTMLIFrameElement>document.getElementById("statistics"));
    if (iframe) {
      iframe.contentWindow.postMessage({ "message": "languageChanged" }, '*');
    }
  }
}