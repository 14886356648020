import { AbstractObject } from '../technical-aspects/abstract-object';
import { SeedEO } from '../entity-objects/seed-eo';
import { DAOSorts } from '../dao/dao-sorts';
import { Constants } from './constants';
import { DAOSortSeeds } from '../dao/dao-sort-seeds';
import { SortSeed } from './sort-seed';
import { Sort } from './sort';

export class Seed extends AbstractObject {

    public daoSorts: DAOSorts;
    public daoSortSeed: DAOSortSeeds;
    public newWithNewSortSeed = false;


    constructor() {
        super();
    }

    update() {

    }

    initialize() {
        this.eo = new SeedEO();
    }

    protected get seedEO(): SeedEO {
        return <SeedEO>this.eo;
    }

    public get sortSeedId(): String {
        return this.seedEO.sortSeedId;
    }
    public set sortSeedId(value: String) {
        this.seedEO.sortSeedId = value;
    }

    public get done(): boolean {
        return this.seedEO.done;
    }
    public set done(value: boolean) {
        this.seedEO.done = value;
    }

    public get sortId(): String {
        return this.seedEO.sortId;
    }
    public set sortId(value: String) {
        this.seedEO.sortId = value;
    }

    public getSort(): Sort {
        return <Sort>this.daoSorts.getObjectById(this.sortId);
    }

    public getSortSeed(): SortSeed {
        return <SortSeed>this.daoSortSeed.getObjectById(this.sortSeedId);
    }

    public getManufacuringYear(): number {
        return this.getSortSeed().manufacturingYear;
    }

    public get quantityUnit(): String {
        return this.seedEO.quantityUnit;
    }
    public set quantityUnit(value: String) {
        this.seedEO.quantityUnit = value;
    }

    public get quantity(): number {
        return this.seedEO.quantity;
    }
    public set quantity(value: number) {
        this.seedEO.quantity = value;
    }

    public getNumCorn(): number {
        if (this.quantityUnit == Constants.UNIT_KORN) {
            return this.quantity;
        } else {
            if (this.getTKG() > 0) {
                return Math.round(1000 / this.getTKG() * this.quantity);
            } else {
                return 0;
            }
        }
    }

    public getWeight(): number {
        if (this.quantityUnit == Constants.UNIT_GRAMM) {
            return this.quantity;
        } else if (this.getTKG() > 0 && this.quantityUnit == Constants.UNIT_KORN) {
            let weight = this.quantity / 1000 * this.getTKG();
            return weight;
        } else {
            return 0;
        }
    }

    public getTKG(): number {
        return this.getSortSeed().tkg;
    }

    public getSeedValue(): number {
        return this.getSortSeed().seedValue;
    }

    public getSeedUnit(): String {
        return this.getSortSeed().seedUnit;
    }

    public getQuantityText(): String {
        return this.quantity + ' ' + this.quantityUnit;
    }

    public get otherUnit(): boolean { //gibts es eigentlich gar nicht das Feld -- obsolet?
        return this.seedEO.otherUnit;
    }
    public set otherUnit(value: boolean) { //gibts es eigentlich gar nicht das Feld -- obsolet?
        this.seedEO.otherUnit = value;
    }
}