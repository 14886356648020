import * as tslib_1 from "tslib";
import { AbstractObject } from '../technical-aspects/abstract-object';
import { FeedbackEO } from '../entity-objects/feedback-eo';
var Feedback = /** @class */ (function (_super) {
    tslib_1.__extends(Feedback, _super);
    function Feedback() {
        return _super.call(this) || this;
    }
    Feedback.prototype.update = function () {
    };
    Object.defineProperty(Feedback.prototype, "feedbackEO", {
        get: function () {
            return this.eo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Feedback.prototype, "uname", {
        get: function () {
            return this.feedbackEO.uname;
        },
        set: function (value) {
            this.feedbackEO.uname = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Feedback.prototype, "view", {
        get: function () {
            return this.feedbackEO.view;
        },
        set: function (value) {
            this.feedbackEO.view = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Feedback.prototype, "comment", {
        get: function () {
            return this.feedbackEO.comment;
        },
        set: function (value) {
            this.feedbackEO.comment = value;
        },
        enumerable: true,
        configurable: true
    });
    Feedback.prototype.initialize = function () {
        this.eo = new FeedbackEO();
    };
    return Feedback;
}(AbstractObject));
export { Feedback };
