import { DAO } from '../technical-aspects/dao';
import { BedAssign } from '../business-logic/bed-assign';
import { DAOBeds } from './dao-beds';
import { DAOAreas } from './dao-areas';

export class DAOBedAssigns extends DAO {

    protected createInstance() {
        let newInstance = new BedAssign();
        newInstance.daoBeds = <DAOBeds>this.daoService.getDAO('beds');
        newInstance.daoAreas = <DAOAreas>this.daoService.getDAO('areas');
        return newInstance;
    }
}