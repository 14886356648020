<div class="d-flex row justify-content-center bg my-4">

    <div class="card m-2">
        <div class="card-header">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item dropdown"><button class="btn btn-sm ml-1 dropdown-toggle btn-outline-success"
                        id="btn-next" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{ myApplication.selectedLanguage }}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <a *ngFor="let language of myApplication.availableLanguages" class="dropdown-item"
                            (click)="switchLanguage(language)">
                            {{ language }}
                        </a>
                    </div>
                </li>
            </ul>

            <div id="lLogin" class="text-center">
                <img [src]="logo">
            </div>
            <P><strong>{{ myApplication.getText('registration') }}
                    <small class="ml-4"> <a href="" onClick="window.location.reload();">
                            <I>{{ myApplication.getText('Register.hintToLogin') }}</I>
                        </a>
                    </small>
                </strong></P>

            <ng-container *ngIf="!done">
                <app-text-field [id]="'Register.email'" [object]="register" [valueName]="'email'" [disable]="false">
                </app-text-field>

                <app-text-field [id]="'Register.emailConfirm'" [object]="register" [valueName]="'emailConfirm'"
                    [disable]="false">
                </app-text-field>

                <p class="nosea">
                    <label for="mail">tragen Sie hier bitte NICHTS ein:</label>
                    <input id="mail" name="mail" size="60" [(ngModel)]="register.mail" />
                </p>

                <p class="nosea">
                    <app-text-field [id]="'Register.uname'" [object]="register" [valueName]="'uname'" [disable]="false">
                    </app-text-field>
                </p>

                <label class="pw2" for="pw">Passwortfeld 2:</label>
                <input class="pw2" id="pw" name="pw" size="60" [(ngModel)]="register.pw" />

                <div class="form-row">
                    <div class="form-group col-sm-4">
                        <label class="col-form-label col-form-label-sm text-secondary"
                            for="pw">{{this.myApplication.getText("Register.password")}}</label>
                    </div>
                    <div class="col-sm-8">
                        <div class="input-group input-group-sm"
                            [ngbTooltip]="myApplication.getHelpText('Login.password')" placement="top"
                            [openDelay]="1000">
                            <input id="pw" class="form-control form-control-sm" type="password"
                                [(ngModel)]="register.password"
                                [placeholder]="myApplication.getHelpText('Login.password')">
                        </div>
                    </div>
                </div>

                <app-text-field [id]="'Register.company'" [object]="register" [valueName]="'company'" [disable]="false">
                </app-text-field>

                <app-select-field [id]="'Register.system'" [object]="register" [valueName]="'system'"
                    [keyValueList]="systems" [disable]="false">
                </app-select-field>

                <BR>
                <div>
                    <app-select-field [id]="'Register.foundVia'" [object]="register" [valueName]="'foundVia'"
                        [keyValueList]="foundVias">
                    </app-select-field>
                    <app-text-field [id]="'Register.foundViaTxt'" [object]="register" [valueName]="'foundViaTxt'">
                    </app-text-field>
                </div>
                <BR>

                <div class="form-row">
                    <div class="form-group col-sm-11">
                        <label class="col-form-label col-form-label-sm form-check-label text-secondary input-group"
                            for="u18">{{ myApplication.getText('Register.iAmOver18') }}&nbsp;
                        </label>
                    </div>
                    <div class="col-sm-1">
                        <div class="input-group input-group-sm">
                            <input class="form-check-input form-control-sm" type="checkbox"
                                [(ngModel)]="register.isOver18" id="over18">
                        </div>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-sm-11">
                        <label class="col-form-label col-form-label-sm form-check-label text-secondary input-group"
                            for="agbudp">{{ myApplication.getText('confirmPart1') }}&nbsp;
                            <a href="{{urlAGB}}" target="agb">{{
                                myApplication.getText('AGB') }}</a>&nbsp;{{ myApplication.getText('confirmPart2')
                            }}</label>
                    </div>
                    <div class="col-sm-1">
                        <div class="input-group input-group-sm">
                            <input class="form-check-input form-control-sm" type="checkbox" [(ngModel)]="register.agb"
                                id="agbudp">
                        </div>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-sm-11">
                        <label class="col-form-label col-form-label-sm form-check-label text-secondary input-group"
                            for="agbudp">{{ myApplication.getText('confirmPart1') }}&nbsp;
                            <a href="{{urlDataProtection}}" target="dp">{{
                                myApplication.getText('DP') }}</a>&nbsp;{{ myApplication.getText('confirmPart2')
                            }}</label>
                    </div>
                    <div class="col-sm-1">
                        <div class="input-group input-group-sm">
                            <input class="form-check-input form-control-sm" type="checkbox" [(ngModel)]="register.dp"
                                id="agbudp">
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="!wait">
                    <div class="d-flex justify-content-start col-form-label col-form-label-sm text-secondary border">
                        &nbsp;<b>{{myApplication.getText('Register.noCommitmentTextPart1')}}{{myApplication.getText('Register.noCommitmentTextPart2')}}</b>&nbsp;
                        {{myApplication.getText('Register.noCommitmentTextPart3')}}&nbsp;
                        <b>{{myApplication.getText('Register.noCommitmentTextPart4')}}</b>{{myApplication.getText('Register.noCommitmentTextPart5')}}&nbsp;
                    </div>
                    <div class="d-flex justify-content-end mt-3">
                        <ng-container *ngIf="promoCode != '524441324700416178'">
                            <app-button *ngIf="register.price < 20" [iconName]="'vial'" [id]="'Register.registerTest'"
                                [callback]="'registerNow'" [callbackOwner]="this" [disable]="false">
                            </app-button>
                        </ng-container>

                        <ng-container *ngIf="promoCode == '524441324700416178'">
                            <app-button *ngIf="register.price < 20" [iconName]="'vial'"
                                [id]="'Register.registerTest.promo1'" [callback]="'registerNow'" [callbackOwner]="this"
                                [disable]="false">
                            </app-button>
                        </ng-container>


                        <app-button *ngIf="register.price >= 20 && register.country != 'other'" [iconName]="'play'"
                            [id]="'Register.register'" [callback]="'registerNow'" [callbackOwner]="this"
                            [disable]="false">
                        </app-button>

                    </div>

                    <div *ngIf="register.price >= 20 && register.country == 'other'" class="text-secondary">
                        <B>{{ myApplication.getText('InfoOtherCountryRegistration') }}</B>
                    </div>
                </ng-container>

                <div *ngIf="wait" class="d-flex justify-content-end mt-3">
                    <div class="spinner-border spinner-border-xl mr-5" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>

            </ng-container>


            <div *ngIf="errorText != ''" class="form-group row mt-4 ml-1">
                <div class="alert alert-danger" id="registerMessage" role="alert">
                    <fa name="exclamation-triangle"></fa> {{errorText}}
                </div>
            </div>

            <div *ngIf="successText != ''" class="form-group row mt-4 ml-1">
                <div class="alert alert-success" id="registerMessageSuccess" role="alert">
                    <fa name="check-circle"></fa> {{successText}}
                </div>

                <button class="btn btn-link" onClick="window.location.reload();">{{ myApplication.getText('toLogin')
                    }}</button>
            </div>
        </div>
    </div>
</div>