import { AuthenticationService } from './authentication.service';
import { Http } from '@angular/http';
import { ApplicationService } from './application.service';
import { DAOAreas } from '../app-core/dao/dao-areas';
import { DAOBeds } from '../app-core/dao/dao-beds';
import { DAOParts } from '../app-core/dao/dao-parts';
import { DAOCultures } from '../app-core/dao/dao-cultures';
import { DAORecords } from '../app-core/dao/dao-records';
import { DAOSorts } from '../app-core/dao/dao-sorts';
import { BasicService } from './basic.service';
import { DAORepository } from '../app-core/dao/dao-repository';
import { DAOBedAssigns } from '../app-core/dao/dao-bed-assigns';
import { DAOTemplate } from '../app-core/dao/dao-template';
import { DAOToDos } from '../app-core/dao/dao-todos';
import { DAOPersons } from '../app-core/dao/dao-persons';
import { DAOSeeds } from '../app-core/dao/dao-seeds';
import { DAOFeedback } from '../app-core/dao/dao-feedback';
import { DAOSortSeeds } from '../app-core/dao/dao-sort-seeds';
import { interval } from 'rxjs';
import { DAOHarvest } from '../app-core/dao/dao-harvest';
import { RepositoryService } from './repository.service';
import { PartsService } from './parts.service';
import * as i0 from "@angular/core";
import * as i1 from "./authentication.service";
import * as i2 from "@angular/http";
import * as i3 from "./application.service";
import * as i4 from "./basic.service";
import * as i5 from "./repository.service";
import * as i6 from "./parts.service";
var DaoService = /** @class */ (function () {
    function DaoService(authenticationService, http, myApplication, basicService, repoService, partsService) {
        var _this = this;
        this.authenticationService = authenticationService;
        this.http = http;
        this.myApplication = myApplication;
        this.basicService = basicService;
        this.repoService = repoService;
        this.partsService = partsService;
        this.filesRead = 14;
        this.allRead = true;
        this.numRead = 14;
        this.initialized = false;
        this.saveQueue = new Array();
        this.inProgress = false;
        if (myApplication) {
            myApplication.setDAOService(this);
        }
        var saveCounter = interval(3000);
        // Subscribe to begin publishing values
        saveCounter.subscribe(function (n) {
            return _this.saveToBackend();
        });
    }
    DaoService.prototype.refreshDAOs = function (daos) {
        this.allRead = false;
        this.filesRead - daos.length;
        daos.forEach(function (dao) {
            dao.refresh();
        });
    };
    DaoService.prototype.saveToBackend = function (force) {
        if (!this.inProgress || force == true) {
            this.inProgress = true;
            var saveQueue = this.saveQueue;
            this.saveQueue = new Array();
            saveQueue.forEach(function (save) {
                save.save();
            });
            this.inProgress = false;
        }
    };
    DaoService.prototype.deleteRepository = function () {
        this.allDAOs.forEach(function (dao) {
            if (dao.name != 'feedback' && dao.name != 'customer') {
                dao.objects.forEach(function (object) {
                    dao.deleteObject(object);
                });
            }
        });
    };
    DaoService.prototype.getDAO = function (name) {
        var found = null;
        if (!this.allDAOs) {
            return null;
        }
        this.allDAOs.forEach(function (element) {
            if (element.name == name) {
                found = element;
            }
        });
        return found;
    };
    DaoService.prototype.update = function () {
        this.filesRead = this.filesRead + 1;
        if (this.filesRead == this.numRead) {
            this.allDAOs.forEach(function (dao) {
                dao.assembleObjects();
            });
            this.allDAOs.forEach(function (dao) {
                dao.objects.forEach(function (object) {
                    object.setInit(false);
                    object.initDone();
                });
            });
            this.allRead = true;
            //this.myApplication.repository = (<DAORepository>this.getDAO('repository')).getRepository();
        }
    };
    DaoService.prototype.reset = function () {
        this.initialized = false;
        this.filesRead = 0;
        this.allRead = false;
        this.allDAOs = null;
    };
    DaoService.prototype.save = function () {
        this.allDAOs.forEach(function (element) {
            element.save();
        });
    };
    DaoService.prototype.initialize = function () {
        if (!this.initialized) {
            this.allDAOs = new Array();
            this.initialized = true;
            this.initializeDAOs();
        }
    };
    DaoService.prototype.initializeDAOs = function () {
        var dao = new DAOAreas(this.http, this.authenticationService, 'areas', this.myApplication, this, this.basicService);
        this.assembleDAO(dao);
        dao = new DAOBeds(this.http, this.authenticationService, 'beds', this.myApplication, this, this.basicService);
        this.assembleDAO(dao);
        dao = new DAOBedAssigns(this.http, this.authenticationService, 'bedAssigns', this.myApplication, this, this.basicService);
        this.assembleDAO(dao);
        dao = new DAOCultures(this.http, this.authenticationService, 'cultures', this.myApplication, this, this.basicService);
        this.assembleDAO(dao);
        dao = new DAOParts(this.http, this.authenticationService, 'parts', this.myApplication, this, this.basicService);
        this.assembleDAO(dao);
        dao = new DAORecords(this.http, this.authenticationService, 'records', this.myApplication, this, this.basicService, this.partsService);
        this.assembleDAO(dao);
        dao = new DAOSorts(this.http, this.authenticationService, 'sorts', this.myApplication, this, this.basicService);
        this.assembleDAO(dao);
        dao = new DAORepository(this.http, this.authenticationService, 'repository', this.myApplication, this, this.basicService);
        this.assembleDAO(dao);
        dao = new DAOTemplate(this.http, this.authenticationService, 'template', this.myApplication, this, this.basicService);
        this.assembleDAO(dao);
        dao = new DAOToDos(this.http, this.authenticationService, 'todos', this.myApplication, this, this.basicService);
        this.assembleDAO(dao);
        dao = new DAOPersons(this.http, this.authenticationService, 'persons', this.myApplication, this, this.basicService);
        this.assembleDAO(dao);
        dao = new DAOSeeds(this.http, this.authenticationService, 'seeds', this.myApplication, this, this.basicService);
        this.assembleDAO(dao);
        // dao = new DAOCustomer(this.http,
        //   this.authenticationService, 'customer', this.myApplication, this, this.basicService);
        // this.assembleDAO(dao);
        dao = new DAOSortSeeds(this.http, this.authenticationService, 'sortSeeds', this.myApplication, this, this.basicService);
        this.assembleDAO(dao);
        dao = new DAOHarvest(this.http, this.authenticationService, 'harvests', this.myApplication, this, this.basicService);
        this.assembleDAO(dao);
        dao = new DAOFeedback(this.http, this.authenticationService, 'feedback', this.myApplication, this, this.basicService);
        this.allDAOs.push(dao);
        //not relevant
    };
    DaoService.prototype.assembleDAO = function (dao) {
        dao.addObserver(this);
        this.allDAOs.push(dao);
        // dao.readData();
    };
    DaoService.ngInjectableDef = i0.defineInjectable({ factory: function DaoService_Factory() { return new DaoService(i0.inject(i1.AuthenticationService), i0.inject(i2.Http), i0.inject(i3.ApplicationService), i0.inject(i4.BasicService), i0.inject(i5.RepositoryService), i0.inject(i6.PartsService)); }, token: DaoService, providedIn: "root" });
    return DaoService;
}());
export { DaoService };
