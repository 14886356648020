import { Save } from './save';


export class SaveDelete extends Save {

    save() {
        this.http.delete(this.route, this.options).pipe(
            //map(resp => resp.json())
        )
            .subscribe(
                () => {

                },
                err => {
                    console.log('There was an error deleting ressource ' + this.route + '.', err);
                }
            );
    }
}