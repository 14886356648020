import { OnInit } from '@angular/core';
import { FieldTextDTO } from 'src/app/app-core/dto/field-text-dto';
import { ApplicationService } from 'src/app/services/application.service';
var NumberUnitSelectFieldComponent = /** @class */ (function () {
    function NumberUnitSelectFieldComponent(myApplication) {
        this.myApplication = myApplication;
        this.colsLabel = 'col-sm-4';
        this.colsField = 'col-sm-8';
        this.step = 0.1;
        this.min = 0;
        this.useWarningClass = false;
        this.btnClass = this.myApplication.theme.btnOutline;
        this._disable = false;
    }
    Object.defineProperty(NumberUnitSelectFieldComponent.prototype, "disable", {
        get: function () {
            return this._disable;
        },
        set: function (value) {
            this._disable = value;
        },
        enumerable: true,
        configurable: true
    });
    NumberUnitSelectFieldComponent.prototype.onBlur = function (event) {
        event.target.value = this.value;
    };
    Object.defineProperty(NumberUnitSelectFieldComponent.prototype, "value", {
        get: function () {
            var value = this.object[this.valueName.toString()].toString();
            value = value.replace('.', ',');
            return value;
        },
        set: function (value) {
            value = value.replace(new RegExp(',', 'g'), '.');
            var num = Number(value);
            if ((!isNaN(num))) {
                this.object[this.valueName.toString()] = num;
            }
        },
        enumerable: true,
        configurable: true
    });
    NumberUnitSelectFieldComponent.prototype.getWarningClass = function () {
        if (this.useWarningClass && this.object[this.valueName.toString()] == 0) {
            return 'border-warning';
        }
    };
    NumberUnitSelectFieldComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.id) {
            this.id = this.valueName;
        }
        this.fieldDTO = this.myApplication.textDTOs.get(this.id);
        if (!this.fieldDTO) {
            this.fieldDTO = new FieldTextDTO(this.id, this.id, '');
        }
        this.btnDTO = this.myApplication.textDTOs.get(this.id + '.btnTooltip');
        this.keyValueList.forEach(function (element) {
            if (!element.value || element.value == '') {
                var textDTO = _this.myApplication.textDTOs.get(_this.id + '.' + element.key);
                if (textDTO) {
                    element.value = textDTO.text;
                }
                else {
                    element.value = _this.id + '.' + element.key;
                }
            }
        });
    };
    NumberUnitSelectFieldComponent.prototype.onClick = function () {
        this.object[this.btnMethod.toString()]();
    };
    return NumberUnitSelectFieldComponent;
}());
export { NumberUnitSelectFieldComponent };
