import { Workbook, Worksheet } from 'exceljs';
import * as fs from 'file-saver';
import { ApplicationService } from 'src/app/services/application.service';
import { Constants } from './constants';
import { NeedsCalculator } from './needs-calculator';
import { NeedsCalculatorPlants } from './needs-calculator-plants';


export class SeedsExcel {


  constructor(private myApplication: ApplicationService) {

  }

  buildHeaderSeeds(sheet: Worksheet, otherUnit: boolean): Worksheet {
    const title = this.myApplication.getText('Seed.needed') + " - " + this.myApplication.year;
    const myFont: String = 'Calibri';

    let header: Array<String> = new Array();
    if (!otherUnit) {
      header.push(this.myApplication.getText('Culture.name'));
      header.push(this.myApplication.getText('RecordsCreateDTO.sort'));
      header.push(this.myApplication.getText('Seed.need') + ' ' + this.myApplication.getText('Unit.Korn'));
      header.push(this.myApplication.getText('Seed.tkg') + ' ' + Constants.UNIT_GRAMM);
      header.push(this.myApplication.getText('Seed.weight') + ' ' + Constants.UNIT_GRAMM);
      header.push(this.myApplication.getText('Seed.existingWeight') + ' ' + Constants.UNIT_GRAMM);
      header.push(this.myApplication.getText('Seed.existingWeight') + ' ' + this.myApplication.getText('Unit.Korn'));
      header.push(this.myApplication.getText('Seed.buyQuantity'));
      header.push(this.myApplication.getText('Seed.buyWeight') + ' ' + Constants.UNIT_GRAMM);
      header.push(this.myApplication.getText('Seed.vendor'));
      header.push(this.myApplication.getText('Seed.hint'));
    } else {

      header.push(this.myApplication.getText('Culture.name'));
      header.push(this.myApplication.getText('RecordsCreateDTO.sort'));
      header.push(this.myApplication.getText('Seed.seedValue'));
      header.push(this.myApplication.getText('ToDo.quantityUnit'));
      header.push(this.myApplication.getText('Seed.need'));
      header.push(this.myApplication.getText('ToDo.quantityUnit'));
      header.push(this.myApplication.getText('Seed.weight'));
      header.push(this.myApplication.getText('ToDo.quantityUnit'));
      header.push(this.myApplication.getText('Seed.existingWeight'));
      header.push(this.myApplication.getText('ToDo.quantityUnit'));
      header.push(this.myApplication.getText('Seed.buyWeight'));
      header.push(this.myApplication.getText('ToDo.quantityUnit'));
      header.push(this.myApplication.getText('Seed.vendor'));
      header.push(this.myApplication.getText('Seed.hint'));
    }

    if (!otherUnit) {
      // Ueberschrift und formatieren
      let titleRow = sheet.addRow([title]);
    } else {
      let titleRow = sheet.addRow(' ');
      titleRow = sheet.addRow([this.myApplication.getText('Sort.otherUnit')]);
    }

    // Kopfzeile schreiben und formatieren
    let headerRow = sheet.addRow(header);
    // sheet.mergeCells(2, 1, 2, 10);

    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF28a745' },
        bgColor: { argb: 'FFFFFFFF' },
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } },
        cell.font = { color: { argb: "FFFFFFFF" }, name: myFont.toString() }

    });
    return sheet;

  }

  buildHeaderPlantBuy(sheet: Worksheet, perDay: boolean): Worksheet {
    const title = this.myApplication.getText('PlantBuy') + " - " + this.myApplication.year;
    const myFont: String = 'Calibri';

    let header: Array<String> = new Array();
    header.push(this.myApplication.getText('Culture.name'));
    header.push(this.myApplication.getText('RecordsCreateDTO.sort'));
    header.push(this.myApplication.getText('Plant.quantity'));
    header.push(this.myApplication.getText('Export.numBoxPlants'));
    header.push(this.myApplication.getText('Export.numBoxPlants') + ' (' +
      this.myApplication.getText('notRounded') + ')');
    header.push(this.myApplication.getText('CalendarWeek'));
    if (perDay) {
      header.push(this.myApplication.getText('Seed.date'));
    }

    // Ueberschrift und formatieren
    let titleRow = sheet.addRow([title]);

    // Kopfzeile schreiben und formatieren
    let headerRow = sheet.addRow(header);
    //  sheet.mergeCells(2, 1, 2, 5);

    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF28a745' },
        bgColor: { argb: 'FFFFFFFF' },
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } },
        cell.font = { color: { argb: "FFFFFFFF" }, name: myFont.toString() }

    });
    return sheet;
  }


  buildDataSeeds(sheet: Worksheet, needsCalculator: NeedsCalculator, otherUnit: boolean): Worksheet {
    if (!otherUnit) {
      needsCalculator.seedNeeds.forEach(need => {
        let data: Array<any> = new Array();
        data.push(need.name);
        data.push(need.sort);
        data.push(need.quantity);
        data.push(need.tkg);
        data.push(need.weight);
        data.push(need.existingWeight);
        data.push(need.existingQuantity);
        data.push(need.buyQuantity);
        data.push(need.buyWeight);
        data.push(need.vendor);
        data.push(need.hint);
        //Satz schreiben und formatieren
        let dataRow = sheet.addRow(data);

        //Spalte 7 und 8 bei TKG hat Korn als Inhalt, Nachkommastellen sind hier sinnfrei
        let cell = dataRow.getCell(7);
        cell.numFmt = '#,##0';
        cell = dataRow.getCell(8);
        cell.numFmt = '#,##0';
      });
    } else {
      needsCalculator.seedNeedsOtherUnit.forEach(need => {
        let data: Array<any> = new Array();
        data.push(need.name);
        data.push(need.sort);
        data.push(need.seedValue);
        data.push(this.myApplication.getText('ToDo.quantity.' + need.seedUnit));
        data.push(need.quantity);
        data.push('m²');
        data.push(need.weight);
        data.push(this.myApplication.getText('Unit.' + need.weightUnit));
        data.push(need.existingWeight);
        data.push(this.myApplication.getText('Unit.' + need.existingWeightUnit));
        data.push(need.buyWeight);
        data.push(this.myApplication.getText('Unit.' + need.weightUnit));
        data.push(need.vendor);
        data.push(need.hint);
        //Satz schreiben und formatieren
        let dataRow = sheet.addRow(data);
      });
    }
    return sheet;
  }

  buildDataPlantBuy(sheet: Worksheet, needsCalculatorPlants: NeedsCalculatorPlants, perDay: boolean): Worksheet {

    needsCalculatorPlants.plantsNeeds.forEach(need => {
      let data: Array<any> = new Array();
      data.push(need.name);
      data.push(need.sort);
      data.push(need.quantity);
      data.push(need.numBoxes);
      data.push(need.numBoxesUnrounded);
      data.push(need.week);
      if (perDay) {
        data.push(need.date.getDate() + '.' + (need.date.getMonth() + 1) + '.' + need.date.getFullYear());
      }

      //Satz schreiben und formatieren
      let dataRow = sheet.addRow(data);

    });

    return sheet;
  }


  buildColSeeds(sheet: Worksheet): Worksheet {
    const myFont: String = 'Calibri';

    // Spaltenbreite festlegen
    for (let i = 1; i <= 2; i++) {
      let col = sheet.getColumn(i);
      col.header = ' ';
      col.width = 20;
      col.style = { font: { bold: false, name: myFont.toString() } };
    }
    for (let i = 3; i <= 14; i++) {
      let col = sheet.getColumn(i);
      col.header = ' ';
      col.width = 15;
      col.style = { font: { bold: false, name: myFont.toString() } };
    }

    sheet.getColumn(3).numFmt = '#,##0';
    sheet.getColumn(4).numFmt = '#,##0.000';
    sheet.getColumn(5).numFmt = '#,##0.000';
    sheet.getColumn(6).numFmt = '#,##0.000';
    sheet.getColumn(7).numFmt = '#,##0.000';
    sheet.getColumn(8).numFmt = '#,##0.000';
    sheet.getColumn(9).numFmt = '#,##0.000';
    sheet.getColumn(11).numFmt = '#,##0.000';
    return sheet;

  }

  buildColPlantBuy(sheet: Worksheet): Worksheet {
    const myFont: String = 'Calibri';
    // Spaltenbreite festlegen
    for (let i = 1; i <= 6; i++) {
      let col = sheet.getColumn(i);
      col.header = ' ';
      col.width = 25;
      col.style = { font: { bold: false, name: myFont.toString() } };
    }

    sheet.getColumn(3).numFmt = '#,##0';
    sheet.getColumn(4).numFmt = '#,##0';
    sheet.getColumn(5).numFmt = '#,##0.00';
    sheet.getColumn(6).numFmt = '#,##0';

    return sheet;
  }

  checkIfNecessary(needsCalculator: NeedsCalculator): boolean {
    if (needsCalculator.seedNeedsOtherUnit && needsCalculator.seedNeedsOtherUnit.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  export(needsCalculator: NeedsCalculator, needsCalculatorPlants: NeedsCalculatorPlants, perDay: boolean) {
    var workbook = new Workbook();
    // 1. Blatt aufbauen
    var sheet = workbook.addWorksheet(
      this.myApplication.getText('Seed.needed') + " - " + this.myApplication.year,
      { properties: { tabColor: { argb: 'FFC0000' } } });
    sheet.views = [
      { state: 'frozen', ySplit: 3 }
    ];

    sheet = this.buildColSeeds(sheet);
    sheet = this.buildHeaderSeeds(sheet, false);
    sheet = this.buildDataSeeds(sheet, needsCalculator, false);
    if (this.checkIfNecessary(needsCalculator)) {
      sheet = this.buildHeaderSeeds(sheet, true);
      sheet = this.buildDataSeeds(sheet, needsCalculator, true);
    }

    // 2. Blatt aufbauen
    var sheet_p = workbook.addWorksheet(
      this.myApplication.getText('PlantBuy') + " - " + this.myApplication.year,
      { properties: { tabColor: { argb: 'FFC0000' } } });
    sheet_p.views = [
      { state: 'frozen', ySplit: 3 }
    ];

    sheet_p = this.buildColPlantBuy(sheet_p);
    sheet_p = this.buildHeaderPlantBuy(sheet_p, perDay);

    sheet_p = this.buildDataPlantBuy(sheet_p, needsCalculatorPlants, perDay);


    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, this.myApplication.getText('Seed.title', this.myApplication.year) + '.xlsx');
    });
  }
}