import { BedAssign } from './bed-assign';
import { DAOBedAssigns } from '../dao/dao-bed-assigns';
import { Bed } from './bed';
import { Record } from './record';
import { RecordAssignDTO } from '../dto/record-assign-dto';
import { Observable } from '../technical-aspects/observable';
import { Area } from './area';
import { ApplicationService } from 'src/app/services/application.service';
import { EventHandler } from '../technical-aspects/event-handler';


export class BedAssignList extends Observable {

    public bedAssigns = new Array<BedAssign>();


    public constructor(public parentId: String, public record: Record, public daoBedAssign: DAOBedAssigns, myApplication: ApplicationService) {
        super();
        this.buildList();

        this.handleRecordDatesChanged();
        myApplication.eventHandler.register(EventHandler.EVENT_RECORD_DATES_CHANGED, this);
    }

    handleRecordDatesChanged() {
        this.bedAssigns.forEach(bedAssign => {
            this.setTS(bedAssign);
        });
    }

    private setTS(bedAssign: BedAssign) {
        bedAssign.startTS = this.record.recordPeriods.growDate.getTime() / 1000;
        bedAssign.cultureEndTS = this.record.recordPeriods.getCultureEnd().getTime() / 1000;
    }

    public deleteAll() {
        this.bedAssigns.forEach(element => {
            this.daoBedAssign.deleteObject(element);
        });
        this.bedAssigns = new Array<BedAssign>();
    }

    public getAreas(): Array<Area> {
        let areas = new Array<Area>();
        this.bedAssigns.forEach(bedAssign => {
            let area = bedAssign.getArea();
            if (area && !this.areaExists(area, areas)) {
                areas.push(area);
            }
        });
        return areas;
    }

    private areaExists(area: Area, areas: Array<Area>): boolean {
        for (let i = 0; i < areas.length; i++) {
            if (area == areas[i]) {
                return true;
            }
        }
        return false;
    }

    public removeAllBedAssigns() {
        this.bedAssigns.forEach(element => {
            this.daoBedAssign.deleteObject(element);
            (<BedAssign>element).bed.buildRecordSet();
            this.record.periodsUIAreaBed = null;
        });
        this.buildList();
    }

    public isOnBed(bedId: String): boolean {
        let found = false;
        this.bedAssigns.forEach(bedAssign => {
            if (bedAssign.bedId == bedId) {
                found = true;
            }
        });
        return found;
    }

    public isOnArea(areaId: String): boolean {
        let found = false;
        this.bedAssigns.forEach(bedAssign => {
            if (bedAssign.getArea().id == areaId) {
                found = true;
            }
        });
        return found;
    }

    public getAssignment(bed: Bed): BedAssign {
        let assignment;
        for (let i = 0; i < this.bedAssigns.length; i++) {
            assignment = this.bedAssigns[i];
            if (assignment.bedId == bed.id) {
                return assignment;
            }
        }
        return null;
    }

    public getPositionOnBed(bedId: String): number {
        let position = -1;
        this.bedAssigns.forEach(bedAssign => {
            if (bedAssign.bedId == bedId) {
                position = bedAssign.positionOnBed;
            }
        });
        return position;
    }

    public getLengthOnBed(bedId: String): number {
        let length = -1;
        this.bedAssigns.forEach(bedAssign => {
            if (bedAssign.bedId == bedId) {
                length = bedAssign.lengthOnBed;
            }
        });
        return length;
    }

    protected add(bedAssign: BedAssign) {
        bedAssign.record = this.record;
        this.bedAssigns.push(bedAssign);
    }

    protected plantOnBed(bed: Bed, positionOnBed: number, lengthOnBed: number, record: Record, planForward: boolean) {
        let bedAssign = this.getAssignment(bed);
        let neu = false;
        if (bedAssign == null) {
            bedAssign = <BedAssign>this.daoBedAssign.createNewInstance();
            this.add(bedAssign);
            neu = true;
        }
        bedAssign.parentId = record.id;
        bedAssign.record = record;
        bedAssign.bedId = bed.id;
        this.setTS(bedAssign);

        if (neu) {
            if (planForward) {
                bedAssign.positionOnBed = positionOnBed;
            } else {
                bedAssign.positionOnBed = positionOnBed - lengthOnBed + 1;
                if (bedAssign.positionOnBed < 1) {
                    bedAssign.positionOnBed = 1;
                }
            }
        }
        bedAssign.lengthOnBed = bedAssign.lengthOnBed + lengthOnBed;
        if (bedAssign.positionOnBed + bedAssign.lengthOnBed - 1 > bed.length) {
            bedAssign.lengthOnBed = bed.length - bedAssign.positionOnBed + 1;
        }
        this.record.periodsUIAreaBed = null;
        bed.buildRecordSet();
    }


    public plant(recordAssignDTO: RecordAssignDTO) {
        let bed = recordAssignDTO.getStartBed();
        if (!bed) {
            this.record.myApplication.messageDTO.setErrorWithId('FM.noStartBedFound');
            return;
        }
        this.record.areaId = bed.areaId;
        let notPlannedLength = this.getLengthUnPlanned();
        let positionOnBed = recordAssignDTO.positionOnBed;

        if (positionOnBed > bed.length) {
            this.record.myApplication.messageDTO.setErrorWithId('FM.bedFull', bed.length);
            return;
        }

        while (notPlannedLength > 0 && bed) {
            let freeOnBed = this.calculateFreeSpaceOnBed(bed, positionOnBed, recordAssignDTO.planForward);
            let planOnBed = notPlannedLength;
            if (notPlannedLength > freeOnBed) {
                planOnBed = freeOnBed;
            }
            this.plantOnBed(bed, positionOnBed, planOnBed, this.record, recordAssignDTO.planForward);
            if (!this.isFullPlanned() && recordAssignDTO.planNext) {
                notPlannedLength = this.getLengthUnPlanned();
                if (recordAssignDTO.planForward) {
                    positionOnBed = 1;
                    bed = bed.getNextBedOnArea();
                } else {
                    bed = bed.getPreviousBedOnArea();
                    positionOnBed = bed.length;
                }
            } else {
                notPlannedLength = 0;
                bed = null;
            }
        }

        if (this.getLengthUnPlanned() > 0) {
            this.record.myApplication.messageDTO.setWarningWithId('FM.bedMToBig');
        }
    }

    public getLengthUnPlanned(): number {
        return this.record.lengthOnBed - this.getLengthPlanned();
    }

    protected calculateFreeSpaceOnBed(bed: Bed, positionOnBed: number, planForward: boolean): number {
        console.log(planForward);
        if (planForward) {
            return bed.length - positionOnBed + 1;
        } else {
            return positionOnBed;
        }
    }

    protected delete(bedAssign: BedAssign) {
        this.daoBedAssign.deleteObject(bedAssign);
    }

    public removeFromBed(bedId: String) {
        let bed;
        this.bedAssigns.forEach(bedAssign => {
            if (bedAssign.bedId == bedId) {
                bed = bedAssign.bed;
                this.daoBedAssign.deleteObject(bedAssign);
            }
        });
        this.buildList();
        bed.buildRecordSet();
    }

    protected buildList() {
        this.bedAssigns = new Array<BedAssign>();
        this.daoBedAssign.objects.forEach(element => {
            let bedAssign = <BedAssign>element;
            if (bedAssign.parentId == this.parentId) {
                this.add(bedAssign);
            }
        });
    }

    public isFullPlanned(): boolean {
        let sum = this.getLengthPlanned();

        if (sum >= this.record.lengthOnBed && this.record.lengthOnBed > 0) {
            return true;
        } else {
            return false;
        }
    }

    public getLengthPlanned(): number {
        let sum = 0;

        this.bedAssigns.forEach(bedAssign => {
            sum = sum + bedAssign.lengthOnBed;
        });
        return sum;
    }

    isAnywherePlanned(): boolean {
        if (this.bedAssigns.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    moveToBed(from: Bed, to: Bed): boolean {
        let assignment = this.getAssignment(from);
        if (!assignment) {
            return true;
        }
        if (this.fitsOnBed(assignment, to)) {
            assignment.bedId = to.id;
            this.buildList();
            from.buildRecordSet();
            to.buildRecordSet();
            return true;
        }
        return false;
    }

    private fitsOnBed(assignment: BedAssign, to: Bed): boolean {
        return (to.length >= (assignment.positionOnBed + assignment.lengthOnBed - 1));
    }
}