import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';
import { Constants } from '../business-logic/constants';


export class TemplateEO extends AbstractEntityObject{

    public parentId: String;
    public predefType: String;
    public type: String;
    public typeId: String;
    
    public name: String;
    public quantity: number;
    public quantityUnit: String;
    
    public time: number;
    public timeUnit: String;
    public timeType: String;
    public timeReference: String;
    public color: String;
    
    public note: String;
    public active: boolean;
    public isModel: boolean;

    public isSeries: boolean;
    public seriesIntervall: number;
    public seriesIntervallUnit: String; 

    public endTime: number;
    public endTimeUnit: String;
    public endTimeType: String;
    public endTimeReference: String;
    public endDueDate: Date;


    constructor() {
        super();

        this.parentId = '';
        this.predefType = '';
        this.type = '';
        this.typeId = '';
        
        this.name = '';
        this.quantity = 0;
        this.quantityUnit = '';

        this.time = 0;
        this.timeUnit = 'Tage';
        this.timeType = 'n';
        this.timeReference = 'w';
        this.color = '';

        this.note = '';
        this.active = true;
        this.isModel = false;

        this.isSeries = false;
        this.seriesIntervall = 0;
        this.seriesIntervallUnit = 'T';
        this.endTime = 0;
        this.endTimeUnit = 'Tage';
        this.endTimeType = 'v';
        this.endTimeReference = Constants.TEMPLATE_TIME_REF_ERNTE;
      //  this.endDueDate = new Date();
    }
  }