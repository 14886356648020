import { Injectable } from '@angular/core';
import { Http, RequestOptions } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  data;
  public dataReady: boolean = false;

  constructor(private authenticationService: AuthenticationService, private http: Http) { }

  readData() {
    this.dataReady = false;
    const options = new RequestOptions();
    options.headers = this.authenticationService.getHeadersAuthenticated();

    let year = new Date().getFullYear();
    this.http.get(environment.apiV2 + 'dashboardData/' + year, options).pipe(
      map(resp => resp.json())
    )
      .subscribe(result => {
        this.data = result;
        this.dataReady = true;
      });
  }
}
