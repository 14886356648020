import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LoginDTO } from '../app-core/dto/login-dto';
import { Http, RequestOptions, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { ChangePWDTO } from '../app-core/dto/change-pw-dto';
import { ApplicationService } from './application.service';
import { InvitationDTO } from '../app-core/dto/invitation-dto';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RepositoryChooseComponent } from '../ui-components/repository-choose/repository-choose.component';
import { AgreementDTO } from '../app-core/dto/agreement-dto';
import { CookieService } from 'ngx-cookie-service';
import { AppInitializerService } from './app-initializer.service';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public environment;

  public isOK = false;
  public isInCheck = true;
  public token: String;
  public loginData: LoginDTO;
  public errorText: String;
  public successText: String;
  public inLoginProcess = false;
  private roles: Array<String> = new Array();
  public possibleLogins;
  public needsAgreement = false;

  public inChangeProcess = false;


  constructor(private http: Http, private myApplication: ApplicationService, private modalService: NgbModal,
    private cookieService: CookieService) {

  }

  public hasRole(roleName: String): boolean {
    let i = 0;
    let role = this.roles[i];

    while (role) {
      if (role == roleName) {
        return true;
      }
      i = i + 1;
      role = this.roles[i];
    }
    return false;
  }

  public readTokenFromLocal(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let login = new Object();
      login['token'] = this.cookieService.get('Gemuese-Anbauplaner.token');
      login['repid'] = this.cookieService.get('Gemuese-Anbauplaner.repid');
      if (login['token']) {
        this.validateToken(login['token']).then((validation: any) => {
          if (!validation) {
            this.logout();
            reject();
            return;
          }

          this.loginData = new LoginDTO();
          this.loginData.uname = this.cookieService.get('Gemuese-Anbauplaner.uname');
          this.setRepository(login);
          resolve();
        });
      } else {
        reject();
      }
    });
  }

  // public set daoService(value: DaoService) {
  //   this._daoService = value;

  //   let login = new Object();
  //   login['token'] = this.cookieService.get('Gemuese-Anbauplaner.token');
  //   login['repid'] = this.cookieService.get('Gemuese-Anbauplaner.repid');
  //   if (login['token']) {
  //     this.validateToken(login['token']).then((validation: any) => {
  //       if (!validation) {
  //         this.logout();
  //         return;
  //       }

  //       this.loginData = new LoginDTO();
  //       this.loginData.uname = this.cookieService.get('Gemuese-Anbauplaner.uname');
  //       this.setRepository(login);
  //       this.isInCheck = false;

  //       // this.refreshToken();
  //     });
  //   } else {
  //     this.isInCheck = false;
  //   }
  // }

  public getHeaders(): Headers {
    let header = new Headers({
      'Content-Type': 'application/json'
    });
    return header;
  }

  public getHeadersAuthenticated(): Headers {
    let header = new Headers({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    });
    return header;
  }

  validateToken(token: string) {
    return new Promise((resolve, reject) => {
      const options = new RequestOptions();
      let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      });
      options.headers = header;
      this.http.get(environment.apiV2 + 'tokenValidation', options).pipe(
        map(resp => resp.json())
      )
        .subscribe(
          res => {
            resolve(res.validation);
          },
          err => {
            resolve(false);
          });
    });
  }

  refreshToken() {
    return new Promise((resolve, reject) => {
      const options = new RequestOptions();
      let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });
      options.headers = header;
      this.http.patch(environment.apiV2 + 'token', null, options).pipe(
        map(resp => resp.json())
      )
        .subscribe(
          res => {
            resolve(res.newToken);
          },
          err => {
            // this.logout();
          });
    });
  }

  logout() {
    localStorage.removeItem('tokenV2');

    this.cookieService.delete('Gemuese-Anbauplaner.repid', '/');
    this.cookieService.delete('Gemuese-Anbauplaner.token', '/');
    this.cookieService.delete('Gemuese-Anbauplaner.uname', '/');

    this.cookieService.delete('Gemuese-Anbauplaner.repid', '/test');
    this.cookieService.delete('Gemuese-Anbauplaner.token', '/test');
    this.cookieService.delete('Gemuese-Anbauplaner.uname', '/test');

    this.cookieService.delete('Gemuese-Anbauplaner.repid', '/cons');
    this.cookieService.delete('Gemuese-Anbauplaner.token', '/cons');
    this.cookieService.delete('Gemuese-Anbauplaner.uname', '/cons');

    this.cookieService.delete('Gemuese-Anbauplaner.repid', '/app');
    this.cookieService.delete('Gemuese-Anbauplaner.token', '/app');
    this.cookieService.delete('Gemuese-Anbauplaner.uname', '/app');

    let options = new RequestOptions();
    options.headers = this.getHeadersAuthenticated();
    if (this.loginData) {
      this.http.delete(environment.apiV2 + 'login', options).pipe()
        .subscribe(
          res => {
          },
          err => {
            //this.errorText = err; egal
          }
        );
    }

    this.isOK = false;
    this.token = null;
    this.isInCheck = false;
  }

  sendAgreement(agreementDTO: AgreementDTO) {
    let options = new RequestOptions();
    options.headers = this.getHeadersAuthenticated();
    let payload = JSON.stringify(agreementDTO);
    this.errorText = "";
    this.http.post(environment.apiV2 + 'agreement', payload, options).pipe(
      map(resp => resp.json())
    )
      .subscribe(
        res => {
          if (!res['error']) {
            this.myApplication.messageDTO.setSuccessWithId('ThanksALot');
          } else {

          }
        },
        err => {
          this.errorText = err;
          console.log(err);
        }
      );
  }

  userActivation(id: String) {
    let options = new RequestOptions();
    options.headers = this.getHeaders();
    let payload = JSON.stringify(new InvitationDTO(id));
    this.errorText = "";
    this.http.post(environment.apiV2 + 'userActivation', payload, options).pipe(
      map(resp => resp.json())
    )
      .subscribe(
        res => {
          if (!res['error']) {
            this.successText = this.myApplication.getTextSimple('UserHasBeenActivated');
          } else {
            this.errorText = this.myApplication.getTextSimple('FM.UserHasNotBeenActivated');
          }
        },
        err => {
          this.errorText = err;
        }
      );
  }

  invitationAccept(id: String) {
   let options = new RequestOptions();
   options.headers = this.getHeaders();
    let payload = JSON.stringify(new InvitationDTO(id));
    this.errorText = "";
    this.http.post(environment.apiV2 + 'invitationAcceptance', payload, options).pipe(
      map(resp => resp.json())
    )
      .subscribe(
        res => {
          if (!res['error']) {
            this.myApplication.messageDTO.setSuccessWithId('InvitationAccepted');
          } else {
            this.myApplication.messageDTO.setErrorWithId('FM.InvitationNotAccepted');
          }
        },
        err => {
          this.errorText = err;
        }
      );
  }

  sendInvitation(uname: String) {
    let login: LoginDTO = new LoginDTO();
    login.uname = uname;
    let options = new RequestOptions();
    options.headers = this.getHeadersAuthenticated();

    let payload = JSON.stringify(login);
    this.errorText = "";
    this.http.post(environment.apiV2 + 'invitation', payload, options).pipe(
      map(resp => resp.json())
    )
      .subscribe(
        res => {
          if (!res['error']) {
            this.myApplication.messageDTO.setSuccessWithId('InvitationSent');
          } else {
            this.myApplication.messageDTO.setErrorWithId('FM.InvitationNotSent');
          }
        },
        err => {
          this.errorText = err;
        }
      );
  }

  pwReset(login: LoginDTO) {
    this.successText = null;
    let options = new RequestOptions();
    options.headers = this.getHeaders();
    let payload = JSON.stringify(login);
    this.errorText = "";
    this.http.post(environment.apiV2 + 'passwordReset', payload, options).pipe(
      map(resp => resp.json())
    )
      .subscribe(
        res => {
          if (!res['error']) {
            this.successText = this.myApplication.getTextSimple('PWForgotOK');
          } else {
            this.errorText = res['message'];
          }
          if (this.loginData) {
            this.loginData.password = '';
          }
        },
        err => {
          this.errorText = err;
          if (this.loginData) {
            this.loginData.password = '';
          }
        }
      );
  }

  sendActivationLink(login: LoginDTO) {
    if (!login.uname || login.uname.length == 0) {
      this.errorText = this.myApplication.getTextSimple('FM.insertEMail');
      return;
    }
    let options = new RequestOptions();
    options.headers = this.getHeaders();
    let payload = JSON.stringify(login);
    this.errorText = "";
    this.http.post(environment.apiV2 + 'activationMail', payload, options).pipe(
      map(resp => resp.json())
    )
      .subscribe(
        res => {
          this.successText = this.myApplication.getTextSimple('ActivationMail.sent');
        },
        err => {
          this.errorText = this.myApplication.getTextSimple('FM.activationMail');
        }
      );
  }

  login(login: LoginDTO) {
    return new Promise((resolve, reject) => {
      localStorage.removeItem('tokenV2');

      this.successText = null;
      this.inLoginProcess = true;
      this.loginData = login;
      let options = new RequestOptions();
      options.headers = this.getHeaders();

      let payload = JSON.stringify(login);
      this.errorText = "";
      this.http.post(environment.apiV2 + 'login', payload, options).pipe(
        map(resp => resp.json())
      )
        .subscribe(
          res => {
            if (res['error']) {
              this.errorText = res['message'];
              this.inLoginProcess = false;
              this.loginData.password = '';
              return;
            }

            this.token = res['token'];

            //permissions übernehmen
            // let roleList = res['permissions'];
            // let i = 0;
            // let role = roleList[0];

            // while (role) {
            //   this.roles.push(role['roleName']);
            //   i = i + 1;
            //   role = roleList[i];
            // }

            this.possibleLogins = res['repositorys'];

            this.needsAgreement = false;
            if (res['needsAgreement']) {
              this.needsAgreement = true;
            }

            if (res['language'] && res['language'] != this.myApplication.selectedLanguage) {
              this.myApplication.changeLanguage(res['language']);
            }

            if (this.possibleLogins.length > 1) {
              this.inLoginProcess = false;
              this.loginData.password = '';
              
              const modalRef = this.modalService.open(RepositoryChooseComponent);
              (<RepositoryChooseComponent>modalRef.componentInstance).possibleRepositorys = this.possibleLogins;
              (<RepositoryChooseComponent>modalRef.componentInstance).authenticationService = this;
              (<RepositoryChooseComponent>modalRef.componentInstance).resolve = resolve;
              return;
            }

            if (this.possibleLogins.length == 1) {
              this.setRepository(this.possibleLogins[0]);
              this.inLoginProcess = false;
              this.loginData.password = '';

              resolve(null);
            }
            reject();
          },
          err => {
            this.errorText = err;
            this.inLoginProcess = false;
            this.loginData.password = '';
            reject();
          }
        );
    });
  }

  setRepository(login) {
    localStorage.setItem('tokenV2', login.token);

    this.cookieService.set('Gemuese-Anbauplaner.repid', login.repid, 3);
    this.cookieService.set('Gemuese-Anbauplaner.token', login.token, 3);
    this.cookieService.set('Gemuese-Anbauplaner.uname', this.loginData.uname.toString(), 3);

    if (localStorage.getItem('tokenV2') != login.token) {
      localStorage.clear();
      localStorage.setItem('tokenV2', login.token);
    }

    this.loginData.id = login.repid;
    this.token = login.token;
    this.possibleLogins = null;
    this.loginData.password = '';

    this.isOK = true;
  }

  changePW(oldPassword: String, newPassword: String) {
    this.inChangeProcess = true;

    let changeDTO = new ChangePWDTO();
    changeDTO.oldPassword = oldPassword;
    changeDTO.newPassword = newPassword;

    let options = new RequestOptions();
    options.headers = this.getHeadersAuthenticated();

    let payload = JSON.stringify(changeDTO);
    this.errorText = "";
    this.http.post(environment.apiV2 + 'userCredentials', payload, options).pipe(
      map(resp => resp.json())
    )
      .subscribe(
        res => {
          if (!res['error']) {
            this.myApplication.messageDTO.setSuccessWithId('PWChanged');
          } else {
            this.myApplication.messageDTO.setErrorWithId('FM.PWoldWrong');
          }
          this.inChangeProcess = false;
        },
        err => {
          this.myApplication.messageDTO.setErrorWithId('FM.PWChangeFailure');
          this.inChangeProcess = false;
        }
      );
  }

  registerRepository(registerDTO, callback) {

    let options = new RequestOptions();
    options.headers = this.getHeadersAuthenticated();

    let country = this.myApplication.getCountry(registerDTO.country);
    if (country) {
      registerDTO.countryCode = country.code;
    } else {
      registerDTO.countryCode = '';
    }    

    let payload = JSON.stringify(registerDTO);
    let json: any = JSON.parse(payload);
    json.country = registerDTO.country;
    if (!json.legalForm) {
      json.legalForm = null;
    }
    json.language = this.myApplication.selectedLanguage;

    this.http.post(environment.apiV2 + 'registration', json, options).pipe(
      map(resp => resp.json())
    )
      .subscribe(
        res => {
          if (res['repoId']) {
            callback.registrationDone(res['repoId']);
          } else {
            callback.registrationDone('');
          }
        },
        err => {
          callback.registrationDone('');
        }
      );
  }
}
