/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../button/button.component.ngfactory";
import * as i3 from "../button/button.component";
import * as i4 from "../../../services/application.service";
import * as i5 from "./confirm-modal.component";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
var styles_ConfirmModalComponent = [i0.styles];
var RenderType_ConfirmModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmModalComponent, data: {} });
export { RenderType_ConfirmModalComponent as RenderType_ConfirmModalComponent };
export function View_ConfirmModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [["class", "modal-title"], ["id", "confirmModalLabel"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", "\n"])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-button", [], null, null, null, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(7, 114688, null, 0, i3.ButtonComponent, [i4.ApplicationService], { iconName: [0, "iconName"], id: [1, "id"], callback: [2, "callback"], callbackOwner: [3, "callbackOwner"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-button", [], null, null, null, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(9, 114688, null, 0, i3.ButtonComponent, [i4.ApplicationService], { iconName: [0, "iconName"], id: [1, "id"], callback: [2, "callback"], callbackOwner: [3, "callbackOwner"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "check"; var currVal_3 = "Confirm.yes"; var currVal_4 = "yes"; var currVal_5 = _co; _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = "ban"; var currVal_7 = "Confirm.no"; var currVal_8 = "noAction"; var currVal_9 = _co; _ck(_v, 9, 0, currVal_6, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.myApplication.getText("Repository.ConfirmQuestion"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.questionDTO.text; _ck(_v, 4, 0, currVal_1); }); }
export function View_ConfirmModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirm-modal", [], null, null, null, View_ConfirmModalComponent_0, RenderType_ConfirmModalComponent)), i1.ɵdid(1, 114688, null, 0, i5.ConfirmModalComponent, [i4.ApplicationService, i6.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmModalComponentNgFactory = i1.ɵccf("app-confirm-modal", i5.ConfirmModalComponent, View_ConfirmModalComponent_Host_0, { callbackOwner: "callbackOwner", callback: "callback", object: "object", question: "question", id: "id" }, {}, []);
export { ConfirmModalComponentNgFactory as ConfirmModalComponentNgFactory };
