import * as tslib_1 from "tslib";
import { AbstractEntityObject } from '../technical-aspects/abstract-entity-object';
import { Constants } from '../business-logic/constants';
var HarvestEO = /** @class */ (function (_super) {
    tslib_1.__extends(HarvestEO, _super);
    function HarvestEO() {
        var _this = _super.call(this) || this;
        _this.recordId = '';
        _this.quantity = 0;
        _this.quantityUnit = Constants.UNIT_KILOGRAMM;
        _this.harvestDate = _this.normalizeDate(new Date());
        _this.kind = Constants.HARVEST_KIND_MARKED;
        return _this;
    }
    HarvestEO.prototype.normalizeDate = function (value) {
        value.setHours(12);
        value.setMinutes(0);
        value.setSeconds(0);
        value.setMilliseconds(0);
        return value;
    };
    return HarvestEO;
}(AbstractEntityObject));
export { HarvestEO };
