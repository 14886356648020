<div class="modal-header">
  <h5 class="modal-title" id="popupagpdp">
    {{ myApplication.getText('Legal.AgreeText') }}</h5>
</div>

<div class="modal-body">
  <P class="ml-2">
    <a href="https://www.micro-farm-planner.com/impressum-und-datenschutz/" target="impressum">{{
      myApplication.getText('Impressum') }}</a>&nbsp;&nbsp;&nbsp;
    <a href="https://www.micro-farm-planner.com/agb/" target="agb">
      {{ myApplication.getText('AGB') }}</a>
  </P>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-sm mr-1 {{cssClass}}" (click)="annehmen()"
    [ngbTooltip]="tooltipAgree">
    <fa name="check"></fa> {{ myApplication.getText('Legal.Agree') }}
  </button>

  <button type="button" class="btn btn-sm mr-1 {{cssClass}}" (click)="ablehnen()"
    [ngbTooltip]="tooltipDecline">
    <fa name="times"></fa> {{ myApplication.getText('Legal.Decline') }}
  </button>
</div>